type HandlerFunc = () => void

function withBusy(setBusy : (value : boolean) => void) {
	return function (func? : HandlerFunc) {
		if (!func) {
			return undefined;
		}

		return function () {
			setBusy(true);
			try {
				func();
			} finally {
				setBusy(false);
			}
		}
	}
}

export default withBusy;
