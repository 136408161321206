import { Box, alpha, useTheme } from "@mui/material";
import useUser from "../../system/useUser";
import { useHistory } from "react-router-dom";
import { UserRole } from "../../system/Domain";
import { RouteProps, useRouteMatch } from "react-router";
import { ReactElement } from "react";

interface MenuItemProps {
	roles?: Array<UserRole>
	path?: string
	icon?: ReactElement
	name?: string
	onClick?: () => any
	variant?: "primary" | "secondary"
	matchProps?: RouteProps
	companies?: string[]
}

export default ({ roles, path, icon, name, onClick, matchProps, companies, variant = "primary" }: MenuItemProps) => {
	const theme = useTheme();
	const [user] = useUser();
	const history = useHistory();
	const match = useRouteMatch({
		path: path,
		...matchProps
	});

	if (roles && !user) return null;
	if (roles && user && !user.roles.some(r => roles.includes(r))) return null;

	const hasCorrectCompany = !companies || companies.includes(user.company?.name);
	if (!hasCorrectCompany) return null;

	let click = onClick;
	if (!click && path) {
		click = () => history.push(path);
	}

	const selected = !!path && !!match;

	return (
		<Box
			sx={{
				boxSizing: "border-box",
				verticalAlign: "top",
				paddingTop: theme.spacing(1.5),
				paddingBottom: theme.spacing(1.5),
				cursor: "pointer",
				display: "flex",
				alignItems: "center",
				fontSize: "0.85rem",
				lineHeight: "1em",
				color: theme.palette.common.white,
				"&:hover": {
					color: theme.palette.primary.light
				},
				...(selected && {
					color: theme.palette.primary.light,
					"&$secondary": {
						borderTop: "0 none",
						borderBottom: "2px solid",
						borderColor: theme.palette.primary.light,
						[theme.breakpoints.down('xs')]: {
							borderBottom: "0 none"
						},
						"& $icon": {
							[theme.breakpoints.down('xs')]: {
								color: theme.palette.primary.light
							}
						}
					}
				}),
				...(variant === "primary" && {
					paddingLeft: theme.spacing(2),
					paddingRight: theme.spacing(8)
				}),
				...(variant === "secondary" && {
					paddingTop: theme.spacing(1),
					paddingRight: theme.spacing(2),
					marginRight: theme.spacing(3),
					[theme.breakpoints.down('xs')]: {
						paddingLeft: 0,
						paddingRight: 0,
						marginLeft: theme.spacing(0.5),
						marginRight: theme.spacing(0.5),
						flexDirection: "column",
						fontSize: "0.75rem",
						"& $icon": {},
						"& $name": {
							paddingLeft: 0
						}
					}
				}),
				...(!name && { opacity: 0.7 })
			}}

			onClick={click}
		>
			<Box sx={{ color: alpha(theme.palette.common.white, 0.8) }}>{icon}</Box>
			{name && (
				<Box sx={{
					paddingLeft: theme.spacing(1),
					fontWeight: 300
				}}>{name}</Box>
			)}
		</Box>
	);
};
