import axios from "axios";
import { Company } from "./Domain";
import useGlobalPromise from "./useGlobalPromise";

export default () =>
	useGlobalPromise("ald_logistic_companies", async () =>
		(await axios.get<Company[]>(`/api/companies`, {
			params: {
				type: "logisticCompany"
			}
		})).data
	);
