import axios from "axios";
import { useCallback, useState } from "react";
import { Vehicle } from "./Domain";
import useAsyncEffect from "./useAsyncEffect";

export default (id? : string) => {
	const [vehicle, setVehicle] = useState<Vehicle>();

	const load = useCallback(async () => {
		if (!id) {
			return;
		}

		const { data: vehicle } = await axios.get<Vehicle>(`/api/vehicles/${id}`);
		setVehicle(vehicle);
	}, [id]);

	useAsyncEffect(async () => {
		setVehicle(undefined);
		await load();
	}, [id]);

	return [
		vehicle,
		setVehicle,
		load
	] as const;
};
