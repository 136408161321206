import { useRecoilState } from "recoil";
import { alpha, Box, useTheme } from "@mui/material";
import { navigationAtom } from "system/atoms/navigation";
import { PropsWithChildren, useLayoutEffect, useRef, useState } from "react";

interface Props {
	title: string;
}

export default ({ title, children }: PropsWithChildren<Props>) => {
	const theme = useTheme();
	const ref = useRef<HTMLDivElement>(null);
	const [hidden, setHidden] = useState(false);
	const [navigation] = useRecoilState(navigationAtom);

	useLayoutEffect(() => {
		const numItems = ref.current?.querySelectorAll("[data-navigation-id]").length || 0;
		if (numItems > 0 && hidden) {
			setHidden(false);
		} else if (numItems === 0 && !hidden) {
			setHidden(true);
		}
	}, [navigation.filter]);

	return (
		<Box
			sx={{
				padding: theme.spacing(4),
				...(hidden && { display: "none" }),
			}}
			ref={ref}
		>
			<Box
				sx={{
					fontWeight: 300,
					fontSize: "1.2rem",
					color: alpha(theme.palette.primary.light, 1.0),
				}}
			>
				{title}
			</Box>
			<Box
				sx={{
					display: "flex",
					flexWrap: "wrap",
					justifyContent: "start",
					flexDirection: navigation.orientation !== "horizontal" ? "column" : "row",
					gap: theme.spacing(2),
					paddingTop: theme.spacing(3),
				}}
			>
				{children}
			</Box>
		</Box>
	);
};
