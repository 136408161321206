import { useState } from 'react';
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { CustomerOrder, PricingRequest } from "../../../system/Domain";
import useAsyncEffect from "../../../system/useAsyncEffect";
import axios from "axios";
import askForTextAnswer from "../../../system/askForTextAnswer";
import OrdersSubMenu from "../OrdersSubMenu";
import Layout from "../../../Components/Layout/Layout";
import { Box, Chip, CircularProgress, Grid, TextField } from "@mui/material";
import AldVehicleHeader from "../../../Components/AldVehicleHeader";
import Actions from "../../../Components/Actions";
import ActionButton from "../../../Components/ActionButton";
import Tile from "../../../Components/Tiles/Tile";
import TileContent from "../../../Components/Tiles/TileContent";
import TextBlock from "../../../Components/Layout/TextBlock";
import moment from "moment";
import dateFormats from "../../../system/dateFormats";
import TileActions from "../../../Components/Tiles/TileActions";
import CustomerOrderStatusPill from "./CustomerOrderStatusPill";
import { copyClick, generateCustomerOrderLink } from "../../../utils";
import ReturnOrderStatusPill from "../Returns/ReturnOrderStatusPill";
import VehicleInformation from "../Returns/VehicleInformation";
import AppointmentInformation from "../../../Components/AppointmentInformation";
import useReturnOrder from "../../../system/useReturnOrder";
import { translateCollectionType, translateCustomerOrderType } from "../../../system/translate";
import useCustomerOrder from "../../../system/useCustomerOrder";
import CustomerOrderConfigurationTile from "./CustomerOrderConfigurationTile";
import TooltipButton from 'Components/Tooltipbutton';
import { setUserMessage } from "../../../system/reducers/userMessage/actions";
import { useDispatch } from "react-redux";
import { CustomerOrderDetailsFormType } from "./CustomerOrdersNew";
import PricingRequestInformationOverview from "../../Customer/PricingRequest/PricingRequestInformationOverview";
import OrdersNavigation from "../../../Navigation/OrdersNavigation";
import PricingDisplay from "../../Customer/PricingRequest/PricingDisplay";
import PricingRequestDecisionStatusPill from "./PricingRequestDecisionStatusPill";
import { Email } from "@mui/icons-material";
import { blue, grey } from "@mui/material/colors";

const CustomerOrderDetails = () => {
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const [customerOrder, setCustomerOrder] = useCustomerOrder(id);
	const dispatch = useDispatch();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [returnOrder] = useReturnOrder(customerOrder?.returnOrderId);
	const daysDiff = customerOrder ? moment().diff(moment(customerOrder?.dateTokenGenerated), "days") : 0;
	const tokenCreationThreshold = 60;

	const [pricingRequest, setPricingRequest] = useState<PricingRequest>();

	useAsyncEffect(async () => {
		if (!customerOrder) return;
		if (customerOrder.type !== "PricingRequest") return;

		const response = await axios.get<PricingRequest>(`/api/pricing-requests/${customerOrder.pricingRequestId}`);

		setPricingRequest(response.data);
	}, [customerOrder]);

	const generateNewToken = async () => {
		if (!id) return;

		setIsSubmitting(true);

		try {
			const { data: co } = await axios.patch<CustomerOrder>(`/api/orders/customers/${id}/generate-token`, {});
			setCustomerOrder(co);

			dispatch(setUserMessage(`Kundenlink wurde erfolgreich neu erstellt`, "success"));
		} finally {
			setIsSubmitting(false);
		}
	};

	useAsyncEffect(async () => {
		if (!id || customerOrder) {
			return;
		}

		const { data: co } = await axios.get<CustomerOrder[]>('/api/orders/customers', {
			params: {
				id: id
			}
		});

		if (co.length !== 1) {
			history.push('/orders/customers');
			return;
		}

		setCustomerOrder(co[0]);
	}, [id]);

	const handleCancellation = async () => {
		if (!customerOrder) {
			return;
		}

		const cancellationReason = await askForTextAnswer({
			text: '',
			required: true,
			label: 'Stornierungsgrund',
			title: "Stornierung"
		});

		if (!cancellationReason) {
			return;
		}

		setIsSubmitting(true);

		try {
			const response = await axios.post<CustomerOrder>(`/api/orders/customers/${customerOrder.id}/cancel`, {
				reason: cancellationReason
			});

			setCustomerOrder(response.data);
		} finally {
			setIsSubmitting(false);
		}
	};

	const handleReactivation = async () => {
		if (!customerOrder) {
			return;
		}

		setIsSubmitting(true);

		try {
			const response = await axios.post<CustomerOrder>(`/api/orders/customers/${customerOrder.id}/reactivate`);

			setCustomerOrder(response.data);
		} finally {
			setIsSubmitting(false);
		}
	};

	const link = customerOrder ? generateCustomerOrderLink(customerOrder) : null;

	const updatedBy = customerOrder?.updatedBy?.name;

	const saveCustomerConfiguration = async (formData: CustomerOrderDetailsFormType) => {
		if (!customerOrder) {
			return;
		}

		setIsSubmitting(true);

		try {
			const response = await axios.put<CustomerOrder>(`/api/orders/customers/${customerOrder.id}/config`, formData);

			setCustomerOrder(response.data);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<Layout
			plateNumber={customerOrder?.plateNumber}
			title="Kundenauftrag"
			navigation={<OrdersNavigation />}
			subMenu={<OrdersSubMenu />}
		>
			{!customerOrder && (
				<CircularProgress size={48} />
			)}
			{customerOrder && (
				<>
					<Grid container justifyContent="space-between" alignItems="flex-end" spacing={2}>
						<Grid item>
							<AldVehicleHeader plateNumber={customerOrder.plateNumber} entries={[customerOrder.vehicle.type?.description, customerOrder.vin]} />
						</Grid>
						<Grid item>
							<Grid container spacing={2} alignItems="center" justifyContent="space-between">
								<Grid item>
									{customerOrder.status !== "Cancelled" && (
										<Actions>
											<ActionButton
												color="secondary"
												disabled={isSubmitting || customerOrder.status === "Completed"}
												onClick={handleCancellation}
											>
												Stornieren
											</ActionButton>
										</Actions>
									)}
									{customerOrder.status === "Cancelled" && (
										<Actions>
											<ActionButton
												color="secondary"
												disabled={isSubmitting}
												onClick={handleReactivation}
											>
												Reaktivieren
											</ActionButton>
										</Actions>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Box mt={4}>
						<Grid container spacing={6}>
							<Grid item xs={12} md={6}>
								<Tile title="Zugang">
									<TileContent>
										<TextField
											disabled
											variant="standard"
											value={link}
											label="Link"
											fullWidth
										>
										</TextField>
									</TileContent>
									<TileActions>
										<ActionButton
											onClick={(e) => copyClick(e, link!)}
											variant="text"
											title="Kundenlink in die Zwischenablage kopieren"
										>
											Kundenlink kopieren
										</ActionButton>
										<ActionButton
											variant="text"
											color="secondary"
											hideOnDisabled
											onClick={() => {
												history.push(`/orders/customers/form/${customerOrder.id}`)
											}}>
											Kundenlink testen
										</ActionButton>
										{customerOrder.status !== "Cancelled" && (
											<TooltipButton
												variant="text"
												title={daysDiff < tokenCreationThreshold ? `Kundenlink kann erst ${tokenCreationThreshold - daysDiff > 1 ? `in ${tokenCreationThreshold - daysDiff} Tagen` : "morgen"} neu estellt werden` : ""}
												disabled={isSubmitting || daysDiff < tokenCreationThreshold}
												onClick={generateNewToken}
											>
												Kundenlink neu erstellen
											</TooltipButton>
										)}
									</TileActions>
								</Tile>
							</Grid>
							<Grid item xs={12} md={6}>
								<Tile title="Status">
									<TileContent>
										<Box>
											{customerOrder.isTraderOrder && (
												<Box mb={2}>
													<Chip
														label="Händlerauftrag"
														size="small"
														style={{ backgroundColor: blue[500] }}
													/>
												</Box>
											)}
											{!customerOrder.isTraderOrder && (
												<Box mb={2}>
													<Chip
														label="Kundenauftrag"
														size="small"
														style={{ backgroundColor: grey[500] }}
													/>
												</Box>
											)}
											{customerOrder.parentOrderId && (
												<Box mb={2}>
													<TextBlock
														primary={
															<Chip
																label="Folgeauftrag"
																size="small"
																style={{ backgroundColor: blue[500] }}
																onClick={() => history.push(`/orders/customers/${customerOrder.parentOrderId}`)}
															/>
														}
													/>
												</Box>
											)}
											<CustomerOrderStatusPill customerOrder={customerOrder} />
											{customerOrder.cancellationReason && (
												<Box mt={2}>
													<em>{customerOrder.cancellationReason}</em>
												</Box>
											)}
											{customerOrder.type && (
												<Box mt={2}>
													<Chip
														label={translateCustomerOrderType(customerOrder.type)}
														size="small"
													/>
												</Box>
											)}
											{customerOrder.type === "PricingRequest" && (
												<Box mt={2}>
													<PricingRequestDecisionStatusPill customerOrder={customerOrder} />
												</Box>
											)}
										</Box>
									</TileContent>
								</Tile>
							</Grid>
							<Grid item xs={12} md={6}>
								<CustomerOrderConfigurationTile
									customerOrder={customerOrder}
									save={saveCustomerConfiguration}
								/>
							</Grid>
							{customerOrder.returnOrderId && returnOrder && (
								<>
									<Grid item xs={12} md={6}>
										<Tile title="Eingegebene Fahrzeuginformationen">
											<TileContent>
												<VehicleInformation returnOrder={returnOrder} />
											</TileContent>
										</Tile>
									</Grid>
									<Grid item xs={12} md={6}>
										<Tile title="Status des Rückholauftrags">
											<TileContent>
												<Box mb={2}>
													<ReturnOrderStatusPill returnOrder={returnOrder} />
													{returnOrder.cancellationReason && (
														<Box mt={2}>
															<em>{returnOrder.cancellationReason}</em>
														</Box>
													)}
												</Box>
											</TileContent>
											<TileActions>
												<ActionButton
													onClick={() => history.push(`/orders/returns/${customerOrder.returnOrderId}`)}
													variant="text"
													title="Zum Rückholauftrag"
												>
													Zum Rückholauftrag
												</ActionButton>
											</TileActions>
										</Tile>
									</Grid>
									{(!customerOrder.type || customerOrder.type === "Return") && (
										<Grid item xs={12} md={6}>
											<Tile title={`Abholung von ${translateCollectionType(returnOrder.collection.collectionType)}`}>
												<TileContent>
													<Box>
														<TextBlock
															primary={returnOrder.collection.address.name}
															secondary={`${returnOrder.collection.address.street} - ${returnOrder.collection.address.zipCode}`}
															tertiary={`${returnOrder.collection.address.city} - ${returnOrder.collection.address.country}`}
														/>
														<TextBlock
															primary={""}
															secondary={returnOrder.collection.address.additionalInformation}
														/>
													</Box>
													<Box mt={2}>
														<TextBlock
															primary="Kontaktperson"
															secondary={`${returnOrder.collection.contact?.firstName} ${returnOrder.collection.contact?.lastName}`}
															tertiary={`${returnOrder.collection.contact?.email} - ${returnOrder.collection.contact?.telephone}`}
														/>
													</Box>
													{returnOrder.collection.comment && (
														<>
															<Box mt={2}>
																<TextBlock
																	primary={""}
																	secondary={returnOrder.collection.address.additionalInformation}
																/>
															</Box>
															<Box mt={2}>
																<TextBlock
																	primary="Kontaktperson"
																	secondary={`${returnOrder.collection.contact?.firstName} ${returnOrder.collection.contact?.lastName}`}
																	tertiary={`${returnOrder.collection.contact?.email} - ${returnOrder.collection.contact?.telephone}`}
																/>
															</Box>
														</>
													)}
													{returnOrder.collection.comment && (
														<Box mt={2}>
															<TextBlock
																primary={""}
																secondary={returnOrder.collection.comment}
															/>
														</Box>
													)}
													{returnOrder.collection.appointment && (
														<AppointmentInformation appointment={returnOrder.collection.appointment} />
													)}
												</TileContent>
											</Tile>
										</Grid>
									)}
								</>
							)}
							{customerOrder.type === "PricingRequest" && (
								<Grid item xs={12} md={6}>
									<Tile title="Kaufpreisanfrage">
										<TileContent>
											{!pricingRequest && (
												<CircularProgress size={48} />
											)}
											{pricingRequest && (
												<PricingRequestInformationOverview customerOrder={customerOrder} pricingRequest={pricingRequest} />
											)}
										</TileContent>
										<TileActions>
											<ActionButton
												color="primary"
												variant="text"
												onClick={() => history.push(`/sales/pricing-requests/${pricingRequest.id}`)}
											>
												Zur Kaufpreisanfrage
											</ActionButton>
										</TileActions>
									</Tile>
								</Grid>
							)}
							{customerOrder.type === "PricingRequest" && (pricingRequest?.status === "Priced" || pricingRequest?.status === "Completed") && (
								<Grid item xs={12} md={6}>
									<Tile title="Preisinformationen">
										<TileContent>
											<PricingDisplay pricingRequest={pricingRequest} />
										</TileContent>
									</Tile>
								</Grid>
							)}
							{customerOrder.emailCommunications.length > 0 && (
								<Grid item xs={12} md={6}>
									<Tile title="Email Kommunikation">
										<TileContent>
											{customerOrder.emailCommunications.map((email, i) =>
												<Box key={i} mt={i > 0 ? 2 : 0}>
													<TextBlock
														primary={
															<Box display="flex" flexDirection="row">
																<Box mr={1} display="flex"><Email /></Box>
																<Box display="flex" alignItems="center">{email.subject}</Box>
															</Box>
														}
														secondary={email.to.join(", ")}
														tertiary={moment(email.referenceCreated).format(dateFormats.dateTime)}
													/>
												</Box>
											)}
										</TileContent>
									</Tile>
								</Grid>
							)}
							<Grid item xs={12} md={6}>
								<Tile title="Historie">
									<TileContent>
										<TextBlock
											primary={`Erstellt: ${customerOrder.createdBy.name}`}
											secondary={moment(customerOrder.dateCreated).format(dateFormats.dateTime)}
										/>
										{customerOrder.updatedBy && (
											<Box mt={1}>
												<TextBlock
													primary={`Zuletzt angepasst: ${updatedBy || "über Link"}`}
													secondary={moment(customerOrder.dateUpdated).format(dateFormats.dateTime)}
												/>
											</Box>
										)}
									</TileContent>
								</Tile>
							</Grid>
							{customerOrder.choiceHistory && (
								<Grid item xs={12} md={6}>
									<Tile title="Auswahlhistorie">
										<TileContent>
											{customerOrder.choiceHistory.map((ch, i) => (
												<Box mt={i === 0 ? 0 : 2} key={i}>
													<TextBlock
														primary={
															<Chip
																label={translateCustomerOrderType(ch.value.customerOrderType)}
																size="small"
															/>
														}
														secondary={moment(ch.date).format(dateFormats.dateTime)}
													/>
												</Box>
											))}
										</TileContent>
									</Tile>
								</Grid>
							)}
						</Grid>
					</Box>
				</>
			)}
		</Layout>
	)
};

export default CustomerOrderDetails;
