import { Box, CircularProgress, Grid } from "@mui/material";
import axios from "axios";
import Tile from "Components/Tiles/Tile";
import PricingRequestNavigation from "Navigation/PricingRequestNavigation";
import SaleLeasingContractTile from "Pages/VehicleSales/SaleLeasingContractTile";
import SalesReporting from "Pages/VehicleSales/SalesReporting";
import { useState } from "react";
import { useParams } from "react-router";
import useAxiosEffect from "system/useAxiosEffect";
import Layout from "../../Components/Layout/Layout";
import TileContent from "../../Components/Tiles/TileContent";
import { LeasmanVehicle, PricingRequest, UserRole, Vehicle, VehicleAssessment } from "../../system/Domain";
import useAsyncEffect from "../../system/useAsyncEffect";
import useChannel from "../../system/useChannel";
import useEvent from "../../system/useEvent";
import useUser from "../../system/useUser";
import ArmadaTicketTile from "./ArmadaTicketTile";
import PricingRequestContractTile from "./PricingRequestContractTile";
import PricingRequestDetailsTile from "./PricingRequestDetailsTile";
import PricingRequestPricingTile from "./PricingRequestPricingTile";
import PricingRequestRelatedTile from "./PricingRequestRelatedTile";
import PricingRequestStatus from "./PricingRequestStatus";

export default () => {
	const { id } = useParams<{ id: string }>();
	const [request, setRequest] = useState<PricingRequest>();

	const [leasmanVehicle, setLeasmanVehicle] = useState<LeasmanVehicle>();
	const [assessment, setAssessment] = useState<VehicleAssessment>();
	const [vehicle, setVehicle] = useState<Vehicle>();

	const loadRequest = async (id: string) => {
		if (!id) {
			return;
		}

		const { data: request } = await axios.get<PricingRequest>(`/api/pricing-requests/${id}`);
		setRequest(request);
	};

	useAsyncEffect(async () => {
		setRequest(undefined);

		if (!id) {
			return;
		}

		await loadRequest(id);
	}, [id]);

	useAxiosEffect(async (config) => {
		if (!request?.leasmanVehicleId) {
			setLeasmanVehicle(undefined);
			return;
		}

		const { data } = await axios.get<LeasmanVehicle>(`/api/leasman/vehicles/${request.leasmanVehicleId}`, config);
		setLeasmanVehicle(data);
	}, [request?.leasmanVehicleId]);

	useAxiosEffect(async (config) => {
		if (!request?.vin) {
			setVehicle(undefined);
			return;
		}

		const { data } = await axios.get<Vehicle[]>(`/api/vehicles`, {
			...config,
			params: {
				vin: request.vin
			}
		});

		if (!!data && data.length === 1) {
			setVehicle(data[0]);
		} else {
			setVehicle(undefined);
		}
	}, [request?.vin]);

	useAxiosEffect(async (config) => {
		if (!vehicle?.remarketing?.assessment?.assessmentId) {
			setAssessment(undefined);
			return;
		}

		const { data } = await axios.get<VehicleAssessment>(`/api/assessments/${vehicle.remarketing.assessment.assessmentId}`, config);

		setAssessment(data);
	}, [vehicle?.remarketing?.assessment?.assessmentId]);

	const [, , hasRole] = useUser();

	useChannel("pricing-requests");

	useEvent("pricing-request:updated", async data => {
		const { id, version } = data;

		if (!request || !id || !version) {
			return;
		}

		if (id === request.id && request.version < version) {
			await loadRequest(id);
		}
	}, [request]);

	useEvent("pricing-request:new-predictions", async data => {
		const { ids } = data;

		if (!request || ids?.length === 0) {
			return;
		}

		if (ids.indexOf(request.id) !== -1) {
			await loadRequest(id);
		}
	}, [request]);

	return (
		<Layout
			title="Kaufpreisanfrage"
			plateNumber={request?.plateNumber}
			navigation={<PricingRequestNavigation request={request} />}
		>
			{!request && (
				<CircularProgress size={48} />
			)}
			{request && (
				<Grid container spacing={6}>
					<Grid item xs={3}>
						<Box sx={{ display: "flex", flexDirection: "column", gap: 6 }}>
							<PricingRequestDetailsTile request={request} setRequest={setRequest} leasmanVehicle={leasmanVehicle} vehicle={vehicle} assessment={assessment} />
							<SaleLeasingContractTile leasmanVehicle={leasmanVehicle} />
						</Box>
					</Grid>

					<Grid item xs={5}>
						<Box sx={{ display: "flex", flexDirection: "column", gap: 6 }}>
							{hasRole(UserRole.SalesManager) && request.status !== "Created" && (
								<PricingRequestPricingTile request={request} setRequest={setRequest} />
							)}

							<PricingRequestContractTile request={request} setRequest={setRequest} />

							{!!request.armadaTicket && (
								<ArmadaTicketTile request={request} setRequest={setRequest} />
							)}
						</Box>
					</Grid>

					<Grid item xs={4}>
						<Box sx={{ display: "flex", flexDirection: "column", gap: 6 }}>
							<PricingRequestRelatedTile request={request} />

							{request.status !== "Created" && request.status !== "Withdrawn" && hasRole(UserRole.SalesUser) && (
								<SalesReporting
									type={request.type}
									highlights={request.highlights || []}
									duration={leasmanVehicle?.contract.duration}
									mileage={leasmanVehicle?.contract.mileage}
								/>
							)}

							{request.statusHistory.length > 1 && (
								<Tile title="Verlauf">
									<TileContent>
										<Box py={1}>
											{[...request.statusHistory].reverse().map((h, i) => (
												<Box mt={i > 0 ? 4 : 0} key={i}>
													<PricingRequestStatus
														request={request}
														historyId={h.id}
														showDate
														showRelativeDate
													/>
												</Box>
											))}
										</Box>
									</TileContent>
								</Tile>
							)}
						</Box>
					</Grid>
				</Grid>
			)}
		</Layout>
	);
}
