import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

export interface IQuery {
	skip: number
	take: number
}

const useQuery = <T extends IQuery>(sessionKey: string, defaultQuery: T, initialUpdate?: (q: T) => T): [T, Dispatch<SetStateAction<T>>, (take?: number) => void, () => T, () => void] => {
	const jsonQuery = sessionStorage[sessionKey];

	let sessionQuery = defaultQuery;
	if (jsonQuery) {
		sessionQuery = {
			...sessionQuery,
			...JSON.parse(jsonQuery),
			skip: defaultQuery.skip,
			take: defaultQuery.take
		};
	}

	if (initialUpdate && sessionQuery) {
		sessionQuery = initialUpdate(sessionQuery);
	}

	const [query, setQuery] = useState<T>(sessionQuery);

	useEffect(() => {
		sessionStorage[sessionKey] = JSON.stringify({
			...query,
			skip: undefined,
			take: undefined
		})
	}, [query]);

	const fetchNext = (take?: number) => {
		setQuery(q => ({
			...q,
			skip: q.skip + q.take,
			take: take ?? q.take
		}));
	};

	const resetQuery = useCallback(() => {
		setQuery(defaultQuery);
		return defaultQuery;
	}, [defaultQuery]);

	const reload = useCallback(() => {
		setQuery(q => ({
			...q,
			skip: 0,
			take: defaultQuery.take
		}));
	}, [defaultQuery]);

	return [query, setQuery, fetchNext, resetQuery, reload];
};

export default useQuery;
