import { useHistory, useLocation } from 'react-router';

import { Videocam } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import { InventoryProps } from './Inventory';

export default ({ inventory } : InventoryProps) => {
	const history = useHistory();
	const location = useLocation();
	const path = `/inventories/${inventory?.id}/scan`;

	return (
		<IconButton
			onClick={() => history.push(path)}
			color={location.pathname === path ? "secondary" : "inherit"}
		>
			<Videocam />
		</IconButton>
	);
};
