import { confirmable, createConfirmation } from "react-confirm";
import { DialogTitle, DialogContent, DialogActions, Button, DialogContentText } from "@mui/material";
import ThemedDialog from "../Components/ThemedDialog";
import { ReactConfirmProps } from "../node_modules/react-confirm";

interface YesNoResult {
	yes?: boolean
}

interface YesNoDialogProps extends ReactConfirmProps<YesNoResult> {
	message: string
}

const YesNoDialog = ({ show, proceed, message }: YesNoDialogProps) => {
	return (
		<ThemedDialog open={show} maxWidth="sm" fullWidth onClose={() => proceed({})}>
			<DialogTitle>Frage</DialogTitle>
			<DialogContent>
				<DialogContentText>{message}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					color="secondary"
					onClick={() => proceed({})}
				>
					Nein
				</Button>
				<Button
					color="primary"
					onClick={() => proceed({ yes: true })}
				>
					Ja
				</Button>
			</DialogActions>
		</ThemedDialog>
	);
};

export default (message: string) => {
	const dialog = confirmable(props => <YesNoDialog {...props} message={message} />);
	return createConfirmation<YesNoResult>(dialog)();
};
