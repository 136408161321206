import { Box, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import ActionButton from "Components/ActionButton"
import FormDateTimePicker from "Components/FormDateTimePicker";
import FormNumber from "Components/FormNumber";
import FormYesNo from "Components/FormYesNo";
import FormText from "Components/FormText";
import Tile from "Components/Tiles/Tile"
import TileActions from "Components/Tiles/TileActions"
import TileContent from "Components/Tiles/TileContent"
import DefectItemControl from "Pages/Orders/Assessments/DefectItemControl";
import moment from "moment";
import { Dispatch, SetStateAction, useState } from "react";
import { AssessmentOrderDefectItem, RepairOrder, Vehicle } from "system/Domain";
import useForm from "system/useForm";

export interface Props {
	vehicle: Vehicle
	isEditingInterruption: boolean
	setIsEditingGeneralRepair: Dispatch<SetStateAction<boolean>>
	saveVehicleDisruption: (formData: GeneralRepairFormType) => Promise<RepairOrder[]>
	isNew: boolean
}

export interface GeneralRepairFormType {
	mileage?: number
	hasCarsChequebook?: boolean
	dateLastMaintenance?: string
	isReadyToDrive?: boolean
	defectItems: AssessmentOrderDefectItem[]
	recipients: string[]
	comment: string
}

export default ({ vehicle, isEditingInterruption, setIsEditingGeneralRepair, saveVehicleDisruption, isNew }: Props) => {
	const localIdPrefix = 'local';
	const [isSaving, setIsSaving] = useState(false);
	const [recipients, setRecipients] = useState<string[]>([]);
	const [editMode, setEditMode] = useState(isEditingInterruption);

	const copyGenralRepairFromPropsToInterrupionDetails = (): GeneralRepairFormType | (() => GeneralRepairFormType) => {
		if (isNew) {
			return {
				hasCarsChequebook: null,
				isReadyToDrive: null,
				dateLastMaintenance: null,
				mileage: null,
				defectItems: [],
				recipients: [],
				comment: null
			};
		}

		return {
			mileage: vehicle?.defect?.mileage,
			hasCarsChequebook: vehicle?.defect?.hasCarsChequebook,
			dateLastMaintenance: vehicle?.defect?.dateLastMaintenance || null,
			isReadyToDrive: vehicle?.defect?.isReadyToDrive,
			defectItems: vehicle?.defect?.defectItems
				.filter((item) => item.id !== undefined)
				.map((item) => ({
					id: item.id,
					description: item.description,
					createdBy: item.createdBy,
					dateCreated: item.dateCreated,
					updatedBy: item.updatedBy,
					dateUpdated: item.dateUpdated
				})),
			recipients: vehicle?.defect?.recipients,
			comment: vehicle?.defect?.comment
		}
	};

	const [generalRepairDetails, setGeneralRepairDetails] = useState<GeneralRepairFormType>(copyGenralRepairFromPropsToInterrupionDetails());

	const form = useForm({
		values: generalRepairDetails,
		setValues: setGeneralRepairDetails
	});

	const save = async () => {
		setIsSaving(true);

		try {
			generalRepairDetails.defectItems.forEach(element => {
				if (element.id.startsWith(localIdPrefix)) {
					element.id = null;
				}
			});

			generalRepairDetails.recipients = recipients;

			await saveVehicleDisruption(generalRepairDetails);
		} finally {
			setIsSaving(false);
			setEditMode(false);
			setGeneralRepairDetails({
				hasCarsChequebook: null,
				isReadyToDrive: null,
				dateLastMaintenance: null,
				mileage: null,
				defectItems: [],
				recipients: [],
				comment: null
			});
		}
	};

	const recipientOptions: string[] = [
		"Logistik",
		"Dekra",
		"Carmarket"
	];

	const handleChange = (event: SelectChangeEvent<string[]>) => {
		setRecipients(event.target.value as string[]);
	};

	const shouldDisableSaveButton = generalRepairDetails.defectItems.length < 1 || recipients.length < 1 || isSaving;

	return (
		<Tile title="Allgemeine Reparatur">
			<TileContent>
				<>
					{editMode && (
						<>
							<Box mt={2}>
								<InputLabel>Benachrichtigung an</InputLabel>
								<Select
									multiple
									fullWidth
									value={recipients}
									onChange={handleChange}
								>
									{recipientOptions.map((name) => (
										<MenuItem key={name} value={name}>
											{name}
										</MenuItem>
									))}
								</Select>

							</Box>
							<Box mt={2}>
								<FormNumber
									name="mileage"
									label="Letzter KM-Stand"
									form={form}
									inline
									options={{ required: true }}
									fullWidth
								/>
							</Box>
							<Box mt={2}>
								<FormDateTimePicker
									form={form}
									label="Letzte Wartung"
									format="DD.MM.YYYY"
									name="dateLastMaintenance"
									variant="date"
									disableFuture
									fullWidth
									minDate={moment(new Date().getDate()).toISOString()}
									views={["year", "month", "day"]}
								/>
							</Box>

							<Box mt={2}>
								<FormYesNo
									name="hasCarsChequebook"
									label="Scheckheft gepflegt"
									form={form}
									options={{ required: true }}
								/>
							</Box>

							<Box mt={2}>
								<FormYesNo
									name="isReadyToDrive"
									label="Fahrbereit"
									form={form}
									options={{ required: true }}
								/>
							</Box>

							<Box mt={2}>
								<FormText
									name="comment"
									label="Kommentar"
									form={form}
									fullWidth
								/>
							</Box>
						</>
					)}
					<Box mt={2}>
						<DefectItemControl defectable={generalRepairDetails} isInEditMode={editMode} form={form} />
					</Box>
				</>
			</TileContent>
			<TileActions>
				<ActionButton
					variant="text"
					hideOnDisabled
					title="Abbrechen"
					disabled={isSaving}
					onClick={() => setIsEditingGeneralRepair(false)}
				>
					Abbrechen
				</ActionButton>
				<ActionButton
					variant="text"
					hideOnDisabled
					title="Speichern"
					onClick={save}
					disabled={shouldDisableSaveButton}
					color="secondary"
				>
					Speichern
				</ActionButton>
			</TileActions>
		</Tile>
	);
};


