import { useEffect, useState } from "react";

export default <T>(key : string, defaultValue : T) => {
	let initialValue : T = defaultValue;

	const storedValue = sessionStorage[key];
	if (storedValue) {
		initialValue = {
			...defaultValue,
			...JSON.parse(storedValue)
		};
	}

	const [value, setValue] = useState<T>(initialValue);

	useEffect(() => {
		sessionStorage.setItem(key, JSON.stringify(value));
	}, [value]);

	return [value, setValue] as const;
};
