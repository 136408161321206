import { PropsWithChildren } from "react";
import { Box, BoxProps, useTheme } from "@mui/material";
import { ThemeColor } from "../../system/Domain";

interface Props extends BoxProps {
	color?: ThemeColor;
}

export default ({ color = "info", children, ...rest }: PropsWithChildren<Props>) => {
	const theme = useTheme();
	const getColor = (color: string) => {
		switch (color) {
			case "primary":
				return theme.palette.primary.light;
			case "secondary":
				return theme.palette.secondary.light;
			case "success":
				return theme.palette.success.light;
			case "warning":
				return theme.palette.warning.light;
			case "error":
				return theme.palette.error.light;
		}
	};
	return (
		<Box {...rest} sx={{ color: getColor(color) }}>
			{children}
		</Box>
	);
};
