import { useEffect } from "react";
import FormText from "../../../../Components/FormText";
import FormNumber from "../../../../Components/FormNumber";
import { Form } from "../../../../system/useForm";
import { OrderType } from "../../../../system/useOrder";
import FormChoices from "../../../../Components/FormChoices";

export default ({ form, order } : { form : Form, order : OrderType | null }) => {
	useEffect(() => {
		const driversLicenseChecked = form.getValue("transfer.driversLicenseChecked");

		console.log('driversLicenseChecked', driversLicenseChecked, order?.isFlexDelivery)

		if ((driversLicenseChecked === undefined || driversLicenseChecked === null) && order?.isFlexDelivery !== true) {
			form.setValue("transfer.driversLicenseChecked", order?.isFlexDelivery);
		}
	}, [form]);

	return (
		<>
			<FormNumber
				name="transfer.mileage"
				label="KM Stand"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormText
				name="transfer.remarks"
				label="Bemerkungen"
				form={form}
				options={{
					required: false
				}}
			/>
			{order?.isFlexDelivery && (
				<FormChoices
					name="transfer.driversLicenseChecked"
					label="Führerscheinkontrolle erfolgt"
					form={form}
					options={{ required: true }}
					choices={{
						true: "Ja"
					}}
				/>
			)}
		</>
	);
};

