import { Dispatch, SetStateAction } from "react";
import { alpha, Box, Button, ButtonGroup, useTheme } from "@mui/material";
import { SalesReporting, SalesReportingSettings } from "./SalesReporting";

interface Props {
	reporting: SalesReporting;
	settings: SalesReportingSettings;
	setSettings: Dispatch<SetStateAction<SalesReportingSettings>>;
}

export default ({ reporting, settings, setSettings }: Props) => {
	const theme = useTheme();
	const styles = {
		list: {
			display: "flex",
			flexDirection: "column",
			maxHeight: 250,
			overflow: "scroll",
		},
		item: {
			display: "flex",
			cursor: "pointer",
			gap: theme.spacing(1),
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			"&:hover": {
				backgroundColor: "rgba(255, 255, 255, 0.1)",
			},
		},
		root: {
			display: "flex",
			flexDirection: "column",
			gap: theme.spacing(1.5),
		},
		filter: {
			display: "flex",
			gap: theme.spacing(2),
			alignItems: "center",
		},
		filterLabel: {
			width: 150,
			fontSize: "0.8rem",
			fontVariantCaps: "all-small-caps",
			color: theme.palette.text.secondary,
		},
		filterControl: {
			flexGrow: 1,
			display: "flex",
			justifyContent: "flex-end",
		},
		filterButton: {
			fontSize: "0.8rem",
			fontVariantCaps: "all-small-caps",
			lineHeight: "0.8rem",
			transitionProperty: "background-color,color,border-color",
			transitionDuration: "300ms",
			color: alpha(theme.palette.text.primary, 0.7),
		},
		active: {
			backgroundColor: alpha(theme.palette.primary.main, 0.2),
		},
	};
	const months = [3, 6, 9, 12, 18];

	if (!months.includes(settings.lastMonths)) {
		months.push(settings.lastMonths);
	}

	const modelFilters = [
		{
			value: "all",
			label: "Alle",
		},
		{
			value: "make",
			label: "Hersteller",
		},
		{
			value: "model",
			label: "Modell",
		},
		{
			value: "variant",
			label: "Variante",
		},
	];

	const durationFilters = [
		{
			value: -1,
			label: "Alle",
		},
		{
			value: 0,
			label: "Gleich",
		},
		{
			value: 3,
			label: "3",
		},
		{
			value: 6,
			label: "6",
		},
		{
			value: 9,
			label: "9",
		},
		{
			value: 12,
			label: "12",
		},
	];

	const mileageFilters = [
		{
			value: -1,
			label: "Alle",
		},
		{
			value: 10,
			label: "10",
		},
		{
			value: 20,
			label: "20",
		},
		{
			value: 30,
			label: "30",
		},
		{
			value: 40,
			label: "40",
		},
		{
			value: 50,
			label: "50",
		},
	];

	const highlightFilters = [
		{
			value: -1,
			label: "Alle",
		},
		{
			value: 0,
			label: "Gleich",
		},
		{
			value: 10,
			label: "10%",
		},
		{
			value: 25,
			label: "25%",
		},
		{
			value: 50,
			label: "50%",
		},
	];

	const retailFilters = [
		{
			value: false,
			label: "B2B",
		},
		{
			value: true,
			label: "Retail",
		},
	];

	const previousOwnersFilters = [
		{
			value: null,
			label: "Alle",
		},
		{
			value: 1,
			label: "1. Hand",
		},
		{
			value: 2,
			label: "2. Hand",
		},
	];

	const sourceFilters = [
		{
			value: null,
			label: "Alle",
		},
		{
			value: "sales",
			label: "Verkäufe",
		},
		{
			value: "offers",
			label: "Angebote",
		},
	];

	return (
		<Box sx={styles.root}>
			<Box sx={styles.filter}>
				<Box sx={styles.filterLabel}>Zeitraum in Monaten</Box>
				<Box sx={styles.filterControl}>
					<ButtonGroup size="small">
						{months.map((m) => (
							<Button
								key={m}
								sx={[styles.filterButton, settings.lastMonths === m && styles.active]}
								onClick={() =>
									setSettings((s) => ({
										...s,
										lastMonths: m,
									}))
								}
							>
								{m}
							</Button>
						))}
					</ButtonGroup>
				</Box>
			</Box>
			<Box sx={styles.filter}>
				<Box sx={styles.filterLabel}>Modell</Box>
				<Box sx={styles.filterControl}>
					<ButtonGroup size="small">
						{modelFilters.map((f) => (
							<Button
								key={f.value}
								sx={[styles.filterButton, settings.model === f.value && styles.active]}
								// @ts-ignore
								onClick={() =>
									setSettings((s) => ({
										...s,
										model: f.value,
									}))
								}
							>
								{f.label}
							</Button>
						))}
					</ButtonGroup>
				</Box>
			</Box>
			<Box sx={styles.filter}>
				<Box sx={styles.filterLabel}>Laufzeit</Box>
				<Box sx={styles.filterControl}>
					<ButtonGroup size="small">
						{durationFilters.map((f) => (
							<Button
								key={f.value}
								sx={[styles.filterButton, settings.duration === f.value && styles.active]}
								// @ts-ignore
								onClick={() =>
									setSettings((s) => ({
										...s,
										duration: f.value,
									}))
								}
							>
								{f.label}
							</Button>
						))}
					</ButtonGroup>
				</Box>
			</Box>
			<Box sx={styles.filter}>
				<Box sx={styles.filterLabel}>Laufleistung</Box>
				<Box sx={styles.filterControl}>
					<ButtonGroup size="small">
						{mileageFilters.map((f) => (
							<Button
								key={f.value}
								sx={[styles.filterButton, settings.mileage === f.value && styles.active]}
								// @ts-ignore
								onClick={() =>
									setSettings((s) => ({
										...s,
										mileage: f.value,
									}))
								}
							>
								{f.label}
							</Button>
						))}
					</ButtonGroup>
				</Box>
			</Box>
			<Box sx={styles.filter}>
				<Box sx={styles.filterLabel}>Ausstattung</Box>
				<Box sx={styles.filterControl}>
					<ButtonGroup size="small">
						{highlightFilters.map((f) => (
							<Button
								key={f.value}
								sx={[styles.filterButton, settings.highlights === f.value && styles.active]}
								// @ts-ignore
								onClick={() =>
									setSettings((s) => ({
										...s,
										highlights: f.value,
									}))
								}
							>
								{f.label}
							</Button>
						))}
					</ButtonGroup>
				</Box>
			</Box>
			<Box sx={styles.filter}>
				<Box sx={styles.filterLabel}>Vertrieb</Box>
				<Box sx={styles.filterControl}>
					<ButtonGroup size="small">
						{retailFilters.map((f) => (
							<Button
								key={f.label}
								sx={[styles.filterButton, settings.retail === f.value && styles.active]}
								// @ts-ignore
								onClick={() =>
									setSettings((s) => ({
										...s,
										retail: f.value,
									}))
								}
							>
								{f.label}
							</Button>
						))}
					</ButtonGroup>
				</Box>
			</Box>
			<Box sx={styles.filter}>
				<Box sx={styles.filterLabel}>Vorbesitzer</Box>
				<Box sx={styles.filterControl}>
					<ButtonGroup size="small">
						{previousOwnersFilters.map((f) => (
							<Button
								key={f.label}
								sx={[styles.filterButton, settings.previousOwners === f.value && styles.active]}
								// @ts-ignore
								onClick={() =>
									setSettings((s) => ({
										...s,
										previousOwners: f.value,
									}))
								}
							>
								{f.label}
							</Button>
						))}
					</ButtonGroup>
				</Box>
			</Box>
			<Box sx={styles.filter}>
				<Box sx={styles.filterLabel}>Quelle</Box>
				<Box sx={styles.filterControl}>
					<ButtonGroup size="small">
						{sourceFilters.map((f) => (
							<Button
								key={f.label}
								sx={[styles.filterButton, settings.source === f.value && styles.active]}
								// @ts-ignore
								onClick={() =>
									setSettings((s) => ({
										...s,
										source: f.value,
									}))
								}
							>
								{f.label}
							</Button>
						))}
					</ButtonGroup>
				</Box>
			</Box>
		</Box>
	);
};
