import { Table } from "@mui/material";
import { OrderEvent } from "../../../system/Domain";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import moment from "moment";
import TextBlock from "../../../Components/Layout/TextBlock";
import dateFormats from "../../../system/dateFormats";

export interface OrderEventListProps {
	events: OrderEvent[]
}

const events_metadata: any = {
	"created": {
		name: "Erzeugt",
		// info: event => event.details.filename,
		order: (e: OrderEvent) => e.last
	},
	"pickupGeocoded": {
		name: "Abholadresse geocodiert"
	},
	"destinationGeocoded": {
		name: "Abgabeadresse geocodiert"
	},
	"pickupPlanned": {
		name: "Abholdatum gesetzt",
		info: (e: OrderEvent) => {
			if (!e.current.plannedPickupDate) return "Datum zurückgesetzt";
			const pickupDate = moment(e.current.plannedPickupDate);
			return pickupDate.format(dateFormats.dateTime);
		}
	},
	"pickupContactChanged": {
		name: "Abholkontakt geändert"
	},
	"confirmed": {
		name: "Auftrag angenommen"
	},
	"cancelled": {
		name: "Auftrag abgebrochen"
	},
	"lotReferenceRegistered": {
		name: "In der Platzlogistik registriert"
	},
	"remarksChanged": {
		name: "Bemerkung geaendert"
	},
	"cancellationRequested": {
		name: "Auftrag zurueckgegeben"
	},
	"assigned": {
		name: "Logistikdienstleister zugewiesen",
		info: (e: OrderEvent) => e.current.assignedTo.name
	},
	"protocolAttached": {
		name: "Rücknahmeprotokoll hochgeladen",
		info: (e: OrderEvent) => e.current.protocol ? e.current.protocol.filename : "Kein Protokoll"
	},
	"protocolDetached": {
		name: "Rücknahmeprotokoll entfernt"
	},
	"protocolReplaced": {
		name: "Rücknahmeprotokoll ersetzt",
		info: (e: OrderEvent) => e.current.protocol ? e.current.protocol.filename : undefined
	},
	"returnPictureAttached": {
		name: "Bild bei Annahme hinzugefügt",
	},
	"vehicleReturned": {
		name: "Fahrzeug angenommen",
	},
	"completed": {
		name: "Auftrag abgeschlossen",
	}
};



export default ({ events }: OrderEventListProps) => {
	return (
		<Table>
			<colgroup>
				<col width="25%" />
				<col width="75%" />
			</colgroup>
			<TableBody>
				{events.map((e, i) => {
					const metadata = events_metadata[e.id];
					const order = metadata && metadata.order ? metadata.order(e) : e.current;

					const eventDate = moment(order.dateUpdated);
					const type = metadata ? metadata.name : e.id;
					const info = metadata && metadata.info ? metadata.info(e) : "";

					const userName = order.updatedBy ? order.updatedBy.name : "Unbekannt";

					return (
						<TableRow sx={{ verticalAlign: "top" }} key={i}>
							<TableCell>
								<TextBlock
									primary={eventDate.format(dateFormats.dateTime)}
									secondary={eventDate.fromNow()}
								/>
							</TableCell>
							<TableCell>
								<TextBlock
									primary={info ? `${type}: ${info}` : type}
									secondary={userName}
								/>
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		</Table>
	);
}
	;
