import { WorkSession } from "../../system/Domain";
import axios from "axios";
import formatWorkSessionPerspective from "./formatWorkSessionPerspective";
import { setWorkSession } from "../../system/reducers/workSessions/actions";
import { useDispatch } from "react-redux";


const useWorkSessions = () => {
	const dispatch = useDispatch();

	const createWorkSession = async (session : Partial<WorkSession>) => {
		const { data: workSession } = await axios.post(`/api/work-sessions`, {
			isPublic: true,
			title: formatWorkSessionPerspective(session.perspective || "Manual"),
			...session
		});

		if (workSession) {
			dispatch(setWorkSession(workSession));
		}
	};

	return [createWorkSession];
};

export default useWorkSessions;
