import Tile from "../../../Components/Tiles/Tile";
import TileContent from "../../../Components/Tiles/TileContent";
import { AssessmentOrder } from "../../../system/Domain";
import moment from "moment";
import TextBlock from "../../../Components/Layout/TextBlock";

const getAssessmentOrderStatus = (order : AssessmentOrder) => {
	switch (order.assessmentStatus) {
		case "Unknown":
			return "Unbekannt";
		case "Transferred":
			return `Übertragen am ${moment(order.dateTransferred).format("LLLL")}`;
		case "Assigned":
			return `Gutachter zugewiesen am ${moment(order.dateAssigned).format("LLLL")}`;
		case "Confirmed":
			return `Begutachtung geplant am ${moment(order.assessmentDate).format("LLLL")}`;
		case "Delayed":
			return `Verzögerung am ${moment(order.dateDelayed).format("LLLL")} - ${order.reasonForDelay}`;
		case "Done":
			return `Abgeschlossen am ${moment(order.assessmentDate).format("LLLL")}`;
		default:
			return `Unbekannter Status ${order.assessmentStatus}`;
	}
};

const DekraAssessmentOrderTile = ({ order } : { order : AssessmentOrder }) => {
	let content;

	if (!order.externalReference) {
		content = (
			<TileContent>
				<TextBlock primary="Der Auftrag wurde nicht via Schnittstelle übertragen"/>
			</TileContent>
		);
	} else {
		content = (
			<TileContent>
				<TextBlock
					gutterBottom
					primary="Status"
					secondary={getAssessmentOrderStatus(order)}
				/>
				<TextBlock
					primary="Auftragsnummer Dekra"
					secondary={order.externalReference ? order.externalReference : "Unbekannt"}
				/>
			</TileContent>
		);
	}

	return (
		<Tile title="Dekra Auftrag">
			{content}
		</Tile>
	);
};

export default DekraAssessmentOrderTile;
