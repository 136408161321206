import FormChoices from "./FormChoices";

export default (props : any) => (
	<FormChoices
		{...props}
		choices={{
			true: "Ja",
			false: "Nein"
		}}
	/>
);
