import { useState } from "react";
import TextBlock from "../../Components/Layout/TextBlock";
import { SaleProps } from "./SaleLayout";
import formatCurrency from "../../system/formatCurrency";
import { Sale, SaleStatus, ThemeColor } from "../../system/Domain";
import useRunAction from "../../system/useRunAction";
import ListItem from "../../Components/ListItem";
import ListItemContent from "../../Components/ListItemContent";
import ListItemActions from "../../Components/ListItemActions";
import ListItemAction from "../../Components/ListItemAction";
import formatRelativeDate from "../../system/formatRelativeDate";
import ListItemChips from "../../Components/ListItemChips";
import ListItemChip from "../../Components/ListItemChip";
import { useHistory } from "react-router";
import { Launch } from "@mui/icons-material";
import { Plate } from "../../Components/Plate";
import _ from "lodash";
import FluentGrid from "../../Components/FluentGrid";

export const formatStatus = (status: SaleStatus) => {
	switch (status) {
		case "Returned":
			return "Inaktiv";
		case "ReadyToAllocate":
		case "ReadyToSell":
			return "Aktiv";
		case "Reserved":
			return "Reserviert";
		case "Sold":
			return "Verkauft";
		case "ReadyForShipping":
			return "Bereit zur Auslieferung";
		case "Shipped":
			return "Ausgeliefert";
		case "End":
			return "Abgeschlossen";
		case "Cancelled":
			return "Abgebrochen";
		default:
			return status;
	}
};

export const formatSaleStatus = (sale: Sale) => {
	if (sale.isBlocked) {
		return "Gestoppt"
	}
	else {
		return formatStatus(sale.status)
	}
};

export const paintStatus = (status: SaleStatus): ThemeColor => {
	switch (status) {
		case "ReadyToAllocate":
		case "ReadyToSell":
		case "Reserved":
			return "primary";
		case "Sold":
		case "Shipped":
		case "End":
			return "success";
		case "Cancelled":
			return "warning";
		case "Returned":
		default:
			return "info";
	}
};

export const paintSaleStatus = (sale: Sale): ThemeColor => {
	if (sale.isBlocked) {
		return "error"
	}
	else {
		return paintStatus(sale.status)
	}
};

interface Props extends SaleProps {
}


type Mode = "view"
	| "sell";

export default ({ sale }: Props) => {
	const history = useHistory();

	const [actionRunning] = useRunAction();
	const [mode] = useState<Mode>("view");

	let color: ThemeColor = "info";
	switch (mode) {
		case "sell":
			color = "primary";
			break;
		case "view":
		default:
			color = "info";
			break;
	}

	return (
		<ListItem
			color={color}>

			<ListItemActions>
				{mode === "view" && (
					<>
						<ListItemAction
							icon={<Launch />}
							disabled={actionRunning}
							onClick={() => history.push(`/sales/${sale.id}`)}
						/>
					</>
				)}
				{mode === "sell" && (<></>)}
			</ListItemActions>
			<ListItemContent
				onClick={() => history.push(`/sales/${sale.id}`)}
			>
				<TextBlock
					primary={(
						<Plate plateNumber={sale.plateNumber} style={{ zoom: 0.6 }} />
					)}
					secondary={!!sale.vehicle.type && `${sale.vehicle.type.make.name} ${sale.vehicle.type.model.version} ${sale.vehicle.type.model.variant}`}
				/>
				{mode === "view" && (
					<FluentGrid mt={2} mb={1} itemWidth={100}>
						<TextBlock
							reduced
							primary={formatCurrency(_.max(sale.offers
								.filter(o => o.status !== "Archived")
								.map(o => o.highestBid?.price)))}
							secondary="Höchstgebot"
						/>
						<TextBlock
							reduced
							primary={formatRelativeDate(sale.dateCreated)}
							secondary="Verkauf angelegt"
						/>
						<TextBlock
							reduced
							primary={formatRelativeDate(sale.dateUpdated)}
							secondary="Letzte Anpassung"
						/>
					</FluentGrid>
				)}
				<ListItemChips>
					<ListItemChip
						label={formatSaleStatus(sale)}
						color={paintSaleStatus(sale)}
					/>
					{sale.offers.filter(o => o.status === "Active" || o.status === "Reserved" || o.status === "Sold").map(o =>
						<ListItemChip
							key={o.id}
							label={o.channel.name}
							color="info"
						/>
					)}
				</ListItemChips>
			</ListItemContent>
		</ListItem>
	);
}
