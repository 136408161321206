import Layout from "../../Components/Layout/Layout";
import { CircularProgress, Grid } from "@mui/material";
import { useHistory } from "react-router";
import useVehicleServiceTypes from "../../system/useVehicleServiceTypes";
import SquareTile from "../../Components/Tiles/SquareTile";
import IconTile from "../../Components/Tiles/IconTile";
import VehicleServiceTypeIcon from "./VehicleServiceTypeIcon";


const VehicleServiceSelectType = () => {
	const history = useHistory();

	const [vehicleServiceServiceTypes] = useVehicleServiceTypes();

	return (
		<Layout>
			{
				!vehicleServiceServiceTypes && (
					<CircularProgress size={48} />
				)
			}
			{
				vehicleServiceServiceTypes && (
					<Grid container spacing={4}>
						{
							vehicleServiceServiceTypes.sort((a, b) => a.text.localeCompare(b.text)).map((item) => {
								return (
									<Grid item key={item.id} xs={6} md={3} lg={2}>
										<SquareTile>
											<IconTile
												primary={<VehicleServiceTypeIcon sx={{
													width: "60%",
													height: "60%"
												}} vehicleServiceType={item} />}
												secondary={item.text}
												onClick={() => history.push(`/vehicles/services/close/${item.id}`)}
											/>
										</SquareTile>
									</Grid>
								);
							})
						}
					</Grid>
				)
			}
		</Layout>
	)
};

export default VehicleServiceSelectType;
