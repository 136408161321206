import { useEffect, useState } from "react";
import { Box, CircularProgress, Link } from "@mui/material";
import { VehicleHistoryEntry } from "../../system/Domain";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import moment from "moment";
import dateFormats from "../../system/dateFormats";
import { Link as RouterLink } from "react-router-dom";
import VehicleStatus from "./VehicleStatus";
import TextBlock from "../../Components/Layout/TextBlock";
import VehiclePlace from "./VehiclePlace";
import PaperTable from "../../Components/PaperTable";
import LotScanContextMenu from "./LotScanContextMenu";
import VehicleHeaderMenu from "./VehicleHeaderMenu";
import VehicleSubMenu from "./VehicleSubMenu";
import Layout from "../../Components/Layout/Layout";
import useVehicle from "../../system/useVehicle";
import { useParams } from "react-router";
import VehicleHeader from "./VehicleHeader";
import { TableCell } from "../../Components/BreakpointStyledComponents";

export default () => {
	const { id } = useParams<{ id: string }>();
	const [vehicle] = useVehicle(id);
	const [entries, setEntries] = useState<VehicleHistoryEntry[]>([]);

	useEffect(() => {
		if (!vehicle) {
			setEntries([]);
			return;
		}

		setEntries([...vehicle.history.entries].reverse());
	}, [vehicle]);

	return (
		<Layout
			contextMenu={[
				<LotScanContextMenu />,
				<VehicleHeaderMenu vehicle={vehicle} />
			]}
			subMenu={vehicle && <VehicleSubMenu vehicle={vehicle} />}
		>
			{!vehicle && (
				<CircularProgress size={48} />
			)}
			{vehicle && (
				<>
					<VehicleHeader vehicle={vehicle} />
					<PaperTable>
						<TableHead>
							<TableRow>
								<TableCell>Eintrag</TableCell>
								<TableCell sm md lg xl>Status</TableCell>
								<TableCell sm md lg xl>Platz</TableCell>
								<TableCell sm md lg xl>Referenzen</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{entries.map((e, i) => {
								const status = <VehicleStatus vehicle={vehicle} historyEntry={e} />;
								const place = <VehiclePlace vehicle={vehicle} historyEntry={e} />;

								const references = [
									!e.orderId ? null :
										<TextBlock
											primary="Auftrag"
											secondary={
												<Link component={RouterLink} to={`/orders/transports/${e.orderId}`}>{e.orderId}</Link>
											}
										/>,
									!e.inventoryId ? null :
										<TextBlock
											primary="Inventur"
											secondary={
												<Link component={RouterLink}
													  to={`/inventories/${e.inventoryId}`}>{e.inventoryId}</Link>
											}
										/>,
									!e.location ? null :
										<TextBlock
											primary="Standort"
											secondary={`${e.location.lat}, ${e.location.lon}`}
										/>,
								]
									.filter(r => !!r)
									.map((r, i) => (
										<Box key={i} mt={i > 0 ? 2 : 0}>
											{r}
										</Box>
									));

								return (
									<TableRow key={i}>
										<TableCell>
											<TextBlock
												primary={moment(e.date).format(dateFormats.dateTime)}
												secondary={<>
													<span>{moment(e.date).fromNow()}</span>
													{e.user && (
														<span> · {e.user.name}</span>
													)}
												</>}
											/>
											<Box sx={{ display: { xs: "contents", sm: "none" } }}>
												<Box mt={2}>
													<Box mb={0.5}>
														<TextBlock primary="Status" />
													</Box>
													{status}
												</Box>
												<Box mt={2}>
													<Box mb={0.5}>
														<TextBlock primary="Platz" />
													</Box>
													{place}
												</Box>
												{references}
											</Box>
										</TableCell>
										<TableCell sm md lg xl>{status}</TableCell>
										<TableCell sm md lg xl>{place}</TableCell>
										<TableCell sm md lg xl>{references}</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</PaperTable>
				</>
			)}
		</Layout>
	);
}
;
