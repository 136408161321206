import { useState } from "react";
import { useParams } from "react-router";
import OrdersSubMenu from "../OrdersSubMenu";
import Layout from "../../../Components/Layout/Layout";
import { DeliveryType, ReturnOrder, ReturnOrderTerminationStatus, UserRole } from "../../../system/Domain";
import { Box, CircularProgress, Grid, MenuItem, Typography, useTheme } from "@mui/material";
import useAsyncEffect from "../../../system/useAsyncEffect";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Tile from "../../../Components/Tiles/Tile";
import TileContent from "../../../Components/Tiles/TileContent";
import AldVehicleHeader from "../../../Components/AldVehicleHeader";
import TextBlock from "../../../Components/Layout/TextBlock";
import moment from "moment";
import dateFormats from "../../../system/dateFormats";
import { getEnumName } from "../../../utils";
import { AssessmentLocation, AssessmentType } from "./ReturnOrdersOverviewTable";
import Actions from "../../../Components/Actions";
import ActionButton from "../../../Components/ActionButton";
import TileActions from "../../../Components/Tiles/TileActions";
import ReturnOrderStatusPill from "./ReturnOrderStatusPill";
import HeaderMenu from "../../../Components/Layout/HeaderMenu";
import { calculateReturnOrderFormData } from "./ReturnOrdersForm";
import VehicleInformation from "./VehicleInformation";
import askForTextAnswer from "../../../system/askForTextAnswer";
import AppointmentInformation from "../../../Components/AppointmentInformation";
import VinDisplay from "../../../Components/VinDisplay";
import Chip from "../../../Components/Chip";
import { translateCollectionType, translateReturnOrderDeliveryType } from "../../../system/translate";
import useUser from "../../../system/useUser";
import TerminatedReturnOrderTile from "../../Vehicles/TerminatedReturnOrderTile";
import confirm from "../../../Dialogs/confirm";
import { Email } from "@mui/icons-material";
import OrdersNavigation from "../../../Navigation/OrdersNavigation";
import AddressTextBlock from "Components/AddressTextBlock";

const ReturnOrderDetails = () => {
	const { id } = useParams<{ id: string }>();
	const [returnOrder, setReturnOrder] = useState<ReturnOrder>();
	const collectionDelayEmails = returnOrder?.emailCommunications ?? [];
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const history = useHistory();
	const theme = useTheme();
	const [, , hasRole] = useUser();
	const [newTerminationStatus, setNewTerminationStatus] = useState<Partial<ReturnOrderTerminationStatus>>();

	// cancellations should only be performed by "Einkauf"
	const isAldManager = hasRole(UserRole.AldManager);

	useAsyncEffect(async () => {
		if (!id || returnOrder) {
			return;
		}

		const { data: ro } = await axios.get<ReturnOrder[]>(`/api/orders/returns`, {
			params: {
				id: id,
			},
		});

		if (ro.length !== 1) {
			history.push("/orders/returns");
			return;
		}

		setReturnOrder(ro[0]);
	}, [id]);

	const handleCancellation = async () => {
		if (!returnOrder) {
			return;
		}

		const cancellationReason = await askForTextAnswer({
			text: "",
			required: true,
			label: "Stornierungsgrund",
			title: "Stornierung",
		});

		if (!cancellationReason) {
			return;
		}

		setIsSubmitting(true);

		try {
			const response = await axios.post<ReturnOrder>(`/api/orders/returns/${returnOrder.id}/cancel`, {
				reason: cancellationReason,
			});

			setReturnOrder(response.data);
		} finally {
			setIsSubmitting(false);
		}
	};

	const handleReactivation = async () => {
		if (!returnOrder) {
			return;
		}

		setIsSubmitting(true);

		try {
			const response = await axios.post<ReturnOrder>(`/api/orders/returns/${returnOrder.id}/reactivate`);

			setReturnOrder(response.data);
		} finally {
			setIsSubmitting(false);
		}
	};

	const handleTerminationReverse = async (returnOrder: ReturnOrder) => {
		if (!returnOrder) {
			return;
		}

		const terminationReverseReason = await confirm(
			"Soll die Aussetzung wirklich zurückgenommen werden?",
			"Aussetzung zurücknehmen",
		);

		if (!terminationReverseReason.ok) {
			return;
		}

		setIsSubmitting(true);

		try {
			const { data: savedReturnOrder } = await axios.post<ReturnOrder>(
				`/api/orders/returns/${returnOrder.id}/termination/revert`,
			);
			setReturnOrder(savedReturnOrder);
			setNewTerminationStatus(undefined);
		} finally {
			setIsSubmitting(false);
		}
	};

	const isCustomerDelivery = returnOrder?.delivery?.deliveryType === DeliveryType.CustomerDelivery;
	const customerDeliveryIndicators = [
		<VinDisplay vin={returnOrder?.vin} />,
		isCustomerDelivery ? <span style={{ color: theme.palette.secondary.light }}>Eigenanlieferung</span> : undefined,
	];

	const saveTerminationStatus = async (terminationStatus: Partial<ReturnOrderTerminationStatus>) => {
		setIsSubmitting(true);

		try {
			const { data: savedReturnOrder } = await axios.put<ReturnOrder>(
				`/api/orders/returns/${returnOrder?.id}/termination`,
				{
					reason: terminationStatus.reason,
					description: terminationStatus.description,
				},
			);

			setReturnOrder(savedReturnOrder);
		} finally {
			setIsSubmitting(false);
		}
	};

	const addTerminationStatus = async (terminationStatus: Partial<ReturnOrderTerminationStatus>) => {
		setIsSubmitting(true);

		try {
			const { data: savedReturnOrder } = await axios.post<ReturnOrder>(
				`/api/orders/returns/${returnOrder?.id}/termination`,
				{
					reason: terminationStatus.reason,
					description: terminationStatus.description,
				},
			);

			setReturnOrder(savedReturnOrder);
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<Layout
			title="Rückholung"
			plateNumber={returnOrder?.plateNumber}
			navigation={<OrdersNavigation />}
			subMenu={<OrdersSubMenu />}
		>
			{!returnOrder && <CircularProgress size={48} />}
			{returnOrder && (
				<>
					<Grid container justifyContent="space-between" alignItems="flex-end" spacing={2}>
						<Grid item>
							<AldVehicleHeader
								plateNumber={returnOrder.plateNumber}
								entries={[returnOrder.vehicle.type?.description]}
								additionalEntries={customerDeliveryIndicators}
							/>
						</Grid>
						<Grid item>
							<Grid container spacing={2} alignItems="center" justifyContent="space-between">
								<Grid item>
									<Actions>
										<HeaderMenu>
											<MenuItem
												onClick={() =>
													history.push("/orders/returns/new", {
														...calculateReturnOrderFormData(returnOrder, true),
														vehiclePlateNumber: returnOrder?.vehicle?.plateNumber,
													})
												}
											>
												Als Vorlage nutzen
											</MenuItem>
											<MenuItem
												onClick={() =>
													history.push(`/vehicles/${returnOrder.vehicle.id}/orders`)
												}
											>
												Zum Fahrzeug
											</MenuItem>
											{returnOrder.status === "Terminated" && isAldManager && (
												<MenuItem onClick={() => handleTerminationReverse(returnOrder)}>
													Aussetzung zurücknehmen
												</MenuItem>
											)}
										</HeaderMenu>
										{returnOrder.status !== "Cancelled" && isAldManager && (
											<ActionButton
												color="secondary"
												disabled={isSubmitting || returnOrder.status === "Imported"}
												onClick={handleCancellation}
											>
												Stornieren
											</ActionButton>
										)}
										{returnOrder.status === "Cancelled" && !returnOrder.transportOrderId && (
											<ActionButton
												color="secondary"
												disabled={isSubmitting}
												onClick={handleReactivation}
											>
												Reaktivieren
											</ActionButton>
										)}
									</Actions>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Box mt={4}>
						<Grid container spacing={6}>
							<Grid item xs={12} md={6}>
								<Tile title="Allgemeine Fahrzeuginformationen">
									<TileContent>
										<VehicleInformation returnOrder={returnOrder} />
									</TileContent>
								</Tile>
							</Grid>
							<Grid item xs={12} md={6}>
								<Tile title="Status">
									<TileContent>
										<Box mb={2}>
											<ReturnOrderStatusPill returnOrder={returnOrder} />
											{returnOrder.cancellationReason && (
												<Box mt={2}>
													<em>{returnOrder.cancellationReason}</em>
												</Box>
											)}
										</Box>
									</TileContent>

									<Box mt={2}>
										<TerminatedReturnOrderTile
											isLoading={isSubmitting}
											setIsLoading={setIsSubmitting}
											returnOrder={returnOrder}
											saveTerminationStatus={saveTerminationStatus}
											addTerminationStatus={addTerminationStatus}
											newTerminationStatus={newTerminationStatus}
											setNewTerminationStatus={setNewTerminationStatus}
										/>
									</Box>

									<TileActions>
										{!newTerminationStatus &&
											returnOrder.status === "Processed" &&
											!returnOrder.terminationStatus &&
											isAldManager && (
												<ActionButton
													variant="text"
													disabled={isSubmitting}
													title="Rückholung aussetzen"
													onClick={() => setNewTerminationStatus({})}
												>
													Aussetzen
												</ActionButton>
											)}
										{(returnOrder.status === "Created" || returnOrder.status === "Approved") && (
											<ActionButton
												variant="text"
												hideOnDisabled
												title="Bearbeiten"
												onClick={() => history.push(`/orders/returns/${returnOrder.id}/edit`)}
											>
												Bearbeiten
											</ActionButton>
										)}
										{returnOrder.importId && (
											<ActionButton
												variant="text"
												hideOnDisabled
												title="Zum Import"
												onClick={() => history.push(`/imports/${returnOrder?.importId}`)}
											>
												Zum Import
											</ActionButton>
										)}
										{returnOrder.status === "CustomerCreated" && (
											<ActionButton
												variant="text"
												hideOnDisabled
												title="Vervollständigen und Abschließen"
												onClick={() => history.push(`/orders/returns/${returnOrder.id}/edit`)}
											>
												Vervollständigen und Abschließen
											</ActionButton>
										)}
										{returnOrder.customerOrderId && (
											<ActionButton
												variant="text"
												hideOnDisabled
												title="Zum Kundenauftrag"
												onClick={() =>
													history.push(`/orders/customers/${returnOrder?.customerOrderId}`)
												}
											>
												Zum Kundenauftrag
											</ActionButton>
										)}
									</TileActions>
								</Tile>
							</Grid>
							{!isCustomerDelivery && (
								<Grid item xs={12} md={6}>
									<Tile
										title={`Abholung von ${translateCollectionType(
											returnOrder.collection.collectionType,
										)}`}
									>
										<TileContent>
											<AddressTextBlock address={returnOrder.collection.address} />
											<Box mt={2}>
												<TextBlock
													primary="Kontaktperson"
													secondary={`${returnOrder.collection.contact?.firstName} ${returnOrder.collection.contact?.lastName}`}
													tertiary={`${returnOrder.collection.contact?.email} - ${returnOrder.collection.contact?.telephone}`}
												/>
											</Box>
											{returnOrder.collection.comment && (
												<Box mt={2}>
													<TextBlock
														primary={""}
														secondary={returnOrder.collection.comment}
													/>
												</Box>
											)}
											{!isCustomerDelivery && returnOrder.collection.appointment && (
												<AppointmentInformation
													appointment={returnOrder.collection.appointment}
													initialAppointment={returnOrder.initialCollectionAppointment}
												/>
											)}
										</TileContent>
									</Tile>
								</Grid>
							)}
							{returnOrder.delivery && (
								<Grid item xs={12} md={6}>
									<Tile
										title={
											isCustomerDelivery
												? `Eigenanlieferung`
												: `Anlieferung an ${translateReturnOrderDeliveryType(
														returnOrder.delivery.deliveryType,
													)}`
										}
									>
										<TileContent>
											<AddressTextBlock address={returnOrder.delivery.address} />
											{returnOrder.delivery.contact && (
												<Box mt={2}>
													<TextBlock
														primary="Kontaktperson"
														secondary={`${returnOrder.delivery.contact?.firstName} ${returnOrder.delivery.contact?.lastName}`}
														tertiary={`${returnOrder.delivery.contact?.email} - ${returnOrder.delivery.contact?.telephone}`}
													/>
												</Box>
											)}
											{returnOrder.delivery.comment && (
												<Box mt={2}>
													<TextBlock primary={""} secondary={returnOrder.delivery.comment} />
												</Box>
											)}
											{returnOrder.delivery.appointment && (
												<AppointmentInformation
													appointment={returnOrder.delivery.appointment}
												/>
											)}
										</TileContent>
									</Tile>
								</Grid>
							)}
							<Grid item xs={12} md={6}>
								<Tile title="Gutachten">
									<TileContent>
										{!returnOrder.doAssessment && (
											<Typography component="div">
												<Box fontStyle="italic">nicht beauftragt</Box>
											</Typography>
										)}
										{returnOrder.doAssessment && returnOrder.assessment && (
											<>
												<TextBlock
													primary={getEnumName(
														AssessmentType,
														returnOrder.assessment.assessmentType,
													)}
													secondary={`an ${getEnumName(
														AssessmentLocation,
														returnOrder.assessment.assessmentLocation,
													)}`}
												/>
												{returnOrder.assessment.assessmentComment && (
													<Box mt={2}>{returnOrder.assessment.assessmentComment}</Box>
												)}
												{returnOrder.assessment.appointment && (
													<AppointmentInformation
														appointment={returnOrder.assessment.appointment}
													/>
												)}
											</>
										)}
										{returnOrder.assessmentIsPreprocessed && returnOrder.status === "Created" && (
											<Box mt={2}>
												<Chip label="Beauftragt" />
											</Box>
										)}
									</TileContent>
								</Tile>
							</Grid>
							{returnOrder.doAssessment && (
								<Grid item xs={12} md={6}>
									<Tile title="Gutachtenauftrag">
										<TileContent>
											{!returnOrder.assessmentOrderId && (
												<Typography component="div">
													<Box fontStyle="italic">noch nicht erstellt</Box>
												</Typography>
											)}
											{returnOrder.assessmentOrderId && (
												<Typography component="div">
													<Box fontStyle="italic">wurde erstellt</Box>
												</Typography>
											)}
										</TileContent>
										{returnOrder.assessmentOrderId && (
											<TileActions>
												<ActionButton
													variant="text"
													hideOnDisabled
													title="Details"
													onClick={() =>
														history.push(
															`/orders/assessments/${returnOrder?.assessmentOrderId}`,
														)
													}
												>
													Details
												</ActionButton>
											</TileActions>
										)}
									</Tile>
								</Grid>
							)}
							<Grid item xs={12} md={6}>
								<Tile title="Abmeldung">
									<TileContent>
										{!returnOrder.doUnregistration && (
											<Typography component="div">
												<Box fontStyle="italic">nicht beauftragt</Box>
											</Typography>
										)}
										{returnOrder.doUnregistration && <>wurde beauftragt</>}
									</TileContent>
								</Tile>
							</Grid>
							{returnOrder.doUnregistration && (
								<Grid item xs={12} md={6}>
									<Tile title="Abmeldeauftrag">
										<TileContent>
											{!returnOrder.unregistrationOrderId && (
												<Typography component="div">
													<Box fontStyle="italic">noch nicht erstellt</Box>
												</Typography>
											)}
											{returnOrder.unregistrationOrderId && (
												<Typography component="div">
													<Box fontStyle="italic">wurde erstellt</Box>
												</Typography>
											)}
										</TileContent>
										{returnOrder.unregistrationOrderId && (
											<TileActions>
												<ActionButton
													variant="text"
													hideOnDisabled
													title="Details"
													onClick={() =>
														history.push(
															`/orders/unregistrations/${returnOrder?.unregistrationOrderId}`,
														)
													}
												>
													Details
												</ActionButton>
											</TileActions>
										)}
									</Tile>
								</Grid>
							)}
							<Grid item xs={12} md={6}>
								<Tile title="Transportauftrag">
									<TileContent>
										{(!returnOrder.transportOrderId || returnOrder.status !== "Processed") && (
											<Typography component="div">
												<Box fontStyle="italic">noch nicht erstellt</Box>
											</Typography>
										)}
										{returnOrder.transportOrderId && returnOrder.status === "Processed" && (
											<Typography component="div">
												<Box fontStyle="italic">wurde erstellt</Box>
											</Typography>
										)}
									</TileContent>
									{returnOrder.transportOrderId && returnOrder.status === "Processed" && (
										<TileActions>
											<ActionButton
												variant="text"
												hideOnDisabled
												title="Details"
												onClick={() =>
													history.push(`/orders/transports/${returnOrder?.transportOrderId}`)
												}
											>
												Details
											</ActionButton>
										</TileActions>
									)}
								</Tile>
							</Grid>
							{collectionDelayEmails.length > 0 && (
								<Grid item xs={12} md={6}>
									<Tile title="Email Kommunikation">
										<TileContent>
											{collectionDelayEmails.map((email, i) => (
												<Box key={i} mt={i > 0 ? 2 : 0}>
													<TextBlock
														primary={
															<Box display="flex" flexDirection="row">
																<Box mr={1} display="flex">
																	<Email />
																</Box>
																<Box display="flex" alignItems="center">
																	{email.subject}
																</Box>
															</Box>
														}
														secondary={email.to.join(", ")}
														tertiary={moment(email.referenceCreated).format(
															dateFormats.dateTime,
														)}
													/>
												</Box>
											))}
										</TileContent>
									</Tile>
								</Grid>
							)}
							{returnOrder.salesType && (
								<Grid item xs={12} md={6}>
									<Tile title="Verkaufsinformationen">
										<TileContent>
											{(returnOrder.salesType === "Flex" || returnOrder.salesType === "Rkv") && (
												<TextBlock primary={returnOrder.salesType} secondary="Verkaufstyp" />
											)}
											{returnOrder.salesType === "NoSale" && (
												<TextBlock
													primary="Nicht frei zum Verkauf"
													secondary={returnOrder.salesTypeComment}
												/>
											)}
											{returnOrder.salesType === "Sale" && (
												<TextBlock primary="Frei zum Verkauf" />
											)}
										</TileContent>
									</Tile>
								</Grid>
							)}
							<Grid item xs={12} md={6}>
								<Tile title="Kostenverteilung">
									<TileContent>
										{returnOrder.transportCostInstallments && (
											<TextBlock
												primary={returnOrder.transportCostInstallments}
												secondary="Transportkosten in Rate kalkuliert"
											/>
										)}
										{returnOrder.costTransferCustomer && (
											<TextBlock
												primary={returnOrder.costTransferCustomer}
												secondary="Weiterbelastung an Kunden"
											/>
										)}
										{returnOrder.debitingAld && (
											<TextBlock
												primary={returnOrder.debitingAld}
												secondary="Kosten zu Lasten ALD"
											/>
										)}
										{!returnOrder.transportCostInstallments &&
											!returnOrder.costTransferCustomer &&
											!returnOrder.debitingAld && (
												<Typography component="div">
													<Box fontStyle="italic">nicht angegeben</Box>
												</Typography>
											)}
									</TileContent>
								</Tile>
							</Grid>
							<Grid item xs={12} md={6}>
								<Tile title="Historie">
									<TileContent>
										<TextBlock
											primary={`Erstellt: ${returnOrder.createdBy.name}`}
											secondary={moment(returnOrder.dateCreated).format(dateFormats.dateTime)}
										/>
										{returnOrder.updatedBy && (
											<Box mt={1}>
												<TextBlock
													primary={`Zuletzt angepasst: ${returnOrder.updatedBy.name}`}
													secondary={moment(returnOrder.dateUpdated).format(
														dateFormats.dateTime,
													)}
												/>
											</Box>
										)}
									</TileContent>
								</Tile>
							</Grid>
						</Grid>
					</Box>
				</>
			)}
		</Layout>
	);
};

export default ReturnOrderDetails;
