import moment from 'moment';

import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';

import FluentGrid from '../../Components/FluentGrid';
import InlineList from '../../Components/InlineList';
import TextBlock from '../../Components/Layout/TextBlock';
import ListItem from '../../Components/ListItem';
import ListItemAction from '../../Components/ListItemAction';
import ListItemActions from '../../Components/ListItemActions';
import ListItemChip from '../../Components/ListItemChip';
import ListItemChips from '../../Components/ListItemChips';
import ListItemContent from '../../Components/ListItemContent';
import {
    CatalogPreview, ThemeColor, Transmission, VehicleTypeProduction, VehicleTypeTransmission
} from '../../system/Domain';
import formatCurrency from '../../system/formatCurrency';
import formatNumber from '../../system/formatNumber';

interface Props {
	catalogPreview : CatalogPreview
	isSelected? : boolean
	onSelect? : (catalogPreview? : CatalogPreview) => void
}

export const formatTransmissionType = (type : Transmission) => {
	switch (type) {
		case "Automatic":
			return `Automatik`;
		case "Manual":
			return `Manuell`;
		case "Direct":
			return "Direkt";
		case "Unknown":
			return "Unbekannt";
	}
};

export const formatTransmission = (transmission : VehicleTypeTransmission) => {
	switch (transmission.type) {
		case "Automatic":
		case "Manual":
			return `${formatTransmissionType(transmission.type)} (${transmission.gears})`;
		case "Direct":
			return formatTransmissionType(transmission.type);
		case "Unknown":
			return formatTransmissionType(transmission.type);
	}
};

export const formatProductionRange = (production : VehicleTypeProduction) => {
	const dateFormat = "MMMM YYYY";

	// let text = `${moment(production.begin.date).format(dateFormat)}`;
	if (production.end) {
		return `${moment(production.begin.date).format(dateFormat)} - ${moment(production.end.date).format(dateFormat)}`;
		// text += ` - ${moment(production.end.date).format(dateFormat)}`;
	} else {
		return `${moment(production.begin.date).format(dateFormat)}`;
	}

	// return text;
};

export default ({ catalogPreview, isSelected, onSelect } : Props) => {
	let color : ThemeColor = "info";
	if (isSelected) {
		color = "primary";
	}

	const toggleSelected = () => onSelect?.(isSelected ? undefined : catalogPreview);
	const type = catalogPreview.type;

	return (
		<ListItem color={color}>
			{!!onSelect && (
				<ListItemActions color={color}>
					{!isSelected && (
						<ListItemAction
							icon={<CheckBoxOutlineBlank />}
							onClick={toggleSelected}
						/>
					)}
					{isSelected && (
						<ListItemAction
							icon={<CheckBoxOutlined />}
							onClick={toggleSelected}
						/>
					)}
				</ListItemActions>
			)}
			<ListItemContent color={color} onClick={toggleSelected}>
				<TextBlock
					primary={`${type.make.name} ${type.model.version} ${type.model.variant || ""}`.trim()}
					secondary={(
						<InlineList>
							{type.body.type}
							{type.engine.fuelType}
						</InlineList>
					)}
				/>
				<Box mt={2} mb={1}>
					<FluentGrid itemWidth={150}>
						{/*<TextBlock*/}
						{/*	primary={formatDate(type.production.begin.date)}*/}
						{/*	secondary="Produktionsbeginn"*/}
						{/*	reduced*/}
						{/*/>*/}
						{/*<TextBlock*/}
						{/*	primary={formatDate(type.production.end?.date)}*/}
						{/*	secondary="Produktionsende"*/}
						{/*	reduced*/}
						{/*/>*/}
						<TextBlock
							primary={`${formatNumber(type.engine.power)}kW / ${formatNumber(type.engine.horsePower)}PS`}
							secondary="Leistung"
							reduced
						/>
						<TextBlock
							primary={formatCurrency(type.costs.listPrice.netAmount)}
							secondary="Listenpreis (netto)"
							reduced
						/>
					</FluentGrid>
				</Box>
				<ListItemChips>
					<ListItemChip
						color="primary"
						label={catalogPreview.id}
					/>
					<ListItemChip
						color={!catalogPreview.type.production.end ? "success" : "warning"}
						label={formatProductionRange(catalogPreview.type.production)}
					/>
					<ListItemChip
						color="info"
						label={formatTransmission(catalogPreview.type.transmission)}
					/>
				</ListItemChips>
			</ListItemContent>
		</ListItem>
	);
}
