import axios from 'axios';
import _ from 'lodash';
import { useState } from 'react';
import { Add, Clear } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import TextBlock from '../../Components/Layout/TextBlock';
import ListItem from '../../Components/ListItem';
import ListItemAction from '../../Components/ListItemAction';
import ListItemActions from '../../Components/ListItemActions';
import ListItemContent from '../../Components/ListItemContent';
import { RecursivePartial, Sale, SaleOffer, ThemeColor } from '../../system/Domain';
import useSalesChannels from '../../system/useSalesChannels';
import { SalePropsWithSetter } from './SaleLayout';

export default ({ sale, setSale }: SalePropsWithSetter) => {
	const [openAvailableSalesChannels, setOpenAvailableSalesChannels] = useState(false);
	const [salesChannels] = useSalesChannels();

	const [isSubmitting, setIsSubmitting] = useState(false);

	const createOffer = async (newOffer: RecursivePartial<SaleOffer>) => {
		if (!newOffer || !newOffer?.channel) {
			return;
		}

		setIsSubmitting(true);

		try {
			const { data: updatedSale } = await axios.post<Sale>(`/api/sales/${sale.id}/offers`, newOffer);
			setOpenAvailableSalesChannels(false);
			setSale(updatedSale);
		} finally {
			setIsSubmitting(false);
		}
	};

	let color: ThemeColor = "info";
	if (openAvailableSalesChannels) {
		color = "primary";
	}

	const availableChannels = salesChannels?.filter(c => !sale.offers.some(o => o.channel.id === c.id)) || [];
	if (availableChannels.length === 0) {
		return null;
	}

	return (
		<ListItem
			transparent={!openAvailableSalesChannels}
			missing={isSubmitting}
			color={color}
		>
			<ListItemActions>
				{!openAvailableSalesChannels && (
					<ListItemAction
						icon={<Add />}
						onClick={() => setOpenAvailableSalesChannels(true)}
					/>
				)}
				{openAvailableSalesChannels && (
					<ListItemAction
						icon={<Clear />}
						onClick={() => setOpenAvailableSalesChannels(false)}
					/>
				)}
			</ListItemActions>
			<ListItemContent
				onClick={!openAvailableSalesChannels ? () => setOpenAvailableSalesChannels(true) : undefined}
			>
				{!openAvailableSalesChannels && (
					<Typography variant="subtitle2">Vertriebskanal hinzufügen</Typography>
				)}
				{openAvailableSalesChannels && (
					<>
						<TextBlock
							flat
							primary="Vertriebskanal hinzufügen"
						/>
						<Box mt={3} sx={{
							display: "flex",
							gap: 2,
							justifyContent: "space-evenly",
							flexWrap: "wrap",
						}}>
							{_.orderBy(availableChannels, c => c.name).map(c => (
								<Box key={c.id}>
									<Button
										variant="text"
										size="small"
										onClick={() => createOffer({ channel: c })}
									>
										{c.name}
									</Button>
								</Box>
							))}
						</Box>
					</>
				)}
			</ListItemContent>
		</ListItem>
	);
}
