import axios from "axios";
import { useCallback, useState } from "react";
import ThemedDialog from "../Components/ThemedDialog";
import { confirmable, createConfirmation } from "react-confirm";
import useAutoResetState from "../system/useAutoResetState";
import { Link, CircularProgress, TextField, Button, DialogActions, DialogContent, DialogTitle } from "@mui/material"

const DelegateReturnFormDialog = ({ order, show, proceed, dismiss, ...props }) => {
	const [name, setName] = useState("");
	const [returnForm, setReturnForm] = useState(props.returnForm);
	const [delegateToken, setDelegateToken] = useState();
	const [waiting, setWaiting] = useState(false);
	const [error, setError] = useAutoResetState(false, 1000);

	const onNameChanged = useCallback(
		e => setName(e.target.value),
		[]
	);

	const delegate = async () => {
		if (!name) {
			setError(true);
			return;
		}

		setWaiting(true);

		let form = returnForm;
		if (!form) {
			const { data: newReturnForm } = await axios.post(`/api/orders/transports/${order.id}/return-forms`, {});
			setReturnForm(newReturnForm);

			form = newReturnForm;
		}

		const { data: newDelegateToken } = await axios.post(`/api/orders/transports/${order.id}/return-forms/${form.id}/delegate`, { name });
		setDelegateToken(newDelegateToken);

		setWaiting(false);
	};

	const delegateLink = !!delegateToken
		? `${window.location.origin}/orders/transports/${order.id}/return-forms/${returnForm.id}?access_token=${delegateToken}`
		: null;

	return (
		<ThemedDialog open={show} maxWidth="sm" fullWidth onClose={proceed}>
			<DialogTitle>
				{!!props.returnForm
					? <span>{props.returnForm.title} an Fahrer delegieren</span>
					: <span>Neues Protokoll an Fahrer delegieren</span>
				}
			</DialogTitle>
			<DialogContent sx={{ display: "flex" }}>
				{waiting && <CircularProgress />}
				{!waiting && !delegateToken && (
					<TextField
						required
						fullWidth
						autoFocus
						error={error}
						value={name}
						onChange={onNameChanged}
						variant="outlined"
						label="Name des Fahrers"
						margin="normal"
					/>
				)}
				{!waiting && delegateToken && (
					<Link
						target="_blank"
						href={delegateLink}
						sx={{
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							overflow: 'hidden'
						}}
					>
						{delegateLink}
					</Link>
				)}
			</DialogContent>
			<DialogActions>
				{!delegateToken && (
					<>
						<Button
							color="secondary"
							onClick={dismiss}
						>
							Abbrechen
						</Button>
						<Button
							color="primary"
							disabled={waiting}
							onClick={delegate}
						>
							Weiter
						</Button>
					</>
				)}
				{delegateToken && (
					<>
						<Button
							color="primary"
							onClick={async () => {
								await navigator.clipboard.writeText(delegateLink);
								proceed();
							}}
						>
							Link kopieren
						</Button>
						<Button
							color="primary"
							onClick={proceed}
						>
							OK
						</Button>
					</>
				)}
			</DialogActions>
		</ThemedDialog>
	);
};

export default (options) => {
	const dialog = confirmable(props => <DelegateReturnFormDialog {...props} {...options} />);
	return createConfirmation(dialog)();
};
