import MenuItem from 'Components/Layout/MenuItem';

import {Add, AssignmentTurnedIn, FormatListNumbered} from '@mui/icons-material';

import { UserRole } from '../../system/Domain';
import useBillingsPermissions from '../Billings/useBillingPermissions';

export default () => {
	const billingsPermissions = useBillingsPermissions();

	return (
		<>
			<MenuItem
				roles={[UserRole.AldUser, UserRole.LotManager]}
				icon={<AssignmentTurnedIn />}
				name="Tätigkeiten"
				path="/vehicles/services"
				variant="secondary"
				matchProps={{
					exact: true
				}}
			/>
			{billingsPermissions.canView && (
				<MenuItem
					roles={[UserRole.AldUser, UserRole.AldManager, UserRole.ServiceAccounting]}
					icon={<FormatListNumbered />}
					name="Abrechnungen"
					path="/billings"
					variant="secondary"
				/>
			)}
			<MenuItem
				roles={[UserRole.AldUser]}
				icon={<Add />}
				name="Importe"
				path="/vehicles/services/imports"
				variant="secondary"
			/>
		</>
	);
};
