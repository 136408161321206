import axios from "axios";
import Thumbnail from "./Thumbnail";
import FormElement from "./FormElement";
import { useDropzone } from "react-dropzone";
import { FileReference } from "../system/Domain";
import { Form, FormOptions } from "../system/useForm";
import { alpha, Box, LinearProgress, Typography, useTheme } from "@mui/material";
import { AddAPhotoSharp, CloudUploadSharp, ErrorOutlineSharp } from "@mui/icons-material";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";

export type FormUploadProps = {
	name: string;
	label: string | ReactNode;

	form: Form;
	options?: FormOptions;
	previewUrl?: string;
	fullWidth?: boolean;
};

type Upload = {
	filename: string;
	progress: number;
};

export default ({ name, label, form, options, previewUrl, fullWidth = false }: FormUploadProps) => {
	const theme = useTheme();
	const darkTheme = theme.palette.mode === "dark";
	const color = darkTheme ? theme.palette.common.white : theme.palette.common.black;

	const rootStyles = {
		position: "relative",
		border: "2px dashed",
		borderColor: alpha(color, 0.12),
		borderRadius: 4,
		width: 300,
		height: 300,
		cursor: "pointer",
		color: alpha(color, 0.3),
		"&:hover": {
			borderColor: alpha(color, 0.3),
			color: alpha(color, 0.6),
		},
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",
		fontSize: "0.75rem",
		transition: "border 200ms ease-out, color 200ms ease-out",
		outline: "none",
		lineHeight: "1.9em",
		overflow: "hidden",
	};

	const errorStyles = {
		borderColor: alpha(theme.palette.error.dark, 0.75),
		color: theme.palette.error.dark,
		"&:hover": {
			borderColor: alpha(theme.palette.error.dark, 0.75),
			color: theme.palette.error.dark,
		},
	};

	const activeStyles = {
		borderColor: theme.palette.primary.main,
		color: theme.palette.primary.main,
		"&:hover": {
			borderColor: theme.palette.primary.main,
			color: theme.palette.primary.main,
		},
	};

	const fileReferenceStyles = {
		borderStyle: "solid",
		borderColor: alpha(color, 0.12),
		borderWidth: 1,
		"&:hover": {
			borderColor: alpha(color, 0.12),
		},
	};

	const readOnlyStyles = {
		cursor: "default",
		"&:hover": {
			borderColor: alpha(color, 0.12),
			color: alpha(color, 0.3),
		},
	};
	const initialMountRef = useRef(true);
	const [error, setError] = useState<string | null>(null);
	const [fileReference, setFileReference] = useState<FileReference>();

	const value = form.getValue(name);
	const deltaValue = form.getDeltaValue(name);

	useEffect(() => setFileReference(value), [value]);

	const [upload, setUpload] = useState<Upload | null>(null);

	useEffect(() => {
		if (!name) {
			return;
		}

		form.register(name, options);

		return () => {
			form.unregister(name);
		};
	}, [name]);

	useEffect(() => {
		if (initialMountRef.current) {
			initialMountRef.current = false;
		} else if (value !== fileReference) {
			form.setValue(name, fileReference, true);
		}
	}, [name, fileReference]);

	const onDrop = useCallback(async (acceptedFiles) => {
		if (acceptedFiles.length !== 1) {
			return;
		}

		const file = acceptedFiles[0];
		const filename = file.name;

		setError(null);
		setUpload({
			filename,
			progress: 0,
		});

		const formData = new FormData();
		formData.append("file", file);

		try {
			const response = await axios.post("/api/storage", formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
				onUploadProgress: (evt) => {
					setUpload({
						filename,
						progress: Math.ceil((evt.loaded / evt.total) * 100),
					});
				},
			});

			if (response.status !== 200) {
				throw new Error();
			}

			const reference = response.data;
			setFileReference(reference);
		} catch (e) {
			setError("Datei konnte nicht hochgeladen werden");
		} finally {
			setUpload(null);
		}
	}, []);

	const { getRootProps, getInputProps, isDragActive, draggedFiles } = useDropzone({
		disabled: form.readOnly,
		onDrop,
		multiple: false,
	});

	if (form.deltaMode && value && deltaValue && value.hash === deltaValue.hash) {
		return null;
	}

	return (
		<FormElement label={label}>
			<Box
				{...getRootProps()}
				sx={[
					((error && !isDragActive) || draggedFiles.length > 1 || form.errors[name]) && errorStyles,
					(isDragActive || fileReference || upload) && activeStyles,
					fileReference && !isDragActive && fileReferenceStyles,
					{ width: fullWidth ? "100%" : "unset" },
					form.readOnly && readOnlyStyles,
					rootStyles,
				]}
			>
				<input {...getInputProps()} capture />

				{error && !isDragActive && (
					<>
						<ErrorOutlineSharp
							sx={{
								position: "absolute",
								fontSize: "3rem",
							}}
						/>
						<Box sx={{ padding: theme.spacing(2) }}>{error}</Box>
					</>
				)}

				{!error && !upload && !fileReference && !isDragActive && (
					<>
						{previewUrl && (
							<Box sx={{ opacity: 0.2 }}>
								<Thumbnail width={300} height={300} url={previewUrl} />
							</Box>
						)}
						<AddAPhotoSharp
							sx={{
								position: "absolute",
								fontSize: "3rem",
							}}
						/>
						{!previewUrl && (
							<Box sx={{ padding: theme.spacing(2) }}>
								Datei per Drag and Drop hierher verschieben oder klicken um eine Datei auszuwählen
							</Box>
						)}
					</>
				)}

				{isDragActive &&
					(draggedFiles.length === 1 ? (
						<>
							<CloudUploadSharp
								sx={{
									position: "absolute",
									fontSize: "3rem",
								}}
							/>
							<Box sx={{ padding: theme.spacing(2) }}>Datei jetzt ablegen um den Upload zu starten</Box>
						</>
					) : (
						<>
							<ErrorOutlineSharp
								sx={{
									position: "absolute",
									fontSize: "3rem",
								}}
							/>
							<Box sx={{ padding: theme.spacing(2) }}>Es kann nur eine Datei hochgeladen werden</Box>
						</>
					))}

				{!error && !isDragActive && upload && (
					<>
						<CloudUploadSharp
							sx={{
								position: "absolute",
								fontSize: "3rem",
							}}
						/>
						<Box sx={{ padding: theme.spacing(2) }}>
							<Box>{upload.filename} wird hochgeladen</Box>
							<LinearProgress
								variant="determinate"
								sx={{
									width: "100%",
									marginTop: theme.spacing(1),
								}}
								value={upload.progress}
							/>
						</Box>
					</>
				)}

				{!error && !isDragActive && !upload && fileReference && (
					<Thumbnail
						width={300}
						height={300}
						asyncUrl={`/api/storage/${fileReference.hash}/thumbnail`}
						downloadUrl={`/api/storage/${fileReference.hash}`}
					/>
				)}
			</Box>
			{form.deltaMode &&
				value &&
				deltaValue &&
				value.hash !== deltaValue.hash &&
				!error &&
				!isDragActive &&
				!upload && (
					<Box mt={2}>
						<Box mb={0.25}>
							<Typography variant="caption" color="secondary">
								{label} · Vorheriges Bild
							</Typography>
						</Box>
						<Box
							sx={{
								width: fullWidth ? "100%" : "unset",
								borderColor: alpha(theme.palette.secondary.main, 0.12),
								rootStyles,
								...(form.readOnly && readOnlyStyles),
								cursor: "default",
							}}
						>
							<Thumbnail
								asyncUrl={`/api/storage/${deltaValue.hash}/thumbnail`}
								downloadUrl={`/api/storage/${deltaValue.hash}`}
							/>
						</Box>
					</Box>
				)}
		</FormElement>
	);
};
