import LayoutNavigation from "../Components/Layout/LayoutNavigation";
import OrdersNavigationSection from "./OrdersNavigationSection";
import RootNavigationSection from "./RootNavigationSection";
import { RecursivePartial, Vehicle } from "../system/Domain";
import VehicleNavigationSection from "./VehicleNavigationSection";

export default ({ vehicle }: { vehicle?: RecursivePartial<Vehicle> }) => {
	return (
		<LayoutNavigation>
			<VehicleNavigationSection vehicle={vehicle}/>
			<OrdersNavigationSection />
			<RootNavigationSection />
		</LayoutNavigation>
	);
};
