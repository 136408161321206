import { formatYesNo } from "Components/LeasmanContract";
import TextGroup from "Pages/VehicleSales/TextGroup";
import TextLine from "Pages/VehicleSales/TextLine";
import { EquipmentHighlightReference } from "system/Domain";
import useEquipmentHighlights from "system/useEquipmentHighlights";

interface Props {
	highlights?: EquipmentHighlightReference[]
	previousOwners?: number
}

export default ({ highlights, previousOwners }: Props) => {
	const [equipmentHighlights] = useEquipmentHighlights();

	if (!equipmentHighlights || !highlights || highlights.length === 0) {
		return null;
	}

	const categories: { isPresent: boolean, id: string, name: string }[] = [];

	if (!!previousOwners) {
		categories.push({
			id: "1",
			name: "1. Hand",
			isPresent: previousOwners === 1
		});
	}

	if (highlights) {
		categories.push(...equipmentHighlights
			.map(h => ({
				id: h.id,
				name: h.name,
				isPresent: !!highlights.find(c2 => c2.id === h.id)
			}))
		);
	}

	if (categories.length === 0) {
		return;
	}

	return (
		<TextGroup title="Highlights">
			{categories.filter(c => c.isPresent).map(c => (
				<TextLine key={c.id || c.name} label={c.name} value={formatYesNo(c.isPresent)} />
			))}
			{categories.filter(c => !c.isPresent).map(c => (
				<TextLine key={c.id || c.name} label={c.name} value={formatYesNo(c.isPresent)} />
			))}
		</TextGroup>
	);
}
