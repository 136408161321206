import axios from "axios";
import List from "Components/List";
import Tile from "Components/Tiles/Tile";
import { useState } from "react";
import useAxiosEffect from "system/useAxiosEffect";
import { PricingRequest } from "../../system/Domain";
import PricingRequestListItem from "./PricingRequestListItem";

interface Props {
	request?: PricingRequest
}

export default ({ request }: Props) => {
	const [relatedRequests, setRelatedRequests] = useState<PricingRequest[]>([]);

	useAxiosEffect(async (config) => {
		setRelatedRequests([]);

		if (!request?.contractId) {
			return;
		}

		const { data: relatedRequests } = await axios.get<PricingRequest[]>(`/api/pricing-requests`, {
			...config,
			params: {
				contractId: request.contractId,
				sort: "dateCreated:desc"
			}
		});

		setRelatedRequests(relatedRequests
			.filter(r => r.id !== request.id)
		);
	}, [request?.contractId]);

	if (relatedRequests.length === 0) {
		return null;
	}

	return (
		<Tile title="Bereits gestellte Anfragen">
			<List>
				{relatedRequests.map(r => (
					<PricingRequestListItem key={r.id} request={r} />
				))}
			</List>
		</Tile>
	);
}
