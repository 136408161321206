import { useState } from 'react';
import Carousel from 'react-material-ui-carousel';

import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { Box, Collapse, ListItem, ListItemText } from '@mui/material';

import VehiclePicture from '../../../Components/VehiclePicture';
import { FileReference } from '../../../system/Domain';
import formatCurrency from '../../../system/formatCurrency';
import { AssessmentDamage } from './AssessmentPage';

interface Props {
  damage: AssessmentDamage
}

const AssessmentDamageListItem = ({ damage }: Props) => {
  const [selectedPictures, setSelectedPictures] = useState<FileReference[]>([]);

  const handleClick = () => {
    setSelectedPictures(selectedPictures => {
      if (selectedPictures.length > 0) {
        return [];
      }
      return damage.attachments.map(d => d.file);
    })
  };

  const hasPictures = damage.attachments.length > 0;
  const open = selectedPictures.length > 0;

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemText
          primary={damage.text}
          secondary={formatCurrency(damage.repairCosts)}
        />
        {hasPictures && (
          <>
            {open ? <ExpandLess /> : <ExpandMore />}
          </>
        )}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box p={2}>
          <Carousel autoPlay={false}>
            {selectedPictures.map(s => {
              return (
                <VehiclePicture
                  key={s.hash}
                  picture={s}
                  thumbnail={false}
                />
              )
            })}
          </Carousel>
        </Box>
      </Collapse>
    </>
  );
};

export default AssessmentDamageListItem;
