import axios from "axios";
import Loading from "Components/Loading";
import RootNavigation from "Navigation/RootNavigation";
import { useState } from "react";
import { useParams } from "react-router";
import useAxiosEffect from "system/useAxiosEffect";
import Layout from "../../Components/Layout/Layout";
import { LeasmanAddress } from "../../system/Domain";
import LeasingCustomerVehiclesTile from "./LeasingCustomerVehiclesTile";

export default () => {
	const { id } = useParams<{ id: string }>();
	const [customer, setCustomer] = useState<LeasmanAddress>();

	useAxiosEffect(
		async (config) => {
			if (!id) {
				setCustomer(undefined);
				return;
			}

			const { data } = await axios.get<LeasmanAddress>(`/api/leasman/addresses/${id}`, config);
			setCustomer(data);
		},
		[id],
	);

	let title = "Leasing";
	if (customer) {
		title = `${title} · ${customer.fullName}`;
	}

	return (
		<Layout title={title} navigation={<RootNavigation />}>
			{!customer && <Loading />}
			{!!customer && <LeasingCustomerVehiclesTile customer={customer} />}
		</Layout>
	);
};
