import moment from 'moment';

import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import { ListItem, ListItemAvatar, ListItemText } from '@mui/material';

import formatCurrency from '../../system/formatCurrency';
import { aversiReconciliationStatus } from './AversiSessionReconciliationListItem';
import { AversiSessionItem, AversiSessionReconciliationItem } from './AversiSessionsPage';

interface Props {
	item: AversiSessionItem
	reconciliationItem?: AversiSessionReconciliationItem
	onClick?: () => void
}

const AversiSessionListItem = ({ item, reconciliationItem, onClick }: Props) => {
	let secondary = formatCurrency(item.totalAmount);

	if (reconciliationItem) {

		let paymentText = "unbezahlt";
		if (reconciliationItem?.paymentDate) {
			paymentText = `bezahlt am ${moment(reconciliationItem.paymentDate).format("L")}`
		}
		const text = aversiReconciliationStatus[reconciliationItem.status];

		if (text) {
			secondary = `${secondary} • ${text}`;
		}

		secondary = `${secondary} • ${paymentText}`;
	}

	const isOk = reconciliationItem && reconciliationItem.status === "Ok";

	return (
		<ListItem button onClick={onClick}>
			<ListItemAvatar>
				{isOk ? (
					<CheckCircle />
				) : (
					<RadioButtonUnchecked />
				)}
			</ListItemAvatar>
			<ListItemText
				primary={`${item.insuranceName} ${item.insuranceId}`}
				secondary={secondary}
			/>
		</ListItem>
	);
};

export default AversiSessionListItem;
