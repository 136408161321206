import List from "../../../Components/List";
import ListItem from "../../../Components/ListItem";
import ListItemContent from "../../../Components/ListItemContent";
import Tile from "../../../Components/Tiles/Tile";
import {
	AssessmentOrder,
	AssessmentOrderDefectItem,
	Assessor,
	UserRole
} from "../../../system/Domain";
import TextBlock from "../../../Components/Layout/TextBlock";
import { Dispatch, SetStateAction, useState } from "react";
import TileActions from "../../../Components/Tiles/TileActions";
import ActionButton from "../../../Components/ActionButton";
import TileContent from "../../../Components/Tiles/TileContent";
import useForm from "system/useForm";
import { Box, Typography } from "@mui/material";
import FormYesNo from "../../../Components/FormYesNo";
import FormDateTimePicker from "../../../Components/FormDateTimePicker";
import FormNumber from "../../../Components/FormNumber";
import moment from "moment";
import dateFormats from "system/dateFormats";
import useAssessors from "system/useAssessors";
import FormSelect from "../../../Components/FormSelect";
import useUser from "system/useUser";
import ExpertAssesmentDefectListItem from "./ExpertAssesmentDefectListItem";
import ListItemActions from "../../../Components/ListItemActions";
import ListItemAction from "../../../Components/ListItemAction";
import { Add } from "@mui/icons-material";
import FormText from "../../../Components/FormText";
import { SubjectInquiryFormType } from "./InquiryAssessmentTile";

interface Props {
	title: string
	assesment: AssessmentOrder
	isLoading?: boolean
	isEditingInterruption: boolean
	setIsEditingInterruption: Dispatch<SetStateAction<boolean>>
	save: (formData: AssesmentInterruptionFormType) => Promise<AssessmentOrder>
}

export interface AssesmentInterruptionFormType extends SubjectInquiryFormType {
	comment: string
}

const ExpertAssesmentTile = (props: Props) => {
	const localIdPrefix = 'local';
	const [user, , hasRole] = useUser();
	const [saving, setSaving] = useState(false);
	const [assessors] = useAssessors();
	const [newDefectItem, setNewDefectItem] = useState<Partial<AssessmentOrderDefectItem>>();
	const [currentId, setCurrentId] = useState(0);

	const getLocalId = () => {
		const itemId = currentId;
		setCurrentId(currentId + 1);
		return localIdPrefix + itemId;
	};

	const newAssessmentDefectItem = () => {
		setNewDefectItem({ id: getLocalId() });
	};

	const copyAssessmentOrderFromPropsToInterrupionDetails = () => {
		return {
			mileage: props.assesment?.defect?.mileage,
			hasCarsChequebook: props.assesment?.defect?.hasCarsChequebook,
			assessor: props.assesment?.defect?.assessor,
			dateLastMaintenance: props.assesment?.defect?.dateLastMaintenance || null,
			isReadyToDrive: props.assesment?.defect?.isReadyToDrive,
			defectItems: props?.assesment?.defect?.defectItems
				.filter((item) => item.id !== undefined)
				.map((item) => ({
					id: item.id,
					description: item.description,
					createdBy: item.createdBy,
					dateCreated: item.dateCreated,
					updatedBy: item.updatedBy,
					dateUpdated: item.dateUpdated
				})),
			comment: props.assesment?.defect?.comment
		};
	};

	const [assesmentInterruptionDetails, setAssesmentInterruptionDetails] = useState<AssesmentInterruptionFormType>(copyAssessmentOrderFromPropsToInterrupionDetails());

	const form = useForm({
		values: assesmentInterruptionDetails,
		setValues: setAssesmentInterruptionDetails
	});

	const save = async () => {
		setSaving(true);

		try {
			assesmentInterruptionDetails.defectItems.forEach(element => {
				if (element.id.startsWith(localIdPrefix)) {
					element.id = null;
				}
			});

			const responseData = await props.save(assesmentInterruptionDetails);
			form.setValue("defectItems", responseData.defect.defectItems);
		} finally {
			setSaving(false);
			props.setIsEditingInterruption(false);
		}
	};

	const onAddingNewItem = (defectItem: Partial<AssessmentOrderDefectItem>) => {
		if (!assesmentInterruptionDetails.defectItems) {
			assesmentInterruptionDetails.defectItems = [];
		}

		const newDefectItemIsValid = (defectItem: Partial<AssessmentOrderDefectItem>): defectItem is AssessmentOrderDefectItem => !!defectItem.description;

		if (!newDefectItemIsValid(defectItem)) {
			throw Error("New defect item is not valid!");
		}

		assesmentInterruptionDetails.defectItems.push(defectItem);
		form.setValue("defectItems", assesmentInterruptionDetails.defectItems);
		setNewDefectItem(undefined);
	};

	const onDeletingItem = (id: string) => {
		form.setValue("defectItems", assesmentInterruptionDetails.defectItems.filter((item) => item.id !== id));
	};

	const getAssessorLabel = (assessor: Assessor) => {
		if (!assessor) {
			return null;
		}

		return `${assessor.firstName} ${assessor.lastName}`;
	};

	const canModifyAssessment = hasRole(UserRole.AldManager) ||
		(hasRole(UserRole.DamageAssessor) && !props.assesment.defect) ||
		(props.assesment.defect &&
			props.assesment.defect?.createdBy.id === user.id &&
			moment(new Date(props.assesment.defect?.dateCreated)).format(dateFormats.date) === moment(new Date()).format(dateFormats.date));

	const editMode = props.isEditingInterruption;
	const cancel = () => {
		props.setIsEditingInterruption(false);
		// restore initial values
		setAssesmentInterruptionDetails(copyAssessmentOrderFromPropsToInterrupionDetails());
	};

	const cancelDefectOrder = () => {
		setNewDefectItem(undefined);
	};

	const canSave =
		assesmentInterruptionDetails.mileage !== undefined &&
		+assesmentInterruptionDetails.mileage > 0 &&
		assesmentInterruptionDetails.assessor &&
		assesmentInterruptionDetails.hasCarsChequebook !== undefined &&
		assesmentInterruptionDetails.isReadyToDrive !== undefined &&
		assesmentInterruptionDetails.defectItems &&
		assesmentInterruptionDetails.defectItems.length > 0;

	return (
		<Tile title={props.title}>
			<TileContent>
				<>
					{!editMode && (
						<>
							<Box>
								<TextBlock
									primary={getAssessorLabel(props.assesment?.defect?.assessor) ?? "nicht angegeben"}
									secondary="Gutachter"
								/>
							</Box>
							<Box mt={2}>
								<TextBlock
									primary={props.assesment.createdBy.name}
									secondary="Erstellt von"
								/>
							</Box>
							<Box mt={2}>
								<TextBlock
									primary={props.assesment?.defect?.mileage?.toString() ?? "nicht angegeben"}
									secondary="Letzter Kilometerstand"
								/>
							</Box>
							<Box mt={2}>
								<TextBlock
									primary={
										props.assesment?.defect?.dateLastMaintenance
											? moment(props.assesment?.defect?.dateLastMaintenance).format(dateFormats.date)
											: "nicht angegeben"
									}
									secondary="Letzte Wartung"
								/>
							</Box>
							<Box mt={2}>
								<FormYesNo
									name="hasCarsChequebook"
									label="Scheckheft gepflegt"
									form={form}
									disabled
									options={{ required: true }}
								/>
							</Box>
							<Box mt={2}>
								<FormYesNo
									name="isReadyToDrive"
									label="Fahrbereit"
									form={form}
									disabled
									options={{ required: true }}
								/>
							</Box>
						</>
					)}

					{editMode && (
						<>
							<Box>
								<FormSelect
									label="Gutachter"
									name="assessor.email"
									form={form}
									entries={assessors?.map((m) => {
										return {
											choice: m.email,
											label: getAssessorLabel(m)
										};
									})}
									options={{ required: true }}
								/>
							</Box>
							<Box mt={2}>
								<FormNumber
									name="mileage"
									label="Letzter Kilometerstand"
									form={form}
									inline
									options={{ required: true }}
									fullWidth
								/>
							</Box>
							<Box mt={2}>
								<FormDateTimePicker
									form={form}
									label="Letzte Wartung"
									format="DD.MM.YYYY"
									name="dateLastMaintenance"
									variant="date"
									disableFuture
									fullWidth
									minDate={moment(new Date().getDate()).toISOString()}
									views={["year", "month", "day"]}
								/>
							</Box>

							<Box mt={2}>
								<FormYesNo
									name="hasCarsChequebook"
									label="Scheckheft gepflegt"
									form={form}
									options={{ required: true }}
								/>
							</Box>

							<Box mt={2}>
								<FormYesNo
									name="isReadyToDrive"
									label="Fahrbereit"
									form={form}
									options={{ required: true }}
								/>
							</Box>
						</>
					)}

					{/* <Box mt={2}>
						<DefectItemControl defectable={assesmentInterruptionDetails} isInEditMode={editMode} form={form} />
					</Box> */}
					<Box mt={2} boxShadow={0}>
						<List boxShadow={0}>
							{assesmentInterruptionDetails?.defectItems?.map((defectItem) => (
								<ExpertAssesmentDefectListItem
									key={defectItem.id}
									defectItem={defectItem}
									disabled={!editMode || props.isLoading}
									onDeletingItem={onDeletingItem}
								/>
							))}

							{editMode && newDefectItem && (
								<ExpertAssesmentDefectListItem
									defectItem={newDefectItem}
									new
									cancel={cancelDefectOrder}
									onAddingNewItem={onAddingNewItem}
								/>
							)}

							{editMode && (
								<ListItem transparent boxShadow={0}>
									<ListItemActions>
										<ListItemAction
											icon={<Add />}
											onClick={newAssessmentDefectItem}
											disabled={props.isLoading}
										/>
									</ListItemActions>
									<ListItemContent onClick={newAssessmentDefectItem} boxShadow={0}>
										<Typography variant="subtitle2">
											Mängelposition hinzufügen
										</Typography>
									</ListItemContent>
								</ListItem>
							)}
						</List>
					</Box>

					{!editMode && (
						<TextBlock
							primary={props.assesment?.defect?.comment}
						/>
					)}
					{editMode && (
						<Box>
							<FormText
								name="comment"
								label="Kommentar"
								form={form}
								fullWidth
							/>
						</Box>
					)}
				</>
			</TileContent>

			{editMode && (
				<TileActions>
					<ActionButton
						variant="text"
						color="primary"
						onClick={cancel}
					>
						Abbrechen
					</ActionButton>
					<ActionButton
						variant="text"
						disabled={saving || !canSave}
						color="secondary"
						onClick={save}
					>
						Speichern
					</ActionButton>
				</TileActions>
			)}
		</Tile>
	);
};

export default ExpertAssesmentTile;
