import moment from 'moment';
import { ChangeEvent, Dispatch, SetStateAction, SyntheticEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link, LinkOff } from '@mui/icons-material';
import {
	Box, Checkbox, CircularProgress, TableBody, TableHead, TableRow, Tooltip
} from '@mui/material';

import TextBlock from '../../../Components/Layout/TextBlock';
import PaperTable from '../../../Components/PaperTable';
import { Plate } from '../../../Components/Plate';
import dateFormats from '../../../system/dateFormats';
import {
	AssessmentOrder, AssessmentOrdersOverviewPerspective, UserRole, Vehicle
} from '../../../system/Domain';
import AssessmentOrderStatusPill from './AssessmentOrderStatusPill';
import { Col, Colgroup, TableCell } from 'Components/BreakpointStyledComponents';
import axios from 'axios';
import useUser from 'system/useUser';

interface CommonProps {
	assessmentOrders: AssessmentOrder[]
	isLoading?: boolean
	noRowsMessage?: string
	align?: boolean
	vehicle?: Vehicle
	reloadVehicle?: (() => Promise<void>) | undefined;
}

type uncheckableProps = {
	checkable?: false
	perspective?: never
	checked?: never
	setChecked?: never
}

type checkableProps = {
	checkable: true
	perspective: AssessmentOrdersOverviewPerspective
	checked: string[]
	setChecked: Dispatch<SetStateAction<string[]>>
}

type SpecificProps = uncheckableProps | checkableProps

const AssessmentOrdersOverviewTable = (props: SpecificProps & CommonProps) => {
	const history = useHistory();
	const [, , hasRole] = useUser();
	const isAldAdmin = hasRole(UserRole.AldAdmin);
	const [isLinking, setIsLinking] = useState(false);

	const handleCheck = (e: SyntheticEvent, assessmentOrderId: string) => {
		if (!props.checked) {
			return;
		}

		e.stopPropagation();

		if (props.checked.includes(assessmentOrderId)) {
			const newChecked = props.checked.filter(f => f !== assessmentOrderId);

			props.setChecked(newChecked);
		} else {
			const newChecked = [...props.checked];

			newChecked.push(assessmentOrderId);

			props.setChecked(newChecked);
		}
	};

	const handleAllCheck = (e: ChangeEvent<HTMLInputElement>) => {
		if (!props.setChecked) {
			return;
		}

		if (e.target.checked) {
			props.setChecked(props.assessmentOrders.map(m => m.id));
		} else {
			props.setChecked([]);
		}
	};

	const linkAssesmentOrder = async (e: SyntheticEvent, assessmentOrderId: string) => {
		if (!props.vehicle || !assessmentOrderId || !props.reloadVehicle) {
			return;
		}

		e.stopPropagation();

		setIsLinking(true);
		try {
			await axios.put(`/api/vehicles/${props.vehicle.id}/remarketing/assessments/${assessmentOrderId}/link`);
		} finally {
			await props.reloadVehicle();
			setIsLinking(false);
		}
	};

	const unlinkAssesmentOrder = async (e: SyntheticEvent, assessmentOrderId: string) => {
		if (!props.vehicle || !assessmentOrderId || !props.reloadVehicle) {
			return;
		}

		e.stopPropagation();

		setIsLinking(true);
		try {
			await axios.put(`/api/orders/assessments/${assessmentOrderId}/unlink`);
		} finally {
			await props.reloadVehicle();
			setIsLinking(false);
		}
	};

	const showCheckboxColumn = props.perspective === "Unprocessed" && props.checkable === true;
	const colSpan = 4 + (showCheckboxColumn ? 1 : 0);

	return (
		<PaperTable>
			{props.align ?
				<TableHead>
					<TableRow>
						<TableCell xs sm>Gutachten</TableCell>
						<TableCell md lg xl sx={{ width: { md: "30%", lg: "20%", xl: "20%" } }}>Fahrzeug</TableCell>
						<TableCell xl sx={{ width: "10%" }}>Status</TableCell>
						<TableCell md lg xl sx={{ width: { md: "25%", lg: "25%", xl: "20%" } }}>Gutachtentyp</TableCell>
						<TableCell md lg xl>Adresse</TableCell>
					</TableRow>
				</TableHead>
				:
				<>
					<Colgroup md lg xl>
						{showCheckboxColumn && (
							<col width="auto" />
						)}
						<col width="auto" />
						<Col xl width="auto" />
						<col width="auto" />
						<col width="auto" />
					</Colgroup>
					<TableHead>
						<TableRow>
							{showCheckboxColumn && (
								<TableCell>
									<Checkbox
										checked={props.assessmentOrders.length === props.checked!.length}
										onChange={(e) => handleAllCheck(e)}
									/>
								</TableCell>
							)}
							<TableCell xs sm>Gutachten</TableCell>
							<TableCell md lg xl>Fahrzeug</TableCell>
							<TableCell xl>Status</TableCell>
							<TableCell md lg xl>Gutachtentyp</TableCell>
							<TableCell md lg xl>Adresse</TableCell>
						</TableRow>
					</TableHead>
				</>}
			<TableBody>
				{!props.isLoading && props.assessmentOrders && props.assessmentOrders.map(assessmentOrder => {
					const status = <AssessmentOrderStatusPill assessmentOrder={assessmentOrder} />;

					const assessment = <TextBlock
						primary={assessmentOrder.assessmentType}
						secondary={assessmentOrder.externalReference}
						tertiary={assessmentOrder.appointment?.notBefore ? moment(assessmentOrder.appointment?.notBefore).format(dateFormats.date) : undefined}
					/>;

					const assessmentAddress = <>
						<TextBlock
							primary={assessmentOrder.address.name}
							secondary={`${assessmentOrder.address.street} - ${assessmentOrder.address.zipCode}`}
							tertiary={`${assessmentOrder.address.city} - ${assessmentOrder.address.country}`}
						/>
						<TextBlock
							primary={""}
							secondary={assessmentOrder.address.additionalInformation}
						/>
					</>;

					return (
						<TableRow
							key={assessmentOrder.id}
							hover
							onClick={() => history.push(`/orders/assessments/${assessmentOrder.id}`)}
						>
							{!props.align && showCheckboxColumn && (
								<TableCell
									onClick={(e) => handleCheck(e, assessmentOrder.id)}
								>
									<Checkbox
										checked={props.checked!.includes(assessmentOrder.id)}
										onChange={(e) => handleCheck(e, assessmentOrder.id)}
									/>
								</TableCell>
							)}
							<TableCell>
								<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
									<Box>
										<Plate plateNumber={assessmentOrder.plateNumber} style={{ zoom: 0.6 }} />
										<TextBlock
											primary={!props.align && assessmentOrder.vehicle.type?.description}
											secondary={assessmentOrder.vin}
											compact
										/>
										<Box sx={{ display: { xs: "contents", xl: "none" } }}>
											<Box mt={2}>{status}</Box>
										</Box>
										<Box sx={{ display: { xs: "contents", md: "none" } }}>
											<Box mt={2}>{assessment}</Box>
										</Box>
										<Box sx={{ display: { xs: "contents", md: "none" } }}>
											<Box mt={2}>{assessmentAddress}</Box>
										</Box>
									</Box>
									{props.align && (
										<Box>
											{isLinking ?
												<CircularProgress size={24} /> :
												(props.vehicle?.remarketing?.assessment?.orderId === assessmentOrder.id ?
													<Tooltip title={"Gutachtenauftrag abtrennen"} disableHoverListener={!isAldAdmin}>
														<Link onClick={(e) => isAldAdmin ? unlinkAssesmentOrder(e, assessmentOrder.id) : undefined} />
													</Tooltip>
													:
													<Tooltip title={"Gutachtenauftrag verknüpfen"} disableHoverListener={!isAldAdmin}>
														<LinkOff onClick={(e) => isAldAdmin ? linkAssesmentOrder(e, assessmentOrder.id) : undefined} />
													</Tooltip>
												)}
										</Box>
									)}
								</Box>
							</TableCell>
							<TableCell xl>{status}</TableCell>
							<TableCell md lg xl>{assessment}</TableCell>
							<TableCell md lg xl>{assessmentAddress}</TableCell>
						</TableRow>
					)
				})}
				{props.isLoading === true && (
					<TableRow>
						<TableCell
							colSpan={colSpan}
							sx={{ textAlign: "center" }}
						>
							<CircularProgress />
						</TableCell>
					</TableRow>
				)}
				{props.assessmentOrders.length === 0 && !props.isLoading && (
					<TableRow>
						<TableCell
							colSpan={colSpan}
							sx={{ textAlign: "center" }}
						>
							{props.noRowsMessage}
						</TableCell>
					</TableRow>
				)}
			</TableBody>
		</PaperTable>
	);
};

export default AssessmentOrdersOverviewTable;
