import { PictureAsPdf } from "@mui/icons-material"
import ListItemChip from "Components/ListItemChip"
import { downloadFile } from "Pages/VehicleSales/SaleOfferListItem"
import { RepairOrder } from "system/Domain"

export interface Props {
	order: RepairOrder
}

export default ({ order }: Props) => {
	return (
		<ListItemChip
			icon={<PictureAsPdf />}
			label="Kostenvoranschlag"
			color={order.costEstimate?.status === "Accepted" ? "success" : "error"}
			onClick={() => downloadFile(order.costEstimate?.document?.hash)}
		/>
	);
};
