const formatter = new Intl.NumberFormat("de-DE", {
	maximumFractionDigits: 2
});

export default (value? : number, emptyPlaceholder : string = "-", suffix? : string, suffixSingular? : string) : string => {
	if (value === undefined || value === null) {
		return emptyPlaceholder;
	}

	const result = formatter.format(value);

	if (!!suffix || !!suffixSingular) {
		if (!!suffixSingular && value === 1) {
			return `${result} ${suffixSingular}`;
		}

		if (!!suffix) {
			return `${result} ${suffix}`;
		}
	}
	
	return result;
}
