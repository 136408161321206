import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import { Add, AttachFile, GetApp, RotateLeft } from '@mui/icons-material';
import { FormControl, FormControlLabel, InputLabel, MenuItem, Select } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import IndeterminateCheckbox from '../../../Components/IndeterminateCheckbox';
import Sidebar from '../../../Components/Sidebar/Sidebar';
import SidebarButton from '../../../Components/Sidebar/SidebarButton';
import SidebarForm from '../../../Components/Sidebar/SidebarForm';
import SidebarGroup from '../../../Components/Sidebar/SidebarGroup';
import Sort, { SortItem } from '../../../Components/Sort';
import { Compound, RemarketingStatus, TransportOrderPerspective, UserRole } from '../../../system/Domain';
import useLogisticCompanies from '../../../system/useLogisticCompanies';
import useOrderExport from '../../../system/useOrderExport';
import { OrderQuery } from '../../../system/useOrderQuery';
import useUser from '../../../system/useUser';
import uploadFileDialog from 'Pages/VehicleSales/uploadFileDialog';

export interface OrdersSidebarProps {
	query: OrderQuery
	setQuery: Dispatch<SetStateAction<OrderQuery>>
	resetQuery: () => OrderQuery
	compounds?: Compound[]
}

const sortItems: SortItem[] = [
	{
		title: "Zugewiesen",
		field: "dateAssigned"
	},
	{
		title: "Geplante Rückgabe",
		field: "plannedReturnPeriod"
	},
	{
		title: "Rückgabe",
		field: "returnDate"
	}
];

export default ({ query, setQuery, resetQuery, compounds }: OrdersSidebarProps) => {
	const [, , hasRole] = useUser();
	const history = useHistory();
	const [companies] = useLogisticCompanies();
	const [exportState, triggerExport] = useOrderExport(query);

	const setHasReturnOrder = (newValue: boolean | undefined) => {
		setQuery(g => ({
			...g,
			hasReturnOrder: newValue
		}));
	};

	const canSeeFullSidebar = !hasRole(UserRole.LogisticCompany);

	return (
		<Sidebar>
			<SidebarGroup>
				{canSeeFullSidebar && (
					<SidebarButton
						startIcon={<Add />}
						onClick={() => history.push('/orders/transports/new')}
						color="primary"
						label="Neuer Transportauftrag"
					/>
				)}
				{canSeeFullSidebar && (
					<SidebarButton
						startIcon={<Add />}
						onClick={() => history.push('/vehicles/arrivals/new')}
						color="primary"
						label="Neue Anlieferung"
					/>
				)}
			</SidebarGroup>
			<SidebarGroup>
				<SidebarForm>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Perspektive</InputLabel>
						<Select
							value={query.perspective}
							onChange={(e) => setQuery(q => ({
								...q,
								skip: 0,
								perspective: e.target.value as TransportOrderPerspective
							}))}
						>
							<MenuItem value="All">Alle</MenuItem>
							<MenuItem value="Active">Aktiv</MenuItem>
							<MenuItem value="Cancelled">Zurückgegeben</MenuItem>
							<MenuItem value="Unconfirmed">Unbestätigt</MenuItem>
							<MenuItem value="Confirmed">Bestätigt</MenuItem>
							<MenuItem value="ScheduledForReturn">Rückgabevorlauf</MenuItem>
							<MenuItem value="OverdueForReturn">Überfällige Rückgaben</MenuItem>
							<MenuItem value="Outbound">Auslieferungen</MenuItem>
							<MenuItem value="Completed">Abgeschlossen</MenuItem>
						</Select>
					</FormControl>
					<Sort
						items={sortItems}
						value={query.sort}
						onChange={sort => setQuery(q => ({
							...q,
							skip: 0,
							sort
						}))}
					/>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Auftragsart</InputLabel>
						<Select
							value={query.orderType || "All"}
							onChange={(e) => setQuery(q => ({
								...q,
								skip: 0,
								orderType: e.target.value !== "All" ? e.target.value : null
							}))}
						>
							<MenuItem value="All">Alle</MenuItem>
							<MenuItem value="CustomerDelivery">Eigenanlieferung</MenuItem>
							<MenuItem value="Delivery">Auslieferung</MenuItem>
							<MenuItem value="Arrival">Anlieferung</MenuItem>
							<MenuItem value="ServiceArrival">Logistiker Anlieferungen</MenuItem>
							<MenuItem value="Internal">Intern</MenuItem>
							<MenuItem value="CrossTransport">Quertransporte </MenuItem>
						</Select>
					</FormControl>
					{canSeeFullSidebar && (
						<FormControl variant="standard" fullWidth>
							<InputLabel>Dienstleister</InputLabel>
							<Select
								value={query.assignedTo || "-"}
								onChange={(e) => {
									let value = e.target.value as string | undefined;
									if (value === "-") {
										value = undefined;
									}

									setQuery(q => ({
										...q,
										skip: 0,
										assignedTo: value as RemarketingStatus
									}));
								}}
							>
								<MenuItem value="-">Alle</MenuItem>
								{companies?.map(c => (
									<MenuItem key={c.name} value={c.name}>{c.name}</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
					<FormControl fullWidth>
						<DatePicker
							format="DD.MM.YYYY"
							label="zugewiesen seit dem"
							value={moment(query.dateFrom)}
							slotProps={{ textField: { variant: 'standard' } }}
							onChange={(d: any) => setQuery(q => ({
								...q,
								dateFrom: (d || moment()).startOf("d").toISOString(true)
							}))}
						/>
					</FormControl>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Abholungsstandort</InputLabel>
						<Select
							value={query.pickupCompound || "-"}
							onChange={(e) => {
								let value = e.target.value as string | undefined;

								if (value === "-") {
									value = undefined;
								}

								setQuery(g => ({
									...g,
									skip: 0,
									pickupCompound: value
								}))
							}}
						>
							<MenuItem value="-">
								Alle
							</MenuItem>
							{compounds?.map(c =>
								<MenuItem value={c.name} key={c.name}>
									{c.name}
								</MenuItem>
							)}
						</Select>
					</FormControl>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Rückgabestandort</InputLabel>
						<Select
							value={query.destinationCompound || "-"}
							onChange={(e) => {
								let value = e.target.value as string | undefined;
								
								if (value === "-") {
									value = undefined;
								}

								setQuery(g => ({
								...g,
								skip: 0,
								destinationCompound: value
							}))}}
						>
							<MenuItem value="-">
								Alle
							</MenuItem>
							{compounds?.map(c =>
								<MenuItem value={c.name} key={c.name}>
									{c.name}
								</MenuItem>
							)}
						</Select>
					</FormControl>
					<FormControl fullWidth>
						<FormControlLabel
							control={<IndeterminateCheckbox value={query.hasReturnOrder} set={setHasReturnOrder} />}
							label="Basiert auf Rückholung"
						/>
					</FormControl>
				</SidebarForm>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarButton
					startIcon={<GetApp />}
					color="inherit"
					disabled={exportState.loading}
					onClick={triggerExport}
					label="Excel-Export"
				/>
				<SidebarButton
					startIcon={<RotateLeft />}
					color="inherit"
					disabled={exportState.loading}
					onClick={resetQuery}
					label="Filter zurücksetzen"
				/>
				<SidebarButton
					startIcon={<AttachFile />}
					label="Auslieferungen hochladen"
					disabled={!hasRole(UserRole.AldManager)}
					onClick={async () => await uploadFileDialog(`/api/imports/transport-orders/delivery`)}
				/>
			</SidebarGroup>
		</Sidebar>
	);
};
