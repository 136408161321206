import { useState } from "react";
import formatNumber from "../system/formatNumber";
import Image, { ImageViewMode } from "../Pages/Vehicles/PictureSets/Image";
import { Button, Grid, Typography, useTheme } from "@mui/material";
import { VehiclePicture, VehiclePictureSet } from "../system/Domain";
import { ImageUploadProps } from "../Pages/Vehicles/PictureSets/VehiclePictureSets";

export interface NamedVehiclePicture extends VehiclePicture {
	title: string
	name: string
}

export function* enumeratePictureSet(set: VehiclePictureSet | null): Generator<NamedVehiclePicture> {
	if (!!set) {
		yield {
			title: "Vorne Links",
			name: "vorne-links",
			...set.frontLeft!
		};
		yield {
			title: "Hinten Links",
			name: "hinten-links",
			...set.backLeft!
		};
		yield {
			title: "Hinten Rechts",
			name: "hinten-rechts",
			...set.backRight!
		};
		yield {
			title: "Vorne Rechts",
			name: "vorne-rechts",
			...set.frontRight!
		};
		yield {
			title: "Scheinwerfer",
			name: "scheinwerfer",
			...set.headlights!
		};
		yield {
			title: "Reifen und Felgen",
			name: "reifen-und-felgen",
			...set.tiresAndRims!
		};
		yield {
			title: "Innenraum vorne",
			name: "innenraum-vorne",
			...set.interiorFront!
		};
		yield {
			title: "Innenraum hinten",
			name: "innenraum-hinten",
			...set.interiorBack!
		};
		yield {
			title: "Kofferraum",
			name: "kofferraum",
			...set.trunk!
		};
		yield {
			title: "Cockpit",
			name: "cockpit",
			...set.cockpit!
		};
		yield {
			title: "Infotainment",
			name: "infotainment",
			...set.infotainment!
		};
		yield {
			title: "Instruments",
			name: "instruments",
			...set.instruments!
		};
	}
};

interface VehiclePictureListProps {
	vin: string
	pictureSet: VehiclePictureSet
	limit?: number
	setter?: (replaceImageProps: ImageUploadProps) => void
	replaceMode?: boolean
}

export default ({ vin, pictureSet, limit, replaceMode, setter }: VehiclePictureListProps) => {
	const theme = useTheme();
	const pictures = Array.from(enumeratePictureSet(pictureSet));

	const [showAll, setShowAll] = useState(false);

	return (
		<>
			{pictures.length === 0 && (
				<Typography>Keine Fotos vorhanden</Typography>
			)}
			{pictures.length > 0 && (
				<Grid container spacing={1}>
					{(!!limit && !showAll ? pictures.slice(0, Math.min(limit, pictures.length)) : pictures).map(picture => (
						<Grid item key={picture.name}>
							<Image
								key={`image-${pictureSet.id}-${picture.hash}`}
								sxProps={{
									borderRadius: theme.spacing(0.5),
									boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)"
								}}
								hash={picture.hash}
								filename={`${vin}-${picture.name}.jpg`}
								isInvalid={picture.isInvalid}
								setter={setter}
								viewMode={replaceMode ? ImageViewMode.Replace : ImageViewMode.Default}
								pictureName={picture.name}
							/>
						</Grid>
					))}
					{!!limit && !showAll && (
						<Grid item xs={12}>
							<Button
								variant="text"
								onClick={() => setShowAll(true)}
							>
								{formatNumber(pictures.length - limit)} weitere Fotos anzeigen
							</Button>
						</Grid>
					)}
				</Grid>
			)}
		</>
	);
}
