import RegistrationDocumentsNavigation from 'Navigation/RegistrationDocumentsNavigation';
import Layout from "../../../Components/Layout/Layout";
import Imports from "../../Imports/Imports";

const VehicleRegistrationDocumentHandoverImports = () => {
	return (
		<Layout
			title="Ausgänge"
			navigation={<RegistrationDocumentsNavigation />}
		>
			<Imports importType="VehicleRegistrationDocumentHandoversImport" />
		</Layout>
	);
};

export default VehicleRegistrationDocumentHandoverImports;
