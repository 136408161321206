import { Paper, useTheme } from "@mui/material";
import { PropsWithChildren } from "react";

interface TileContentProps {
	dense?: boolean
}

export default ({ children, dense }: PropsWithChildren<TileContentProps>) => {
	const theme = useTheme();
	return (
		<Paper sx={{
			padding: dense ? 0 : theme.spacing(2),
			overflow: "hidden",
			position: "relative"
		}}>
			{children}
		</Paper>
	);
};
