import { CustomerOrder } from "./Domain";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import axios from "axios";
import useAsyncEffect from "./useAsyncEffect";

const useCustomerOrder = (id?: string): [CustomerOrder | undefined, Dispatch<SetStateAction<CustomerOrder | undefined>>, boolean] => {
	const [customerOrder, setCustomerOrder] = useState<CustomerOrder>();
	const [hasLoaded, setHasLoaded] = useState<boolean>(false);

	const load = useCallback(async (id?: string) => {
		if (!id) return;

		const { data: co } = await axios.get<CustomerOrder[]>(`/api/orders/customers`, {
			params: {
				id: id
			}
		});

		setHasLoaded(true);

		if (co.length !== 1) {
			return;
		}

		setCustomerOrder(co[0]);
	}, [id]);

	useAsyncEffect(async () => {
		setCustomerOrder(undefined);
		setHasLoaded(false);
		await load(id);
	}, [id]);

	return [
		customerOrder,
		setCustomerOrder,
		hasLoaded
	];
};

export default useCustomerOrder;
