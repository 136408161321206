import { ReactNode } from "react";
import Typography from "@mui/material/Typography";
import { SxProps, Theme } from "@mui/material";

interface LayoutSubTitleProps {
	label: ReactNode
	sx?: SxProps<Theme>
}

export default ({ label, sx }: LayoutSubTitleProps) => {
	return (
		<Typography variant="h6" sx={[{ fontWeight: 300 }, ...(Array.isArray(sx) ? sx : [sx])]}>{label}</Typography>
	);
};
