import { useHistory, useLocation } from "react-router";

import { Videocam } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export default () => {
	const history = useHistory();
	const location = useLocation();
	const path = "/compounds/scan";

	return (
		<IconButton onClick={() => history.push(path)} color={location.pathname === path ? "secondary" : "inherit"}>
			<Videocam />
		</IconButton>
	);
};
