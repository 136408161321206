import { Box } from "@mui/material";
import TextBlock from "../../../Components/Layout/TextBlock";

interface Props {

}

const StepDeliveryInstructions = (props: Props) => {

	return (
		<Box>
			Sehr geehrter Kunde, bitte füllen Sie das nachfolgende Formular komplett aus.
			<br />
			<br />
			Bitte achten Sie vor allem auf korrekt ausgefüllte Kontaktdaten, da sonst der Auftrag nicht ordnungsgemäß ausgeführt werden kann.
			<br />
			<br />
			Für Rückfragen kontaktieren Sie bitte Ihren Kundenbetreuer.
			<br />
			<br />
			<TextBlock
				primary="Die Anlieferung muss an dem von Ihnen angegebenen Tag auf unserem Hof in Dorkmark erfolgen."
			/>
		</Box>
	);
}

export default StepDeliveryInstructions;
