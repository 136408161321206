import { Dispatch, SetStateAction, SyntheticEvent, useState } from 'react';
import { ReturnOrderSession } from "../../../../system/Domain";
import PaperTable from "../../../../Components/PaperTable";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import TextBlock from "../../../../Components/Layout/TextBlock";
import dateFormats from "../../../../system/dateFormats";
import moment from "moment";
import { GetApp, Link, LinkOff } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import parseContentDispositionFilename from "../../../../system/parseContentDispositionFilename";
import fileDownload from "js-file-download";
import ReturnOrderSessionStatusPill from "../ReturnOrderSessionStatusPill";
import ActionButton from 'Components/ActionButton';

interface Props {
	returnOrderSessions: ReturnOrderSession[]
	setReturnOrderSessions: Dispatch<SetStateAction<ReturnOrderSession[]>>
}

enum DataType {
	Assessment = "assessment",
	History = "history",
	Unregistration = "unregistration",
	AssessmentOverview = "assessmentOverview"
}

const ReturnOrderSessionsOverviewTable = (props: Props) => {
	const history = useHistory();
	const [isProcessing, setIsProcessing] = useState(false);

	const handleDownloadFile = async (e: SyntheticEvent, id: string, requestedFile: string) => {
		e.stopPropagation();
		if (!id) return;

		const response = await axios.get<Blob>(`/api/orders/returns/sessions/${id}/${requestedFile}`, {
			responseType: "blob"
		});

		handleDownload(response);
	};

	const setDateDocsTransfer = async (e: any, id: string) => {
		e.stopPropagation();
		if (!id) return;
		setIsProcessing(true);

		try {
			const { data: returnOrderSession } = await axios.patch(`/api/orders/return/sessions/${id}/set-date-docs-transfer`);

			props.setReturnOrderSessions(sessions => sessions
				.map(s => s.id === returnOrderSession.id ? returnOrderSession : s)
			);
		}
		finally {
			setIsProcessing(false);
		}
	};

	const handleDownload = (response: AxiosResponse<Blob>) => {
		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);
	};

	return (
		<PaperTable>
			<colgroup>
				<col width="auto" />
				<col width="20%" />
				<col width="20%" />
				<col width="20%" />
				<col width="20%" />
				<col width="20%" />
				<col width="auto" />
			</colgroup>
			<TableHead>
				<TableRow>
					<TableCell>Status</TableCell>
					<TableCell>Session</TableCell>
					<TableCell>Gutachten</TableCell>
					<TableCell>Fahrzeughistorie</TableCell>
					<TableCell>Gutachtenübersicht</TableCell>
					<TableCell>Abmeldung</TableCell>
					<TableCell>Erstellt von</TableCell>
					<TableCell>Aktionen</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{props.returnOrderSessions && props.returnOrderSessions.map(session => (
					<TableRow
						key={session.id}
						hover
						onClick={session.shouldBeAccessible ? () => history.push(`/orders/returns/sessions/${session.id}`) : undefined}
					>
						<TableCell>
							<ReturnOrderSessionStatusPill returnOrderSession={session} />
						</TableCell>
						<TableCell>
							{session.shouldBeAccessible && (
								<TextBlock
									primary={<Link />}
									secondary="zur Sitzung"
								/>
							)}
							{!session.shouldBeAccessible && (
								<TextBlock
									primary={<LinkOff />}
								/>
							)}
						</TableCell>
						<TableCell>
							{session.status === "Completed" && session.containsAssessments && (
								<TextBlock
									primary={<GetApp onClick={(e) => handleDownloadFile(e, session.id, DataType.Assessment)} />}
								/>
							)}
						</TableCell>
						<TableCell>
							{session.status === "Completed" && session.containsAssessments && (
								<TextBlock
									primary={<GetApp onClick={(e) => handleDownloadFile(e, session.id, DataType.History)} />}
								/>
							)}
						</TableCell>
						<TableCell>
							{session.status === "Completed" && session.containsAssessments && (
								<TextBlock
									primary={<GetApp onClick={(e) => handleDownloadFile(e, session.id, DataType.AssessmentOverview)} />}
								/>
							)}
						</TableCell>
						<TableCell>
							{session.status === "Completed" && session.containsUnregistrations && (
								<GetApp onClick={(e) => handleDownloadFile(e, session.id, DataType.Unregistration)} />
							)}
						</TableCell>
						<TableCell>
							<TextBlock
								primary={session.createdBy.name}
								secondary={moment(session.dateCreated).format(dateFormats.dateTime)}
							/>
						</TableCell>
						<TableCell>
							{(session.status === "Completed" && !session.dateDocumentsTransferred) && (
								<ActionButton variant="text" color="primary" disabled={isProcessing} onClick={(e) => setDateDocsTransfer(e, session.id)}>
									Abschliessen
								</ActionButton>
							)}
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</PaperTable>
	);
};

export default ReturnOrderSessionsOverviewTable;
