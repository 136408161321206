import { useState } from "react";
import ThemedDialog from "../Components/ThemedDialog";
import { confirmable, createConfirmation } from "react-confirm";
import { TextField, Button, DialogActions, DialogContent, DialogTitle} from "@mui/material";

const NoteDialog = ({ show, proceed }) => {
	const [text, setText] = useState("");

	return (
		<ThemedDialog open={show} maxWidth="sm" fullWidth onClose={proceed}>
			<DialogTitle>
				Notiz hinzufügen
			</DialogTitle>
			<DialogContent>
				<TextField
					variant="outlined"
					label="Bemerkung"
					required
					fullWidth
					value={text}
					onChange={event => setText(event.target.value)}
					autoFocus
					margin="normal"
					multiline
					rows="4"
				/>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="secondary"
					href=""
					onClick={proceed}
				>
					Abbrechen
				</Button>
				<Button
					variant="outlined"
					color="primary"
					href=""
					onClick={() => proceed({ ok: 1, text })}
				>
					OK
				</Button>
			</DialogActions>
		</ThemedDialog>
	);
};

export default (options) => {
	const dialog = confirmable(props => <NoteDialog {...props} {...options} />);
	return createConfirmation(dialog)();
};
