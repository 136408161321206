import { useState } from "react";
import {
	PoolFleetCategory,
	PoolFleetProduct,
	PoolFleetVehicle,
} from "../../system/Domain";
import { Box, FormControlLabel, Paper } from "@mui/material";
import { useAsync } from "react-use";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
interface Props {
	vehicle: PoolFleetVehicle;
	onVehicleChange?: (vehicle: PoolFleetVehicle) => void;
}

function PoolFleetVehicleCategoriesCard({ vehicle, onVehicleChange }: Props) {
	const [busy, setBusy] = useState(false);

	const { value: products } = useAsync(async () => {
		const { data: products } = await axios.get<PoolFleetProduct[]>(`/api/pool-fleet/products`);
		return products;
	}, []);

	function handleCheckboxChange(product: PoolFleetProduct, category: PoolFleetCategory) {
		if (onVehicleChange) {
			return async function handleCheckboxChange() {
				setBusy(true);

				const { data: poolFleetVehicle } =
					await axios.post<PoolFleetVehicle>(
						`/api/pool-fleet/vehicles/${vehicle.contractId}/categories`,
						{
							productName: product.name,
							categoryName: category.name,
						}
					);

				onVehicleChange(poolFleetVehicle);
				setBusy(false);
			};
		}
	}

	return (
		<Box>
			<Typography gutterBottom variant="h5">
				Kategorien
			</Typography>
			<Paper sx={{ padding: 2 }}>
				{products &&
					products.map((product) => {
						return (
							<Box key={product.name} p={2}>
								<Typography
									variant="h6"
									sx={{
										position: "relative",
										overflow: "hidden",
										textAlign: "left",
										"&:after": {
											position: "absolute",
											top: "50%",
											overflow: "hidden",
											width: "98%",
											height: 1,
											content: '""',
											backgroundColor: "#cccccc",
											marginLeft: "2%"
										}
									}}
								>
									{product.name}
								</Typography>
								<Box mt={2}>
									{product.categories.map((c) => {
										const productCategory = vehicle.categories[product.name.toLowerCase()];
										const hasCategory = productCategory === c.name;

										return (
											<Box key={c.name}>
												<FormControlLabel
													label={c.name}
													control={
														<Checkbox
															readOnly={busy || !onVehicleChange}
															checked={hasCategory}
															onChange={handleCheckboxChange(product, c)}
														/>
													}
												/>
											</Box>
										);
									})}
								</Box>
							</Box>
						);
					})}
			</Paper>
		</Box>
	);
}

export default PoolFleetVehicleCategoriesCard;
