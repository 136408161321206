import { Box, Button, CircularProgress, Grid, MobileStepper, Paper, Step, StepContent, StepLabel, Stepper, useTheme } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import axios from "axios";
import VinDisplay from "Components/VinDisplay";
import { ReactNode, useState } from 'react';
import { useHistory } from "react-router-dom";
import AldVehicleHeader from "../../../Components/AldVehicleHeader";
import TextBlock from "../../../Components/Layout/TextBlock";
import { AssessmentOrder, Vehicle, VehicleAddresses } from "../../../system/Domain";
import useAsyncEffect from "../../../system/useAsyncEffect";
import useForm, { Form } from "../../../system/useForm";
import { joinDisplay } from '../Returns/ReturnOrderUtils';
import StepAdditional from "./StepAdditional";
import StepAssessment from "./StepAssessment";

interface Props {
	vehicle: Vehicle
	formData?: AssessmentOrderFormType
	id?: string
	stepProps?: any
}

interface Step {
	title: string
	ui: (props: any) => ReactNode
}

interface StepProps {
	form: Form
	addresses: VehicleAddresses
}

const steps: Step[] = [
	{
		title: "Gutachten",
		ui: (props: StepProps) => <StepAssessment {...props} />
	}, {
		title: "Allgemeines",
		ui: (props: StepProps) => <StepAdditional {...props} />
	}
]

export interface AssessmentOrderFormType {
	doUnregistration?: boolean
	isPoolfleet?: boolean
	assessmentType?: string
	assessmentLocationOrigin?: string
	address?: {
		name?: string
		street?: string
		additionalInformation?: string
		zipCode?: string
		city?: string
		country?: string
	}
	contact?: {
		firstName?: string
		lastName?: string
		email?: string
		telephone?: string
	}
	appointment?: {
		notBefore?: string
		from?: string
		to?: string
	}
	transportCostInstallments?: string
	costTransferCustomer?: string
	debitingAld?: string
	comment?: string
}

export const calculateAssessmentOrderFormData = (assessmentOrder: AssessmentOrder): AssessmentOrderFormType => {
	return {
		doUnregistration: assessmentOrder.doUnregistration,
		isPoolfleet: assessmentOrder.isPoolfleet,
		assessmentType: assessmentOrder.assessmentType,
		assessmentLocationOrigin: assessmentOrder.assessmentLocationOrigin,
		address: assessmentOrder.address,
		contact: assessmentOrder.contact,
		appointment: assessmentOrder.appointment,
		transportCostInstallments: assessmentOrder.transportCostInstallments,
		costTransferCustomer: assessmentOrder.costTransferCustomer,
		debitingAld: assessmentOrder.debitingAld,
		comment: assessmentOrder.comment
	};
};

const AssessmentOrdersForm = (props: Props) => {
	const history = useHistory();
	const theme = useTheme();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [vehicleAddresses, setVehicleAddresses] = useState<VehicleAddresses>();
	const [assessmentOrderForm, setAssessmentOrderForm] = useState<AssessmentOrderFormType>(props.formData ?? {
		address: {
			country: "Deutschland"
		}
	});

	const form = useForm({
		values: assessmentOrderForm,
		setValues: setAssessmentOrderForm,
		deltaValues: null
	});

	const editMode = Boolean(props.id);

	useAsyncEffect(async () => {
		if (!props.vehicle?.id) {
			return;
		}

		const { data } = await axios.get<VehicleAddresses>(`/api/vehicles/${props.vehicle.id}/addresses`);

		setVehicleAddresses(data);
	}, [props.vehicle?.id]);

	const [activeStep, setActiveStep] = useState(0);
	const maxSteps = steps.length;
	const isLastStep = activeStep === steps.length - 1;
	const isFirstStep = activeStep === 0;

	const handleNext = () => {
		const validated = form.validateAll();
		console.log('hanling next, valid is', validated)

		if (validated) {
			if (!isLastStep) {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			} else {
				if (editMode) {
					completeUpdate();
				} else {
					completeCreate();
				}
			}
		}
	};

	const completeCreate = async () => {
		setIsSubmitting(true);

		try {
			await axios.post(`/api/orders/assessments`, {
				...assessmentOrderForm,
				vehicleId: props.vehicle.id
			});
		} finally {
			setIsSubmitting(false);
		}

		history.push(`/orders/assessments`);
	};

	const completeUpdate = async () => {
		setIsSubmitting(true);
		try {
			await axios.put(`/api/orders/assessments/${props.id}`, assessmentOrderForm);
		} finally {
			setIsSubmitting(false);
		}

		history.push(`/orders/assessments`);
	};

	const handleBack = () => {
		if (activeStep === 0) {
			if (editMode) {
				history.push(`/orders/assessments/${props.id}`)
			} else {
				history.push("/orders/assessments")
			}
		}

		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const rkvIndicator = vehicleAddresses?.isRkv ? <span style={{ color: theme.palette.secondary.light }}>RKV Fahrzeug</span> : undefined;
	const serviceAgentInformation = joinDisplay([props.vehicle.remarketing.leasmanContract?.serviceAgent?.name, props.vehicle.remarketing.leasmanContract?.serviceAgent?.name, props.vehicle.remarketing.leasmanContract?.serviceAgent?.telephone]);

	return (
		<>
			{!vehicleAddresses && (
				<CircularProgress size={48} />
			)}
			{vehicleAddresses && (
				<Box>
					<Grid container style={{ width: "auto" }} spacing={2} alignItems="flex-end" justifyContent="space-between">
						<Grid item>
							<AldVehicleHeader
								plateNumber={props.vehicle.plateNumber}
								entries={[props.vehicle.type?.description, <VinDisplay vin={props.vehicle.vin} />]}
								additionalEntries={[rkvIndicator]}
							/>
						</Grid>
					</Grid>
					<Box mt={1}>
						<TextBlock
							secondary={serviceAgentInformation}
						/>
					</Box>
					<Box mt={2}>
						<Paper>
							<Box p={2}>
								<Stepper activeStep={activeStep} orientation="vertical">
									{steps.map((step, index) => (
										<Step key={index}>
											<StepLabel>
												{step.title}
											</StepLabel>
											<StepContent>
												{step.ui({
													form: form,
													addresses: vehicleAddresses,
													...props.stepProps
												})}
											</StepContent>
										</Step>
									))}
								</Stepper>
							</Box>
						</Paper>
					</Box>
					<MobileStepper
						style={{ position: "absolute", bottom: 0, left: 0, width: "100%" }}
						steps={maxSteps}
						position="static"
						variant="progress"
						activeStep={activeStep}
						nextButton={
							isSubmitting ? (
								<CircularProgress size={24} />
							) : (
								<Button size="small" onClick={handleNext} disabled={activeStep === maxSteps}>
									{!isLastStep ? "Weiter" : editMode ? "Auftrag anpassen" : "Auftrag erstellen"}
									{<KeyboardArrowRight />}
								</Button>
							)
						}
						backButton={
							<Button size="small" onClick={handleBack} disabled={activeStep < 0}>
								{<KeyboardArrowLeft />}
								{!isFirstStep ? "Zurück" : "Abbrechen"}
							</Button>
						}
					/>
				</Box>
			)}
		</>
	);
};

export default AssessmentOrdersForm;
