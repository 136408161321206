import axios from "axios";
import { useState } from "react";
import { Clear, Delete, Done } from "@mui/icons-material";
import { Box, Button, useTheme } from "@mui/material";
import FluentGrid from "../../Components/FluentGrid";
import TextBlock from "../../Components/Layout/TextBlock";
import ListItem from "../../Components/ListItem";
import ListItemAction from "../../Components/ListItemAction";
import ListItemActions from "../../Components/ListItemActions";
import ListItemChip from "../../Components/ListItemChip";
import ListItemChips from "../../Components/ListItemChips";
import ListItemContent from "../../Components/ListItemContent";
import { Plate } from "../../Components/Plate";
import { RepairOrder, RepairOrderStatus, RepairWork, ThemeColor } from "../../system/Domain";
import useAction from "../../system/useAction";
import { RepairOrderViewModeBase } from "./DekraRepairOrderListItem";
import RepairOrderCompleteButton from "./RepairOrderCompleteButton";
import RepairOrderStatusHistory from "./RepairOrderStatusHistory";
import { translateRepairOrderStatus } from "system/translate";

export const formatRepairTask = (task?: RepairWork | null) => {
	if (!task) {
		return "-";
	}

	switch (task.repairTask) {
		case "None":
			return "Keine Reparatur";
		case "WindshieldReplacement":
			return "Windschutzscheibe Austausch";
		case "WindshieldSmartRepair":
			return "Windschutzscheibe SmartRepair";
		default:
			return task.description;
	}
};

export const formatRepairOrderStatus = (status: RepairOrderStatus) => {
	return translateRepairOrderStatus(status) ?? status;
};

export const paintRepairOrderStatus = (status: RepairOrderStatus): ThemeColor => {
	switch (status) {
		case "Created":
		case "Assigned":
			return "info";
		case "Ordered":
		case "Assignable":
			return "primary";
		case "Cancelled":
			return "warning";
		case "Completed":
			return "success";
		default:
			return "info";
	}
};

export enum ViewModeCarglass {
	Overview = 7,
}

export type ViewMode = RepairOrderViewModeBase | ViewModeCarglass;

interface Props {
	compact?: boolean;
	order: RepairOrder;
	viewMode?: ViewMode;
	defaultViewMode: ViewMode;

	setOrder?(order: RepairOrder): void;
}

export default ({ order, viewMode, setOrder, defaultViewMode, compact = false }: Props) => {
	const theme = useTheme();
	const [mode, setMode] = useState<ViewMode>(viewMode ?? defaultViewMode);

	let color: ThemeColor = "info";
	switch (mode) {
		case RepairOrderViewModeBase.Cancel:
			color = "warning";
			break;
		case RepairOrderViewModeBase.Complete:
			color = "primary";
			break;
		case RepairOrderViewModeBase.UndoCostEstimation:
			color = "error";
			break;
		case RepairOrderViewModeBase.View:
		default:
			color = "info";
			break;
	}

	const [cancelOrder, isCancelling] = useAction(async () => {
		const { data: responseData } = await axios.post<RepairOrder>(`/api/orders/repairs/${order.id}/cancel`, {});

		if (setOrder) {
			setOrder(responseData);
		}

		setMode(defaultViewMode);
	});

	return (
		<ListItem color={color}>
			<ListItemActions>
				{(mode === RepairOrderViewModeBase.View || mode === ViewModeCarglass.Overview) && (
					<>
						{order.status === "Ordered" && (
							<ListItemAction icon={<Done />} onClick={() => setMode(RepairOrderViewModeBase.Complete)} />
						)}
						{(order.status === "Created" || order.status === "Assigned") && (
							<ListItemAction icon={<Delete />} onClick={() => setMode(RepairOrderViewModeBase.Cancel)} />
						)}
					</>
				)}
				{(mode === RepairOrderViewModeBase.Cancel ||
					mode === RepairOrderViewModeBase.Complete ||
					mode === RepairOrderViewModeBase.UndoCostEstimation ||
					mode === RepairOrderViewModeBase.StatusHistory) && (
					<ListItemAction icon={<Clear />} onClick={() => setMode(defaultViewMode)} />
				)}
			</ListItemActions>
			<ListItemContent>
				{!compact && (
					<TextBlock
						primary={<Plate plateNumber={order.plateNumber} style={{ zoom: 0.6 }} />}
						secondary={`${order.vehicle.type?.make.name} ${order.vehicle.type?.model.version} ${order.vehicle.type?.model.variant}`}
					/>
				)}
				{compact && (
					<TextBlock
						primary={order.repairWorks.map((t) => formatRepairTask(t)).join(", ")}
						secondary={order?.company?.name ?? "Nicht zugewiesen"}
					/>
				)}
				<Box mt={2} mb={1}>
					<FluentGrid>
						{mode !== ViewModeCarglass.Overview && !compact && (
							<TextBlock primary={order.vin} secondary="FIN" reduced />
						)}
						{order?.comment && (
							<Box sx={{ gridColumn: "span 2" }}>
								<TextBlock primary={order?.comment} secondary="Kommentar" reduced />
							</Box>
						)}
						{!compact && (
							<TextBlock
								primary={order.repairWorks.map((t) => formatRepairTask(t)).join(", ")}
								secondary="Reparatur"
								reduced
							/>
						)}
					</FluentGrid>
				</Box>

				{mode === RepairOrderViewModeBase.Cancel && (
					<Box>
						<Box mt={3} mb={1} color={theme.palette.warning.light}>
							<Button
								color="inherit"
								variant="text"
								onClick={cancelOrder}
								disabled={isCancelling}
								startIcon={<Delete />}
							>
								Auftrag stornieren
							</Button>
						</Box>
					</Box>
				)}

				{mode === RepairOrderViewModeBase.Complete && (
					<RepairOrderCompleteButton order={order} setMode={setMode} setOrder={setOrder} />
				)}

				{mode === RepairOrderViewModeBase.StatusHistory && <RepairOrderStatusHistory repairOrder={order} />}

				<ListItemChips>
					<ListItemChip
						label={formatRepairOrderStatus(order.status)}
						color={paintRepairOrderStatus(order.status)}
						active={mode === RepairOrderViewModeBase.StatusHistory}
						onClick={() =>
							setMode(
								mode === RepairOrderViewModeBase.StatusHistory
									? defaultViewMode
									: RepairOrderViewModeBase.StatusHistory
							)
						}
					/>
					{!compact && <ListItemChip label={order.company.name} />}
					{order.compound && <ListItemChip label={order.compound.name} />}
				</ListItemChips>
			</ListItemContent>
		</ListItem>
	);
};
