import { useState } from "react";
import { Box, Button } from "@mui/material";
import { SalePropsWithSetter } from "./SaleLayout";
import SaleOfferListItem from "./SaleOfferListItem";
import Tile from "../../Components/Tiles/Tile";
import _ from "lodash";
import NewSaleOfferListItem from "./NewSaleOfferListItem";
import formatNumber from "../../system/formatNumber";
import List from "../../Components/List";
import { Vehicle } from "../../system/Domain";
import useSalePermissions from "./useSalePermissions";

interface Props extends SalePropsWithSetter {
	vehicle : Vehicle
}

export default ({ sale, setSale, vehicle } : Props) => {
	const [showArchived, setShowArchived] = useState(false);
	const permissions = useSalePermissions(); 

	const offers = _.orderBy(sale.offers,
		o => o.dateCreated,
		'desc'
	);

	const archivedOffers = offers.filter(o => o.status === "Archived");

	return (
		<Tile title="Vertriebskanäle">
			<List>
				{permissions.canManageOffers && (sale.status === "ReadyToAllocate" || sale.status === "ReadyToSell") && !vehicle.remarketing.isStopped && (
					<NewSaleOfferListItem
						sale={sale}
						setSale={setSale}
					/>
				)}

				{offers && offers.filter(o => showArchived || o.status !== "Archived").map(offer => (
					<SaleOfferListItem
						key={offer.channel.id}
						sale={sale}
						setSale={setSale}
						offer={offer}
						vehicle={vehicle}
					/>
				))}
			</List>
			{archivedOffers.length > 0 && (
				<Box mt={2}>
					<Button
						variant="text"
						size="small"
						onClick={() => setShowArchived(s => !s)}
					>
						{formatNumber(archivedOffers.length)} archivierte Vertriebskanäle {showArchived ? "ausblenden" : "anzeigen"}
					</Button>
				</Box>
			)}
		</Tile>
	);
}
