import moment from "moment";
import { TransportPricing } from "system/Domain";

export const createNew = (companyName: string): TransportPricing => ({
	id: null,
	version: 1,
	dateCreated: moment.utc().toISOString(),
	createdBy: { id: null, name: null, email: null },
	dateUpdated: null,
	updatedBy: { id: null, name: null, email: null },
	company: { name: companyName },
	missedTransportPrice: null,
	areasPricing: []
});

export interface PricingFormData {
	missedTransportPrice: number | null
	areasPricing: FormAreaPricing[]
}

export interface FormAreaPricing {
	areaCode: string
	prices: number[]
}

export const createEmptyForm = (areaCodes: string[], vehicleNumbers: number[]): PricingFormData => ({
	missedTransportPrice: null,
	areasPricing: areaCodes?.map<FormAreaPricing>(ac => ({
		areaCode: ac,
		prices: vehicleNumbers.map((vh, ind, arr) => (
			arr[vh - 1] = null
		))
	}))
});

export const createForm = (areaCodes: string[], vehicleNumbers: number[], pricing: TransportPricing): PricingFormData => {
	let form = createEmptyForm(areaCodes, vehicleNumbers);

	if (pricing !== null) {
		form.missedTransportPrice = pricing.missedTransportPrice;
		pricing.areasPricing.forEach(acp => {
			const fap = form.areasPricing.find(x => x.areaCode === acp.areaCode);
			if (fap) {
				acp.prices.forEach(tp => {
					fap.prices[tp.numberOfVehicle - 1] = tp.pricePerVehicle;
				});
			}
		});
	}

	return form;
};

export const applyForm = (form: PricingFormData, pricing: TransportPricing) => {
	pricing.missedTransportPrice = form.missedTransportPrice;

	// 1. remove from pricing if now null in the form
	pricing.areasPricing.forEach(pap => {
		const fap = form.areasPricing.find(x => x.areaCode === pap.areaCode);
		pap.prices = pap.prices.filter((v) => (fap.prices[v.numberOfVehicle - 1] !== null &&
			fap.prices[v.numberOfVehicle - 1] !== undefined));
	})
	pricing.areasPricing = pricing.areasPricing.filter(ap => ap.prices.length > 0);
	// 2. apply form non-null to pricing
	form.areasPricing.forEach(facp => {
		if (facp.prices.some(p => (p !== null && p !== undefined))) {
			// at least one price exists in this area in the form, so we can continue
			let pap = pricing.areasPricing.find(x => x.areaCode === facp.areaCode);
			if (!pap) {
				pap = {
					areaCode: facp.areaCode,
					prices: []
				};
				pricing.areasPricing.push(pap);
			}
			facp.prices.forEach((fp, ind) => {
				if (fp) {
					let ptp = pap.prices.find(x => x.numberOfVehicle === ind + 1);
					if (!ptp) {
						ptp = {
							numberOfVehicle: ind + 1,
							pricePerVehicle: null
						};
						pap.prices.push(ptp);
					}
					ptp.pricePerVehicle = fp;
				}
			});
			pap.prices = pap.prices.sort((a, b) => a.numberOfVehicle - b.numberOfVehicle);
		}
	});
	pricing.areasPricing = pricing.areasPricing.sort((a, b) => a.areaCode < b.areaCode ? -1 : 1)

	return pricing;
};


