import axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useHistory } from "react-router";

import { Add, Assignment, GetApp, RotateLeft } from "@mui/icons-material";
import { Box, Divider, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Sidebar from "../../Components/Sidebar/Sidebar";
import SidebarButton from "../../Components/Sidebar/SidebarButton";
import SidebarForm from "../../Components/Sidebar/SidebarForm";
import SidebarGroup from "../../Components/Sidebar/SidebarGroup";
import Sort, { SortItem } from "../../Components/Sort";
import { RemarketingStatus, UserRole, VehiclePerspective } from "../../system/Domain";
import parseContentDispositionFilename from "../../system/parseContentDispositionFilename";
import useCompoundPlaces from "../../system/useCompoundPlaces";
import useCompoundProcessSteps from "../../system/useCompoundProcessSteps";
import useVehicleExport from "../../system/useVehicleExport";
import { VehicleQuery } from "../../system/useVehicleQuery";
import useUser from "../../system/useUser";

export interface VehiclesSidebarProps {
	query: VehicleQuery;
	setQuery: Dispatch<SetStateAction<VehicleQuery>>;
	place: string | undefined;
	setPlace: Dispatch<SetStateAction<string | undefined>>;
	resetQuery: () => VehicleQuery;
}

const sortItems: SortItem[] = [
	{
		title: "Status zugewiesen",
		field: "statusAssigned",
	},
	{
		title: "Platz zugewiesen",
		field: "placeAssigned",
	},
	{
		title: "Eingang",
		field: "stockIn",
	},
];

export default ({ query, setQuery, place, setPlace, resetQuery }: VehiclesSidebarProps) => {
	const history = useHistory();
	const [processSteps] = useCompoundProcessSteps();
	const [compoundPlaces] = useCompoundPlaces();

	const [locations, setLocations] = useState<string[]>([]);
	const [groups, setGroups] = useState<{ [k: string]: string[] }>({});

	const [, , hasRole] = useUser();

	useEffect(() => {
		if (!compoundPlaces) return;

		const locations = compoundPlaces
			.map((item) => item.compound.name)
			.filter((value, index, self) => self.indexOf(value) === index);
		const groups: { [k: string]: string[] } = {};

		locations.forEach((location) => {
			groups[location] = compoundPlaces
				?.filter((f) => f.compound.name === location)
				.map((lotSpace) => lotSpace.groups || [])
				.flat()
				.filter((value, index, self) => self.indexOf(value) === index);
		});

		setLocations(locations);
		setGroups(groups);
	}, [compoundPlaces]);

	const [exportState, triggerExport] = useVehicleExport(query);
	const triggerMigrationExport = async () => {
		const response = await axios.get(`/api/vehicles/lot-place-migration/export`, {
			responseType: "blob",
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);
	};

	return (
		<Sidebar>
			{hasRole(UserRole.AldUser) && (
				<SidebarGroup>
					<SidebarButton
						startIcon={<Add />}
						roles={[UserRole.AldUser]}
						label="Neues Fahrzeug"
						color="primary"
						onClick={() => history.push(`/vehicles/new`)}
					/>
				</SidebarGroup>
			)}
			<SidebarGroup>
				<SidebarForm>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Perspektive</InputLabel>
						<Select
							value={query.perspective}
							onChange={(e) =>
								setQuery((q) => ({
									...q,
									perspective: e.target.value as VehiclePerspective,
								}))
							}
						>
							<MenuItem value="Stock">Bestand</MenuItem>
							<MenuItem value="In">Eingang</MenuItem>
							<MenuItem value="Out">Ausgang</MenuItem>
							<MenuItem value="Bookmarked">Merkliste</MenuItem>
							<MenuItem value="All">Alle</MenuItem>
						</Select>
					</FormControl>
					<Sort
						items={sortItems}
						value={query.sort}
						onChange={(sort) =>
							setQuery((q) => ({
								...q,
								sort,
							}))
						}
					/>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Status</InputLabel>
						<Select
							value={query.status || "-"}
							onChange={(e) => {
								let value = e.target.value as string | undefined;
								if (value === "-") {
									value = undefined;
								}

								setQuery((q) => ({
									...q,
									status: value as RemarketingStatus,
								}));
							}}
						>
							<MenuItem value="-">Alle</MenuItem>
							{processSteps?.map((s) => (
								<MenuItem key={s.status} value={s.status}>
									{s.displayName}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Standort</InputLabel>
						<Select
							value={query.locationName || "-"}
							onChange={(e) => {
								const value = e.target.value as string;
								setQuery((q) => ({
									...q,
									locationName: value === "-" ? undefined : value,
									placeGroup: undefined,
									placeId: undefined,
								}));
							}}
						>
							<MenuItem value="-">Alle</MenuItem>
							{locations && (
								<Box my={2}>
									<Divider />
								</Box>
							)}
							{locations?.map((g) => (
								<MenuItem key={`locationName:${g}`} value={g}>
									{g}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Platz</InputLabel>
						<Select
							value={place || "-"}
							onChange={(e) => {
								const value = e.target.value as string;
								setPlace(value === "-" ? undefined : value);
							}}
							disabled={!query.locationName}
						>
							<MenuItem value="-">Alle</MenuItem>
							{query.locationName && groups[query.locationName]?.length > 0 && (
								<Box my={2}>
									<Divider />
								</Box>
							)}
							{query.locationName &&
								groups[query.locationName]?.length > 0 &&
								groups[query.locationName].map((g) => (
									<MenuItem key={g} value={`group:${g}`}>
										{g}
									</MenuItem>
								))}
							{compoundPlaces?.some((f) => f.compound.name === query.locationName) && (
								<Box my={2}>
									<Divider />
								</Box>
							)}
							{compoundPlaces
								?.filter((f) => f.compound.name === query.locationName)
								.map((p) => (
									<MenuItem key={`${p.id}`} value={p.id}>
										{p.name}
									</MenuItem>
								))}
						</Select>
					</FormControl>

					{query.perspective !== "All" && (
						<FormControl fullWidth variant="standard">
							<DatePicker
								format="DD.MM.YYYY"
								label="Tag"
								value={query.date === undefined ? null : moment(query.date)}
								slotProps={{ textField: { variant: "standard" } }}
								onChange={(d: any) =>
									setQuery((q) => ({
										...q,
										date: (d || moment()).startOf("d").toISOString(true),
									}))
								}
							/>
						</FormControl>
					)}
					<FormControl fullWidth variant="standard">
						<DatePicker
							format="DD.MM.YYYY"
							sx={{ border: 0 }}
							label="Eingang seit dem"
							slotProps={{ textField: { variant: "standard" } }}
							value={query.currentEntryDateFrom === undefined ? null : moment(query.currentEntryDateFrom)}
							onChange={(d: any) =>
								setQuery((q) => ({
									...q,
									currentEntryDateFrom: (d || moment()).startOf("d").toISOString(true),
								}))
							}
						/>
					</FormControl>
				</SidebarForm>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarButton
					startIcon={<GetApp />}
					color="inherit"
					label="Excel-Export"
					onClick={triggerExport}
					disabled={exportState.loading}
				/>
				<SidebarButton
					startIcon={<RotateLeft />}
					label="Filter zurücksetzen"
					color="inherit"
					onClick={resetQuery}
				/>
			</SidebarGroup>
			{hasRole(UserRole.AldUser) && (
				<SidebarGroup>
					<SidebarButton
						startIcon={<Assignment />}
						roles={[UserRole.AldUser]}
						label="Migrationsexport"
						color="inherit"
						onClick={triggerMigrationExport}
					/>
					<SidebarButton
						startIcon={<Assignment />}
						roles={[UserRole.AldUser]}
						label="Leaseplan Import"
						color="inherit"
						onClick={() => history.push(`/vehicles/leaseplan-import`)}
					/>
				</SidebarGroup>
			)}
		</Sidebar>
	);
};
