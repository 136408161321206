import { Box, useTheme } from "@mui/material";
import { Children, PropsWithChildren, ReactNode } from "react";

interface Props {
	title?: ReactNode
	gap?: "none" | "narrow" | "wide"
}

export default ({ title, gap = "none", children }: PropsWithChildren<Props>) => {
	const theme = useTheme();

	children = Children.map(children, (c: any) => c);

	return (
		<Box sx={{
	
				display: "flex",
				flexDirection: "column",
				gap: theme.spacing(0.5),
				paddingTop: theme.spacing(3),
				"&:first-child": {
					paddingTop: 0
				}
		}}>
			{title && (
				<Box sx={{
					paddingBottom: theme.spacing(0.5)
				}}>{title}</Box>
			)}
			<Box sx={{
				display: "flex",
				flexDirection: "column",
				paddingLeft: theme.spacing(0.5),
				...(gap === "narrow" && {
					gap: theme.spacing(0.5)
				}),
				...(gap === "wide" && {
					gap: theme.spacing(1)
				})
			}}>{children}</Box>
		</Box>
	);
};
