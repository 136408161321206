import axios from "axios";
import { ReactNode, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {
	Box,
	Button,
	CircularProgress,
	Grid,
	MobileStepper,
	Paper,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	useTheme
} from "@mui/material";
import AldVehicleHeader from "../../../Components/AldVehicleHeader";
import TextBlock from "../../../Components/Layout/TextBlock";
import {
	AssessmentInstructions,
	CarType,
	CollectionType,
	Compound,
	CompoundReference,
	DeliveryType,
	EngineType,
	FuelType,
	ReturnOrder,
	SalesType,
	Vehicle,
	VehicleAddresses
} from "../../../system/Domain";
import { translateCollectionType, translateReturnOrderDeliveryType } from "../../../system/translate";
import useAsyncEffect from "../../../system/useAsyncEffect";
import useForm, { Form } from "../../../system/useForm";
import { joinDisplay } from "./ReturnOrderUtils";
import StepAssessment from "./StepAssessment";
import StepCarState from "./StepCarState";
import StepCollection from "./StepCollection";
import StepDelivery from "./StepDelivery";
import useCompounds from "system/useCompounds";

export type EditMode = undefined | "Full" | "AssessmentPreProcessed";

export const engineTypeFromFuelType = (fuelType?: FuelType): EngineType => {
	if (!fuelType) {
		return "Unknown";
	}

	switch (fuelType) {
		case "Electric":
		case "Hydrogen":
			return "Electric";
		case "Hybrid":
			return "Hybrid";
		case "Diesel":
		case "Gasoline":
		case "Super":
		case "SuperPlus":
		case "NaturalGas":
			return "Combustion";
		default:
			return "Unknown";
	}
};

interface Props {
	vehicle: Vehicle;
	formData?: ReturnOrderFormType;
	returnOrder?: ReturnOrder;
}

interface StepProps {
	form: Form;
	returnOrder: ReturnOrderFormType;
	addresses: VehicleAddresses;
	editMode: EditMode;
	compounds: Compound[];
}

interface IStep {
	id: number;
	title: string;
	ui: (props: StepProps) => ReactNode;
	reference?: {
		name: string;
		title: (name: string) => string;
	};
}

const steps: IStep[] = [
	{
		id: 1,
		title: "Allgemeine Fahrzeuginformationen",
		ui: (props: StepProps) => <StepCarState {...props} />
	},
	{
		id: 2,
		title: "Abholung",
		ui: (props: StepProps) => <StepCollection {...props} />,
		reference: {
			name: "collection.collectionType",
			title: (e) => translateCollectionType(e as CollectionType)
		}
	},
	{
		id: 3,
		title: "Anlieferung",
		ui: (props: StepProps) => <StepDelivery {...props} />,
		reference: {
			name: "delivery.deliveryType",
			title: (e) => {
				const castingResult: DeliveryType = DeliveryType[e as keyof typeof DeliveryType];
				return translateReturnOrderDeliveryType(castingResult);
			}
		}
	},
	{
		id: 4,
		title: "Weitere Aktionen",
		ui: (props: StepProps) => <StepAssessment {...props} />
	}
];

export const calculateReturnOrderFormData = (
	returnOrder: ReturnOrder,
	clearAppointments: boolean
): ReturnOrderFormType => {
	const returnOrderForm: ReturnOrderFormType = {
		vehicleId: returnOrder.vehicle.id,
		isElectricCar: returnOrder.isElectricCar,
		isDrivable: returnOrder.isDrivable,
		isPoolfleet: returnOrder.isPoolfleet,
		drivableComment: returnOrder.drivableComment,
		isRegistered: returnOrder.isRegistered,
		hasValidMainInspection: returnOrder.hasValidMainInspection,
		mainInspectionValidUntil: returnOrder.mainInspectionValidUntil,
		tireType: returnOrder.tireType,
		carType: returnOrder.carType,
		doAssessment: returnOrder.doAssessment,
		doUnregistration: returnOrder.doUnregistration,
		transportCostInstallments: returnOrder.transportCostInstallments,
		costTransferCustomer: returnOrder.costTransferCustomer,
		debitingAld: returnOrder.debitingAld,
		collection: returnOrder.collection,
		delivery: returnOrder.delivery,
		assessment: returnOrder.assessment,
		customerServiceAgent: returnOrder.customerServiceAgent,
		engineType: returnOrder.engineType,
		salesType: returnOrder.salesType,
		salesTypeComment: returnOrder.salesTypeComment
	};

	if (clearAppointments) {
		if (returnOrderForm.collection) {
			returnOrderForm.collection.appointment = undefined;
		}
		if (returnOrderForm.delivery) {
			returnOrderForm.delivery.appointment = undefined;
		}

		if (returnOrderForm.assessment) {
			returnOrderForm.assessment.appointment = undefined;
		}
	}

	if (returnOrder.status === "CustomerCreated") {
		returnOrderForm.doAssessment = undefined;
		returnOrderForm.doUnregistration = undefined;
	}

	return returnOrderForm;
};

export interface ReturnOrderFormType {
	vehicleId: string;
	isElectricCar?: boolean;
	isDrivable?: boolean;
	isPoolfleet?: boolean;
	drivableComment?: string;
	isRegistered?: boolean;
	hasValidMainInspection?: boolean;
	mainInspectionValidUntil?: string;
	tireType?: string;
	carType?: CarType;
	salesType?: SalesType;
	salesTypeComment?: string;
	doAssessment?: boolean;
	doUnregistration?: boolean;
	transportCostInstallments?: string;
	costTransferCustomer?: string;
	debitingAld?: string;
	collection?: {
		collectionType?: CollectionType;
		compound?: CompoundReference;
		comment?: string;
		internalComment?: string;
		address?: {
			name?: string;
			name1?: string;
			street?: string;
			additionalInformation?: string;
			zipCode?: string;
			city?: string;
			country?: string;
		};
		contact?: {
			firstName?: string;
			lastName?: string;
			email?: string;
			telephone?: string;
		};
		appointment?: {
			notBefore?: string;
			from?: string;
			to?: string;
		};
		sendEmail?: boolean;
	};
	delivery?: {
		deliveryType?: DeliveryType;
		compound?: CompoundReference;
		comment?: string;
		isCrossTransport?: boolean;
		address?: {
			name?: string;
			name1?: string;
			street?: string;
			additionalInformation?: string;
			zipCode?: string;
			city?: string;
			country?: string;
		};
		contact?: {
			firstName?: string;
			lastName?: string;
			email?: string;
			telephone?: string;
		};
		appointment?: {
			notBefore?: string;
			from?: string;
			to?: string;
		};
	};
	assessment?: AssessmentInstructions;
	customerServiceAgent?: {
		name?: string;
		email?: string;
		telephone?: string;
	};
	engineType?: EngineType;
}

const ReturnOrdersForm = (props: Props) => {
	const history = useHistory();
	const theme = useTheme();
	const [returnOrderForm, setReturnOrderForm] = useState<ReturnOrderFormType>(
		props.formData ?? {
			vehicleId: props.vehicle.id,
			isDrivable: true
		}
	);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [addresses, setAddresses] = useState<VehicleAddresses>();
	const isCustomerDelivery = returnOrderForm?.delivery?.deliveryType === DeliveryType.CustomerDelivery;
	const [compounds] = useCompounds({ includeAmazonCompounds: false });

	const form = useForm({
		values: returnOrderForm,
		setValues: setReturnOrderForm,
		deltaValues: null
	});

	const editMode: EditMode = props.returnOrder
		? props.returnOrder?.assessmentIsPreprocessed
			? "AssessmentPreProcessed"
			: "Full"
		: undefined;

	useAsyncEffect(async () => {
		if (!props.vehicle) return;

		const response = await axios.get<VehicleAddresses>(`/api/vehicles/${props.vehicle.id}/addresses`);
		setAddresses(response.data);
	}, [props.vehicle]);

	useEffect(() => {
		if (!props.vehicle) return;

		form.setValue("customerServiceAgent.name", props.vehicle.remarketing.leasmanContract?.serviceAgent?.name);
		form.setValue("customerServiceAgent.email", props.vehicle.remarketing.leasmanContract?.serviceAgent?.email);
		form.setValue("customerServiceAgent.telephone", props.vehicle.remarketing.leasmanContract?.serviceAgent?.telephone);

		const fuelType = engineTypeFromFuelType(props.vehicle.type.engine.fuelType);

		form.setValue("engineType", fuelType);

		if (fuelType === "Electric") {
			form.setValue("isDrivable", false);
		}
	}, [props.vehicle]);

	const usedSteps = steps.filter((f) => !isCustomerDelivery || f.id !== 2);

	const [activeStep, setActiveStep] = useState(0);
	const maxSteps = usedSteps.length;
	const isLastStep = activeStep === usedSteps.length - 1;
	const isFirstStep = activeStep === 0;

	const handleNext = () => {
		const validated = form.validateAll();

		if (validated) {
			if (!isLastStep) {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			} else {
				if (editMode) {
					completeUpdate();
				} else {
					completeCreate();
				}
			}
		}
	};

	const completeCreate = async () => {
		setIsSubmitting(true);

		try {
			await axios.post(`/api/orders/returns`, {
				...returnOrderForm,
				vehicleId: props.vehicle.id
			});
		} finally {
			setIsSubmitting(false);
		}

		history.push(`/orders/returns`);
	};

	const completeUpdate = async () => {
		setIsSubmitting(true);

		try {
			await axios.put(`/api/orders/returns/${props.returnOrder?.id}`, returnOrderForm);
		} finally {
			setIsSubmitting(false);
		}

		history.push(`/orders/returns`);
	};

	const handleBack = () => {
		if (activeStep === 0) {
			if (editMode) {
				history.push(`/orders/returns/${props.returnOrder?.id}`);
			} else {
				history.push("/orders/returns");
			}
		}

		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const rkvIndicator = addresses?.isRkv ? (
		<span style={{ color: theme.palette.secondary.light }}>RKV Fahrzeug</span>
	) : undefined;
	const serviceAgentInformation = joinDisplay([
		props.vehicle.remarketing.leasmanContract?.serviceAgent?.name,
		props.vehicle.remarketing.leasmanContract?.serviceAgent?.name,
		props.vehicle.remarketing.leasmanContract?.serviceAgent?.telephone
	]);
	const customerDeliveryIndicator = isCustomerDelivery ? (
		<span style={{ color: theme.palette.secondary.light }}>Eigenanlieferung</span>
	) : undefined;

	return (
		<>
			{!addresses && <CircularProgress size={48} />}
			{addresses && (
				<>
					<Grid
						container
						style={{ width: "auto" }}
						spacing={2}
						alignItems="flex-end"
						justifyContent="space-between"
					>
						<Grid item>
							<AldVehicleHeader
								plateNumber={props.vehicle.plateNumber}
								entries={[props.vehicle.type?.description, props.vehicle.vin]}
								additionalEntries={[rkvIndicator, customerDeliveryIndicator]}
							/>
						</Grid>
					</Grid>
					<Box mt={1}>
						<TextBlock secondary={serviceAgentInformation} />
					</Box>
					<Box mt={1}>
						<Paper>
							<Box p={2}>
								<Stepper activeStep={activeStep} orientation="vertical">
									{usedSteps.map((step, index) => (
										<Step key={index}>
											<StepLabel>
												{step.title}
												{step.reference && form.getValue(step.reference.name)
													? `: ${step.reference.title(form.getValue(step.reference.name))}`
													: ""}
											</StepLabel>
											<StepContent>
												{step.ui({
													form: form,
													returnOrder: returnOrderForm,
													addresses: addresses,
													editMode: editMode,
													compounds: compounds
												})}
											</StepContent>
										</Step>
									))}
								</Stepper>
							</Box>
						</Paper>
					</Box>
					<MobileStepper
						sx={{ position: "absolute", bottom: 0, left: 0, width: "100%" }}
						steps={maxSteps}
						position="static"
						variant="progress"
						activeStep={activeStep}
						nextButton={
							isSubmitting ? (
								<CircularProgress size={24} />
							) : (
								<Button
									size="small"
									onClick={handleNext}
									disabled={activeStep === maxSteps}
									color="info"
								>
									{!isLastStep ? "Weiter" : editMode ? "Auftrag anpassen" : "Auftrag erstellen"}
									<KeyboardArrowRight />
								</Button>
							)
						}
						backButton={
							<Button size="small" onClick={handleBack} disabled={activeStep < 0} color="info">
								<KeyboardArrowLeft />
								{!isFirstStep ? "Zurück" : "Abbrechen"}
							</Button>
						}
					/>
				</>
			)}
		</>
	);
};

export default ReturnOrdersForm;
