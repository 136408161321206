import axios from 'axios';
import moment from 'moment';
import { ChangeEvent, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, CircularProgress, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Layout from '../../../Components/Layout/Layout';
import TextBlock from '../../../Components/Layout/TextBlock';
import PaperTable from '../../../Components/PaperTable';
import Plate from '../../../Components/Plate/Plate';
import Search from '../../../Components/Search';
import dateFormats from '../../../system/dateFormats';
import { VehicleRegistrationDocumentsProjection } from "../../../system/Domain";
import useAsyncEffect from '../../../system/useAsyncEffect';
import ArrivalToggleButtonGroup from './ArrivalToggleButtonGroup';
import DocumentArrivalsSidebar from './DocumentArrivalsSidebar';
import VehicleRegistrationDocumentsStatusPill from './VehicleRegistrationDocumentsStatusPill';
import useVehicleQuery from "../../../system/useVehicleQuery";

const DocumentArrivals = () => {
	const [vehicleToUpdate, setVehicleToUpdate] = useState<VehicleRegistrationDocumentsProjection | null>(null);
	const [dateUntil, setDateUntil] = useState<string>();
	const [isLoading, setIsLoading] = useState(false);
	const [vehicles, setVehicles] = useState<VehicleRegistrationDocumentsProjection[]>();
	const [search, setSearch] = useState<string>();
	const [refresh, setRefresh] = useState(false);
	const [hideKey, setHideKey] = useState<string[]>([]);

	const [query, setQuery, place, setPlace, resetQuery] = useVehicleQuery('vehicleDocumentQuery', {
		perspective: "All",
		sort: "businessLine:asc",
		fullText: ""
	});

	const groupedVehicles : Map<string, VehicleRegistrationDocumentsProjection[]> | undefined = vehicles?.filter(str => {
		if (!search) return true;

		return str.vin.toLowerCase().includes(search.toLowerCase()) || str.plateNumber.toLowerCase().includes(search.toLowerCase());
	}).reduce((map, e) => {
		const date = moment(e.registrationDocuments.dateStatusChanged).format(dateFormats.date);
		return map.set(
			date,
			[...map.get(date) || [], e]
		);
	}, new Map());

	useAsyncEffect(async () => {
		setIsLoading(true);

		try {
			const { data } = await axios.get<VehicleRegistrationDocumentsProjection[]>(`/api/vehicles/remarketing/registration-documents`, {
				params: {
					registrationDocumentStatus: "Requested",
					registrationDocumentStatusDateFrom: dateUntil,
					specialSort: "Date;PlateNumberNumbers",
					businessLine: query.businessLine !== "-" ? query.businessLine : undefined,
				}
			});

			setVehicles(data);
		} finally {
			setIsLoading(false);
		}
	}, [dateUntil, refresh, query.businessLine]);

	const toggleVisibility = (key : string) => {
		if (hideKey.includes(key)) {
			setHideKey(hideKey.filter(f => f !== key));
		} else {
			setHideKey([...hideKey, key]);
		}
	};

	return (
		<Layout
			title="Dokumenteneingang"
			sidebar={
				<DocumentArrivalsSidebar
					query={query}
					setQuery={setQuery}
					dateUntil={dateUntil}
					setDateUntil={setDateUntil}
					refresh={() => setRefresh(!refresh)}
					resetQuery={resetQuery}
				/>
			}
		>
			<Box mb={2}>
				{isLoading && (
					<CircularProgress size={48} />
				)}

				{!isLoading && vehicles && groupedVehicles && (
					<>
						<Box mb={2}>
							<Search
								value={undefined}
								realtime
								onSearch={value => setSearch(value)}
							/>
						</Box>
						{Array.from(groupedVehicles.keys()).map((key, index) => {
							const groupVehicles = groupedVehicles.get(key)!;
							const dateRequested = moment(groupVehicles[0].registrationDocuments.dateStatusChanged).format(dateFormats.date);
							const hide = hideKey.includes(key);

							return (
								<Box key={index}>
									<Box my={3} flexDirection="row" display="flex" justifyContent="space-between">
										<TextBlock
											primary={`Abfrageimport vom: ${dateRequested}`}
										/>
										<Box mr={1}>
											{!hide && (
												<Visibility
													onClick={() => toggleVisibility(key)}
												/>
											)}
											{hide && (
												<VisibilityOff
													onClick={() => toggleVisibility(key)}
												/>
											)}
										</Box>
									</Box>
									<Box hidden={hide}>
										<PaperTable>
											<colgroup>
												<col width="auto" />
												<col width="auto" />
												<col width="auto" />
											</colgroup>
											<TableHead>
												<TableRow>
													<TableCell>
														Fahrzeug
													</TableCell>
													<TableCell>
														Mandant
													</TableCell>
													<TableCell>
														Status
													</TableCell>
													<TableCell>
														Angekommenes Dokument
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{groupVehicles.map((v, i) => {
													const handleChangeEvent = async (event : ChangeEvent<any>, value : any) => {
														if (vehicleToUpdate !== null || !value) return;

														setVehicleToUpdate(v);

														try {
															const { data: vehicle } = await axios.put<VehicleRegistrationDocumentsProjection>(`/api/vehicles/${v.id}/remarketing/documents/arrival`, {
																type: value
															});

															setVehicles(setVehicles => {
																if (setVehicles) {
																	const index = setVehicles.findIndex(ve => ve.id === v.id);

																	vehicle.registrationDocuments.dateStatusChanged = v.registrationDocuments.dateStatusChanged;

																	if (index >= 0) {
																		return [
																			...setVehicles.slice(0, index),
																			vehicle,
																			...setVehicles.slice(index + 1)
																		]
																	}
																}

																return setVehicles;
															});
														} finally {
															setVehicleToUpdate(null);
														}
													};

													return (
														<TableRow
															key={i}
															hover
														>
															<TableCell>
																<Plate plateNumber={v.plateNumber} style={{ zoom: 0.6 }} />
																<TextBlock
																	primary={v.makeModel}
																	secondary={v.vin}
																	compact
																/>
															</TableCell>
															<TableCell>
																<TextBlock
																	primary={v.businessLine}
																	compact
																/>
															</TableCell>
															<TableCell>
																<VehicleRegistrationDocumentsStatusPill status={v.registrationDocuments.status} />
															</TableCell>
															<TableCell>
																<ArrivalToggleButtonGroup
																	value={v.registrationDocuments.arrivalType}
																	disable={vehicleToUpdate !== null}
																	previousValue={v.registrationDocuments.arrivalType}
																	status={v.registrationDocuments.status}
																	onChange={handleChangeEvent}
																	alwaysShowAll
																/>
															</TableCell>
														</TableRow>
													);
												})}
											</TableBody>
										</PaperTable>
									</Box>
								</Box>
							);
						})}
					</>
				)}
			</Box>
		</Layout>
	);
};

export default DocumentArrivals;
