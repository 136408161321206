const status = {
	"InPreparation": "In Vorbereitung",
	"ReadyForRent": "Bereit zur Vermietung",
	"Reserved": "Reserviert",
	"InDelivery": "In Auslieferung",
	"Rented": "Vermietet",
	"InReturn": "In Abholung",
	"Unavailable": "Nicht verfügbar"
};

export default status;
