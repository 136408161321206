import { ReactElement } from "react";
import { Box } from "@mui/material";

export interface FaqEntry {
	key: string
	title: string
	answer: string | ReactElement
	includeInList: boolean
}

const FaqList : FaqEntry[] = [
	{
		key: "motor-type-electro",
		title: "E-Fahrzeug Sonderregelungen",
		answer: "Abholung des Fahrzeuges erfolgt grundsätzlich auf Fremdachse.",
		includeInList: true
	},
	{
		key: "motor-type-electro-warning",
		title: "E-Fahrzeug Fahrbereitschaft",
		answer: "E-Fahrzeuge gelten aufgrund der geringen Reichweite und Verfügbarkeit von Lademöglichkeiten als nicht fahrbereit.",
		includeInList: true
	},
	{
		key: "no-hu-au",
		title: "Abgelaufene Hauptuntersuchung/Abgasuntersuchung",
		answer: "Fahrzeuge ohne Hauptuntersuchung/Abgasuntersuchung gelten als nicht fahrbereit und müssen dementsprechend auf Fremdachse abgeholt werden.",
		includeInList: true
	},
	{
		key: "tires",
		title: "Bereifung",
		answer: "Das Fahrzeug muss gemäß der Bereifung im Leasingvertrag ausgehändigt werden.",
		includeInList: true
	},
	{
		key: "collection-types-self",
		title: "Eigenachse",
		answer: <Box>
			Von Eigenachse wird gesprochen, wenn das Fahrzeug selbst und ohne Verladung "gefahren" werden kann. In diesen Fällen wird das Fahrzeug über einen Fahrer abgeholt.
			<br /><br />
			Zu beachten bei Eigenachse: as Fahrzeug muss in einem fahrbereiten Zustand sein (richtige Bereifung, keine Unfallschäden) und die TÜV und HU/AU Gültigkeit müssen gegeben sein.
		</Box>,
		includeInList: true
	},
	{
		key: "collection-types-assisted",
		title: "Fremdachse",
		answer: <Box>
			Von Fremdachse wird gesprochen, wenn das Fahrzeug (Leasingfahrzeug) auf einem extra dafür vorgesehenem Fahrzeug (auf einer fremden Achse) wie z.B. Autoanhänger, Sattelschlepper oder Abschleppfahrzeug transportiert wird (werden muss).
			<br /><br />
			Ein Fahrzeug-Transport via Fremdachse Ist immer mit zusätzlichen Kosten verbunden.
			<br /><br />
			Zusätzlich muss bei einem Transport via Fremdachse darauf geachtet werden, dass die Strassenverhältnisse am Abholort des Fahrzeugs einen Fremdachsen-Transport zulassen.
			<br />
			Das Bedeutet zum Beispiel: Ist die Strasse breit genug für einen Sattelschlepper?
		</Box>,
		includeInList: true
	},
	{
		key: "unregistered-vehicles",
		title: "Abgemeldete Fahrzeuge",
		answer: "Abgemeldete Fahrzeugen können nur via Fremdachse oder mit \"roten Nummernschildern\" abgeholt werden. Dadurch entstehen ggf. Mehrkosten.",
		includeInList: true
	},
	{
		key: "collection-capacity-warning",
		title: "Aufgrund von Kapazitätsengpässen kann es aktuell zu Verzögerungen bei den Abholungen kommen.",
		answer: "",
		includeInList: false
	},
	{
		key: "creation-trader-hint",
		title: "",
		answer: "Ein Händler darf aus rechtlichen Gründen die Kundenadresse und allgemeinen Kundendaten nicht einsehen.",
		includeInList: false
	},
	{
		key: "mileage-picture-proof",
		title: "",
		answer: "Um den Kilometerstand des Fahrzeugs zu dokumentieren, akzeptieren wir ein Foto des Tachostands, ein unterschriebenes Übergabeprotokoll von Ihrem Händler oder ein aktuelles Gutachten eines unabhängigen Sachverständigen.",
		includeInList: false
	},
	{
		key: "creation-early-return-hint",
		title: "",
		answer: "Für das Forderungsmanagement, wenn ein Fahrzeug aufgrund von z.B. Insolvenz oder Kündigung frühzeitig vor Vertragsende abgeholt werden muss.",
		includeInList: false
	},
	{
		key: "delivery-cancellation",
		title: "",
		answer: "Es wird eine E-mail an ALD Carmarket versendet.",
		includeInList: false
	},
	{
		key: "unstick-service",
		title: "",
		answer: "Es wird die Tatigkeit 'Entkleben' angelegt.",
		includeInList: false
	},
	{
		key: "change-tires-service",
		title: "",
		answer: "Es wird die Tatigkeit 'Räderwechsel' angelegt.",
		includeInList: false
	}
]

export default FaqList;
