import moment from "moment";
import dateFormats from "../../../system/dateFormats";
import TextBlock from "../../../Components/Layout/TextBlock";
import { TransportOrder } from "../../../system/Domain";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";

interface OrderEmailCommunicationsTableProps {
	order: TransportOrder
}

export default ({ order }: OrderEmailCommunicationsTableProps) => {
	const sorterEmails = order.emailCommunications?.sort((a, b) => moment(b.referenceCreated).valueOf() - moment(a.referenceCreated).valueOf()) ?? [];

	return (
		<Table>
			<TableBody>
				{sorterEmails.map((n, i) => (
					<TableRow key={i}>
						<TableCell sx={{ verticalAlign: "top", width: 1 / 4 }}>
							<TextBlock
								primary={moment(n.referenceCreated).format(dateFormats.dateTime)}
								secondary={moment(n.referenceCreated).fromNow()}
							/>
						</TableCell>
						<TableCell sx={{ verticalAlign: "top", width: 3 / 4 }}>
							<TextBlock
								primary={n.subject}
								secondary={n.to.join(", ")}
							/>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
};
