import { DependencyList, useEffect } from "react";
import useEvents from "./useEvents";

export default (channel? : string, deps : DependencyList = []) => {
	const events = useEvents();

	useEffect(() => {
		if (!channel) {
			return;
		}

		events.subscribe([channel]);
		return () => events.unsubscribe([channel]);
	}, [...deps, channel]);

}
