import { combineReducers, createStore } from "redux";
import auth from './auth';
import errors from "./errors";
import notifications from "./notifications";
import userMessage from "./reducers/userMessage/reducer";
import workSessions from "./reducers/workSessions/reducer";
import layout from "./layout";
import { TypedUseSelectorHook, useSelector } from "react-redux";

const reducers = combineReducers({
	errors,
	auth,
	notifications,
	userMessage,
	workSessions,
	layout
});

export type AppState = ReturnType<typeof reducers>;
export const useReduxState: TypedUseSelectorHook<AppState> = useSelector

export default createStore(
	reducers,
	// @ts-ignore
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
