import { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import patternFill from "highcharts/modules/pattern-fill";
import HighchartsReact from "highcharts-react-official";
import { useReduxState } from "../system/store";

patternFill(Highcharts);

interface Props {
	options: Highcharts.Options;
}

export default ({ options }: Props) => {
	const componentRef = useRef<HighchartsReact.RefObject>(null);
	const layout = useReduxState((s) => s.layout);

	useEffect(() => {
		componentRef.current?.chart.reflow();
		setTimeout(() => {
			componentRef.current?.chart.reflow();
		}, 0);
	}, [layout.fullscreen]);

	return <HighchartsReact highcharts={Highcharts} options={options} ref={componentRef} />;
};
