import { Dispatch, useState, SetStateAction, useEffect } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";

import Sidebar from "../../Components/Sidebar/Sidebar";
import SidebarGroup from "../../Components/Sidebar/SidebarGroup";
import { SaleQuery } from "../../system/Domain";
import useSalesChannels from "../../system/useSalesChannels";
import SidebarButton from "Components/Sidebar/SidebarButton";
import { GetApp } from "@mui/icons-material";
import axios from "axios";
import parseContentDispositionFilename from "system/parseContentDispositionFilename";
import fileDownload from "js-file-download";
import showMessage from "Dialogs/showMessage";
import SidebarForm from "Components/Sidebar/SidebarForm";

export interface Props {
	query: SaleQuery;
	setQuery: Dispatch<SetStateAction<SaleQuery>>;
}

export default ({ query, setQuery }: Props) => {
	const [salesChannels] = useSalesChannels();
	const [channel, setChannel] = useState("-");
	const [perspective, setPerspective] = useState<string>("All");

	const statusPerspectives = {
		All: { name: "Alle", isStopped: null, statuses: null },
		Active: { name: "Aktiv", isStopped: null, statuses: ["Returned", "ReadyToAllocate", "ReadyToSell"] },
		Reserved: { name: "Reserviert", isStopped: null, statuses: ["Reserved"] },
		Sold: { name: "Verkauft", isStopped: null, statuses: ["Sold"] },
		Paid: { name: "Bezahlt", isStopped: null, statuses: ["ReadyForShipping"] },
		Closed: { name: "Abgeschlossen", isStopped: null, statuses: ["Shipped", "End"] },
		Cancelled: { name: "Abgebrochen", isStopped: null, statuses: ["Cancelled"] },
		Stopped: { name: "Gestoppt", isStopped: true, statuses: null },
	};

	const updateQuery = (query: SaleQuery, perespective: string, channelName: string): SaleQuery => {
		return {
			...query,
			status: statusPerspectives[perespective].statuses,
			isStopped: statusPerspectives[perespective].isStopped,
			channel: channelName,
		};
	};

	useEffect(() => {
		if (!query) return;

		if (!salesChannels) return;

		// deconstruct query for SelectInputs values

		const perspectiveIndex = Object.values(statusPerspectives).findIndex(
			(sp) =>
				sp.isStopped == query.isStopped &&
				((query.status == null && sp.statuses == null) ||
					query.status?.every((qs) => sp.statuses?.includes(qs))),
		);

		const queryPerspective = perspectiveIndex > 0 ? Object.keys(statusPerspectives).at(perspectiveIndex) : "All";

		setPerspective(queryPerspective);

		setChannel(query.channel ? query.channel : "-");
	}, [query, salesChannels]);

	const [exporting, setExporting] = useState(false);
	const triggerExport = async () => {
		if (!query.fullText && perspective === "All") {
			await showMessage({
				title: "Fehler",
				message:
					"Es muss entweder Suchtext eingeben sein oder eine Perspektive ausgewählt sein. Sonst ist der Export zu groß.",
			});
			return;
		}

		setExporting(true);
		try {
			const response = await axios.post(`/api/sales/search`, query, {
				responseType: "blob",
				params: {
					format: "xlsx",
				},
			});

			const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
			if (filename) {
				fileDownload(response.data, filename);
			}
		} finally {
			setExporting(false);
		}
	};

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarForm>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Perspektive</InputLabel>
						<Select
							value={perspective}
							onChange={(e) => {
								setPerspective(e.target.value);
								setQuery((q) =>
									updateQuery(
										q,
										e.target.value,
										salesChannels?.find((sc) => sc.name === channel)?.name,
									),
								);
							}}
						>
							{Object.keys(statusPerspectives).map((k) => (
								<MenuItem value={k}>{statusPerspectives[k].name}</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Vertriebskanal</InputLabel>
						<Select
							value={channel}
							onChange={(e) => {
								setChannel(e.target.value);

								const actualChannel = salesChannels.find((c) => c.name === e.target.value)?.name;
								setQuery((q) => updateQuery(q, perspective, actualChannel));
							}}
						>
							<MenuItem value="-">Alle</MenuItem>{" "}
							{/* value here is something what is never proper channel name */}
							{salesChannels && salesChannels.map((sc) => <MenuItem value={sc.name}>{sc.name}</MenuItem>)}
						</Select>
					</FormControl>
				</SidebarForm>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarButton
					startIcon={<GetApp />}
					color="inherit"
					disabled={exporting}
					onClick={triggerExport}
					label="Excel-Export"
				/>
			</SidebarGroup>
			<Grid item>
				<Typography sx={{ opacity: "0.7" }} variant="caption">
					Beim Export sind alle Filter- und Sucheingaben berücksichtigt
				</Typography>
			</Grid>
		</Sidebar>
	);
};
