import { useCallback, useEffect, useState } from "react";
import useTimeout from "./useTimeout";

interface RetryableApi {
	(retryCount : number) : Promise<void>
}

export interface BackgroundApi {
	request : () => void
	executing : boolean
	promise : Promise<void> | null
}

export default (api : RetryableApi) : [() => void, boolean, Promise<void> | null] => {
	const [requested, setRequested] = useState(false);
	const [promise, setPromise] = useState<Promise<void> | null>(null);
	const [executing, setExecuting] = useState(false);
	const [retryId, startRetry, stopRetry] = useTimeout(5000, {});
	const [retryCount, setRetryCount] = useState(0);

	const request = useCallback(() => setRequested(true), []);
	const execute = () => {
		stopRetry();

		setPromise((async () => {
			try {
				if (retryCount > 0) {
					console.log(`retry #${retryCount}`);
				}

				if (!executing) {
					setExecuting(true);
				}

				await api(retryCount);
				setRetryCount(0);
				setExecuting(false);
			} catch (e) {
				console.error(e);
				startRetry();
				setRetryCount(c => c + 1);
			} finally {
				setPromise(null);
			}
		})());
	};

	useEffect(() => {
		if (!requested && !retryId) {
			return;
		}

		console.log([requested, retryId]);

		if (promise) {
			console.log("prior promise still running");
			return;
		}

		if (requested) {
			setRequested(false);
			execute();
		} else if (retryId) {
			execute();
		}
	}, [requested, retryId]);

	return [
		request,
		executing,
		promise
	];
};
