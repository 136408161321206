import { ElementType, PropsWithChildren, ReactNode } from "react";
import { Box, useTheme } from "@mui/material";
import { ThemeColor } from "../../system/Domain";

interface Props {
	value?: ReactNode;
	color?: ThemeColor;
	component?: ElementType;
}

export default ({ value = null, color = "info", children, component = "div", ...rest }: PropsWithChildren<Props>) => {
	const theme = useTheme();
	const getColor = (color: ThemeColor): string => {
		switch (color) {
			case "primary":
				return theme.palette.primary.light;
			case "secondary":
				return theme.palette.secondary.light;
			case "success":
				return theme.palette.success.light;
			case "warning":
				return theme.palette.warning.light;
			case "error":
				return theme.palette.error.light;
			case "grey":
				return theme.palette.grey[500];
		}
	};
	return (
		<Box sx={{ color: getColor(color) }} {...rest} component={component}>
			{value}
			{children}
		</Box>
	);
};
