import { Box } from "@mui/material";

interface Props {

}

const StepPricingRequestInstructions = (props: Props) => {

	return (
		<Box>
			Sehr geehrter Kunde, bitte füllen Sie das nachfolgende Formular komplett aus.
			<br />
			<br />
			Bitte achten Sie vor allem auf die korrekte Angabe der angefragten Daten, da sonst der Auftrag nicht ordnungsgemäß ausgeführt werden kann.
			<br />
			<br />
			Für Rückfragen kontaktieren Sie bitte Ihren Kundenbetreuer.
		</Box>
	);
}

export default StepPricingRequestInstructions;
