import { Dispatch, SetStateAction } from 'react';

import { RotateLeft } from '@mui/icons-material';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import Sidebar from '../../../Components/Sidebar/Sidebar';
import SidebarForm from '../../../Components/Sidebar/SidebarForm';
import SidebarGroup from '../../../Components/Sidebar/SidebarGroup';
import Sort, { SortItem } from '../../../Components/Sort';
import { ReturnOrderSessionsOverviewPerspective, ReturnOrderSessionsOverviewType } from '../../../system/Domain';
import { ReturnOrderSessionsOverviewQuery } from './ReturnOrderSessionsOverview';

interface Props {
	query: ReturnOrderSessionsOverviewQuery,
	setQuery: Dispatch<SetStateAction<ReturnOrderSessionsOverviewQuery>>
	resetQuery: () => ReturnOrderSessionsOverviewQuery
}

const sortItems: SortItem[] = [
	{
		title: "Erstellungsdatum",
		field: "dateCreated"
	}
];

const ReturnOrderSessionsOverviewSidebar = (props: Props) => {
	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarForm>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Perspektive</InputLabel>
						<Select
							value={props.query.perspective}
							onChange={(e) => props.setQuery(g => ({
								...g,
								skip: 0,
								perspective: e.target.value as ReturnOrderSessionsOverviewPerspective
							}))}
						>
							<MenuItem value="All">Alle</MenuItem>
							<MenuItem value="Created">Erstellt</MenuItem>
							<MenuItem value="Completed">Abgeschlossen</MenuItem>
						</Select>
					</FormControl>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Sitzungstyp</InputLabel>
						<Select
							value={props.query.type}
							onChange={(e) => props.setQuery(g => ({
								...g,
								skip: 0,
								type: e.target.value as ReturnOrderSessionsOverviewType
							}))}
						>
							<MenuItem value="Fileless">Direkt</MenuItem>
							<MenuItem value="ImportBased">Import basiert (alt)</MenuItem>
						</Select>
					</FormControl>
					<Sort
						items={sortItems}
						value={props.query.sort}
						onChange={(sort) => props.setQuery(g => ({
							...g,
							skip: 0,
							sort
						}))}
					/>
				</SidebarForm>
			</SidebarGroup>
			<SidebarGroup>
				<Button
					variant="outlined"
					startIcon={<RotateLeft />}
					fullWidth
					color="inherit"
					onClick={props.resetQuery}
				>
					Filter zurücksetzen
				</Button>
			</SidebarGroup>
		</Sidebar>
	);
};

export default ReturnOrderSessionsOverviewSidebar;
