import { CustomerOrder } from "../../../system/Domain";
import TextBlock from "../../../Components/Layout/TextBlock";
import { Box, Chip } from "@mui/material";
import { blue, green, grey, red } from "@mui/material/colors";
import moment from "moment";
import dateFormats from "../../../system/dateFormats";
import { useHistory } from "react-router";

interface Props {
	customerOrder: CustomerOrder
	disableLink?: boolean
}

const PricingRequestDecisionStatusPill = (props: Props) => {
	const history = useHistory();

	if (props.customerOrder.type !== "PricingRequest") return;

	const openDecision = !props.customerOrder.pricingRequestDecision;

	const accepted = props.customerOrder.pricingRequestDecision?.accepted;

	const label = openDecision ? "Offen" : accepted ? "Angenommen" : "Abgelehnt";
	const color = openDecision ? grey[500] : accepted ? green[500] : red[500];

	return (
		<>
			<TextBlock
				primary={
					<Chip
						label={label}
						size="small"
						style={{ backgroundColor: color }}
					/>
				}
				secondary={!openDecision ? moment(props.customerOrder.pricingRequestDecision.decisionDate).format(dateFormats.dateTime) : undefined}
			/>
			{props.customerOrder.linkedOrder && (
				<Box mt={2}>
					<TextBlock
						primary={
							<Chip
								label="Auftrag neu erstellt"
								size="small"
								style={{ backgroundColor: blue[500] }}
								clickable
								onClick={props.disableLink === true ? undefined : () => history.push(`/orders/customers/${props.customerOrder.linkedOrder.orderId}`)}
							/>
						}
						secondary={moment(props.customerOrder.linkedOrder.dateCreated).format(dateFormats.dateTime)}
					/>
				</Box>
			)}
		</>
	);
};

export default PricingRequestDecisionStatusPill;
