import { Dispatch, SetStateAction } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { CompoundRegistrationReference, TransportOrder, UserRole } from "../../../system/Domain";
import { OrderType } from "../../../system/useOrder";
import SidebarButton from "../../../Components/Sidebar/SidebarButton";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import SidebarGroup from "../../../Components/Sidebar/SidebarGroup";
import useUser from "../../../system/useUser";
import { isBadFallingBostelAddress, isDorfmarkAddress } from "../../../system/isDorfmarkAddress";

interface Props {
	order: OrderType | null;
	setOrder: Dispatch<SetStateAction<TransportOrder | null>>;
	compoundReference?: CompoundRegistrationReference;
	setCompoundReference: Dispatch<SetStateAction<CompoundRegistrationReference | undefined>>;
}

export default ({ order, setOrder, compoundReference, setCompoundReference }: Props) => {
	const history = useHistory();
	const [, , hasRole, , hasAnyRole] = useUser();
	const isDorfmarkDelivery = order?.destinationAddress
		? isDorfmarkAddress(order.destinationAddress) || isBadFallingBostelAddress(order.destinationAddress)
		: false;
	const isAldManager = hasRole(UserRole.AldManager);

	const reloadAfter = (func: () => Promise<any> | null) => {
		if (!func) return undefined;

		return async () => {
			await func();
			const response = await axios.get(`/api/orders/transports/${order!.id}`);
			setOrder(response.data);
		};
	};

	if (!order) {
		return null;
	}

	const prepareTransportOrderTemplate = (order: TransportOrder) => {
		const name = (order.destinationContact.name ?? "").trim();
		const nameSplit = name.split(" ");
		const firstName = nameSplit.length > 1 ? nameSplit[0] : "";
		const lastName = name.slice(firstName.length).trim();

		return {
			vehicleId: undefined,
			vehiclePlateNumber: order?.vehicle?.plateNumber,
			type: order.type,
			isRegistered: order.isRegistered,
			isPoolfleet: order.isPoolfleet,
			isDrivable: order.isVehicleReadyForDriving,
			delivery: {
				address: {
					name: order.destinationAddress.name,
					street: order.destinationAddress.street,
					zipCode: order.destinationAddress.zipCode,
					city: order.destinationAddress.city,
					country: order.destinationAddress.country,
				},
				contact: {
					firstName: !firstName ? " " : firstName,
					lastName: lastName,
					email: order.destinationContact.email,
					telephone: order.destinationContact.telephone,
				},
				deliveryDate: order.requestedDeliveryDate,
				company: order.assignedTo.name,
				comment: order.remarks,
			},
			collection: {
				collectionDate: order.plannedPickupDate,
			},
		};
	};

	return (
		<Sidebar>
			<SidebarGroup>
				{order.confirm && (
					<SidebarButton color="primary" onClick={reloadAfter(order.confirm)} label="Bestätigen" />
				)}
				{!compoundReference && order.vehicleId && (
					<SidebarButton
						onClick={() => history.push(`/vehicles/${order.vehicleId}`)}
						label="Zur Platzlogistik"
					/>
				)}
				{compoundReference &&
					!compoundReference.compoundVehicle &&
					compoundReference.allowCompoundRegistration && (
						<SidebarButton
							color="primary"
							onClick={async () => {
								const compoundReference = await order.postCompoundReference();
								setCompoundReference(undefined);
								setOrder((o) => ({
									...o,
									vehicleId: compoundReference.compoundVehicleId,
								}));
							}}
							label="Registrierung in der Platzlogistik"
						/>
					)}
				{order.vehicleArrivalId && (
					<SidebarButton
						onClick={() => history.push(`/vehicles/arrivals/${order.vehicleArrivalId}`)}
						label="Zur Anlieferung"
					/>
				)}
			</SidebarGroup>
			<SidebarGroup>
				{!isDorfmarkDelivery &&
					isAldManager &&
					order.status !== "Completed" &&
					order.status !== "Cancelled" &&
					(!order.returnDate || !order.vehicleId) && (
						<SidebarButton
							color="primary"
							onClick={reloadAfter(
								!order.returnDate
									? order.assignReturnDate
									: !order.vehicleId
										? order.completeDelivery
										: undefined,
							)}
							label="Auftrag abschließen"
						/>
					)}
			</SidebarGroup>
			<SidebarGroup>
				{order.requestCancellation && (
					<SidebarButton
						color="secondary"
						disabled={order.status === "Assigned" && !hasRole(UserRole.LogisticCompany) && !isAldManager}
						onClick={reloadAfter(order.requestCancellation)}
						label="Auftrag abbrechen"
					/>
				)}
				{order.reopen && (
					<SidebarButton color="secondary" onClick={reloadAfter(order.reopen)} label="Auftrag reaktivieren" />
				)}
			</SidebarGroup>
			<SidebarGroup>
				{order.assignedTo && hasAnyRole([UserRole.AldUser, UserRole.LogisticCompany]) && (
					<SidebarButton onClick={reloadAfter(order.downloadOrder)} label="Auftrag drucken" />
				)}
				<SidebarButton onClick={reloadAfter(order.downloadQRCode)} label="QR Code drucken" />
				{order.source === "Stock" && (
					<SidebarButton
						onClick={reloadAfter(order.downloadCommissionCsv)}
						label="Kommissions-Csv herunterladen"
					/>
				)}
			</SidebarGroup>
			{isAldManager && order?.type === "Delivery" && (
				<SidebarGroup>
					<SidebarButton
						label="Als Vorlage nutzen"
						onClick={() => history.push("/orders/transports/new", prepareTransportOrderTemplate(order))}
					/>
				</SidebarGroup>
			)}
		</Sidebar>
	);
};
