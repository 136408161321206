import { ReturnOrder } from "./Domain";
import { useCallback, useState } from "react";
import useAsyncEffect from "./useAsyncEffect";
import axios from "axios";

const useReturnOrder = (id? : string) : [ReturnOrder | undefined, boolean] => {
	const [returnOrder, setReturnOrder] = useState<ReturnOrder>();
	const [hasLoaded, setHasLoaded] = useState<boolean>(false);

	const load = useCallback(async (id?:string) => {
		if (!id) return;

		const { data: ro } = await axios.get<ReturnOrder[]>(`/api/orders/returns`, {
			params: {
				id: id
			}
		});

		setHasLoaded(true);

		if (ro.length !== 1) {
			return;
		}

		setReturnOrder(ro[0]);
	}, [id]);

	useAsyncEffect(async () => {
		setReturnOrder(undefined);
		setHasLoaded(false);
		await load(id);
	}, [id]);

	return [
		returnOrder,
		hasLoaded
	];
};

export default useReturnOrder;
