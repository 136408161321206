import { useState } from 'react';

import { Check } from '@mui/icons-material';
import { Box, CircularProgress, Grid, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';

import FormElement from '../../../../Components/FormElement';
import FormSignature from '../../../../Components/FormSignature';
import FormText from '../../../../Components/FormText';
import { Form } from '../../../../system/useForm';
import { isValidText } from '../../../../system/validators';
import { TemplateVersion, ReturnForm } from "../../../../system/Domain";


const returnFormTextVersions = {
	["InitialTemplateVersionTill062023" as TemplateVersion]: <>Dieses Rückgabeprotokoll erfasst ausschließlich optische und keine versteckten bzw. technischen Mängel. Sollte der Leasinggeber nach Übernahme des Leasingfahrzeuges versteckte oder technische Mängel an diesem feststellen (z.B. auf Grund eines eingeholten Sachverständigen Gutachtens), behält sich der Leasinggeber die Geltendmachung entsprechender Ansprüche gegenüber dem Leasingnehmer hiermit ausdrücklich vor. Dies gilt auch für <b>optische Mängel, die aufgrund von eingeschränkten Besichtigungsbedingungen (z.B. Regen, Schnee, Dunkelheit) bei der Rücknahme nicht erkannt werden konnten.</b> Festgestelle Mängel, km-Stand und Rückgabe-Datum sowie –Uhrzeit werden durch Unterschrift bestätigt. Die Bewertung der aufgenommenen Schäden erfolgt später durch einen Sachverständigen."</>,
	["TemplateVersionAfter062023LiabilityClause" as TemplateVersion]: <>Dieses Rückgabeprotokoll erfasst ausschließlich optische und keine versteckten bzw. technischen Mängel. Sollte der Leasinggeber nach Übernahme des Leasingfahrzeuges versteckte oder technische Mängel an diesem feststellen (z.B. auf Grund eines eingeholten Sachverständigen Gutachtens), behält sich der Leasinggeber die Geltendmachung entsprechender Ansprüche gegenüber dem Leasingnehmer hiermit ausdrücklich vor. Dies gilt auch für <b>optische Mängel, die aufgrund von eingeschränkten Besichtigungsbedingungen (z.B. Regen, Schnee, Dunkelheit) bei der Rücknahme nicht erkannt werden konnten.</b> Festgestelle Mängel, km-Stand und Rückgabe-Datum sowie –Uhrzeit werden durch Unterschrift bestätigt. Die Bewertung der aufgenommenen Schäden erfolgt später durch einen Sachverständigen.<br/><br/>Alle Schadenangaben im Protokoll sind vorbehaltlich der abschließenden Prüfung bei der Erstellung des Rückgabegutachtens. Dem Leasingnehmer steht es frei bei später dokumentierten Schäden den Nachweis zu führen, dass diese Schäden bei Übergabe nicht vorhanden waren.</>
}

export default ({ returnForm, form, save, unsavedChanges } : { returnForm : ReturnForm, form : Form, save : () => void, unsavedChanges : boolean }) => {
	const theme = useTheme();
	const [isInitiallyReadOnly] = useState(form.readOnly);
	const isValid = returnForm.isValid();
	const isLocked = returnForm.isLocked();
	const isSigned = returnForm.isSigned();

	const validDelivererInfo = isValidText(returnForm?.signatures?.delivererInfo?.firstName)
		&& isValidText(returnForm?.signatures?.delivererInfo?.lastName);

	const validRecipientInfo = isValidText(returnForm?.signatures?.recipientInfo?.firstName)
		&& isValidText(returnForm?.signatures?.recipientInfo?.lastName);

	const isDelivererLocked = !!returnForm?.signatures?.deliverer?.path || !!returnForm?.signatures?.deliverer?.image;
	const isRecipientLocked = !!returnForm?.signatures?.recipient?.path || !!returnForm?.signatures?.recipient?.image;

	if (!returnForm.id) {
		returnForm.templateVersion = "TemplateVersionAfter062023LiabilityClause";
	}

	return (
		<>
			{!isValid && (
				<Grid item xs={12}>
					<Typography variant="body1" sx={{
						color: theme.palette.secondary.main
					}}>Das Protokoll kann erst unterzeichnet werden
						wenn alle Pflichtfelder vollständig ausgefüllt sind</Typography>
				</Grid>
			)}
			{(isValid || isLocked) && (
				<>
					<Grid item xs={12}>
						<Typography variant="body1">
							{returnFormTextVersions[returnForm.templateVersion]}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Box>
							<Typography variant="subtitle1" color="textPrimary">Unterschrift Abgabe</Typography>
						</Box>
					</Grid>
					<FormText
						name="signatures.delivererInfo.firstName"
						label="Vorname"
						form={form}
						readOnly={isDelivererLocked}
						options={{
							required: true
						}}
					/>
					<FormText
						name="signatures.delivererInfo.lastName"
						label="Nachname"
						form={form}
						readOnly={isDelivererLocked}
						options={{
							required: true
						}}
					/>
					<FormSignature
						name="signatures.deliverer"
						label="Unterschrift"
						form={form}
						onSign={save}
						onReset={save}
						allowSign={validDelivererInfo}
						allowRemoval={!isSigned}
					/>
					<Grid item xs={12}>
						<Box mt={2}>
							<Typography variant="subtitle1" color="textPrimary">Unterschrift Annahme</Typography>
						</Box>
					</Grid>
					<FormText
						name="signatures.recipientInfo.firstName"
						label="Vorname"
						form={form}
						readOnly={isRecipientLocked}
						options={{
							required: true
						}}
					/>
					<FormText
						name="signatures.recipientInfo.lastName"
						label="Nachname"
						form={form}
						readOnly={isRecipientLocked}
						options={{
							required: true
						}}
					/>
					<FormSignature
						name="signatures.recipient"
						label="Unterschrift"
						form={form}
						onSign={save}
						onReset={save}
						allowSign={validRecipientInfo}
						allowRemoval={!isSigned}
					/>
					{isValid && isSigned && !isInitiallyReadOnly && (
						<FormElement label="Speichern">
							{unsavedChanges && (
								<CircularProgress size={48} />
							)}
							{!unsavedChanges && (
								<Check />
							)}
						</FormElement>
					)}
				</>
			)}
		</>
	);
};
