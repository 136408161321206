import _ from "lodash";
import { useRecoilState } from "recoil";
import useHotkeys from "system/useHotkeys";
import { Box, InputBase, useTheme } from "@mui/material";
import { navigationAtom } from "system/atoms/navigation";
import { PropsWithChildren, useCallback, useState } from "react";
import { lineLength, Point, Polygon, polygonCentroid, polygonIntersectsPolygon } from "geometric";

type Direction = "up" | "down" | "left" | "right";

export default ({ children }: PropsWithChildren<any>) => {
	const theme = useTheme();

	const [mode, setMode] = useState<"select" | "filter">("select");
	useHotkeys(
		"/",
		() => {
			if (mode !== "filter") {
				setMode("filter");
			} else {
				setMode("select");
			}

			return false;
		},
		[mode],
	);

	useHotkeys(
		"enter",
		() => {
			if (mode !== "filter") {
				return;
			}

			const nodes = Array.from(document.querySelectorAll("[data-navigation-id]"));
			if (nodes.length > 0) {
				setNavigation((n) => ({
					...n,
					selected: nodes[0].getAttribute("data-navigation-id") || "",
				}));
			}

			setMode("select");
		},
		[mode],
	);

	const [navigation, setNavigation] = useRecoilState(navigationAtom);

	const moveSelection = useCallback(
		(direction: Direction) => {
			if (mode !== "select") {
				return;
			}

			const nodes = Array.from(document.querySelectorAll("[data-navigation-id]"));
			if (nodes.length === 0) {
				return;
			}

			const getPolygon = (rect: DOMRect): Polygon => [
				[rect.x, rect.y],
				[rect.x + rect.width, rect.y],
				[rect.x + rect.width, rect.y + rect.height],
				[rect.x, rect.y + rect.height],
			];

			const items = nodes
				.map((n) => ({
					node: n,
					id: n.getAttribute("data-navigation-id"),
					title: n.getAttribute("data-navigation-title"),
					bounds: n.getBoundingClientRect(),
				}))
				.map((n) => ({
					...n,
					center: [n.bounds.x + n.bounds.width / 2, n.bounds.y + n.bounds.height / 2],
					polygon: getPolygon(n.bounds),
				}));

			const tl: Point = [_.min(items.map((i) => i.bounds.x)) || 0, _.min(items.map((i) => i.bounds.y)) || 0];

			const br: Point = [
				_.max(items.map((i) => i.bounds.x)) || window.outerWidth,
				_.max(items.map((i) => i.bounds.y)) || window.outerHeight,
			];

			//Unused variables
			// const w = _.meanBy(items, i => i.bounds.width);
			// const h = _.meanBy(items, i => i.bounds.height);

			const getRay = (polygon: Polygon, direction: Direction): Polygon => {
				switch (direction) {
					case "up":
						return [[polygon[0][0], tl[1]], [polygon[1][0], tl[1]], polygon[2], polygon[3]];
					case "down":
						return [polygon[0], polygon[1], [polygon[2][0], br[1]], [polygon[3][0], br[1]]];
					case "left":
						return [[tl[0], polygon[0][1]], polygon[1], polygon[2], [tl[0], polygon[3][1]]];
					case "right":
						return [polygon[0], [br[0], polygon[1][1]], [br[0], polygon[2][1]], polygon[3]];
				}
			};

			const currentItem = items.find((i) => i.id === navigation.selected) ?? items[0];
			const center = polygonCentroid(currentItem.polygon);
			const ray = getRay(currentItem.polygon, direction);

			const nearestItems = _(items)
				.filter((i) => i.id !== navigation.selected)
				.map((i) => ({
					...i,
					intersects: polygonIntersectsPolygon(i.polygon, ray),
					distance: lineLength([[i.center[0], i.center[1]], center]),
				}))
				.filter((i) => i.intersects)
				.orderBy([(i) => i.distance], ["asc", "asc"])
				.value();

			if (nearestItems.length === 0) {
				return;
			}

			const nearestItem = nearestItems[0];
			nearestItem.node.scrollIntoView({
				behavior: "smooth",
				block: "center",
			});

			setNavigation((n) => ({
				...n,
				selected: nearestItem.id || "",
			}));
		},
		[mode, navigation.selected],
	);

	useHotkeys("up, k", () => moveSelection("up"), [moveSelection]);
	useHotkeys("down, j", () => moveSelection("down"), [moveSelection]);
	useHotkeys("left, h", () => moveSelection("left"), [moveSelection]);
	useHotkeys("right, l", () => moveSelection("right"), [moveSelection]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: navigation.orientation === "horizontal" ? "column" : "row-reverse",
				justifyContent: "start",
				flexWrap: "wrap",
			}}
		>
			{mode === "filter" && (
				<InputBase
					sx={{
						position: "absolute",
						opacity: 1,
						bottom: 0,
						backgroundColor: "rgba(0, 0, 0, 0.3)",
						width: "100%",
						textAlign: "center",
						fontSize: "1.8rem",
						fontWeight: 100,
						paddingLeft: theme.spacing(2),
						paddingRight: theme.spacing(2),
						paddingTop: theme.spacing(1),
						paddingBottom: theme.spacing(1),
						"& input": {
							textAlign: "center",
							letterSpacing: "0.15rem",
						},
					}}
					value={navigation.filter}
					onChange={(e) =>
						setNavigation((n) => ({
							...n,
							filter: e.target.value,
						}))
					}
					autoFocus
				/>
			)}
			{children}
		</Box>
	);
};
