import * as turf from "@turf/turf";
import { GeoPoint, CompoundPlace } from "system/Domain";
import { CompoundPlaceDesign } from "./mapUtils";

export const rotate = (compoundPlace: CompoundPlaceDesign, direction: number): CompoundPlaceDesign => {
	const polygon = createTurfPolygon(compoundPlace.geoArea.points);
	const centroid = turf.centroid(polygon);

	const rotatedPolygon = turf.transformRotate(polygon, direction, { pivot: centroid.geometry.coordinates });
	compoundPlace.geoArea.points = closeLoop(rotatedPolygon.geometry.coordinates as number[][][]);
	return compoundPlace;
};

export const merge = (compoundPlace1: CompoundPlace, compoundPlace2: CompoundPlace): CompoundPlace => {
	const polygon1 = createTurfPolygon(compoundPlace1.geoArea.points);
	const polygon2 = createTurfPolygon(compoundPlace2.geoArea.points);

	const mergedPolygon = turf.union(polygon1, polygon2) as turf.helpers.Feature<turf.helpers.Polygon>;

	const mergedCompoundPlace: CompoundPlace = <CompoundPlace>{
		name: `${compoundPlace1.name} + ${compoundPlace2.name}`,
		geoArea: { points: polygonToGeoPoints(mergedPolygon) },
		createdBy: compoundPlace1.createdBy,
		id: compoundPlace1.id,
	};

	return mergedCompoundPlace;
};

export const pararelBorders = () => {};

export const changeAngle = () => {};

export const cutVertical = (compoundPlace: CompoundPlaceDesign) => {
	const polygon = createTurfPolygon(compoundPlace.geoArea.points);
	const centroid = turf.centroid(polygon);
	const bbox = turf.bbox(polygon);

	const polyLeft = turf.bboxClip(polygon, [bbox[0], bbox[1], centroid.geometry.coordinates[0], bbox[3]]);
	const polyRight = turf.bboxClip(polygon, [centroid.geometry.coordinates[0], bbox[1], bbox[2], bbox[3]]);

	compoundPlace.geoArea.points = closeLoop(polyLeft.geometry.coordinates as number[][][]);
	const compoundPlace1: CompoundPlaceDesign = {
		name: `${compoundPlace.name}-1`,
		isActive: compoundPlace.isActive,
		isNew: true,
		geoArea: {
			points: closeLoop(polyRight.geometry.coordinates as number[][][]),
		},
		compound: compoundPlace.compound,
	} as CompoundPlaceDesign;

	return [compoundPlace, compoundPlace1];
};

export const cutHorizontal = (compoundPlace: CompoundPlaceDesign) => {
	const polygon = createTurfPolygon(compoundPlace.geoArea.points);
	const centroid = turf.centroid(polygon);
	const bbox = turf.bbox(polygon);

	const polyBottom = turf.bboxClip(polygon, [bbox[0], bbox[1], bbox[2], centroid.geometry.coordinates[1]]);
	const polyTop = turf.bboxClip(polygon, [bbox[0], centroid.geometry.coordinates[1], bbox[2], bbox[3]]);

	compoundPlace.geoArea.points = closeLoop(polyTop.geometry.coordinates as number[][][]);
	const compoundPlace1: CompoundPlaceDesign = {
		name: `${compoundPlace.name}-1`,
		isActive: compoundPlace.isActive,
		geoArea: {
			points: closeLoop(polyBottom.geometry.coordinates as number[][][]),
		},
		compound: compoundPlace.compound,
	} as CompoundPlaceDesign;

	return [compoundPlace, compoundPlace1];
};
const closeLoop = (coords: number[][][]): GeoPoint[] => {
	const loop = coords[0];
	if (loop[0] !== loop[loop.length - 1]) {
		loop.push(loop[0]);
	}
	return loop.map((coord) => ({ lat: coord[1], lon: coord[0] }));
};

/**
 * Converts an array of GeoPoint objects into a Turf.js polygon.
 *
 * This function maps each GeoPoint to a [lon, lat] format required by Turf.js,
 * ensures the polygon path is closed by comparing the first and last points,
 * and if they are not the same, it duplicates the first point at the end of the array.
 * In the end, it creates and returns a Turf.js polygon.
 *
 * @param {GeoPoint[]} inputCoords - The array of GeoPoint objects to be converted into a polygon.
 * @returns {turf.helpers.Feature<turf.helpers.Polygon>} A Turf.js polygon created from the input coordinates.
 */

const createTurfPolygon = (inputCoords: GeoPoint[]): turf.helpers.Feature<turf.helpers.Polygon> => {
	const convertedCoords = inputCoords.map((coord) => [coord.lon, coord.lat]);

	if (
		convertedCoords[0][0] !== convertedCoords[convertedCoords.length - 1][0] ||
		convertedCoords[0][1] !== convertedCoords[convertedCoords.length - 1][1]
	) {
		convertedCoords.push(convertedCoords[0]);
	}

	const polygon = turf.polygon([convertedCoords]);

	return polygon;
};

/**
 * Converts a Turf.js polygon into an array of GeoPoint objects.
 *
 * This function extracts the coordinates from the first LinearRing (outer boundary)
 * of the polygon and maps them to an array of GeoPoint objects, each with 'lon' and 'lat'
 * properties representing longitude and latitude, respectively.
 *
 * @param {turf.helpers.Feature<turf.helpers.Polygon>} polygon - The Turf.js polygon to be converted.
 * @returns An array of GeoPoint objects extracted from the polygon.
 */
const polygonToGeoPoints = (polygon: turf.helpers.Feature<turf.helpers.Polygon>): GeoPoint[] => {
	const coordinates = polygon.geometry.coordinates[0];
	const geoPoints: GeoPoint[] = coordinates.map(([lon, lat]) => ({ lon, lat }));

	// Optionally, remove the last point if it's the same as the first (closing point of the polygon)
	if (
		geoPoints.length > 1 &&
		geoPoints[0].lon === geoPoints[geoPoints.length - 1].lon &&
		geoPoints[0].lat === geoPoints[geoPoints.length - 1].lat
	) {
		geoPoints.pop();
	}

	return geoPoints;
};
