import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import Layout from "../../Components/Layout/Layout";
import { Invoice } from "../../system/Domain";
import Search from "../../Components/Search";
import _ from "lodash";
import querystring from "query-string";
import InvoicesTable from "./InvoiceTable";
import FetchNextButton from "../../Components/FetchNextButton";
import { useAsync } from "react-use";
import InvoicesSidebar from "./InvoicesSidebar";
import OrdersNavigation from "Navigation/OrdersNavigation";

interface InvoiceData {
	[key: string]: Invoice[]
}

export default () => {
	const [queryParams, setQueryParams] = useState({
		skip: 0,
		search: ""
	});

	const take = 20;

	const [invoices, setInvoices] = useState<Invoice[]>([]);
	const [invoicesPerMonths, setInvoicesPerMonths] = useState<InvoiceData>({});

	useAsync(async () => {
		const query = querystring.stringify({
			...queryParams,
			take
		}, {
			skipEmptyString: true
		}
		);

		const { data: newInvoices } = await axios.get<Invoice[]>(`/api/invoices?${query}`);

		if (queryParams.skip === 0) {
			setInvoices(newInvoices);
		} else {
			setInvoices(invoices => [
				...invoices,
				...newInvoices
			]);
		}
	}, [queryParams, take]);

	useEffect(() => {
		const invoicesPerMonths = _.groupBy(
			invoices,
			i => moment(i.dateCreated).format("YYYYMM")
		);
		setInvoicesPerMonths(invoicesPerMonths);
	}, [invoices]);

	const handleSearch = (search: string) => {
		setQueryParams({
			skip: 0,
			search
		});
	};

	const handleNext = async () => {
		setQueryParams(queryParams => ({
			...queryParams,
			skip: queryParams.skip + take
		}));
	};

	return (
		<Layout
			title="Rechnungen"
			navigation={<OrdersNavigation />}
			sidebar={(
				<InvoicesSidebar
					invoices={invoices}
					setInvoices={setInvoices}
				/>
			)}
		>
			<Search
				onSearch={handleSearch}
				help="You can filter by: example"
				fullWidth
			/>
			{Object.keys(invoicesPerMonths).sort().reverse().map(k => {
				const firstDayOfMonth = moment(k).format("L");
				const lastDayOfMonth = moment(k).endOf("month").format("L");

				return (
					<Box my={4} key={k}>
						<Typography gutterBottom sx={{ fontWeight: 300 }}>
							{`${firstDayOfMonth} - ${lastDayOfMonth}`}
						</Typography>

						<InvoicesTable invoices={invoicesPerMonths[k]} />
					</Box>
				);
			})}
			<FetchNextButton
				hidden={invoices.length < take + queryParams.skip}
				mb={3}
				onNext={handleNext}
			/>
		</Layout>
	);
};
