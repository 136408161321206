import {Button, IconButton, ButtonProps } from "@mui/material";

interface ActionButtonProps extends ButtonProps {
	hideOnDisabled : boolean
	icon? : boolean
}

const ActionButton = ({ onClick, hideOnDisabled, icon = false, size = "medium", variant = "outlined", color = "primary", ...rest } : ActionButtonProps) => {

	if (!onClick && hideOnDisabled) return null;

	if (icon) {
		return <IconButton
			color={color}
			disabled={!onClick}
			onClick={onClick}
			// @ts-ignore
			size={size}
			{...rest}
		/>
	}

	return (
		<Button
			variant={variant}
			size={size}
			color={color}
			disabled={!onClick}
			onClick={onClick}
			{...rest}
		/>
	);
};

ActionButton.defaultProps = {
	hideOnDisabled: false
};

export default ActionButton;
