import useForm from "../../system/useForm";
import { useEffect, useState } from "react";
import Layout from "../../Components/Layout/Layout";
import { Box, Grid, useTheme } from "@mui/material";
import FormSelect from "../../Components/FormSelect";
import FormText from "../../Components/FormText";
import FormDateTimePicker from "../../Components/FormDateTimePicker";
import { FleetnetVehicle, FleetnetVehicleQuery, LeasmanVehicle, LeasmanVehicleQuery } from "../../system/Domain";
import Tile from "../../Components/Tiles/Tile";
import axios from "axios";
import useAxiosEffect from "../../system/useAxiosEffect";
import _ from "lodash";
import NewVehicleSidebar from "./NewVehicleSidebar";
import LeasmanVehicleSearchTile from "./LeasmanVehicleSearchTile";
import dateFormats from "../../system/dateFormats";
import CatalogPreviewListTile from "./CatalogPreviewSelectTile";
import FleetnetVehicleSearchTile from "./FleetnetVehicleSearchTile";
import FleetnetVehicleListTile from "./FleetnetVehicleListTile";
import LeasmanVehicleListTile from "./LeasmanVehicleListTile";

type VehicleSource = "Leasman" | "Fleetnet" | "Catalog";
export interface NewVehicleRequest {
	source: VehicleSource,
	leasman?: {
		id?: string
		vin?: string
	},
	fleetnet?: {
		id?: number
		vin?: string
	},
	catalog?: {
		id?: number
		vin?: string
		plateNumber?: string
		dateFirstRegistered?: string
	}
}

export default () => {
	const theme = useTheme();

	const [request, setRequest] = useState<NewVehicleRequest>({
		source: "Leasman"
	});

	const form = useForm({
		values: request,
		setValues: setRequest
	});

	const [leasmanQuery, setLeasmanQuery] = useState<LeasmanVehicleQuery>({
		// plateNumber: "N-QS-1000"
		// vin: "WVWZZZ3CZHE159923"
		includeDetails: false
	});

	const [leasmanVehicles, setLeasmanVehicles] = useState<LeasmanVehicle[]>();
	const [loadingLeasman] = useAxiosEffect(async config => {
		if (!leasmanQuery.vin && !leasmanQuery.contractId && !leasmanQuery.plateNumber) {
			setLeasmanVehicles(undefined);
			return;
		}

		const { data } = await axios.post<LeasmanVehicle[]>(`/api/leasman/vehicles/search-db`, {
			tenants: leasmanQuery.tenants,
			fullText: leasmanQuery.fullText,
			customerIds: leasmanQuery.customerIds ?? [],
			includeDetails: leasmanQuery.includeDetails,
			plateNumbers: leasmanQuery.plateNumber ? [leasmanQuery.plateNumber
				?.replaceAll(' ', '-')
				?.toUpperCase()] : [],
			vins: leasmanQuery.vin ? [leasmanQuery.vin] : [],
			contractIds: leasmanQuery.contractId ? [leasmanQuery.contractId] : []
		}, config);

		setLeasmanVehicles(_.orderBy(data, v => v.contract.begin.date, 'desc'));
		setRequest(r => ({
			...r,
			leasman: {
				...r.leasman,
				id: undefined
			}
		}));
	}, [leasmanQuery]);

	const [fleetnetQuery, setFleetnetQuery] = useState<FleetnetVehicleQuery>({
		// plateNumber: "HH-T 2690E"
	});

	const [fleetnetVehicles, setFleetnetVehicles] = useState<FleetnetVehicle[]>();
	const [loadingFleetnet] = useAxiosEffect(async config => {
		if (!fleetnetQuery.vin && !fleetnetQuery.plateNumber && !fleetnetQuery.clientNr && !fleetnetQuery.nr) {
			setFleetnetVehicles(undefined);
			return;
		}

		const { data } = await axios.post<FleetnetVehicle[]>(`/api/fleetnet/vehicles/search-db`, {
			...fleetnetQuery,
			skip: undefined,
			take: 25,
			plateNumber: fleetnetQuery.plateNumber
				// ?.replaceAll(' ', '-')
				?.toUpperCase()
		}, config);

		setFleetnetVehicles(_.orderBy(data, v => v.dateFirstRegistered, 'desc'));
		setRequest(r => ({
			...r,
			fleetnet: {
				...r.fleetnet,
				id: undefined
			}
		}));
	}, [fleetnetQuery]);

	useEffect(() => {
		switch (request.source) {
			case "Leasman":
				setRequest(r => ({
					source: r.source,
					leasman: r.leasman
				}));

				break;
			case "Fleetnet":
				setRequest(r => ({
					source: r.source,
					fleetnet: r.fleetnet
				}));

				break;
			case "Catalog":
				setRequest(r => ({
					source: r.source,
					catalog: r.catalog
				}));

				break;
		}
	}, [request.source]);

	return (
		<Layout
			title="Neues Fahrzeug"
			sidebar={(
				<NewVehicleSidebar
					request={request}
				/>
			)}
		>
			<Box sx={{
				display: "flex",
				gap: theme.spacing(6),
				alignItems: "self-start"
			}} mb={6}>
				<Box sx={{
					flexShrink: 0,
					width: 300
				}}>
					<Grid container spacing={3}>
						<FormSelect
							form={form}
							name="source"
							label="Quelle"
							choices={{
								"Leasman": "Leasman",
								"Fleetnet": "Fleetnet",
								"Catalog": "Fahrzeugkatalog",
							}}
							fullWidth
						/>
					</Grid>
				</Box>
			</Box>
			{request.source === "Leasman" && (
				<Box sx={{
					display: "flex",
					gap: theme.spacing(6),
					alignItems: "self-start"
				}}>
					<Box sx={{
						flexShrink: 0,
						width: 300
					}}>
						<LeasmanVehicleSearchTile
							query={leasmanQuery}
							setQuery={setLeasmanQuery}
						/>
					</Box>
					{(loadingLeasman || !!leasmanVehicles) && (
						<Box sx={{
							flexGrow: 1
						}}>
							<LeasmanVehicleListTile
								isLoading={loadingLeasman}
								leasmanVehicles={leasmanVehicles}
								isSelected={v => v.id === request.leasman?.id}
								onSelect={v => setRequest(r => ({
									...r,
									leasman: !v ? undefined : {
										id: v.id,
										vin: v.vin
									}
								}))}
							/>
						</Box>
					)}
				</Box>
			)}

			{request.source === "Fleetnet" && (
				<Box sx={{
					display: "flex",
					gap: theme.spacing(6),
					alignItems: "self-start"
				}}>
					<Box sx={{
						flexShrink: 0,
						width: 300
					}}>
						<FleetnetVehicleSearchTile
							query={fleetnetQuery}
							setQuery={setFleetnetQuery}
						/>
					</Box>
					{(loadingFleetnet || !!fleetnetVehicles) && (
						<Box sx={{
							flexGrow: 1
						}}>
							<FleetnetVehicleListTile
								isLoading={loadingFleetnet}
								fleetnetVehicles={fleetnetVehicles}
								isSelected={v => v.id === request.fleetnet?.id}
								onSelect={v => setRequest(r => ({
									...r,
									fleetnet: !v ? undefined : {
										id: v.id,
										vin: v.vin
									}
								}))}
							/>
						</Box>
					)}
				</Box>
			)}

			{request.source === "Catalog" && (
				<Box sx={{
					display: "flex",
					gap: theme.spacing(6),
					alignItems: "self-start",
				}}>
					<Box sx={{
						flexShrink: 0,
						width: 300
					}}>
						<Tile title="Zulassung">
							<Grid container spacing={3}>
								<FormText
									form={form}
									name="catalog.vin"
									label="FIN"
									options={{ required: true }}
									fullWidth
								/>
								<FormText
									form={form}
									name="catalog.plateNumber"
									label="Kennzeichen"
									options={{ required: true }}
									fullWidth
								/>
								<Grid item xs={12} sx={{ width: "100%" }}>
									<FormDateTimePicker
										form={form}
										name="catalog.dateFirstRegistered"
										label="Erstzulassung"
										variant="date"
										format={dateFormats.date}
										required
										fullWidth
									/>
								</Grid>
							</Grid>
						</Tile>
					</Box>
					<Box sx={{ flexGrow: 1 }}>
						<CatalogPreviewListTile
							isSelected={p => p.id === request.catalog?.id}
							onSelect={p => setRequest(r => ({
								...r,
								catalog: {
									...r.catalog,
									id: !p ? undefined : p.id
								}
							}))}
						/>
					</Box>
				</Box>
			)}
		</Layout>
	);
}
