import { Box, Paper, Table, TableBody, TableCell, TableRow } from "@mui/material";
import Typography from "@mui/material/Typography";
import moment from "moment";
import TextBlock from "../../Components/Layout/TextBlock";
import dateFormats from "../../system/dateFormats";
import { PoolFleetVehicle } from "../../system/Domain";
import poolFleetLotStatus from "./poolFleetLotStatus";
import poolFleetStatus from "./poolFleetStatus";

interface Props {
	vehicle: PoolFleetVehicle
}

function PoolFleetVehicleHistoryCard({ vehicle }: Props) {
	return (
		<Box>
			<Typography gutterBottom variant="h5">Historie</Typography>
			<Paper>
				<Table>
					<TableBody>
						{vehicle.history.entries.map(he => {
							const date = moment(he.date);

							return (
								<TableRow key={date.toDate().getTime()}>
									<TableCell>
										<TextBlock
											primary={date.format(dateFormats.dateTime)}
											secondary={date.fromNow()}
										/>
									</TableCell>
									<TableCell>
										<TextBlock
											primary={poolFleetStatus[he.status]}
											secondary={he.text}
										/>
									</TableCell>
									<TableCell>
										<TextBlock
											primary={poolFleetLotStatus[he.lotStatus]}
											secondary={he.text}
										/>
									</TableCell>
									<TableCell>
										<TextBlock
											primary={he.user.name}
											secondary={he.user.email}
										/>
									</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</Paper>
		</Box>
	);
}

export default PoolFleetVehicleHistoryCard;
