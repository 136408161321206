import { Button, Chip, CircularProgress, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import Typography from "@mui/material/Typography";
import axios from "axios";
import PaperTable from "Components/PaperTable";
import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useAsync } from "react-use";
import Layout from "../../Components/Layout/Layout";
import { Plate } from "../../Components/Plate";
import { PoolFleetVehicle } from "../../system/Domain";
import poolFleetLotStatus from "./poolFleetLotStatus";
import poolFleetStatus from "./poolFleetStatus";
import { PoolFleetVehiclesSidebar } from "./PoolFleetVehiclesSidebar";

function PoolFleetVehiclesPage() {
	const location = useLocation();
	const [vehicles, setVehicles] = useState<PoolFleetVehicle[]>([]);
	const history = useHistory();

	const { loading } = useAsync(async () => {
		let url = "/api/pool-fleet/vehicles";

		if (location.search) {
			url += location.search;
		}

		const { data: vehicles } = await axios.get<PoolFleetVehicle[]>(url);
		setVehicles(vehicles);
	}, [location]);

	function createAddToInventory(vehicle: PoolFleetVehicle) {
		if (vehicle.status !== "Unavailable") {
			return undefined;
		}

		return async function () {
			const { data: changedVehicle } = await axios.post<PoolFleetVehicle>(`/api/pool-fleet/vehicles`, vehicle);
			const index = vehicles.findIndex((v) => v.contractId === vehicle.contractId);

			if (changedVehicle.status !== vehicle.status) {
				setVehicles((vehicles) => [...vehicles.slice(0, index), ...vehicles.slice(index + 1)]);
			} else {
				setVehicles((vehicles) => [...vehicles.slice(0, index), changedVehicle, ...vehicles.slice(index + 1)]);
			}
		};
	}

	function createSelect(vehicle: PoolFleetVehicle) {
		if (vehicle.status === "Unavailable") {
			return undefined;
		}

		return function () {
			history.push(`/pool-fleet/vehicles/${vehicle.contractId}`);
		};
	}

	return (
		<Layout title="Flex Fahrzeuge" sidebar={<PoolFleetVehiclesSidebar />}>
			<PaperTable>
				<TableHead>
					<TableRow>
						<TableCell>Fahrzeug</TableCell>
						<TableCell>Vertragsnummer</TableCell>
						<TableCell>Status</TableCell>
						<TableCell>Platz Status</TableCell>
						<TableCell>Kategorien</TableCell>
						<TableCell></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{loading && (
						<TableRow>
							<TableCell colSpan={4}>
								<CircularProgress />
							</TableCell>
						</TableRow>
					)}
					{!loading && vehicles.length === 0 && (
						<TableRow>
							<TableCell colSpan={6}>Keine Daten gefunden</TableCell>
						</TableRow>
					)}
					{!loading &&
						vehicles.map((vehicle) => {
							const addToInventory = createAddToInventory(vehicle);
							const handleSelect = createSelect(vehicle);

							return (
								<TableRow key={vehicle.contractId} hover onClick={handleSelect}>
									<TableCell>
										<>
											<Plate plateNumber={vehicle.plateNumber || "X-XX0"} style={{ zoom: 0.6 }} />
											<Typography color="textSecondary">{vehicle.makeModel}</Typography>
										</>
									</TableCell>
									<TableCell>{vehicle.contractId}</TableCell>
									<TableCell>
										<Chip label={poolFleetStatus[vehicle.status]} />
									</TableCell>
									<TableCell>
										<Chip label={poolFleetLotStatus[vehicle.lotStatus]} />
									</TableCell>
									<TableCell>
										{Object.keys(vehicle.categories).map((key) => {
											const category = vehicle.categories[key];
											return <span key={key}>{`${category} (${key})`}</span>;
										})}
										{Object.keys(vehicle.categories).length === 0 && <span>-</span>}
									</TableCell>
									<TableCell>
										{addToInventory && (
											<Button color="primary" variant="outlined" onClick={addToInventory}>
												Hinzufügen
											</Button>
										)}
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
			</PaperTable>
		</Layout>
	);
}

export default PoolFleetVehiclesPage;
