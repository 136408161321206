import TextBlock from "./Layout/TextBlock";
import formatNumber from "../system/formatNumber";
import { LeasmanContract } from "../system/Domain";
import Tile from "./Tiles/Tile";
import TileContent from "./Tiles/TileContent";
import formatDate from "../system/formatDate";
import FluentGrid from "./FluentGrid";

interface Props {
	contract? : LeasmanContract
}

export const formatYesNo = (value? : boolean | null) => {
	if (value === undefined || value === null) {
		return "-";
	}

	return value ? "Ja" : "Nein";
};

export default ({ contract } : Props) => {
	return (
		<Tile title="Leasingvertrag">
			<TileContent>
				{contract && (
					<FluentGrid itemWidth={150}>
						{/*	<TextBlock primary={formatDate(contract.begin.date)} secondary="Vertragsbeginn" reduced />*/}
						<TextBlock primary={contract.id.toString()} secondary="Vertragsnummer" reduced />
						<TextBlock primary={!!contract.end.date ? formatDate(contract.end.date) : "-"} secondary="Vorraussichtliches Vertragsende" reduced />
						<TextBlock primary={!!contract.return.date ? formatDate(contract.return.date) : "-"} secondary="Rückgabedatum" reduced />
						<TextBlock primary={!!contract.return.mileage ? formatNumber(contract.return.mileage) : "-"} secondary="Rückgabe KM-Stand" reduced />

						<TextBlock primary={formatNumber(contract.duration)} secondary="Laufzeit" reduced />
						<TextBlock primary={formatNumber(contract.mileage)} secondary="Laufleistung" reduced />
						{/*<TextBlock primary={contract.customer.id.toString()} secondary="Kundennummer" reduced />*/}
						{/*<TextBlock primary={(contract.customer.name1 + ' ' + contract.customer.name2).trim()} secondary="Kunde" reduced />*/}
					</FluentGrid>
				)}
				{!contract && (
					<TextBlock primary="-" reduced />
				)}
			</TileContent>
		</Tile>
	);
}
