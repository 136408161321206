import axios from 'axios';
import fileDownload from 'js-file-download';
import { Add, GetApp } from '@mui/icons-material';
import Sidebar from '../../Components/Sidebar/Sidebar';
import SidebarButton from '../../Components/Sidebar/SidebarButton';
import SidebarGroup from '../../Components/Sidebar/SidebarGroup';
import { UserRole } from '../../system/Domain';
import parseContentDispositionFilename from '../../system/parseContentDispositionFilename';
import useUser from '../../system/useUser';

interface Props {
	handleNewUser: () => void
}

const UsersSidebar = (props: Props) => {
	const [, , hasRole] = useUser();

	const isAldAdmin = hasRole(UserRole.AldAdmin);
	const isUserAdmin = hasRole(UserRole.UserAdmin);

	const triggerExport = async () => {
		const response = await axios.get(`/api/users`, {
			responseType: "blob",
			params: {
				output: "xlsx"
			}
		});

		const fileName = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, fileName);
	};

	const triggerOperationVsRolesExport = async () => {
		const response = await axios.get(`/api/documentation/roles-permissions`, {
			responseType: "blob"
		});

		const fileName = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, fileName);
	};

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarButton
					color="primary"
					startIcon={<Add />}
					label="Benutzer anlegen"
					onClick={props.handleNewUser}
				/>
			</SidebarGroup>
			{isAldAdmin && (
				<SidebarGroup>
					<SidebarButton
						startIcon={<GetApp />}
						onClick={triggerExport}
						label="User-Export"
					/>
				</SidebarGroup>
			)}
			{isUserAdmin && (
				<SidebarGroup>
					<SidebarButton
						startIcon={<GetApp />}
						onClick={triggerOperationVsRolesExport}
						label="Permission-Export"
					/>
				</SidebarGroup>
			)}
		</Sidebar>
	);
};

export default UsersSidebar;
