import fileDownload from "js-file-download";
import { CSSProperties, useState } from "react";
import { GetApp } from "@mui/icons-material";
import { Box, IconButton, useTheme } from "@mui/material";
import parseContentDispositionFilename from "../system/parseContentDispositionFilename";
import useAsyncEffect from "../system/useAsyncEffect";
import axios from "axios";

export type ThumbnailProps = {
	url?: string;
	asyncUrl?: string;
	downloadUrl?: string;
	width?: number;
	height?: number;
	standalone?: boolean;
};

export default ({ url, asyncUrl, downloadUrl, width = 300, height = 200, standalone = false }: ThumbnailProps) => {
	const theme = useTheme();
	const [imgUrl, setImgUrl] = useState(url);

	useAsyncEffect(async () => {
		if (!asyncUrl) {
			return;
		}

		const response = await axios.get(asyncUrl, {
			responseType: "blob",
		});

		const newImgUrl = URL.createObjectURL(response.data);
		setImgUrl(newImgUrl);
	}, [asyncUrl]);

	const download = async () => {
		if (!downloadUrl) {
			return;
		}

		const response = await axios.get(downloadUrl, {
			responseType: "blob",
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);

		fileDownload(response.data, filename);
	};

	const style: CSSProperties | undefined = width ? { width, height } : undefined;

	return (
		<Box
			sx={{
				"&:hover": {
					button: {
						opacity: 1,
					},
				},
				position: "relative",
			}}
			style={style}
		>
			{imgUrl && (
				<Box
					component="img"
					src={imgUrl}
					alt=""
					sx={[
						{
							width: "100%",
							height: "100%",
							objectFit: "contain",
						},
						standalone && {
							objectFit: "cover",
							boxShadow: theme.shadows[1],
							borderRadius: theme.spacing(0.5),
						},
					]}
				/>
			)}
			{downloadUrl && (
				<IconButton
					color="primary"
					onClick={download}
					sx={{
						position: "absolute",
						right: theme.spacing(0.5),
						bottom: theme.spacing(0.5),
						cursor: "pointer",
						opacity: { xs: 1, lg: 0 },
					}}
				>
					<GetApp sx={{ stroke: "rgba(255, 255, 255, 0.6)" }} />
				</IconButton>
			)}
		</Box>
	);
};
