import { ReactElement } from "react";
import {Assignment, Notifications} from "@mui/icons-material";
import { NotificationCategory } from "../../system/Domain";

const icons : { [key in NotificationCategory] : ReactElement } = {
	"Orders": <Assignment />,
	"Notifications": <Notifications />
};

export default ({ category } : { category : NotificationCategory }) => {
	return icons[category];
};
