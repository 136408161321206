import { useState } from "react";
import Layout from "../../Components/Layout/Layout";
import Tile from "../../Components/Tiles/Tile";
import TileContent from "../../Components/Tiles/TileContent";
import DekraNewOrdersTile from "./DekraNewOrdersTile";
import moment from "moment";
import DekraServiceChart from "./DekraServiceChart";
import getInterval, { Interval } from "../../Components/getInterval";
import ReturnsChart from "./ReturnsChart";
import ActionButton from "../../Components/ActionButton";
import TileActions from "../../Components/Tiles/TileActions";
import { Box } from "@mui/material";
import DashboardKpi, { kpiPlaceholder } from "Components/Dashboard/DashboardKpi";
import TileContentActions from "Components/Tiles/TileContentActions";
import TileContentAction from "Components/Tiles/TileContentAction";
import axios, { AxiosRequestConfig } from "axios";
import useAxiosEffect from "system/useAxiosEffect";
import formatNumber from "system/formatNumber";
import { useDispatch } from "react-redux";
import useUser from "system/useUser";
import { UserRole, VehiclesKpis, WorkSessionSalePreparationsPerspective } from "system/Domain";
import { setWorkSession } from "system/reducers/workSessions/actions";
import useChartOptions from "system/useChartOptions";

export default () => {
	const dispatch = useDispatch();
	const [vehiclesKpis, setVehiclesKpis] = useState<VehiclesKpis>();
	const [, , hasRole] = useUser();
	const canStartSessions = hasRole(UserRole.AldUser);
	const [options] = useChartOptions();

	const [interval, setInterval] = useState<Interval>({
		dateFrom: moment().startOf("month").format(moment.HTML5_FMT.DATE),
		dateTo: moment().endOf("month").format(moment.HTML5_FMT.DATE)
	});

	const handleDownloadPhotoBoxStatistic = async () => {
		const result = await getInterval("Zeitraum eingeben", interval);
		if (result) {
			setInterval(result);
		}
	};

	const loadKpis = async (axiosConfig: AxiosRequestConfig) => {
		const { data } = await axios.get<VehiclesKpis>(`/api/kpis/vehicles`, axiosConfig);

		setVehiclesKpis(data);
	};

	const createWorkSession = async (perspective: WorkSessionSalePreparationsPerspective, title: string) => {
		const { data: workSession } = await axios.post(`/api/work-sessions/presales`, {
			perspective: perspective,
			title: title
		});

		if (workSession) {
			dispatch(setWorkSession(workSession));
		}
	};

	useAxiosEffect(loadKpis, []);

	return (
		<Layout
			title="Dekra"
		>
			<Box  sx={{ display: "flex", gap: "30px", flexDirection: "column" }}>
				<Box sx={{ display: "flex", gap: "30px", flexWrap: "wrap", flex: "1 0 0px"}}>
					<DekraNewOrdersTile />
					<Box sx={{ display: "flex", gap: "15px", flexWrap: "wrap", flex: "1 0 50%" }}>
						<Box sx={{ minWidth: "270px"}}>
							<Tile title="Fehlende Fotosätze">
								<TileContent dense>
									<Box p={2}>
										{vehiclesKpis && (
											<DashboardKpi
												primary={formatNumber(vehiclesKpis.presales.missingPhotos)}
												secondary={undefined}
											/>
										)}
										{!vehiclesKpis && kpiPlaceholder}
									</Box>
									{canStartSessions && (
										<TileContentActions align="right">
											<TileContentAction
												disabled={vehiclesKpis?.presales.missingPhotos === 0}
												onClick={() => createWorkSession("MissingPictures", "Fehlende Fotosätze")}
												label="Überprüfen"
											/>
										</TileContentActions>
									)}
								</TileContent>
							</Tile>
						</Box>
						<Box sx={{ minWidth: "270px"}}>
							<Tile title="Offene Gutachtenaufträge">
								<TileContent dense>
									<Box p={2}>
										{vehiclesKpis && (
											<DashboardKpi
												primary={formatNumber(vehiclesKpis.presales.unassessed)}
												secondary={undefined}
											/>
										)}
										{!vehiclesKpis && kpiPlaceholder}
									</Box>
									{canStartSessions && (
										<TileContentActions align="right">
											<TileContentAction
												disabled={vehiclesKpis?.presales.unassessed === 0}
												onClick={() => createWorkSession("OpenAssessments", "Offene Gutachtenaufträge")}
												label="Überprüfen"
											/>
										</TileContentActions>
									)}
								</TileContent>
							</Tile>
						</Box>
						<Box sx={{ minWidth: "270px"}}>
							<Tile title="Fehlende Gutachtenaufträge">
								<TileContent dense>
									<Box p={2}>
										{vehiclesKpis && (
											<DashboardKpi
												primary={formatNumber(vehiclesKpis.presales.missingAssessmentOrders)}
												secondary={undefined}
											/>
										)}
										{!vehiclesKpis && kpiPlaceholder}
									</Box>
									{canStartSessions && (
										<TileContentActions align="right">
											<TileContentAction
												disabled={vehiclesKpis?.presales.missingAssessmentOrders === 0}
												onClick={() => createWorkSession("MissingAssessmentOrders", "Fehlende Gutachtenaufträge")}
												label="Überprüfen"
											/>
										</TileContentActions>
									)}
								</TileContent>
							</Tile>
						</Box>
					</Box>
				</Box>
				<Box>
					<Tile
						title="Fahrzeugeingänge und -ausgänge in Dorfmark"
						subtitle="der letzten zwei Monate">
						<TileContent>
							<ReturnsChart options={options} />
						</TileContent>
					</Tile>
				</Box>

				<Box>
					<Tile
						title="Haupttätigkeiten"
						subtitle={`Vom ${moment(interval.dateFrom).format("LL")} bis ${moment(interval.dateTo).format("LL")}`}
					>
						<TileContent>
							<DekraServiceChart interval={interval} options={options} />
						</TileContent>
						<TileActions>
							<ActionButton
								variant="text"
								onClick={handleDownloadPhotoBoxStatistic}
							>
								Zeitraum
							</ActionButton>
						</TileActions>
					</Tile>
				</Box>
			</Box>
		</Layout>
	);
};
