import { WorkSession } from "../../Domain";
import * as actionTypes from './actionTypes';

export function setWorkSession(workSession : WorkSession) {
	return {
		type: actionTypes.SET_WORK_SESSION,
		workSession
	}
}

export interface SetWorkSessionAction {
	type : typeof actionTypes.SET_WORK_SESSION,
	workSession : WorkSession
}

export function clearWorkSession() {
	return {
		type: actionTypes.CLEAR_WORK_SESSION
	}
}

export interface ClearWorkSessionAction {
	type : typeof actionTypes.CLEAR_WORK_SESSION
}

export function setWorkSessionCurrent(current : number) {
	return {
		type: actionTypes.SET_WORK_SESSION_CURRENT,
		current
	}
}

export interface SetWorkSessionCurrentAction {
	type : typeof actionTypes.SET_WORK_SESSION_CURRENT,
	current : number
}

export type Actions =
	SetWorkSessionAction
	| ClearWorkSessionAction
	| SetWorkSessionCurrentAction
	;
