import formatCurrency from "system/formatCurrency";
import { Box, TableCell } from "@mui/material";
import { AreaPricing } from "../../../system/Domain";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from "@mui/styles";
import _ from "lodash";

interface RowProps {
	vehicleNumbers: number[]
	areaPrices: AreaPricing
}

const minCols = 3;

export const PricingViewRowCells = (props: RowProps) => {
	const theme = useTheme();
	const isBigScreen = useMediaQuery(theme.breakpoints.up('lg'));
	const useOwnCells = (_.maxBy(props.areaPrices.prices, (tp => tp.numberOfVehicle)).numberOfVehicle <= minCols) || isBigScreen;

	return (
		<>
			{useOwnCells && (
				props.vehicleNumbers.map((n) => (
					<TableCell key={n} sx={{ textAlign: "right" }}>
						{formatCurrency(props.areaPrices.prices.find(tp => tp.numberOfVehicle === n)?.pricePerVehicle)}
					</TableCell>
				))
			)}
			{!useOwnCells && (
				<TableCell>
					<Box sx={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
						{props.vehicleNumbers.map((n) => (
							<Box sx={{ width: '80px', display: 'flex', flexDirection: 'row' }}>
								<Box>{n} -</Box>
								<Box textAlign={'right'} sx={{ textAlign: 'right', flexGrow: '1' }}>
									{formatCurrency(props.areaPrices.prices.find(tp => tp.numberOfVehicle === n)?.pricePerVehicle)}
								</Box>
							</Box>
						))}
					</Box>
				</TableCell>
			)}
		</>
	);
};

interface HeaderProps {
	vehicleNumbers: number[]
}

export const PricingViewHeaderCells = (props: HeaderProps) => {
	const theme = useTheme();
	const isBigScreen = useMediaQuery(theme.breakpoints.up('lg'));
	const showVehicleIndex = (props.vehicleNumbers.length <= minCols) || isBigScreen;

	return (
		<>
			{showVehicleIndex && (
				props.vehicleNumbers.map((n) => (
					<TableCell key={n}>{n}</TableCell>
				))
			)}
			{!showVehicleIndex && (
				<TableCell>
					Preis pro Fahrzeug
				</TableCell>
			)}
		</>
	)
}

