import { useState } from "react";
import SalesOrderRow from "./SalesOrderRow";
import formatCurrency from "../../../system/formatCurrency";
import { SaleOrderLine, SaleOrder } from "../../../system/Domain";
import { Button, Table, TableBody, TableCell, TableHead, TableRow, useTheme } from "@mui/material";

interface Props {
	order: SaleOrder
	onChange?: (order: SaleOrder) => void
}

const SalesOrderTable = ({ order, onChange }: Props) => {
	const [currentLine, setCurrentLine] = useState(-1);
	const theme = useTheme();

	const handleAddLine = (line: SaleOrderLine) => {
		if (!line.text) {
			throw new Error("Text darf nicht leer sein");
		}

		onChange && onChange({
			...order,
			lines: [
				...order!.lines,
				line
			]
		})

		setCurrentLine(-1);
	};

	return (
		<Table padding="none" sx={{
			root: {
				"& td": {
					borderBottom: "none",
					padding: theme.spacing(1)
				}
			}
		}}>
			<TableHead>
				<TableRow>
					<TableCell>Text</TableCell>
					<TableCell align="right">Netto</TableCell>
					<TableCell align="right">Mwst</TableCell>
					<TableCell align="right">Brutto</TableCell>
					{onChange && (
						<>
							<TableCell align="right"></TableCell>
						</>
					)}
				</TableRow>
			</TableHead>
			<TableBody>
				{order.lines.map((line, index) => {

					const handleLineChanged = (line: SaleOrderLine) => {
						if (!line.text) {
							throw new Error("Text muss angegeben werden");
						}

						onChange && onChange({
							...order,
							lines: [
								...order!.lines.slice(0, index),
								line,
								...order!.lines.slice(index + 1)
							]
						})

						setCurrentLine(-1);
					};

					if (index === currentLine) {
						return (
							<SalesOrderRow line={line} onChange={handleLineChanged} />
						)
					}

					const handleEditLine = () => {
						setCurrentLine(index);
					};

					return (
						<TableRow key={`tr-${index}`}>
							<TableCell>{line.text}</TableCell>
							<TableCell align="right">{formatCurrency(line.netAmount)}</TableCell>
							<TableCell align="right">{formatCurrency(line.vat)}</TableCell>
							<TableCell align="right">{formatCurrency(line.grossAmount)}</TableCell>
							{onChange && (
								<>
									<TableCell>
										<Button onClick={handleEditLine}>Bearbeiten</Button>
									</TableCell>
								</>
							)}
						</TableRow>
					)
				})}
				{currentLine === -1 && onChange && (
					<SalesOrderRow onChange={handleAddLine} />
				)}
			</TableBody>
		</Table>
	);
};

export default SalesOrderTable;
