import axios from 'axios';
import fileDownload from 'js-file-download';
import moment from 'moment';
import OrdersNavigation from 'Navigation/OrdersNavigation';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import FetchNextButton from '../../../Components/FetchNextButton';
import Layout from '../../../Components/Layout/Layout';
import Search from '../../../Components/Search';
import { AssessmentOrder, AssessmentOrdersOverviewPerspective } from '../../../system/Domain';
import parseContentDispositionFilename from '../../../system/parseContentDispositionFilename';
import useAsyncEffect from '../../../system/useAsyncEffect';
import useQuery, { IQuery } from '../../../system/useQuery';
import OrdersSubMenu from '../OrdersSubMenu';
import AssessmentOrdersOverviewSidebar from './AssessmentOrdersOverviewSidebar';
import AssessmentOrdersOverviewTable from './AssessmentOrdersOverviewTable';

export interface AssessmentOrdersOverviewQuery extends IQuery {
	sort: string
	perspective: AssessmentOrdersOverviewPerspective
	fullText?: string
	dateFrom?: string
	isTransferred?: boolean
	hasInquiryRequest?: boolean
	hasDefect?: boolean
}

const AssessmentOrdersOverview = () => {
	const history = useHistory();

	const [isProcessing, setIsProcessing] = useState(false);
	const [assessmentOrders, setAssessmentOrders] = useState<AssessmentOrder[]>();
	const [isFetching, setIsFetching] = useState(false);
	const [checkedAssessmentOrders, setCheckedAssessmentOrders] = useState<string[]>([]);

	const [query, setQuery, fetchNext, resetQuery] = useQuery<AssessmentOrdersOverviewQuery>('assessmentOrdersOverviewQuery', {
		perspective: "All",
		skip: 0,
		take: 20,
		fullText: "",
		sort: "dateCreated:desc",
		dateFrom: moment().add(-3, 'months').startOf('d').toISOString(true),
		isTransferred: undefined,
		hasInquiryRequest: undefined,
		hasDefect: undefined
	});

	useAsyncEffect(async (cancelToken) => {
		const axiosCancelTokenSource = axios.CancelToken.source();
		cancelToken.promise.then(() => axiosCancelTokenSource.cancel());

		setIsFetching(true);

		try {
			const { data: ao } = await axios.get<AssessmentOrder[]>(`/api/orders/assessments`, {
				params: query,
				cancelToken: axiosCancelTokenSource.token
			});

			if (query.skip > 0) {
				setAssessmentOrders(o => [
					...(o ?? []),
					...ao
				]);
			} else {
				setAssessmentOrders(ao);
			}
		} finally {
			setIsFetching(false);
		}
	}, [query]);

	const canProcess = assessmentOrders?.some(f => f.status === "Created") ?? false;

	const handleProcess = async () => {
		if (!assessmentOrders) return;

		setIsProcessing(true);

		try {
			const assessmentOrderIds =
				checkedAssessmentOrders.length > 0 && query.perspective === "Unprocessed"
					? checkedAssessmentOrders
					: assessmentOrders.filter(f => f.status === "Created").map(m => m.id);

			await axios.post<string>(`/api/orders/assessments/process`,
				assessmentOrderIds
			);

			history.push(`/orders/returns/sessions`);
		} finally {
			setIsProcessing(false);
		}
	};

	const triggerExport = async () => {
		const response = await axios.get(`/api/orders/assessments`, {
			responseType: "blob",
			params: {
				...query,
				output: "xlsx"
			}
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);
	};

	return (
		<Layout
			title="Gutachtenaufträge"
			navigation={<OrdersNavigation />}
			subMenu={<OrdersSubMenu />}
			sidebar={<AssessmentOrdersOverviewSidebar
				query={query}
				setQuery={setQuery}
				resetQuery={resetQuery}
				canProcess={canProcess}
				isProcessing={isProcessing}
				handleProcess={handleProcess}
				triggerExport={triggerExport}
			/>}
		>
			<Box mb={2}>
				{!assessmentOrders && (
					<CircularProgress size={48} />
				)}
				{assessmentOrders && (
					<>
						<Box mb={2}>
							<Search
								value={undefined}
								realtime={false}
								onSearch={(value) => value !== query.fullText && setQuery(q => ({
									...q,
									skip: 0,
									fullText: value
								}))}
							/>
						</Box>
						<AssessmentOrdersOverviewTable
							checkable
							assessmentOrders={assessmentOrders}
							perspective={query.perspective}
							checked={checkedAssessmentOrders}
							setChecked={setCheckedAssessmentOrders}
						/>
						<FetchNextButton
							hidden={assessmentOrders.length < query.take + query.skip}
							mb={3}
							onNext={fetchNext}
							disabled={isFetching}
						/>
					</>
				)}
			</Box>
		</Layout>
	);
};

export default AssessmentOrdersOverview;
