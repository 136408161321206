import { useEffect, useState } from "react";
import Layout from "../../Components/Layout/Layout";
import { Box, CircularProgress, Grid } from "@mui/material";
import { useParams } from "react-router";
import { CatalogVehicle, Equipment, LeasmanVehicle, ReturnForm, Sale, VehicleAssessment } from "../../system/Domain";
import useVehicle from "../../system/useVehicle";
import useAxiosEffect from "../../system/useAxiosEffect";
import axios from "axios";
import RemarketingSidebar from "./RemarketingSidebar";
import RemarketingStopList from "./RemarketingStopsTile";
import EquipmentList from "./EquipmentList";
import _ from "lodash";
import useEvent from "../../system/useEvent";
import useChannel from "../../system/useChannel";
import RemarketingAssessmentTile from "./RemarketingAssessmentTile";
import RemarketingUnregistrationTile from "./RemarketingUnregistrationTile";
import RemarketingGeneralInformationTile from "./RemarketingGeneralInformationTile";
import RemarketingForm from "./RemarketingForm";
import RemarketingNotesTile from "./RemarketingNotesTile";
import TileContent from "../../Components/Tiles/TileContent";
import Tile from "../../Components/Tiles/Tile";
import TextLine from "../VehicleSales/TextLine";
import TextGroup from "../VehicleSales/TextGroup";
import { Plate } from "../../Components/Plate";
import formatNumber from "../../system/formatNumber";
import formatDate from "../../system/formatDate";
import EquipmentHighlights from "./EquipmentHighlights";
import VehicleNavigation from "Navigation/VehicleNavigation";
import VehicleHeader from "./VehicleHeader";

export default () => {
	const { id } = useParams<{ id: string }>();
	const [vehicle, setVehicle, reloadVehicle] = useVehicle(id);
	const [assessment, setAssessment] = useState<VehicleAssessment>();
	const [sale, setSale] = useState<Sale>();

	const [catalogVehicle, setCatalogVehicle] = useState<CatalogVehicle>();
	const [leasmanVehicles, setLeasmanVehicles] = useState<LeasmanVehicle[]>();
	const [leasmanVehicle, setLeasmanVehicle] = useState<LeasmanVehicle>();
	const [equipment, setEquipment] = useState<Equipment[]>([]);
	const [returnForm, setReturnForm] = useState<ReturnForm>();

	const [, reloadCatalog] = useAxiosEffect(
		async (config) => {
			if (!vehicle?.type.id) {
				return;
			}

			const { data } = await axios.get<CatalogVehicle>(`/api/catalog/vehicles/${vehicle.type.id}`, config);

			setCatalogVehicle(data);
		},
		[vehicle?.type?.id],
	);

	const [, reloadLeasman] = useAxiosEffect(
		async (config) => {
			if (!vehicle) {
				setLeasmanVehicles(undefined);
				return;
			}

			const { data } = await axios.get<LeasmanVehicle[]>(`/api/leasman/vehicles`, {
				...config,
				params: {
					vin: vehicle.vin,
				},
			});

			setLeasmanVehicles(data);
		},
		[vehicle?.vin],
	);

	//UNUSED HOOK
	// const [loadingAssessment, reloadAssessment] =
	useAxiosEffect(
		async (config) => {
			if (!vehicle?.remarketing?.assessment?.assessmentId) {
				setAssessment(undefined);
				return;
			}

			const { data } = await axios.get<VehicleAssessment>(
				`/api/assessments/${vehicle.remarketing.assessment.assessmentId}`,
				config,
			);

			setAssessment(data);
		},
		[vehicle?.remarketing?.assessment?.assessmentId],
	);

	useAxiosEffect(
		async (config) => {
			if (!vehicle?.remarketing?.saleId) {
				setSale(undefined);
				return;
			}

			const { data } = await axios.get<Sale>(`/api/sales/${vehicle.remarketing.saleId}`, config);
			setSale(data);
		},
		[vehicle?.remarketing?.saleId],
	);

	useEffect(() => {
		if (!vehicle || !vehicle.leasmanContractId || !leasmanVehicles || leasmanVehicles.length === 0) {
			setLeasmanVehicle(undefined);
			return;
		}

		const leasmanVehicle = leasmanVehicles.find((v) => v.contract.id === vehicle.leasmanContractId);
		setLeasmanVehicle(leasmanVehicle);
	}, [vehicle, leasmanVehicles]);

	useEffect(() => {
		if (!vehicle && !leasmanVehicle) {
			setEquipment([]);
			return;
		}

		let equipment = vehicle?.equipment || [];
		if (!!leasmanVehicle) {
			equipment = [
				...equipment,
				...leasmanVehicle.equipment.filter((e) => !equipment.some((e2) => e2.id === e.id)),
			];
		}

		setEquipment(_.orderBy(equipment, (e) => e.text?.toLowerCase()));
	}, [vehicle, leasmanVehicle]);

	useChannel("remarketing");

	const reload = () => Promise.all([reloadVehicle(), reloadCatalog(), reloadLeasman()]);

	useEvent(
		"remarketing:updated",
		async (data) => {
			const { id } = data;

			if (!vehicle || !id) {
				return;
			}

			if (vehicle.id === id) {
				await reload();
			}
		},
		[vehicle?.id],
	);

	useEvent(
		"remarketing:prepared",
		async (data) => {
			const { ids } = data;

			if (!vehicle || !ids || !ids.length) {
				return;
			}

			if (ids.includes(vehicle.id)) {
				await reload();
			}
		},
		[vehicle?.id],
	);

	const disabled = !!vehicle?.remarketing.saleId && !sale?.corrections.some((c) => c.status === "Created");
	const type = vehicle?.type;

	return (
		<Layout
			title="Verkaufsvorbereitung"
			plateNumber={vehicle?.plateNumber}
			navigation={<VehicleNavigation vehicle={vehicle} />}
			sidebar={
				vehicle && (
					<RemarketingSidebar vehicle={vehicle} setVehicle={setVehicle} sale={sale} setSale={setSale} />
				)
			}
		>
			{!vehicle && <CircularProgress size={48} />}
			{vehicle && (
				<>
					<VehicleHeader vehicle={vehicle} />
					<Grid container spacing={6}>
						<Grid item xs={12} md={8}>
							<Grid container spacing={6}>
								<Grid item xs={12}>
									<Grid container spacing={6}>
										<Grid item xs={12} md={6}>
											<Grid container spacing={6}>
												<Grid item xs={12}>
													<Tile title="Informationen">
														<TileContent>
															<TextGroup title="Allgemein">
																<TextLine
																	label="Kennzeichen"
																	value={
																		<Plate
																			plateNumber={vehicle.plateNumber}
																			style={{ zoom: 0.4 }}
																		/>
																	}
																/>
																<Box mt={1}>
																	<TextLine label="FIN" value={vehicle.vin} />
																	<TextLine label="Schwacke Code" value={type?.id} />
																</Box>
																{type && (
																	<Box mt={1}>
																		<TextLine
																			label="Hersteller"
																			value={type.make.name}
																		/>
																		<TextLine
																			label="Modell"
																			value={type.model.name}
																		/>
																		<TextLine
																			label="Variante"
																			value={type.model.variant}
																		/>
																		<TextLine
																			label="Version"
																			value={type.model.version}
																		/>
																		<TextLine
																			label="Bauart"
																			value={type.body.type}
																		/>
																		<TextLine
																			label="Farbe"
																			value={type.body.paint}
																		/>
																		<TextLine
																			label="Kraftstoff"
																			value={type.engine.fuelType}
																		/>
																		<TextLine
																			label="Leistung"
																			value={`${formatNumber(type.engine.power)}kW / ${formatNumber(type.engine.horsePower)}PS`}
																		/>
																	</Box>
																)}
															</TextGroup>
															{leasmanVehicle?.contract && (
																<TextGroup title="Leasingvertrag">
																	<TextLine
																		label="Vertragsnummer"
																		value={leasmanVehicle.contract.id.toString()}
																	/>
																	<TextLine
																		label="Vorraussichtliches Ende"
																		value={formatDate(
																			leasmanVehicle.contract.end.date,
																		)}
																	/>
																	<TextLine
																		label="Rückgabedatum"
																		value={formatDate(
																			leasmanVehicle.contract.return.date,
																		)}
																	/>
																	<TextLine
																		label="Rückgabe KM-Stand"
																		value={formatNumber(
																			leasmanVehicle.contract.return.mileage,
																		)}
																	/>
																	<TextLine
																		label="Laufzeit"
																		value={formatNumber(
																			leasmanVehicle.contract.duration,
																		)}
																	/>
																	<TextLine
																		label="Laufleistung"
																		value={formatNumber(
																			leasmanVehicle.contract.mileage,
																		)}
																	/>
																</TextGroup>
															)}
														</TileContent>
													</Tile>
												</Grid>
												<Grid item xs={12}>
													<RemarketingUnregistrationTile vehicle={vehicle} />
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12} md={6}>
											<Grid container spacing={6}>
												<Grid item xs={12}>
													<RemarketingGeneralInformationTile
														vehicle={vehicle}
														onReturnFormLoaded={(rf) => setReturnForm(rf)}
													/>
												</Grid>
												<Grid item xs={12}>
													<RemarketingAssessmentTile
														vehicle={vehicle}
														assessment={assessment}
													/>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<EquipmentHighlights
										disabled={disabled}
										vehicle={vehicle}
										setVehicle={setVehicle}
									/>
								</Grid>
								<Grid item xs={12}>
									<EquipmentList
										title="Sonderausstattung"
										equipments={equipment.filter((e) => e.isExtra)}
										vehicle={vehicle}
										setVehicle={setVehicle}
										catalogVehicle={catalogVehicle}
										leasmanVehicle={leasmanVehicle}
										disabled={disabled}
									/>
								</Grid>
								<Grid item xs={12}>
									<EquipmentList
										title="Serienausstattung"
										equipments={equipment.filter((e) => !e.isExtra)}
										vehicle={vehicle}
										setVehicle={setVehicle}
										catalogVehicle={catalogVehicle}
										leasmanVehicle={leasmanVehicle}
										disabled={disabled}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} md={4}>
							<Grid container spacing={6}>
								<Grid item xs={12}>
									<RemarketingForm
										disabled={disabled}
										vehicle={vehicle}
										setVehicle={setVehicle}
										assessment={assessment}
										returnForm={returnForm}
									/>
								</Grid>
								<Grid item xs={12}>
									<RemarketingStopList
										vehicle={vehicle}
										setVehicle={setVehicle}
										excluded={["VehicleAssessmentRejection", "PictureSetQuality"]}
									/>
								</Grid>
								<Grid item xs={12}>
									<RemarketingNotesTile vehicle={vehicle} setVehicle={setVehicle} />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</>
			)}
		</Layout>
	);
};
