import { formatTransmissionType } from "Pages/Vehicles/CatalogPreviewListItem";
import TextGroup from "Pages/VehicleSales/TextGroup";
import TextLine from "Pages/VehicleSales/TextLine";
import formatNumber from "system/formatNumber";
import { VehicleType, WheelDrive } from "../../system/Domain";

const formatWheelDrive = (wheelDrive: WheelDrive | null) => {
	switch (wheelDrive) {
		case "All":
			return "Allrad";
		case "Front":
			return "Vorderradantrieb";
		case "Back":
			return "Hinterradantrieb";
		case "Switchable":
			return "Allrad zuschaltbar";
		default:
			return "-";
	}
};

interface Props {
	type: VehicleType
}

export default ({ type }: Props) => {
	return (
		<TextGroup title="Antrieb">
			<TextLine label="Kraftstoff" value={type?.engine.fuelType} />
			<TextLine label="Art" value={formatWheelDrive(type?.engine.wheelDrive)} />
			<TextLine label="Getriebe" value={formatTransmissionType(type?.transmission.type)} />
			<TextLine label="Gänge" value={formatNumber(type?.transmission.gears)} />
			<TextLine label="Leistung" value={`${formatNumber(type?.engine.power)}kW / ${formatNumber(type?.engine.horsePower)}PS`} />
			<TextLine label="EU-Norm" value={type?.emissions.group.name} />
			<TextLine label="CO2-Emmissionen" value={`${formatNumber(type?.emissions.co2)}g/km`} />
			<TextLine label="Hubraum" value={`${formatNumber(type?.engine.cubicCapacity)}ccm`} />
			<TextLine label="Zylinder" value={formatNumber(type?.engine.cylinders)} />
		</TextGroup>
	);
}
