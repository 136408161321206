import { useEffect } from "react";
import { SaleOrder } from "system/Domain";
import formatNumber from "system/formatNumber";
import { Form } from "../../../system/useForm";
import { Grid, GridProps } from "@mui/material";
import FormText from "../../../Components/FormText";
import FormYesNo from "../../../Components/FormYesNo";
import FormChoices from "../../../Components/FormChoices";
import FormDateTimePicker from "../../../Components/FormDateTimePicker";
import FormSelect from "../../../Components/FormSelect";

interface Props extends GridProps {
	form: Form
	saleOrder: SaleOrder
}

const SalesOrderPrivateGridItem = ({ form, saleOrder, ...rest }: Props) => {
	useEffect(() => {
		if (!saleOrder.repairCostInsuranceType) {
			return;
		}

		const duration = saleOrder.repairCostInsuranceDuration || 0;
		const priceFormatted = formatNumber(saleOrder.repairCostInsurancePrice || 0);

		let additionalAgreements = "";
		if (saleOrder.repairCostInsuranceType !== "none") {
			additionalAgreements = `Es wird eine Reparaturkostenversicherung der Multipart Garantie AG für die Laufzeit von ${duration} Monaten für ${priceFormatted} Euro inkl. Versicherungsteuer erworben.`;
		}
		form.setValue("additionalAgreements", additionalAgreements)
	}, [saleOrder.repairCostInsuranceType, saleOrder.repairCostInsuranceDuration, saleOrder.repairCostInsurancePrice]);

	return (
		<Grid {...rest}>
			<Grid container spacing={4}>
				<FormSelect
					label="Leadgenerierung"
					form={form}
					name="leadOrigin"
					choices={{
						"Mobile": "Mobile",
						"Autoscout24": "Autoscout24",
						"AldShop": "ALD Shop",
						"WalkIn": "Laufkundschaft",
						"Marketing": "Werbeaktion",
						"Recommendations": "Empfehlung",
						"Others": "Sonstige"
					}}
					options={{
						required: true
					}}
				/>
				{saleOrder.leadOrigin === "Others" && (
					<FormText
						label="Leadgenerierung - Bemerkung"
						form={form}
						name="leadOriginComment"
						options={{
							required: true
						}}
					/>
				)}
				<Grid item>
					<FormDateTimePicker
						label="Lieferdatum"
						form={form}
						name="deliveryDate"
						variant="date"
						format="DD.MM.YYYY"
					/>
				</Grid>
				<FormYesNo
					label="Ist das Fahrzeug fahrbereit"
					form={form}
					name="isReadyForDriving"
				/>
				<FormText
					label="Negative Beschaffenheiten 1"
					fullWidth
					form={form}
					name="damages"
				/>
				<FormText
					label="Negative Beschaffenheiten 2"
					fullWidth
					form={form}
					name="damages1"
				/>
				<FormText
					label="Unfallschäden"
					fullWidth
					form={form}
					name="otherDamages"
				/>
				<FormYesNo
					label="War das Fahrzeug als Taxi/Mietwagen/Fahrschule zugelassen"
					form={form}
					name="wasTaxi"
				/>
				<FormYesNo
					label="Ist das Fahrzeug mit einer Anhängerkupplung ausgestattet?"
					form={form}
					name="hasTrailerHitch"
				/>

				<FormChoices
					name="repairCostInsuranceType"
					label="Reparaturkostenversicherung"
					form={form}
					choices={{
						"none": "Keine",
						"Multi-N": "Multi-N",
						"MultiPlus": "MultiPlus",
					}}
					options={{ required: true }}
				/>

				<FormChoices
					name="repairCostInsuranceDuration"
					label="Versicherungsdauer"
					form={form}
					choices={{
						"12": "12 Monate",
						"24": "24 Monate",
						"36": "36 Monate",
					}}
					options={{ required: false }}
				/>

				<FormText
					name="repairCostInsurancePrice"
					label="Versicherungspreis"
					form={form}
					type="currency"
					fullWidth={false}
					inline
					options={{ required: false }}
				/>
				<FormText
					label="Sonstige Vereinbarungen"
					fullWidth
					form={form}
					name="additionalAgreements"
				/>
			</Grid>
		</Grid>
	);
}

export default SalesOrderPrivateGridItem;
