import { Check } from '@mui/icons-material';
import { Box, Grid, Typography, useTheme } from '@mui/material';

import Chip from '../../../Components/Chip';
import { Form } from '../../../system/useForm';

interface Props {
	form: Form
}

const lines: string[] = [
	"Zulassungsbescheinigung Teil I",
	"Bei Online Abmeldung ausgedruckter Nachweis",
	"Beide Amtliche Kennzeichen",
	"Serviceheft bzw. die digitalen Servicenachweise",
	"Alle Fahrzeugschlüssel",
	"Borddokumente (Bedienungsanleitungen und Radio-Code)",
	"Sämtliches zum Fahrzeug gehörendes Zubehör",
	"Alle zum Fahrzeug und Vertrag gehörenden Räder"
];

export const ReturnList = () => {
	const theme = useTheme();

	return (
		<>
			<Typography variant="h6">
				Folgende Unterlagen und Zubehör müssen bei der Rückgabe abgegeben werden
			</Typography>

			<Box mt={2} maxWidth="1000px">
				<Grid container>
					{lines && lines.map((l, i) => (
						<Grid key={i} item xs={12} md={6}>
							<Box display="flex">
								<Check />
								<Box
									ml={2}
									display="flex"
									flexDirection="column"
									justifyContent="center"
								>
									{l}
								</Box>
							</Box>
						</Grid>
					))}
				</Grid>

				<Box mt={3}>
					<Chip label="Bitte beachten" explicitColor={theme.palette.error.main} />
					<Box mt={1}>
						Das Fahrzeug ist in einem gereinigten und verkehrssicheren Zustand inkl. der vorgenannten Unterlagen/Zubehörteile abzugeben.
					</Box>
				</Box>
			</Box>
		</>
	);
};

const StepCollectionFinalRemarks = (props: Props) => {
	return (
		<Box mb={2} mt={2}>
			<ReturnList />
		</Box>
	);
};

export default StepCollectionFinalRemarks;
