import { AnyAction } from "redux";

export interface ErrorState {
	error? : any
	// should the error be shown on a global level?
	// if yes, the error will be displayed on the layout page
	handleError : boolean
}

const initialState : ErrorState = {
	error: null,
	handleError: true
};

export default (state = initialState, action : AnyAction) : ErrorState => {
	switch (action.type) {
		case "SUSPEND_ERROR_HANDLING":
			return {
				...state,
				error: null,
				handleError: false
			};
		case "RESUME_ERROR_HANDLING":
			return {
				...state,
				error: null,
				handleError: true
			};
		case 'SET_ERROR':
			return {
				...state,
				error: action.error
			};
		case "CLEAR_ERROR":
			return {
				...state,
				error: null
			};
		default:
			return state;
	}
};
