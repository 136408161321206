import axios from "axios";
import fileDownload from "js-file-download";
import { Dispatch, SetStateAction } from 'react';
import Sidebar from "../../Components/Sidebar/Sidebar";
import { FindInPage, GetApp } from "@mui/icons-material";
import SidebarGroup from "../../Components/Sidebar/SidebarGroup";
import SidebarButton from "../../Components/Sidebar/SidebarButton";
import parseContentDispositionFilename from "../../system/parseContentDispositionFilename";

interface Props {
	isLoading: boolean
	setIsLoading: Dispatch<SetStateAction<boolean>>
	loadDocumentation: () => void
}

const SecurityDocumentationSidebar = (props: Props) => {
	const triggerExport = async () => {
		const response = await axios.get(`/api/documentation/security`, {
			responseType: "blob",
			params: {
				output: "xlsx"
			}
		});

		const fileName = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, fileName);
	};

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarButton
					color="primary"
					startIcon={<FindInPage />}
					disabled={props.isLoading}
					label="Dokumentation laden"
					onClick={props.loadDocumentation}
				/>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarButton
					startIcon={<GetApp />}
					disabled={props.isLoading}
					onClick={triggerExport}
					label="Excel-Export"
				/>
			</SidebarGroup>
		</Sidebar>
	);
};

export default SecurityDocumentationSidebar;
