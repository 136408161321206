import { AxiosResponse } from "axios";
import parseContentDispositionFilename from "./parseContentDispositionFilename";
import fileDownload from "js-file-download";

export const axiosDownload = (response : AxiosResponse, filename : string | null = null) => {
	if (!filename) {
		filename = parseContentDispositionFilename(response.headers["content-disposition"]);
	}

	fileDownload(response.data, filename || "file");
};
