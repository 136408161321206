import Layout from "../../Components/Layout/Layout";
import LotScanContextMenu from "./LotScanContextMenu";
import VehicleHeaderMenu from "./VehicleHeaderMenu";
import VehicleSubMenu from "./VehicleSubMenu";
import { CircularProgress, Grid } from "@mui/material";
import VehicleHeader from "./VehicleHeader";
import { useParams } from "react-router";
import useVehicle from "../../system/useVehicle";
import VehicleNavigation from "Navigation/VehicleNavigation";
import VehicleReturnForms from "./VehicleReturnForms";
import VehiclePapers from "./VehiclePapers";
import RemarketingUnregistrationTile from "./RemarketingUnregistrationTile";
import ArrivalTransmissionNotesTile from "./ArrivalTransmissionNotesTile";
import PicturesTile from "../../Components/PicturesTile";
import { enumeratePictures } from "./RegistrationDocuments";
import VehicleTransportProtocol from "./VehicleTransportProtocol";
import useUser from "../../system/useUser";
import { UserRole } from "../../system/Domain";
import VehicleDocumentList from "./VehicleDocumentList";

const VehicleAllDocuments = () => {
	const { id } = useParams<{ id: string }>();
	const [vehicle, setVehicle] = useVehicle(id);
	const [, , hasRole] = useUser();

	return (
		<Layout
			title="Dokumente"
			plateNumber={vehicle?.plateNumber}
			navigation={<VehicleNavigation vehicle={vehicle} />}
			contextMenu={[
				<LotScanContextMenu />,
				<VehicleHeaderMenu vehicle={vehicle} />
			]}
			subMenu={vehicle && <VehicleSubMenu vehicle={vehicle} />}
		>
			{!vehicle && (
				<CircularProgress size={48} />
			)}
			{vehicle && (
				<>
					<VehicleHeader vehicle={vehicle} />
					<Grid container spacing={6}>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<RemarketingUnregistrationTile vehicle={vehicle} />
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<VehiclePapers vehicle={vehicle} setVehicle={setVehicle} />
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<ArrivalTransmissionNotesTile vehicle={vehicle} setVehicle={setVehicle} />
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={4}>
							<VehicleReturnForms vehicle={vehicle} />
						</Grid>

						<VehicleTransportProtocol vehicleId={id} />

						{hasRole(UserRole.AldUser) && (
							<Grid item xs={12} sm={6} md={6} lg={4}>
								<VehicleDocumentList vehicle={vehicle} />
							</Grid>
						)}
						{vehicle?.remarketing.registrationDocuments?.documentsPictureProof && (
							<Grid item xs={12} sm={6} md={6} lg={4}>
								<PicturesTile
									pictures={Array.from(enumeratePictures(vehicle.remarketing.registrationDocuments.documentsPictureProof))}
								/>
							</Grid>
						)}
					</Grid>
				</>
			)}
		</Layout>
	)
};

export default VehicleAllDocuments;
