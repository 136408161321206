import { useEffect, useState } from 'react';
import { CustomerOrder, FileReference, PricingRequest, PricingRequestPurchaser } from "../../../system/Domain";
import CustomerStepper, { Step } from "../CustomerStepper";
import StepPricingRequestInstructions from "../Steps/StepPricingRequestInstructions";
import useForm, { Form } from "../../../system/useForm";
import StepPricingRequestData from "../Steps/StepPricingRequestData";
import StepPricingRequestFinalRemarks from "../Steps/StepPricingRequestFinalRemarks";
import { useRecoilState } from "recoil";
import { customerTitle } from "../../../system/atoms/customerTitle";
import axios from "axios";
import moment from "moment/moment";

interface CommonProps {
	customerOrder: CustomerOrder
	toStatusOverview: () => void
	backAction: () => void
}

type EditProps = {
	formData: CustomerReturnOrderFormPricingRequestType
	updateCustomerOrder: (customerOrder: CustomerOrder) => void
}

type CreateProps = {
	formData?: never
	updateCustomerOrder?: never
}

type Props = CommonProps & (EditProps | CreateProps)

type StepProps = Props & { form: Form };

const steps: Step[] = [
	{
		id: 1,
		title: "Anleitung",
		ui: (props: StepProps) => <StepPricingRequestInstructions {...props} />
	}, {
		id: 2,
		title: "Dateneingabe",
		ui: (props: StepProps) => <StepPricingRequestData {...props} />
	}, {
		id: 3,
		title: "Anmerkungen",
		ui: (props: StepProps) => <StepPricingRequestFinalRemarks {...props} />
	}
];

export const calculateFormData = (pricingRequest: PricingRequest, customerOrder: CustomerOrder): CustomerReturnOrderFormPricingRequestType => {
	// if (pricingRequest.purchaser !== "Driver" && pricingRequest.purchaser !== "Lessee") {
	// 	throw "Invalid purchaser";
	// } // TODO

	return {
		mileage: pricingRequest.mileage,
		purchaser: pricingRequest.purchaser,
		mileagePictureProof: pricingRequest.mileagePictureProof,
		datePurchase: pricingRequest.datePurchase,
		customerEmail: customerOrder.customerEmail
	};
};

export interface CustomerReturnOrderFormPricingRequestType {
	mileage?: number,
	datePurchase: string
	purchaser?: PricingRequestPurchaser
	mileagePictureProof?: FileReference
	customerEmail?: string
}

const PricingRequestChoice = (props: Props) => {
	const [, setCustomerTitle] = useRecoilState(customerTitle);

	const [customerForm, setCustomerForm] = useState<CustomerReturnOrderFormPricingRequestType>(props.formData ?? {
		datePurchase: moment.max(moment(props.customerOrder.dateContractEnd), moment()).toISOString(true)
	});

	const editMode = !!props.formData;

	useEffect(() => setCustomerTitle(editMode ? "Kaufpreisanfrage bearbeiten" : "Kaufpreisanfrage erstellen"), []);

	const form = useForm({
		values: customerForm,
		setValues: setCustomerForm
	});

	const updateCall = async () => {
		const response = await axios.put<CustomerOrder>(`/api/orders/customers/${props.customerOrder.id}/pricing-request`, customerForm);

		props.updateCustomerOrder(response.data);
	};

	const createCall = async () => {
		await axios.post(`/api/orders/customers/${props.customerOrder.id}/pricing-request`, customerForm);
	};

	return (
		<CustomerStepper
			customerOrder={props.customerOrder}
			steps={steps}
			editMode={editMode}
			backAction={props.backAction}
			toStatusOverview={props.toStatusOverview}
			update={updateCall}
			create={createCall}
			form={form}
		/>
	);
}

export default PricingRequestChoice;
