import { CircularProgress } from "@mui/material";
import axios from "axios";
import OrdersNavigation from 'Navigation/OrdersNavigation';
import { useState } from 'react';
import { useParams } from "react-router";
import Layout from "../../../Components/Layout/Layout";
import { ReturnOrder, Vehicle } from "../../../system/Domain";
import useAsyncEffect from "../../../system/useAsyncEffect";
import ReturnOrdersForm, { calculateReturnOrderFormData } from "./ReturnOrdersForm";

const ReturnOrdersEdit = () => {
	const { id } = useParams<{ id: string }>();
	const [returnOrder, setReturnOrder] = useState<ReturnOrder>();
	const [vehicle, setVehicle] = useState<Vehicle>();

	useAsyncEffect(async () => {
		if (!id) {
			return;
		}

		const { data } = await axios.get<ReturnOrder>(`/api/orders/returns/${id}`);
		setReturnOrder(data);
	}, [id]);

	useAsyncEffect(async () => {
		if (!returnOrder) {
			return;
		}

		const { data } = await axios.get<Vehicle>(`/api/vehicles/${returnOrder.vehicle.id}`);
		setVehicle(data);
	}, [returnOrder?.vehicle.id]);

	return (
		<Layout
			title="Rückholung bearbeiten"
			navigation={<OrdersNavigation />}
		>
			{(!returnOrder || !vehicle) && (
				<CircularProgress size={48} />
			)}
			{returnOrder && vehicle && (
				<ReturnOrdersForm
					vehicle={vehicle}
					formData={calculateReturnOrderFormData(returnOrder, false)}
					returnOrder={returnOrder}
				/>
			)}
		</Layout>
	);
};

export default ReturnOrdersEdit;
