import { Box, BoxProps } from "@mui/material";
import { PropsWithChildren } from "react";

export default ({ children, ...rest }: PropsWithChildren<BoxProps>) => {
	return (
		<Box sx={{ fontSize: "0.8rem" }} {...rest}>
			{children}
		</Box>
	);
};
