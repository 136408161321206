import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import { Box, darken, lighten, Paper, useTheme } from "@mui/material";
import dateFormats from "../../system/dateFormats";
import { LogEvent } from "../../system/Domain";
import useWindowSize from "../../system/useWindowSize";
import LogEntry from "./LogEntry";

interface LogProps {
	items: LogEvent[];
	top?: number;
	loading?: boolean;
	scrollIntoView?: boolean;
	fullSize?: boolean;
}

export default ({ items, top = 250, loading = false, scrollIntoView = true, fullSize = true }: LogProps) => {
	const theme = useTheme();
	const [loadingEvents, setLoadingEvents] = useState<LogEvent[]>([]);
	const size = useWindowSize();

	useEffect(() => {
		if (!loading) {
			setLoadingEvents([]);
		} else {
			setLoadingEvents([
				{
					id: "1",
					jobId: "",
					date: moment().toISOString(),
					level: "Information",
					message: "Loading logs",
				},
			]);

			const loadingTimer = setInterval(() => {
				setLoadingEvents((events) => {
					const lastEvent = events[events.length - 1];

					let message = "Loading logs";
					if (lastEvent) {
						message = lastEvent.message + ".";
					}
					return [
						...events,
						{
							id: lastEvent ? (Number.parseInt(lastEvent.id) + 1).toString() : "1",
							jobId: "",
							date: moment().toISOString(),
							level: "Information",
							message,
						},
					];
				});
			}, 1000);

			return () => clearInterval(loadingTimer);
		}
	}, [loading]);

	const events = loading ? loadingEvents : items;

	const scrollRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!events || events.length < 3) {
			return;
		}
		console.log(events);

		if (scrollIntoView) {
			scrollRef.current?.scrollIntoView({ behavior: "smooth" });
		}
	}, [events]);

	return (
		<Paper
			sx={{
				backgroundColor:
					theme.palette.mode === "dark"
						? darken(theme.palette.background.paper, 0.3)
						: lighten(theme.palette.background.paper, 0.3),
				color: theme.palette.text.primary,
				fontFamily: '"Monaco", monospace',
				fontSize: "0.8rem",
				lineHeight: "1.5rem",
				whiteSpace: "pre",
				overflow: "auto",
				paddingTop: theme.spacing(2.25),
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2),
				paddingBottom: theme.spacing(2.25),
				maxHeight: "80vh",
				height: fullSize ? size.height - top : "auto",
			}}
		>
			{events.length === 0 && <div>Keine Daten vorhanden</div>}

			{events.map((item, index) => {
				const date = moment(item.date);
				const lastDate = index > 0 ? moment(events[index - 1].date) : date;

				const deltaMinutes = date.diff(lastDate, "minutes");

				return (
					<Fragment key={item.id}>
						{(!date.isSame(lastDate, "date") || index === 0) && (
							<Box mt={index > 0 ? 4 : 0} mb={2} color={theme.palette.text.primary} fontSize="1rem">
								{date.format(dateFormats.date)}
							</Box>
						)}
						{deltaMinutes > 5 && (
							<Box my={1} color={theme.palette.text.secondary} fontSize="0.8rem" fontStyle="italic">
								+ {moment.duration(deltaMinutes, "minutes").humanize()}
							</Box>
						)}
						<LogEntry index={index + 1} item={item} />
					</Fragment>
				);
			})}
			<div style={{ float: "left", clear: "both" }} ref={scrollRef} />
		</Paper>
	);
};
