import { Box } from "@mui/material";
import { Address } from "system/Domain";
import InlineList from "./InlineList";
import TextBlock from "./Layout/TextBlock";

interface Props {
	address: Address;
}

export default ({ address }: Props) => {
	return (
		<TextBlock
			primary={
				<>
					<Box>{address.name}</Box>
					{address.name1 && <Box>{address.name1}</Box>}
				</>
			}
			secondary={
				<>
					<Box>
						<InlineList>
							{address.street}
							{address.zipCode}
						</InlineList>
					</Box>
					<Box>
						<InlineList>
							{address.city}
							{address.country}
						</InlineList>
					</Box>
					<Box>{address.additionalInformation}</Box>
				</>
			}
		/>
	);
};
