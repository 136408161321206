import { Box, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField, Tooltip } from "@mui/material";
import TextBlock from "Components/Layout/TextBlock";
import Sidebar from "Components/Sidebar/Sidebar";
import SidebarButton from "Components/Sidebar/SidebarButton";
import SidebarGroup from "Components/Sidebar/SidebarGroup";
import { CompoundDesignerSettings, CompoundPlaceMerger } from "./CompoundDesigner";
import { CompoundPlaceDesign, locations } from "./mapUtils";
import axios from "axios";
import { cutHorizontal, cutVertical, rotate } from "./CompoundPlaceOperations";
import { MapHandler } from "./MapHandler";
import { Dispatch, SetStateAction } from "react";

export interface CompoundDesignerSidebarProps {
	compoundPlace: CompoundPlaceDesign;
	CompoundDesignerSettings: CompoundDesignerSettings;
	setCompoundPlace: Dispatch<SetStateAction<CompoundPlaceDesign>>;
	setCompoundDesignerSettings: Dispatch<SetStateAction<CompoundDesignerSettings>>;
	compoundPlaceMerger: CompoundPlaceMerger;
	setCompoundPlaceMerger: Dispatch<SetStateAction<CompoundPlaceMerger>>;
	mapHandler: MapHandler;
	isUploading: boolean;
	setUploading: Dispatch<SetStateAction<boolean>>;
}

export default ({
	CompoundDesignerSettings,
	setCompoundDesignerSettings,
	compoundPlace,
	setCompoundPlace,
	compoundPlaceMerger,
	setCompoundPlaceMerger,
	mapHandler,
	isUploading,
	setUploading,
}: CompoundDesignerSidebarProps) => {
	const handleSwitchChange = (event) => {
		const { name, checked } = event.target;
		setCompoundDesignerSettings((prevState) => ({
			...prevState,
			[name]: checked,
		}));
	};

	const handleNameChange = (event) => {
		const { value } = event.target;
		setCompoundPlace((prevState) => ({
			...prevState,
			name: value,
		}));
	};

	const handleMergeButtonClick = () => {
		setCompoundPlaceMerger((prevState) => ({
			...prevState,
			isMergeMode: !prevState.isMergeMode,
		}));
	};

	const handlePlaceChange = (e) => {
		const location = locations.find((it) => it.id === e.target.value);
		delete location.coordinates;

		setCompoundPlace((lp) => {
			return {
				...lp,
				compound: { name: location.name },
			};
		});
	};

	const handleSave = async (compoundPlace: CompoundPlaceDesign) => {
		setUploading(true);
		try {
			let data: CompoundPlaceDesign;

			compoundPlace.geoArea.points = mapHandler.getCurrentVertexPosition(compoundPlace.id);

			if (compoundPlace.isNew) {
				const tempId = compoundPlace.id;
				delete compoundPlace.isNew;
				delete compoundPlace.id;

				compoundPlace.isActive = true;
				const response = await axios.post<CompoundPlaceDesign>("api/compounds/places", compoundPlace);
				if (response.status !== 200) return;
				data = response.data;
				mapHandler.updatePolygonsId(data, tempId);
			} else {
				const response = await axios.put<CompoundPlaceDesign>(
					`api/compounds/places/${compoundPlace.id}`,
					compoundPlace,
				);
				if (response.status !== 200) return;
				data = response.data;
			}

			if (data) {
				mapHandler.updateCompoundPlace(data);
			}
		} catch (e) {
			return;
		} finally {
			setUploading(false);
		}
	};

	const handleCut = (cut: (compoundPlace: CompoundPlaceDesign) => CompoundPlaceDesign[]) => {
		const compoundPlaces: CompoundPlaceDesign[] = cut(compoundPlace);
		const compoundPlace1 = compoundPlaces[0];
		const compoundPlace2 = compoundPlaces[1];

		mapHandler.removePolygon(null, compoundPlace.id);

		mapHandler.createPolygonFromCompoundPlace(compoundPlace1 as CompoundPlaceDesign);
		mapHandler.createPolygonFromCompoundPlace(compoundPlace2 as CompoundPlaceDesign);

		setCompoundPlace(compoundPlace1);
	};

	const handleRotate = (compoundPlace: CompoundPlaceDesign, degree: number) => {
		const rotatedCompoundPlace = rotate(compoundPlace, degree);
		console.log(rotatedCompoundPlace);
		mapHandler.removePolygon(null, compoundPlace.id);
		mapHandler.createPolygonFromCompoundPlace(rotatedCompoundPlace as CompoundPlaceDesign);
		setCompoundPlace(rotatedCompoundPlace);
	};
	return (
		<Sidebar>
			<TextBlock primary="Parkraumbewirtschaftung" />
			<SidebarGroup>
				<Box>
					<InputLabel>Parking Platze Namen</InputLabel>
					<TextField
						disabled={!compoundPlace || compoundPlaceMerger.isMergeMode || isUploading}
						variant="outlined"
						onChange={handleNameChange}
						value={compoundPlace?.name ?? ""}
					/>
				</Box>
				<Box>
					<InputLabel>Parking Platze</InputLabel>
					<Select
						disabled={!compoundPlace || compoundPlaceMerger.isMergeMode || isUploading}
						sx={{ width: "100%", margin: "5px 0" }}
						onChange={handlePlaceChange}
						value={""}
					>
						{locations.map((l) => {
							return (
								<MenuItem key={l.id} value={l.id}>
									{l.name}
								</MenuItem>
							);
						})}
					</Select>
				</Box>
				<br />
				<SidebarButton disabled={true} onClick={handleMergeButtonClick}>
					{!compoundPlaceMerger.isMergeMode ? "Vereinige Losplätze" : "Klicke auf das zweite Losplatz"}
				</SidebarButton>
				<SidebarButton
					disabled={!compoundPlace || compoundPlaceMerger.isMergeMode || isUploading}
					onClick={() => {
						handleCut(cutVertical);
					}}
				>
					Schneide Lot Platz vertikal
				</SidebarButton>
				<SidebarButton
					disabled={!compoundPlace || compoundPlaceMerger.isMergeMode || isUploading}
					onClick={() => {
						handleCut(cutHorizontal);
					}}
				>
					Schneide Lot Platz horizontal
				</SidebarButton>
				<br />

				<SidebarButton
					disabled={!compoundPlace || compoundPlaceMerger.isMergeMode || isUploading}
					onClick={() => {
						handleRotate(compoundPlace, 90);
					}}
				>
					Drehe das Polygon um 90° im Uhrzeigersinn
				</SidebarButton>
				<SidebarButton
					disabled={!compoundPlace || compoundPlaceMerger.isMergeMode || isUploading}
					onClick={() => {
						handleRotate(compoundPlace, -90);
					}}
				>
					Drehe das Polygon um 90° gegen den Uhrzeigersinn.
				</SidebarButton>
				<br />
				{/* <SidebarButton disabled={!compoundPlace || compoundPlaceMerger.isMergeMode || isUploading} onClick={() => { }}>Parallel left to right</SidebarButton>
				<SidebarButton disabled={!compoundPlace || compoundPlaceMerger.isMergeMode || isUploading} onClick={() => { }}>Parallel right to left</SidebarButton>
				<SidebarButton disabled={!compoundPlace || compoundPlaceMerger.isMergeMode || isUploading} onClick={() => { }}>Parallel top to bottom</SidebarButton>
				<SidebarButton disabled={!compoundPlace || compoundPlaceMerger.isMergeMode || isUploading} onClick={() => { }}>Parallel bottom to top</SidebarButton>

				<br /> */}
				<SidebarButton
					color="error"
					disabled={!compoundPlace || compoundPlaceMerger.isMergeMode || isUploading}
					onClick={() => {
						mapHandler.removeCompoundPlace(compoundPlace);
					}}
				>
					Platz entfernen
				</SidebarButton>
				<SidebarButton
					disabled={!compoundPlace || compoundPlaceMerger.isMergeMode || isUploading}
					onClick={() => {
						handleSave(compoundPlace);
					}}
				>
					Save lot place
				</SidebarButton>
			</SidebarGroup>
			<SidebarGroup>
				<TextBlock primary="CompoundDesigner Einstellungen" />
				<Tooltip title="Stelle ein, ob du inaktive Losplätze sehen möchtest.">
					<FormControlLabel
						control={
							<Switch
								name="showInactiveCompoundPlaces"
								onClick={handleSwitchChange}
								checked={CompoundDesignerSettings.showInactiveCompoundPlaces}
							/>
						}
						label="Zeige inaktive Losplätze"
					/>
				</Tooltip>
				<Tooltip title="Stelle ein, ob du aktive Losplätze sehen möchtest.">
					<FormControlLabel
						control={
							<Switch
								name="showActiveCompoundPlaces"
								onClick={handleSwitchChange}
								checked={CompoundDesignerSettings.showActiveCompoundPlaces}
							/>
						}
						label="Zeige aktives Losplätze"
					/>
				</Tooltip>
				<Tooltip title="Beim Abschluss des Verschiebens ziehe das Polygon zum nächstgelegenen Punkt eines anderen (ungefähr 50 Meter entfernt).">
					<FormControlLabel
						control={
							<Switch
								name="enableCompoundPlacePulling"
								onClick={handleSwitchChange}
								inputProps={{ "aria-label": "controlled" }}
								disabled={true}
								checked={CompoundDesignerSettings.enableCompoundPlacePulling}
							/>
						}
						label="Aktiviere das Ziehen von Losplätzen"
					/>
				</Tooltip>
				<Tooltip title="Speichere alle Änderungen beim Einstellen des aktiven Polygons.">
					<FormControlLabel
						control={
							<Switch
								name="autoSave"
								onClick={handleSwitchChange}
								inputProps={{ "aria-label": "controlled" }}
								disabled={true}
								checked={CompoundDesignerSettings.autoSave}
							/>
						}
						label="Automatisches Speichern"
					/>
				</Tooltip>
			</SidebarGroup>
		</Sidebar>
	);
};
