import { Launch } from "@mui/icons-material";
import moment from "moment";
import { useHistory } from "react-router";
import InlineList from "../../../Components/InlineList";
import TextBlock from "../../../Components/Layout/TextBlock";
import ListItem from "../../../Components/ListItem";
import ListItemAction from "../../../Components/ListItemAction";
import ListItemActions from "../../../Components/ListItemActions";
import ListItemChip from "../../../Components/ListItemChip";
import ListItemChips from "../../../Components/ListItemChips";
import ListItemContent from "../../../Components/ListItemContent";
import { Job, JobStatus, ThemeColor } from "../../../system/Domain";
import formatDateTime from "../../../system/formatDateTime";
import { formatJobType } from "./JobsSidebar";

interface Props {
	job: Job;
}

export const formatJobName = (job: Job) => `${formatJobType(job.type)}.${job.method}`;

export const formatJobStatus = (status: JobStatus) => {
	switch (status) {
		case "Created":
			return "Erstellung";
		case "Scheduled":
			return "Avisiert";
		case "Running":
			return "Wird ausgeführt";
		case "Completed":
			return "Abgeschlossen";
		case "Cancelled":
			return "Abgebrochen";
		case "Failed":
			return "Fehler";
		case "Deleted":
			return "Gelöscht";
		default:
			return status;
	}
};

export const paintJobStatus = (name: JobStatus): ThemeColor => {
	switch (name) {
		case "Running":
			return "primary";
		case "Completed":
			return "success";
		case "Scheduled":
		case "Cancelled":
			return "warning";
		case "Failed":
			return "error";
		case "Created":
		case "Deleted":
		default:
			return "info";
	}
};

export default ({ job }: Props) => {
	const history = useHistory();

	const openJob = () => history.push(`/admin/jobs/${job.id}`);
	const invocation = job.invocations.length > 0 ? job.invocations[job.invocations.length - 1] : null;
	const duration = !invocation ? 0 : moment(invocation.dateCompleted).diff(moment(invocation.dateCreated), "seconds");

	return (
		<ListItem>
			<ListItemActions>
				<ListItemAction icon={<Launch />} onClick={openJob} />
			</ListItemActions>
			<ListItemContent onClick={openJob}>
				<TextBlock
					flat
					primary={formatJobName(job)}
					secondary={
						<InlineList>
							{formatDateTime(job.dateCreated)}
							{moment(job.dateCreated).fromNow()}
						</InlineList>
					}
				/>
				<ListItemChips>
					{!!job.cronJob && <ListItemChip label="Cronjob" color="primary" />}
					<ListItemChip label={formatJobStatus(job.status)} color={paintJobStatus(job.status)} />
					{job.status === "Completed" && !!duration && duration > 1000 * 60 && (
						<ListItemChip label={moment.duration(duration).humanize()} />
					)}
				</ListItemChips>
			</ListItemContent>
		</ListItem>
	);
};
