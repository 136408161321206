import TextBlock from "../../Components/Layout/TextBlock";
import { colors } from "@mui/material";
import { BillingProps } from "./useBilling";
import formatDateTime from "../../system/formatDateTime";

export interface BillingStatusProps extends BillingProps {
	showDate? : boolean
}

export default ({ billing, showDate = true } : BillingStatusProps) => {
	switch (billing.status) {
		case "Created":
			return (
				<TextBlock
					chip={{
						label: "Erstellt",
						color: "secondary"
					}}
					secondary={showDate && formatDateTime(billing.dateCreated)}
				/>
			);
		case "Closed":
			return (
				<TextBlock
					chip={{
						label: "Abgerechnet",
						color: "primary"
					}}
					secondary={showDate && formatDateTime(billing.dateClosed)}
				/>
			);
		case "Approved":
			return (
				<TextBlock
					chip={{
						label: "Genehmigt",
						explicitColor: colors.green[500]
					}}
					secondary={showDate && formatDateTime(billing.dateApproved)}
				/>
			);
	}
}
