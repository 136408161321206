const utf8Token = "utf-8''";

export default (contentDisposition : string) => {
	if (!contentDisposition) {
		return null;
	}

	const entries = Object.fromEntries(contentDisposition
		.split(';')
		.map((e : string) => e.trim().split('='))
		.filter((e : string[]) => e.length > 1)
	);

	let filename = entries['filename*'] || entries.filename;
	if (filename.toLowerCase().indexOf(utf8Token) === 0) {
		filename = decodeURIComponent(filename.substr(utf8Token.length));
	}

	return filename;
}
