import { Add, CloudDownload, CloudUpload, RotateLeft } from "@mui/icons-material";
import { FormControl, FormControlLabel, InputLabel, MenuItem, Select } from "@mui/material";
import axios from "axios";
import Sort, { SortItem } from "Components/Sort";
import fileDownload from "js-file-download";
import { Dispatch, SetStateAction } from "react";
import { useHistory } from "react-router";
import IndeterminateCheckbox from "../../Components/IndeterminateCheckbox";
import Sidebar from "../../Components/Sidebar/Sidebar";
import SidebarButton from "../../Components/Sidebar/SidebarButton";
import SidebarForm from "../../Components/Sidebar/SidebarForm";
import SidebarGroup from "../../Components/Sidebar/SidebarGroup";
import executeWithProgressDialog from "../../Dialogs/executeWithProgressDialog";
import getFiles from "../../Dialogs/getFiles";
import { PricingRequest, PricingRequestStatus, StorageFile, UserRole } from "../../system/Domain";
import parseContentDispositionFilename from "../../system/parseContentDispositionFilename";
import useRunAction from "../../system/useRunAction";
import useUser from "../../system/useUser";
import { PricingRequestQuery } from "./PricingRequestsLayout";

interface Props {
	query: PricingRequestQuery
	setQuery: Dispatch<SetStateAction<PricingRequestQuery>>
	resetQuery: () => PricingRequestQuery,
	reload: () => void
}

const sortItems: SortItem[] = [
	{
		title: "Erstellt",
		field: "dateCreated"
	}
];

export default ({ query, setQuery, resetQuery, reload }: Props) => {
	const history = useHistory();
	const [exportActionRunning, runExportAction] = useRunAction();
	const [importActionRunning, runImportAction] = useRunAction();
	const [, , , , hasAnyRole] = useUser();

	const createExport = () => runExportAction(async () => {
		const { data: file } = await axios.post<StorageFile>(`/api/pricing-requests/export`);

		const response = await axios.get(`/api/storage/${file.hash}`, {
			responseType: "blob"
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);

		reload();
	});

	const importResults = () => runImportAction(async () => {
		const result = await getFiles("Bepreisung hochladen", { multiple: false });
		if (!result.ok) {
			return;
		}

		const file = result.files[0];
		await executeWithProgressDialog(async progress => {
			const formData = new FormData();
			formData.append("file", file);

			await axios.post<PricingRequest[]>(`/api/pricing-requests/import`,
				formData, {
				headers: {
					"Content-Type": "multipart/form-data"
				},
				onUploadProgress: evt => {
					const completed = Math.ceil((evt.loaded / evt.total) * 100);
					progress(completed);
				}
			});

			reload();
		});
	});

	const isSalesManagerOrAdminOrBackoffice = hasAnyRole([UserRole.SalesManager, UserRole.SalesAdmin, UserRole.SalesBackoffice]);

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarButton
					startIcon={<Add />}
					color="primary"
					label="Neue Anfrage"
					onClick={() => history.push(`/sales/pricing-requests/new`)}
				/>
			</SidebarGroup>
			{isSalesManagerOrAdminOrBackoffice && (
				<SidebarGroup>
					<SidebarButton
						startIcon={<CloudDownload />}
						label="Anfragen exportieren"
						disabled={exportActionRunning}
						onClick={createExport}
						color="inherit"
					/>
					<SidebarButton
						startIcon={<CloudUpload />}
						label="Preise importieren"
						disabled={importActionRunning}
						onClick={importResults}
						color="inherit"
					/>
				</SidebarGroup>
			)}
			<SidebarGroup>
				<SidebarForm>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Status</InputLabel>
						<Select
							value={query.status || "-"}
							onChange={(e) => {
								let value = e.target.value as string | undefined;
								if (value === "-") {
									value = undefined;
								}

								setQuery(q => ({
									...q,
									skip: 0,
									status: value as PricingRequestStatus
								}));
							}}
						>
							<MenuItem value="-">Alle</MenuItem>
							<MenuItem value="Created">Erstellt</MenuItem>
							<MenuItem value="Requested">Angefragt</MenuItem>
							<MenuItem value="Pricing">Bepreisung</MenuItem>
							<MenuItem value="Priced">Bepreist</MenuItem>
							<MenuItem value="Completed">Abgeschlossen</MenuItem>
							<MenuItem value="Withdrawn">Zurückgezogen</MenuItem>
						</Select>
					</FormControl>

					<Sort
						items={sortItems}
						value={query.sort}
						onChange={sort => setQuery(q => ({
							...q,
							skip: 0,
							sort
						}))}
					/>

					<FormControl fullWidth>
						<FormControlLabel
							control={(
								<IndeterminateCheckbox
									value={query.onlyArmada}
									set={value => setQuery(q => ({
										...q,
										onlyArmada: value
									}))}
								/>
							)}
							label="Armada Tickets"
						/>
					</FormControl>
				</SidebarForm>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarButton
					startIcon={<RotateLeft />}
					label="Filter zurücksetzen"
					onClick={resetQuery}
				/>
			</SidebarGroup>
		</Sidebar>
	);
}
