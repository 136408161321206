import axios from 'axios';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Add, FormatListNumbered } from '@mui/icons-material';

import Sidebar from '../../Components/Sidebar/Sidebar';
import SidebarButton from '../../Components/Sidebar/SidebarButton';
import SidebarGroup from '../../Components/Sidebar/SidebarGroup';
import { Billing } from '../../system/Domain';

export interface NewBillingSidebarProps {
	billing : Partial<Billing>
}

export default ({ billing } : NewBillingSidebarProps) => {
	const history = useHistory();
	const [isCreating, setIsCreating] = useState(false);
	const isBillingReady = !!billing.company && !!billing.dateFrom && !!billing.dateTo;

	const createBilling = async () => {
		try {
			setIsCreating(true);
			const { data: createdBilling } = await axios.post<Billing>(`/api/billings`, billing);

			history.push(`/billings/${createdBilling.id}`);
		} finally {
			setIsCreating(false);
		}
	};

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarButton
					startIcon={<FormatListNumbered />}
					label="Alle Abrechnungen"
					onClick={() => history.push(`/billings`)}
				/>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarButton
					color="primary"
					startIcon={<Add />}
					label="Abrechnung erstellen"
					onClick={createBilling}
					disabled={!isBillingReady || isCreating}
				/>
			</SidebarGroup>
		</Sidebar>
	);
}
