import log from "loglevel";
// @ts-ignore
import remote from "loglevel-plugin-remote";

remote.apply(log, {
	url: '/api/client-logs',
	method: 'POST',
	headers: {},
	token: undefined,
	onUnauthorized: (failedToken : any) => {},
	timeout: 0,
	interval: 1000,
	level: 'trace',
	backoff: {
		multiplier: 2,
		jitter: 0.1,
		limit: 30000,
	},
	capacity: 500,
	stacktrace: {
		levels: ['trace', 'warn', 'error'],
		depth: 3,
		excess: 0,
	},
	timestamp: () => new Date().toISOString(),
	format: (log : any) => {
		log.level = log.level.label;
		log.url = window.location.href;
		return log;
	},
});

log.enableAll();

export default () => {
	return log;
};
