import { Box } from "@mui/material";
import axios from "axios";
import List from "Components/List";
import Search from "Components/Search";
import RootNavigation from "Navigation/RootNavigation";
import { useState } from "react";
import { useHistory } from "react-router";
import Layout from "../../Components/Layout/Layout";
import { LeasmanAddress } from "../../system/Domain";
import LeasmanAddressListItem from "./LeasmanAddressListItem";

export default () => {
	const history = useHistory();
	const [customers, setCustomers] = useState<LeasmanAddress[]>();

	const search = async (fullText?: string) => {
		const { data } = await axios.post<LeasmanAddress[]>(`/api/leasman/addresses/search`, {
			target: "index",
			query: {
				kinds: [1],
				types: ["KD"],
				fullText,
			},
		});

		setCustomers(data);
	};

	return (
		<Layout title="Leasingkunden" navigation={<RootNavigation />}>
			<Search onSearch={search} realtime autoFocus disableEnter />
			<Box pt={2}>
				<List>
					{!!customers &&
						customers.map((c) => (
							<LeasmanAddressListItem
								key={c.id}
								address={c}
								onClick={() => history.push(`/leasing/customers/${c.id}`)}
							/>
						))}
				</List>
			</Box>
		</Layout>
	);
};
