import axios from 'axios';
import TileContent from 'Components/Tiles/TileContent';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Add, Delete, Directions, Print } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';

import ActionButton from '../../../../Components/ActionButton';
import Tile from '../../../../Components/Tiles/Tile';
import TileActions from '../../../../Components/Tiles/TileActions';
import delegateReturnForm from '../../../../Dialogs/delegateReturnForm';
import { ReturnForm as ReturnFormType, TransportOrder } from '../../../../system/Domain';
import extendReturnForm from '../../../../system/extendReturnForm';
import useAsyncEffect from '../../../../system/useAsyncEffect';
import ReturnFormTable from './ReturnFormTable';
import useAction from "../../../../system/useAction";

export interface ReturnFormsProps {
	order: TransportOrder
}

export default ({ order }: ReturnFormsProps) => {
	const [fetching, setFetching] = useState(false);
	const [returnForms, setReturnForms] = useState<Array<ReturnFormType>>([]);
	const history = useHistory();

	const load = useCallback(async order => {
		setReturnForms([]);

		if (!order) {
			return;
		}

		setFetching(true);
		const response = await axios.get<Array<ReturnFormType>>(`/api/orders/transports/${order.id}/return-forms`);
		setReturnForms(response.data.map(r => extendReturnForm(r)));
		setFetching(false);
	}, []);

	useAsyncEffect(async () => {
		await load(order);
	}, [order]);

	const newReturnForm = async () => {
		if (returnForms.length > 0) {
			const { data: newReturnForm } = await axios.post(`/api/orders/transports/${order.id}/return-forms`, { predecessorId: returnForms[0].id }, { params: { apply: false } });
			history.push({
				pathname: `/orders/transports/${order.id}/return-forms/${newReturnForm.id}`
			});
		} else {
			history.push({
				pathname: `/orders/transports/${order.id}/return-forms/new`
			});
		}
	};

	const [createNewReturnForm] = useAction(newReturnForm);

	const deleteReturnForm = async (returnForm: ReturnFormType) => {
		if (!returnForm.delete) {
			return;
		}

		const deleted = await returnForm.delete();
		if (deleted) {
			setReturnForms(returnForms => returnForms.filter(r => r.id !== returnForm.id));
		}
	};

	const delegateNewReturnForm = async () => {
		await delegateReturnForm({ order });
		await load(order);
	};

	const delegateExistingReturnForm = async (returnForm: ReturnFormType) => {
		await delegateReturnForm({ order, returnForm });
		await load(order);
	};

	const [printing, setPrinting] = useState(false);
	const printReturnForm = useCallback(async returnForm => {
		if (!returnForm.print) {
			return;
		}

		setPrinting(true);

		try {
			await returnForm.print();
		} finally {
			setPrinting(false);
		}
	}, []);

	return (
		<Tile title="Rücknahmeprotokolle">
			{fetching && <CircularProgress />}
			{!fetching && returnForms.length > 0 && (
				<TileContent dense>
					<ReturnFormTable
						returnForms={returnForms}
						isLoading={fetching}
						showHeader={false}
						actions={r => (<>
							{r.isSigned && !r.isSigned() && (
								<IconButton
									sx={{ padding: 1.5 }}
									edge="end"
									onClick={e => {
										delegateExistingReturnForm(r);
										e.stopPropagation();
									}}>
									<Directions />
								</IconButton>
							)}
							<IconButton
								sx={{ padding: 1.5 }}
								edge="end"
								onClick={e => {
									printReturnForm(r);
									e.stopPropagation();
								}}
								disabled={printing}>
								<Print />
							</IconButton>
							{r.isSigned && !r.isSigned() && (
								<IconButton
									sx={{ padding: 1.5 }}
									edge="end"
									onClick={e => {
										deleteReturnForm(r);
										e.stopPropagation();
									}}>
									<Delete />
								</IconButton>
							)}
						</>)} />
				</TileContent>
			)}
			<TileActions>
				<ActionButton
					variant="text"
					onClick={createNewReturnForm}
					disabled={returnForms.length > 0 && returnForms[0].isSigned && !returnForms[0].isSigned()}
					startIcon={<Add />}
				>
					{returnForms.length > 0 && <span>Differenzprotokoll erstellen</span>}
					{returnForms.length === 0 && <span>Neues Protokoll erstellen</span>}
				</ActionButton>
				{returnForms.length === 0 && (
					<ActionButton
						variant="text"
						onClick={delegateNewReturnForm}
						startIcon={<Directions />}
					>
						Neues Protokoll an Fahrer delegieren
					</ActionButton>
				)}
			</TileActions>
		</Tile>
	);
}
