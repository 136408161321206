import axios from "axios";
import { useDropzone } from "react-dropzone";
import { useCallback, useState } from "react";
import uploadImage from "../../../cloud-upload.png";
import ThemedDialog from "../../../Components/ThemedDialog";
import executeWithProgressDialog from "../../../Dialogs/executeWithProgressDialog";
import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";
import { alpha, Box, Button, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from "@mui/material";

interface Props extends ReactConfirmProps<any> {
	show: boolean;
}

interface Result {
	company: string;
	lines: number;
	areas: number;
}

const UploadTransportPricingDialog = (props: Props) => {
	const [result, setResult] = useState<Result>();
	const [canCancel, setCanCancel] = useState(true);

	const theme = useTheme();
	const color = theme.palette.primary.main;

	const handleClose = () => {
		props.cancel();
	};

	const onDrop = useCallback(async (files: File[]) => {
		await executeWithProgressDialog(async (progress) => {
			let formData = new FormData();
			formData.append("file", files[0]);

			axios
				.post<Result>(`/api/imports/transport-pricing`, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
					onUploadProgress: (event) => {
						const completed = Math.ceil((event.loaded / event.total) * 100);
						progress(completed);
					},
				})
				.then((response) => {
					setResult(response.data);
					setCanCancel(false);
				})
				.catch(() => props.cancel()); // we want the dialog disappear froim the screen immediately in case of error - error box will be displayed instead
		});
	}, []);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		multiple: false,
		noKeyboard: true,
		noDragEventsBubbling: true,
	});

	return (
		<ThemedDialog open={props.show} maxWidth="sm" fullWidth onClose={handleClose}>
			<DialogTitle>Preis Csv hochladen</DialogTitle>
			<DialogContent>
				{!result && (
					<Box
						{...getRootProps({ outline: "none" })}
						sx={{
							position: "relative",
							border: "2px dashed",
							borderColor: alpha(color, 0.12),
							borderRadius: 4,
							width: "100%",
							height: 150,
							cursor: "pointer",
							color: alpha(color, 0.3),
							"&:hover": {
								borderColor: alpha(color, 0.3),
								color: alpha(color, 0.6),
							},
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							textAlign: "center",
							fontSize: "0.75rem",
							transition: "border 200ms ease-out, color 200ms ease-out",
							outline: "none",
							lineHeight: "1.9em",
							overflow: "hidden",
						}}
					>
						<input {...getInputProps()} />
						<img src={uploadImage} alt="upload" />
						<Typography gutterBottom>Dateien per Drag and Drop hierher verschieben</Typography>
					</Box>
				)}
				{result && (
					<Box mx={2}>
						<Box>Firma: {result.company}</Box>
						<Box>Zeilen gefunden: {result.lines}</Box>
						<Box>Gebiete aktualisiert: {result.areas}</Box>
					</Box>
				)}
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="secondary" href="" onClick={handleClose} disabled={!canCancel}>
					Abbrechen
				</Button>
				<Button variant="outlined" color="primary" href="" onClick={() => props.proceed({})}>
					Ok
				</Button>
			</DialogActions>
		</ThemedDialog>
	);
};

export default () => {
	const dialog = confirmable((props) => <UploadTransportPricingDialog {...props} />);
	return createConfirmation(dialog)();
};
