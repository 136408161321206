import { UserRole } from "system/Domain";
import LayoutNavigationItem from "../Components/Layout/LayoutNavigationItem";
import { Explore, FormatListNumbered, Gavel, Search } from "@mui/icons-material";
import LayoutNavigationSection from "../Components/Layout/LayoutNavigationSection";

export default () => {
	return (
		<LayoutNavigationSection title="Verkauf">
			<LayoutNavigationItem
				roles={[UserRole.SalesUser]}
				icon={<Explore />}
				title="Dashboard"
				path="/sales/dashboard"
			/>
			<LayoutNavigationItem
				roles={[UserRole.SalesUser]}
				icon={<Search />}
				title="Verkäufe"
				path="/sales/search"
			/>
			<LayoutNavigationItem
				roles={[UserRole.SalesUser]}
				icon={<Gavel />}
				title="Carmarket Auktionen"
				path="/sales/carmarket"
			/>
			<LayoutNavigationItem
				roles={[UserRole.SalesUser]}
				icon={<Gavel />}
				title="BCA Auktionen"
				path="/sales/bca"
			/>
			<LayoutNavigationItem
				roles={[UserRole.AldUser, UserRole.SalesUser]}
				icon={<FormatListNumbered />}
				title="Kaufpreisanfragen"
				path="/sales/pricing-requests"
			/>
			<LayoutNavigationItem
				roles={[UserRole.SalesUser]}
				icon={<FormatListNumbered />}
				title="Preisschilder"
				path="/sales/pricing-tags"
			/>
		</LayoutNavigationSection>
	);
};
