import LayoutNavigation from "../Components/Layout/LayoutNavigation";
import SalesNavigationSection from "./SalesNavigationSection";
import RootNavigationSection from "./RootNavigationSection";
import { Sale, Vehicle } from "system/Domain";
import SaleNavigationSection from "./SaleNavigationSection";

interface Props {
	sale?: Sale
	vehicle?: Vehicle
}

export default ({ sale, vehicle }: Props) => {
	return (
		<LayoutNavigation>
			{sale && vehicle && (
				<SaleNavigationSection sale={sale} vehicle={vehicle} />
			)}
			<SalesNavigationSection />
			<RootNavigationSection />
		</LayoutNavigation>
	);
};
