import Thumbnail from 'Components/Thumbnail';
import moment from 'moment';

import { Link } from '@mui/icons-material';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Typography } from '@mui/material';

import { AssessmentItem } from './AssessmentsPage';

interface Props {
	assessmentItem : AssessmentItem
	onSelect? : () => void
}

const AssessmentItemCard = ({ assessmentItem, onSelect } : Props) => {
	const file = assessmentItem.assessment;

	const handleSelect = () => {
		onSelect && onSelect();
	};

	const info = assessmentItem.type === "DekraAssessment" ? "Ohne Auftrag" : "Beauftragt";
	const dateCreated = moment(assessmentItem.dateCreated).format("L");

	return (
		<Card>
			<CardHeader
				title={
					<Box display="flex" justifyContent="space-between">
						<Box>{moment(assessmentItem.date).format("L")}</Box>
						{assessmentItem.isLinked && (
							<Box><Link /></Box>
						)}
					</Box>
				}
				subheader={
					assessmentItem.dateRejected ? (
						<Typography color='error'>{`Gutachten storniert ∙ ${dateCreated}`}</Typography>
					) : (
						<Typography>{`${info} ∙ ${dateCreated}`}</Typography>
					)
				}
			/>
			<CardContent>
				<Thumbnail
					width={300}
					height={400}
					asyncUrl={`/api/storage/${file.hash}/thumbnail`}
					downloadUrl={`/api/storage/${file.hash}`}
					standalone
				/>
			</CardContent>
			<CardActions>
				<Button
					color="primary"
					onClick={handleSelect}
					disabled={!onSelect}
				>
					{assessmentItem.type === "DekraAssessment" ? "Übernehmen" : "Anzeigen"}
				</Button>
			</CardActions>
		</Card>
	)
};

export default AssessmentItemCard
