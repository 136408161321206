import LayoutNavigation from "../Components/Layout/LayoutNavigation";
import RootNavigationSection from "./RootNavigationSection";
import VehiclesNavigationSection from "./VehiclesNavigationSection";

export default () => {
	return (
		<LayoutNavigation>
			<VehiclesNavigationSection />
			<RootNavigationSection />
		</LayoutNavigation>
	);
};
