import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import "moment-timezone";
import "moment/locale/de";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import ThemedApp from "ThemedApp";
import store from "./system/store";

moment.locale("de");
(window as any).moment = moment;

export default () => {
	return (
		<Provider store={store}>
			<RecoilRoot>
				<LocalizationProvider utils={AdapterMoment} dateAdapter={AdapterMoment}>
					<BrowserRouter>
						<ThemedApp />
					</BrowserRouter>
				</LocalizationProvider>
			</RecoilRoot>
		</Provider>
	);
};
