import { useState } from 'react';
import ThemedDialog from "../../../Components/ThemedDialog";
import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";
import formatVehicleAssessmentRejectionTopic from "../formatVehicleAssessmentRejectionTopic";
import { VehicleAssessmentRejectionTopic, vehicleAssessmentRejectionTopics } from "../../../system/Domain";
import { Box, Button, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";

interface Props extends ReactConfirmProps<any> {
	show: boolean
}

const RejectVehicleAssessmentDialog = (props: Props) => {
	const [topic, setTopic] = useState<VehicleAssessmentRejectionTopic>();
	const [reason, setReason] = useState<string>("");

	const handleClose = () => {
		props.cancel();
	};

	return (
		<ThemedDialog
			open={props.show}
			maxWidth="md"
			onClose={handleClose}
		>
			<DialogTitle>
				Gutachten ablehnen
			</DialogTitle>
			<DialogContent>
				<Box mb={4} minWidth="300px">
					<FormControl
						fullWidth
						variant="standard"
					>
						<InputLabel>Ablehnungsgrund</InputLabel>
						<Select
							onChange={(e) => setTopic(e.target.value as VehicleAssessmentRejectionTopic)}
							fullWidth
						>
							{vehicleAssessmentRejectionTopics.map(t => (
								<MenuItem key={t} value={t}>
									{formatVehicleAssessmentRejectionTopic(t)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Box>
				<Box mb={2}>
					<TextField
						fullWidth
						variant="outlined"
						value={reason}
						label="Beschreibung"
						onChange={(e) => setReason(e.target.value)}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="secondary"
					href=""
					onClick={handleClose}
				>
					Abbrechen
				</Button>
				<Button
					variant="outlined"
					color="primary"
					href=""
					disabled={!topic || !reason}
					onClick={() => props.proceed({
						topic, reason
					})}
				>
					Ok
				</Button>
			</DialogActions>
		</ThemedDialog>
	);
};

interface Result {
	reason: string
	topic: VehicleAssessmentRejectionTopic
}

export default (options: any) => {
	const dialog = confirmable(props => <RejectVehicleAssessmentDialog {...props} {...options} />);
	return createConfirmation<Result>(dialog)();
}
