import { CircularProgress } from "@mui/material";
import axios from "axios";
import VehicleSearch from "Components/VehicleSearch";
import OrdersNavigation from "Navigation/OrdersNavigation";
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import Layout from "../../../Components/Layout/Layout";
import { Vehicle } from "../../../system/Domain";

const UnregistrationOrdersNew = () => {
	const history = useHistory();
	const [isProcessing, setIsProcessing] = useState<boolean>(false);

	const handleCreate = async (vehicle: Vehicle) => {
		setIsProcessing(true);

		try {
			await axios.post(`/api/orders/unregistrations`, {
				vehicleId: vehicle.id
			});

			history.push("/orders/unregistrations");
		} finally {
			setIsProcessing(false);
		}
	};

	return (
		<Layout
			title="Neuer Abmeldeauftrag"
			navigation={<OrdersNavigation />}
		>
			<VehicleSearch
				onClick={handleCreate}
			/>
			{isProcessing && (
				<CircularProgress size={48} />
			)}
		</Layout>
	);
};

export default UnregistrationOrdersNew;
