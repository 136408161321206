import { Box, Typography } from "@mui/material";
import ActionButton from "Components/ActionButton";
import Actions from "Components/Actions";
import Tile from "Components/Tiles/Tile";
import TileContent from "Components/Tiles/TileContent";
import TextGroup from "Pages/VehicleSales/TextGroup";
import TextLine from "Pages/VehicleSales/TextLine";
import { PoolFleetVehicle } from "system/Domain";

interface Props {
	vehicle: PoolFleetVehicle
	disabled?: boolean
	onSyncWithLeasman?: () => void
}

export default function PoolFleetMainContractTile({ vehicle, disabled, onSyncWithLeasman }: Props) {
	return (
		<Box>
			<Typography gutterBottom variant="h5">Mantelvertrag</Typography>
			<Tile>
				<TileContent>
					<TextGroup title="Allgemein">
						<TextLine
							label="Vertragsnummer"
							value={vehicle.contractId}
						/>
						<TextLine
							label="Model"
							value={vehicle.makeModel}
						/>
						<TextLine
							label="Leasman Status"
							value={vehicle.contractStatusId}
						/>
						<TextLine
							label="Vin"
							value={vehicle.vin}
						/>
						<TextLine
							label="Objekt ID"
							value={vehicle.vehicleId}
						/>
					</TextGroup>
					<Box>
						<Actions>
							<ActionButton
								color="primary"
								variant="text"
								size="small"
								disabled={!Boolean(onSyncWithLeasman) || disabled}
								onClick={onSyncWithLeasman}
							>
								Sync mit Leasman
							</ActionButton>
						</Actions>
					</Box>
				</TileContent>
			</Tile>
		</Box>
	)
}
