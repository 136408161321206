import useUser from "../../system/useUser";
import { UserRole, VehicleService } from "../../system/Domain";
import moment from "moment";

export interface VehicleServicePermissions {
	canConfirmRequests : boolean
	canCreate : boolean
	isRestrictedToHisCompany : boolean
	canRejectRequests : boolean
	canSeeMainMenuBadge : boolean
	canSeePrices : boolean
	canCloseAtAll: boolean
	canChangePrice : (vehicleService : VehicleService) => boolean
	canChangeCompany : (vehicleService : VehicleService) => boolean
	canClose : (vehicleService : VehicleService) => boolean
	canStart : (vehicleService : VehicleService) => boolean
	canCancel : (vehicleService : VehicleService) => boolean
	canDelete : (vehicleService : VehicleService) => boolean
}

export default () : VehicleServicePermissions => {
	const [user] = useUser();

	const isOpenStatus = (vehicleService : VehicleService) : boolean => {
		return vehicleService?.status === "Created" || vehicleService?.status === "Assigned" || vehicleService?.status === "Requested";
	};

	const isCreator = (vehicleService : VehicleService) : boolean => {
		if (!user || !vehicleService) return false;

		return vehicleService.createdBy?.id === user.id;
	};

	const matchingCompany = (vehicleService : VehicleService) : boolean => {
		if (!user || !vehicleService) return false;

		return user.company?.name === vehicleService.assignedTo?.name;
	};

	const isServiceProvider = (user && user.roles.some(r => [UserRole.ServiceProvider].includes(r))) ?? false;
	const isServiceManager = (user && user.roles.some(r => [UserRole.ServiceManager].includes(r))) ?? false;
	const isServiceUser = (user && user.roles.some(r => [UserRole.ServiceUser].includes(r))) ?? false;
	const isServiceAccounting = (user && user.roles.some(r => [UserRole.ServiceAccounting].includes(r))) ?? false;
	const hasAnyServiceRole = isServiceProvider || isServiceManager || isServiceUser || isServiceAccounting;

	const permissions : VehicleServicePermissions = {
		canConfirmRequests: isServiceManager,
		canCreate: hasAnyServiceRole,
		isRestrictedToHisCompany: isServiceProvider || isServiceAccounting,
		canRejectRequests: isServiceManager,
		canSeePrices: hasAnyServiceRole && !isServiceProvider,
		canCloseAtAll: hasAnyServiceRole,
		canChangePrice: (vehicleService) => isOpenStatus(vehicleService) && (isServiceManager || (isServiceUser && isCreator(vehicleService))),
		canChangeCompany: (vehicleService) => isOpenStatus(vehicleService) && (isServiceManager || (isServiceUser && isCreator(vehicleService))),
		canClose: (vehicleService) => {
			const hasReducedPermissions = hasAnyServiceRole && matchingCompany(vehicleService);

			return isServiceManager || hasReducedPermissions;
		},
		canStart: (vehicleService) => {
			const hasReducedPermissions = hasAnyServiceRole && matchingCompany(vehicleService);

			return isServiceManager || hasReducedPermissions;
		},
		canCancel: (vehicleService) => {
			const hasPermissions = isServiceManager || (hasAnyServiceRole && matchingCompany(vehicleService));
			const correctStatus = vehicleService.status === "Assigned";

			return hasPermissions && correctStatus;
		},
		canDelete: (vehicleService) => {
			const isInTime = moment(vehicleService.dateCreated).isSame(moment.utc(), 'day');

			return isCreator(vehicleService) && isInTime && isOpenStatus(vehicleService);
		},
		canSeeMainMenuBadge: isServiceManager
	};

	return permissions;
}
