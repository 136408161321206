import { ReactNode } from "react";
import { Box,Grid } from "@mui/material";
import FormElementLabel from "./FormElementLabel";

export type FormElementProps = {
	label?: ReactNode
	error?: boolean
	gridProps?: any
	children?: ReactNode
};

export default ({ label, error, gridProps, children }: FormElementProps) => {
	return (
		<Grid item xs={12} {...gridProps}>
			{label && (typeof (label) === "string") && (
				<Box>
					<Box mb={0.25}>
						<FormElementLabel text={label} error={error} />
					</Box>
					{children}
				</Box>
			)}
			{label && (typeof (label) !== "string") && (
				<Box>
					{label}
					{children}
				</Box>
			)}
			{!label && (
				<Box>
					{children}
				</Box>
			)}
		</Grid>
	);
};
