import { alpha, Box, SxProps, useTheme } from "@mui/material";

interface Props {
	sx?: SxProps
}

export default ({ sx }: Props) => {
	const theme = useTheme();

	return (
		<Box sx={{
			ml: -2,
			mr: -2,
			mt: 3,
			mb: 3,
			height: "1px",
			backgroundColor: alpha(theme.palette.background.default, 0.7),
			...sx
		}} />
	);
};
