import SalesNavigation from "Navigation/SalesNavigation";
import { useHistory } from "react-router";
import { UserRole } from "system/Domain";
import useUser from "system/useUser";
import Layout from "../../Components/Layout/Layout";
import SalesKpisTile from "./SalesKpis";
import SalesSidebar from "./SalesSidebar";

export default () => {
	const [, , hasRole] = useUser();
	const history = useHistory();

	if (hasRole(UserRole.AldUser) && !hasRole(UserRole.SalesUser)) {
		history.push(`/sales/pricing-requests`);
		return null;
	}

	return (
		<Layout title="Dashboard" navigation={<SalesNavigation />} sidebar={<SalesSidebar />}>
			<SalesKpisTile />
		</Layout>
	);
};
