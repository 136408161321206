import { useCallback, useState } from "react";
import { Import, ImportStatus, ImportType } from "../../system/Domain";
import { useHistory } from "react-router-dom";
import { useAsync } from "react-use";
import axios from "axios";
import { Box, Chip, PropTypes, TableBody, TableHead, TableRow } from "@mui/material";
import PaperTable from "../../Components/PaperTable";
import importStatus from "./importStatus";
import TextBlock from "../../Components/Layout/TextBlock";
import moment from "moment";
import dateFormats from "../../system/dateFormats";
import FetchNextButton from "../../Components/FetchNextButton";
import { useDropzone } from "react-dropzone";
import executeWithProgressDialog from "../../Dialogs/executeWithProgressDialog";
import { Colgroup, TableCell } from "../../Components/BreakpointStyledComponents";

const importTypes = {
	LogisticOrdersImport: "Logistkbeauftragung",
	VehicleServiceImport: "Dienstleistungen",
	ReturnOrdersImport: "Rückholungsbeauftragung",
	CompleteUnregistrationOrdersImport: "Abmeldeaufträge",
	VehicleRegistrationDocumentRequestsImport: "Abfragen Fahrzeugpapiere",
	VehicleRegistrationDocumentArrivalsImport: "Ankunft Fahrzeugpapiere",
	VehicleRegistrationDocumentHandoversImport: "Ausgabe Fahrzeugpapiere",
	VehicleRegistrationDocumentUnregistrationsImport: "Abmeldungen DAD",
	LeaseplanVehicleImport: "Leaseplan Fahrzeuge",
};

interface Props {
	importType: ImportType;
	secondaryImportType?: ImportType;
}

export default ({ importType, secondaryImportType }: Props) => {
	const history = useHistory();

	const [imports, setImports] = useState<Import[]>([]);
	const [skip, setSkip] = useState(0);
	const take = 20;

	const onDrop = useCallback(async (files: File[]) => {
		await executeWithProgressDialog(async (progress) => {
			let formData = new FormData();
			formData.append("file", files[0]);
			formData.append("importType", importType);

			const { data: orderImport } = await axios.post<Import>(`/api/imports`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
				onUploadProgress: (evt) => {
					const completed = Math.ceil((evt.loaded / evt.total) * 100);
					progress(completed);
				},
			});
			history.push(`/imports/${orderImport.id}`);
		});
	}, []);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		multiple: false,
		noClick: true,
		noKeyboard: true,
		noDragEventsBubbling: true,
	});

	useAsync(async () => {
		const { data: importsToAdd } = await axios.get<Import[]>(`/api/imports?importType=${importType}&skip=${skip}&take=${take}`);

		let secondaryImportsToAdd: Import[] = [];

		if (secondaryImportType) {
			const { data } = await axios.get<Import[]>(`/api/imports?importType=${secondaryImportType}&skip=${skip}&take=${take}`);

			secondaryImportsToAdd = data;
		}

		setImports((imports) => [...imports, ...importsToAdd, ...secondaryImportsToAdd].sort((a, b) => (a.dateCreated < b.dateCreated ? 1 : -1)));
	}, [importType, secondaryImportType, skip, take]);

	const handleNext = () => {
		setSkip((skip) => skip + take);
	};

	return (
		<>
			<div {...getRootProps({ outline: "none" })}>
				<input {...getInputProps()} />
				<PaperTable>
					<Colgroup xs>
						<col width="100%" />
					</Colgroup>
					<Colgroup sm md lg xl>
						<col width="auto" />
						<col width="100%" />
					</Colgroup>
					<TableHead>
						<TableRow sx={{ display: { xs: "contents", sm: "none" } }}>
							<TableCell>Import</TableCell>
						</TableRow>
						<TableRow sx={{ display: { xs: "none", sm: "contents" } }}>
							<TableCell>Status</TableCell>
							<TableCell>Dateiimport</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{imports.map((i) => {
							let color: Exclude<PropTypes.Color, "inherit"> = "default";

							switch (i.status) {
								case ImportStatus.Committed:
									color = "primary";
									break;
								case ImportStatus.Imported:
								case ImportStatus.Created:
									color = "secondary";
									break;
							}

							const handleDetails = () => {
								history.push(`/imports/${i.id}`);
							};

							return (
								<TableRow key={i.id} hover onClick={handleDetails}>
									<TableCell>
										<Chip size="small" label={importStatus[i.status]} color={color} />
										<Box sx={{ display: { xs: "contents", sm: "none" } }}>
											<Box mt={2}>
												<TextBlock
													primary={importTypes[i.importType]}
													secondary={
														<span>
															{moment(i.dateCreated).format(dateFormats.date)} | {i.filename}
														</span>
													}
												/>
											</Box>
										</Box>
									</TableCell>
									<TableCell sm md lg xl>
										<TextBlock
											primary={importTypes[i.importType]}
											secondary={
												<span>
													{moment(i.dateCreated).format(dateFormats.date)} | {i.filename}
												</span>
											}
										/>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</PaperTable>
			</div>

			<FetchNextButton my={3} hidden={imports.length < take} onNext={handleNext} />
		</>
	);
};
