import { useTheme } from "@mui/styles";
import InlineList from "Components/InlineList";
import { Job, JobStatus, ThemeColor } from "system/Domain";
import TextBlock from "../../../Components/Layout/TextBlock";
import formatDateTimeWithSeconds from "../../../system/formatDateTimeWithSeconds";
import formatRelativeDate from "../../../system/formatRelativeDate";
import { formatJobStatus, paintJobStatus } from "./JobListItem";

export interface JobStatusProps {
	job: Job;
	status?: JobStatus;
	statusDate?: string;

	showDate?: boolean;
	showRelativeDate?: boolean;
}

export default ({ job, status, statusDate, showDate = true, showRelativeDate = false }: JobStatusProps) => {
	const theme = useTheme();
	const getColor = (color: ThemeColor): string => {
		switch (color) {
			case "primary":
				return theme.palette.primary.main;
			case "secondary":
				return theme.palette.secondary.main;
			case "success":
				return theme.palette.success.main;
			case "warning":
				return theme.palette.warning.main;
			case "error":
				return theme.palette.error.main;
			case "grey":
				return theme.palette.grey[500];
		}
	};

	if (!status) {
		status = job.status;
	}

	if (!status) {
		return null;
	}

	const date = statusDate || job.dateLastActive;

	return (
		<TextBlock
			chip={{
				label: formatJobStatus(status),
				explicitColor: getColor(paintJobStatus(status)),
			}}
			secondary={
				<InlineList>
					{showDate && formatDateTimeWithSeconds(date)}
					{showRelativeDate && formatRelativeDate(date)}
				</InlineList>
			}
			nowrap
		/>
	);
};
