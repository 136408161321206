import { useEffect, useState } from "react";
import Layout from "../../Components/Layout/Layout";
import { Grid } from "@mui/material";
import { CatalogVehicle, Equipment, FleetnetVehicle, LeasmanVehicle } from "../../system/Domain";
import Tile from "../../Components/Tiles/Tile";
import axios from "axios";
import useAxiosEffect from "../../system/useAxiosEffect";
import _ from "lodash";
import LeasmanVehicleListTile from "./LeasmanVehicleListTile";
import useVehicle from "../../system/useVehicle";
import { useParams } from "react-router";
import EquipmentList from "./EquipmentList";
import List from "../../Components/List";
import CatalogPreviewListItem from "./CatalogPreviewListItem";
import ListItemContent from "../../Components/ListItemContent";
import ListItem from "../../Components/ListItem";
import BaseDataSidebar from "./BaseDataSidebar";
import VehicleNavigation from "Navigation/VehicleNavigation";
import FleetnetVehicleListTile from "./FleetnetVehicleListTile";
import VehicleHeader from "./VehicleHeader";

export default () => {
	const { id } = useParams<{ id: string }>();
	const [vehicle, setVehicle] = useVehicle(id);

	const [selectedLeasmanVehicle, setSelectedLeasmanVehicle] = useState<LeasmanVehicle>();

	const [catalogVehicle, setCatalogVehicle] = useState<CatalogVehicle>();
	const [leasmanVehicles, setLeasmanVehicles] = useState<LeasmanVehicle[]>();
	const [leasmanVehicle, setLeasmanVehicle] = useState<LeasmanVehicle>();
	const [fleetnetVehicles, setFleetnetVehicles] = useState<FleetnetVehicle[]>([]);
	const [equipment, setEquipment] = useState<Equipment[]>([]);

	useEffect(() => {
		if (!vehicle || !vehicle.leasmanContractId || !leasmanVehicles || leasmanVehicles.length === 0) {
			setLeasmanVehicle(undefined);
			setSelectedLeasmanVehicle(undefined);
			return;
		}

		const leasmanVehicle = leasmanVehicles.find(v => v.contract.id === vehicle.leasmanContractId);
		setLeasmanVehicle(leasmanVehicle);
		setSelectedLeasmanVehicle(leasmanVehicle);
	}, [vehicle, leasmanVehicles]);

	useEffect(() => {
		if (!vehicle && !leasmanVehicle) {
			setEquipment([]);
			return;
		}

		let equipment = vehicle?.equipment || [];
		if (!!leasmanVehicle) {
			equipment = [
				...equipment,
				...leasmanVehicle.equipment.filter(e => !equipment.some(e2 => e2.id === e.id))
			];
		}

		setEquipment(_.orderBy(equipment, e => e.text?.toLowerCase()));
	}, [vehicle, leasmanVehicle]);

	const [loadingCatalog] = useAxiosEffect(async (config) => {
		if (!vehicle?.type.id) {
			return;
		}

		const { data } = await axios.get<CatalogVehicle>(`/api/catalog/vehicles/${vehicle.type.id}`, config);

		setCatalogVehicle(data);
	}, [vehicle?.type?.id]);

	const [loadingLeasman] = useAxiosEffect(async (config) => {
		if (!vehicle) {
			setLeasmanVehicles(undefined);
			return;
		}

		const { data } = await axios.get<LeasmanVehicle[]>(`/api/leasman/vehicles`, {
			...config,
			params: {
				vin: vehicle.vin
			}
		});

		setLeasmanVehicles(data);
	}, [vehicle?.vin]);

	const [loadingFleetnetVehicle] = useAxiosEffect(async (config) => {
		if (!vehicle) {
			setFleetnetVehicles(undefined);
			return;
		}

		const { data } = await axios.get<FleetnetVehicle[]>(`/api/fleetnet/vehicles/`, {
			...config,
			params: {
				vin: vehicle.vin
			}
		});

		setFleetnetVehicles(data);
	}, [vehicle?.vin]);

	return (
		<Layout
			title="Stammdaten"
			plateNumber={vehicle?.plateNumber}
			navigation={<VehicleNavigation vehicle={vehicle} />}
			loading={!vehicle}
			sidebar={vehicle && (
				<BaseDataSidebar
					vehicle={vehicle}
					setVehicle={setVehicle}
					leasmanVehicle={selectedLeasmanVehicle}
				/>
			)}
		>
			{vehicle && (
				<>
					<VehicleHeader vehicle={vehicle} />
					<Grid container spacing={6}>
						<Grid item xs={12}>
							<LeasmanVehicleListTile
								isLoading={loadingLeasman}
								leasmanVehicles={leasmanVehicles}
								isSelected={v => v === selectedLeasmanVehicle}
								onSelect={v => setSelectedLeasmanVehicle(v)}
							/>
						</Grid>
						<Grid item xs={12}>
							<FleetnetVehicleListTile
								isLoading={loadingFleetnetVehicle}
								fleetnetVehicles={fleetnetVehicles}
								isSelected={v => v.id === vehicle.remarketing.fleetnetVehicleId}
								onSelect={() => { }} // feature omitted for the time being
							/>
						</Grid>
						<Grid item xs={12}>
							<Tile title="Fahrzeugtyp">
								<List>
									{!loadingCatalog && !catalogVehicle && (
										<ListItem>
											<ListItemContent>Keinen Fahrzeugtyp gefunden</ListItemContent>
										</ListItem>
									)}
									{!loadingCatalog && catalogVehicle && (
										<CatalogPreviewListItem
											catalogPreview={catalogVehicle}
										/>
									)}
								</List>
							</Tile>
						</Grid>
						<Grid item xs={12}>
							<EquipmentList
								title="Sonderausstattung"
								equipments={equipment.filter(e => e.isExtra)}
								vehicle={vehicle}
								setVehicle={setVehicle}
								catalogVehicle={catalogVehicle}
								leasmanVehicle={leasmanVehicle}
								allowManualEquipment
							/>
						</Grid>
						<Grid item xs={12}>
							<EquipmentList
								title="Serienausstattung"
								equipments={equipment.filter(e => !e.isExtra)}
								vehicle={vehicle}
								setVehicle={setVehicle}
								catalogVehicle={catalogVehicle}
								leasmanVehicle={leasmanVehicle}
							/>
						</Grid>
					</Grid>
				</>
			)}
		</Layout>
	);
}
