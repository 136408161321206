import _ from "lodash";
import axios from "axios";
import { Box, Grid, Typography } from "@mui/material";
import TextBlock from "Components/Layout/TextBlock";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { useAsync } from "react-use";
import InlineList from "../../../Components/InlineList";
import Layout from "../../../Components/Layout/Layout";
import { Plate } from "../../../Components/Plate";
import { Sale, SaleEmail, SaleOrder, SaleOrderStatus, ThemeColor } from "../../../system/Domain";
import useEvent from "../../../system/useEvent";
import SaleEmailCard from "./SaleEmailCard";
import SalesAddressInfo from "./SalesAddressInfo";
import SalesDocumentCard from "./SalesDocumentCard";
import SalesInvoiceCard from "./SalesInvoiceCard";
import SalesOrderCard from "./SalesOrderCard";
import SalesOrderSidebar from "./SalesOrderSidebar";
import ShippingCard from "./ShippingCard";


export const saleOrderStatusLabels = {
	"Active": "Bestellt",
	"Invoiced": "Verrechnet",
	"Paid": "Bezahlt",
	"Cancelled": "Abgebrochen"
}

const saleOrderStatusColors = {
	"Active": "info",
	"Invoiced": "primary",
	"Paid": "success",
	"Cancelled": "warning"
}

export const paintSaleOrderStatus = (status: SaleOrderStatus) => (saleOrderStatusColors[status] as ThemeColor);

export interface CurrentSalesOrder {
	sale: Sale
	order: SaleOrder
}

const SalesOrderPage = () => {
	const params = useParams<{ id: string, orderId: string }>();
	const [salesOrder, setSalesOrder] = useState<CurrentSalesOrder | null>(null);
	const history = useHistory();

	useAsync(async () => {
		if (!params) {
			return null;
		}

		const orderId = Number.parseInt(params.orderId);

		const { data: sale } = await axios.get<Sale>(`/api/sales/${params.id}`);
		const order = sale.orders.find(o => o.id === orderId);

		if (!order) {
			return null;
		}

		setSalesOrder({
			sale,
			order
		});
	}, [params]);

	const handleSalesChanged = (sales: Sale) => {
		const orderId = Number.parseInt(params.orderId);
		const order = sales.orders.find(o => o.id === orderId);

		if (!order) {
			setSalesOrder(null);
			return;
		}

		setSalesOrder({
			sale: sales,
			order
		});
	};

	useEvent("sale:updated", async data => {
		const { id } = params;

		const { data: updatedSale } = await axios.get<Sale>(`/api/sales/${id}`);

		handleSalesChanged(updatedSale);
	}, [params]);

	const handleSelectEmail = (email: SaleEmail) => {
		history.push(`/sales/${sales!.id}/orders/${order!.id}/emails/${email.mailTrackingId}`)
	};

	const order = salesOrder?.order;
	const sales = salesOrder?.sale;

	const lines = order?.lines || [];
	const invoiceIds = _.groupBy(lines, "invoiceId");

	return (
		<Layout
			title="Fahrzeugverkauf"
			subtitle={order ? `Status ${saleOrderStatusLabels[order.status]}` : undefined}
			sidebar={(
				<SalesOrderSidebar
					currentSalesOrder={salesOrder}
					onChange={handleSalesChanged}
				/>
			)}
		>
			{order && sales && (
				<Box>
					<Grid container spacing={2} justifyContent="space-between">
						<Grid item>
							<TextBlock
								primary={<Plate plateNumber={sales.plateNumber} />}
								secondary={
									<InlineList>
										{sales.vin}
										{sales.vehicle.businessLine}
									</InlineList>
								}
							/>
						</Grid>
					</Grid>
					{order.status === "Cancelled" && (
						<Box marginY={4}>
							<Typography>Verkauf an</Typography>
							<SalesAddressInfo address={order.buyer} />
						</Box>
					)}
					<Box py={4}>
						<Grid container spacing={2}>
							<>
								{order.status === "Cancelled" && (
									<Grid item xs={12}>
										<Typography>
											Die Bestellung wurde abgebrochen
										</Typography>
									</Grid>
								)}
								{(order.status === "Invoiced" || order.status === "Paid")
									&& Object.keys(invoiceIds)
										.map(invoiceId => invoiceId)
										.map(invoiceId => (
											<Grid key={invoiceId} item md={12} lg={6} sx={{
												flexGrow: 1
											}}>
												<SalesInvoiceCard
													invoiceId={invoiceId !== "null" ? invoiceId : null}
													currentSalesOrder={salesOrder}
													onChange={handleSalesChanged}
												/>
											</Grid>
										))
								}
								{order.status === "Active" && (
									<Grid item md={12} lg={6} sx={{
										flexGrow: 1
									}}>
										<SalesOrderCard
											sale={sales}
											order={order}
											onSaleChanged={handleSalesChanged}
										/>
									</Grid>
								)}
								<Grid item md={12} lg={6} sx={{
									flexGrow: 1
								}}>
									<SalesDocumentCard
										sale={salesOrder?.sale}
										order={salesOrder?.order}
										onSaleChanged={handleSalesChanged}
									/>
								</Grid>
								{order.emails && (
									<Grid item md={12} lg={6} sx={{
										flexGrow: 1
									}}>
										<SaleEmailCard
											emails={order.emails}
											onSelect={handleSelectEmail}
										/>
									</Grid>
								)}
								{sales && sales.shipping && (
									<Grid item md={12} lg={6} sx={{
										flexGrow: 1
									}}>
										<ShippingCard
											sale={sales}
											shipping={sales.shipping}
										/>
									</Grid>
								)}
							</>
						</Grid>
					</Box>
				</Box>
			)}
		</Layout>
	);
};

export default SalesOrderPage;
