import { Box, List, ListItem, ListProps } from "@mui/material";
import { VehicleAssessment } from "../../system/Domain";
import { useAsync } from "react-use";
import axios from "axios";
import { AssessmentDamage } from "./Assessments/AssessmentPage";
import formatCurrency from "system/formatCurrency";
import Text from "Pages/VehicleSales/Text";

interface Props extends ListProps {
	assessment: VehicleAssessment
	onLoaded?: (damagesCount: number) => void
}

const AssessmentDamageListHigh = ({ assessment, onLoaded }: Props) => {
	const { value } = useAsync(async () => {
		const { data: damages } = await axios.get<AssessmentDamage[]>(`/api/assessments/${assessment.id}/damages`);
		if (onLoaded) {
			onLoaded(damages?.length);
		}
		return damages;
	}, [assessment]);

	const damages = value || [];
	const specialDamages = ['Windschutzscheibe', 'Frontscheibe', 'WSS', 'Glasschaden'];

	return (
		<List sx={{ fontSize: "0.7rem", paddingBottom: 0 }}>
			{damages.map(d => {
				const isSpecial = d.text && specialDamages.some(sd => d.text.toLowerCase().includes(sd.toLowerCase()));

				return (
					<ListItem key={d.text}>
						<Box>
							<Text
								value={d.text}
								color={isSpecial ? "warning" : null}
							/>
							<Text
								value={formatCurrency(d.repairCosts)}
							/>
						</Box>
					</ListItem>
				);
			})}
			{damages.length === 0 && (
				<ListItem>Keine Daten gefunden oder noch nicht geladen</ListItem>
			)}
		</List>
	)
};

export default AssessmentDamageListHigh;
