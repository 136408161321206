import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useAsync } from "react-use";
import { Import } from "system/Domain";
import formatDate from "system/formatDate";

interface LeaseplanImportInfo {
	plateNumber: string;
	vin: string;
	mileage: number;
	previousOwners: boolean;
	hadEngineReplacement: boolean;
	hadAccident: boolean;
	hadWindshieldDamage: boolean;
	unRegistrationDate: string;
}

export default function LeaseplanVehiclesView({ imp }: { imp: Import }) {
	const [error, setError] = useState<Error | null>(null);

	const { value: vehicles, loading } = useAsync(async () => {
		setError(null);
		try {
			const { data } = await axios.get<LeaseplanImportInfo[]>(`/api/imports/${imp.id}/leaseplan-vehicles`);
			return data;
		} catch (e) {
			setError(e);
		}
	}, [imp]);

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>Kennzeichen</TableCell>
					<TableCell>VIN</TableCell>
					<TableCell align="right">KM-Stand</TableCell>
					<TableCell align="right">Abmeldedatum</TableCell>
					<TableCell align="right">Anzahl Halter</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{loading && (
					<TableRow>
						<TableCell>
							<CircularProgress />
						</TableCell>
					</TableRow>
				)}

				{error && (
					<TableRow>
						<TableCell colSpan={5}>Die Importdaten sind im falschen Format oder fehlerhaft</TableCell>
					</TableRow>
				)}

				{vehicles?.map((v) => (
					<TableRow key={v.vin}>
						<TableCell>{v.plateNumber}</TableCell>
						<TableCell>{v.vin}</TableCell>
						<TableCell align="right">{v.mileage}</TableCell>
						<TableCell align="right">{formatDate(v.unRegistrationDate)}</TableCell>
						<TableCell align="right">{v.previousOwners}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
}
