import MenuItem from 'Components/Layout/MenuItem';
import {
	Assignment, Create, FileCopy, List, NoCrash, PermIdentity, PrecisionManufacturing, RemoveRoad
} from '@mui/icons-material';

import { UserRole } from '../../system/Domain';

export default () => {
	return (
		<>
			<MenuItem
				roles={[UserRole.AldUser, UserRole.LotManager]}
				icon={<List />}
				name="Transporte"
				path="/orders/transports"
				matchProps={{
					exact: true,
					path: [
						'/orders',
						'/orders/transports',
						'/orders/transports/new',
					]
				}}
				variant="secondary"
			/>
			<MenuItem
				roles={[UserRole.AldUser, UserRole.DamageAssessor]}
				icon={<NoCrash />}
				name="Gutachten"
				path="/orders/assessments"
				variant="secondary"
			/>
			<MenuItem
				roles={[UserRole.AldUser]}
				icon={<RemoveRoad />}
				name="Abmeldungen"
				path="/orders/unregistrations"
				variant="secondary"
			/>
			<MenuItem
				roles={[UserRole.AldUser]}
				icon={<Assignment />}
				name="Rückholungen"
				path="/orders/returns"
				variant="secondary"
			/>
			<MenuItem
				roles={[UserRole.AldUser]}
				icon={<FileCopy />}
				name="Beauftragungen"
				path="/orders/returns/sessions"
				variant="secondary"
			/>
			<MenuItem
				roles={[UserRole.AldUser]}
				icon={<Create />}
				name="Importe"
				path="/orders/imports"
				variant="secondary"
			/>
			<MenuItem
				roles={[UserRole.AldUser]}
				icon={<PermIdentity />}
				name="Kundenauftrag"
				path="/orders/customers"
				variant="secondary"
			/>
			<MenuItem
				roles={[UserRole.AldUser]}
				icon={<PrecisionManufacturing />}
				name="Werkstatt"
				path="/orders/repairs"
				variant="secondary"
			/>
		</>
	);
};
