import moment from "moment";
import dateFormats from "./dateFormats";

export default (date? : moment.MomentInput) : string => {
	if (!date) {
		return "";
	}

	return moment(date).format(dateFormats.dateTimeWithSeconds);
}
