import { Import, VehicleRegistrationDocumentsProjection } from "../../../system/Domain";
import { useHistory } from "react-router";
import { useAsync } from "react-use";
import axios from "axios";
import { Box, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import PaperTable from "../../../Components/PaperTable";
import Plate from "../../../Components/Plate/Plate";
import TextBlock from "../../../Components/Layout/TextBlock";
import VehicleRegistrationDocumentsStatusPill from "./VehicleRegistrationDocumentsStatusPill";
import ArrivalToggleButtonGroup from "./ArrivalToggleButtonGroup";
import UnregistrationStatusPill from "./UnregistrationStatusPill";

const VehicleRegistrationDocumentArrivalsImportView = ({ imp } : { imp : Import }) => {
	const history = useHistory();

	const { value: vehicles } = useAsync(async () => {
		const { data } = await axios.get<VehicleRegistrationDocumentsProjection[]>(`/api/vehicles/remarketing/registration-documents`, {
			params: {
				importId: imp.id,
				specialSort: "PlateNumberNumbers"
			}
		});
		return data;
	}, [imp]);

	return (
		<Box mt={2}>
			<PaperTable>
				<colgroup>
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
				</colgroup>
				<TableHead>
					<TableRow>
						<TableCell>
							Fahrzeug
						</TableCell>
						<TableCell>
							Status
						</TableCell>
						<TableCell>
							Abmeldung
						</TableCell>
						<TableCell>
							Angekommenes Dokument
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{vehicles && vehicles.map((v, i) => (
						<TableRow
							key={i}
							hover
							onClick={() => history.push(`/vehicles/${v.id}`)}
						>
							<TableCell>
								<Plate plateNumber={v.plateNumber} style={{ zoom: 0.6 }} />
								<TextBlock
									primary={v.makeModel}
									secondary={v.vin}
									compact
								/>
							</TableCell>
							<TableCell>
								<VehicleRegistrationDocumentsStatusPill status={v.registrationDocuments.status} />
							</TableCell>
							<TableCell>
								<UnregistrationStatusPill isUnregistered={v.isUnregistered} registrationDocuments={v.registrationDocuments} />
							</TableCell>
							<TableCell>
								<ArrivalToggleButtonGroup disable value={v.registrationDocuments.arrivalType} />
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</PaperTable>

		</Box>
	);
};

export default VehicleRegistrationDocumentArrivalsImportView;
