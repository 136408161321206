import { Box, Button } from "@mui/material";
import TextGroup from "Pages/VehicleSales/TextGroup";
import TextLine from "Pages/VehicleSales/TextLine";
import { useState } from "react";
import { VehicleType } from "../../system/Domain";
import formatNumber from "../../system/formatNumber";

interface Props {
	type: VehicleType
}

export default ({ type }: Props) => {
	const [showDetails, setShowDetails] = useState(false);

	return (
		<TextGroup title={(
			<Box display="flex" alignItems="baseline">
				<Box>Technische Daten</Box>
				<Box pl={1}>
					<Button
						sx={{
							fontSize: "0.6rem",
							minWidth: "auto",
							padding: 0.5,
							paddingLeft: 1,
							paddingRight: 1,
						}}
						onClick={() => setShowDetails(s => !s)}
						variant="text"
						color="primary"
						size="small">
						{!showDetails && "+"}
						{showDetails && "-"}
					</Button>
				</Box>
			</Box>
		)}>
			<TextLine label="Türen" value={type?.body.doors} />
			<TextLine label="Sitze" value={type?.body.seats} />
			<TextLine label="Reifen" value={type?.body.tires} />
			{showDetails && (
				<>
					<TextLine label="Radstand" value={type?.body.wheelBase} />
					<TextLine label="Größe" value={`${formatNumber(type?.body.size.width)} x ${formatNumber(type?.body.size.length)} x ${formatNumber(type?.body.size.height)}`} />
					<TextLine label="Eigengewicht" value={`${formatNumber(type?.body.weight.base)}kg`} />
					<TextLine label="Nutzlast" value={`${formatNumber(type?.body.weight.payload)}kg`} />
					<TextLine label="Gesamtgewicht" value={`${formatNumber(type?.body.weight.total)}kg`} />
				</>
			)}
		</TextGroup>
	);
}
