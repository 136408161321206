import { alpha, Box, Typography, useTheme } from "@mui/material";
import axios from "axios";
import InlineList from "Components/InlineList";
import SyncTileAction from "Components/Tiles/SyncTileAction";
import TileDivider from "Components/Tiles/TileDivider";
import moment from "moment";
import TextGroup from "Pages/VehicleSales/TextGroup";
import TextLine from "Pages/VehicleSales/TextLine";
import { Dispatch, SetStateAction } from "react";
import Tile from "../../Components/Tiles/Tile";
import TileContent from "../../Components/Tiles/TileContent";
import { ArmadaTicketMessage, ArmadaTicketUser, PricingRequest } from "../../system/Domain";
import formatDateTime from "../../system/formatDateTime";

interface Props {
	request: PricingRequest
	setRequest: Dispatch<SetStateAction<PricingRequest | undefined>>
}

interface MessageGroup {
	author: ArmadaTicketUser
	fromMe: boolean
	fromArmada: boolean
	messages: ArmadaTicketMessage[]
}

export default ({ request, setRequest }: Props) => {
	const theme = useTheme();
	const { armadaTicket } = request;

	if (!armadaTicket) {
		return null;
	}

	const groups: MessageGroup[] = [];
	const messages = [...armadaTicket.messages].reverse();

	let group: MessageGroup | null = null;
	for (const message of messages) {
		if (!group || group.author?.username !== message.creator?.username) {
			group = {
				author: message.creator,
				fromMe: message.creator?.username === "tu_carlos",
				fromArmada: message.creator?.applicationId === "ARMADA",
				messages: [],
			};

			groups.push(group);
		}

		group.messages.push(message);
	}

	const syncArmadaTicket = async () => {
		const { data } = await axios.post(`/api/pricing-requests/${request.id}/armada/sync`);
		setRequest(data);
	}

	return (
		<Tile title="Armada Ticket">
			<TileContent>
				<TextGroup>
					<TextLine label="Partner" value={armadaTicket.partner} />
					<TextLine label="Ticketnummer" value={armadaTicket.id.toString()} />
					<TextLine label="Status" value={armadaTicket.status} />
					<TextLine label="Erstellt am" value={formatDateTime(armadaTicket.dateCreated)} />
					<TextLine label="Letzte Änderung" value={formatDateTime(armadaTicket.dateUpdated)} />
				</TextGroup>

				<SyncTileAction reference={request.armadaTicket.sync} action={syncArmadaTicket} />

				{armadaTicket.messages?.length > 0 && (
					<>
						<TileDivider sx={{ mt: 2, mb: 3 }} />
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								gap: 2
							}}
						>
							{groups.map(g => (
								<Box
									key={`group_${g.messages[0].id}`}
									sx={{
										marginLeft: g.fromArmada ? 12.5 : 0,
										marginRight: g.fromArmada ? 0 : 12.5,
									}}
								>
									{g.messages.map((m, i) => (
										<Box
											key={m.id}
											sx={{
												backgroundColor: g.fromArmada
													? g.fromMe
														? alpha(theme.palette.info.light, 0.1)
														: alpha(theme.palette.info.light, 0.1)
													: alpha(theme.palette.primary.light, 0.1),
												padding: 1.5,
												borderRadius: "4px",
												borderBottomLeftRadius: i === g.messages.length - 1 ? "4px" : 0,
												borderBottomRightRadius: i === g.messages.length - 1 ? "4px" : 0,
												borderTopLeftRadius: i === 0 ? "4px" : 0,
												borderTopRightRadius: i === 0 ? "4px" : 0,
												marginTop: i > 0 ? "1px" : 0,
											}}
										>
											{i === 0 && (
												<Box mb={1}>
													<InlineList>
														<Typography variant="subtitle1" component="span">
															{m.creator?.fullName}
														</Typography>
														<Typography variant="caption" component="span">
															{m.creator?.applicationId}
														</Typography>
														{m.isIntern && (
															<Typography color="secondary" variant="caption" component="span">
																Intern
															</Typography>
														)}
														<Typography variant="caption" component="span">
															{formatDateTime(m.dateCreated)}
														</Typography>
														<Typography variant="caption" component="span">
															{moment(m.dateCreated).fromNow()}
														</Typography>
													</InlineList>
												</Box>
											)}
											<Box>
												<Typography
													variant="caption"
													component="div"
													sx={{
														"& hr": {
															display: "none"
														}
													}}
													dangerouslySetInnerHTML={{ __html: m.text }}
												/>
											</Box>
										</Box>
									))}
								</Box>
							))}
						</Box>
					</>
				)}
			</TileContent>
		</Tile >
	);
}
