import { Box, TableBody, TableHead, TableRow, Tooltip, Typography, useTheme } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Compound, CompoundReference, TransportOrder, UserRole, Vehicle } from "../../../system/Domain";
import OrderDateTooltip from "./TransportOrderDateTooltip";
import { Plate } from "../../../Components/Plate";
import TextBlock from "../../../Components/Layout/TextBlock";
import OrderStatus from "./TransportOrderStatus";
import PaperTable from "../../../Components/PaperTable";
import { Colgroup, TableCell } from "../../../Components/BreakpointStyledComponents";
import useUser from "system/useUser";
import { SyntheticEvent, useState } from "react";
import axios from "axios";
import { Link, LinkOff } from "@mui/icons-material";

export interface OrderTableProps {
	orders: TransportOrder[]
	isLoading?: boolean
	onOrderClick?: (o: TransportOrder) => void
	noRowsMessage?: string
	align?: boolean
	compounds?: Compound[];
	vehicle?: Vehicle
	reloadVehicle?: (() => Promise<void>) | undefined;
}

export default (props: OrderTableProps) => {
	const history = useHistory();
	const theme = useTheme();
	const [, , hasRole, , hasAnyRole] = useUser();
	const isAldAdmin = hasRole(UserRole.AldAdmin);
	const [isLinking, setIsLinking] = useState(false);

	const compoundPill = (compoundRef?: CompoundReference) => {
		const compound = props.compounds?.find( c => c.name === compoundRef?.name);
		if (!compound) {
			return <></>;
		}
		return (
			<Tooltip title={
				<Typography component="div">
					{compound.address.name}<br />
					{compound.address.zipCode} - {compound.address.city} - {compound.address.street}
				</Typography>}>
				<Box sx={{ width: "fit-content" }}>
					<TextBlock
						chip={{
							label: compound?.name,
							color: "default",
						}}
					/>
				</Box>
			</Tooltip>
		);
	}

	const linkTransportOrder = async (e: SyntheticEvent, transportOrderId: string) => {
		if (!props.vehicle || !transportOrderId || !props.reloadVehicle) {
			return;
		}

		e.stopPropagation();

		setIsLinking(true);
		try {
			await axios.put(`/api/vehicles/${props.vehicle.id}/remarketing/arrival/${transportOrderId}/link`);
		} finally {
			await props.reloadVehicle();
			setIsLinking(false);
		}
	};

	const unlinkTransportOrder = async (e: SyntheticEvent, transportOrderId: string) => {
		if (!props.vehicle || !transportOrderId || !props.reloadVehicle) {
			return;
		}

		e.stopPropagation();

		setIsLinking(true);
		try {
			await axios.put(`/api/orders/transports/${transportOrderId}/arrival/unlink`);
		} finally {
			await props.reloadVehicle();
			setIsLinking(false);
		}
	};

	return (
		<PaperTable>
			{props.align ?
				<TableHead>
					<TableRow>
						<TableCell xs sm>Auftrag</TableCell>
						<TableCell md lg xl sx={{width: {md: "30%", lg: "20%", xl: "20%"}}}>Fahrzeug</TableCell>
						<TableCell xl sx={{width:"10%"}}>Status</TableCell>
						<TableCell md lg xl sx={{width: {md: "25%", lg: "25%", xl: "20%"}}}>Logistiker</TableCell>
						<TableCell md lg xl sx={{width: {md: "25%", lg: "20%", xl: "15%"}}}>Abholung</TableCell>
						<TableCell md lg xl>Rückgabe</TableCell>
					</TableRow>
				</TableHead>
				:
				<>
					<Colgroup xl>
						<col style={{ width: "20%" }} />
						<col style={{ width: "20%" }} />
						<col style={{ width: "20%" }} />
						<col style={{ width: "20%" }} />
						<col style={{ width: "20%" }} />
					</Colgroup>
					<Colgroup md lg>
						<col style={{ width: "25%" }} />
						<col style={{ width: "25%" }} />
						<col style={{ width: "25%" }} />
						<col style={{ width: "25%" }} />
					</Colgroup>
					<TableHead>
						<TableRow>
							<TableCell xs sm>Auftrag</TableCell>
							<TableCell md lg xl>Fahrzeug</TableCell>
							<TableCell xl>Status</TableCell>
							<TableCell md lg xl>Logistiker</TableCell>
							<TableCell md lg xl>Abholung</TableCell>
							<TableCell md lg xl>Rückgabe</TableCell>
						</TableRow>
					</TableHead>
				</>}
			<TableBody>
				{!props.isLoading && props.orders && props.orders.map(o => {
					let pickupDate = "";
					let pickupTooltip = "Abholung noch nicht geplant";
					let returnDate: string = "";
					let isDelivery = o.source === "Stock";

					if (!o.pickupDate) {
						if (o.plannedPickupDate) {
							pickupDate = `Geplant zum ${moment(o.plannedPickupDate).format("L")}`;
							pickupTooltip = `Mit dem Kunden ist der ${moment(o.plannedPickupDate).format("L")} als Abholtermin vereinbart`;
						}
					} else {
						pickupDate = moment(o.pickupDate).format("L");
						pickupTooltip = `Das Fahrzeug wurde am ${moment(o.pickupDate).format("L")} beim Kunden abgeholt`
					}

					if (!o.returnDate) {
						if (o.expectedReturnDate) {
							returnDate = `Voraussichtlich ${moment(o.expectedReturnDate).format("L")}`;
						}
					} else {
						returnDate = moment(o.returnDate).format("L");
					}

					const dateAssigned = o.dateAssigned && moment(o.dateAssigned).format("L");
					const status = <OrderStatus order={o} />;

					const logisticCompany = <TextBlock
						primary={o.assignedTo?.name}
						secondary={dateAssigned ? `Zugewiesen am ${dateAssigned}` : "Noch nicht zugewiesen"}
					/>;

					const pickupAddress = <TextBlock
						primary={
							<OrderDateTooltip title={pickupTooltip} arrow>
								<div>{pickupDate || "Noch kein Termin geplant"}</div>
							</OrderDateTooltip>
						}
						secondary={(props.compounds && o.pickupCompound) ? 
							compoundPill(o.pickupCompound)
							:
							<Box color={isDelivery ? undefined : theme.palette.primary.light}>
								<div>{o.pickupAddress.name}</div>
								<div>{o.pickupAddress.street}</div>
								<div>{o.pickupAddress.zipCode} {o.pickupAddress.city}</div>
							</Box>}
					/>;

					const destinationAddress = <TextBlock
						primary={
							<OrderDateTooltip title={pickupTooltip} arrow>
								<div>{returnDate || "Noch kein Termin geplant"}</div>
							</OrderDateTooltip>
						}
						secondary={(props.compounds && o.destinationCompound) ?
							compoundPill(o.destinationCompound)
							:
							<Box color={isDelivery ? theme.palette.primary.light : undefined}>
								<div>{o.destinationAddress.name}</div>
								<div>{o.destinationAddress.street}</div>
								<div>{o.destinationAddress.zipCode} {o.destinationAddress.city}</div>
							</Box>}
					/>;

					return (
						<TableRow
							hover
							key={o.id}
							onClick={() => (props.onOrderClick ? props.onOrderClick(o) : history.push(`/orders/transports/${o.id}`))}
						>
							<TableCell>
							<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
							<Box>
								<div>
									<Plate plateNumber={o.plateNumber} style={{ zoom: 0.6 }} />
								</div>
								<TextBlock
									primary={!props.align && o.vehicle.type?.description}
									secondary={o.vin}
								/>
								<Box sx={{ display: { xs: "contents", xl: "none" } }}>
									<Box mt={2}>{status}</Box>
								</Box>
								<Box sx={{ display: { xs: "contents", md: "none" } }}>
									<Box mt={2}>{logisticCompany}</Box>
										</Box>

										<Box sx={{ display: { xs: "contents", md: "none" } }}>
											<Box mt={2}>{pickupAddress}</Box>
											<Box mt={2}>{destinationAddress}</Box>
										</Box>
									</Box>
									{props.align && (
										<Box>
											{isLinking ?
												<CircularProgress size={24} /> :
												(props.vehicle?.remarketing?.arrival?.orderId === o.id ?
													<Tooltip title={"Anlieferung abtrennen"} disableHoverListener={!isAldAdmin}>
														<Link onClick={(e) => isAldAdmin ? unlinkTransportOrder(e, o.id) : undefined} />
													</Tooltip>
													:
													<Tooltip title={"Anlieferung verknüpfen"} disableHoverListener={!isAldAdmin}>
														<LinkOff onClick={(e) => isAldAdmin ? linkTransportOrder(e, o.id) : undefined} />
													</Tooltip>
												)}
										</Box>
									)}
								</Box>
							</TableCell>
							<TableCell xl>{status}</TableCell>
							<TableCell md lg xl>{logisticCompany}</TableCell>
							<TableCell md lg xl>{pickupAddress}</TableCell>
							<TableCell md lg xl>{destinationAddress}</TableCell>
						</TableRow>
					)
				})}
				{props.isLoading === true && (
					<TableRow>
						<TableCell
							colSpan={9}
							sx={{ textAlign: "center" }}
						>
							<CircularProgress />
						</TableCell>
					</TableRow>
				)}
				{props.orders.length === 0 && !props.isLoading && (
					<TableRow>
						<TableCell
							colSpan={6}
							style={{ textAlign: "center" }}
						>
							{props.noRowsMessage}
						</TableCell>
					</TableRow>
				)}
			</TableBody>
		</PaperTable>
	);
};
