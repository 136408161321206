import { useEffect } from 'react';
import { Box, useTheme } from "@mui/material";
import { useNetworkState } from "react-use";
import moment from "moment/moment";
import useLog from "../../system/useLog";
import Typography from "@mui/material/Typography";
import { Warning } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import dateFormats from "../../system/dateFormats";

export default () => {
	const log = useLog();
	const history = useHistory();
	const networkState = useNetworkState();
	const theme = useTheme();

	useEffect(() => {
		if (!networkState.since || networkState.online === undefined) {
			return;
		}

		const timestamp = moment(networkState.since).format(dateFormats.timeWithSeconds);
		const description = [networkState.type, networkState.effectiveType].filter(e => !!e).join(", ") || "Unknown connection type";

		if (networkState.online) {
			log.info(`Network connection reestablished at ${timestamp} (${description})`);
		} else {
			log.info(`Network connection lost at ${timestamp} (${description})`);
		}

	}, [networkState.online]);

	if (!networkState.online) {
		return (
			<Box
				bgcolor={theme.palette.warning.light}
				padding={1}
				display="flex"
				alignItems="center"
				justifyContent="center"
				onClick={() => history.push("/network")}
			>
				<Box lineHeight="1em">
					<Warning />
				</Box>
				<Box pl={2}>
					<Typography color="textPrimary">Verbindung wurde getrennt</Typography>
				</Box>
			</Box>
		)
	}
	return null;
}
