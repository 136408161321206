import { useState } from "react";
import Layout from "../../../Components/Layout/Layout";
import CarmarketOverviewSidebar from "./CarmarketOverviewSidebar";
import { Box, Grid } from "@mui/material";
import { CarmarketSale, WorkSession } from "../../../system/Domain";
import useAxiosEffect from "../../../system/useAxiosEffect";
import axios, { AxiosRequestConfig } from "axios";
import moment from "moment";
import qs from "query-string";
import CarmarketSaleListItem from "../CarmarketSaleListItem";
import Tile from "../../../Components/Tiles/Tile";
import List from "../../../Components/List";
import ListItem from "../../../Components/ListItem";
import ListItemContent from "../../../Components/ListItemContent";
import useQuery, { IQuery } from "../../../system/useQuery";
import SalesNavigation from "Navigation/SalesNavigation";
import Search from "../../../Components/Search";
import WorkSessionListItem from "../WorkSessionListItem";
import { WorkSessionQuery } from "Pages/WorkSessions/WorkSessions";
import useEvent from "../../../system/useEvent";
import useChannels from "../../../system/useChannels";

export interface CarmarketOverviewQuery extends IQuery {
	date?: string;
	search?: string;
}

const CarmarketOverview = () => {
	const [carmarketSales, setCarmarketSales] = useState<CarmarketSale[]>([]);
	const [query, setQuery, , resetQuery] = useQuery<CarmarketOverviewQuery>("carmarketOverviewQuery", {
		search: "",
		skip: 0, // currently not used
		take: 20, // currently not used
	});

	const [isLoading, setIsLoading] = useState(false);

	useAxiosEffect(
		async (config) => {
			setIsLoading(true);

			try {
				const { data } = await axios.post<CarmarketSale[]>(
					`/api/carmarket/sales/search`,
					{
						since: query.search ? undefined : moment().subtract(14, "days").toISOString(),
						search: query.search,
					},
					config,
				);
				setCarmarketSales(data);
			} finally {
				setIsLoading(false);
			}
		},
		[query],
	);

	const fixedPriceAuctions = carmarketSales.filter((f) => f.type === "fixedPrice");
	const auctions = carmarketSales.filter((f) => f.type === "auction" || f.type === "tender");

	const [sessions, setSessions] = useState<WorkSession[]>([]);

	const loadSessions = async (axiosConfig: AxiosRequestConfig = {}) => {
		const sessionQuery: WorkSessionQuery = {
			sort: "dateCreated:desc",
			onlyOpen: true,
			onlyPublic: true,
			skip: 0,
			take: 10,
		};

		const { data: sessions } = await axios.get<WorkSession[]>(`/api/work-sessions`, {
			...axiosConfig,
			params: sessionQuery,
			paramsSerializer: (params) => qs.stringify(params),
		});

		setSessions(sessions);
	};

	useChannels(
		sessions.map((s) => s.id),
		[sessions],
	);

	useAxiosEffect(loadSessions, []);

	useEvent(
		"work-session:updated",
		(data) => {
			const { id } = data;

			if (!sessions || !id) {
				return;
			}

			if (!sessions.find((s) => s.id === id)) {
				loadSessions();
			}
		},
		[sessions],
	);

	useEvent(
		"work-session:closed",
		(data) => {
			const { id } = data;

			if (!sessions || !id) {
				return;
			}

			setSessions((sessions) => sessions.filter((s) => s.id !== id));
		},
		[sessions],
	);

	return (
		<Layout
			title="Carmarket Auktionen"
			navigation={<SalesNavigation />}
			sidebar={<CarmarketOverviewSidebar query={query} setQuery={setQuery} resetQuery={resetQuery} />}
		>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Box mb={3}>
						<Search
							value={query.search}
							realtime={false}
							loading={isLoading}
							onSearch={(v) =>
								v !== query.search &&
								setQuery((g) => ({
									...g,
									search: v,
									skip: 0,
								}))
							}
						/>
					</Box>
				</Grid>
			</Grid>
			<Grid container spacing={6}>
				<Grid item xs={12} sm={4}>
					<Tile title="Sofortkaufauktionen">
						{fixedPriceAuctions && (
							<List>
								{fixedPriceAuctions.map((sale, i) => (
									<CarmarketSaleListItem key={i} carmarketSale={sale} />
								))}
							</List>
						)}
						{fixedPriceAuctions?.length === 0 && (
							<ListItem>
								<ListItemContent>Keine</ListItemContent>
							</ListItem>
						)}
					</Tile>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Tile title="Auktionen">
						{auctions && (
							<List>
								{auctions.map((sale, i) => (
									<CarmarketSaleListItem key={i} carmarketSale={sale} />
								))}
							</List>
						)}
						{auctions?.length === 0 && (
							<ListItem>
								<ListItemContent>Keine</ListItemContent>
							</ListItem>
						)}
					</Tile>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Tile title="Aktive Sitzungen">
						<List>
							{sessions?.length > 0 &&
								sessions.map((s) => <WorkSessionListItem key={s.id} session={s} />)}
							{sessions?.length === 0 && (
								<ListItem>
									<ListItemContent>Keine Sitzungen vorhanden</ListItemContent>
								</ListItem>
							)}
						</List>
					</Tile>
				</Grid>
			</Grid>
		</Layout>
	);
};

export default CarmarketOverview;
