import { Vehicle } from "../../system/Domain";
import moment from "moment";
import dateFormats from "../../system/dateFormats";
import VehicleStatus from "./VehicleStatus";
import { Box } from "@mui/material";
import VinDisplay from "../../Components/VinDisplay";
import TextBlock from "Components/Layout/TextBlock";

export interface VehicleDescriptionProps {
	vehicle?: Vehicle;
	showVinWarning?: boolean
}

const VehicleDescription = ({ vehicle, showVinWarning = false }: VehicleDescriptionProps) => {
	if (!vehicle) {
		return null;
	}

	const descBusinessLine = (
		<Box sx={{ display: "inline-block" }}>
			<TextBlock chip={{ label: vehicle.businessLine }} />
		</Box>
	);

	const descVin = <VinDisplay vin={vehicle.vin} showVinWarning={showVinWarning} />;

	const descRegistered = !vehicle.history.currentStockDateRange?.from && (
		<span>Registriert am {moment(vehicle?.dateCreated).format(dateFormats.date)}</span>
	);

	const descIn = !!vehicle.history.currentStockDateRange?.from && (
		<span>Eingang am {moment(vehicle.history.currentStockDateRange?.from).format(dateFormats.date)}</span>
	);

	const descOut = !!vehicle.history.currentStockDateRange?.to ? (
		<span>Ausgang am {moment(vehicle.history.currentStockDateRange?.to).format(dateFormats.date)}</span>
	) : null;

	const entries = [descBusinessLine, descVin, descRegistered, descIn, descOut].filter((e) => !!e);

	return (
		<Box display="flex" alignItems="baseline" pt={0.25}>
			<VehicleStatus vehicle={vehicle} />
			<Box pl={1} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				{entries.map((e, i) => (
					<Box key={i} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
						{i > 0 && <Box sx={{ mx: 0.4 }}> · </Box>}
						{e}
					</Box>
				))}
			</Box>
		</Box>
	);
};

export default VehicleDescription;
