import { Dispatch, SetStateAction } from 'react';
import { ReturnOrderSession } from "../../../../system/Domain";
import SessionReturnOrdersView from "./SessionReturnOrdersView";
import SessionReturnOrdersEditView from "./SessionReturnOrdersEditView";

interface Checked {
	checkedOrdersIds: string[]
	areAllChecked: boolean
}

export default ({ session, checked, setChecked }: { session: ReturnOrderSession, checked: Checked, setChecked: Dispatch<SetStateAction<Checked>> }) => (
	<>
		{session.status === "Completed" && (
			<SessionReturnOrdersView session={session} />
		)}
		{session.status !== "Completed" && (
			<SessionReturnOrdersEditView
				{...{ session, checked, setChecked }}
			/>
		)}
	</>
)
