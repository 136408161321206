import { useCallback, useState } from "react";
import ThemedDialog from "../../Components/ThemedDialog";
import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";
import { alpha, Box, Button, DialogActions, DialogContent, DialogTitle, Typography, useTheme } from "@mui/material";
import { useDropzone } from "react-dropzone";
import executeWithProgressDialog from "../../Dialogs/executeWithProgressDialog";
import axios from "axios";
import parseContentDispositionFilename from "system/parseContentDispositionFilename";
import fileDownload from "js-file-download";
import uploadImage from "../../cloud-upload.png";

interface Props extends ReactConfirmProps<any> {
	show: boolean;
}

const UploadPostmanDialog = (props: Props) => {
	const theme = useTheme();
	const color = theme.palette.primary.main;
	const styles = {
		root: {
			position: "relative",
			border: "2px dashed",
			borderColor: alpha(color, 0.12),
			borderRadius: 4,
			width: "100%",
			height: 150,
			cursor: "pointer",
			color: alpha(color, 0.3),
			"&:hover": {
				borderColor: alpha(color, 0.3),
				color: alpha(color, 0.6),
			},
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			textAlign: "center",
			fontSize: "0.75rem",
			transition: "border 200ms ease-out, color 200ms ease-out",
			outline: "none",
			lineHeight: "1.9em",
			overflow: "hidden",
		},
		error: {
			borderColor: alpha(theme.palette.error.dark, 0.75),
			color: theme.palette.error.dark,
			"&:hover": {
				borderColor: alpha(theme.palette.error.dark, 0.75),
				color: theme.palette.error.dark,
			},
		},
		ui: {
			padding: 2,
		},
	};
	const [result, setResult] = useState<string>();
	const [error, setError] = useState<string>();

	const handleClose = () => {
		props.cancel();
	};

	const onDrop = useCallback(async (files: File[]) => {
		await executeWithProgressDialog(async (progress) => {
			let formData = new FormData();
			formData.append("file", files[0]);

			try {
				const response = await axios.post<string>(`/api/documentation/postman-merge?output=json`, formData, {
					responseType: "blob",
					headers: {
						"Content-Type": "multipart/form-data",
					},
					onUploadProgress: (event) => {
						const completed = Math.ceil((event.loaded / event.total) * 100);
						progress(completed);
					},
				});

				const fileName = parseContentDispositionFilename(response.headers["content-disposition"]);
				await fileDownload(response.data, fileName);
				setResult(`Als ${fileName} gespeichert`);
			} catch (e) {
				setError("Fehler: " + ((await e.response?.data.text()) ?? e.message));
			}
		});
	}, []);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		multiple: false,
		noKeyboard: true,
		noDragEventsBubbling: true,
	});

	return (
		<ThemedDialog open={props.show} maxWidth="sm" fullWidth onClose={handleClose}>
			<DialogTitle>Postman Kollektion</DialogTitle>
			<DialogContent>
				{!result && !error && (
					<Box {...getRootProps({ outline: "none" })} sx={styles.root}>
						<input {...getInputProps()} />
						<img src={uploadImage} alt="upload" />
						<Typography gutterBottom>Dateien per Drag and Drop hierher verschieben</Typography>
					</Box>
				)}
				{(result || error) && (
					<Box mx={2}>
						{error && <Box sx={styles.error}>{error}</Box>}
						{result && <Box sx={styles.ui}>{result}</Box>}
					</Box>
				)}
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="primary" href="" onClick={() => props.proceed({})}>
					Schließen
				</Button>
			</DialogActions>
		</ThemedDialog>
	);
};

export default () => {
	const dialog = confirmable((props) => <UploadPostmanDialog {...props} />);
	return createConfirmation(dialog)();
};
