import { Box, Typography, useTheme } from "@mui/material";
import { BillingPropsWithSetter } from "./useBilling";
import BillingInvoiceTable from "./BillingInvoiceTable";
import BillingServiceTable from "./BillingServiceTable";
import formatCurrency from "../../system/formatCurrency";
import useBillingPermissions from "./useBillingPermissions";
import { BillingClient, BillingInvoice, BillingServices } from "../../system/Domain";

export interface BillingClientProps extends BillingPropsWithSetter {
	client?: BillingClient
	services: BillingServices
	invoices: BillingInvoice[]
}

export default ({ billing, setBilling, client, services, invoices }: BillingClientProps) => {
	const theme = useTheme();
	const permissions = useBillingPermissions();

	const totalNetAmount = invoices
		.map(i => i.netAmount)
		.reduce((total, netAmount) => (total ?? 0) + (netAmount ?? 0), 0);

	return (
		<>
			<Box>
				<Typography variant="h5">
					{!client && (
						<Box sx={{
							opacity: 0.5,
							paddingRight: theme.spacing(1),
							display: "inline"
						}}>Alle Mandanten</Box>
					)}
					{client && (
						<>
							<Box sx={{
								opacity: 0.5,
								paddingRight: theme.spacing(1),
								display: "inline"
							}}>Mandant</Box>
							<span>{client.name}</span>
						</>
					)}
				</Typography>
				{!!totalNetAmount && (
					<Box mt={0.5}>
						<Typography>{formatCurrency(totalNetAmount)}</Typography>
					</Box>
				)}
			</Box>
			{permissions.canViewInvoices(billing) && (
				<Box mt={2}>
					{invoices.length > 0 && (
						<BillingInvoiceTable
							billing={billing}
							setBilling={setBilling}
							invoices={invoices}
							showClient={!client}
						/>
					)}
					{invoices.length === 0 && !client && (
						<Typography variant="body1">Noch keine Rechnungen hochgeladen</Typography>
					)}
				</Box>
			)}
			<Box mt={2}>
				<BillingServiceTable services={services} />
			</Box>
		</>
	);
}
