import { atom } from "recoil";
import { UserSettings } from "system/Domain";
import localStorageEffect from "./effects/localStorageEffect";

const defaultSettings: UserSettings = {
	menuOpen: true,
	theme: "dark",
	navigationOrientation: "vertical",
};

const settingsAtom = atom<UserSettings>({
	key: "settings",
	default: defaultSettings,
	effects: [localStorageEffect("settings", defaultSettings)],
});

export { settingsAtom };
