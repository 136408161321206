import { useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { Inventory, Vehicle } from "../../system/Domain";
import useAsyncEffect from "../../system/useAsyncEffect";
import axios from "axios";
import InventoryVehicleList from "./InventoryVehicleTable";
import InventoryHeader from "./InventoryHeader";
import InventoryScanContextMenu from "./InventoryScanContextMenu";
import Layout from "../../Components/Layout/Layout";
import useInventory from "../../system/useInventory";
import { useParams } from "react-router";
import InventorySidebar from "./InventorySidebar";
import useVehicleQuery, { VehicleQuery } from "../../system/useVehicleQuery";
import Search from "../../Components/Search";
import InventoryTitle from "./InventoryTitle";
import VehiclesNavigation from "Navigation/VehiclesNavigation";

export interface InventoryProps {
	inventory: Inventory
}

export default () => {
	const { id } = useParams<{ id: string }>();
	const [inventory, setInventory] = useInventory(id);

	const [query, setQuery, place, setPlace, resetQuery] = useVehicleQuery('inventoryVehicleQuery', {
		perspective: "InventoryDone",
		sort: "inventoryDate:desc",
		fullText: ""
	});

	const [vehicles, setVehicles] = useState<Vehicle[]>();

	useAsyncEffect(async (cancelToken) => {
		if (!inventory) {
			return;
		}

		const inventoryQuery: VehicleQuery = {
			...query,
			inventoryId: inventory.id
		};

		let vehicles: Vehicle[];
		if (query.fullText) {
			const { data } = await axios.post<Vehicle[]>(`/api/vehicles/search`, inventoryQuery, {
				cancelToken: cancelToken.getAxiosToken()
			});

			vehicles = data;
		} else {
			const { data } = await axios.get<Vehicle[]>(`/api/vehicles`, {
				params: inventoryQuery,
				cancelToken: cancelToken.getAxiosToken()
			});

			vehicles = data;
		}

		setVehicles(vehicles);
	}, [inventory, query]);

	return (
		<Layout
			title={inventory && <InventoryTitle inventory={inventory} />}
			navigation={<VehiclesNavigation />}
			contextMenu={inventory && [
				<InventoryScanContextMenu inventory={inventory} />
			]}
			sidebar={inventory && (
				<InventorySidebar
					query={query}
					setQuery={setQuery}
					place={place}
					setPlace={setPlace}
					resetQuery={resetQuery}
					inventory={inventory}
					setInventory={setInventory}
				/>
			)}
		>
			{!inventory && (
				<CircularProgress size={48} />
			)}
			{inventory && (
				<>
					<InventoryHeader inventory={inventory} />
					<Box display="flex" mb={2}>
						<Search
							value={query.fullText}
							realtime
							onSearch={(value) => value !== query.fullText && setQuery(q => ({
								...q,
								fullText: value
							}))}
						/>
					</Box>
					{!vehicles && (
						<CircularProgress size={48} />
					)}
					{!!vehicles && (
						<>
							{vehicles.length === 0 && (
								<Box>Keine Fahrzeuge vorhanden</Box>
							)}
							{vehicles.length > 0 && (
								<InventoryVehicleList
									inventory={inventory}
									vehicles={vehicles}
								/>
							)}
						</>
					)}
				</>
			)}
		</Layout>
	);
};
