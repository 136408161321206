import axios from "axios";
import { useRecoilState } from "recoil";
import { logoutAtom } from "./atoms/logout";
import { userAtom } from "./atoms/user";
import { User, UserRole } from "./Domain";
import store from "./store";

export default () => {
	const [user, _setUser] = useRecoilState<User>(userAtom);
	const [, setLogout] = useRecoilState<boolean>(logoutAtom);

	const hasRole = (role: UserRole) => user?.roles.some((r) => r.includes(role)) ?? false;
	const hasRoles = (roles: UserRole[]) => roles.every(hasRole);
	const hasAnyRole = (roles: UserRole[]) => roles.some(hasRole);

	const saveUser = (user: User) => {
		if (!user) {
			sessionStorage.removeItem("user");
			return;
		}

		sessionStorage["user"] = JSON.stringify(user);
	};

	const _logout = () => {
		axios.defaults.headers.common["Authorization"] = undefined;

		saveUser(null);
		_setUser(null);

		setLogout(true);
	};

	const setUser = (newUser: User | null) => {
		if (newUser) {
			axios.defaults.headers.common["Authorization"] = "Bearer " + newUser.loginToken!.token;
			axios.interceptors.response.use(
				(response) => {
					const newAccessToken = response.headers["access-token"];
					if (Boolean(newAccessToken)) {
						axios.defaults.headers.common["Authorization"] = `Bearer ${newAccessToken}`;
						newUser.loginToken!.token = newAccessToken;
						setUser(newUser);
					}
					return response;
				},
				(error) => {
					if (error.response && error.response.status === 401) {
						// noinspection JSIgnoredPromiseFromCall
						_logout();
						return;
					}
					store.dispatch({ type: "SET_ERROR", error });
					return Promise.reject(error);
				},
			);

			saveUser(newUser);
			_setUser(newUser);
		} else {
			_logout();
		}
	};

	const loadUser = () => {
		const json = sessionStorage["user"];
		if (!!json) {
			const user = JSON.parse(json);
			if (!!user) {
				setUser(user);
			}
		}
	};

	if (!user) {
		loadUser();
	}

	return [user, setUser, hasRole, hasRoles, hasAnyRole] as const;
};
