import axios from "axios";
import parseContentDispositionFilename from "../../system/parseContentDispositionFilename";
import fileDownload from "js-file-download";

export const downloadApiAsJson = async () => {
	const response = await getApiAsJson(true);
	const fileName = parseContentDispositionFilename(response.headers["content-disposition"]);

	fileDownload(response.data, fileName);

	return fileName;
};

export const getApiAsJson = async (blob: boolean) => {
	const response = await axios.get(`/api/documentation/postman?output=json`, {
		responseType: blob ? "blob" : 'json',
		params: {
			output: "json"
		}
	});

	return response;
};
