import { Money, PermIdentity } from "@mui/icons-material";
import { PricingRequest, UserRole } from "system/Domain";
import LayoutNavigationItem from "../Components/Layout/LayoutNavigationItem";
import LayoutNavigationSection from "../Components/Layout/LayoutNavigationSection";

interface Props {
	request: PricingRequest;
}

export default ({ request }: Props) => {
	return (
		<LayoutNavigationSection title={`Kaufpreisanfrage ${request.plateNumber}`}>
			<LayoutNavigationItem
				roles={[UserRole.AldUser]}
				icon={<Money />}
				title="Kaufpreisanfrage"
				path={`/sales/pricing-requests/${request.id}`}
			/>

			{!!request.customerOrderId && (
				<LayoutNavigationItem
					roles={[UserRole.AldUser]}
					icon={<PermIdentity />}
					title="Kundenauftrag"
					path={`/orders/customers/${request.customerOrderId}`}
				/>
			)}
		</LayoutNavigationSection>
	);
};
