import { Grid } from "@mui/material";
import TextBlock from "../../../Components/Layout/TextBlock";
import PaperBox from "../../../Components/PaperBox";
import formatDateTime from "../../../system/formatDateTime";
import { JobProps } from "./JobLayout";
import { formatJobType } from "./JobsSidebar";
import JobStatus from "./JobStatus";

export default ({ job }: JobProps) => {
	return (
		<PaperBox>
			<Grid container spacing={3}>
				<Grid item xs={6} md={3}>
					<JobStatus job={job} />
				</Grid>
				<Grid item xs={6} md={3}>
					<TextBlock primary={formatJobType(job.type)} secondary="Typ" compact />
				</Grid>
				<Grid item xs={6} md={3}>
					<TextBlock primary={job.method} secondary="Methode" compact />
				</Grid>
				<Grid item xs={6} md={3}>
					<TextBlock primary={formatDateTime(job.dateCreated)} secondary="Erstellt am" compact />
				</Grid>
			</Grid>
		</PaperBox>
	);
};
