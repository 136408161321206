import axios from 'axios';
import moment from 'moment';
import { useState } from 'react';
import { useNetworkState } from 'react-use';

import { AccountCircle, Wifi, WifiOff } from '@mui/icons-material';
import { Box, CircularProgress, TableBody, TableCell, TableRow } from '@mui/material';

import ActionButton from '../../Components/ActionButton';
import Layout from '../../Components/Layout/Layout';
import Table from '../../Components/Table';
import Tile from '../../Components/Tiles/Tile';
import TileActions from '../../Components/Tiles/TileActions';
import TileContent from '../../Components/Tiles/TileContent';
import dateFormats from '../../system/dateFormats';
import { Company, UserRole } from '../../system/Domain';

interface WhoAmI {
	id : string,
	name : string
	email : string
	roles : UserRole[]
	company : Company
}

const NetworkOverview = () => {
	const networkState = useNetworkState();
	const [user, setUser] = useState<WhoAmI>();
	const [isLoading, setIsLoading] = useState(false);

	const query = async () => {
		try {
			setIsLoading(true);
			setUser(undefined);

			const { data: user } = await axios.get<WhoAmI>("/api/users/whoami");

			setUser(user);
		} finally {
			setIsLoading(false);
		}
	};

	const handleRefresh = () => {
		window.location.reload();
	}

	return (
		<Layout title="Verbindung">
			<Box maxWidth="400px">
				<Tile>
					<TileContent>
						<Box display="flex" my={4}>
							<Box m="auto">
								{networkState.online && (
									<Wifi style={{ fontSize: 60 }} />
								)}
								{!networkState.online && (
									<WifiOff style={{ fontSize: 60 }} />
								)}
							</Box>
						</Box>
						<Table>
							<colgroup>
								<col width="auto" />
								<col width="auto" />
							</colgroup>
							<TableBody>
								{Object.entries(networkState).map(([key, value]) => (
									<TableRow key={key}>
										<TableCell>{key}</TableCell>
										<TableCell>
											{key === "since" && (
												<>{moment(value).format(dateFormats.dateTime)}</>
											)}

											{key !== "since" && (
												<>{value?.toString()}</>
											)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						{user && (
							<>
								<Box display="flex" my={2}>
									<Box m="auto">
										<AccountCircle style={{ fontSize: 60 }} />
									</Box>
								</Box>
								<UserTable user={user} />
							</>
						)}
						{isLoading && (
							<Box display="flex" my={2}>
								<Box m="auto">
									<CircularProgress size={48} />
								</Box>
							</Box>
						)}
					</TileContent>
					<TileActions justifyContent="space-between">
						<ActionButton
							variant="outlined"
							color="secondary"
							onClick={query}
						>
							Nutzerdaten laden
						</ActionButton>
						<ActionButton
							variant="outlined"
							color="primary"
							onClick={handleRefresh}
						>
							Neu Laden
						</ActionButton>
					</TileActions>
				</Tile>
			</Box>
		</Layout>
	);
};

const UserTable = ({ user } : { user : WhoAmI }) => {
	return (
		<Table>
			<colgroup>
				<col width="auto" />
				<col width="auto" />
			</colgroup>
			<TableBody>
				<TableRow>
					<TableCell>id</TableCell>
					<TableCell>{user.id}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>name</TableCell>
					<TableCell>{user.name}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>email</TableCell>
					<TableCell>{user.email}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>roles</TableCell>
					<TableCell>{user.roles.join(', ')}</TableCell>
				</TableRow>
				<TableRow>
					<TableCell>company</TableCell>
					<TableCell>{user.company.name}</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	);
};

export default NetworkOverview;
