import { Box, CircularProgress } from "@mui/material";
import axios from "axios";
import List from "Components/List";
import SalesNavigation from "Navigation/SalesNavigation";
import { useState } from "react";
import useUser from "system/useUser";
import FetchNextButton from "../../Components/FetchNextButton";
import Layout from "../../Components/Layout/Layout";
import Search from "../../Components/Search";
import { PricingRequest, PricingRequestStatus, UserRole } from "../../system/Domain";
import useAsyncEffect from "../../system/useAsyncEffect";
import useQuery, { IQuery } from "../../system/useQuery";
import PricingRequestListItem from "./PricingRequestListItem";
import PricingRequestsKpis from "./PricingRequestsKpis";
import PricingRequestsSidebar from "./PricingRequestsSidebar";

export interface PricingRequestQuery extends IQuery {
	status?: PricingRequestStatus
	onlyArmada?: boolean
	sort: string
	search?: string
}

export default () => {
	const take = 20;
	const [query, setQuery, fetchNext, resetQuery, reload] = useQuery<PricingRequestQuery>('vehiclePricingRequestQuery', {
		sort: "dateCreated:desc",
		skip: 0,
		take: take
	});
	const [isFetching, setIsFetching] = useState(false);

	const [requests, setRequests] = useState<PricingRequest[]>([]);

	useAsyncEffect(async (cancelToken) => {
		setIsFetching(true);

		try {
			const { data: requests } = await axios.get<PricingRequest[]>(`/api/pricing-requests`, {
				params: query,
				cancelToken: cancelToken.getAxiosToken()
			});

			if (query.skip > 0) {
				setRequests(r => [
					...r,
					...requests
				]);
			} else {
				setRequests(requests);
			}
		} finally {
			setIsFetching(false);
		}
	}, [query]);

	const [, , , , hasAnyRole] = useUser();
	const isSalesManagerOrAdminOrBackoffice = hasAnyRole([UserRole.SalesManager, UserRole.SalesAdmin, UserRole.SalesBackoffice]);

	return (
		<Layout
			title="Kaufpreisanfragen"
			navigation={<SalesNavigation />}
			sidebar={
				<PricingRequestsSidebar
					query={query}
					setQuery={setQuery}
					resetQuery={resetQuery}
					reload={reload}
				/>
			}
		>
			{!requests && (
				<CircularProgress size={48} />
			)}
			{requests && (
				<Box sx={{ display: "flex", flexDirection: "row", gap: 6 }}>
					<Box sx={{ flexGrow: 1 }}>
						<Box mb={2}>
							<Search
								value={query.search}
								realtime={false}
								onSearch={(value) => value !== query.search && setQuery(g => ({
									...g,
									skip: 0,
									search: value
								}))}
							/>
						</Box>
						<List>
							{requests.map(r => (
								<PricingRequestListItem key={r.id} request={r} />
							))}
						</List>
						<FetchNextButton
							hidden={requests.length < take + query.skip}
							my={3}
							onNext={fetchNext}
							disabled={isFetching}
						/>
					</Box>
					{isSalesManagerOrAdminOrBackoffice && (
						<PricingRequestsKpis />
					)}
				</Box>
			)}
		</Layout>
	);
}
