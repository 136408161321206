import { Box, TableBody, TableHead, TableRow } from "@mui/material";
import { Inventory } from "../../system/Domain";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router-dom";
import TextBlock from "../../Components/Layout/TextBlock";
import InventoryStatus from "./InventoryStatus";
import InventoryProgress from "./InventoryProgress";
import InventoryTitle from "./InventoryTitle";
import PaperTable from "../../Components/PaperTable";
import { Colgroup, TableCell } from "../../Components/BreakpointStyledComponents";

export interface InventoryTableProps {
	inventories?: Inventory[]
	isLoading?: boolean
	onClick?: (inventory: Inventory) => void
}

export default ({ inventories = [], isLoading, onClick }: InventoryTableProps) => {
	const history = useHistory();

	return (
		<PaperTable>
			<Colgroup sm md lg xl>
				<col style={{ width: "35%" }} />
				<col style={{ width: "30%" }} />
				<col style={{ width: "35%" }} />
			</Colgroup>
			<TableHead>
				<TableRow>
					<TableCell xs>Inventur</TableCell>
					<TableCell sm md lg xl>Bezeichnung</TableCell>
					<TableCell sm md lg xl>Status</TableCell>
					<TableCell sm md lg xl>Fortschritt</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{inventories.map((i: Inventory) => {
					const status = <InventoryStatus inventory={i} />
					const progress = <InventoryProgress inventory={i} />

					return (
						<TableRow
							hover
							key={i.id}
							onClick={() => {
								if (onClick) {
									onClick(i);
								} else {
									history.push({
										pathname: `/inventories/${i.id}`
									});
								}
							}}
						>
							<TableCell>
								<TextBlock
									primary={<InventoryTitle inventory={i} />}
									secondary={`Erstellt von ${i.createdBy.name}`}
								/>
								<Box sx={{ display: { xs: "contents", sm: "none" } }}>
									<Box mt={3}>{status}</Box>
									<Box mt={3}>{progress}</Box>
								</Box>
							</TableCell>
							<TableCell sm md lg xl>{status}</TableCell>
							<TableCell sm md lg xl>{progress}</TableCell>
						</TableRow>
					)
				})}
				{isLoading === true && (
					<TableRow>
						<TableCell
							colSpan={2}
							style={{ textAlign: "center" }}
						>
							<CircularProgress />
						</TableCell>
					</TableRow>
				)}
			</TableBody>
		</PaperTable>
	);
};
