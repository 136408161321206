import { Box, Grid } from "@mui/material";
import { Form } from "../../../system/useForm";
import FormText from "../../../Components/FormText";

interface Props {
	form: Form
}

const StepPricingRequestFinalRemarks = (props: Props) => {

	return (
		<Box mt={2}>
			<Box my={2}>
				Wir benachrichtigen Sie optional per Email wenn ein Preis angegeben wird. Tragen Sie dafür hier ihre Email Adresse ein.
				<br />
				<br />
				Damit akzeptieren Sie, dass wir Ihnen eine Email schicken, sobald ihre Kaufpreisanfrage bearbeitet wurde.
			</Box>

			<Grid container spacing={4}>
				<FormText
					form={props.form}
					name="customerEmail"
					label="E-Mail"
					options={{
						required: false
					}}
				/>
			</Grid>

			<Box mt={2}>
				Mit dem Absenden der Daten bestätigen Sie die Richtigkeit Ihrer Angaben.
			</Box>
		</Box>
	);
}

export default StepPricingRequestFinalRemarks;
