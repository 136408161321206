import { PictureAsPdf } from "@mui/icons-material";
import ListItemChip from "Components/ListItemChip";
import axios from "axios";
import fileDownload from "js-file-download";
import { RepairOrder } from "system/Domain";
import parseContentDispositionFilename from "system/parseContentDispositionFilename";

export interface Props {
	repairOrder: RepairOrder
}

export default ({ repairOrder }: Props) => {
	const onDownloadRepairOrder = async () => {
		const response = await axios.get(`/api/orders/repairs/${repairOrder.id}/order.pdf`, {
			responseType: "blob"
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);
	};

	return (
		<ListItemChip
			icon={<PictureAsPdf />}
			label="Auftrag"
			color="warning"
			onClick={onDownloadRepairOrder}
		/>
	);
};
