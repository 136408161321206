import { RemarketingStopReason } from "../../system/Domain";

function formatRemarketingStopReason(reason: RemarketingStopReason) {
	switch (reason) {
		case "Other":
			return "Andere";
		case "Carglass":
			return "Carglass";
		case "ReturnOrderSalesComment":
			return "Verkaufskommentar Rückholauftrag";
		case "VehicleAssessmentRejection":
			return "Gutachten abgelehnt";
		case "IncorrectLeasmanData":
			return "Fehlerhafte Leasmandaten";
		case "PictureSetQuality":
			return "Fotosatz abgelehnt";
		case "ExistingLeasmanSale":
			return "Verkauf in Leasman bereits angelegt";
		default:
			return reason;
	}
}

export default formatRemarketingStopReason;
