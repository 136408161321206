import { Box, Link, Typography } from "@mui/material";
import ActionButton from "Components/ActionButton";
import Actions from "Components/Actions";
import { formatYesNo } from "Components/LeasmanContract";
import Tile from "Components/Tiles/Tile";
import TileContent from "Components/Tiles/TileContent";
import TextGroup from "Pages/VehicleSales/TextGroup";
import TextLine from "Pages/VehicleSales/TextLine";
import { useMemo } from "react";
import { useHistory } from "react-router";
import { PoolFleetContract, PoolFleetContractStatus } from "system/Domain";
import formatCurrency from "system/formatCurrency";
import formatDate from "system/formatDate";
import formatNumber from "system/formatNumber";
import poolFleetContractStatus from "./poolFleetContractStatus";

interface Props {
	contract: PoolFleetContract
	onCancel?: () => Promise<void>
	onOrderTransport?: () => Promise<void>
	disabled?: boolean
}

function formatStatusColor(status: PoolFleetContractStatus) {
	switch (status) {
		case "ACTIVE":
			return "success"
		default:
			return "warning";
	}
}

export default function PoolFleetContractTile({ contract, onCancel, onOrderTransport, disabled }: Props) {
	const history = useHistory();

	const handleCancel = useMemo(() => {
		if (onCancel && !disabled && contract.status === "ORDER") {
			return onCancel;
		}
	}, [disabled, onCancel, contract]);

	const handleCreateTransport = useMemo(() => {
		if (!disabled && onOrderTransport) {
			if (contract.status === "ACTIVE" && !contract.pickupOrderId) {
				return onOrderTransport;
			} else if (contract.status === "ORDER" && !contract.deliveryOrderId) {
				return onOrderTransport;
			}
		}
	}, [disabled, onOrderTransport, contract]);

	function handleGotoOrder(orderId: string | null) {
		if (orderId) {
			return () => history.push(`/orders/transports/${orderId}`);
		}
	}

	return (
		<Box>
			<Typography gutterBottom variant="h5">Aktueller Vertrag</Typography>
			<Tile>
				<TileContent>
					<TextGroup title="Allgemein">
						<TextLine
							label="Vertragsnummer"
							value={contract.contractId}
						/>
						<TextLine
							label="Status"
							color={formatStatusColor(contract.status)}
							value={poolFleetContractStatus[contract.status]}
						/>
						<TextLine
							label="Vertragsdauer"
							value={formatNumber(contract.duration)}
						/>
						<TextLine
							label="Kilometerleistung"
							value={formatNumber(contract.mileage)}
						/>
						<TextLine
							label="Rate"
							value={formatCurrency(contract.rate)}
						/>
						<TextLine
							label="Mit Transport"
							value={formatYesNo(contract.includeDelivery)}
						/>
						{contract.deliveryAddress && (
							<TextGroup title="Lieferadresse">
								<TextLine
									label="Name"
									value={contract.deliveryAddress.name}
								/>
								<TextLine
									label="Strasse"
									value={contract.deliveryAddress.street}
								/>
								<TextLine
									label="Ort"
									value={`${contract.deliveryAddress.zipCode} ${contract.deliveryAddress.city}`}
								/>
							</TextGroup>
						)}
						{contract.deliveryContact && (
							<TextGroup title="Kontakt">
								<TextLine
									label="Name"
									value={contract.deliveryContact.name}
								/>
								<TextLine
									label="Email"
									value={contract.deliveryContact.email}
								/>
								<TextLine
									label="Telefon"
									value={contract.deliveryContact.telephone}
								/>
							</TextGroup>
						)}
						{contract.status === "ACTIVE" && (
							<>
								<TextLine
									label="Vertragsbeginn"
									value={formatDate(contract.startDate)}
								/>
								<TextLine
									label="Vertragsende"
									value={formatDate(contract.endDate)}
								/>
							</>
						)}
					</TextGroup>
					<TextGroup title="Kunde">
						<TextLine
							label="Adressnummer"
							value={contract.customer.id}
						/>
						<TextLine
							label="name"
							value={(
								<Box>
									<Box>{contract.customer.name1}</Box>
									<Box>{contract.customer.name2}</Box>
								</Box>
							)}
						/>
						<TextLine
							label="Strasse"
							value={contract.customer.street}
						/>
						<TextLine
							label="Ort"
							value={(
								<Box>
									{contract.customer.zipCode}
									<span> </span>
									{contract.customer.city}
								</Box>
							)}
						/>
						<TextLine
							label="Telefon"
							value={contract.customer.telefone}
						/>
						<TextLine
							label="Mobil"
							value={contract.customer.mobile}
						/>
						<TextLine
							label="Email"
							value={contract.customer.email}
						/>
					</TextGroup>
					<TextGroup
						title="Auslieferung"
					>
						<TextLine
							label="Wunschliefertermin"
							value={formatDate(contract.desiredDeliveryDate)}
						/>
						<TextLine
							label="Transportauftrag"
							value={(
								<Link href="#" onClick={handleGotoOrder(contract.deliveryOrderId)}>{contract.deliveryOrderId}</Link>
							)}
						/>
						<TextLine
							label="Abholauftrag"
							value={(
								<Link href="#" onClick={handleGotoOrder(contract.pickupOrderId)}>{contract.pickupOrderId}</Link>
							)}
						/>
					</TextGroup>
					<Box mt={2}>
						<Actions>
							<ActionButton
								color="secondary"
								variant="text"
								size="small"
								disabled={!Boolean(handleCancel) || disabled}
								onClick={handleCancel}
							>
								Stornieren
							</ActionButton>
							{handleCreateTransport && (
								<ActionButton
									color="primary"
									variant="text"
									size="small"
									disabled={disabled}
									onClick={handleCreateTransport}
								>
									Transport beauftragen
								</ActionButton>
							)}
						</Actions>
					</Box>
				</TileContent>
			</Tile>
		</Box>
	)
}
