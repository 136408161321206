import { useState } from "react";

export default () : [boolean, (action : () => Promise<void>) => Promise<void>] => {
	const [actionRunning, setActionRunning] = useState(false);

	const runAction = async (action : () => Promise<void>) => {
		try {
			setActionRunning(true);
			await action();
		} finally {
			setActionRunning(false);
		}
	};

	return [actionRunning, runAction];
};
