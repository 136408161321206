import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";
import ThemedDialog from "../../../Components/ThemedDialog";
import { Box, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";

interface Props {
	proposedEmail: string
}

const NewCustomOrderConfirmationDialog = (props: ReactConfirmProps<string | null> & Props) => {
	const [email, setEmail] = useState<string>(props.proposedEmail);

	return (
		<ThemedDialog open={props.show} maxWidth="sm" fullWidth onClose={() => props.proceed(null)}>
			<DialogTitle>Rückführung planen</DialogTitle>
			<DialogContent>
				<DialogContentText>Sehr geehrter Kunde,</DialogContentText>
				<DialogContentText>Um die Rückführung Ihres Fahrzeuges zu planen, senden wir Ihnen einen neuen Link. Bitte tragen Sie dafür hier Ihre E-Mail-Adresse ein.</DialogContentText>
				<br />
				<DialogContentText>Damit akzeptieren Sie, dass wir Ihnen diese Email schicken.</DialogContentText>
				<Box mt={3}>
					<TextField
						fullWidth
						variant="outlined"
						value={email}
						label="E-Mail"
						required
						error={email === ""}
						onChange={(e) => setEmail(e.target.value)}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					color="secondary"
					onClick={() => props.proceed(null)}
				>
					Abbrechen
				</Button>
				<Button
					color="primary"
					onClick={() => props.proceed(email)}
					disabled={!email}
				>
					Bestätigen
				</Button>
			</DialogActions>
		</ThemedDialog>
	)
};

export default (inputProps: Props) => {
	const dialog = confirmable(props => <NewCustomOrderConfirmationDialog {...props} {...inputProps} />);
	return createConfirmation<string | null>(dialog)();
}
