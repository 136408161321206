import { Import, VehicleRegistrationDocumentsProjection } from "../../../system/Domain";
import { useHistory } from "react-router-dom";
import { useAsync } from "react-use";
import axios from "axios";
import { Box, TableBody, TableCell, TableRow } from "@mui/material";
import Plate from "../../../Components/Plate/Plate";
import TextBlock from "../../../Components/Layout/TextBlock";
import PaperTable from "../../../Components/PaperTable";
import VehicleRegistrationDocumentsStatusPill from "./VehicleRegistrationDocumentsStatusPill";
import UnregistrationStatusPill from "./UnregistrationStatusPill";

const VehicleRegistrationDocumentUnregistrationsImportView = ({ imp } : { imp : Import }) => {
	const history = useHistory();

	const { value: vehicles } = useAsync(async () => {
		const { data } = await axios.get<VehicleRegistrationDocumentsProjection[]>(`/api/vehicles/remarketing/registration-documents`, {
			params: {
				importId: imp.id,
				specialSort: "PlateNumberNumbers"
			}
		});
		return data;
	}, [imp]);

	return (
		<Box mt={2}>
			<PaperTable>
				<colgroup>
					<col width="auto" />
					<col width="auto" />
				</colgroup>
				<TableBody>
					{vehicles && vehicles.map((v, i) => (
						<TableRow
							key={i}
							hover
							onClick={() => history.push(`/vehicles/${v.id}/orders`)}
						>
							<TableCell>
								<Plate plateNumber={v.plateNumber} style={{ zoom: 0.6 }} />
								<TextBlock
									primary={v.makeModel}
									secondary={v.vin}
									compact
								/>
							</TableCell>
							<TableCell>
								{imp.status === "Committed" && v.registrationDocuments && (
									<VehicleRegistrationDocumentsStatusPill status={v.registrationDocuments.status} />
								)}
							</TableCell>
							<TableCell>
								{imp.status === "Committed" && (
									<UnregistrationStatusPill isUnregistered={v.isUnregistered} registrationDocuments={v.registrationDocuments} />
								)}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</PaperTable>
		</Box>
	)
};

export default VehicleRegistrationDocumentUnregistrationsImportView;
