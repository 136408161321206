import { useState } from "react";
import axios, { AxiosRequestConfig } from "axios";
import FluentGrid from "../../Components/FluentGrid";
import { useTheme } from "@mui/styles";
import {
	KpisGroup, NumberQuery,
	SalesKpis,
	SalesKpisQuery,
	WorkItemType
} from "../../system/Domain";
import { Box, BoxProps, Button, IconButton, lighten, CircularProgress, alpha, colors } from "@mui/material";
import useAxiosEffect from "../../system/useAxiosEffect";
import formatNumber from "../../system/formatNumber";
import useChartOptions from "../../system/useChartOptions";
import Highcharts from "../../Components/Highcharts";
import { axiosDownload } from "../../system/axiosDownload";
import { setWorkSession } from "../../system/reducers/workSessions/actions";
import moment from "moment";
import { CloudDownload, Launch } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import createKpiStyles from "Components/createKpiStyles";
import KpisGroupTile from "../../Components/KpisGroupTile";

export const ageBuckets: AgeBucket[] = [
	{
		label: "mehr als 90 Tage",
		color: alpha(colors.red["A700"], 0.8),
		query: {
			greaterThanOrEquals: 90,
		},
	},
	{
		label: "60 bis 90 Tage",
		color: alpha(colors.deepOrange["A700"], 0.8),
		query: {
			greaterThanOrEquals: 60,
			lessThan: 90,
		},
	},
	{
		label: "30 bis 60 Tage",
		color: alpha(colors.orange["A700"], 0.8),
		query: {
			greaterThanOrEquals: 30,
			lessThan: 60,
		},
	},
	{
		label: "14 bis 30 Tage",
		color: alpha(colors.amber["A700"], 0.8),
		query: {
			greaterThanOrEquals: 14,
			lessThan: 30,
		},
	},
	{
		label: "7 bis 14 Tage",
		color: alpha(colors.green["A700"], 0.8),
		query: {
			greaterThanOrEquals: 7,
			lessThan: 14,
		},
	},
	{
		label: "0 bis 7 Tage",
		color: alpha(colors.lightGreen["A700"], 0.8),
		query: {
			greaterThanOrEquals: 0,
			lessThan: 7,
		},
	},
];

interface AgeBucket {
	label: string;
	color: string;
	query: NumberQuery;
}

interface EntryProps<TQuery> {
	group: KpisGroup<TQuery>;
	active: boolean;
	startSession: (group: KpisGroup<TQuery>) => Promise<void>;
	exportKpis: (group: KpisGroup<TQuery>) => Promise<void>;
}

export const KpisGroupTileEntry = <TQuery,>({
	group,
	active,
	startSession,
	exportKpis,
	...rest
}: EntryProps<TQuery> & BoxProps) => {
	const theme = useTheme();
	const styles = createKpiStyles(theme);
	const [options] = useChartOptions();

	return (
		<Box sx={[styles.entry, group.total === 0 && styles.opaque, active && styles.active]} {...rest}>
			<Box sx={[styles.columns, { justifyContent: "space-between", alignItems: "end" }]}>
				<Box>{group.name}</Box>
				<Box sx={[styles.columns, { alignItems: "end" }]}>
					<Box>{formatNumber(group.total)}</Box>
					<Highcharts
						options={{
							...options,
							chart: {
								animation: false,
								width: 64,
								height: 28,
								spacing: [1, 1, 1, 1],
								backgroundColor: "transparent",
								borderWidth: 0,
							},
							legend: {
								enabled: false,
							},
							xAxis: {
								visible: false,
							},
							yAxis: {
								visible: false,
							},
							tooltip: {
								enabled: false,
							},
							plotOptions: {
								column: {
									borderWidth: 0,
									colorByPoint: true,
									cursor: "pointer",
									colors: ageBuckets.map((b) => b.color),
								},
							},
							series: [
								{
									type: "column",
									data: group.ages,
									animation: false,
								},
							],
						}}
					/>
				</Box>
			</Box>
			{active && (
				<Box sx={styles.details}>
					<Box sx={styles.detailActions}>
						<Button
							size="small"
							variant="outlined"
							color="inherit"
							onClick={(e) => {
								e.stopPropagation();
								startSession(group);
							}}
							startIcon={<Launch />}
						>
							Bearbeitung starten
						</Button>
						<Button
							size="small"
							variant="outlined"
							color="inherit"
							onClick={(e) => {
								e.stopPropagation();
								exportKpis(group);
							}}
							startIcon={<CloudDownload />}
						>
							Exportieren
						</Button>
					</Box>
					{ageBuckets
						.map((b, index) => ({
							bucket: b,
							count: group.ages[index],
						}))
						.map((a, i) => (
							<Box key={i} sx={[styles.columns, styles.ageEntry, a.count === 0 && styles.opaque]}>
								<Box
									sx={styles.ageLabel}
									style={{
										backgroundColor: a.bucket.color,
										borderColor: lighten(a.bucket.color, 0.2),
										color: theme.palette.getContrastText(a.bucket.color),
									}}
								>
									{formatNumber(a.count)}
								</Box>
								<Box>{a.bucket.label}</Box>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										gap: 1,
									}}
								>
									<IconButton
										size="small"
										color="inherit"
										// variant="text"
										onClick={(e) => {
											e.stopPropagation();
											startSession({
												...group,
												query: {
													...group.query,
													age: a.bucket.query,
												},
											});
										}}
									>
										<Launch sx={{ fontSize: "1.2rem" }} />
									</IconButton>
									<IconButton
										size="small"
										color="inherit"
										onClick={(e) => {
											e.stopPropagation();
											exportKpis({
												...group,
												query: {
													...group.query,
													age: a.bucket.query,
												},
											});
										}}
									>
										<CloudDownload sx={{ fontSize: "1.2rem" }} />
									</IconButton>
								</Box>
							</Box>
						))}
				</Box>
			)}
		</Box>
	);
};

export default () => {
	const dispatch = useDispatch();

	const [kpis, setKpis] = useState<SalesKpis>();

	const loadKpis = async (axiosConfig: AxiosRequestConfig = {}) => {
		const { data } = await axios.get<SalesKpis>(`/api/sales/kpis`, axiosConfig);
		setKpis(data);
	};

	const startSalesKpisSession = async (group: KpisGroup<SalesKpisQuery>) => {
		let workItemType: WorkItemType = "Sale";
		if (group.tags.includes("direct-sale")) {
			workItemType = "DirectSale";
		}

		const { data: workSession } = await axios.post(`/api/sales/kpis/work-session`, {
			title: group.name,
			workItemType,
			query: group.query,
		});

		if (workSession) {
			dispatch(setWorkSession(workSession));
		}
	};

	const exportSalesKpis = async (group: KpisGroup<SalesKpisQuery>) => {
		const response = await axios.post(`/api/sales/kpis/export`, group.query, {
			responseType: "blob",
		});

		axiosDownload(response, `${moment().format("YYYY-MM-DD")} ${group.name}.xlsx`);
	};

	useAxiosEffect(loadKpis, []);

	if (!kpis) {
		return <CircularProgress size={48} />;
	}

	return (
		<FluentGrid itemWidth={350} itemGap={6}>
			<KpisGroupTile group={kpis.active} startSession={startSalesKpisSession} exportKpis={exportSalesKpis} />

			<KpisGroupTile group={kpis.carmarket} startSession={startSalesKpisSession} exportKpis={exportSalesKpis} />

			<KpisGroupTile
				group={kpis.manualResalePlattforms}
				startSession={startSalesKpisSession}
				exportKpis={exportSalesKpis}
			/>

			<KpisGroupTile group={kpis.reserved} startSession={startSalesKpisSession} exportKpis={exportSalesKpis} />

			<KpisGroupTile group={kpis.sold} startSession={startSalesKpisSession} exportKpis={exportSalesKpis} />

			<KpisGroupTile group={kpis.paid} startSession={startSalesKpisSession} exportKpis={exportSalesKpis} />

			<KpisGroupTile group={kpis.withoutPickupOrderSent} startSession={startSalesKpisSession} exportKpis={exportSalesKpis} />
		</FluentGrid>
	);
};
