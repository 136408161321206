import { AddAPhotoSharp, CloudUploadSharp, ErrorOutlineSharp } from "@mui/icons-material";
import { alpha, Box, LinearProgress, useTheme } from "@mui/material";
import axios from "axios";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FileReference } from "../system/Domain";
import Thumbnail from "./Thumbnail";
import { ImageUploadProps } from "../Pages/Vehicles/PictureSets/VehiclePictureSets";

interface Props {
	previewUrl?: string
	disabled: boolean
	fullWidth: boolean
	readOnly: boolean
	pictureName?: string
	setter: Dispatch<SetStateAction<ImageUploadProps>>
}

type Upload = {
	filename: string
	progress: number
};

const PictureUpload = (props: Props) => {
	const theme = useTheme();
	const darkTheme = theme.palette.mode === 'dark';
	const color = darkTheme ? theme.palette.common.white : theme.palette.common.black;

	const errorSx = {
		borderColor: alpha(theme.palette.error.dark, 0.75),
		color: theme.palette.error.dark,
		"&:hover": {
			borderColor: alpha(theme.palette.error.dark, 0.75),
			color: theme.palette.error.dark,
		}
	};

	const rootSx = {
		position: "relative",
		border: "2px dashed",
		borderColor: alpha(color, 0.12),
		borderRadius: 4,
		width: 300,
		height: 300,
		cursor: "pointer",
		color: alpha(color, 0.3),
		"&:hover": {
			borderColor: alpha(color, 0.3),
			color: alpha(color, 0.6)
		},
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",
		fontSize: "0.75rem",
		transition: "border 200ms ease-out, color 200ms ease-out",
		outline: "none",
		lineHeight: "1.9em",
		overflow: "hidden"
	};

	const [error, setError] = useState<string | null>(null);
	const [upload, setUpload] = useState<Upload | null>(null);
	const [fileReference, setFileReference] = useState<FileReference | undefined>();

	const onDrop = useCallback(async (acceptedFiles) => {
		if (acceptedFiles.length !== 1) {
			return;
		}

		const file = acceptedFiles[0];
		const filename = file.name;

		setError(null);
		setUpload({
			filename,
			progress: 0
		});

		const formData = new FormData();
		formData.append("file", file);

		try {
			const response = await axios.post<FileReference>("/api/storage",
				formData,
				{
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				onUploadProgress: (evt) => {
					setUpload({
						filename,
						progress: Math.ceil((evt.loaded / evt.total) * 100)
					});
				}
			});

			if (response.status !== 200) {
				throw new Error();
			}

			const reference = response.data;

			setFileReference(reference);

			props.setter({ fileReference: reference, propertyName: props.pictureName });
		} catch (e) {
			setError("Datei konnte nicht hochgeladen werden");
		} finally {
			setUpload(null);
		}
	}, []);

	const { getRootProps, getInputProps, isDragActive, draggedFiles } = useDropzone({
		disabled: props.disabled,
		onDrop,
		multiple: false
	});

	return (
		<>
			<Box
				{...getRootProps()}
				sx={[
					rootSx,
					(isDragActive || fileReference || upload) && {
						borderColor: theme.palette.primary.main,
						color: theme.palette.primary.main,
						"&:hover": {
							borderColor: theme.palette.primary.main,
							color: theme.palette.primary.main
						}
					},
					((error && !isDragActive) || draggedFiles.length) && errorSx,
					fileReference &&
					!isDragActive && {
						borderStyle: "solid",
						borderColor: alpha(color, 0.12),
						borderWidth: 1,
						"&:hover": {
							borderColor: alpha(color, 0.12),
						}
					},
					props.fullWidth && { width: "100%" },
					props.readOnly && {
						cursor: "default",
						"&:hover": {
							borderColor: alpha(color, 0.12),
							color: alpha(color, 0.3)
						}
					}
				]}
			>
				<input {...getInputProps()} capture />

				{error && !isDragActive && (
					<>
						<ErrorOutlineSharp
							sx={{
								position: "absolute",
								fontSize: "3rem"
							}}
						/>
						<Box sx={{ padding: theme.spacing(2) }}>{error}</Box>
					</>
				)}

				{!error && !upload && !fileReference && !isDragActive && (
					<>
						{props.previewUrl && (
							<Box
								sx={{
									position: "absolute",
									fontSize: "3rem"
								}}
							>
								<Thumbnail width={300} height={300} url={props.previewUrl} />
							</Box>
						)}
						<AddAPhotoSharp
							sx={{
								position: "absolute",
								fontSize: "3rem"
							}}
						/>
						{!props.previewUrl && (
							<Box sx={{ padding: theme.spacing(2) }}>
								Datei per Drag and Drop hierher verschieben oder klicken um eine Datei auszuwählen
							</Box>
						)}
					</>
				)}

				{isDragActive &&
					(draggedFiles.length === 1 ? (
						<>
							<CloudUploadSharp
								sx={{
									position: "absolute",
									fontSize: "3rem"
								}}
							/>
							<Box sx={{ padding: theme.spacing(2) }}>Datei jetzt ablegen um den Upload zu starten</Box>
						</>
					) : (
						<>
							<ErrorOutlineSharp
								sx={{
									position: "absolute",
									fontSize: "3rem"
								}}
							/>
							<Box sx={{ padding: theme.spacing(2) }}>Es kann nur eine Datei hochgeladen werden</Box>
						</>
					))}

				{!error && !isDragActive && upload && (
					<>
						<CloudUploadSharp
							sx={{
								position: "absolute",
								fontSize: "3rem"
							}}
						/>
						<Box sx={{ padding: theme.spacing(2) }}>
							<Box>{upload.filename} wird hochgeladen</Box>
							<LinearProgress
								variant="determinate"
								sx={{
									width: "100%",
									marginTop: theme.spacing(1)
								}}
								value={upload.progress}
							/>
						</Box>
					</>
				)}

				{!error && !isDragActive && !upload && fileReference && (
					<Thumbnail
						width={300}
						height={300}
						asyncUrl={`/api/storage/${fileReference.hash}/thumbnail`}
						downloadUrl={`/api/storage/${fileReference.hash}`}
					/>
				)}
			</Box>
		</>
	);
};

export default PictureUpload;
