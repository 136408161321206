
import { Box, BoxProps, useTheme } from "@mui/material";

export default ({ children, ...rest }: BoxProps) => {
	const theme = useTheme();

	return (
		<Box
			pt={theme.spacing(2)}
			gap={theme.spacing(1.5)}
			display="flex"
			flexDirection="row"
			alignItems="center"
			flexWrap="wrap"
			{...rest}
		>
			{children}
		</Box>
	);
}
