import { Box, Table, TableBody, TableHead, TableRow } from "@mui/material";
import { UserRole, WorkSession } from "../../system/Domain";
import TextBlock from "../../Components/Layout/TextBlock";
import formatNumber from "../../system/formatNumber";
import formatDateTime from "../../system/formatDateTime";
import { useDispatch } from "react-redux";
import { setWorkSession } from "../../system/reducers/workSessions/actions";
import formatWorkSessionPerspective from "./formatWorkSessionPerspective";
import axios from "axios";
import useUser from "../../system/useUser";
import { Colgroup, TableCell } from "../../Components/BreakpointStyledComponents";

interface WorkSessionTableProps {
	workSessions: WorkSession[]
}

export default ({ workSessions }: WorkSessionTableProps) => {
	const dispatch = useDispatch();
	const [, , hasRole] = useUser();

	const openWorkSession = async (id: string) => {
		const { data: session } = await axios.get<WorkSession>(`/api/work-sessions/${id}`);
		dispatch(setWorkSession(session));
	};

	const closeWorkSession = async (id: string) => {
		await axios.post<WorkSession>(`/api/work-sessions/${id}/close`);
	};

	return (
		<Table>
			<Colgroup sm md lg xl>
				<col width="auto" />
				<col width="auto" />
			</Colgroup>
			<TableHead>
				<TableRow>
					<TableCell>Sitzung</TableCell>
					<TableCell sm md lg xl>Zusammenfassung</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{workSessions && workSessions.map(workSession => {
					const title = (
						<TextBlock
							primary={workSession.title || formatWorkSessionPerspective(workSession.perspective)}
							secondary={<span>{formatNumber(workSession.items.length)} Einträge</span>}
						/>
					);

					const summary = (
						<TextBlock
							primary={workSession.createdBy.name}
							secondary={<span>Erstellt am {formatDateTime(workSession.dateCreated)}</span>}
						/>
					);

					return (
						<TableRow
							key={workSession.id}
							hover
							onClick={(e) => {
								if (e.altKey && hasRole(UserRole.AldAdmin)) {
									closeWorkSession(workSession.id);
									return;
								}
								openWorkSession(workSession.id)
							}}
						>
							<TableCell>
								{title}
								<Box sx={{ display: { xs: "contents", sm: "none" } }}>
									<Box mt={2}>
										{summary}
									</Box>
								</Box>
							</TableCell>
							<TableCell sm md lg xl>
								{summary}
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		</Table>
	);
}
