import { Import, ImportStatus } from "../../../system/Domain";
import ReturnOrdersView from "./ReturnOrdersView";
import ReturnOrdersEditView from "./ReturnOrdersEditView";

export default ({ imp }: { imp: Import }) => (
	<>
		{imp.status === ImportStatus.Committed && (
			<ReturnOrdersView imp={imp} />
		)}
		{imp.status !== ImportStatus.Committed && (
			<ReturnOrdersEditView imp={imp} />
		)}
	</>
)
