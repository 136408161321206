import moment from 'moment';

import { Mail } from '@mui/icons-material';
import {
    Box, Card, CardContent, List, ListItem, ListItemIcon, ListItemText, Typography
} from '@mui/material';

import { SaleEmail } from '../../../system/Domain';

interface Props {
	emails : SaleEmail[]
	onSelect?: (mail: SaleEmail) => void
}

const SaleEmailCard = ({ emails, onSelect } : Props) => (
	<Card>
		<CardContent>
			<Box>
				<Typography variant="h6">Emails</Typography>
			</Box>
			<Box>
				{emails.length === 0 && (
					<Typography>Keine Emails versandt</Typography>
				)}
				{emails.length > 0 && (
					<List>
						{emails.map(e => {
							const dateSent = moment(e.dateSent);

							const handleClick = () => {
								onSelect && onSelect(e);
							};

							return (
								<ListItem
									key={e.mailTrackingId}
									button
									onClick={handleClick}
								>
									<ListItemIcon>
										<Mail />
									</ListItemIcon>
									<ListItemText
										primary={e.subject}
										secondary={`${dateSent.format("LLL")} an ${e.to}`}
									/>
								</ListItem>
							)
						})}
					</List>
				)}
			</Box>
		</CardContent>
	</Card>
);

export default SaleEmailCard;
