import axios from 'axios';
import fileDownload from 'js-file-download';
import moment from 'moment';
import * as queryString from "query-string";
import { InsertDriveFile } from '@mui/icons-material';
import { DmsDocument, Vehicle } from '../../system/Domain';
import parseContentDispositionFilename from '../../system/parseContentDispositionFilename';
import { useAsync } from 'react-use';
import VehicleItemsTile from './VehicleItemsTile';
import { useCallback } from 'react';

const VehicleDocumentList = ({ vehicle }: { vehicle: Vehicle }) => {
	const { loading, value } = useAsync(async () => {
		if (!vehicle?.leasmanContractId) {
			return [];
		}

		const query = queryString.stringify({
			contractId: vehicle.leasmanContractId
		});

		const response = await axios.get<DmsDocument[]>(`/api/documents?${query}`);

		return response.data;
	}, [vehicle]);

	const documents = value || [];

	const handleClick = useCallback(async (d) => {
		const response = await axios.get(`/api/documents/${d.id}`, {
			responseType: "blob"
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);

		fileDownload(response.data, filename);
	}, []);

	return (
		<VehicleItemsTile
			title="Doxis Dokumente"
			isLoading={loading}
			items={documents}
			itemIcon={<InsertDriveFile color="action" />}
			makeItemKey={(d) => d.id}
			makeItemText={(d) => (
				{
					primary: d.documentTypeName,
					secondary: `${moment(d.date).format("LLL")} - ${d.documentType}`
				}
			)}
			onItemClick={handleClick}
		/>
	);
}

export default VehicleDocumentList;
