import { useEffect, useState } from "react";
import Select from "../../Components/Select";
import useCompanies from "../../system/useCompanies";
import { CompanyReference } from "../../system/Domain";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ThemedDialog from "../../Components/ThemedDialog";
import { Box, Button, CircularProgress, DialogTitle } from "@mui/material";
import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";

interface Props extends ChangeCompanyOptions, ReactConfirmProps<string> {
	show: boolean
}

const ChangeCompanyDialog = ({ show, proceed, company, cancel }: Props) => {
	const [companies] = useCompanies();
	const [selectedCompany, setSelectedCompany] = useState<string>();

	const changeCompany = (event: any) => {
		setSelectedCompany(event.target.value);
	};

	useEffect(() => {
		if (!companies) {
			return;
		}

		let initiallySelectedCompanies = companies?.filter(f => f.name === company?.name);

		setSelectedCompany(initiallySelectedCompanies && initiallySelectedCompanies.some(f => f) ? initiallySelectedCompanies[0].name : undefined);
	}, [companies]);

	const handleClose = () => {
		cancel();
	};

	return (
		<ThemedDialog open={show} maxWidth="sm" fullWidth onClose={handleClose}>
			<DialogTitle>
				{company ? "Zuweisung anpassen" : "Dienstleister zuweisen"}
			</DialogTitle>
			<DialogContent>
				{!companies && (
					<CircularProgress size={48} />
				)}
				{companies && (
					<Box mb={2}>
						<Select
							label="Dienstleister"
							name="company"
							fullWidth
							value={selectedCompany}
							onChange={changeCompany}
							variant="standard"
						>
							<option value="" />
							{companies.map(c => (
								<option key={c.name} value={c.name}>{c.name}</option>
							))}
						</Select>
					</Box>
				)}
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="secondary"
					href=""
					onClick={handleClose}
				>
					Abbrechen
				</Button>
				<Button
					variant="outlined"
					color="primary"
					href=""
					onClick={selectedCompany ? () => proceed(selectedCompany) : undefined}
				>
					Ok
				</Button>
			</DialogActions>
		</ThemedDialog>
	);
};

interface ChangeCompanyOptions {
	company?: CompanyReference
}

export default (options: ChangeCompanyOptions) => {
	const dialog = confirmable(props => <ChangeCompanyDialog {...props} {...options} />);
	return createConfirmation<string>(dialog)();
}
