import axios from "axios";
import moment from "moment";
import useUser from "../system/useUser";
import { Box, useTheme } from "@mui/material";
import { Star, StarBorder } from "@mui/icons-material";
import { RecursivePartial, Vehicle } from "../system/Domain";
import manageVehicleBookmarks from "../system/manageVehicleBookmarks";
import { Dispatch, SetStateAction, MouseEvent } from "react";

interface Props {
	vehicle: Vehicle;
	float?: boolean;
	setSaving?: Dispatch<SetStateAction<boolean>>;
	updateCallback?: (vehicle: Vehicle) => void;
}

const VehicleBookmark = (props: Props) => {
	const theme = useTheme();

	const [user] = useUser();
	const ownBookmarks = props.vehicle.bookmarks?.filter((f) => f.user?.id === user?.id);
	const hasBookmarks = !!ownBookmarks?.length;

	const handleClick = async (e: MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();

		const resultBookmarks = await manageVehicleBookmarks({
			bookmarks: props.vehicle.bookmarks ?? [],
			userId: user?.id ?? "",
			theme: theme as any
		});

		await updateVehicle({
			bookmarks: resultBookmarks ?? []
		});
	};

	const updateVehicle = async (update: RecursivePartial<Vehicle>) => {
		if (props.setSaving) {
			props.setSaving(true);
		}

		try {
			const { data: updatedVehicle } = await axios.put<Vehicle>(`/api/vehicles/${props.vehicle.id}`, update);

			if (props.updateCallback) {
				props.updateCallback(updatedVehicle);
			}
		} finally {
			if (props.setSaving) {
				props.setSaving(false);
			}
		}
	};

	return (
		<Box
			onClick={handleClick}
			sx={{ mt: 0.5 }}
			title={
				hasBookmarks
					? ownBookmarks.sort((a, b) => (moment(a.dateCreated) < moment(b.dateCreated) ? 1 : -1))[0].remark
					: undefined
			}
		>
			{hasBookmarks && <Star />}
			{!hasBookmarks && <StarBorder />}
		</Box>
	);
};

export default VehicleBookmark;
