import { StorageFile } from "../../../system/Domain";

const put = function (key : string, file : StorageFile) {
	const item = window.sessionStorage.getItem("pictureSets");
	let result;

	if (!item) {
		result = {
			[key]: file
		}
	} else {
		result = JSON.parse(item);
		result[key] = file;
	}
	window.sessionStorage.setItem("pictureSets", JSON.stringify(result));
}

interface VehiclePictureSet {
	ghostImageName: string
	uploadPromise: Promise<StorageFile> | null
	filename: string | null
	uploadProgress: number
}

const initialValues = function () : VehiclePictureSet[] {
	const initialValue = [
		{
			ghostImageName: "ghost_front_left.jpeg",
			uploadPromise: null,
			filename: null,
			uploadProgress: 0
		},
		{
			ghostImageName: "ghost_back_left.jpeg",
			uploadPromise: null,
			filename: null,
			uploadProgress: 0
		},
		{
			ghostImageName: "ghost_back_right.jpeg",
			uploadPromise: null,
			filename: null,
			uploadProgress: 0
		},
		{
			ghostImageName: "ghost_front_right.jpeg",
			uploadPromise: null,
			filename: null,
			uploadProgress: 0
		},
		{
			ghostImageName: "ghost_headlights.jpeg",
			uploadPromise: null,
			filename: null,
			uploadProgress: 0
		},
		{
			ghostImageName: "ghost_tire_and_rim.jpeg",
			uploadPromise: null,
			filename: null,
			uploadProgress: 0
		},
		{
			ghostImageName: "ghost_interior_front.jpeg",
			uploadPromise: null,
			filename: null,
			uploadProgress: 0
		},
		{
			ghostImageName: "ghost_interior_back.jpeg",
			uploadPromise: null,
			filename: null,
			uploadProgress: 0
		},
		{
			ghostImageName: "ghost_trunk.jpeg",
			uploadPromise: null,
			filename: null,
			uploadProgress: 0
		},
		{
			ghostImageName: "ghost_cockpit.jpeg",
			uploadPromise: null,
			filename: null,
			uploadProgress: 0
		},
		{
			ghostImageName: "ghost_infotainment.jpeg",
			uploadPromise: null,
			filename: null,
			uploadProgress: 0
		},
		{
			ghostImageName: "ghost_instruments.jpeg",
			uploadPromise: null,
			filename: null,
			uploadProgress: 0
		}
	];

	const item = window.sessionStorage.getItem("pictureSets");
	if (item) {
		const cache = JSON.parse(item);
		return initialValue.map(i => {
			const cachedImage = cache[i.ghostImageName];
			if (cachedImage) {
				return {
					...i,
					uploadPromise: Promise.resolve(cachedImage as StorageFile),
					uploadProgress: 100
				}
			}
			return i;
		});
	}
	return initialValue;
}

const clear = function () {
	window.sessionStorage.removeItem("pictureSets");
}

export default {
	initialValues,
	put,
	clear
}
