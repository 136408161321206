
import { Assessment, Image } from '@mui/icons-material';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';

import { DekraAssessmentAttachment } from '../../../system/Domain';

interface DekraAssessmentAttachmentListProps {
	onSelect? : (attachment : DekraAssessmentAttachment) => void
	attachments : DekraAssessmentAttachment[]
}

const DekraAssessmentAttachmentList = ({ attachments, onSelect } : DekraAssessmentAttachmentListProps) => (
	<List>
		{attachments.map(a => {
			const handleClick = () => {
				onSelect && onSelect(a);
			};

			return (
				<ListItem button onClick={handleClick}>
					<ListItemAvatar>
						<Avatar>
							{a.beschreibung === "SV-Ergebnis" ? (
								<Assessment />
							) : (
								<Image />
							)}
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary={a.beschreibung}
						secondary={a.file.filename}
					/>
				</ListItem>
			)
		})}
	</List>
);

export default DekraAssessmentAttachmentList;
