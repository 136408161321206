import { useCallback, useState } from "react";
import { useDropzone } from 'react-dropzone'
import uploadImage from '../cloud-upload.png';
import { SelectChangeEvent, Typography } from "@mui/material";
import ThemedDialog from "../Components/ThemedDialog";
import { confirmable, createConfirmation } from "react-confirm";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Select, MenuItem } from "@mui/material";
import { ReactConfirmProps } from "react-confirm";

interface CompanyFilesResult {
	ok?: boolean
	files: string[]
	company: string | null
}

interface CompanyFilesDialogProps extends ReactConfirmProps<CompanyFilesResult> {
	title: string
	multiple: boolean
	companies: string[]
}

const CompanyFileDialog = ({ show, proceed, title, multiple, companies }: CompanyFilesDialogProps) => {
	const noneCompanyValue = '';
	const [selectedCompany, setSelectedCompany] = useState(noneCompanyValue);

	const onDrop = useCallback(files => proceed({
		ok: true,
		files,
		company: selectedCompany !== noneCompanyValue ? selectedCompany : null
	}), [proceed, selectedCompany]);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		multiple
	});

	const selectCompany = (e: SelectChangeEvent<string>) => {
		setSelectedCompany(e.target.value)
	}

	return (
		<ThemedDialog open={show} maxWidth="md">
			<DialogTitle>{title || "Dateien laden"}</DialogTitle>
			<DialogContent>
				<Box mb={1}
					{...getRootProps()}
					sx={{
						padding: 36, paddingTop: 0, paddingBottom: 0, minHeight: 240, minWidth: 600,
						borderWidth: 2, borderColor: "#757575", borderStyle: 'dashed', borderRadius: 5,
						textAlign: 'center'
					}}
				>
					<input {...getInputProps()} />
					<img src={uploadImage} alt="upload" />
					<Typography gutterBottom>Dateien per Drag and Drop hierher verschieben</Typography>
				</Box>
				<Box mt={2}>
					<div>
						<Typography>Firma</Typography>
						<Select
							name="company"
							fullWidth
							value={selectedCompany}
							onChange={selectCompany}
							variant="standard"
						>
							{companies.map(c => (
								<MenuItem key={c} value={c}>{c}</MenuItem>
							))}
						</Select>
					</div>
				</Box>
			</DialogContent>
			<DialogActions sx={{ root: { marginRight: 12, marginBottom: 6 } }}>
				<Button variant="contained" color="primary" onClick={() => proceed({ok: false, files:null, company: null})}>
					Abbrechen
				</Button>
			</DialogActions>
		</ThemedDialog>
	)
};



export default (title: string, companies: string[], allowMultipleFiles = false) => {
	const dialog = confirmable(props => <CompanyFileDialog {...props} title={title} multiple={allowMultipleFiles} companies={companies} />);
	return createConfirmation<CompanyFilesResult>(dialog)();
};
