import { useCallback, useEffect, useState } from "react";
import { Box, CircularProgress, useTheme } from "@mui/material";
import useGoogleMaps from "../system/useGoogleMaps";
import useArraySync from "../system/useArraySync";
import { grey } from "@mui/material/colors";

export interface MapProps {
	opts?: google.maps.MapOptions;
	init?: (map: google.maps.Map) => void;
	center?: google.maps.LatLng;
	markers?: google.maps.ReadonlyMarkerOptions[];
	polygons?: google.maps.PolygonOptions[];
}

interface MapLocation {
	name: string;
	coordinates: {
		lat: number;
		lng: number;
	};
}

const locationsList: MapLocation[] = [
	{ name: "Dorfmark", coordinates: { lat: 52.898, lng: 9.778 } },
	{ name: "Dorfmark Bahnhof", coordinates: { lat: 52.898865763267004, lng: 9.771296959439226 } },
	{ name: "Dorfmark Siemsglüss", coordinates: { lat: 52.89722368466212, lng: 9.777427527119778 } },
	{ name: "Bad Fallingbostel", coordinates: { lat: 52.85084, lng: 9.692551 } },
	{ name: "Bochum", coordinates: { lat: 51.486347729215424, lng: 7.2976849003447155 } },
	{ name: "Bremen", coordinates: { lat: 53.175537, lng: 8.588502 } },
	{ name: "Düren", coordinates: { lat: 50.786027, lng: 6.502422 } },
	{ name: "Emmering", coordinates: { lat: 48.177998, lng: 11.303748 } },
	{ name: "Heidenheim", coordinates: { lat: 48.69057866033265, lng: 10.163080839217546 } },
	{ name: "Hoppegarten", coordinates: { lat: 52.514591648222144, lng: 13.655254047818486 } },
	{ name: "Kerpen", coordinates: { lat: 50.89576037913442, lng: 6.6498368581360285 } },
	{ name: "Mücke Atzenhain", coordinates: { lat: 50.647787, lng: 8.988986 } },
	{ name: "Neuss", coordinates: { lat: 51.21413238627201, lng: 6.711731423252748 } },
	{ name: "Norderstedt", coordinates: { lat: 53.6677, lng: 9.975 } },
	{ name: "Seddiner See", coordinates: { lat: 52.290665, lng: 13.006879 } },
];

let locationsListLength = locationsList.length;

export default ({ opts, init, center, markers, polygons }: MapProps) => {
	const theme = useTheme();
	const [selectedLocationIndex, setSelectedLocationIndex] = useState<number>(0);
	const [isLoaded] = useGoogleMaps();
	const [map, setMap] = useState<google.maps.Map>();

	const goToNextLocation = () => {
		setSelectedLocationIndex((prevIndex) => (prevIndex + 1) % locationsListLength);
	};

	const goToPreviousLocation = () => {
		setSelectedLocationIndex((prevIndex) => (locationsListLength + prevIndex - 1) % locationsListLength);
	};

	const toggleShowLocations = (element: HTMLElement) => {
		if (!element) return;
		element.hidden = !element.hidden;
	};

	const CustomControlButton = (behavior: () => void, text: string, title: string) => {
		// Set CSS for the control border.
		const controlUI = document.createElement("div");
		controlUI.style.backgroundColor = "#fff";
		controlUI.style.border = "2px solid #fff";
		controlUI.style.borderRadius = "3px";
		controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
		controlUI.style.cursor = "pointer";
		controlUI.style.marginBottom = "22px";
		controlUI.style.textAlign = "center";
		controlUI.style.margin = "10px";
		controlUI.title = title;

		// Set CSS for the control interior.
		const controlText = document.createElement("div");
		controlText.style.color = "rgb(25,25,25)";
		controlText.style.fontFamily = "Roboto,Arial,sans-serif";
		controlText.style.fontSize = "20px";
		controlText.style.lineHeight = "25px";
		controlText.style.fontWeight = "100";
		controlText.style.textShadow = "none";
		controlText.style.paddingLeft = "5px";
		controlText.style.paddingRight = "5px";
		controlText.style.letterSpacing = "0.4px";
		controlText.style.webkitTextFillColor = "rgb(25,25,25)";
		controlText.style.width = "25px";
		controlText.innerHTML = text;
		controlUI.appendChild(controlText);

		// Set up the click event listeners: simply set the map to Norderstedt.
		controlUI.addEventListener("click", behavior);

		return controlUI;
	};

	const LocationsControlButton = (text: string, title: string) => {
		const controlList = document.createElement("div");
		controlList.id = "controlList";
		controlList.style.color = "rgb(25,25,25)";
		controlList.style.fontFamily = "Roboto,Arial,sans-serif";
		controlList.style.fontSize = "20px";
		controlList.style.lineHeight = "25px";
		controlList.style.fontWeight = "100";
		controlList.style.textShadow = "none";
		controlList.style.paddingLeft = "5px";
		controlList.style.paddingRight = "5px";
		controlList.style.letterSpacing = "0.4px";
		controlList.style.webkitTextFillColor = "rgb(25,25,25)";
		controlList.style.width = "140px";
		controlList.hidden = true;

		const controlUI = CustomControlButton(() => toggleShowLocations(controlList), text, title);
		controlUI.appendChild(controlList);

		for (let index = 0; index < locationsListLength; index++) {
			const controlLocation = document.createElement("div");
			controlLocation.style.color = "rgb(25,25,25)";
			controlLocation.style.fontFamily = "Roboto,Arial,sans-serif";
			controlLocation.style.fontSize = "12px";
			controlLocation.style.textAlign = "left";
			controlLocation.style.lineHeight = "25px";
			controlLocation.style.fontWeight = "100";
			controlLocation.style.textShadow = "none";
			controlLocation.style.paddingLeft = "5px";
			controlLocation.style.paddingRight = "5px";
			controlLocation.style.letterSpacing = "0.4px";
			controlLocation.style.webkitTextFillColor = "rgb(25,25,25)";
			controlLocation.style.width = "130px";
			controlLocation.innerHTML = locationsList[index].name;
			controlLocation.onmouseover = () => {
				controlLocation.style.backgroundColor = grey[500];
			};
			controlLocation.onmouseout = () => {
				controlLocation.style.backgroundColor = "#fff";
			};
			controlLocation.addEventListener("click", () => setSelectedLocationIndex(index));

			controlList.appendChild(controlLocation);
		}

		return controlUI;
	};

	useEffect(() => {
		if (!map || !locationsList || selectedLocationIndex === undefined) {
			return;
		}
		map.setCenter(locationsList[selectedLocationIndex].coordinates);
	}, [map, selectedLocationIndex]);

	const initMap = useCallback((node) => {
		if (!node) {
			return;
		}

		const mapOpts: google.maps.MapOptions = {
			center: center || { lat: 52.898991, lng: 9.778258 }, // || { lat: 52.898973, lng: 9.771826 }
			zoom: 17,
			disableDefaultUI: true,
			fullscreenControl: true,
			mapTypeId: google.maps.MapTypeId.SATELLITE,
			...opts,
		};

		const map = new google.maps.Map(node, mapOpts);

		const controlGoBack = CustomControlButton(() => goToPreviousLocation(), "&lt;", "");
		const controlGoForward = CustomControlButton(() => goToNextLocation(), "&gt;", "");
		const controlChooseLocation = LocationsControlButton("...", "");

		map.controls[google.maps.ControlPosition.TOP_LEFT].push(controlGoBack);
		map.controls[google.maps.ControlPosition.TOP_LEFT].push(controlGoForward);
		map.controls[google.maps.ControlPosition.TOP_LEFT].push(controlChooseLocation);

		setMap(map);

		if (init) {
			init(map);
		}
	}, []);

	useEffect(() => {
		if (!map || !center) {
			return;
		}

		map.setCenter(center);
	}, [map, center]);

	const applyGoogleHandlers = (googleObject: any, handlers?: any) => {
		if (handlers) {
			Object.keys(handlers).forEach((eventName) => {
				google.maps.event.clearInstanceListeners(googleObject);
				googleObject.addListener(eventName, handlers[eventName]);
			});
		}
	};

	useArraySync<google.maps.ReadonlyMarkerOptions, google.maps.Marker>(
		markers,
		{
			isReady: () => {
				return !!map;
			},
			onAdd: (opts) => {
				const marker = new google.maps.Marker(opts);
				// @ts-ignore
				marker.setMap(map);
				applyGoogleHandlers(marker, (opts as any).handlers);

				return marker;
			},
			onUpdate: (opts, marker) => {
				marker.setOptions(opts);
				applyGoogleHandlers(marker, (opts as any).handlers);
			},
			onRemove: (marker) => {
				marker.setMap(null);
			},
		},
		[map],
	);

	useArraySync<google.maps.PolygonOptions, google.maps.Polygon>(
		polygons,
		{
			isReady: () => {
				return !!map;
			},
			onAdd: (opts) => {
				const polygon = new google.maps.Polygon(opts);
				// @ts-ignore
				polygon.setMap(map);
				applyGoogleHandlers(polygon, (opts as any).handlers);
				return polygon;
			},
			onUpdate: (opts, polygon) => {
				polygon.setOptions(opts);
				applyGoogleHandlers(polygon, (opts as any).handlers);
			},
			onRemove: (polygon) => {
				polygon.setMap(null);
			},
		},
		[map],
	);

	if (!isLoaded) {
		return (
			<Box m={3} style={{ textAlign: "center" }}>
				<CircularProgress size={48} />
			</Box>
		);
	}

	return (
		<Box
			sx={{
				flexGrow: 1,
				textShadow: "rgba(0, 0, 0, 0.8) 1px 1px 1px",
				"-webkit-text-stroke-width": "1px",
				"-webkit-text-stroke-color": "rgba(50, 50, 50, 0.6)",
				"-webkit-text-fill-color": "#ffffff",
				borderRadius: theme.spacing(0.5),
			}}
			ref={initMap}
		/>
	);
};
