import { Box, Paper, Table, TableProps } from "@mui/material";

export default (props: TableProps, fixed: boolean = false) => {
	return (
		<Paper>
			<Box overflow="hidden">
				<Table
					{...props}
					sx={{
						width: "100%",
						"& tr": {
							cursor: "pointer",
							verticalAlign: "top",
							"&:last-child > td": {
								border: "0 none"
							}
						}
					}}
				/>
			</Box>
		</Paper>
	);
}
