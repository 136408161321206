import { CssBaseline, StyledEngineProvider, Theme, ThemeProvider } from "@mui/material";
import createTheme from "createTheme";
import { useRecoilValue } from "recoil";
import { settingsAtom } from "system/atoms/settings";
import App from "./App";

declare module "@mui/styles" {
	interface DefaultTheme extends Theme {}
}

export default () => {
	const settings = useRecoilValue(settingsAtom);
	const theme = createTheme(settings.theme);

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline>
				<StyledEngineProvider injectFirst>
					<App />
				</StyledEngineProvider>
			</CssBaseline>
		</ThemeProvider>
	);
};
