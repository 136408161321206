import { Box } from "@mui/material";
import dateFormats from "../../../system/dateFormats";
import { TransportPricing } from "../../../system/Domain";
import moment from "moment";
import Tile from "Components/Tiles/Tile";
import TileContent from "Components/Tiles/TileContent";
import TextBlock from "Components/Layout/TextBlock";

interface Props {
	pricing: TransportPricing
}

export default (props: Props) => {
	return (
		<Tile title="Historie">
			<TileContent>
				<Box>
					<TextBlock
						primary={`Zuletzt angepasst: ${props.pricing.dateUpdated ? moment(props.pricing.dateUpdated).format(dateFormats.dateTime) : 'niemals'}`}
						secondary={props.pricing.updatedBy?.name}
					/>
				</Box>
			</TileContent>
		</Tile>
	)
}
