import { useEffect, useState } from "react";

export default (initialState : any, timeout = 1000) => {
	const [value, setValue] = useState<any>(initialState);

	useEffect(() => {
		if (value === initialState) return;
		

		const status = {
			done: false
		};

		const handle = setTimeout(() => {
			status.done = true;
			setValue(initialState);
		}, timeout);

		return () => {
			if (status.done) {
				return;
			}

			clearTimeout(handle);
		}
	}, [value]);

	return [value, setValue];
};
