import { useState } from "react";
import { Vehicle, TransportOrder } from "../../system/Domain";
import axios from "axios";
import { useHistory } from "react-router-dom";
import useAsyncEffect from "../../system/useAsyncEffect";
import VinQrScanner from "../../Components/VinQrScanner";
import qs from "query-string";
import Layout from "../../Components/Layout/Layout";
import LotSubMenu from "./LotSubMenu";
import VehiclesNavigation from "Navigation/VehiclesNavigation";

export default () => {
	const history = useHistory();
	const [vin, setVin] = useState<string>();
	const [, setSaving] = useState<boolean>(false);

	useAsyncEffect(async () => {
		if (!vin) {
			return;
		}

		setSaving(true);

		try {
			const { data: vehicle } = await axios.post<Vehicle>(`/api/compounds/scans`, {
				vin,
			});

			if (vehicle.remarketing.status === "Registriert") {
				const { data: orders } = await axios.get<TransportOrder[]>(`/api/orders/transports`, {
					params: {
						vin,
						status: ["Assigned", "Completed"],
					},
					paramsSerializer: (params) => qs.stringify(params),
				});

				if (orders.length === 1) {
					history.push({
						pathname: `/orders/transports/${orders[0].id}`,
					});

					return;
				}
			}

			history.push({
				pathname: `/vehicles/${vehicle.id}`,
			});
		} finally {
			setSaving(false);
		}
	}, [vin]);

	return (
		<Layout title="Fahrzeugscan" navigation={<VehiclesNavigation />} subMenu={<LotSubMenu />}>
			<VinQrScanner setVin={setVin} />
		</Layout>
	);
};
