import axios from "axios";
import moment from "moment";
import Tile from "./Tiles/Tile";
import { useState } from "react";
import ListItemChip from "./ListItemChip";
import TextBlock from "./Layout/TextBlock";
import ListItemChips from "./ListItemChips";
import TileContent from "./Tiles/TileContent";
import { Job } from "../system/Domain";
import { Check, Close } from "@mui/icons-material";
import { Box, CircularProgress } from "@mui/material";
import useAsyncEffect from "../system/useAsyncEffect";
import TextGroup from "../Pages/VehicleSales/TextGroup";

interface WebmobileRun {
	should: boolean;
	executionDate?: string;
	success: boolean;
}

interface JobRunInformation {
	lastRun: WebmobileRun;
	earlyRun: WebmobileRun;
	lateRun: WebmobileRun;
}

const WebmobileIndicator = () => {
	const [state, setState] = useState<JobRunInformation>();

	useAsyncEffect(async (config) => {
		const { data: jobs } = await axios.get<Job[]>("/api/sales/jobs", {
			...config,
			params: {
				types: "Ald.Logistics.Jobs.SyncWebMobileSalesJob",
				skip: 0,
				take: 20,
			},
		});

		const now = moment.tz("Europe/Berlin");
		const today = now.clone().startOf("d");
		const secondStart = today.clone().add(17, "h");

		const earlyRuns = jobs.filter((f) => moment(f.dateCreated) > today && moment(f.dateCreated) < secondStart);
		const lateRuns = jobs.filter((f) => moment(f.dateCreated) > secondStart);

		const earlyRun = earlyRuns.filter((f) => f.status === "Completed")[0] ?? earlyRuns[0];
		const lateRun = lateRuns.filter((f) => f.status === "Completed")[0] ?? lateRuns[0];

		const isAfterFirstRun = now.isAfter(today.clone().add(9, "hours"));
		const isAfterSecondRun = now.isAfter(today.clone().add(17, "hours"));

		const pastJob = jobs.filter((f) => f.status === "Completed")[0];

		const newState = {
			lastRun: {
				should: true,
				executionDate: pastJob?.dateCreated,
				success: pastJob?.status === "Completed",
			},
			earlyRun: {
				should: isAfterFirstRun,
				executionDate: earlyRun?.dateCreated,
				success: earlyRun?.status === "Completed",
			},
			lateRun: {
				should: isAfterSecondRun,
				executionDate: lateRun?.dateCreated,
				success: lateRun?.status === "Completed",
			},
		};

		setState(newState);
	}, []);

	return (
		<>
			{!state && <CircularProgress size={48} />}
			{state && (
				<Tile>
					<TileContent>
						<TextGroup title="Webmobil Übertragung">
							<TextBlock
								secondary="Letzte erfolgreiche Übertragung"
								primary={
									state.lastRun ? moment(state.lastRun.executionDate).format("DD.MM.YYYY HH:mm") : "-"
								}
							/>
						</TextGroup>
						<Box mt={1}>
							<ListItemChips>
								<JobIndicatorChip run={state.earlyRun} />
								<JobIndicatorChip run={state.lateRun} />
							</ListItemChips>
						</Box>
					</TileContent>
				</Tile>
			)}
		</>
	);
};

const JobIndicatorChip = (props: { run: WebmobileRun }) => {
	const color = !props.run.should ? "grey" : props.run.success ? "primary" : "error";
	const label = props.run.executionDate ? moment(props.run.executionDate).format("HH:mm") : "-";

	return (
		<ListItemChip
			label={label}
			icon={props.run.should && props.run.success ? <Check /> : <Close />}
			color={color}
		/>
	);
};

export default WebmobileIndicator;
