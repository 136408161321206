import { useState } from 'react';
import { useHistory } from 'react-router';
import Layout from "../../../Components/Layout/Layout";
import { Box, CircularProgress } from "@mui/material";
import useQuery, { IQuery } from "../../../system/useQuery";
import useAsyncEffect from "../../../system/useAsyncEffect";
import { TransportPricing } from "../../../system/Domain";
import axios from "axios";
import FetchNextButton from "../../../Components/FetchNextButton";
import TransportPricingsTable from "./TransportPricingsTable";
import TransportPricingsSidebar from "./TransportPricingsSidebar";
import Search from "../../../Components/Search";
import AdminSubMenu from "../AdminSubMenu";
import AdminNavigation from 'Navigation/AdminNavigation';
import selectCompany from './selectCompany';
import parseContentDispositionFilename from 'system/parseContentDispositionFilename';
import fileDownload from 'js-file-download';
import uploadTransportPricing from './uploadTransportPricing';

export interface TransportPricingQuery extends IQuery {
	sort: string
	search: string
}

const TransportPricingsPage = () => {
	const history = useHistory();
	const take = 20;
	const [query, setQuery, fetchNext, resetQuery] = useQuery<TransportPricingQuery>('transportPricingQuery', {
		skip: 0,
		search: "",
		take: take,
		sort: "name:desc"
	});
	const [companyPricings, setCompanyPricings] = useState<string[]>([]);
	const [isFetching, setIsFetching] = useState(false);
	const [transportPricings, setTransportPricings] = useState<TransportPricing[]>();
	const [refresh, setRefresh] = useState(false);

	useAsyncEffect(async (cancelToken) => {
		setIsFetching(true);

		try {
			const { data: vs } = await axios.get<TransportPricing[]>(`/api/transport-pricing/companies`, {
				params: query,
				cancelToken: cancelToken.getAxiosToken()
			});

			if (query.skip === 0) {
				const existing = vs.map(tc => tc.company?.name);
				setCompanyPricings(existing);
			}

			if (query.skip > 0) {
				setTransportPricings(o => [
					...(o ?? []),
					...vs
				]);
			} else {
				setTransportPricings(vs);
			}
		} finally {
			setIsFetching(false);
			setRefresh(false);
		}
	}, [query, refresh]);

	const exportAll = async () => {
		const exportParams = { ...query, output: "xlsx" };

		const response = await axios.get(`/api/transport-pricing/companies`, {
			responseType: "blob",
			params: exportParams
		});

		const fileName = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, fileName);
	};

	const upload = async () => {
		await uploadTransportPricing();
		setRefresh(true);
	};

	const addNew = async () => {
		const company = await selectCompany({ priceCompanies: companyPricings, okButtonTitle: "Fortfahren" });
		if (company) {
			history.push(`/transport-pricing/${company}/new`);
		}
	};

	return (
		<Layout
			title="Überführungskosten"
			disableOverlay
			navigation={<AdminNavigation />}
			subMenu={<AdminSubMenu />}
			sidebar={<TransportPricingsSidebar
				query={query}
				setQuery={setQuery}
				resetQuery={resetQuery}
				export={exportAll}
				upload={upload}
				addNew={addNew}
			/>}
		>
			<Box mb={2}>
				<Search
					value={query.search}
					realtime={false}
					onSearch={(value) => value !== query.search && setQuery(g => ({
						...g,
						search: value
					}))}
				/>
			</Box>
			<Box mt={2}>
				{!transportPricings && (
					<CircularProgress size={48} />
				)}
				{transportPricings && (
					<>
						<TransportPricingsTable
							setTransportPricing={setTransportPricings}
							transportPricing={transportPricings ?? []}
						/>
						<FetchNextButton
							hidden={transportPricings.length < take + query.skip}
							mb={3}
							onNext={fetchNext}
							disabled={isFetching}
						/>
					</>
				)}
			</Box>
		</Layout>
	);
};

export default TransportPricingsPage;
