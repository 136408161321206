import { useState } from "react";
import Layout from "../../Components/Layout/Layout";
import useAxiosEffect from "../../system/useAxiosEffect";
import axios from "axios";
import Tile from "../../Components/Tiles/Tile";
import List from "../../Components/List";
import DadArrivalTransmissionListItem from "./DadArrivalTransmissionListItem";
import { Grid, Box, CircularProgress } from "@mui/material";
import BatchFilesTile from "../VehicleSales/BatchFilesTile";
import DadArrivalTransmissionsSidebar from "./DadArrivalTransmissionsSidebar";
import Search from "../../Components/Search";
import OrdersNavigation from "Navigation/OrdersNavigation";
import { ArrivalTransmissionNote, TransportOrder, VehicleReference } from "../../system/Domain";
import ListItemContent from "../../Components/ListItemContent";
import useChannel from "../../system/useChannel";
import useEvent from "../../system/useEvent";
import useQuery, { IQuery } from "system/useQuery";

export type VehicleReferenceWithDadNotes = VehicleReference & {
	businessLine: string;
	note: ArrivalTransmissionNote;
};

export interface ArrivalTransmissionQuery extends IQuery {
	compound?: string;
	fullText?: string;
}

const DadArrivalTransmissionsLayout = () => {
	const [transportOrders, setTransportOrders] = useState<TransportOrder[]>();
	const [vehiclesWithNotes, setVehiclesWithNotes] = useState<VehicleReferenceWithDadNotes[]>();

	const [query, setQuery, fetchNext, resetQuery] = useQuery<ArrivalTransmissionQuery>("arrivalTransmissionQuery", {
		skip: 0,
		take: 20,
		fullText: "",
		compound: undefined,
	});

	const [loadingOrders, reloadOrders] = useAxiosEffect(
		async (config) => {
			const { data } = await axios.get<TransportOrder[]>(`/api/arrivaltransmissions/dad/search`, {
				...config,
				params: {
					...query,
				},
			});
			setTransportOrders(data);
		},
		[query],
	);

	const [loadingVehiclesWithNotes, loadVehiclesWithNotes] = useAxiosEffect(
		async (config) => {
			const { data } = await axios.get<VehicleReferenceWithDadNotes[]>(`/api/arrivaltransmissions/notes`, {
				...config,
				params: {
					...query,
				},
			});
			setVehiclesWithNotes(data);
		},
		[query],
	);

	useChannel("batch-files");

	useEvent("batch-file:created", async (data) => {
		if (data.type === "DadArrivalTransmission") {
			await reloadOrders();
			await loadVehiclesWithNotes();
		}
	});

	useEvent("batch-file:updated", async (data) => {
		if (data.type === "DadArrivalTransmission") {
			await reloadOrders();
			await loadVehiclesWithNotes();
		}
	});

	return (
		<Layout
			title="Packlisten"
			navigation={<OrdersNavigation />}
			sidebar={<DadArrivalTransmissionsSidebar query={query} setQuery={setQuery} resetQuery={resetQuery} />}
		>
			<Grid container spacing={6}>
				<Grid item xs={12} md={6}>
					<Box mb={4}>
						<Tile title="Notizen">
							<List>
								{!loadingVehiclesWithNotes && vehiclesWithNotes?.length === 0 && (
									<ListItemContent>Keine Einträge vorhanden</ListItemContent>
								)}
								{!loadingVehiclesWithNotes &&
									vehiclesWithNotes &&
									vehiclesWithNotes.map((v) => (
										<DadArrivalTransmissionListItem key={v.id} vehicle={v} />
									))}
								{loadingVehiclesWithNotes && <CircularProgress size={48} />}
							</List>
						</Tile>
					</Box>
					<Box>
						<Tile title="Transportaufträge">
							<List>
								<Search onSearch={(s) => setQuery((q) => ({ ...q, fullText: s }))} realtime />
								{!loadingOrders && transportOrders?.length === 0 && (
									<ListItemContent>Keine Einträge vorhanden</ListItemContent>
								)}
								{!loadingOrders &&
									transportOrders &&
									transportOrders.map((o) => (
										<DadArrivalTransmissionListItem key={o.id} transportOrder={o} />
									))}
								{loadingOrders && <CircularProgress size={48} />}
							</List>
						</Tile>
					</Box>
				</Grid>
				<Grid item xs={12} md={6}>
					<BatchFilesTile
						title="Packlisten"
						query={{
							types: ["DadArrivalTransmission"],
						}}
					/>
				</Grid>
			</Grid>
		</Layout>
	);
};

export default DadArrivalTransmissionsLayout;
