import { Box } from "@mui/material";
import axios from "axios";
import List from "Components/List";
import Search from "Components/Search";
import Tile from "Components/Tiles/Tile";
import { useState } from "react";
import useAxiosEffect from "system/useAxiosEffect";
import { LeasmanAddress, Vehicle } from "../../system/Domain";
import VehicleListItem from "./VehicleListItem";

interface Props {
	customer: LeasmanAddress;
}

export default ({ customer }: Props) => {
	const [vehicles, setVehicles] = useState<Vehicle[]>();
	const [fullText, setFullText] = useState<string>();

	useAxiosEffect(
		async (config) => {
			if (!customer) {
				setVehicles([]);
				return;
			}

			const { data } = await axios.post<Vehicle[]>(
				`/api/vehicles/search`,
				{
					perspective: "all",
					// tenants: [customer.tenant],
					leasingCustomerId: customer.addressId,
					fullText,
				},
				config,
			);

			setVehicles(data);
		},
		[customer, fullText],
	);

	return (
		<Tile title="Fahrzeuge">
			<Search onSearch={(v) => setFullText(v)} realtime autoFocus disableEnter />
			<Box pt={2}>
				<List>{!!vehicles && vehicles.map((v) => <VehicleListItem key={v.id} vehicle={v} />)}</List>
			</Box>
		</Tile>
	);
};
