import LineList from "../../Components/LineList";
import { Block, Check } from "@mui/icons-material";
import PaperTable from "../../Components/PaperTable";
import TextBlock from "../../Components/Layout/TextBlock";
import HttpMethodPill from "../../Components/HttpMethodPill";
import { ActionSecurityDocumentation } from "./SecurityDocumentation";
import { Box, CircularProgress, TableBody, TableHead, TableRow, Tooltip } from "@mui/material";
import { TableCell, Colgroup } from "../../Components/BreakpointStyledComponents";

interface Props {
	actionSecurityDocumentationLines: ActionSecurityDocumentation[]
	isLoading: boolean
}

const SecurityDocumentationTable = (props: Props) => {
	return (
		<PaperTable>
			<Colgroup xl>
				<col width="auto" />
				<col width="33%" />
				<col width="33%" />
				<col width="33%" />
			</Colgroup>
			<Colgroup lg xl>
				<col width="auto" />
				<col width="auto" />
			</Colgroup>
			<Colgroup xs md>
				<col width="100%" />
			</Colgroup>

			<TableHead>
				<TableRow>
					<TableCell>Verb</TableCell>
					<TableCell>Action</TableCell>
					<TableCell lg xl>Roles</TableCell>
					<TableCell xl>Description</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{props.actionSecurityDocumentationLines && !props.isLoading && props.actionSecurityDocumentationLines.map((line, i) => (
					<TableRow
						key={i}
						hover
						sx={{ width: "100%" }}
					>
						<TableCell>
							<HttpMethodPill httpMethod={line.httpMethod} />
						</TableCell>
						<TableCell>
							<Box sx={{ display: 'flex', flexDirection: "row", justifyContent: "space-between", flexBasis: "100%", flexWrap: "wrap" }}>
								<TextBlock
									primary={line.relativePath}
									secondary={line.actionName}
								/>
								<Box sx={{
									display: "flex",
									marginLeft: 2.5
								}}>
									{line.allowAnonymous && (
										<Tooltip title="Allow annonymous">
											<Check color="secondary" />
										</Tooltip>
									)}
									{!line.allowAnonymous && (
										<Tooltip title="Allow annonymous">
											<Block color="primary" />
										</Tooltip>
									)}
								</Box>
							</Box>
							<Box sx={{ display: { xs: "contents", lg: "none" } }}>
								<LineList lines={line.roles} />
								<Box sx={{ display: { xs: "contents", xl: "none" } }}>
									<LineList
										lines={[line.description ?? "?", ...line.roleDescriptions]}
										highlightFirst />
								</Box>
							</Box>
						</TableCell>
						<TableCell lg xl>
							<LineList lines={line.roles} />
							<Box sx={{ display: { xs: "contents", xl: "none" } }}>
								<LineList
									lines={[line.description ?? "?", ...line.roleDescriptions]}
									highlightFirst />
							</Box>
						</TableCell>

						<TableCell xl>
							<LineList
								lines={[line.description ?? "?", ...line.roleDescriptions]}
								highlightFirst
							/></TableCell>
					</TableRow>
				))}
				{props.isLoading && (
					<TableRow>
						<TableCell
							colSpan={7}
							sx={{ textAlign: "center" }}
						>
							<CircularProgress size={48} />
						</TableCell>
					</TableRow>
				)}
				{props.actionSecurityDocumentationLines.length === 0 && !props.isLoading && (
					<TableRow>
						<TableCell
							colSpan={7}
							sx={{ textAlign: "center" }}>
							Daten noch nicht geladen
						</TableCell>
					</TableRow>
				)}
			</TableBody>
		</PaperTable>
	);
};

export default SecurityDocumentationTable;
