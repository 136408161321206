import RegistrationDocumentsNavigation from 'Navigation/RegistrationDocumentsNavigation';
import Layout from "../../../Components/Layout/Layout";
import Imports from "../../Imports/Imports";

const VehicleRegistrationDocumentUnregistrationImports = () => {
	return (
		<Layout
			title="Abmeldungen"
			navigation={<RegistrationDocumentsNavigation />}
		>
			<Imports importType="VehicleRegistrationDocumentUnregistrationsImport" />
		</Layout>
	);
};

export default VehicleRegistrationDocumentUnregistrationImports;
