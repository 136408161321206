import { UnregistrationOrder } from "../../../system/Domain";
import TextBlock from "../../../Components/Layout/TextBlock";
import { Chip } from "@mui/material";
import { blue, green, orange, red } from "@mui/material/colors";
import moment from "moment";
import dateFormats from "../../../system/dateFormats";

interface Props {
	unregistrationOrder : UnregistrationOrder
}

export const unregistrationOrderStatusSettings = {
	"Created": {
		label: "Erstellt",
		backgroundColor: orange[500],
		date: (unregistrationOrder : UnregistrationOrder) : string => moment(unregistrationOrder.dateCreated).format(dateFormats.dateTime)
	},
	"Cancelled": {
		label: "Storniert",
		backgroundColor: red[500],
		date: (unregistrationOrder : UnregistrationOrder) : string => moment(unregistrationOrder.dateCancelled).format(dateFormats.dateTime)
	},
	"Processed": {
		label: "Verarbeitet",
		backgroundColor: green[500],
		date: (unregistrationOrder : UnregistrationOrder) : string => moment(unregistrationOrder.dateProcessed).format(dateFormats.dateTime)
	},
	"Completed": {
		label: "Abgeschlossen",
		backgroundColor: blue[500],
		date: (unregistrationOrder: UnregistrationOrder) : string => moment(unregistrationOrder.dateCompleted).format(dateFormats.dateTime)
	}
};

const UnregistrationOrderStatusPill = (props : Props) => {
	const setting = unregistrationOrderStatusSettings[props.unregistrationOrder.status];

	return (
		<TextBlock
			primary={
				<Chip
					label={setting.label}
					size="small"
					style={{ backgroundColor: setting.backgroundColor }}
				/>
			}
			secondary={
				setting.date(props.unregistrationOrder)
			}
		/>
	);
};

export default UnregistrationOrderStatusPill;
