import { Box, BoxProps, useTheme } from "@mui/material";
import { PropsWithChildren } from "react";

export interface StackProps extends BoxProps {
	spacing?: number;
	direction?: "vertical" | "horizontal";
}

export default ({ spacing = 0, direction = "vertical", children, ...rest }: PropsWithChildren<StackProps>) => {
	const theme = useTheme();

	return (
		<Box
			{...rest}
			display="flex"
			flexDirection={direction === "vertical" ? "column" : "row"}
			sx={{
				gap: theme.spacing(spacing),
			}}
		>
			{children}
		</Box>
	);
};
