import { useState } from "react";
import Layout from "../../Components/Layout/Layout";
import { useParams } from "react-router";
import { useAsync } from "react-use";
import axios from "axios";
import { Vehicle } from "../../system/Domain";
import { Typography } from "@mui/material";
import { Redirect } from "react-router-dom";

const VehicleAssessmentsPage = () => {
	const { vin } = useParams<{ vin : string }>();
	const [error, setError] = useState<string | null>(null);

	const { value: vehicle, loading } = useAsync(async () => {
		if (!vin) {
			return null;
		}

		const { data: vehicles } = await axios.get<Vehicle[]>(`/api/vehicles?vin=${vin}`);

		if (vehicles.length === 0) {
			setError("Kein Fahrzeug gefunden");
			return null;
		}

		if (vehicles.length > 0) {
			return vehicles[vehicles.length - 1];
		}
	}, [vin]);

	return (
		<Layout
			title="Gutachten"
			plateNumber={vehicle?.plateNumber}
			loading={loading}
		>

			{!loading && error && (
				<Typography color="error">{error}</Typography>
			)}
			{vehicle && (
				<Redirect to={`/vehicles/${vehicle.id}/assessments`}/>
			)}
		</Layout>
	);
};

export default VehicleAssessmentsPage;
