import { Box } from "@mui/material";
import TextGroup from "Pages/VehicleSales/TextGroup";
import TextLine from "Pages/VehicleSales/TextLine";
import formatDate from "system/formatDate";
import formatPercentage from "system/formatPercentage";
import formatPrice from "system/formatPrice";
import { LeasmanVehicle, VehicleType } from "../../system/Domain";

interface Props {
	type: VehicleType
	leasmanVehicle?: LeasmanVehicle
}

export default ({ type, leasmanVehicle }: Props) => {
	return (
		<TextGroup title="Preisübersicht">
			<Box mb={1}>
				<TextLine label="Listenpreis" value={formatPrice(type?.costs.listPrice)} />
				<TextLine label="Ausstattungspreis" value={formatPrice(type?.costs.equipmentPrice)} />
			</Box>

			<TextLine label="Lieferdatum" value={formatDate(leasmanVehicle?.order?.dateDelivered)} />
			<TextLine label="Steuersatz" value={formatPercentage(leasmanVehicle?.order?.taxRate)} />
			<TextLine label="Vertragsrestwert" value="N/A" />
		</TextGroup>
	);
}
