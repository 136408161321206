import { Form } from "../system/useForm";
import { Typography, useTheme } from "@mui/material";

export type FormElementLabelProps = {
	text?: string
	error?: boolean
	form?: Form
	name?: string
};

export default (props: FormElementLabelProps) => {
	const theme = useTheme();

	if (props.text === null || props.text === undefined) {
		return null;
	}

	if (props.form !== undefined && props.name !== undefined) {
		const error = !!props.form.errors[props.name];
		return (
			<Typography
				variant="caption"
				color="textSecondary"
				sx={{
					...(error && { color: theme.palette.error.light })
				}}
			>
				{props.text}
			</Typography>
		);
	}

	return (
		<Typography
			variant="caption"
			color="textSecondary"
			sx={{
				...(props.error && { color: theme.palette.error.light })
			}}
		>
			{props.text}
		</Typography>
	);
};
