import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Cancel, Check, Clear, Edit, Info, Reply } from '@mui/icons-material';
import List from '../../../Components/List';
import ListItem from '../../../Components/ListItem';
import ListItemAction from '../../../Components/ListItemAction';
import ListItemActions from '../../../Components/ListItemActions';
import ListItemContent from '../../../Components/ListItemContent';
import { Remarketing, ReturnOrder, TransportOrder } from '../../../system/Domain';
import { DoOperationIndicator, LinkingIndicator, openLink } from './StatusActionTile';

export const calculateAssessmentStatus = (props: AssessmentStatusProps) => {
	const isPreprocessed = props.returnOrder?.assessmentIsPreprocessed;
	const linkedAssessment = props.remarketing?.assessment?.orderId;
	const newAssessment = props.returnOrder?.assessmentOrderId;

	return {
		doAssessment: props.returnOrder?.doAssessment ?? false,
		isPreprocessed: isPreprocessed,
		linkedAssessment: linkedAssessment,
		newAssessment: newAssessment,
		linkingMissmatch: linkedAssessment && linkedAssessment !== newAssessment
	}
};

interface AssessmentStatusProps {
	transportOrder: TransportOrder
	returnOrder?: ReturnOrder
	remarketing?: Remarketing
}

interface Props {
	setDoAssessment: Dispatch<SetStateAction<boolean>>
	setUnlinkAssessment: Dispatch<SetStateAction<boolean>>
	setRelinkAssessment: Dispatch<SetStateAction<boolean>>
	setSyncReturnOrder: Dispatch<SetStateAction<boolean>>
}

const AssessmentStatusActionTile = (props: Props & AssessmentStatusProps) => {
	const stats = calculateAssessmentStatus(props);

	const [doAssessment, setDoAssessment] = useState(stats.doAssessment);
	const [unlinkAssessment, setUnlinkAssessment] = useState(false);
	const [relinkAssessment, setRelinkAssessment] = useState(false);
	const [syncReturnOrder, setSyncReturnOrder] = useState(false);
	const canDoAssessment = !stats.linkedAssessment || unlinkAssessment;

	useEffect(() => {
		props.setDoAssessment(doAssessment);
	}, [
		doAssessment
	]);

	useEffect(() => {
		props.setUnlinkAssessment(unlinkAssessment);
	}, [
		unlinkAssessment
	]);

	useEffect(() => {
		props.setRelinkAssessment(relinkAssessment);
	}, [
		relinkAssessment
	]);

	useEffect(() => {
		props.setSyncReturnOrder(syncReturnOrder);
	}, [
		syncReturnOrder
	]);

	const linkReturnOrderAssessment = () => openLink(`${window.origin}/orders/assessments/${props.returnOrder?.assessmentOrderId}`);
	const linkRemarketingAssessment = () => openLink(`${window.origin}/orders/assessments/${props.remarketing?.assessment?.orderId}`);

	return (
		<List>
			{stats.isPreprocessed && (
				<>
					<ListItem color={(stats.linkingMissmatch || !stats.linkedAssessment) ? "primary" : "success"}>
						<ListItemActions>
							<ListItemAction
								icon={<Reply />}
								onClick={linkReturnOrderAssessment}
							/>
						</ListItemActions>
						<ListItemContent>
							Gutachten wurde bereits beauftragt
						</ListItemContent>
					</ListItem>
					{!stats.linkedAssessment && (
						<RelinkIndicator
							{...props}
							relink={relinkAssessment}
							setRelink={setRelinkAssessment}
						/>
					)}
					{stats.linkingMissmatch && doAssessment && (
						<LinkingIndicator
							{...props}
							link={linkRemarketingAssessment}
							changeLink={syncReturnOrder}
							setChangeLink={setSyncReturnOrder}
							captionIsLinked="Ein anderes Gutachten ist mit dem Fahrzeug verlinkt"
							captionChangeLink="Anderes Gutachten wird mit der Rückholung verlinkt."
						/>
					)}
				</>
			)}

			{!stats.isPreprocessed && (
				<>
					{stats.doAssessment &&
						<DoOperationIndicator
							{...props}
							doOperation={doAssessment}
							setDoOperation={setDoAssessment}
							captionDoOperation="Gutachten wird beauftragt"
							captionNotDoOperation="Gutachten wird nicht beauftragt"
						/>
					}

					{stats.linkedAssessment && doAssessment && (
						<LinkingIndicator
							{...props}
							link={linkRemarketingAssessment}
							changeLink={unlinkAssessment}
							setChangeLink={setUnlinkAssessment}
							captionIsLinked="Ein anderes Gutachten ist mit dem Fahrzeug verlinkt"
							captionChangeLink="Das mit dem Fahrzeug verbundene Gutachten wird entlinkt" />
					)}

					{!stats.doAssessment && (
						<ListItem color="info">
							<ListItemActions>
								<ListItemAction icon={<Info />} />
							</ListItemActions>
							<ListItemContent>
								Gutachten kann nur nachträglich manuell beauftragt werden.
							</ListItemContent>
						</ListItem>
					)}

					{doAssessment && (
						<ListItem color={canDoAssessment ? "success" : "error"}>
							<ListItemActions>
								<ListItemAction
									icon={
										<>
											{!canDoAssessment && (
												<Cancel />
											)}
											{canDoAssessment && (
												<Check />
											)}
										</>
									}
								/>
							</ListItemActions>
							<ListItemContent>
								{!canDoAssessment && (
									<>Gutachten kann nicht beauftragt werden</>
								)}
								{canDoAssessment && (
									<>Gutachten kann beauftragt werden</>
								)}
							</ListItemContent>
						</ListItem>
					)}
				</>
			)}
		</List>
	);
};

interface RelinkIndicatorProps {
	relink: boolean
	setRelink: Dispatch<SetStateAction<boolean>>
}

export const RelinkIndicator = (props: RelinkIndicatorProps) => {
	const [relink, setRelink] = useState(props.relink);
	const [editMode, setEditMode] = useState(false);
	const [relinkPreEdit, setDoOperationPreEdit] = useState(props.relink);

	return (
		<ListItem color={relink ? "primary" : "warning"}>
			<ListItemActions>
				{!editMode && (
					<ListItemAction
						icon={<Edit />}
						onClick={() => {
							setDoOperationPreEdit(relink);
							setEditMode(true)
						}}
					/>
				)}
				{editMode && (
					<>
						<ListItemAction
							icon={<Check />}
							onClick={() => {
								props.setRelink(relink);
								setEditMode(false)
							}}
						/>
						<ListItemAction
							icon={<Clear />}
							onClick={() => {
								setDoOperationPreEdit(relinkPreEdit);
								setEditMode(false)
							}}
						/>
					</>
				)}
			</ListItemActions>
			<ListItemContent
				onClick={editMode ? () => setRelink(g => !g) : undefined}
			>
				{relink && (
					<>Vorbeauftragtes Gutachten wird neu verlinkt</>
				)}
				{!relink && (
					<>Vorbeauftragtes Gutachten nicht verlinkt</>
				)}
			</ListItemContent>
		</ListItem>
	);
};

export default AssessmentStatusActionTile;
