import FormText from "./FormText";
import Thumbnail from "./Thumbnail";
import FormUpload from "./FormUpload";
import FormSelect from "./FormSelect";
import { Form } from "../system/useForm";
import { Damage } from "../system/Domain";
import { Add, Delete, Done, ExpandMore } from "@mui/icons-material";
import { useCallback, useLayoutEffect, useRef, useState } from "react";
import {
	Accordion,
	AccordionActions,
	AccordionDetails,
	AccordionSummary,
	alpha,
	Grid,
	Divider,
	Typography,
	Button,
	Box,
	useTheme,
} from "@mui/material";

export type FormDamagesProps = {
	name: string;
	form: Form;
	damageComponents: any;
	damageTypes: any;
};

export default ({ name, form, damageComponents, damageTypes }: FormDamagesProps) => {
	const theme = useTheme();
	const styles = {
		nr: {
			color: alpha(theme.palette.text.primary, 0.5),
		},
		delete: {},
		summaryContent: {
			alignItems: "center",
		},
		deltaMode: {
			cursor: "default",
		},
		details: {
			backgroundColor: alpha(theme.palette.background.paper, 0.5),
		},
		title: {
			"& span": {
				marginLeft: theme.spacing(0.5),
			},
		},
		add: {
			color: alpha(theme.palette.secondary.main, 0.5),
			"&:hover": {
				color: theme.palette.secondary.main,
			},
		},
		panelAnchor: {
			position: "relative",
			top: -theme.spacing(4),
		},
		thumbnail: {
			position: "relative",
			width: 50,
			height: 50,
			marginRight: theme.spacing(1),
			backgroundColor: alpha(theme.palette.common.white, 0.1),
		},
		description: {
			display: "flex",
			flexDirection: "column",
		},
		removed: {
			textDecoration: "line-through",
			opacity: 0.5,
		},
	};
	const [activePanel, setActivePanel] = useState<number | null>(null);

	const damages: Damage[] = form.getValue(name) || [];
	const deltaDamages: Damage[] = form.getDeltaValue(name) || [];
	const togglePanel = (p: number) => setActivePanel(activePanel === p ? null : p);

	const add = useCallback(() => {
		const newDamages: Damage[] = [
			...damages,
			{
				component: null,
				type: null,
			},
		];
		form.setValue(name, newDamages, true);
		setActivePanel(newDamages.length - 1);
	}, [damages]);

	const remove = useCallback(
		(damage: Damage) => {
			const oldIndex = damages.indexOf(damage);
			if (oldIndex === -1) {
				return;
			}

			const newDamages = [...damages.filter((d) => d !== damage)];
			form.setValue(name, newDamages);
			setActivePanel(null);
		},
		[damages],
	);

	const ref = useRef<any>();
	useLayoutEffect(() => {
		if (!ref.current) {
			return;
		}

		const ui = ref.current.querySelector(`#panel-anchor-${activePanel}`);
		if (ui) {
			window.setTimeout(() => {
				ui.scrollIntoView({ behavior: "smooth" });
			}, theme.transitions.duration.standard + 100);
		}
	}, [activePanel]);

	let entries = damages.map((d, index) => {
		const isNew = index >= deltaDamages.length;

		let delta = isNew ? null : deltaDamages[index];
		if (
			delta &&
			delta.component === d.component &&
			delta.type === d.type &&
			delta.remarks === d.remarks &&
			((!delta.picture && !d.picture) || (delta.picture && d.picture && delta.picture.hash === d.picture.hash))
		) {
			delta = null;
		}

		return {
			index,
			damage: d,
			isNew,
			isRemoved: false,
			delta,
		};
	});

	if (deltaDamages.length > damages.length) {
		entries = entries.concat(
			deltaDamages.slice(damages.length).map((d, p) => ({
				index: damages.length + p,
				damage: d,
				isNew: false,
				isRemoved: true,
				delta: null,
			})),
		);
	}

	if (form.deltaMode) {
		entries = entries.filter((e) => e.isNew || e.isRemoved || e.delta);
	}

	return (
		<Grid item xs={12} ref={ref}>
			{entries.map((e) => (
				<Accordion
					key={e.index}
					expanded={activePanel === e.index || (form.deltaMode && !!e.delta)}
					onChange={() => !form.deltaMode && togglePanel(e.index)}
				>
					<AccordionSummary
						sx={{
							content: styles.summaryContent,
							expanded: styles.deltaMode,
						}}
						expandIcon={!form.deltaMode && <ExpandMore />}
					>
						<Box id={`panel-anchor-${e.index}`} sx={styles.panelAnchor} />
						{e.damage.picture && e.damage.picture.hash && (
							<Box sx={[styles.thumbnail, e.isRemoved && styles.removed]}>
								<Thumbnail
									asyncUrl={`/api/storage/${e.damage.picture.hash}/thumbnail`}
									downloadUrl={`/api/storage/${e.damage.picture.hash}`}
									width={50}
									height={50}
								/>
							</Box>
						)}
						<Box sx={[styles.description, e.isRemoved && styles.removed]}>
							<Typography variant="subtitle2" sx={styles.title}>
								<Box component="span" sx={styles.nr}>
									#{e.index + 1}{" "}
								</Box>
								{e.damage.component && <span>{damageComponents[e.damage.component]}</span>}
								{e.damage.component && e.damage.type && <span>({damageTypes[e.damage.type]})</span>}
							</Typography>
							<Typography variant="caption">{e.damage.remarks}</Typography>
						</Box>
					</AccordionSummary>
					<AccordionDetails sx={styles.details}>
						<Grid container spacing={4}>
							<FormSelect
								name={`${name}.${e.index}.component`}
								label="Bauteil"
								form={form}
								choices={damageComponents}
								sort
								options={{
									required: true,
								}}
							/>
							<FormSelect
								name={`${name}.${e.index}.type`}
								label="Schadenart"
								form={form}
								choices={damageTypes}
								sort
								options={{
									required: true,
								}}
							/>
							<FormText name={`${name}.${e.index}.remarks`} label="Bemerkungen" form={form} />
							<FormUpload
								name={`${name}.${e.index}.picture`}
								label="Foto"
								form={form}
								previewUrl={`/static/vehiclePictures.${name}.jpg` }
								fullWidth
							/>
						</Grid>
					</AccordionDetails>
					{!form.readOnly && (
						<>
							<Divider />
							<AccordionActions>
								<Button
									size="small"
									color="secondary"
									onClick={() => remove(e.damage)}
									startIcon={<Delete />}
								>
									Eintrag entfernen
								</Button>
								<Button
									size="small"
									color="primary"
									onClick={() => setActivePanel(null)}
									startIcon={<Done />}
								>
									Ok
								</Button>
							</AccordionActions>
						</>
					)}
				</Accordion>
			))}
			{!form.readOnly && (
				<Accordion expanded={false} onChange={add}>
					<AccordionSummary sx={[styles.add, styles.summaryContent]}>
						<Button size="small" color="primary" startIcon={<Add />}>
							Neuen Eintrag hinzufügen
						</Button>
					</AccordionSummary>
				</Accordion>
			)}
		</Grid>
	);
};
