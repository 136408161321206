import { useState } from "react";

export interface GoogleMapsOptions {
	apiKey? : string
	version? : string
	libraries? : string[]
};

const CALLBACK_NAME = "ald_googlemaps_cb";
const GLOBAL_PROMISE = "ald_googlemaps_promise";

const apiKeys = {
	dev: "AIzaSyCv4-Yxl589GKrY3d3P9_Vi22PYGgXSm1U",
	stage: "AIzaSyAA4CVlhRXCDoR1F5W_6Au0DYjldZcIrHU",
	prod: "AIzaSyB2Svawhcaiee5qWb28QIqE45aze2NpD0M"
};

let defaultApiKey = apiKeys.dev;
if (window.location.host.indexOf("logistics.dev.ald.digital") !== -1) {
	defaultApiKey = apiKeys.dev;
} else if (window.location.host.indexOf("logistics.stage.ald.digital") !== -1) {
	defaultApiKey = apiKeys.stage;
} else if (window.location.host.indexOf("logistics.ald.digital") !== -1) {
	defaultApiKey = apiKeys.prod;
}

(window as any).googleApiKey = defaultApiKey;

export default (options? : GoogleMapsOptions) => {
	let global_promise : Promise<void> = (window as any)[GLOBAL_PROMISE];

	const [isLoaded, setIsLoaded] = useState(false);

	if (!global_promise) {
		global_promise = (window as any)[GLOBAL_PROMISE] = new Promise<void>((resolve, reject) => {
			(window as any)[CALLBACK_NAME] = () => {
				resolve();
			};

			const apiKey = options?.apiKey || defaultApiKey;

			const parameters : Array<string> = [
				`callback=${CALLBACK_NAME}`,
				`key=${apiKey}`
			];

			if (options?.version) {
				parameters.push(`v=${options.version}`);
			}

			if (options?.libraries) {
				parameters.push(`libraries=${options.libraries.join(',')}`);
			}

			const script = document.createElement('script');
			script.src = `//maps.googleapis.com/maps/api/js?${parameters.join('&')}`;
			script.async = true;
			document.head.appendChild(script);
		});
	}

	global_promise.then(() => {
		setIsLoaded(true);
	});

	return [isLoaded];
};
