import { setUserMessage } from "../system/reducers/userMessage/actions";
import { useDispatch } from "react-redux";
import { Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { MouseEvent } from "react";

interface Props {
	vin?: string
	showVinWarning?: boolean
}

const VinDisplay = ({ vin, showVinWarning = false }: Props) => {
	const dispatch = useDispatch();
	const theme = useTheme();

	if (!vin) return <></>;

	if (vin.length < 6) {
		return <Box>{vin}</Box>;
	}

	const copy = (e: MouseEvent<HTMLSpanElement, globalThis.MouseEvent>, text: string) => {
		if (e.altKey) {
			e.stopPropagation();
			navigator.clipboard.writeText(text);
			dispatch(setUserMessage(`Text kopiert: ${text}`, "success"));
		}
	};

	return (
		<Box
			sx={{
				display: "flex",
				alignItems: "center",
				whiteSpace: "nowrap",
				flexDirection: "row",
				color: showVinWarning ? theme.palette.error.main : "inherit"
			}}
		>
			<Typography
				onClick={e => copy(e, vin)}
				sx={{ fontSize: "1em" }}
			>
				{vin.substring(0, vin.length - 6)}
				<Box sx={{ display: "inline", fontWeight: "bold" }} onClick={e => copy(e, vin.substring(vin.length - 6))}>
					{vin.substring(vin.length - 6)}
				</Box>
			</Typography>
		</Box>
	);

};

export default VinDisplay;
