import { Button, ButtonProps, IconButton, Tooltip } from "@mui/material";
interface TooltipButtonProps extends ButtonProps {
    title: string
    hideOnDisabled: boolean
    icon?: boolean
}

const TooltipButton = ({ title = "", onClick, hideOnDisabled, icon = false, size = "medium", variant = "outlined", color = "primary", ...rest }: TooltipButtonProps) => {
    if (icon) {
        return <Tooltip title={title}>
            <span>
                <IconButton
                    color={color}
                    disabled={!onClick}
                    onClick={onClick}
                    // @ts-ignore
                    size={size}
                    {...rest}
                />
            </span>
        </Tooltip>
    }

    return (
        <Tooltip title={title}>
            <span>
                <Button
                    variant={variant}
                    size={size}
                    color={color}
                    disabled={!onClick}
                    onClick={onClick}
                    {...rest}
                />
            </span>
        </Tooltip>
    );
}

TooltipButton.defaultProps = {
    hideOnDisabled: false
};

export default TooltipButton; 