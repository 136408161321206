import { ListItemAvatar, ListItemText, ListItem } from "@mui/material";

export default ({ primary, secondary, icon, onClick }) => {
	return (
		<ListItem component="li" button onClick={onClick}>
			<ListItemText primary={primary} secondary={secondary} />
			<ListItemAvatar style={{ textAlign: "right" }}>
				{icon}
			</ListItemAvatar>
		</ListItem>
	);
};
