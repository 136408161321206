import { Dispatch, SetStateAction } from 'react';

import { GetApp, RotateLeft } from '@mui/icons-material';
import {
	Box, Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select
} from '@mui/material';

import Sort, { SortItem } from 'Components/Sort';
import useVehicleServiceTypes from '../../system/useVehicleServiceTypes';
import useVehicleServiceExport from '../VehicleServices/useVehicleServiceExport';
import { VehicleServiceQuery } from '../VehicleServices/useVehicleServices';
import { BillingProps } from './useBilling';

export interface BillingServicesSidebarProps extends BillingProps {
	query: VehicleServiceQuery
	setQuery: Dispatch<SetStateAction<VehicleServiceQuery>>
	resetQuery: () => VehicleServiceQuery
}

const sortItems: SortItem[] = [
	{
		title: "Tätigkeit erstellt",
		field: "dateCreated"
	},
	{
		title: "Zugewiesen",
		field: "dateAssigned"
	},
	{
		title: "Umgesetzt",
		field: "dateCompleted"
	}
];

export default ({ billing, query, setQuery, resetQuery }: BillingServicesSidebarProps) => {
	const [vehicleServiceServiceTypes] = useVehicleServiceTypes();
	const [exportState, triggerExport] = useVehicleServiceExport({
		...query,
		billingId: billing.id
	});

	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<Sort
					items={sortItems}
					value={query.sort}
					onChange={sort => setQuery(q => ({
						...q,
						skip: 0,
						sort
					}))}
				/>
			</Grid>
			<Grid item xs={12}>
				<FormControl fullWidth variant="standard">
					<InputLabel>Tätigkeit</InputLabel>
					<Select
						value={query.serviceTypeId || "-"}
						onChange={(e) => {
							let value = e.target.value as string | undefined;

							if (value === "-") {
								value = undefined;
							}

							setQuery(g => ({
								...g,
								skip: 0,
								serviceTypeId: value
							}));
						}}
					>
						<MenuItem value="-">Alle</MenuItem>
						<Box my={2}>
							<Divider />
						</Box>
						{vehicleServiceServiceTypes?.sort((a, b) => a.text.localeCompare(b.text)).map(t => (
							<MenuItem key={t.id} value={t.id}>{t.text}</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<FormControl fullWidth variant="standard">
					<InputLabel>Mandant</InputLabel>
					<Select
						value={query.billingClient || "-"}
						onChange={(e) => {
							let value = e.target.value as string | undefined;

							if (value === "-") {
								value = undefined;
							}

							setQuery(g => ({
								...g,
								skip: 0,
								billingClient: value
							}));
						}}
					>
						<MenuItem value="-">Alle</MenuItem>
						<Box my={2}>
							<Divider />
						</Box>
						{billing.clients.map(c => (
							<MenuItem key={c.name} value={c.name}>{c.name}</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={12}>
				<Divider />
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Button
							variant="outlined"
							startIcon={<GetApp />}
							fullWidth
							disabled={exportState.loading}
							onClick={triggerExport}
							color="info"
						>
							Excel-Export
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Button
							variant="outlined"
							startIcon={<RotateLeft />}
							fullWidth
							onClick={resetQuery}
							color="info"
						>
							Filter zurücksetzen
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}
