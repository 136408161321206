import TextBlock from "../../../Components/Layout/TextBlock";
import Box from "@mui/material/Box";
import { TransportOrder } from "../../../system/Domain";
import OrderStatus from "./TransportOrderStatus";
import VinDisplay from "../../../Components/VinDisplay";

export interface OrderHeaderProps {
	order : TransportOrder
}

export default ({ order } : OrderHeaderProps) => {
	return (
		<Box mb={2}>
			<TextBlock
				flat
				primary={order.vehicle.type?.description}
				secondary={<Box display="flex" alignItems="baseline" pt={0.25}>
					<OrderStatus order={order} showAssignee />
					<Box pl={1}>
						<VinDisplay vin={order.vin} />
					</Box>
				</Box>}
			/>
		</Box>
	);
};
