import moment from "moment";
import useUser from "../../../system/useUser";
import dateFormats from "../../../system/dateFormats";
import TextBlock from "../../../Components/Layout/TextBlock";
import { Note, TransportOrder, UserRole } from "../../../system/Domain";
import { Box, Table, TableBody, TableCell, TableRow, useTheme } from "@mui/material";

export interface OrderNoteListProps {
	order: TransportOrder
}

interface TypedNote extends Note {
	isInternal: boolean
}

export default ({ order }: OrderNoteListProps) => {
	const theme = useTheme();
	const [, , hasRole] = useUser();
	const canSeeInternalNotes = hasRole(UserRole.AldUser);

	if (!order.notes || (canSeeInternalNotes && !order.internalNotes)) {
		return null;
	}

	const setInternal = (note: Note, isInternal: boolean): TypedNote => {
		let t = note as TypedNote;
		t.isInternal = isInternal;
		return t;
	};

	let allNotes: TypedNote[] = order.notes.map(m => setInternal(m, false));

	if (canSeeInternalNotes && order.internalNotes) {
		allNotes = allNotes.concat(order.internalNotes.map(m => setInternal(m, true)));
	}

	const sortedNotes = allNotes.sort((a, b) => moment(b.dateCreated).valueOf() - moment(a.dateCreated).valueOf());

	return (
		<Table>
			<colgroup>
				<col width="25%" />
				<col width="75%" />
			</colgroup>
			<TableBody>
				{sortedNotes.map((n, i) => {
					return (
						<TableRow key={i} sx={{verticalAlign: "top"}}>
							<TableCell>
								<TextBlock
									primary={moment(n.dateCreated).format(dateFormats.dateTime)}
									secondary={moment(n.dateCreated).fromNow()}
								/>
							</TableCell>
							<TableCell>
								<TextBlock
									primary={<Box fontStyle={n.isInternal ? "italic" : "initial"} color={n.isInternal ? theme.palette.secondary.light : undefined}>{n.text}</Box>}
									secondary={n.user.name}
								/>
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		</Table>
	);
};
