import TextBlock from "Components/Layout/TextBlock";
import moment from "moment";
import { ReactElement, useState } from "react";
import { useHistory } from "react-router-dom";
import { CompareArrows } from "@mui/icons-material";
import { Box, Button, Chip, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import * as colors from "@mui/material/colors";

import { ReturnForm } from "../../../../system/Domain";
import { Colgroup } from "../../../../Components/BreakpointStyledComponents";
import dateFormats from "system/dateFormats";

const comparisonBaseStyles = {
	"& td, td:hover": {
		backgroundColor: colors.blueGrey[500],
	},
};

export interface ReturnFormListProps {
	returnForms: ReturnForm[];
	isLoading?: boolean;
	actions?: (returnForm: ReturnForm) => ReactElement;
	showHeader?: boolean;
}

export default ({ returnForms, isLoading, actions, showHeader = true }: ReturnFormListProps) => {
	const history = useHistory();

	const [comparisonBase, setComparisonBase] = useState<ReturnForm>();

	return (
		<Table>
			<Colgroup xs>
				<col style={{ width: "80%" }} />
				<col style={{ width: "20%" }} />
			</Colgroup>
			{showHeader && (
				<TableHead>
					<TableRow>
						<TableCell>Protokoll</TableCell>
						<TableCell>Aktion</TableCell>
					</TableRow>
				</TableHead>
			)}
			<TableBody>
				{returnForms.map((r) => {
					const valid = r.isValid && r.isValid();
					const signed = r.isSigned && r.isSigned();

					let status = "";
					let color = "";

					if (signed) {
						color = colors.green[500];
						status = "Unterschrieben";
					} else {
						color = colors.orange[500];

						if (!valid) {
							status = "In Bearbeitung";
						} else {
							status = "Noch nicht unterschrieben";
						}
					}

					const status_ui = <Chip label={status} size="small" sx={{ backgroundColor: color }} />;
					const title = !r.predecessorId
						? `Protokoll, erstellt durch ${r.createdBy.name}`
						: `Differenzprotokoll, erstellt durch ${r.createdBy.name}`;

					const dateInformation = (
						<span>
							Erstellungsdatum: {moment(r.dateCreated).format(dateFormats.dateTime)} <br />
							{signed && `Abgeschlossen am: ${moment(r.transfer.date).format(dateFormats.dateTime)}`}{" "}
						</span>
					);

					return (
						<TableRow
							hover
							key={r.id}
							sx={comparisonBase === r && comparisonBaseStyles}
							onClick={() =>
								history.push({ pathname: `/orders/transports/${r.orderId}/return-forms/${r.id}` })
							}
						>
							<TableCell>
								<TextBlock primary={title} secondary={dateInformation} />
								<Box mt={2}>{status_ui}</Box>
							</TableCell>
							<TableCell
								sx={{
									verticalAlign: "middle",
									textAlign: "right",
									whiteSpace: "nowrap",
								}}
							>
								{actions && !comparisonBase && actions(r)}
								{signed && (
									<>
										{!comparisonBase &&
											returnForms.find(
												(r2) =>
													r2 !== r &&
													r2.isSigned &&
													r2.isSigned() &&
													moment(r2.dateCreated).isBefore(moment(r.dateCreated)),
											) && (
												<IconButton
													sx={{ padding: 1.5 }}
													onClick={(e) => {
														setComparisonBase(r);
														e.stopPropagation();
													}}
												>
													<CompareArrows />
												</IconButton>
											)}
										{comparisonBase && comparisonBase === r && (
											<Button
												sx={{ padding: 1.5 }}
												color="secondary"
												size="small"
												onClick={(e) => {
													setComparisonBase(undefined);
													e.stopPropagation();
												}}
											>
												Vergleich abbrechen
											</Button>
										)}
										{comparisonBase &&
											comparisonBase !== r &&
											moment(comparisonBase.dateCreated).isAfter(moment(r.dateCreated)) && (
												<Button
													sx={{ padding: 1.5 }}
													color="primary"
													size="small"
													onClick={(e) => {
														history.push(
															`/orders/transports/${comparisonBase.orderId}/return-forms/${comparisonBase.id}/delta/${r.orderId}/${r.id}`,
														);
														e.stopPropagation();
													}}
												>
													Hiermit vergleichen
												</Button>
											)}
									</>
								)}
							</TableCell>
						</TableRow>
					);
				})}
				{isLoading === true && (
					<TableRow>
						<TableCell colSpan={5} sx={{ textAlign: "center" }}>
							<CircularProgress />
						</TableCell>
					</TableRow>
				)}
			</TableBody>
		</Table>
	);
};
