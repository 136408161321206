import FormText from "../../../../Components/FormText";
import FormYesNo from "../../../../Components/FormYesNo";
import { Form } from "../../../../system/useForm";
import TextBlock from "../../../../Components/Layout/TextBlock";
import { Box, Grid, Typography } from "@mui/material";
import FormUpload from "../../../../Components/FormUpload";
import Thumbnail from "Components/Thumbnail";
import { useEffect } from "react";

export default ({ form }: { form: Form }) => {
	const hasNoRegistrationDocument = form.getValue("documentsAndEquipment.registrationDocument") === false && form.deltaMode !== true;
	const plateNumbersFront = form.getValue("documentsAndEquipment.plateNumbersFront");
	const plateNumbersBack = form.getValue("documentsAndEquipment.plateNumbersBack");

	useEffect(() => {
		if (hasNoRegistrationDocument !== true) return;

		const currentValue1 = form.getValue("documentsAndEquipment.registrationDocumentFront");
		if (currentValue1 !== null) {
			form.setValue("documentsAndEquipment.registrationDocumentFront", null);
		}

		const currentValue2 = form.getValue("documentsAndEquipment.registrationDocumentBack");
		if (currentValue2 !== null) {
			form.setValue("documentsAndEquipment.registrationDocumentBack", null);
		}
	}, [hasNoRegistrationDocument]);

	return (
		<>
			<FormYesNo
				name="documentsAndEquipment.registrationDocument"
				label="ZB 1 vorhanden (ehemals Fahrzeugschein)"
				form={form}
				options={{
					required: true
				}}
			/>
			{!hasNoRegistrationDocument && (
				<>
					<FormUpload
						name="documentsAndEquipment.registrationDocumentFront"
						label="ZB 1 Vorderseite"
						form={form}
						previewUrl="/static/vehiclePictures.registrationDocumentFront.jpg"
						options={{
							required: true
						}}
					/>
					<FormUpload
						name="documentsAndEquipment.registrationDocumentBack"
						label="ZB 1 Rückseite"
						form={form}
						previewUrl="/static/vehiclePictures.registrationDocumentBack.jpg"
						options={{
							required: true
						}}
					/>
				</>
			)}
			{hasNoRegistrationDocument && (
				<Grid item xs={12}>
					<TextBlock
						primary={<Box color="red">Ohne ZB1 darf das Fahrzeug nur in Abstimmung mit ALD transportiert werden; bitte Kontakt mit ALD suchen</Box>}
					/>
				</Grid>
			)}
			<FormText
				name="documentsAndEquipment.keys"
				label="Anzahl Schlüssel"
				form={form}
				options={{
					required: true
				}}
				type="number"
			/>
			<FormYesNo
				name="documentsAndEquipment.remoteControl"
				label="Fernbedienung vorhanden (Standheizung)"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormText
				name="documentsAndEquipment.fuelCards"
				label="Anzahl Tankkarten"
				form={form}
				options={{
					required: true
				}}
				type="number"
			/>
			<FormText
				name="documentsAndEquipment.seats"
				label="Anzahl Sitze"
				form={form}
				options={{
					required: true
				}}
				type="number"
			/>
			<FormYesNo
				name="documentsAndEquipment.boardBriefcase"
				label="Bordmappe vorhanden"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormYesNo
				name="documentsAndEquipment.operationManual"
				label="Bedienungsanleitungen"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormYesNo
				name="documentsAndEquipment.serviceBooklet"
				label="Serviceheft vorhanden"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormYesNo
				name="documentsAndEquipment.serviceRecord"
				label="Servicenachweise vorhanden"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormYesNo
				name="documentsAndEquipment.radioCodeCard"
				label="Radio Code Karte"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormYesNo
				name="documentsAndEquipment.radio"
				label="Radio vorhanden"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormYesNo
				name="documentsAndEquipment.navigationDevice"
				label="Navigationsgerät"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormYesNo
				name="documentsAndEquipment.navigationDataMedium"
				label="Navigations DVD/CD/SD"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormText
				name="documentsAndEquipment.plateNumbers"
				label="Anzahl Kennzeichen"
				form={form}
				options={{
					required: true
				}}
				type="number"
			/>
			{plateNumbersFront && (
				<Box sx={{ paddingLeft: 7.5, paddingTop: 7.5 }}>
					<Typography variant="caption" color="textSecondary">
						Kennzeichen Vorderseite
					</Typography>
					<Thumbnail
						width={300}
						height={300}
						asyncUrl={`/api/storage/${plateNumbersFront.hash}/thumbnail`}
						downloadUrl={`/api/storage/${plateNumbersFront.hash}`}
						standalone
					/>
				</Box>)}
			{plateNumbersBack && (
				<Box sx={{ paddingLeft: 7.5, paddingTop: 7.5 }}>
					<Typography variant="caption" color="textSecondary">
						Kennzeichen Rückseite
					</Typography>
					<Thumbnail
						width={300}
						height={300}
						asyncUrl={`/api/storage/${plateNumbersBack.hash}/thumbnail`}
						downloadUrl={`/api/storage/${plateNumbersBack.hash}`}
						standalone
					/>
				</Box>)}
		</>
	);
};
