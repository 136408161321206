import Highcharts from "highcharts";
import { useTheme } from "@mui/styles";
import { useLayoutEffect, useRef } from "react";
import HighchartsMore from "highcharts/highcharts-more";
import SolidGauge from "highcharts/modules/solid-gauge";
import { green, red, yellow } from "@mui/material/colors";

HighchartsMore(Highcharts);
SolidGauge(Highcharts);

const Gauge = ({ value, max, title }) => {
	const canvasRef = useRef();
	const theme = useTheme();
	const darkTheme = theme.palette.mode === "dark";

	useLayoutEffect(() => {
		const chart = Highcharts.chart(canvasRef.current, {
			chart: {
				height: 300,
				type: "solidgauge",
				backgroundColor: "transparent",
			},
			title: {
				y: 40,
				text: title,
				style: {
					color: darkTheme ? theme.palette.grey[200] : theme.palette.grey[800],
				},
			},
			pane: {
				center: ["50%", "65%"],
				startAngle: -90,
				endAngle: 90,
				background: {
					backgroundColor: "transparent",
					innerRadius: "60%",
					outerRadius: "100%",
					shape: "arc",
				},
			},

			credits: {
				enabled: false,
			},

			yAxis: {
				min: 0,
				max: max,
				stops: [
					[0.1, green[500]],
					[0.5, yellow[500]],
					[0.9, red[500]],
				],
				lineWidth: 0,
				tickWidth: 0,
				tickAmount: 2,
				tickInterval: 0,
				minorTickInterval: null,
				labels: {
					y: 16,
				},
			},

			plotOptions: {
				solidgauge: {
					dataLabels: {
						y: 0,
						borderWidth: 0,
						style: {
							color: theme.palette.grey[200],
						},
					},
				},
			},

			series: [
				{
					data: [value],
					dataLabels: {
						format:
							'<div style="text-align:center">' +
							`<span style="font-size:25px;color: ${theme.palette.common.white}">{y}</span><br/>` +
							"</div>",
					},
				},
			],
		});

		return () => {
			chart.destroy();
		};
	}, [value]);

	return <div ref={canvasRef} style={{ width: 300, height: 300, float: "left" }}></div>;
};

export default Gauge;
