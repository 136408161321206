import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { useAsyncFn } from 'react-use';

import { Add } from '@mui/icons-material';

import Sidebar from '../../Components/Sidebar/Sidebar';
import SidebarButton from '../../Components/Sidebar/SidebarButton';
import SidebarGroup from '../../Components/Sidebar/SidebarGroup';
import { Inventory } from '../../system/Domain';

interface Props {
	inventories: Inventory[]
	setInventories: Dispatch<SetStateAction<Inventory[]>>
}

export default ({ inventories, setInventories }: Props) => {
	const [addInventoryState, addInventory] = useAsyncFn(async () => {
		const { data: inventory } = await axios.post<Inventory>(`/api/inventories`);
		setInventories(i => [
			inventory,
			...i
		]);
	}, []);

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarButton
					startIcon={<Add />}
					disabled={addInventoryState.loading}
					onClick={addInventory}
					label="Neue Inventur"
				/>
			</SidebarGroup>
		</Sidebar>
	);
}
