import { Actions } from "./actions";
import { WorkSession } from "../../Domain";
import { CLEAR_WORK_SESSION, SET_WORK_SESSION, SET_WORK_SESSION_CURRENT } from "./actionTypes";

interface State {
	workSession : WorkSession | null
}

const initialState = {
	workSession: null
}

const reducer = (state : State = initialState, action : Actions) : State => {
	const newState = { ...state };

	if (action.type === SET_WORK_SESSION) {
		newState.workSession = action.workSession;
		sessionStorage["work-session"] = newState.workSession.id;
	} else if (action.type === SET_WORK_SESSION_CURRENT) {
		if (newState.workSession) {
			newState.workSession = {
				...newState.workSession,
				current: action.current
			};
		}
	} else if (action.type === CLEAR_WORK_SESSION) {
		newState.workSession = null;
		delete sessionStorage["work-session"];
	}

	return newState;
}

export default reducer;
