import { useState } from "react";
import Select from "../../../Components/Select";
import useCompanies from "../../../system/useCompanies";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ThemedDialog from "../../../Components/ThemedDialog";
import { Box, Button, CircularProgress, DialogTitle, SelectChangeEvent } from "@mui/material";
import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";

interface Props extends ReactConfirmProps<string> {
	priceCompanies: string[]
	okButtonTitle?: string
	dialogTitle?: string
}

const SelectCompanyDialog = ({ show, proceed, cancel, priceCompanies, okButtonTitle, dialogTitle }: Props) => {
	const [allCompanies] = useCompanies();
	const [selectedCompany, setSelectedCompany] = useState<string>();

	const companies = allCompanies?.filter(c => !priceCompanies.some(bn => bn === c.name));

	const changeCompany = (event: SelectChangeEvent) => {
		setSelectedCompany(event.target.value);
	};

	const handleClose = () => {
		cancel();
	};

	return (
		<ThemedDialog open={show} maxWidth="sm" fullWidth onClose={handleClose}>
			<DialogTitle>
				{dialogTitle ?? "Dienstleister auswählen"}
			</DialogTitle>
			<DialogContent>
				{!companies && (
					<CircularProgress size={48} />
				)}
				{companies && (
					<Box mb={2}>
						<Select
							label="Dienstleister"
							name="company"
							fullWidth
							value={selectedCompany}
							onChange={changeCompany}
							variant="standard"
						>
							<option value="" />
							{companies.map(c => (
								<option key={c.name} value={c.name}>{c.name}</option>
							))}
						</Select>
					</Box>
				)}
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="secondary"
					href=""
					onClick={handleClose}
				>
					Abbrechen
				</Button>
				<Button
					variant="outlined"
					color="primary"
					href=""
					onClick={selectedCompany ? () => proceed(selectedCompany) : undefined}
				>
					{okButtonTitle ?? "Ok"}
				</Button>
			</DialogActions>
		</ThemedDialog>
	);
};

interface SelectCompanyOptions {
	priceCompanies: string[]
	okButtonTitle?: string
	dialogTitle?: string
}

export default (options: SelectCompanyOptions) => {
	const dialog = confirmable(props => <SelectCompanyDialog {...props} {...options} />);
	return createConfirmation<string>(dialog)();
}
