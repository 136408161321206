import { AsyncFnReturn } from "react-use/lib/useAsyncFn";
import { useAsyncFn } from "react-use";
import axios from "axios";
import parseContentDispositionFilename from "../../system/parseContentDispositionFilename";
import fileDownload from "js-file-download";
import { VehicleServiceQuery } from "./useVehicleServices";

export default (query : Partial<VehicleServiceQuery>) : AsyncFnReturn<() => Promise<void>> =>
	useAsyncFn(async () => {
		const response = await axios.get(`/api/vehicles/services`, {
			responseType: "blob",
			params: {
				...query,
				output: "xlsx"
			}
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);
	}, [query]);
