import useUser from "../system/useUser";
import { UserRole } from "../system/Domain";
import { IconButtonProps, Tooltip, IconButton } from "@mui/material";

interface TooltipIconButtonProps extends IconButtonProps {
	title: string
	role?: UserRole
}

export default ({ title, role, children, ...rest } : TooltipIconButtonProps) => {
	const [user] = useUser();
	
	
	if (role && !user) return null;
	if (role && user && !user.roles.includes(role)) return null;

	return (
		<Tooltip title={title}>
			<IconButton {...rest}>
				{children}
			</IconButton>
		</Tooltip>
	);
};
