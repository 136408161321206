import { useState } from 'react';
import useQuery, { IQuery } from "../../../system/useQuery";
import { UnregistrationOrder } from "../../../system/Domain";
import Layout from "../../../Components/Layout/Layout";
import OrdersSubMenu from "../OrdersSubMenu";
import { Box, CircularProgress } from "@mui/material";
import FetchNextButton from "../../../Components/FetchNextButton";
import UnregistrationOrdersOverviewTable from "./UnregistrationOrdersOverviewTable";
import useAsyncEffect from "../../../system/useAsyncEffect";
import axios from "axios";
import Search from "../../../Components/Search";
import UnregistrationOrdersOverviewSidebar from "./UnregistrationOrdersOverviewSidebar";
import { useHistory } from "react-router-dom";
import OrdersNavigation from 'Navigation/OrdersNavigation';

export interface UnregistrationOrdersOverviewQuery extends IQuery {
	perspective?: string
	sort: string
	fullText?: string
	isTransferred?: boolean
}

const UnregistrationOrdersOverview = () => {
	const history = useHistory();

	const [isProcessing, setIsProcessing] = useState<boolean>(false);

	const [unregistrationOrders, setUnregistrationOrders] = useState<UnregistrationOrder[]>();
	const [isFetching, setIsFetching] = useState(false);
	const [query, setQuery, fetchNext, resetQuery] = useQuery<UnregistrationOrdersOverviewQuery>('unregistrationOrdersOverviewQuery', {
		perspective: "All",
		skip: 0,
		take: 20,
		fullText: "",
		sort: "dateCreated:desc",
		isTransferred: undefined
	});

	useAsyncEffect(async (cancelToken) => {
		const axiosCancelTokenSource = axios.CancelToken.source();
		cancelToken.promise.then(() => axiosCancelTokenSource.cancel());

		setIsFetching(true);

		try {
			const { data: uo } = await axios.get<UnregistrationOrder[]>(`/api/orders/unregistrations`, {
				params: query,
				cancelToken: axiosCancelTokenSource.token
			});

			if (query.skip > 0) {
				setUnregistrationOrders(o => [
					...(o ?? []),
					...uo
				]);
			} else {
				setUnregistrationOrders(uo);
			}
		} finally {
			setIsFetching(false);
		}
	}, [query]);

	const canProcess = unregistrationOrders?.some(f => f.status === "Created") ?? false;

	const handleProcess = async () => {
		if (!unregistrationOrders) return;

		setIsProcessing(true);

		try {
			const unregistrationOrderIds = unregistrationOrders.filter(f => f.status === "Created").map(m => m.id);

			const response = await axios.post<string>(`/api/orders/unregistrations/process`,
				unregistrationOrderIds
			);

			history.push(`/orders/returns/sessions`);
		} finally {
			setIsProcessing(false);
		}
	};

	return (
		<Layout
			title="Abmeldeaufträge"
			navigation={<OrdersNavigation />}
			subMenu={<OrdersSubMenu />}
			sidebar={<UnregistrationOrdersOverviewSidebar
				query={query}
				setQuery={setQuery}
				resetQuery={resetQuery}
				canProcess={canProcess}
				isProcessing={isProcessing}
				handleProcess={handleProcess}
			/>}
		>
			<Box mb={2}>
				{!unregistrationOrders && (
					<CircularProgress size={48} />
				)}
				{unregistrationOrders && (
					<>
						<Box mb={2}>
							<Search
								value={undefined}
								realtime={false}
								onSearch={(value) => value !== query.fullText && setQuery(q => ({
									...q,
									skip: 0,
									fullText: value
								}))}
							/>
						</Box>
						<UnregistrationOrdersOverviewTable unregistrationOrders={unregistrationOrders} />
						<FetchNextButton
							hidden={unregistrationOrders.length < query.take + query.skip}
							mb={3}
							onNext={fetchNext}
							disabled={isFetching}
						/>
					</>
				)}
			</Box>
		</Layout>
	);
};

export default UnregistrationOrdersOverview;
