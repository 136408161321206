import { fetchEventSource } from "@microsoft/fetch-event-source";
import axios from "axios";
import { EventSourceMessage } from "@microsoft/fetch-event-source/lib/cjs/parse";

export type EventListenerFunction = (data : any) => void;

export class Channel {
	pattern : string;
	subscribers : number = 0;

	constructor(pattern : string) {
		this.pattern = pattern;
	}

	subscribe() {
		this.subscribers++;
	}

	unsubscribe() {
		this.subscribers--;
	}
}

export class EventListener {
	event : string;
	fns : EventListenerFunction[] = [];

	constructor(event : string) {
		this.event = event;
	}

	on(fn : EventListenerFunction) {
		this.fns.push(fn);
	}

	off(fn : EventListenerFunction) {
		this.fns = this.fns.filter(f => f !== fn);
		return this.fns.length;
	}

	raise(data : any) {
		for (const fn of this.fns) {
			fn(data);
		}
	}
}

export class Events {
	url? : string;
	hub? : string;
	token? : string;

	sessionId? : string;
	retries : number = 0;
	channels : Channel[] = [];
	listeners : EventListener[] = [];
	ctrl? : AbortController;

	on(event : string, fn : EventListenerFunction) {
		let listener = this.listeners.find(c => c.event === event);
		if (!listener) {
			listener = new EventListener(event);
			this.listeners.push(listener);
		}

		listener.on(fn);
	}

	off(event : string, fn : EventListenerFunction) {
		const listener = this.listeners.find(c => c.event === event);
		if (!listener) {
			return;
		}

		const remainingFns = listener.off(fn);
		if (remainingFns === 0) {
			this.listeners = this.listeners.filter(l => l !== listener);
		}
	}

	raise(event : string, data : any) {
		const listener = this.listeners.find(c => c.event === event);
		if (!listener) {
			return;
		}

		listener.raise(data);
	}

	subscribe(channels : string[]) {
		const newChannels : string[] = [];

		for (const pattern of channels) {
			let channel = this.channels.find(c => c.pattern === pattern);
			if (!channel) {
				channel = new Channel(pattern);
				this.channels.push(channel);

				newChannels.push(pattern);
			}

			channel.subscribe();
		}

		if (newChannels.length > 0) {
			this._subscribe(newChannels);
		}
	}

	async _subscribe(channels : string[]) {
		if (!this.sessionId) {
			return;
		}

		console.log(`Subscribing session ${this.sessionId} to channels ${channels.join(", ")}`);

		try {
			await axios.create().post(`${this.url}/hubs/${this.hub}/subscribe`,
				{
					sessionId: this.sessionId,
					channels
				}, {
					headers: {
						"Authorization": `Bearer ${this.token}`
					}
				}
			);
		} catch (e) {
			console.warn(`Unable to subscribe session ${this.sessionId} to channels ${channels.join(", ")}`);
			console.warn(e);

			// noinspection ES6MissingAwait
			this.reconnect();
		}
	}

	unsubscribe(channels : string[]) {
		const orphanedChannels : string[] = [];
		for (const pattern of channels) {
			const channel = this.channels.find(c => c.pattern === pattern);
			if (!channel) {
				continue;
			}

			channel.unsubscribe();

			if (channel.subscribers === 0) {
				this.channels = this.channels.filter(c => c !== channel);
				orphanedChannels.push(pattern);
			}
		}

		if (orphanedChannels.length > 0) {
			this._unsubscribe(orphanedChannels)
		}
	}

	async _unsubscribe(channels : string[]) {
		if (!this.sessionId) {
			return;
		}

		console.log(`Unsubscribing session ${this.sessionId} from channels ${channels.join(", ")}`);

		try {
			await axios.create().post(`${this.url}/hubs/${this.hub}/unsubscribe`,
				{
					sessionId: this.sessionId,
					channels
				}, {
					headers: {
						"Authorization": `Bearer ${this.token}`
					}
				}
			);
		} catch (e) {
			console.warn(`Unable to unsubscribe session ${this.sessionId} from channels ${channels.join(", ")}`);
			console.warn(e);

			// noinspection ES6MissingAwait
			this.reconnect();
		}
	}

	async connect(url : string, hub : string, token : string) : Promise<void> {
		if (!!this.sessionId) {
			throw new Error("Already connected to events");
		}

		this.url = url;
		this.hub = hub;
		this.token = token;

		await this._connect();
	}

	async _connect() {
		console.info(`Connecting to events hub ${this.hub} at ${this.url}`);

		this.ctrl = new AbortController();

		await fetchEventSource(`${this.url}/hubs/${this.hub}/events`, {
			headers: {
				"Authorization": `Bearer ${this.token}`
			},
			signal: this.ctrl.signal,
			openWhenHidden: true,
			keepalive: true,
			onmessage: this.onMessage.bind(this),
			onerror: err => {
				console.warn(err);
				console.log(this);
				this.retries = this.retries + 1;
				return Math.min(1000 * Math.pow(2, events.retries), 10000);
			},
		});
	}

	async reconnect() {
		this.disconnect();
		await this._connect();
	}

	disconnect() {
		console.info(`Disconnecting from events hub ${this.hub} at ${this.url}`);
		this.ctrl?.abort();

		if (!this.sessionId) {
			return;
		}

		console.info(`Disconnecting session ${this.sessionId}`);
		delete this.sessionId;
	}

	onMessage(ev : EventSourceMessage) {
		if (!ev || !ev.event) {
			return;
		}

		this.retries = 0;
		const data = JSON.parse(ev.data);

		// console.log(ev.event, data);

		if (ev.event === "init" && !!data.sessionId) {
			this.sessionId = data.sessionId;
			console.log(`Initializing session with id ${this.sessionId}`);
			if (this.channels.length > 0) {
				this._subscribe(this.channels.map(c => c.pattern));
			}
		} else {
			this.raise(ev.event, data);
		}
	}
}

const events = new Events();
(window as any).ald_events = events;

export default () => {
	return events;
}
