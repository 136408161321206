import { Plate } from "../../Components/Plate";
import ListItem from "../../Components/ListItem";
import { TransportOrder } from "../../system/Domain";
import TextBlock from "../../Components/Layout/TextBlock";
import ListItemContent from "../../Components/ListItemContent";
import ListItemAction from "../../Components/ListItemAction";
import { DirectionsCar, Reply } from "@mui/icons-material";
import ListItemActions from "../../Components/ListItemActions";
import { useHistory } from "react-router";
import { Box } from "@mui/material";
import { VehicleReferenceWithDadNotes } from "./DadArrivalTransmissionsLayout";
import ListItemChips from "Components/ListItemChips";
import ListItemChip from "Components/ListItemChip";

interface Props {
	transportOrder?: TransportOrder;
	vehicle?: VehicleReferenceWithDadNotes;
}

const DadArrivalTransmissionListItem = (props: Props) => {
	const history = useHistory();

	const plateNumber = props.transportOrder?.plateNumber || props.vehicle?.plateNumber || "";
	const description = props.transportOrder?.vehicle?.type?.description || props.vehicle?.type?.description || "";
	const vin = props.transportOrder?.vin || props.vehicle?.vin || "";
	const note = props.vehicle?.note;

	return (
		<ListItem color="info">
			{!!note && (
				<ListItemActions>
					<ListItemAction
						icon={<Reply />}
						onClick={() =>
							props.vehicle && history.push(`/vehicles/${props.vehicle.id}/registration-documents`)
						}
					/>
				</ListItemActions>
			)}
			{!note && (
				<ListItemActions>
					<ListItemAction
						icon={<Reply />}
						onClick={() =>
							props.transportOrder && history.push(`/orders/transports/${props.transportOrder.id}`)
						}
					/>
					<ListItemAction
						icon={<DirectionsCar />}
						disabled={!props.transportOrder?.vehicle?.id}
						onClick={() =>
							props.transportOrder &&
							history.push(`/vehicles/${props.transportOrder?.vehicle.id}/registration-documents`)
						}
					/>
				</ListItemActions>
			)}
			<ListItemContent>
				<TextBlock primary={<Plate plateNumber={plateNumber} style={{ zoom: 0.6 }} />} />
				<TextBlock primary={description} secondary={vin} />
				{note && (
					<Box mt={2}>
						<TextBlock primary={note.text} />
					</Box>
				)}
				<ListItemChips>
					{props.transportOrder?.destinationCompound && (
						<ListItemChip label={props.transportOrder.destinationCompound.name} />
					)}
				</ListItemChips>
			</ListItemContent>
		</ListItem>
	);
};

export default DadArrivalTransmissionListItem;
