import { useState } from 'react';
import ThemedDialog from "../../../Components/ThemedDialog";
import PictureUpload from "../../../Components/PictureUpload";
import ArrivalToggleButtonGroup from "./ArrivalToggleButtonGroup";
import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";
import { Box, Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DocumentArrivalType, DocumentsPictureProof, RegistrationDocuments } from "../../../system/Domain";
import { ImageUploadProps } from "../PictureSets/VehiclePictureSets";

interface Props extends DocumentArrivalPictureProofOptions, ReactConfirmProps<Result> {
	show: boolean
}

const DocumentArrivalPictureProofDialog = (props: Props) => {
	const [type, setType] = useState<DocumentArrivalType | undefined>(
		props.document.arrivalImport?.arrivalType === "None" ? undefined
			: props.document.arrivalImport?.arrivalType === "RegistrationDocument" ? "RegistrationTitle"
				: "RegistrationDocument"
	);

	const [titlePictureReference, setTitlePictureReference] = useState<ImageUploadProps>();
	const [registrationDocumentFrontPictureProof, setRegistrationDocumentFrontPictureProof] = useState<ImageUploadProps>();
	const [registrationDocumentBackPictureProof, setRegistrationDocumentBackPictureProof] = useState<ImageUploadProps>();

	const canComplete = (type === "All" && titlePictureReference?.fileReference && registrationDocumentFrontPictureProof?.fileReference && registrationDocumentBackPictureProof?.fileReference)
		|| (type === "RegistrationTitle" && titlePictureReference?.fileReference)
		|| (type === "RegistrationDocument" && registrationDocumentBackPictureProof?.fileReference && registrationDocumentFrontPictureProof?.fileReference);

	const title = (type === "All" && "Fahrzeugbrief und Fahrzeugschein")
		|| (type === "RegistrationDocument" && "Fahrzeugschein")
		|| (type === "RegistrationTitle" && "Fahrzeugbrief");

	const handleClose = () => {
		props.cancel();
	};

	const ok = () => {
		if (!type) return;

		props.proceed({
			proof: {
				registrationTitle: titlePictureReference.fileReference,
				registrationDocumentBack: registrationDocumentBackPictureProof.fileReference,
				registrationDocumentFront: registrationDocumentFrontPictureProof.fileReference
			},
			type: type
		})
	};

	return (
		<ThemedDialog
			open={props.show}
			maxWidth="md"
			onClose={handleClose}
		>
			<DialogTitle>
				Manuelle Dokumentenankunft {title}
			</DialogTitle>
			<DialogContent>
				<ArrivalToggleButtonGroup
					disable={false}
					previousValue={props.document.arrivalImport?.arrivalType}
					status={props.document.status}
					value={type}
					hideNone
					onChange={(e, v) => setType(v)}
				/>
				<Box display="flex"
					 my={2}
					 flexDirection="row"
				>
					{(type === "RegistrationDocument" || type === "All") && (
						<>
							<Box mx={1}>
								<Box>Fahrzeugschein Vorderseite</Box>
								<PictureUpload
									disabled={false}
									fullWidth={false}
									readOnly={false}
									previewUrl=""
									setter={setRegistrationDocumentFrontPictureProof}
								/>
							</Box>
							<Box mx={1}>
								<Box>Fahrzeugschein Rückseite</Box>
								<PictureUpload
									disabled={false}
									fullWidth={false}
									readOnly={false}
									previewUrl=""
									setter={setRegistrationDocumentBackPictureProof}
								/>
							</Box>
						</>
					)}
				</Box>
				{(type === "RegistrationTitle" || type === "All") && (
					<Box mx={1}>
						<Box>Fahrzeugbrief</Box>
						<PictureUpload
							disabled={false}
							fullWidth={false}
							readOnly={false}
							previewUrl=""
							setter={setTitlePictureReference}
						/>
					</Box>
				)}
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="secondary"
					href=""
					onClick={handleClose}
				>
					Abbrechen
				</Button>
				<Button
					variant="outlined"
					color="primary"
					href=""
					disabled={!canComplete}
					onClick={ok}
				>
					Ok
				</Button>
			</DialogActions>
		</ThemedDialog>
	);
};

interface DocumentArrivalPictureProofOptions {
	document: RegistrationDocuments
}

interface Result {
	proof: DocumentsPictureProof
	type: DocumentArrivalType
}

export default (options: DocumentArrivalPictureProofOptions) => {
	const dialog = confirmable(props => <DocumentArrivalPictureProofDialog {...props} {...options} />);
	return createConfirmation<Result>(dialog)();
};
