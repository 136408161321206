import axios from "axios";
import parseContentDispositionFilename from "./parseContentDispositionFilename";
import fileDownload from "js-file-download";

function handleDownload(url: string) {
	return async function() {
		const response = await axios.get(url, {
			responseType: "blob"
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);
	}
}

export default handleDownload;
