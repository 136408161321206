import { Vehicle } from "../../system/Domain";
import HeaderMenu from "../../Components/Layout/HeaderMenu";
import axios from "axios";
import parseContentDispositionFilename from "../../system/parseContentDispositionFilename";
import fileDownload from "js-file-download";
import { MenuItem } from "@mui/material";

export interface VehicleHeaderMenuProps {
	vehicle : Vehicle | undefined
}

export default ({ vehicle } : VehicleHeaderMenuProps) => {
	if (!vehicle) {
		return null;
	}

	const downloadQRCode = async () => {
		const response = await axios.get(`/api/vehicles/${vehicle!.id}/qr-code`, {
			responseType: "blob"
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);
	};

	const handleRequestRequisition = () => {
		window.open(`https://www.servicefreigabe.de/intern/preRequisitions/new?remarketingVehicleId=${vehicle?.id}`);
	};

	return (
		<HeaderMenu>
			<MenuItem onClick={handleRequestRequisition}>Reparaturfreigabe anfordern</MenuItem>
			<MenuItem onClick={downloadQRCode}>QR Code drucken</MenuItem>
		</HeaderMenu>
	);
};
