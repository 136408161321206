import { alpha, colors, Theme } from "@mui/material";

const createKpiStyles = (theme: Theme) => ({
	entry: {
		cursor: "pointer",
		color: alpha(theme.palette.text.primary, 0.8),
		"&:hover": {
			color: alpha(theme.palette.text.primary, 1),
			fontWeight: 400,
		},
		paddingBottom: theme.spacing(1),
		fontSize: "0.8rem",
		fontWeight: 300,
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		transitionProperty: "background-color, color, font-size",
		transitionDuration: "250ms",
	},
	opaque: {
		opacity: 0.5,
	},
	active: {
		backgroundColor:
			theme.palette.mode === "dark"
				? alpha(theme.palette.primary.main, 0.2)
				: alpha(theme.palette.primary.dark, 0.05),
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(2),
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(2),
		fontSize: "0.8rem",
		fontWeight: 400,
		color: alpha(theme.palette.text.primary, 1),
		opacity: 1,
	},
	columns: {
		display: "flex",
		flexDirection: "row",
		gap: theme.spacing(3),
	},
	details: {
		paddingTop: theme.spacing(3),
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(1.5),
		fontSize: "0.7rem",
		fontWeight: 300,
		color: theme.palette.text.primary,
	},
	detailActions: {
		paddingBottom: theme.spacing(2),
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(1),
		justifyContent: "space-between",
	},
	agesInfo: {
		alignItems: "center",
		justifyContent: "space-between",
		gap: theme.spacing(0.5),
		color: alpha(theme.palette.text.primary, 0.8),
		textAlign: "center",
		marginTop: theme.spacing(-2),
		marginBottom: theme.spacing(-1.5),
		fontStyle: "italic",
	},
	ageEntry: {
		alignItems: "center",
		justifyContent: "space-between",
		gap: theme.spacing(1.5),
		color: alpha(theme.palette.text.primary, 0.8),
		"&:hover": {
			color: alpha(theme.palette.text.primary, 1),
		},
	},
	ageLabel: {
		cursor: "default",
		display: "inline-block",
		width: "auto",
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
		paddingLeft: theme.spacing(0.75),
		paddingRight: theme.spacing(0.75),
		borderWidth: 1,
		borderStyle: "solid",
		borderRadius: theme.spacing(2),
		borderColor: colors.grey[500],
		fontSize: "0.7rem",
		fontWeight: 400,
		minWidth: theme.spacing(4),
		textAlign: "center",
	},
});

export default createKpiStyles;
