import { ImportStatus } from "../../system/Domain";

type ImportStatusInfo = {
	[K in ImportStatus]: string;
};

const infos: ImportStatusInfo = {
	[ImportStatus.Created]: "In Bearbeitung",
	[ImportStatus.Imported]: "In Bearbeitung",
	[ImportStatus.Committed]: "Abgeschlossen",
	[ImportStatus.Deleted]: "Gelöscht",
};

export default infos;
