import { blue, green, grey, orange, red } from "@mui/material/colors";
import { Chip } from "@mui/material";

interface Props {
	httpMethod : string
}

const httpMethodSettings = (httpMethod : string) => {
	switch (httpMethod) {
		case "GET":
			return green[500];
		case "POST":
			return orange[500];
		case "PUT":
			return blue[500];
		case "DELETE":
			return red[500];
		default:
			return grey[500];
	}
}

const HttpMethodPill = (props : Props) => {
	return (
		<Chip
			label={props.httpMethod}
			size="small"
			style={{ backgroundColor: httpMethodSettings(props.httpMethod) }}
		/>
	);
};

export default HttpMethodPill;
