import { Sale, UserRole, Vehicle } from "system/Domain";
import { DirectionsCar, Money, NoCrash } from "@mui/icons-material";
import LayoutNavigationItem from "../Components/Layout/LayoutNavigationItem";
import LayoutNavigationSection from "../Components/Layout/LayoutNavigationSection";

interface Props {
	sale: Sale;
	vehicle: Vehicle;
}

export default ({ sale, vehicle }: Props) => {
	return (
		<LayoutNavigationSection title={`Verkauf ${vehicle.plateNumber}`}>
			<LayoutNavigationItem
				roles={[UserRole.SalesUser]}
				icon={<Money />}
				title="Verkaufsvorbereitung"
				path={`/vehicles/${vehicle.id}/remarketing`}
			/>

			{sale.assessment && (
				<LayoutNavigationItem
					roles={[UserRole.LotManager, UserRole.AldUser]}
					icon={<NoCrash />}
					title="Gutachten"
					path={`/vehicles/${vehicle.id}/assessments/${sale.assessment.id}`}
				/>
			)}

			<LayoutNavigationItem
				roles={[UserRole.AldManager]}
				icon={<DirectionsCar />}
				title="Stammdaten"
				path={`/vehicles/${vehicle.id}/base-data`}
			/>
		</LayoutNavigationSection>
	);
};
