import { green, orange, red } from "@mui/material/colors";
import { Chip } from "@mui/material";
import { ReturnOrderSession } from "../../../system/Domain";

interface Props {
	returnOrderSession : ReturnOrderSession
}

const statusSettings = {
	"Created": {
		label: "Erstellt",
		backgroundColor: orange[500]
	},
	"Cancelled": {
		label: "Storniert",
		backgroundColor: red[500]
	},
	"Completed": {
		label: "Abgeschlossen",
		backgroundColor: green[500]
	},
	"CompletedBeforeDocsTransfer": {
		label: "Abgeschlossen",
		backgroundColor: orange[500]
	}
}

const ReturnOrderSessionStatusPill = (props : Props) => {
	const status = props.returnOrderSession.status === "Completed" && !props.returnOrderSession.dateDocumentsTransferred ? "CompletedBeforeDocsTransfer" : props.returnOrderSession.status;
	const setting = statusSettings[status];

	return (
		<Chip
			label={setting.label}
			size="small"
			style={{ backgroundColor: setting.backgroundColor }}
		/>
	);
};

export default ReturnOrderSessionStatusPill;
