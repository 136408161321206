import { ChangeEvent, useState } from "react";
import { confirmable, createConfirmation } from "react-confirm";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import ThemedDialog from "../Components/ThemedDialog";
import DialogContentText from "@mui/material/DialogContentText";
import { ReactConfirmProps } from "../node_modules/react-confirm";
import { TextField } from "@mui/material";

interface PromptProps extends ReactConfirmProps<string | undefined> {
	message : string
}

const YesNoDialog = ({ show, proceed, message } : PromptProps) => {
	const [text, setText] = useState("");
	
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setText(event.target.value);
	};
	
	return (
		<ThemedDialog open={show} maxWidth="sm" fullWidth onClose={() => proceed(undefined)}>
			<DialogTitle>Frage</DialogTitle>
			<DialogContent>
				<DialogContentText>{message}</DialogContentText>
				<TextField
					onChange={handleChange}
					variant="outlined"
					fullWidth
					autoFocus
				/>
			</DialogContent>
			<DialogActions>
				<Button
					color="secondary"
					onClick={() => proceed(undefined)}
				>
					Abbrechen
				</Button>
				<Button
					color="primary"
					onClick={() => proceed(text)}
				>
					Ok
				</Button>
			</DialogActions>
		</ThemedDialog>
	);
};

export default (message : string) => {
	const dialog = confirmable(props => <YesNoDialog {...props} message={message}/>);
	return createConfirmation<string | undefined>(dialog)();
};
