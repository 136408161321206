import { useState } from 'react';
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { UnregistrationOrder } from "../../../system/Domain";
import useAsyncEffect from "../../../system/useAsyncEffect";
import axios from "axios";
import Layout from "../../../Components/Layout/Layout";
import OrdersSubMenu from "../OrdersSubMenu";
import { CircularProgress } from "@mui/material";
import OrdersNavigation from "../../../Navigation/OrdersNavigation";

const UnregistrationOrdersEdit = () => {
	const { id } = useParams<{ id : string }>();
	const history = useHistory();
	const [unregistrationOrder, setUnregistrationOrder] = useState<UnregistrationOrder>();

	useAsyncEffect(async () => {
		if (!id || unregistrationOrder) {
			return;
		}

		const { data: uo } = await axios.get<UnregistrationOrder[]>(`/api/orders/unregistrations`, {
			params: {
				id: id
			}
		});

		if (uo.length !== 1) {
			history.push('/orders/unregistrations');
			return;
		}

		setUnregistrationOrder(uo[0]);
	}, [id]);

	return (
		<Layout
			navigation={<OrdersNavigation />}
			subMenu={<OrdersSubMenu />}
		>
			{!unregistrationOrder && (
				<CircularProgress size={48} />
			)}
			{/*TODO*/}
		</Layout>
	);
};

export default UnregistrationOrdersEdit;
