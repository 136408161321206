import { Grid } from "@mui/material";
import axios from "axios";
import fileDownload from "js-file-download";
import { useState } from "react";
import FetchNextButton from "../../Components/FetchNextButton";
import Layout from "../../Components/Layout/Layout";
import List from "../../Components/List";
import Search from "../../Components/Search";
import Tile from "../../Components/Tiles/Tile";
import OrdersNavigation from "../../Navigation/OrdersNavigation";
import { RepairOrder, RepairOrderPerspective, RepairOrderStatus, RepairOrderType } from "../../system/Domain";
import parseContentDispositionFilename from "../../system/parseContentDispositionFilename";
import useAsyncEffect from "../../system/useAsyncEffect";
import useChannel from "../../system/useChannel";
import useEvent from "../../system/useEvent";
import useQuery, { IQuery } from "../../system/useQuery";
import BatchFilesTile from "../VehicleSales/BatchFilesTile";
import CarglassRepairOrderListItem from "./CarglassRepairOrderListItem";
import DekraRepairOrderListItem, { RepairOrderViewModeBase } from "./DekraRepairOrderListItem";
import GeneralRepairOrderListItem from "./GeneralRepairOrderListItem";
import RepairOrdersSidebar from "./RepairOrdersSidebar";

export interface RepairOrderQuery extends IQuery {
	sort: string;
	compound?: string;
	company?: string;
	status?: RepairOrderStatus;
	fullText?: string;
	statusDateFrom?: string;
	dateAssignable?: string;
	perspective: RepairOrderPerspective;
	workType: RepairOrderType;
}

export default (props: any) => {
	const plateNumberInputState: string = ((props.location && props.location.state) || undefined)?.plateNumber;

	const [orders, setOrders] = useState<RepairOrder[]>();

	const [query, setQuery, fetchNext, resetQuery] = useQuery<RepairOrderQuery>(
		"repairOrderQuery",
		{
			perspective: "Actionable",
			skip: 0,
			take: 20,
			fullText: "",
			sort: "dateCreated:desc",
			statusDateFrom: undefined,
			dateAssignable: undefined,
			company: undefined,
			compound: undefined,
			workType: undefined,
			status: undefined,
		},
		plateNumberInputState
			? (q) => ({
					...q,
					fullText: plateNumberInputState,
					perspective: "All",
				})
			: undefined,
	);

	useChannel("repair-orders");

	const [isFetching, setIsFetching] = useState(false);
	const reloadOrder = async (id: string) => {
		if (query.fullText) {
			return;
		}

		const { data: order } = await axios.get<RepairOrder>(`/api/orders/repairs/${id}`);
		setOrders((o) => {
			const orders = [...(o || [])];
			const index = orders.findIndex((o) => o.id === order.id);
			if (index !== -1) {
				orders[index] = order;
			}
			return orders;
		});
	};

	useEvent(
		"repair-order:updated",
		async (data) => {
			const { id, version } = data;

			if (!orders || !id || !version) {
				return;
			}

			const order = orders.find((o) => o.id === id);
			if (order && order.version < version) {
				await reloadOrder(id);
			}
		},
		[orders],
	);

	const getRepairOrders = async () => {
		try {
			const { data: ao } = await axios.get<RepairOrder[]>(`/api/orders/repairs`, {
				params: query,
			});

			if (query.skip > 0) {
				setOrders((o) => [...(o ?? []), ...ao]);
			} else {
				setOrders(ao);
			}
		} finally {
			setIsFetching(false);
		}
	};

	const triggerExport = async () => {
		const response = await axios.get(`/api/orders/repairs`, {
			responseType: "blob",
			params: { ...query, format: "xlsx" },
		});

		const fileName = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, fileName);
	};

	useAsyncEffect(async () => {
		setIsFetching(true);
		await getRepairOrders();
	}, [query]);

	const setOrder = (o: RepairOrder) => {
		const changed = orders.map((repairOrder) => (repairOrder.id === o.id ? o : repairOrder));
		setOrders(changed);
	};

	return (
		<Layout
			title="Werkstattaufträge"
			navigation={<OrdersNavigation />}
			sidebar={
				<RepairOrdersSidebar
					query={query}
					setQuery={setQuery}
					canProcess
					isProcessing={false}
					resetQuery={resetQuery}
					repairOrders={orders}
					runExport={triggerExport}
				/>
			}
		>
			<Grid container spacing={6}>
				<Grid item xs={12} md={6}>
					<Tile title="Aufträge">
						<List>
							<Search
								onSearch={(s) =>
									setQuery((q) => ({
										...q,
										fullText: s,
									}))
								}
								value={query.fullText}
								realtime
							/>
							{orders &&
								orders.map((repairOrder) => {
									switch (repairOrder.repairOrderType) {
										case "AssessmentReport": {
											return (
												<DekraRepairOrderListItem
													key={repairOrder.id}
													order={repairOrder}
													setOrder={setOrder}
													setIsFetching={setIsFetching}
													orders={orders}
													setOrders={setOrders}
													defaultViewMode={RepairOrderViewModeBase.View}
												/>
											);
										}
										case "Carglass": {
											return (
												<CarglassRepairOrderListItem
													key={repairOrder.id}
													order={repairOrder}
													setOrder={setOrder}
													defaultViewMode={RepairOrderViewModeBase.View}
												/>
											);
										}
										case "GeneralRepair": {
											return (
												<GeneralRepairOrderListItem
													key={repairOrder.id}
													order={repairOrder}
													setOrder={setOrder}
													isFetching={isFetching}
													setIsFetching={setIsFetching}
													orders={orders}
													setOrders={setOrders}
													defaultViewMode={RepairOrderViewModeBase.View}
												/>
											);
										}
									}
								})}
						</List>
					</Tile>

					{orders && (
						<FetchNextButton
							hidden={orders.length < query.take + query.skip}
							mb={3}
							onNext={fetchNext}
							disabled={isFetching}
						/>
					)}
				</Grid>
				<Grid item xs={12} md={6}>
					<BatchFilesTile
						title="Beauftragungen"
						query={{
							types: ["RepairOrders"],
						}}
					/>
				</Grid>
			</Grid>
		</Layout>
	);
};
