import { FunctionComponent, useState, MouseEvent, Children, cloneElement } from "react";
import IconButton from "@mui/material/IconButton";
import { MoreVert } from "@mui/icons-material"
import Menu from "@mui/material/Menu";
import { IconButtonProps } from "@mui/material";

interface Props extends IconButtonProps {

}

const component: FunctionComponent = ({ children, ...rest }: Props) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const closeOnClick = (func: any) => () => {
		func();
		setAnchorEl(null);
	};

	const hasClickableItems = Children.toArray(children).some((c: any) => c.props.onClick);
	if (!hasClickableItems) return null;

	return (
		<>
			<IconButton
				{...rest}
				onClick={handleClick}
			>
				<MoreVert />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left"
				}}
			>
				{Children.map(children, (child: any) => {
					if (!child?.props.onClick) return null;

					return cloneElement(child, {
						onClick: closeOnClick(child.props.onClick)
					});
				})}
			</Menu>
		</>
	);
};

export default component;
