import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";
import ThemedDialog from "../../Components/ThemedDialog";
import { Box, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { ChangeEvent, useState } from "react";

interface Props {
	title: string
	message: string
	initialValue?: string
}

const CustomerEmailNotificationDialog = (props: ReactConfirmProps<string | undefined> & Props) => {
	const [text, setText] = useState(props.initialValue ?? "");

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setText(event.target.value);
	};

	return (
		<ThemedDialog open={props.show} maxWidth="sm" fullWidth onClose={() => props.proceed(undefined)}>
			<DialogTitle>{props.title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{props.message}</DialogContentText>
				<TextField
					onChange={handleChange}
					variant="outlined"
					fullWidth
					autoFocus
					value={text}
				/>
			</DialogContent>
			<DialogActions>
				<Box width="100%" display="flex" justifyContent="space-between">
					<Box>
						{props.initialValue && (
							<Button
								color="primary"
								onClick={() => props.proceed("")}
							>
								Emailbenachrichtung deaktivieren
							</Button>
						)}
					</Box>
					<Box>
						<Button
							color="secondary"
							onClick={() => props.proceed(undefined)}
						>
							Abbrechen
						</Button>
						<Button
							color="primary"
							onClick={() => props.proceed(text)}
						>
							Ok
						</Button>
					</Box>
				</Box>
			</DialogActions>
		</ThemedDialog>
	);
};

export default (inputProps: Props) => {
	const dialog = confirmable(props => <CustomerEmailNotificationDialog {...props} {...inputProps} />);
	return createConfirmation<string | undefined>(dialog)();
}
