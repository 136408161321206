import { Grid } from "@mui/material";
import Layout from "Components/Layout/Layout";

import SalesNavigation from "Navigation/SalesNavigation";
import BatchFilesTile from "../BatchFilesTile";
import BcaAuctionsTile from "./BcaAuctionsTile";
import BcaSidebar from "./BcaSidebar";

export default () => {
	return (
		<Layout title="BCA" navigation={<SalesNavigation />} sidebar={<BcaSidebar />}>
			<Grid container spacing={6}>
				<Grid item xs={12} sm={6}>
					<BatchFilesTile
						title="Verkäufe"
						query={{
							types: ["BcaSales"],
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<BcaAuctionsTile query={{}} />
				</Grid>
			</Grid>
		</Layout>
	);
};
