import LayoutNavigation from "../Components/Layout/LayoutNavigation";
import SalesNavigationSection from "./SalesNavigationSection";
import RootNavigationSection from "./RootNavigationSection";

export default () => {
	return (
		<LayoutNavigation>
			<SalesNavigationSection />
			<RootNavigationSection />
		</LayoutNavigation>
	);
};
