import { RadioButtonUnchecked } from '@mui/icons-material';
import { ListItem, ListItemAvatar, ListItemText } from '@mui/material';

import { AversiSessionReconciliationItem } from './AversiSessionsPage';

interface Props {
	item : AversiSessionReconciliationItem
	onClick? : () => void
}

export const aversiReconciliationStatus = {
	"Ok": "",
	"MissingAttachment": "Begleitschreiben fehlt",
	"MissingItem": "Buchungen fehlen",
	"AmountDiffers": "Beträge sind falsch"
};

const AversiSessionReconciliationListItem = ({ item, onClick } : Props) => (
	<ListItem button onClick={onClick}>
		<ListItemAvatar>
			<RadioButtonUnchecked />
		</ListItemAvatar>
		<ListItemText
			primary={`${item.insuranceName} ${item.insuranceId}`}
			secondary={aversiReconciliationStatus[item.status]}
		/>
	</ListItem>
);

export default AversiSessionReconciliationListItem;
