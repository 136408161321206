import { styled, TableCellProps } from "@mui/material";
import { TableCell as MuiTableCell } from "@mui/material";

interface BreakpointProps {
	xs?: boolean
	sm?: boolean
	md?: boolean
	lg?: boolean
	xl?: boolean
}

export const Colgroup = styled("colgroup", {
	shouldForwardProp: (prop) => prop !== 'xs' && prop !== 'sm' && prop !== 'md' && prop !== 'lg' && prop !== 'xl',
})<BreakpointProps>(({ theme, xs, sm, md, lg, xl }) => ({
	display: 'none',
	[theme.breakpoints.only("xs")]: {
		display: xs ? 'contents' : 'none'
	},
	[theme.breakpoints.only("sm")]: {
		display: sm ? 'contents' : 'none'
	},
	[theme.breakpoints.only("md")]: {
		display: md ? 'contents' : 'none'
	},
	[theme.breakpoints.only("lg")]: {
		display: lg ? 'contents' : 'none'
	},
	[theme.breakpoints.only("xl")]: {
		display: xl ? 'contents' : 'none'
	}
}));

export const Col = styled("col", {
	shouldForwardProp: (prop) => prop !== 'xs' && prop !== 'sm' && prop !== 'md' && prop !== 'lg' && prop !== 'xl',
})<BreakpointProps>(({ theme, xs, sm, md, lg, xl }) => ({
	display: 'none',
	[theme.breakpoints.only("xs")]: {
		display: xs ? 'contents' : 'none'
	},
	[theme.breakpoints.only("sm")]: {
		display: sm ? 'contents' : 'none'
	},
	[theme.breakpoints.only("md")]: {
		display: md ? 'contents' : 'none'
	},
	[theme.breakpoints.only("lg")]: {
		display: lg ? 'contents' : 'none'
	},
	[theme.breakpoints.only("xl")]: {
		display: xl ? 'contents' : 'none'
	}
}));

interface StyledTableCellProps extends TableCellProps, BreakpointProps {

}

export const TableCell = styled(MuiTableCell, {
	shouldForwardProp: (prop) => prop !== 'xs' && prop !== 'sm' && prop !== 'md' && prop !== 'lg' && prop !== 'xl',
})<StyledTableCellProps>(({ theme, xs, sm, md, lg, xl }) => ({
	display: 'none',
	[theme.breakpoints.only("xs")]: {
		// meaning xs or none of the breakpoints
		display: xs || (!xs && !sm && !md && !lg && !xl) ? 'table-cell' : 'none'
	},
	[theme.breakpoints.only("sm")]: {
		display: sm || (!xs && !sm && !md && !lg && !xl) ? 'table-cell' : 'none'
	},
	[theme.breakpoints.only("md")]: {
		display: md || (!xs && !sm && !md && !lg && !xl) ? 'table-cell' : 'none'
	},
	[theme.breakpoints.only("lg")]: {
		display: lg || (!xs && !sm && !md && !lg && !xl) ? 'table-cell' : 'none'
	},
	[theme.breakpoints.only("xl")]: {
		display: xl || (!xs && !sm && !md && !lg && !xl) ? 'table-cell' : 'none'
	}
}));
