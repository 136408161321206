import { Dispatch, SetStateAction } from "react";
import { Grid } from "@mui/material";
import useForm from "../../system/useForm";
import FormText from "../../Components/FormText";
import { LeasmanVehicleQuery } from "../../system/Domain";
import Tile from "../../Components/Tiles/Tile";
import FormNumber from "../../Components/FormNumber";

interface Props {
	query : LeasmanVehicleQuery
	setQuery : Dispatch<SetStateAction<LeasmanVehicleQuery>>
}

export default ({ query, setQuery } : Props) => {
	const form = useForm({
		values: query,
		setValues: setQuery
	});

	return (
		<Tile title="Suche">
			<Grid container spacing={3}>
				<FormNumber
					form={form}
					name="contractId"
					label="Vertragsnummer"
					fullWidth
				/>
				<FormText
					form={form}
					name="plateNumber"
					label="Kennzeichen"
					fullWidth
				/>
				<FormText
					form={form}
					name="vin"
					label="FIN"
					fullWidth
				/>
			</Grid>
		</Tile>
	);
}
