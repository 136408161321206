import { Box, Paper } from "@mui/material";
import { ReactElement } from "react";

interface CustomerActionChoiceButtonProps {
	title: string
	icon: ReactElement
	onClick: () => void
}

const CustomerActionChoiceButton = (props: CustomerActionChoiceButtonProps) => {
	return (
		<Box width="175px" onClick={props.onClick} style={{ cursor: "pointer" }}>
			<Paper>
				<Box display="flex" width="175px">
					<div
						style={{
							width: "3px", backgroundColor: "#616469", float: "left"
						}}
					>
					</div>
					<Box p={4} width="100%" height="150px"
						 fontSize="18px"
						 fontWeight="100"
						 display="flex" flexDirection="column" justifyContent="space-between">
						{props.icon}
						<Box textAlign="right">
							{props.title}
						</Box>
					</Box>
				</Box>
			</Paper>
		</Box>
	);
};

export default CustomerActionChoiceButton;
