import { Box, CircularProgress, Grid } from "@mui/material";
import axios from "axios";
import ActionButton from "Components/ActionButton";
import Actions from "Components/Actions";
import ask from "Dialogs/ask";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useAsync } from "react-use";
import Layout from "../../Components/Layout/Layout";
import { PoolFleetContract, PoolFleetLotStatus, PoolFleetVehicle } from "../../system/Domain";
import PoolFleetContractList from "./PoolFleetContractList";
import PoolFleetContractTile from "./PoolFleetContractTile";
import { PoolFleetLotTile } from "./PoolFleetLotTile";
import PoolFleetMainContractTile from "./PoolFleetMainContractTile";
import PoolFleetVehicleCategoriesCard from "./PoolFleetVehicleCategoriesCard";
import PoolFleetVehicleHeader from "./PoolFleetVehicleHeader";
import PoolFleetVehicleHistoryCard from "./PoolFleetVehicleHistoryCard";

function PoolFleetVehiclePage() {
	const { id } = useParams<{ id: string }>();
	const [vehicle, setVehicle] = useState<PoolFleetVehicle | null>(null);
	const [currentContract, setCurrentContract] = useState<PoolFleetContract | null>(null);
	const [busy, setBusy] = useState(false);
	const history = useHistory();

	async function queryVehicle(id: string | number) {
		const { data: vehicle } = await axios.get<PoolFleetVehicle>(`/api/pool-fleet/vehicles/${id}`);
		return vehicle;
	}

	const { loading } = useAsync(async () => {
		const vehicle = await queryVehicle(id);
		setVehicle(vehicle);
	}, [id]);

	useEffect(() => {
		if (!vehicle) {
			setCurrentContract(null);
			return;
		}
		setCurrentContract(vehicle.currentContract);
	}, [vehicle]);

	async function handleSyncWithLeasman() {
		setBusy(true);
		try {
			const { data: vehicle } = await axios.post<PoolFleetVehicle>(
				`/api/pool-fleet/vehicles/${id}/sync-with-leasman`,
			);
			setVehicle(vehicle);
		} finally {
			setBusy(false);
		}
	}

	function handleVehicleChange(vehicle: PoolFleetVehicle) {
		setVehicle(vehicle);
	}

	function handleSelectContract(contract: PoolFleetContract) {
		setCurrentContract(contract);
	}

	function handleNewContract() {
		history.push(`/pool-fleet/vehicles/${id}/contracts/new`);
	}

	async function handleOrderTransport() {
		setBusy(true);
		try {
			const { data: vehicle } = await axios.post(`/api/pool-fleet/vehicles/${id}/order-transport`);
			setVehicle(vehicle);
		} finally {
			setBusy(false);
		}
	}

	async function handleCancelCurrentContract() {
		if (currentContract == null) {
			return;
		}

		const answer = await ask("Der Vertrag wird in Leasman storniert. Fortfahren?");

		if (!answer.yes) {
			return;
		}

		setBusy(true);
		try {
			await axios.post(`/api/pool-fleet/vehicles/${id}/cancel-current-contract`);
			const vehicle = await queryVehicle(id);
			setVehicle(vehicle);
		} finally {
			setBusy(false);
		}
	}

	async function handlePoolFleetStatusChange(status: PoolFleetLotStatus) {
		setBusy(true);
		try {
			const { data: vehicle } = await axios.put<PoolFleetVehicle>(`/api/pool-fleet/vehicles/${id}`, {
				lotStatus: status,
			});
			setVehicle(vehicle);
		} finally {
			setBusy(false);
		}
	}

	return (
		<Layout title="Flex Fahrzeug" loading={busy}>
			{loading && (
				<Box>
					<CircularProgress />
				</Box>
			)}
			<>
				{!vehicle && !loading && <Box>Kein Fahrzeug gefunden</Box>}
				{vehicle && (
					<>
						<Box mt={2} mb={3}>
							<Box
								display="flex"
								flexDirection="row"
								justifyContent="space-between"
								alignItems="baseline"
							>
								<Box>
									<PoolFleetVehicleHeader vehicle={vehicle} />
								</Box>
								<Box>
									<Actions>
										<ActionButton
											variant="outlined"
											color="primary"
											disabled={busy || vehicle.status !== "ReadyForRent"}
											onClick={handleNewContract}
										>
											Neuer Vertrag
										</ActionButton>
									</Actions>
								</Box>
							</Box>
						</Box>
						<Box mt={4}>
							<Grid container spacing={2}>
								{currentContract && (
									<Grid item xs={4}>
										<PoolFleetContractTile
											contract={currentContract}
											onCancel={handleCancelCurrentContract}
											onOrderTransport={handleOrderTransport}
											disabled={busy}
										/>
									</Grid>
								)}
								{vehicle.contracts.length > 1 && (
									<Grid item xs={4}>
										<PoolFleetContractList
											disabled={busy}
											onSelect={handleSelectContract}
											contracts={_.orderBy(vehicle.contracts, "contractId", "desc")}
											currentContract={currentContract}
										/>
									</Grid>
								)}
								<Grid item xs={4}>
									<PoolFleetLotTile
										disabled={busy}
										vehicle={vehicle}
										onStatusChange={handlePoolFleetStatusChange}
									/>
								</Grid>
								<Grid item xs={4}>
									<PoolFleetMainContractTile
										vehicle={vehicle}
										disabled={busy}
										onSyncWithLeasman={handleSyncWithLeasman}
									/>
								</Grid>
								<Grid item xs={4}>
									<PoolFleetVehicleCategoriesCard
										vehicle={vehicle}
										onVehicleChange={handleVehicleChange}
									/>
								</Grid>
								<Grid item xs={12}>
									<PoolFleetVehicleHistoryCard vehicle={vehicle} />
								</Grid>
							</Grid>
						</Box>
					</>
				)}
			</>
		</Layout>
	);
}

export default PoolFleetVehiclePage;
