import { ChangeEvent, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";
import Select from "../../../Components/Select";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import ThemedDialog from "../../../Components/ThemedDialog";
import { Checkbox, FormControlLabel } from "@mui/material";

import * as yup from 'yup';
import { TransportOrder } from "../../../system/Domain";

const schema = yup.object().shape({
	reason: yup.string().required('Grund muss angegeben werden'),
	comment: yup.string().required('Bemerkung muss angegeben werden')
});

interface CancelOrderForm {
	reason : string,
	comment : string,
	willRecreate : boolean
}

const CancelOrderDialog = ({ show, proceed, canSeeWillRecreate } : ReactConfirmProps<CancelOrderForm> & CancelOrderOptions) => {
	const [formState, setFormState] = useState({
		reason: "",
		comment: "",
		willRecreate: false
	});
	const [errors, setErrors] = useState<any>({});

	const handleChange = (field : string) => (event: ChangeEvent<any>) => {
		const newState = {
			...formState,
			[field]: event.target.value
		};

		setFormState(newState);
		setErrors({
			...errors,
			[field]: undefined
		});
	};

	const handleClose = () => {
		// @ts-ignore
		proceed();
	};

	const handleSave = async () => {
		try {
			await schema.validate(formState, { abortEarly: false });
		} catch (err) {
			setErrors(err.inner.reduce((p : any, c : any) => {
				p[c.path] = c.message;
				return p;
			}, {}));
			return;
		}

		proceed(formState);
	};

	return (
		<ThemedDialog open={show} onClose={handleClose} maxWidth="sm" fullWidth>
			<DialogTitle>
				Auftrag abbrechen
			</DialogTitle>
			<DialogContent>
				<Box>
					<Select
						name="reason"
						autoFocus
						fullWidth
						label="Grund"
						error={Boolean(errors.reason)}
						helperText={errors.reason}
						value={formState.reason}
						onChange={handleChange("reason")}
						variant="standard"
					>
						<option value=""></option>
						<option value="InvalidPickupDate">Terminvorgabe falsch oder fehlerhaft</option>
						<option value="VehicleCannotBeMoved">Fahrzeug nicht fahrbereit</option>
						<option value="PickupAddressChanged">Abholadresse hat sich geändert</option>
						<option value="PickedUpByCustomer">Fahrzeug wurde vom Kunden abgeholt</option>
						<option value="Other">Anderer Grund</option>
					</Select>
				</Box>
				<Box>
					<TextField
						label="Bemerkung"
						multiline
						fullWidth
						maxRows={4}
						rows={4}
						value={formState.comment}
						onChange={handleChange('comment')}
						margin="normal"
						variant="outlined"
						error={!!errors.comment}
						helperText={errors.comment}
					/>
				</Box>
				{canSeeWillRecreate && (
					<Box>
						<FormControlLabel
							control={
								<Checkbox
									checked={formState.willRecreate}
									onChange={(e) => {
										setFormState({
											...formState,
											willRecreate: e.target.checked
										})
									}}
								/>
							}
							label="Auftrag wurde neu angelegt"
						/>
					</Box>
				)}
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="secondary" href="" onClick={handleClose}>
					Schließen
				</Button>
				<Button variant="outlined" color="primary" href="" onClick={handleSave}>
					Vorgang abbrechen
				</Button>
			</DialogActions>
		</ThemedDialog>
	);
};

interface CancelOrderOptions {
	order : TransportOrder | null
	canSeeWillRecreate : boolean
}

export default (options : CancelOrderOptions) => {
	const dialog = confirmable(props => <CancelOrderDialog {...props} {...options} />);
	return createConfirmation<CancelOrderForm>(dialog)();
};
