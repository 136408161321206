import axios from 'axios';
import moment from 'moment';
import { useState } from 'react';

import { Check, Clear } from '@mui/icons-material';
import { Input } from '@mui/material';

import TextBlock from '../../Components/Layout/TextBlock';
import ListItem from '../../Components/ListItem';
import ListItemAction from '../../Components/ListItemAction';
import ListItemActions from '../../Components/ListItemActions';
import ListItemContent from '../../Components/ListItemContent';
import dateFormats from '../../system/dateFormats';
import { Note, ThemeColor, Vehicle } from '../../system/Domain';

type NewProps = {
	note : Partial<Note>
	new : true
	cancel : () => void
	save : (vehicle : Vehicle) => void
}

type ReadProps = {
	new? : false
	note : Note
}

interface CommonProps {
	vehicle : Vehicle
}

type SpecificProps = NewProps | ReadProps;

const RemarketingNoteListItem = (props : CommonProps & SpecificProps) => {
	const [text, setText] = useState<string>("");
	const [saving, setSaving] = useState(false);

	let color : ThemeColor = "info";
	if (props.new) {
		color = "primary";
	}

	const create = async () => {
		if (!props.new || saving) return;

		setSaving(true);

		try {
			const { data: vehicle } = await axios.post<Vehicle>(`/api/vehicles/${props.vehicle.id}/remarketing/notes`, {
				text: text
			});

			props.save(vehicle);
		} finally {
			setSaving(false);
		}
	};

	const cancel = () => {
		if (props.new) {
			props.cancel();
		}
	};

	return (
		<ListItem color={color}>
			{props.new && (
				<ListItemActions color={color}>
					<>
						<ListItemAction
							icon={<Check/>}
							onClick={create}
						/>
						<ListItemAction
							icon={<Clear/>}
							onClick={cancel}
						/>
					</>
				</ListItemActions>
			)}
			<ListItemContent color={color}>
				{props.new && (
					<Input
						fullWidth
						autoFocus
						value={text}
						onChange={e => setText(e.target.value)}
						onKeyPress={e => e.key === "Enter" && !saving ? create() : undefined}
					/>
				)}
				{!props.new && (
					<TextBlock
						primary={props.note.text}
						secondary={`${props.note.user?.name} - ${moment(props.note.dateCreated).format(dateFormats.dateTime)}`}
					/>
				)}
			</ListItemContent>
		</ListItem>
	);
};

export default RemarketingNoteListItem;
