import { Box, Button, Typography } from "@mui/material";
import Layout from "../../Components/Layout/Layout";
import auth0 from "../../system/auth0";

interface Props {
	error: string;
}

export default (props: Props) => {
	if (props.error === "FORBIDDEN") {
		return (
			<Layout hideNavigation>
				<Box m={2}>
					<Typography gutterBottom>Sind zur Nutzung dieser Anwendung nicht freigeschaltet</Typography>
					<Button
						variant="contained"
						color="primary"
						onClick={() =>
							auth0.createClient().then((client) =>
								client.logout({
									returnTo: window.location.origin,
								}),
							)
						}
					>
						Erneut anmelden
					</Button>
				</Box>
			</Layout>
		);
	}
	if (props.error === "CUSTOMER-UNAUTHORIZED") {
		return (
			<Layout hideNavigation title="Zugang abgelaufen">
				<Box m={2}>
					<Typography variant="subtitle1" gutterBottom>
						Der von Ihnen verwendete Zugang ist nicht mehr gültig.
					</Typography>
					<Typography gutterBottom variant="subtitle1">
						Bitte wenden Sie sich an ihren Kundenbetreuer.
					</Typography>
				</Box>
			</Layout>
		);
	}

	return (
		<Layout hideNavigation>
			<Box m={2}>
				<Typography>Bei der Anmeldung ist ein Fehler aufgetreten: {props.error}</Typography>
			</Box>
		</Layout>
	);
};
