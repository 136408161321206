import { Checkbox } from "@mui/material";

interface Props {
	value: boolean | undefined
	set: (value: boolean | undefined) => void
}

const IndeterminateCheckbox = (props: Props) => {
	return (
		<Checkbox
			indeterminate={props.value === undefined}
			checked={props.value ?? false}
			onChange={() => {
				if (props.value === true) {
					props.set(false);
				} else if (props.value === false) {
					props.set(undefined);
				} else {
					props.set(true);
				}
			}}
		/>
	);
};

export default IndeterminateCheckbox;
