import FormElement from "./FormElement";
import FormDeltaValue from "./FormDeltaValue";
import { alpha, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import { Form, FormOptions } from "../system/useForm";
import { ReactNode, useCallback, useEffect } from "react";

const readChoice = (choice: any) => {
	switch (choice) {
		case "true":
			return true;
		case "false":
			return false;
		default:
			return choice;
	}
};

export type FormChoicesProps = {
	name: string;
	label: string | ReactNode;
	choices: any;
	form: Form;
	options?: FormOptions;
	sort?: boolean;
	disabled?: boolean;
};

export default ({ name, label, choices, form, options, sort = false, disabled = undefined }: FormChoicesProps) => {
	const theme = useTheme();
	const darkTheme = theme.palette.mode === "dark";
	const color = darkTheme ? theme.palette.common.white : theme.palette.common.black;

	const styles = {
		color: alpha(color, 0.5),
		"&.Mui-selected": {
			backgroundColor: alpha(theme.palette.primary.main, 0.7),
			"&:hover": {
				backgroundColor: alpha(theme.palette.primary.main, 0.7),
			},
		},
		"&.Mui-disabled": {
			color: alpha(color, 0.38),
			"&.Mui-selected": {
				color: color,
			},
		},
		selected: {}, // memo: this one triggers the selected color choice as referenced in root
		error: {
			backgroundColor: alpha(theme.palette.error.main, 0.5),
			borderColor: alpha(color, 0.3),
			color: alpha(color, 0.8),
			"&:hover": {
				backgroundColor: alpha(theme.palette.error.main, 0.5),
				color: alpha(color, 0.8),
			},
		},
	};
	const value = form.getValue(name);
	const deltaValue = form.getDeltaValue(name);
	const onChange = useCallback(
		(event, value) => {
			if (disabled) {
				return;
			}

			form.setValue(name, readChoice(value), true);
		},
		[name, form.setValue, disabled],
	);

	useEffect(() => {
		form.register(name, options);

		return () => {
			form.unregister(name);
		};
	}, [name]);

	if (form.deltaMode && value === deltaValue) {
		return null;
	}

	const entries = Object.keys(choices).map((key) => ({
		choice: readChoice(key),
		label: choices[key],
	}));

	const entry = entries.find((e) => e.choice === value);
	const deltaEntry = entries.find((e) => e.choice === deltaValue);

	if (sort) {
		entries.sort((a, b) => (a.label > b.label ? 1 : -1));
	}

	const error = !!form.errors[name];

	return (
		<FormElement label={label} error={error}>
			<ToggleButtonGroup value={value} size="small" exclusive onChange={onChange}>
				{/*  Mark to look with Max */}
				{entries.map((e) => (
					<ToggleButton
						key={e.choice}
						value={e.choice}
						disabled={disabled ?? form.readOnly}
						sx={[styles, error && styles.error]}
					>
						<Typography variant="caption">{e.label}</Typography>
					</ToggleButton>
				))}
			</ToggleButtonGroup>
			{form.deltaMode && (
				<FormDeltaValue
					deltaValue={deltaEntry ? deltaEntry.label : "Keine Angabe"}
					value={entry ? entry.label : "Keine Angabe"}
				/>
			)}
		</FormElement>
	);
};
