import { CircularProgress } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props {
	isLoading?: boolean;
}

export default ({ isLoading = true, children }: PropsWithChildren<Props>) => (
	<>
		{isLoading && <CircularProgress size={48} />}
		{!isLoading && children}
	</>
);
