import { Vehicle } from "../system/Domain";
import useUser from "../system/useUser";
import moment from "moment";
import TextBlock from "./Layout/TextBlock";
import dateFormats from "../system/dateFormats";

interface Props {
	vehicle : Vehicle
}

const VehicleBookmarkDisplay = (props:Props) => {
	const [user] = useUser();

	const userBookmarks = props.vehicle.bookmarks?.filter(f => f.user?.id === user?.id);

	if (userBookmarks.length === 0) {
		return (<></>);
	}

	const bookmark = userBookmarks.sort((a, b) => moment(a.dateCreated) < moment(b.dateCreated) ? 1 : -1)[0];

	return (
		<TextBlock
			primary={!!bookmark.remark ? bookmark.remark : <em>ohne Kommentar</em>}
			secondary={moment(bookmark.dateCreated).format(dateFormats.dateTime)}
		/>
	);
};

export default VehicleBookmarkDisplay;
