import { useParams } from "react-router-dom";
import { useState } from "react";
import { CustomerOrder } from "./Domain";
import useAsyncEffect from "./useAsyncEffect";
import axios from "axios";

const useCustomerOrderFromMatch = (): [CustomerOrder | null | undefined, () => void, (customerOrder: CustomerOrder) => void] => {
	const { id } = useParams<{ id: string }>();
	const [customerOrder, setCustomerOrder] = useState<CustomerOrder | null | undefined>(null);

	useAsyncEffect(async () => {
		if (!id) {
			setCustomerOrder(null);
		} else {
			await load();
		}
	}, [id]);

	const load = async () => {
		const { data: co } = await axios.get<CustomerOrder[]>(`/api/orders/customers`, {
			params: {
				id: id
			}
		});

		if (co.length > 0) {
			setCustomerOrder(co[0]);
		} else {
			setCustomerOrder(undefined);
		}
	};

	const refresh = async () => {
		setCustomerOrder(null);

		await load();
	};

	const updateCustomerOrder = (customerOrder: CustomerOrder) => setCustomerOrder(customerOrder);

	return [customerOrder, refresh, updateCustomerOrder];
};

export default useCustomerOrderFromMatch;
