import { Dispatch, SetStateAction } from "react";
import Tile from "../../Components/Tiles/Tile";
import { ReturnOrder, ReturnOrderTerminationStatus } from "../../system/Domain";
import TerminatedReturnOrderListItem from "./TerminatedReturnOrderListItem";

interface Props {
	returnOrder: ReturnOrder
	disabled?: boolean
	isLoading: boolean
	setIsLoading: Dispatch<SetStateAction<boolean>>
	saveTerminationStatus: (terminationStatus: Partial<ReturnOrderTerminationStatus>) => void
	addTerminationStatus: (terminationStatus: Partial<ReturnOrderTerminationStatus>) => void
	newTerminationStatus: Partial<ReturnOrderTerminationStatus> | undefined
	setNewTerminationStatus: (terminationStatus: Partial<ReturnOrderTerminationStatus> | undefined) => void
}

const TerminatedReturnOrderTile = (props: Props) => {
	let currentTermination = props.returnOrder.terminationStatus;

	return (
		<>
			{props.returnOrder.status === "Processed" && !currentTermination && props.newTerminationStatus && (
				<TerminatedReturnOrderListItem
					termination={props.newTerminationStatus}
					new
					disabled={props.isLoading}
					cancel={() => props.setNewTerminationStatus(undefined)}
					returnOrder={props.returnOrder}
					save={props.saveTerminationStatus}
					add={props.addTerminationStatus}
				/>
			)}
			{props.returnOrder.status === "Terminated" && currentTermination && (
				<Tile title="Aussetzung">
					<TerminatedReturnOrderListItem
						termination={currentTermination}
						returnOrder={props.returnOrder}
						disabled
						save={props.saveTerminationStatus}
						add={props.addTerminationStatus}
					/>
				</Tile>
			)}
		</>
	);
};

export default TerminatedReturnOrderTile;
