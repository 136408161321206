import useUser from "../../system/useUser";
import { Billing, UserRole } from "../../system/Domain";

export interface BillingPermissions {
	canView : boolean
	canCreate : boolean
	canApprove : boolean
	isRestrictedToHisCompany : boolean
	canClose : boolean
	canReset : boolean
	canDelete : boolean

	canViewInvoices : (billing : Billing) => boolean
	canUploadInvoices : (billing : Billing) => boolean
}

export default () : BillingPermissions => {
	const [, , hasRole, , hasAnyRole] = useUser();

	const permissions : BillingPermissions = {
		canView: hasAnyRole([UserRole.AldUser, UserRole.AldManager, UserRole.ServiceAccounting]),
		canCreate: hasAnyRole([UserRole.AldManager, UserRole.ServiceAccounting]),
		canApprove: hasRole(UserRole.AldManager),
		isRestrictedToHisCompany: !hasRole(UserRole.AldManager) && hasRole(UserRole.ServiceAccounting),
		canClose: hasAnyRole([UserRole.AldManager, UserRole.ServiceAccounting]),
		canReset: hasAnyRole([UserRole.AldManager, UserRole.ServiceAccounting]),
		canDelete: hasAnyRole([UserRole.AldManager, UserRole.ServiceAccounting]),

		canViewInvoices: billing =>
			hasAnyRole([UserRole.AldManager, UserRole.ServiceAccounting])
			&& billing.status !== "Created",

		canUploadInvoices: billing =>
			hasAnyRole([UserRole.AldManager, UserRole.ServiceAccounting])
			&& billing.status === "Closed"
	};

	return permissions;
}
