import { v4 as uuidv4 } from "uuid";
import useUser from "system/useUser";
import { useRecoilState } from "recoil";
import useHotkeys from "system/useHotkeys";
import { alpha, Box, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";
import { UserRole } from "../../system/Domain";
import Highlighter from "react-highlight-words";
import { navigationAtom } from "system/atoms/navigation";
import { ReactElement, useCallback, useMemo } from "react";

interface Props {
	roles?: Array<UserRole>;
	icon?: ReactElement;
	title: string;
	description?: string;
	companies?: string[]
	path?: string;
	onClick?: () => any;
}

export default ({ title, description, icon, roles, path, onClick, companies }: Props) => {
	const history = useHistory();
	const theme = useTheme();
	const isDarkMode = theme.palette.mode === "dark";
	const id = useMemo(() => uuidv4(), []);

	const navigate = useCallback(() => {
		if (onClick) {
			onClick();
			return;
		}

		if (path) {
			history.push(path);
		}
	}, [path]);

	const [navigation] = useRecoilState(navigationAtom);
	const selected = navigation.selected === id;

	const searchTokens = navigation.filter
		.toLowerCase()
		.split(" ")
		.filter((t) => !!t);
	const isFiltered = searchTokens.length > 0 && searchTokens.every((t) => title.toLowerCase().indexOf(t) === -1);

	useHotkeys(
		"enter",
		() => {
			console.log(`enter shortcut in nav item ${title} ${id}`);

			if (!selected || isFiltered) {
				return;
			}

			console.log(`navigating ${title} ${id}`);
			navigate();
		},
		[navigate, selected, isFiltered],
	);

	const [user, , hasRole, , hasAnyRole] = useUser();

	const hasCorrectCompany = !companies || companies.includes(user.company?.name);

	if (!!roles && (!hasAnyRole(roles) || !hasCorrectCompany) && !hasRole(UserRole.AldAdmin)) {
		return null;
	}

	if (isFiltered) {
		return null;
	}

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				borderRadius: theme.spacing(1),
				padding: theme.spacing(1),
				paddingLeft: theme.spacing(2),
				paddingRight: theme.spacing(2),
				gap: theme.spacing(1),
				width: 250,
				lineHeight: "1em",
				alignItems: "center",
				cursor: "pointer",
				backgroundColor: isDarkMode
					? alpha(theme.palette.common.white, 0.025)
					: alpha(theme.palette.text.primary, 0.025),
				outline: "none",
				"&:hover": {
					backgroundColor: alpha(theme.palette.primary.main, 0.1),
				},
				"&:focus": {
					backgroundColor: alpha(theme.palette.primary.main, 0.5),
				},
				"& mark": {
					backgroundColor: alpha(theme.palette.primary.main, 0.5),
					color: alpha(theme.palette.common.white, 0.9),
				},
				...(selected && {
					backgroundColor: alpha(theme.palette.primary.main, 0.3),
					"&:hover": {
						backgroundColor: alpha(theme.palette.primary.main, 0.3),
					},
				}),
			}}
			onClick={navigate}
			tabIndex={1}
			data-navigation-id={id}
			data-navigation-title={title}
		>
			{icon && (
				<Box
					sx={{
						color: alpha(theme.palette.text.primary, 0.8),
					}}
				>
					{icon}
				</Box>
			)}
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: theme.spacing(1),
				}}
			>
				<Box
					sx={{
						color: alpha(theme.palette.text.primary, 0.9),
						fontSize: "0.9rem",
						fontWeight: 300,
					}}
				>
					<Highlighter searchWords={searchTokens} textToHighlight={title} />
				</Box>
				{description && (
					<Box
						sx={{
							fontSize: "0.8rem",
							color: theme.palette.text.disabled,
						}}
					>
						{description}
					</Box>
				)}
			</Box>
		</Box>
	);
};
