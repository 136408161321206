import { useHistory, useLocation } from 'react-router';

import { Videocam } from '@mui/icons-material';

import TooltipIconButton from '../../../Components/TooltipIconButton';

export default () => {
	const history = useHistory();
	const location = useLocation();
	const path = '/orders/transports/scan';

	return (
		<TooltipIconButton
			title="Auftrag scannen"
			onClick={() => history.push(path)}
			color={location.pathname === path ? "secondary" : "inherit"}
		>
			<Videocam />
		</TooltipIconButton>
	);
};
