import { styled, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import formatCurrency from "system/formatCurrency";
import formatPercentage from "system/formatPercentage";
import useVatHistory from "system/useVatHistory";
import { PricingRequest } from "../../../system/Domain";

interface Props {
	pricingRequest: PricingRequest
}

const ItalicTableCell = styled(TableCell)({
	fontStyle: "italic"
});
const BoldTableCell = styled(TableCell)({
	fontWeight: "bold"
});

const PricingDisplay = (props: Props) => {
	const [, getGrossAmount, getFullVatRate] = useVatHistory();

	const handlingFee = 150;
	const vatDate = moment(props.pricingRequest.datePurchase || props.pricingRequest.dateCreated);
	const vatRate = getFullVatRate(vatDate);
	const completePrice = props.pricingRequest.price.netAmount + handlingFee;

	return (
		<Table>
			<colgroup>
				<col width="auto" />
				<col width="auto" />
				<col width="auto" />
				<col width="auto" />
			</colgroup>
			<TableHead>
				<TableRow>
					<TableCell>Beschreibung</TableCell>
					<TableCell>Preis zzgl. Mwst.</TableCell>
					<TableCell>Mwst.</TableCell>
					<TableCell>Preis inkl. Mwst.</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				<TableRow>
					<ItalicTableCell>Kaufpreis</ItalicTableCell>
					<ItalicTableCell>{formatCurrency(props.pricingRequest.price.netAmount)}</ItalicTableCell>
					<TableCell>{formatPercentage(vatRate)}</TableCell>
					<ItalicTableCell>{formatCurrency(props.pricingRequest.price.grossAmount)}</ItalicTableCell>
				</TableRow>
				<TableRow>
					<ItalicTableCell>Verkaufs-Sonderhandling</ItalicTableCell>
					<ItalicTableCell>{formatCurrency(handlingFee)}</ItalicTableCell>
					<TableCell>{formatPercentage(vatRate)}</TableCell>
					<ItalicTableCell>{formatCurrency(getGrossAmount(vatDate, handlingFee))}</ItalicTableCell>
				</TableRow>
				<TableRow>
					<BoldTableCell>Gesamtkosten</BoldTableCell>
					<BoldTableCell>{formatCurrency(completePrice)}</BoldTableCell>
					<TableCell>{formatPercentage(vatRate)}</TableCell>
					<BoldTableCell>{formatCurrency(getGrossAmount(vatDate, completePrice))}</BoldTableCell>
				</TableRow>
			</TableBody>
		</Table>
	)
};

export default PricingDisplay;
