import SidebarGroup from "../../Components/Sidebar/SidebarGroup";
import Sidebar from "../../Components/Sidebar/Sidebar";
import SidebarButton from "../../Components/Sidebar/SidebarButton";
import useAction from "../../system/useAction";
import { useHistory } from "react-router";
import { NewVehicleRequest } from "./NewVehicle";
import axios from "axios";

export interface Props {
	request: NewVehicleRequest;
}

const isRequestReady = (request: NewVehicleRequest) => {
	switch (request.source) {
		case "Leasman":
			return !!request.leasman?.id && !!request.leasman?.vin;
		case "Fleetnet":
			return !!request.fleetnet?.id && !!request.fleetnet?.vin;
		case "Catalog":
			return (
				!!request.catalog &&
				!!request.catalog?.id &&
				!!request.catalog?.vin &&
				!!request.catalog?.plateNumber &&
				!!request.catalog?.dateFirstRegistered
			);
	}
};

export default ({ request }: Props) => {
	const history = useHistory();

	const [create, isCreating] = useAction(async () => {
		if (!isRequestReady(request)) {
			return;
		}

		let finalRequest: Partial<NewVehicleRequest> | null = null;

		switch (request.source) {
			case "Leasman":
				if (!request.leasman) {
					return;
				}

				await axios.post(`/api/leasman/vehicles`, {
					vins: [request.leasman.vin],
				});

				finalRequest = {
					leasman: request.leasman,
				};

				break;
			case "Fleetnet":
				if (!request.fleetnet) {
					return;
				}

				await axios.post(`/api/fleetnet/vehicles`, {
					id: request.fleetnet.id,
				});

				finalRequest = {
					fleetnet: request.fleetnet,
				};

				break;
			case "Catalog":
				if (!request.catalog) {
					return;
				}

				await axios.post(`/api/catalog/vehicles`, {
					ids: [request.catalog.id],
				});

				finalRequest = {
					catalog: request.catalog,
				};

				break;
		}

		if (!!finalRequest) {
			const { data: vehicle } = await axios.post(`/api/vehicles`, finalRequest);
			history.push(`/vehicles/${vehicle.id}`);
		}
	});

	const isReady = isRequestReady(request);

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarButton
					label="Fahrzeug anlegen"
					color="primary"
					disabled={!isReady || isCreating}
					onClick={create}
				/>
				{/*<Box mt={2}>*/}
				{/*	<pre>*/}
				{/*		{JSON.stringify(request, null, "\t")}*/}
				{/*	</pre>*/}
				{/*</Box>*/}
			</SidebarGroup>
		</Sidebar>
	);
};
