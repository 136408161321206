import axios from "axios";
import useUser from "../../system/useUser";
import Select from "../../Components/Select";
import { Plate } from "../../Components/Plate";
import useVehicle from "../../system/useVehicle";
import { FormEvent, useEffect, useState } from "react";
import Layout from "../../Components/Layout/Layout";
import { useHistory, useParams } from "react-router";
import usePureCompanies from "../../system/usePureCompanies";
import { VehicleService, VehicleServiceType } from "../../system/Domain";
import useVehicleServiceTypes from "../../system/useVehicleServiceTypes";
import useVehicleServicePermissions from "./useVehicleServicePermissions";
import { Box, Button, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import useAsyncEffect from "system/useAsyncEffect";

interface VehicleServiceForm {
	company?: string
	vehicleServiceType: string
	businessLine: string
	additionalInformation: string
}

export default () => {
	const { id } = useParams<{ id: string }>();
	const [vehicle] = useVehicle(id);
	const [vehicleServiceTypes] = useVehicleServiceTypes();
	const history = useHistory();
	const [openServicesIds, setOpenServicesIds] = useState<string[]>([]);
	const [busy, setBusy] = useState(false);

	const [companies] = usePureCompanies();
	const [user] = useUser();

	const vehicleServicePermissions = useVehicleServicePermissions();

	const [formState, setFormState] = useState<VehicleServiceForm>({
		vehicleServiceType: "",
		company: "",
		businessLine: "",
		additionalInformation: ""
	});

	const company = () => {
		return companies?.find(f => f.name === user?.company.name);
	};

	useEffect(() => {
		if (!user)
			return;

		if (vehicleServicePermissions.isRestrictedToHisCompany) {
			setFormState({
				...formState,
				company: user.company.name
			});
		}
	}, [user]);

	useAsyncEffect(async (cancelToken) => {
		const { data: openServices } = await axios.get<VehicleService[]>(`/api/vehicles/services`, {
			params: {
				vehicleId: id,
				isOpen: true
			},
			cancelToken: cancelToken.getAxiosToken()
		});

		setOpenServicesIds(openServices.map(os => os.serviceType.id));
	}, [id]);

	const onChanged = (field: string) => (event: any) => setFormState({
		...formState,
		[field]: event.target.value
	});

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		if (!vehicle) {
			return;
		}

		setBusy(true);
		try {
			const response = await axios.post<VehicleService>("/api/vehicles/services", {
				vehicleId: vehicle.id,
				assignedCompany: formState.company,
				vehicleServiceType: formState.vehicleServiceType,
				businessLine: formState.businessLine,
				additionalInformation: formState.additionalInformation
			});

			history.push(`/vehicles/${vehicle.id}/services/${response.data.id}`);
		} finally {
			setBusy(false);
		}
	};

	const selectedVehicleServiceType = () => {
		return vehicleServiceTypes?.find(f => f.id === formState.vehicleServiceType);
	};

	const vehicleServiceTypeFilter = (vs: VehicleServiceType): boolean => {
		return vs.id !== "LotVehicleHandling"
			&& vs.id !== "PicturePreparation"
			&& vs.id !== "UnRegistration"
			&& vs.id !== "LotVehicleExit"
			&& vs.id !== "HuAu"
			&& vs.id !== "HuAu-Follow-up"
			&& vs.id !== "UsedCarSeal";
	};

	return (
		<Layout
			title="Neue Tätigkeit"
			plateNumber={vehicle?.plateNumber}
		>
			<Box>
				{(!vehicle || !companies || !vehicleServiceTypes) && (
					<CircularProgress size={48} />
				)}
				{vehicle && companies && vehicleServiceTypes && (
					<form onSubmit={handleSubmit}>
						<Grid container spacing={4}>
							<Grid item xs={12}>
								<Box mb={6}>
									<Plate plateNumber={vehicle.plateNumber} />
									<Typography
										variant="caption"
										component="div"
									>
										{vehicle.type?.description} · {vehicle.vin}
									</Typography>
								</Box>
								<Box mb={2}>
									<Select
										label="Servicetätigkeit"
										name="serviceType"
										fullWidth
										autoFocus
										value={formState.vehicleServiceType}
										onChange={onChanged("vehicleServiceType")}
									>
										<option value="" />
										{!vehicleServicePermissions.isRestrictedToHisCompany && vehicleServiceTypes.filter(f => vehicleServiceTypeFilter(f)).sort((a, b) => a.text.localeCompare(b.text)).map(c => (
											<option
												key={c.id}
												value={c.id}
												disabled={openServicesIds.some(id => id === c.id)}
											>
												{c.text}
											</option>
										))}
										{vehicleServicePermissions.isRestrictedToHisCompany && company()?.services?.requestableTypes?.filter(f => vehicleServiceTypeFilter(f)).sort((a, b) => a.text.localeCompare(b.text)).map(c => (
											<option
												key={c.id}
												value={c.id}
												disabled={openServicesIds.some(id => id === c.id)}
											>
												{c.text}
											</option>
										))}
									</Select>
								</Box>
								<Box mb={2}>
									<Select
										label="Dienstleister"
										name="company"
										fullWidth
										value={formState.company}
										disabled={vehicleServicePermissions.isRestrictedToHisCompany}
										onChange={onChanged("company")}
									>
										<option value="" />
										{companies.map(c => (
											<option key={c.name} value={c.name}>{c.name}</option>
										))}
									</Select>
								</Box>
								{selectedVehicleServiceType()?.canHaveRequestText && (
									<Box mb={2}>
										<TextField
											fullWidth
											variant="outlined"
											value={formState.additionalInformation}
											label="Zusatzinformationen"
											onChange={onChanged("additionalInformation")}
										/>
									</Box>
								)}
							</Grid>
							<Grid item container direction="row-reverse" spacing={2}>
								<Grid item>
									<Button
										variant="outlined"
										color="primary"
										type="submit"
										disabled={busy}
									>
										Hinzufügen
									</Button>
								</Grid>
								<Grid item>
									<Button
										variant="outlined"
										color="secondary"
										onClick={() => history.goBack()}
									>
										Zurück
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</form>
				)}
			</Box>
		</Layout>
	);
};
