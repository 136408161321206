import axios from "axios";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useHistory } from "react-router";

import { Done, GetApp, Refresh, RotateLeft, Videocam } from "@mui/icons-material";
import { Box, Divider, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import Sort, { SortItem } from "Components/Sort";
import Sidebar from "../../Components/Sidebar/Sidebar";
import SidebarButton from "../../Components/Sidebar/SidebarButton";
import SidebarForm from "../../Components/Sidebar/SidebarForm";
import SidebarGroup from "../../Components/Sidebar/SidebarGroup";
import { Inventory, RemarketingStatus, VehiclePerspective } from "../../system/Domain";
import useCompoundPlaces from "../../system/useCompoundPlaces";
import useCompoundProcessSteps from "../../system/useCompoundProcessSteps";
import useVehicleExport from "../../system/useVehicleExport";
import { VehiclesSidebarProps } from "../Vehicles/VehiclesSidebar";

const sortItems: SortItem[] = [
	{
		title: "Inventurdatum",
		field: "inventoryDate",
	},
	{
		title: "Status zugewiesen",
		field: "statusAssigned",
	},
	{
		title: "Platz zugewiesen",
		field: "placeAssigned",
	},
	{
		title: "Eingang",
		field: "stockIn",
	},
];

export interface InventorySidebarProps extends VehiclesSidebarProps {
	inventory: Inventory;
	setInventory: Dispatch<SetStateAction<Inventory | undefined>>;
}

export default ({ query, setQuery, place, setPlace, resetQuery, inventory, setInventory }: InventorySidebarProps) => {
	const history = useHistory();
	const [processSteps] = useCompoundProcessSteps();
	const [compoundPlaces] = useCompoundPlaces();

	const [locations, setLocations] = useState<string[]>([]);
	const [groups, setGroups] = useState<{ [k: string]: string[] }>({});

	useEffect(() => {
		if (!compoundPlaces) return;

		const locations = compoundPlaces
			.map((item) => item.compound.name)
			.filter((value, index, self) => self.indexOf(value) === index);
		const groups: { [k: string]: string[] } = {};

		locations.forEach((location) => {
			groups[location] = compoundPlaces
				?.filter((f) => f.compound.name === location)
				.map((lotSpace) => lotSpace.groups || [])
				.flat()
				.filter((value, index, self) => self.indexOf(value) === index);
		});

		setLocations(locations);
		setGroups(groups);
	}, [compoundPlaces]);

	const [exportState, triggerExport] = useVehicleExport({
		...query,
		inventoryId: inventory.id,
	});

	const [closing, setClosing] = useState<boolean>(false);
	const close = async () => {
		setClosing(true);

		try {
			const { data: updatedInventory } = await axios.post<Inventory>(`/api/inventories/${inventory!.id}/close`);
			setInventory(updatedInventory);
		} finally {
			setClosing(false);
		}
	};

	const [reconciling, setReconciling] = useState<boolean>(false);
	const reconcile = async () => {
		setReconciling(true);

		try {
			const { data: updatedInventory } = await axios.post<Inventory>(
				`/api/inventories/${inventory!.id}/reconcile`,
			);
			setInventory(updatedInventory);
		} finally {
			setReconciling(false);
		}
	};

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarButton
					color="primary"
					startIcon={<Videocam />}
					onClick={() => history.push(`/inventories/${inventory?.id}/scan`)}
					label="Fahrzeug scannen"
				/>
				{!inventory.inventoryDate && (
					<SidebarButton
						startIcon={<Done />}
						disabled={closing || inventory.missingVehicles > 0}
						color="primary"
						onClick={close}
						label="Abschließen"
					/>
				)}
				<SidebarButton
					startIcon={<Refresh />}
					disabled={reconciling}
					onClick={reconcile}
					label="Aktualisieren"
				/>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarForm>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Perspektive</InputLabel>
						<Select
							value={query.perspective}
							onChange={(e) =>
								setQuery((q) => ({
									...q,
									perspective: e.target.value as VehiclePerspective,
								}))
							}
						>
							<MenuItem value="InventoryDone">Erfasst</MenuItem>
							<MenuItem value="InventoryOpen">Offen</MenuItem>
						</Select>
					</FormControl>
					<Sort
						items={sortItems}
						value={query.sort}
						onChange={(sort) =>
							setQuery((q) => ({
								...q,
								sort,
							}))
						}
					/>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Status</InputLabel>
						<Select
							value={query.status || "-"}
							onChange={(e) => {
								let value = e.target.value as string | undefined;
								if (value === "-") {
									value = undefined;
								}

								setQuery((q) => ({
									...q,
									status: value as RemarketingStatus,
								}));
							}}
						>
							<MenuItem value="-">Alle</MenuItem>
							{processSteps?.map((s) => (
								<MenuItem key={s.status} value={s.status}>
									{s.displayName}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Standort</InputLabel>
						<Select
							value={query.locationName || "-"}
							onChange={(e) => {
								const value = e.target.value as string;
								setQuery((q) => ({
									...q,
									locationName: value === "-" ? undefined : value,
									placeGroup: undefined,
									placeId: undefined,
								}));
							}}
						>
							<MenuItem value="-">Alle</MenuItem>
							{locations && (
								<Box my={2}>
									<Divider />
								</Box>
							)}
							{locations?.map((g) => (
								<MenuItem key={`locationName:${g}`} value={g}>
									{g}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Platz</InputLabel>
						<Select
							value={place || "-"}
							onChange={(e) => {
								const value = e.target.value as string;
								setPlace(value === "-" ? undefined : value);
							}}
							disabled={!query.locationName}
						>
							<MenuItem value="-">Alle</MenuItem>
							{query.locationName && groups[query.locationName]?.length > 0 && (
								<Box my={2}>
									<Divider />
								</Box>
							)}
							{query.locationName &&
								groups[query.locationName]?.length > 0 &&
								groups[query.locationName].map((g) => (
									<MenuItem key={g} value={`group:${g}`}>
										{g}
									</MenuItem>
								))}
							{compoundPlaces?.some((f) => f.compound.name === query.locationName) && (
								<Box my={2}>
									<Divider />
								</Box>
							)}
							{compoundPlaces
								?.filter((f) => f.compound.name === query.locationName)
								.map((p) => (
									<MenuItem key={`${p.id}`} value={p.id}>
										{p.name}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</SidebarForm>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarButton
					startIcon={<GetApp />}
					label="Excel-Export"
					onClick={triggerExport}
					disabled={exportState.loading}
				/>
				<SidebarButton startIcon={<RotateLeft />} label="Filter zurücksetzen" onClick={resetQuery} />
			</SidebarGroup>
		</Sidebar>
	);
};
