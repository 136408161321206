import {
	Assignment,
	CarCrash,
	Create,
	Explore,
	FileCopy,
	LocalShipping,
	NoCrash,
	PermIdentity,
	PrecisionManufacturing,
	Receipt,
	RemoveRoad
} from "@mui/icons-material";
import { UserRole } from "system/Domain";
import LayoutNavigationItem from "../Components/Layout/LayoutNavigationItem";
import LayoutNavigationSection from "../Components/Layout/LayoutNavigationSection";

export default () => {
	return (
		<>
			<LayoutNavigationSection
				title="Aufträge"
			>
				<LayoutNavigationItem
					roles={[UserRole.AldManager, UserRole.LotManager]}
					icon={<Explore />}
					title="Dashboard"
					path="/orders/dashboard"
				/>
				<LayoutNavigationItem
					roles={[UserRole.AldUser, UserRole.LotManager]}
					icon={<LocalShipping />}
					title="Transportaufträge"
					path="/orders/transports"
				/>
				<LayoutNavigationItem
					roles={[UserRole.AldUser, UserRole.DamageAssessor]}
					icon={<NoCrash />}
					title="Gutachtenaufträge"
					path="/orders/assessments"
				/>
				<LayoutNavigationItem
					roles={[UserRole.AldUser]}
					icon={<RemoveRoad />}
					title="Abmeldeaufträge"
					path="/orders/unregistrations"
				/>
				<LayoutNavigationItem
					roles={[UserRole.AldUser]}
					icon={<PrecisionManufacturing />}
					title="Werkstattaufträge"
					path="/orders/repairs"
				/>
				<LayoutNavigationItem
					roles={[UserRole.AldUser]}
					icon={<Receipt />}
					title="Rechnungen"
					path="/invoices"
				/>
				<LayoutNavigationItem
					roles={[UserRole.AldUser, UserRole.LotManager]}
					icon={<Receipt />}
					title="Packlisten"
					path="/arrivaltransmissions/dad"
				/>
				<LayoutNavigationItem
					roles={[UserRole.AldUser]}
					icon={<CarCrash />}
					title="Anlieferungen"
					path="/vehicles/arrivals"
				/>
			</LayoutNavigationSection>
			<LayoutNavigationSection
				title="Rückholung"
			>
				<LayoutNavigationItem
					roles={[UserRole.AldUser]}
					icon={<Assignment />}
					title="Rückholungen"
					path="/orders/returns"
				/>
				<LayoutNavigationItem
					roles={[UserRole.AldUser]}
					icon={<FileCopy />}
					title="Beauftragungen"
					path="/orders/returns/sessions"
				/>
				<LayoutNavigationItem
					roles={[UserRole.AldUser]}
					icon={<Create />}
					title="Importe"
					path="/orders/imports"
				/>
				<LayoutNavigationItem
					roles={[UserRole.AldUser]}
					icon={<PermIdentity />}
					title="Kundenaufträge"
					path="/orders/customers"
				/>
			</LayoutNavigationSection>
		</>
	);
};
