import axios from "axios";
import { formatYesNo } from "Components/LeasmanContract";
import SyncTileAction from "Components/Tiles/SyncTileAction";
import CostsTextGroup from "Components/Vehicles/CostsTextGroup";
import DriveTextGroup from "Components/Vehicles/DriveTextGroup";
import HighlightsTextGroup from "Components/Vehicles/HighlightsTextGroup";
import ModelTextGroup from "Components/Vehicles/ModelTextGroup";
import TechnicalDetailsTextGroup from "Components/Vehicles/TechnicalDetailsTextGroup";
import { formatPriceWithRanges } from "Pages/VehicleSales/SaleDetailsTile";
import TextGroup from "Pages/VehicleSales/TextGroup";
import TextLine from "Pages/VehicleSales/TextLine";
import { Dispatch, SetStateAction } from "react";
import formatCurrency from "system/formatCurrency";
import Tile from "../../Components/Tiles/Tile";
import TileContent from "../../Components/Tiles/TileContent";
import { LeasmanVehicle, PricingRequest, PricingRequestStatus, ThemeColor, Vehicle, VehicleAssessment } from "../../system/Domain";
import formatDate from "../../system/formatDate";
import formatDateTime from "../../system/formatDateTime";

interface Props {
	request: PricingRequest
	setRequest: Dispatch<SetStateAction<PricingRequest | undefined>>
	vehicle?: Vehicle
	leasmanVehicle?: LeasmanVehicle
	assessment?: VehicleAssessment
}

export const formatPricingRequestStatus = (status: PricingRequestStatus) => {
	switch (status) {
		case "Created":
			return "Erstellt";
		case "Requested":
			return "Angefragt";
		case "Pricing":
			return "Bepreisung";
		case "Priced":
			return "Bepreist";
		case "Completed":
			return "Abgeschlossen";
		case "Withdrawn":
			return "Zurückgezogen";
		default:
			return status;
	}
};

export const paintPricingRequestStatus = (status: PricingRequestStatus): ThemeColor => {
	switch (status) {
		case "Created":
			return "info";
		case "Requested":
			return "secondary";
		case "Pricing":
			return "primary";
		case "Priced":
		case "Completed":
			return "success";
		case "Withdrawn":
			return "error";
		default:
			return "info";
	}
};

const formatOrigin = (request: PricingRequest): string => {
	if (!!request.armadaTicket) {
		return "Armada";
	}

	return "Carlos";
}

const PricingRequestDetailsTile = ({ request, setRequest, vehicle, leasmanVehicle, assessment }: Props) => {
	const type = request.type;
	const historyEntry = vehicle?.history.entries.length > 0 ? vehicle.history.entries[vehicle.history.entries.length - 1] : null;
	const hasRemarketingStop = vehicle?.remarketing.isStopped;

	const syncRequest = async () => {
		const { data } = await axios.post<PricingRequest>(`/api/pricing-requests/${request.id}/sync`);
		setRequest(data);
	};

	return (
		<Tile title="Informationen">
			<TileContent>
				<TextGroup title="Allgemein">
					<TextLine
						label="Status"
						value={hasRemarketingStop ? "Gestoppt" : formatPricingRequestStatus(request.status)}
						color={hasRemarketingStop ? "error" : paintPricingRequestStatus(request.status)}
						bold={hasRemarketingStop}
					/>
					<TextLine label="Herkunft" value={formatOrigin(request)} />
					<TextLine label="Erstellt am" value={formatDateTime(request.dateCreated)} />
					<TextLine label="Letzte Änderung" value={formatDateTime(request.dateUpdated)} />
					<TextLine label="RKV" value={formatYesNo(request.hasBuybackAgreement)} />
					<TextLine label="Gesamtrestwert" value={formatCurrency(request.residualValue)} />
					<TextLine label="Praiceman v3 Carmarket" value={formatPriceWithRanges(request.praicemanPrediction?.carmarket)} />
					<TextLine label="Praiceman v3 Retail" value={formatPriceWithRanges(request.praicemanPrediction?.retail)} />
				</TextGroup>
				<TextGroup title="Fahrzeug">
					<TextLine label="Hersteller" value={type?.make?.name} />
					<TextLine label="Modell" value={type?.model?.name} />
					<TextLine
						label="FIN"
						value={request.vin}
					/>
					<TextLine
						label="Schwacke Code"
						value={type?.id}
					/>
					<TextLine
						label="Erstzulassung"
						value={formatDate(leasmanVehicle?.dateFirstRegistered)}
					/>
					<TextLine label="Standort" value={!!historyEntry ? `${historyEntry?.processStep.status}` : "Leasingnehmer"} />
					<TextLine
						label="Eingang in Dorfmark"
						value={formatDate(vehicle?.history.currentStockDateRange?.from)}
					/>
				</TextGroup>

				<ModelTextGroup type={type} assessment={assessment} vehicle={vehicle} />

				<TechnicalDetailsTextGroup type={type} />

				<DriveTextGroup type={type} />

				<CostsTextGroup type={type} leasmanVehicle={leasmanVehicle} />

				<HighlightsTextGroup highlights={request.highlights} />

				<TextGroup title="Zulassungsinformationen">
					<TextLine label="Zulassung" value={formatDate(undefined)} />
					<TextLine label="Abmeldung" value={formatDate(vehicle?.dateUnregistered)} />
				</TextGroup>

				<SyncTileAction reference={request.sync} action={syncRequest} />
			</TileContent>
		</Tile>
	);
};

export default PricingRequestDetailsTile;
