import { Check, Close, ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { downloadAssessmentAttachment } from "Pages/VehicleSales/SaleLayout";
import { useCallback, useState } from "react";
import { useHistory } from "react-router";
import ListItemChip from "../../Components/ListItemChip";
import ListItemChips from "../../Components/ListItemChips";
import Tile from "../../Components/Tiles/Tile";
import TileContent from "../../Components/Tiles/TileContent";
import { Vehicle, VehicleAssessment, VehicleAssessmentAttachment } from "../../system/Domain";
import formatCurrency from "../../system/formatCurrency";
import formatDateTime from "../../system/formatDateTime";
import formatNumber from "../../system/formatNumber";
import TextGroup from "../VehicleSales/TextGroup";
import TextLine from "../VehicleSales/TextLine";
import RemarketingAssesmentDamages from "./RemarketingAssesmentDamages";

interface Props {
	vehicle: Vehicle;
	assessment?: VehicleAssessment;
}

const RemarketingAssessmentTile = ({ vehicle, assessment }: Props) => {
	const theme = useTheme();
	const history = useHistory();
	const [damageCount, setDamageCount] = useState<number>();
	const [damagesExpanded, setDamagesExpanded] = useState(!vehicle.remarketing?.previousOwners);

	const hasOrderId = !!vehicle.remarketing.assessment?.orderId;
	const isAssessed = vehicle.remarketing.isAssessed;
	const assessmentHasRequiredFields = assessment?.market1Price && assessment.market2Price;
	const assessmentDetailsLink = () => history.push(`/orders/assessments/${vehicle.remarketing.assessment?.orderId}`);

	const assesmentDoc = assessment?.attachments.find((aat) => aat.type == "Gutachten");
	const quickAssessmentWithPictureSet = assessment?.attachments.find((aat) => aat.type == "Kurzgutachten mit Fotosatz");

	const AttachmentChip = ({ attachment }: { attachment: VehicleAssessmentAttachment }) => {
		const [isDownloading, setIsDownloading] = useState(false);
		const downloadAttachment = useCallback(
			async (attm: VehicleAssessmentAttachment) => {
				try {
					setIsDownloading(true);

					await downloadAssessmentAttachment(assessment, attm);
				} finally {
					setIsDownloading(false);
				}
			},
			[assessment],
		);

		const limitText = (text: string, maxLen: number) => {
			if (text.length > maxLen) {
				text =
					text.substring(0, maxLen / 2 - 2) +
					"..." +
					text.substring(text.length - maxLen / 2 - 2, text.length);
			}

			return text;
		};

		return (
			<ListItemChip
				label={
					<Box sx={{ display: "flex", flexDirection: "column" }}>
						<Typography align="center" color={theme.palette.info.main} sx={{ fontSize: "0.7rem" }}>
							{attachment.type}
						</Typography>
						<Typography variant="inherit" align="center">
							{limitText(attachment.storageFile?.filename, 15)}
						</Typography>
					</Box>
				}
				onClick={() => downloadAttachment(attachment)}
				loading={isDownloading}
			/>
		);
	};

	return (
		<Tile title="Gutachten">
			<TileContent>
				{!hasOrderId && <TextGroup title="Gutachten nicht beauftragt" />}

				{hasOrderId && !isAssessed && <TextGroup title="Gutachten beauftragt" />}

				{isAssessed && (
					<TextGroup title="Gutachten erstellt">
						<TextLine
							label="Beauftragt"
							value={formatDateTime(vehicle.remarketing.assessment.dateStarted)}
						/>
						<TextLine
							label="Abgeschlossen"
							value={formatDateTime(vehicle.remarketing.assessment.dateCompleted)}
						/>
						{!!assessment && (
							<Box mt={1}>
								<TextLine label="Reparaturkosten" value={formatCurrency(assessment.repairCosts)} />
								<TextLine label="Minderwerte" value={formatCurrency(assessment.depreciationAmount)} />
								<TextLine label="Km-Stand" value={formatNumber(assessment.mileage)} />
								<TextLine label="Farbe" value={assessment.color?.name} />
							</Box>
						)}
					</TextGroup>
				)}
				{isAssessed && (
					<TextGroup title="Downloads">
						<ListItemChips sx={{ paddingTop: 0.5 }}>
							{assesmentDoc && <AttachmentChip attachment={assesmentDoc} />}
							{quickAssessmentWithPictureSet && <AttachmentChip attachment={quickAssessmentWithPictureSet} />}
						</ListItemChips>
					</TextGroup>
				)}

				<Box mt={1}>
					<ListItemChips>
						{isAssessed && (
							<ListItemChip
								label={`${damageCount ?? "?"} ${damageCount === 1 ? "Reparatur" : "Reparaturen"}`}
								icon={damagesExpanded ? <ExpandLess /> : <ExpandMore />}
								active={damagesExpanded}
								onClick={() => setDamagesExpanded((de) => !de)}
							/>
						)}
						<ListItemChip
							label={isAssessed ? "Begutachtet" : "Nicht begutachtet"}
							icon={isAssessed ? <Check /> : <Close />}
							color={isAssessed ? "success" : "error"}
							onClick={isAssessed ? () => history.push(`/vehicles/${vehicle.id}/assessments`) : undefined}
						/>
						<ListItemChip
							label={hasOrderId ? "Auftrag vorhanden" : "Kein Auftrag"}
							icon={hasOrderId ? <Check /> : <Close />}
							color={hasOrderId ? "primary" : "error"}
							onClick={
								hasOrderId
									? assessmentDetailsLink
									: () =>
										history.push(`/orders/assessments/new`, {
											plateNumber: vehicle.plateNumber,
										})
							}
						/>
						{hasOrderId && isAssessed && assessment && !assessmentHasRequiredFields && (
							<ListItemChip
								label="Gutachten unvollständig"
								color="error"
								icon={<Close />}
								onClick={assessmentDetailsLink}
								title={"Market1Price und Market2Price müssen gesetzt sein"}
							/>
						)}
					</ListItemChips>
					{damagesExpanded && isAssessed && (
						<Box mt={1} maxHeight={350} overflow="auto">
							<Box mt={1}>
								<Divider />
							</Box>
							<RemarketingAssesmentDamages
								assessment={assessment}
								onLoaded={(count: number) => setDamageCount(count)}
							/>
						</Box>
					)}
				</Box>
			</TileContent>
		</Tile>
	);
};

export default RemarketingAssessmentTile;
