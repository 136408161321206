import { useState } from 'react';
import Layout from "./Layout";
import { Box, Button, Grid } from "@mui/material";
import useForm from "../../system/useForm";
import FormText from "../FormText";
import axios from "axios";
import { setUserMessage } from "../../system/reducers/userMessage/actions";
import { useDispatch } from "react-redux";
import useAction from "../../system/useAction";

interface SupportTicket {
	subject? : string
	message? : string
}

export default () => {
	const dispatch = useDispatch();
	const [ticket, setTicket] = useState<SupportTicket>({});
	const form = useForm({
		values: ticket,
		setValues: setTicket
	});

	const [createTicket, isCreating] = useAction(async () => {
		await axios.post(`/api/tickets`, ticket);

		setTicket({});
		dispatch(setUserMessage(`Ihre Anfrage wurde zu uns gesendet`, "success"));
	});

	return (
		<Layout title="Hilfe">
			<Box maxWidth={500}>
				<Grid container spacing={3}>
					<FormText
						form={form}
						name="subject"
						label="Betreff"
						fullWidth
						readOnly={isCreating}
						options={{
							required: true
						}}
					/>
					<FormText
						form={form}
						name="message"
						label="Nachricht"
						multiline
						rows={10}
						fullWidth
						readOnly={isCreating}
						options={{
							required: true
						}}
					/>
					<Grid item xs={12}>
						<Button
							variant="outlined"
							color="primary"
							onClick={createTicket}
							disabled={form.hasErrors || !ticket?.subject || !ticket?.message || isCreating}
						>
							Ticket erstellen
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Layout>
	);
}
