import { VehicleAssessmentRejectionTopic } from "../../system/Domain";

function formatVehicleAssessmentRejectionTopic(topic : VehicleAssessmentRejectionTopic) {
	switch (topic) {
		case "DamagePosition":
			return "Schadensposition";
		case "DamageAmount":
			return "Schadensumme";
		case "Equipment":
			return "Ausstattung";
		case "PictureProof":
			return "Fotonachweis";
		case "CostEstimation":
			return "KVA wird benötigt";
		case "DatValues":
			return "DAT Werte";
		case "Mileage":
			return "Kilometerstand";
		case "Vin":
			return "Fahrgestellnummer";
		case "NotFinished":
			return "Vorzeitig abgeschlossen";
		case "Others":
			return "Andere";
		default:
			return topic;
	}
}

export default formatVehicleAssessmentRejectionTopic;
