import { atom } from "recoil";

const menuAtom = atom({
	key: "menu",
	default: {
		open: false,
	},
});

export { menuAtom };
