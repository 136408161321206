import { Launch } from "@mui/icons-material";
import ListItem from "Components/ListItem";
import ListItemAction from "Components/ListItemAction";
import ListItemActions from "Components/ListItemActions";
import ListItemChip from "Components/ListItemChip";
import ListItemChips from "Components/ListItemChips";
import ListItemContent from "Components/ListItemContent";
import moment from "moment";
import { useHistory } from "react-router";
import formatDate from "system/formatDate";
import formatNumber from "system/formatNumber";
import { formatVehiclePricingRequestPurchaser } from "system/formatVehiclePricingRequestPurchaser";
import InlineList from "../../Components/InlineList";
import TextBlock from "../../Components/Layout/TextBlock";
import { Plate } from "../../Components/Plate";
import Text from "../../Pages/VehicleSales/Text";
import { PricingRequest, ThemeColor } from "../../system/Domain";
import formatCurrency from "../../system/formatCurrency";
import { formatPricingRequestStatus, paintPricingRequestStatus } from "./PricingRequestDetailsTile";

interface Props {
	request: PricingRequest
}

export default ({ request }: Props) => {
	const history = useHistory();

	const handleClick = () => history.push(`/sales/pricing-requests/${request.id}`);
	let color: ThemeColor = "info";

	return (
		<ListItem color={color}>
			<ListItemActions>
				<ListItemAction
					icon={<Launch />}
					color="inherit"
					onClick={handleClick}
				/>
			</ListItemActions>
			<ListItemContent
				onClick={handleClick}
			>
				<TextBlock
					primary={(
						<Plate plateNumber={request.plateNumber} style={{ zoom: 0.6 }} />
					)}
					secondary={
						!!request.type && `${request.type.make.name} ${request.type.model.version} ${request.type.model.variant}`
					}
					tertiary={
						<InlineList>
							{!!request.purchaser && (
								<Text color="info" component="span">
									{formatVehiclePricingRequestPurchaser(request.purchaser)}
								</Text>
							)}
							{!!request.mileage && (
								<span>{formatNumber(request.mileage)}km</span>
							)}
							{!!request.datePurchase && formatDate(request.datePurchase)}
							{!!request.price && formatCurrency(request.price.netAmount)}
						</InlineList>
					}
				/>
				<ListItemChips>
					{!!request.armadaTicket && (
						<ListItemChip
							label={`Armada · ${request.armadaTicket.partner}`}
							color="secondary"
						/>
					)}
					<ListItemChip
						label={formatPricingRequestStatus(request.status)}
						color={paintPricingRequestStatus(request.status)}
					/>
					<ListItemChip
						label={
							<InlineList>
								{request.armadaTicket?.messages?.length > 0 && !!request.armadaTicket.messages[0].creator
									? request.armadaTicket.messages[0].creator.fullName
									: request.createdBy.name
								}
								{moment(request.dateCreated).fromNow()}
							</InlineList>
						}
						color="info"
					/>
				</ListItemChips>
			</ListItemContent>
		</ListItem>
	);
}
