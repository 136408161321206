import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Tile from "Components/Tiles/Tile";
import TileContent from "Components/Tiles/TileContent";
import TileDivider from "Components/Tiles/TileDivider";
import _, { Dictionary } from "lodash";
import moment from "moment";
import { Moment } from "moment";
import { useEffect, useState } from "react";
import { RemarketingStatus } from "system/Domain";
import RemarketingStatusActivityDisplay from "./RemarketingStatusActivityDisplay";

export type ActivityType = "generic" | "remarketing.status" | "remarketing.place";

export interface BaseActivity {
	type: ActivityType;
	date: Moment;
	orderId: number;
}

export interface GenericActivity extends BaseActivity {
	type: "generic";
	payload: {
		message: string;
	};
}

export interface RemarketingStatusActivity extends BaseActivity {
	type: "remarketing.status";
	payload: {
		previousStatus?: RemarketingStatus;
		status: RemarketingStatus;
	};
}

export interface RemarketingPlaceActivity extends BaseActivity {
	type: "remarketing.place";
	payload: {
		previousPlaceId?: string;
		placeId: string;
	};
}

export type Activity = GenericActivity | RemarketingStatusActivity | RemarketingPlaceActivity;

export type UnitOfTime = "day" | "week" | "month" | "year";

const formatDate = (date: Moment, perspective: UnitOfTime): string => {
	switch (perspective) {
		case "day":
			return date.format("D. MMMM YYYY");
		case "week":
			return `KW ${date.format("w")}, ${date.startOf("week").format("D")}.-${date.endOf("week").format("D. MMMM YYYY")}`;
		case "month":
			return date.format("MMMM YYYY");
		case "year":
			return `Jahr ${date.format("YYYY")}`;
		default:
			return date.format("YYYY-MM-DD");
	}
};

type GroupedActivities = Dictionary<Dictionary<Activity[]>>;

interface Props {
	activities: Activity[];
}

export default ({ activities }: Props) => {
	const [unitOfTime, setUnitOfTime] = useState<UnitOfTime>("month");
	const [groupedActivities, setGroupedActivities] = useState<GroupedActivities>({});

	useEffect(() => {
		const groupedActivities = _(activities)
			.orderBy(["date", "orderId"], ["asc", "asc"])
			.groupBy((a) => formatDate(moment(a.date), unitOfTime))
			.mapValues((g) => _.groupBy(g, "type"))
			.value();

		console.log(groupedActivities);
		setGroupedActivities(groupedActivities);
	}, [activities, unitOfTime]);

	return (
		<Tile>
			<TileContent>
				<Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
					<FormControl sx={{ m: 1, minWidth: 120 }} size="small">
						<InputLabel id="time">Zeiteinheit</InputLabel>
						<Select
							labelId="time"
							id="time"
							value={unitOfTime}
							label="Zeiteinheit"
							onChange={(e) => setUnitOfTime(e.target.value as UnitOfTime)}
						>
							<MenuItem value="day">Tag</MenuItem>
							<MenuItem value="week">Woche</MenuItem>
							<MenuItem value="month">Monat</MenuItem>
							<MenuItem value="year">Jahr</MenuItem>
						</Select>
					</FormControl>
				</Box>
				<TileDivider />
				<Box sx={{ display: "flex", flexDirection: "column", gap: 6 }}>
					{Object.entries(groupedActivities)
						.reverse()
						.map(([date, typeGroups]) => (
							<Box key={date}>
								<Box sx={{ fontWeight: 300, fontSize: "1rem", pb: 2 }}>{date}</Box>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										gap: 0.5,
										pl: 2,
										fontSize: "0.8rem",
									}}
								>
									{Object.entries(typeGroups)
										.reverse()
										.map(([type, activities]) => {
											switch (type) {
												case "generic":
													return (
														<Box
															key={type}
															sx={{
																display: "flex",
																flexDirection: "column",
																gap: 0.5,
															}}
														>
															{(activities as GenericActivity[]).reverse().map((a) => (
																<Box>
																	[{a.date.format("DD.MM.YYYY HH:mm")}]{" "}
																	{a.payload.message}
																</Box>
															))}
														</Box>
													);
												case "remarketing.status":
													return (
														<RemarketingStatusActivityDisplay
															key={type}
															activities={activities as RemarketingStatusActivity[]}
														/>
													);
												case "remarketing.place":
													return null;
												default:
													return <Box>{type}</Box>;
											}
										})}
								</Box>
							</Box>
						))}
				</Box>
			</TileContent>
		</Tile>
	);
};
