import { LeasmanVehicle } from "../../system/Domain";
import Tile from "../../Components/Tiles/Tile";
import TileContent from "../../Components/Tiles/TileContent";
import formatNumber from "../../system/formatNumber";
import formatDate from "../../system/formatDate";
import TextGroup from "./TextGroup";
import TextLine from "./TextLine";
import { Box } from "@mui/material";

interface Props {
	leasmanVehicle?: LeasmanVehicle;
}

export default ({ leasmanVehicle }: Props) => {
	const contract = leasmanVehicle?.contract;

	const emptyPlaceholder = "-";

	return (
		<Tile title="Leasingvertrag">
			<TileContent>
				{!contract && <Box>-</Box>}
				{!!contract && (
					<>
						<TextGroup title="Allgemein">
							<TextLine label="Vertragsnummer" value={contract.id.toString()} />
							<TextLine label="Mandant" value={leasmanVehicle?.tenant} />
							<TextLine label="Brand" value={leasmanVehicle?.brand} />
							<TextLine label="Beginn" value={formatDate(contract.begin.date)} />
							<TextLine label="Vorraussichtliches Ende" value={formatDate(contract.end.date)} />
							<TextLine label="Mietstopp" value={formatDate(contract.stop?.date)} />
							<TextLine label="Laufzeit" value={formatNumber(contract.duration)} />
							<TextLine label="Laufleistung" value={formatNumber(contract.mileage)} />
						</TextGroup>
						<TextGroup title="Rückgabe">
							<TextLine label="Datum" value={formatDate(contract.return.date)} />
							<TextLine label="KM-Stand" value={formatNumber(contract.return.mileage)} />
						</TextGroup>
						<TextGroup title="Kunde">
							<TextLine label="Nummer" value={contract.customer.id.toString()} />
							<TextLine
								label="Name"
								value={
									`${contract.customer.name1} ${contract.customer.name2}`.trim() || emptyPlaceholder
								}
							/>
							<TextLine label="E-Mail" value={contract.customer.email || emptyPlaceholder} />
							<TextLine label="Telefon" value={contract.customer.phone || emptyPlaceholder} />
							<TextLine label="Fax" value={contract.customer.fax || emptyPlaceholder} />
							<TextLine
								label="Adresse"
								value={
									<Box>
										<Box>{contract.customer.street}</Box>
										<Box>
											{contract.customer.postal} {contract.customer.city}
										</Box>
										<Box>
											{contract.customer.country} ({contract.customer.countryIsoCode})
										</Box>
									</Box>
								}
							/>
							{/*<TextLine label="Straße" value={contract.customer.street} />*/}
							{/*<TextLine label="Stadt" value={`${contract.customer.postal} ${contract.customer.city}`.trim()} />*/}
							{/*<TextLine label="Land" value={contract.customer.country} />*/}
						</TextGroup>
					</>
				)}
			</TileContent>
		</Tile>
	);
};
