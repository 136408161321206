import axios from "axios";
import Layout from "Components/Layout/Layout";
import { useRef, useState } from "react";
import { CompoundPlace } from "system/Domain";
import useAsyncEffect from "system/useAsyncEffect";
import CompoundDesignerSidebar from "./CompoundDesignerSidebar";
import MapComponent from "./MapComponent";
import { MapHandler } from "./MapHandler";
import { CompoundPlaceDesign } from "./mapUtils";

export interface CompoundDesignerSettings {
	showInactiveCompoundPlaces: boolean;
	showActiveCompoundPlaces: boolean;
	enableCompoundPlacePulling: boolean;
	autoSave: boolean;
}

export interface CompoundPlaceMerger {
	isMergeMode: boolean;
	compoundPlace1: CompoundPlace;
	compoundPlace2: CompoundPlace;
}

export default () => {
	const [compoundPlace, setCompoundPlace] = useState<CompoundPlaceDesign>();
	const [compoundPlaces, setCompoundPlaces] = useState([]);
	const [uploading, setUploading] = useState<boolean>(false);
	const [CompoundDesignerSettings, setCompoundDesignerSettings] = useState<CompoundDesignerSettings>({
		autoSave: false,
		enableCompoundPlacePulling: false,
		showInactiveCompoundPlaces: false,
		showActiveCompoundPlaces: true,
	});

	const [compoundPlaceMerger, setCompoundPlaceMerger] = useState<CompoundPlaceMerger>({
		isMergeMode: false,
		compoundPlace1: null,
		compoundPlace2: null,
	});

	const mapHandler = useRef(
		new MapHandler(compoundPlaces, setCompoundPlace, setCompoundPlaces, compoundPlaceMerger, setUploading),
	);

	useAsyncEffect(async () => {
		const { data } = await axios.get("/api/compounds/places");
		setCompoundPlaces(data);
	}, []);

	return (
		<Layout
			title="Compound Designer"
			sidebar={
				<CompoundDesignerSidebar
					CompoundDesignerSettings={CompoundDesignerSettings}
					setCompoundDesignerSettings={setCompoundDesignerSettings}
					compoundPlace={compoundPlace}
					setCompoundPlace={setCompoundPlace}
					compoundPlaceMerger={compoundPlaceMerger}
					setCompoundPlaceMerger={setCompoundPlaceMerger}
					mapHandler={mapHandler.current}
					isUploading={uploading}
					setUploading={setUploading}
				/>
			}
		>
			<MapComponent
				compoundPlaces={compoundPlaces}
				CompoundDesignerSettings={CompoundDesignerSettings}
				compoundPlace={compoundPlace}
				compoundPlaceMerger={compoundPlaceMerger}
				setCompoundPlaceMerger={setCompoundPlaceMerger}
				mapHandler={mapHandler.current}
			/>
		</Layout>
	);
};
