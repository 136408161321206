import axios from "axios";
import fileDownload from "js-file-download";
import { useAsyncFn } from "react-use";
import { AsyncFnReturn } from "react-use/lib/useAsyncFn";
import { OrderQuery } from "./useOrderQuery";
import parseContentDispositionFilename from "./parseContentDispositionFilename";

export default (query : OrderQuery) : AsyncFnReturn<() => Promise<void>> =>
	useAsyncFn(async () => {
		const response = await axios.get(`/api/orders/transports`, {
			responseType: "blob",
			params: {
				...query,
				format: "xlsx"
			}
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);
	}, [query]);
