import { cloneElement, ReactElement } from "react";
import { alpha, Button, ButtonProps, Tooltip, useTheme } from "@mui/material";

interface Props extends ButtonProps {
	icon?: ReactElement;
	tooltip?: string;
}

export default ({ icon, tooltip, ...rest }: Props) => {
	const theme = useTheme();

	if (!!icon) {
		icon = cloneElement(icon, {
			sx: { fontSize: "1.1rem" },
		});
	}

	return (
		<>
			{tooltip && (
				<Tooltip title={tooltip}>
					<Button
						sx={{
							flexGrow: 1,
							minWidth: "auto",
							padding: theme.spacing(1),
							borderRadius: 0,
							borderTopStyle: "solid",
							borderTopColor: alpha(theme.palette.common.white, 0.1),
							borderTopWidth: 1,
							"&:first-child": {
								borderTopWidth: 0,
							},
							color: "inherit",
						}}
						{...rest}
					>
						{icon}
					</Button>
				</Tooltip>
			)}
			{!tooltip && (
				<Button
					sx={{
						flexGrow: 1,
						minWidth: "auto",
						padding: theme.spacing(1),
						borderRadius: 0,
						borderTopStyle: "solid",
						borderTopColor: alpha(theme.palette.common.white, 0.1),
						borderTopWidth: 1,
						"&:first-child": {
							borderTopWidth: 0,
						},
						color: "inherit",
					}}
					{...rest}
				>
					{icon}
				</Button>
			)}
		</>
	);
};
