import { CircularProgress } from "@mui/material";
import axios, { AxiosRequestConfig } from "axios";
import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import FluentGrid from "../../Components/FluentGrid";
import { axiosDownload } from "../../system/axiosDownload";
import { KpisGroup, PricingRequestsKpis, PricingRequestsKpisQuery, WorkItemType } from "../../system/Domain";
import { setWorkSession } from "../../system/reducers/workSessions/actions";
import useAxiosEffect from "../../system/useAxiosEffect";
import KpisGroupTile from "../../Components/KpisGroupTile";

export default () => {
	const dispatch = useDispatch();

	const [kpis, setKpis] = useState<PricingRequestsKpis>();

	const loadKpis = async (axiosConfig: AxiosRequestConfig = {}) => {
		const { data } = await axios.get<PricingRequestsKpis>(`/api/pricing-requests/kpis`, axiosConfig);
		setKpis(data);
	};

	const startKpisSession = async (group: KpisGroup<PricingRequestsKpisQuery>) => {
		let workItemType: WorkItemType = "PricingRequest";

		const { data: workSession } = await axios.post(`/api/pricing-requests/kpis/work-session`, {
			title: group.name,
			workItemType,
			query: group.query
		});

		if (workSession) {
			dispatch(setWorkSession(workSession));
		}
	};

	const exportKpis = async (group: KpisGroup<PricingRequestsKpisQuery>) => {
		const response = await axios.post(`/api/pricing-requests/kpis/export`, group.query, {
			responseType: "blob"
		});

		axiosDownload(response, `${moment().format('YYYY-MM-DD')} ${group.name}.xlsx`);
	};

	useAxiosEffect(loadKpis, []);

	if (!kpis) {
		return <CircularProgress size={48} />;
	}

	return (
		<FluentGrid itemWidth={350} itemGap={6}>
			<KpisGroupTile
				group={kpis.requests}
				startSession={startKpisSession}
				exportKpis={exportKpis}
			/>
		</FluentGrid>
	);
};
