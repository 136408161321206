import { CircularProgress } from "@mui/material";
import axios from "axios";
import VehicleSearch from "Components/VehicleSearch";
import OrdersNavigation from 'Navigation/OrdersNavigation';
import { useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import useAsyncEffect from "system/useAsyncEffect";
import Layout from "../../../Components/Layout/Layout";
import NotificationSnackbar from "../../../Components/Layout/NotificationSnackbar";
import confirm from "../../../Dialogs/confirm";
import { AssessmentOrder, ReturnOrder, Vehicle } from "../../../system/Domain";
import AssessmentOrdersForm, { AssessmentOrderFormType } from "./AssessmentOrdersForm";

const AssessmentOrdersNew = (props: any) => {
	const { vehicleId } = useParams<{ vehicleId: string }>();
	const inputState: AssessmentOrderFormType = (props.location && {...props.location.state, "vehiclePlateNumber" : undefined}) || undefined;
	const [error, setError] = useState<string>('');
	const [vehicle, setVehicle] = useState<Vehicle>();
	const history = useHistory();
	const [vehicleSearchFullText] = useState<string>( props.location?.state?.vehiclePlateNumber || '');

	useAsyncEffect(async () => {
		if (!vehicleId) {
			return;
		}

		const { data } = await axios.get(`/api/vehicles/${vehicleId}`);
		onVehicleSelect(data);
	}, [vehicleId]);

	const onVehicleSelect = async (vehicle: Vehicle) => {
		const { data: assessmentOrders } = await axios.get<AssessmentOrder[]>('/api/orders/assessments', {
			params: {
				vehicleId: vehicle.id
			}
		});

		if (assessmentOrders?.length >= 1) {
			if (assessmentOrders.some(s => s.status === "Created")) {
				setError("Es gibt für dieses Fahrzeug bereits einen offenen Gutachtenauftrag");
				return;
			} else if (assessmentOrders.some(s => s.status === "Processed")) {
				const result = await confirm("Achtung - es liegt bereits ein verarbeiteter Gutachtenauftrag für das Fahrzeug vor. Wollen Sie wirklich fortfahren?", "Warnung");

				if (!result.ok) {
					return;
				}
			}
		}

		const { data: returnOrders } = await axios.get<ReturnOrder[]>('/api/orders/returns', {
			params: {
				vehicleId: vehicle.id
			}
		});

		if (returnOrders?.length >= 1) {
			if (returnOrders.some(s => s.doAssessment && (s.status === "Imported"))) {
				setError("Es gibt für dieses Fahrzeug bereits einen offenen Rückholauftrag mit Gutachtenerstellung");
				return;
			}
		}

		if (!vehicleId) {
			history.push(`/vehicles/${vehicle.id}/orders/assessments/new`, inputState);
			return;
		}

		setVehicle(vehicle);
	};

	const handleClearError = () => {
		setError('');
	};

	return (
		<Layout
			plateNumber={vehicle?.plateNumber}
			title="Neuer Gutachtenauftrag"
			navigation={<OrdersNavigation />}
		>
			<NotificationSnackbar
				message={error}
				onClose={handleClearError}
				color="error"
			/>
			{!vehicleId && !vehicle && (
				<VehicleSearch
					onClick={onVehicleSelect}
					fullText={vehicleSearchFullText}
				/>
			)}
			{!!vehicleId && !vehicle && (
				<CircularProgress size={48} />
			)}
			{vehicle && (
				<AssessmentOrdersForm vehicle={vehicle} formData={inputState} />
			)}
		</Layout>
	);
};

export default AssessmentOrdersNew;
