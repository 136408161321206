import { useState } from "react";
import { Options, TooltipFormatterContextObject } from "highcharts";
import { colors } from "@mui/material";

export default () => {
	const [options, setOptions] = useState<Options>({
		chart: {
			height: 250,
			backgroundColor: "transparent"
		},
		credits: {
			enabled: false
		},
		title: {
			text: undefined
		},
		legend: {
			enabled: false
		},
		xAxis: {
			type: "datetime",
			title: {
				text: null
			},
			lineColor: "rgba(255, 255, 255, 0.1)",
			tickColor: "rgba(255, 255, 255, 0.1)",
			gridLineColor: "rgba(255, 255, 255, 0.05)",
			gridLineDashStyle: "Dash"
		},
		yAxis: {
			title: {
				text: null
			},
			lineColor: "rgba(255, 255, 255, 0.1)",
			tickColor: "rgba(255, 255, 255, 0.1)",
			gridLineColor: "rgba(255, 255, 255, 0.05)",
			gridLineDashStyle: "Dash"
		},
		tooltip: {
			// formatter: tooltip => tooltip.
			// pointFormatter: p => formatCurrency(p.y)
		},
		colors: [
			colors.blue[500],
			colors.green[500],
			colors.amber[500],
			colors.pink[500],
			colors.yellow[500],
			colors.lightBlue[500],
			colors.lightGreen[500],
			colors.orange[500],
			colors.red[500]
		],
		plotOptions: {
			column: {
				borderWidth: 0
			},
			line: {
				lineWidth: 1,
				connectNulls: true,
				marker: {
					symbol: "circle",
					enabled: false
				}
			}
		}
	});

	const formatters = {
		default: (context: TooltipFormatterContextObject, title: string, value: string) => {
			return `
				<span style="font-size: 10px">${title}</span>
				<br/>
				<span style="color: ${context.point.color}">\u25CF</span>
				<span>${context.series.name}: <b>${value}</b></span>`;
		}
	};

	return [options, formatters, setOptions] as const;
};
