import { DependencyList, useEffect } from "react";
import useEvents from "./useEvents";

export default (channels : string[], deps? : DependencyList) => {
	const events = useEvents();

	useEffect(() => {
		events.subscribe(channels);
		return () => events.unsubscribe(channels);
	}, deps);
}
