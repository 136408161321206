import axios from 'axios';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import {
    CloudDownload, CloudUpload, Delete, Done, DoneAll, Explore, FormatListNumbered, Restore
} from '@mui/icons-material';

import Sidebar from '../../Components/Sidebar/Sidebar';
import SidebarButton from '../../Components/Sidebar/SidebarButton';
import SidebarGroup from '../../Components/Sidebar/SidebarGroup';
import ask from '../../Dialogs/ask';
import { BillingClient, BillingInvoice } from '../../system/Domain';
import useRunAction from '../../system/useRunAction';
import useVehicleServiceExport from '../VehicleServices/useVehicleServiceExport';
import { BillingPropsWithSetter } from './useBilling';
import useBillingInvoiceDownload from './useBillingInvoiceDownload';
import useBillingInvoiceUpload from './useBillingInvoiceUpload';
import useBillingsPermissions from './useBillingPermissions';

export interface BillingSidebarProps extends BillingPropsWithSetter {
	client?: BillingClient
	invoices: BillingInvoice[]
}

export default ({ billing, setBilling, client, invoices }: BillingSidebarProps) => {
	const history = useHistory();
	const { client: routeClient } = useParams<{ id: string, client?: string }>();
	const permissions = useBillingsPermissions();

	const [actionRunning, runAction] = useRunAction();

	const [uploadInvoice, isUploadingInvoice] = useBillingInvoiceUpload();
	const [, downloadInvoices, isDownloadingInvoices] = useBillingInvoiceDownload();
	const [isDownloadingServices, downloadServices] = useVehicleServiceExport({
		sort: "dateCompleted:asc",
		billingId: billing.id,
		billingClient: client?.name
	});

	const confirmAction = (question: string, action: () => Promise<any>) => runAction(async () => {
		const { yes } = await ask(question);
		if (yes) {
			await action();
		}
	});

	const closeBilling = () => confirmAction("Soll die Abrechnung geschlossen werden?", async () => {
		const { data: updatedBilling } = await axios.post(`/api/billings/${billing!.id}/close`);
		setBilling(updatedBilling);
	});

	const deleteBilling = () => confirmAction("Soll die Abrechnung gelöscht werden?", async () => {
		await axios.delete(`/api/billings/${billing!.id}`);
		history.push("/billings");
	});

	const approveBilling = () => confirmAction("Soll die Abrechnung genehmigt werden?", async () => {
		const { data: updatedBilling } = await axios.post(`/api/billings/${billing!.id}/approve`);
		setBilling(updatedBilling);
	});

	const resetBilling = () => confirmAction("Soll die Abrechnung zurückgesetzt werden?", async () => {
		const { data: updatedBilling } = await axios.post(`/api/billings/${billing!.id}/reset`);
		setBilling(updatedBilling);
	});

	return (
		<Sidebar>
			<SidebarGroup>
				{routeClient && (
					<SidebarButton
						startIcon={<Explore />}
						label="Übersicht"
						onClick={() => history.push(`/billings/${billing.id}`)}
					/>
				)}
				{!routeClient && (
					<SidebarButton
						startIcon={<FormatListNumbered />}
						label="Alle Abrechnungen"
						onClick={() => history.push(`/billings`)}
					/>
				)}
			</SidebarGroup>
			<SidebarGroup>
				{billing.status === "Created" && permissions.canClose && (
					<SidebarButton
						color="primary"
						startIcon={<Done />}
						label="Abschließen"
						onClick={closeBilling}
						disabled={actionRunning}
					/>
				)}
				{billing.status === "Closed" && permissions.canApprove && (
					<SidebarButton
						color="primary"
						startIcon={<DoneAll />}
						label="Genehmigen"
						onClick={approveBilling}
						disabled={actionRunning}
					/>
				)}
				{billing.status === "Closed" && permissions.canReset && (
					<SidebarButton
						color="secondary"
						startIcon={<Restore />}
						label="Zurücksetzen"
						onClick={resetBilling}
						disabled={actionRunning}
					/>
				)}
				{billing.status === "Created" && permissions.canDelete && (
					<SidebarButton
						color="secondary"
						startIcon={<Delete />}
						label="Löschen"
						onClick={deleteBilling}
						disabled={actionRunning}
					/>
				)}
			</SidebarGroup>
			<SidebarGroup>
				{permissions.canViewInvoices(billing) && invoices.length > 0 && (
					<SidebarButton
						startIcon={<CloudDownload />}
						label="Rechnungen (Archiv)"
						onClick={() => downloadInvoices(billing, client?.name)}
						disabled={isDownloadingInvoices}
					/>
				)}
				{billing.status !== "Created" && (
					<SidebarButton
						startIcon={<CloudDownload />}
						label="Tätigkeiten (Excel)"
						onClick={() => downloadServices()}
						disabled={isDownloadingServices.loading}
					/>
				)}
			</SidebarGroup>
			<SidebarGroup>
				{permissions.canUploadInvoices(billing) && client && (
					<SidebarButton
						startIcon={<CloudUpload />}
						label="Rechnung hochladen"
						onClick={async (e) => {
							e.stopPropagation();
							await uploadInvoice(billing, client.name, setBilling);
						}}
						disabled={isUploadingInvoice}
					/>
				)}
			</SidebarGroup>
		</Sidebar>
	);
}
