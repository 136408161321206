import { useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../../../Components/Layout/Layout";
import axios from "axios";
import { Box, Button } from "@mui/material";
import confirm from "../../../../Dialogs/confirm";
import { ReturnOrderSession } from "../../../../system/Domain";
import Actions from "../../../../Components/Actions";
import SessionReturnOrders from "./SessionReturnOrders";
import fileDownload from "js-file-download";
import { AttachFile, Delete, Save } from "@mui/icons-material";
import { useHistory } from "react-router";
import { useAsync } from "react-use";
import OrdersNavigation from "Navigation/OrdersNavigation";

export default () => {
	const { id } = useParams<{ id: string }>();
	const [isLoading, setIsLoading] = useState(false);
	const [session, setSession] = useState<ReturnOrderSession | null>(null);

	const history = useHistory();
	const [checkedOrders, setCheckedOrders] = useState({ checkedOrdersIds: [] as string[], areAllChecked: false });

	useAsync(async () => {
		if (!id) return;

		const { data: session } = await axios.get<ReturnOrderSession>(`/api/orders/returns/sessions`, {
			params: {
				id: id
			}
		});

		if (session) {
			setSession(session[0]);
		}
	}, [id]);

	const handleDelete = async () => {
		const answer = await confirm("Wollen Sie die Sitzung wirklich löschen?");

		if (answer.ok) {
			setIsLoading(true);

			let success = false;

			try {
				const response = await axios.delete<ReturnOrderSession>(`/api/orders/returns/sessions/${id}`);
				setSession(response.data);

				success = true;
			} finally {
				setIsLoading(false);
			}

			if (success) {
				history.push(`/orders/returns/sessions`);
			}
		}
	};

	const handle = (operation: string) => async () => {
		setIsLoading(true);
		try {
			const response = await axios.post(`/api/orders/returns/sessions/${id}/${operation}`);
			setSession(response.data);
		} finally {
			setIsLoading(false);
		}
	};

	const handleExportAssignedToExcel = async () => {
		const response = await axios.get(`/api/orders/returns/sessions/${id}/transport-orders.csv?company=all`, {
			responseType: "blob"
		});

		fileDownload(response.data, `auftraege-all.csv`);
	};

	const handleDeleteSelected = async () => {
		if (checkedOrders.areAllChecked) {
			handleDelete();
		}

		const answer = await confirm("Wollen Sie die ausgewählten Einträge löschen?");
		if (answer.ok) {
			setIsLoading(true);
			try {
				await axios.post(`/api/orders/returns/sessions/${id}/delete-orders`, checkedOrders.checkedOrdersIds);
				setCheckedOrders({ checkedOrdersIds: [], areAllChecked: false });
			} finally {
				setIsLoading(false);
			}
		}
	};

	const status = session && session.status;

	if (isLoading) {
		return (
			<Layout disablePadding loading>
			</Layout>
		);
	}

	return (
		<Layout
			title="Rückholungssitzung"
			navigation={<OrdersNavigation />}
		>
			<Box mb={2}>
				<Actions>
					<Button
						variant="outlined"
						size="medium"
						color="primary"
						disabled={status === "Completed"}
						startIcon={<Delete />}
						onClick={handleDelete}
					>
						Session löschen
					</Button>
					<Button
						variant="outlined"
						size="medium"
						color="primary"
						disabled={status !== "Created"}
						startIcon={<Save />}
						onClick={handle("commit")}
					>
						Session abschließen
					</Button>
					<Button
						variant="outlined"
						size="medium"
						color="primary"
						disabled={status !== "Completed"}
						startIcon={<AttachFile />}
						onClick={handleExportAssignedToExcel}
					>
						Export
					</Button>
					{session?.status !== "Completed" && (
						<Button
							variant="outlined"
							size="medium"
							color="primary"
							disabled={checkedOrders.checkedOrdersIds.length === 0 || status === "Completed"}
							startIcon={<Delete />}
							onClick={checkedOrders.areAllChecked ? handleDelete : handleDeleteSelected}
						>
							Auswahl Löschen
						</Button>
					)}
				</Actions>
			</Box>
			{session && (
				<SessionReturnOrders
					session={session}
					checked={checkedOrders}
					setChecked={setCheckedOrders}
				/>
			)}
		</Layout>
	);
};
