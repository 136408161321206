import { Dispatch, SetStateAction } from "react";
import { CatalogVehicle, Equipment, LeasmanVehicle, Vehicle } from "../../system/Domain";
import Tile from "../../Components/Tiles/Tile";
import EquipmentListItem from "./EquipmentListItem";
import List from "Components/List";
import NewEquipmentListItem from "./NewEquipmentListItem";

interface Props {
	title : string

	vehicle : Vehicle
	setVehicle : Dispatch<SetStateAction<Vehicle | undefined>>

	equipments : Equipment[]

	leasmanVehicle? : LeasmanVehicle
	leasmanVehicles? : LeasmanVehicle[]
	catalogVehicle? : CatalogVehicle

	disabled? : boolean
	allowManualEquipment? : boolean
}

export default ({ title, vehicle, setVehicle, equipments, leasmanVehicle, catalogVehicle, disabled = false, allowManualEquipment = false } : Props) => {
	return (
		<Tile title={title}>
			<List>
				{allowManualEquipment && catalogVehicle && (
					<>
						<NewEquipmentListItem
							vehicle={vehicle}
							setVehicle={setVehicle}
							catalogVehicle={catalogVehicle}
						/>
					</>
				)}
				{equipments.map(e => (
					<EquipmentListItem
						key={e.id}
						vehicle={vehicle}
						setVehicle={setVehicle}
						equipment={e}
						catalogVehicle={catalogVehicle}
						leasmanVehicle={leasmanVehicle}
						disabled={disabled}
					/>
				))}
			</List>
		</Tile>
	);
}
