import moment from "moment";
import { ChangeEvent, FormEvent, useState } from "react";
import ThemedDialog from "./ThemedDialog";
import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";
import { DialogTitle, DialogContent, DialogActions, Button, TextField } from "@mui/material"

export interface Interval {
	dateFrom : string
	dateTo : string
}

interface GetDateDialogProps extends ReactConfirmProps<Interval | null>, Partial<Interval> {
	title : string
}

const GetIntervalDialog = ({ show, proceed, title, dateFrom, dateTo } : GetDateDialogProps) => {
	const [form, setForm] = useState({
		dateFrom: dateFrom || "",
		dateTo: dateTo || ""
	});

	const [error, setError] = useState<string | null>(null);

	const handleChange = (name : string) => (event: ChangeEvent<HTMLInputElement>) => {
		setForm({
			...form,
			[name]: event.target.value
		});
		setError(null);
	};

	const handleClose = () => {
		proceed(null);
	};

	const handleSave = async (event: FormEvent) => {
		event.preventDefault();

		const dateFrom = moment(form.dateFrom);
		const dateTo = moment(form.dateTo);

		if (!dateFrom.isValid()) {
			setError("Datum ist ungültig")
			return;
		}

		if (!dateTo.isValid()) {
			setError("Datum ist ungültig")
			return;
		}

		if (dateFrom.isSameOrAfter(dateTo)) {
			setError("Datum von darf nicht früher oder gleich Datum bis sein")
			return;
		}

		proceed({
			dateFrom: form.dateFrom,
			dateTo: form.dateTo
		});
	};

	return (
		<ThemedDialog
			open={show}
			fullWidth
			maxWidth="sm"
			onClose={handleClose}
		>
			<DialogTitle>{title}</DialogTitle>
			<form onSubmit={handleSave}>
				<DialogContent>
					<TextField
						label="Datum von"
						variant="outlined"
						type="date"
						required
						fullWidth
						error={Boolean(error)}
						helperText={error}
						value={form.dateFrom}
						onChange={handleChange("dateFrom")}
						autoFocus
						margin="normal"
					/>
					<TextField
						variant="outlined"
						type="date"
						required
						fullWidth
						value={form.dateTo}
						onChange={handleChange("dateTo")}
						margin="normal"
					/>
				</DialogContent>
				<DialogActions style={{ justifyContent: "space-between", flexDirection: "row-reverse" }}>
					<DialogActions>
						<Button
							variant="outlined"
							color="secondary"
							onClick={handleClose}
						>
							Abbrechen
						</Button>
						<Button
							variant="outlined"
							color="primary"
							type="submit"
						>
							Speichern
						</Button>
					</DialogActions>
				</DialogActions>
			</form>
		</ThemedDialog>
	);
};

export default (title : string, options : Partial<Interval>) => {
	const dialog = confirmable(props => <GetIntervalDialog {...props} {...options} title={title}/>);
	return createConfirmation<Interval | null>(dialog)();
};
