import { Box, CircularProgress } from "@mui/material";
import axios from "axios";
import fileDownload from "js-file-download";
import OrdersNavigation from 'Navigation/OrdersNavigation';
import { useState } from 'react';
import FetchNextButton from "../../../Components/FetchNextButton";
import Layout from "../../../Components/Layout/Layout";
import Search from "../../../Components/Search";
import { CustomerOrder, CustomerOrdersOverviewPerspective, CustomerOrderType } from "../../../system/Domain";
import parseContentDispositionFilename from "../../../system/parseContentDispositionFilename";
import useAsyncEffect from "../../../system/useAsyncEffect";
import useQuery, { IQuery } from "../../../system/useQuery";
import OrdersSubMenu from "../OrdersSubMenu";
import CustomerOrdersOverviewSidebar from "./CustomerOrdersOverviewSidebar";
import CustomerOrdersOverviewTable from "./CustomerOrdersOverviewTable";

export interface CustomerOrdersOverviewQuery extends IQuery {
	sort: string
	perspective: CustomerOrdersOverviewPerspective
	fullText?: string
	type?: CustomerOrderType
	choice?: CustomerOrderChoice
}

export type CustomerOrderChoice = "None" | "Accepted" | "Denied";

const CustomerOrdersOverview = () => {
	const [customerOrders, setCustomerOrders] = useState<CustomerOrder[]>();
	const [isFetching, setIsFetching] = useState(false);

	const [query, setQuery, fetchNext, resetQuery] = useQuery<CustomerOrdersOverviewQuery>('customerOrdersOverviewQuery', {
		perspective: "All",
		skip: 0,
		take: 20,
		fullText: "",
		sort: "dateCreated:desc"
	});

	useAsyncEffect(async (cancelToken) => {
		const axiosCancelTokenSource = axios.CancelToken.source();
		cancelToken.promise.then(() => axiosCancelTokenSource.cancel());

		setIsFetching(true);

		try {
			const { data: co } = await axios.get<CustomerOrder[]>(`/api/orders/customers`, {
				params: query,
				cancelToken: axiosCancelTokenSource.token
			});

			if (query.skip > 0) {
				setCustomerOrders(o => [
					...(o ?? []),
					...co
				]);
			} else {
				setCustomerOrders(co);
			}
		} finally {
			setIsFetching(false);
		}
	}, [query]);

	const triggerExport = async () => {
		const response = await axios.get(`/api/orders/customers`, {
			responseType: "blob",
			params: {
				...query,
				output: "xlsx"
			}
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);
	};

	return (
		<Layout
			title="Kundenaufträge"
			navigation={<OrdersNavigation />}
			subMenu={<OrdersSubMenu />}
			sidebar={
				<CustomerOrdersOverviewSidebar
					query={query}
					setQuery={setQuery}
					resetQuery={resetQuery}
					triggerExport={triggerExport}
				/>
			}
		>
			<Box mb={2}>
				{!customerOrders && (
					<CircularProgress size={48} />
				)}
				{customerOrders && (
					<>
						<Box mb={2}>
							<Search
								value={undefined}
								realtime={false}
								onSearch={(value) => value !== query.fullText && setQuery(q => ({
									...q,
									skip: 0,
									fullText: value
								}))}
							/>
						</Box>

						<CustomerOrdersOverviewTable
							customerOrders={customerOrders}
						/>

						<FetchNextButton
							hidden={customerOrders.length < query.take + query.skip}
							mb={3}
							onNext={fetchNext}
							disabled={isFetching}
						/>
					</>
				)}
			</Box>
		</Layout>
	);
};

export default CustomerOrdersOverview;
