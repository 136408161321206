import { Button, CircularProgress, ButtonProps } from "@mui/material";
import { UserRole } from "../../system/Domain";
import useUser from "../../system/useUser";

export interface SidebarButtonProps extends ButtonProps {
	label?: string
	useBusyWheelWhen?: boolean
	roles?: UserRole[]
}

export default ({ label, children, useBusyWheelWhen, roles, ...rest }: SidebarButtonProps) => {
	const [, ,hasRole, ,hasAnyRole] = useUser();

	if (!!roles && !hasAnyRole(roles) && !hasRole(UserRole.AldAdmin)) return null;

	return (
		<Button
			variant="outlined"
			color="inherit"
			fullWidth
			{...rest}
		>
			{useBusyWheelWhen && (
				<CircularProgress sx={{ marginRight: 2.5 }} size={16} />
			)}
			{label ?? children}
		</Button>
	);
}
