import { ReactNode, useLayoutEffect, useRef, useState } from "react";
import { FormControl, InputLabel, OutlinedInput, Select, SelectProps, FormHelperText } from "@mui/material";

export interface ExtendedSelectProps extends SelectProps {
	fullWidth?: boolean,
	label?: string,
	name?: string,
	error?: boolean,
	helperText?: string,
	autoFocus?: boolean,
	children?: ReactNode,
}

export default ({ fullWidth, label, name, error, helperText, autoFocus, margin, children, ...rest }: ExtendedSelectProps) => {
	const inputLabel = useRef<HTMLLabelElement>(null);
	const [, setLabelWidth] = useState(0);

	useLayoutEffect(() => {
		if (inputLabel && inputLabel.current) {
			setLabelWidth(inputLabel.current.offsetWidth);
		}
	}, []);

	return (
		<FormControl
			variant="outlined"
			fullWidth={fullWidth}
			margin={margin}
			component="div"
		>
			<InputLabel
				ref={inputLabel}
				htmlFor={name}
			>
				{label}
			</InputLabel>
			<Select
				native
				input={
					<OutlinedInput
						label={label}
						autoFocus={autoFocus}
						error={error}
						name={name}
						id={name}
					/>
				}
				{...rest}
			>
				{children}
			</Select>
			{helperText && (
				<FormHelperText error={error} title={helperText}>
					{helperText}
				</FormHelperText>
			)}
		</FormControl>
	);
};
