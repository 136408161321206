import { CustomerOrder } from "../../../system/Domain";
import { formatVehiclePricingRequestPurchaser } from "../../../system/formatVehiclePricingRequestPurchaser";
import FormChoices from "../../../Components/FormChoices";
import FormDateTimePicker from "../../../Components/FormDateTimePicker";
import dateFormats from "../../../system/dateFormats";
import FormNumber from "../../../Components/FormNumber";
import { Form } from "../../../system/useForm";
import { Box, Grid } from "@mui/material";
import moment from "moment";
import FormUpload from "../../../Components/FormUpload";
import CustomerQuickFAQ from "../CustomerQuickFAQ";

interface Props {
	customerOrder: CustomerOrder
	// toStatusOverview: () => void
	// backAction?: () => void
	// formData?: CustomerReturnOrderFormPricingRequestType
	form: Form
}

const StepPricingRequestData = (props: Props) => {
	const referenceDate = moment.max(moment(props.customerOrder.dateContractEnd), moment());

	const choices = props.customerOrder.isTraderOrder ? {
		"Dealer": formatVehiclePricingRequestPurchaser("Dealer"),
		"BusinessClient": formatVehiclePricingRequestPurchaser("BusinessClient")
	} : {
		"Driver": formatVehiclePricingRequestPurchaser("Driver"),
		"Lessee": formatVehiclePricingRequestPurchaser("Lessee")
	};

	return (
		<Box my={2}>
			<Grid container spacing={4}>
				<FormChoices
					form={props.form}
					label="Übernahme durch"
					name="purchaser"
					choices={choices}
					options={{
						required: true
					}}
				/>
				<Grid item xs={12}>
					<Box width={250}>
						<FormDateTimePicker
							form={props.form}
							label="Datum Kaufvertrag"
							name="datePurchase"
							variant="date"
							format={dateFormats.date}
							fullWidth
							required
							disablePast
							minDate={referenceDate.toISOString(true)}
							maxDate={referenceDate.add(12, "weeks").toISOString(true)}
						/>
					</Box>
				</Grid>
				<FormNumber
					form={props.form}
					label="Aktueller Kilometerstand"
					name="mileage"
					inline
					options={{
						required: true
					}}
				/>
				<FormUpload
					form={props.form}
					label={
						<>
							Kilometernachweis
							<CustomerQuickFAQ faqKey="mileage-picture-proof" />
						</>
					}
					name="mileagePictureProof"
					options={{
						required: true
					}}
				/>
			</Grid>
		</Box>
	);
}

export default StepPricingRequestData;
