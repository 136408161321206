import { PropsWithChildren } from 'react';
import { Box, Grid } from "@mui/material";
import { CustomerOrder } from "../../system/Domain";
import AldVehicleHeader from "../../Components/AldVehicleHeader";

interface Props {
	customerOrder: CustomerOrder
}

const CustomerVehicleHeader = (props: PropsWithChildren<Props>) => {
	return (
		<Box>
			<Grid container style={{ width: "auto" }} spacing={2} alignItems="flex-end" justifyContent="space-between">
				<Grid item>
					<AldVehicleHeader
						plateNumber={props.customerOrder.plateNumber}
						entries={[props.customerOrder.vehicle.type?.description, props.customerOrder.vin]}
					/>
				</Grid>
			</Grid>
			<Box mt={1}>
				{props.children}
			</Box>
		</Box>
	);
};

export default CustomerVehicleHeader;
