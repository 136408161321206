import { Box, Chip, TableBody, TableHead, TableRow } from "@mui/material";
import PaperTable from "../../Components/PaperTable";
import { Billing } from "../../system/Domain";
import { useHistory } from "react-router";
import TextBlock from "../../Components/Layout/TextBlock";
import BillingStatus from "./BillingStatus";
import useBillingsPermissions from "./useBillingPermissions";
import formatCurrency from "../../system/formatCurrency";
import formatNumber from "../../system/formatNumber";
import formatDate from "../../system/formatDate";
import { Colgroup, TableCell } from "../../Components/BreakpointStyledComponents";

interface BillingTableProps {
	billings: Billing[]
}

export default ({ billings }: BillingTableProps) => {
	const history = useHistory();
	const permissions = useBillingsPermissions();

	return (
		<PaperTable>
			<Colgroup sm md lg xl>
				{!permissions.isRestrictedToHisCompany && (
					<col width="auto" />
				)}
				<col width="auto" />
				<col width="auto" />
				<col width="auto" />
			</Colgroup>
			<TableHead>
				<TableRow>
					{!permissions.isRestrictedToHisCompany && (
						<TableCell sm md lg xl>Dienstleister</TableCell>
					)}
					<TableCell>Abrechnung</TableCell>
					<TableCell sm md lg xl>Status</TableCell>
					<TableCell sm md lg xl>Zusammenfassung</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{billings && billings.map(billing => {
					const company = (
						<Chip label={billing.company.name} />
					);

					const title = (
						<TextBlock
							primary={<span>{formatDate(billing.dateFrom)} - {formatDate(billing.dateTo)}</span>}
							secondary={<span>Nr. {billing.nr}, erstellt am {formatDate(billing.dateCreated)}</span>}
						/>
					);

					const status = (
						<BillingStatus billing={billing} />
					);

					const summary = (
						<TextBlock
							primary={formatCurrency(billing.services.netAmount)}
							secondary={<span>{formatNumber(billing.services.total)} Tätigkeiten</span>}
						/>
					);

					return (
						<TableRow
							key={billing.id}
							hover
							onClick={() => history.push(`/billings/${billing.id}`)}
						>
							{!permissions.isRestrictedToHisCompany && (
								<TableCell sm md lg xl>
									{company}
								</TableCell>
							)}
							<TableCell>
								{!permissions.isRestrictedToHisCompany && (
									<Box sx={{ display: { xs: "contents", sm: "none" } }}>
										<Box mb={2}>
											{company}
										</Box>
									</Box>
								)}
								{title}
								<Box sx={{ display: { xs: "contents", sm: "none" } }}>
									<Box mt={2}>
										{status}
									</Box>
									<Box mt={2}>
										{summary}
									</Box>
								</Box>
							</TableCell>
							<TableCell sm md lg xl>
								{status}
							</TableCell>
							<TableCell sm md lg xl>
								{summary}
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		</PaperTable>
	);
}
