import { WorkSessionPerspective } from "../../system/Domain";

function formatWorkSessionPerspective(perspective : WorkSessionPerspective) {
	switch (perspective) {
		case "Manual":
			return "Manuelle Liste";
		case "Presales":
			return "Verkaufsbereite Fahrzeuge";
		case "BlockedPresales":
			return "Blockierte Verkaufsvorbereitung";
		case "ActiveSales":
			return "Aktive Verkäufe";
		case "SalesWithoutPricing":
			return "Erstbepreisung";
		case "PotentialRetailSales":
			return "Potentielle Retail Verkäufe";
		case "OverdueRetailSales":
			return "Überfällige Retail Verkäufe";
		case "SalesWithoutPriceChanges":
			return "Verkäufe ohne Preisänderungen";
		case "CarmarketSalesEvaluation":
			return "Carmarket Auktionsauswertungen";
		case "ReservedCarmarketSales":
			return "Reservierte Carmarket Verkäufe";
		case "OpenPricingRequests":
			return "Offene Kaufpreisanfragen";
		case "StoppedSales":
			return "Gestoppte Verkäufe";
		default:
			return perspective;
	}
}

export default formatWorkSessionPerspective;
