import { Children, cloneElement } from 'react';
import { alpha, Box, BoxProps } from '@mui/material';
import { useTheme } from '@mui/styles';
import { ThemeColor } from '../system/Domain';

interface Props extends BoxProps {
	color?: ThemeColor
	disabled?: boolean
}

export default ({ color = "info", disabled = undefined, children, ...rest }: Props) => {
	const theme = useTheme();

	if (disabled !== undefined) {
		children = Children.map(children, (c: any) => {
			if (!c) {
				return null;
			}

			return cloneElement(c, {
				disabled: disabled
			});
		});
	}

	const getColor = (color: string): string => {
		switch (color) {
			case "primary": return alpha(theme.palette.primary.light,0.4);
			case "success": return alpha(theme.palette.success.light, 0.4);
			case "warning": return alpha(theme.palette.warning.light, 0.4);
			case "error": return alpha(theme.palette.error.light, 0.4);
			default: return undefined;
		}
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="space-evenly"
			bgcolor={alpha(theme.palette.primary.light, 0.1)}
			sx={{
				transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
				backgroundColor: getColor(color),
			}}
			{...rest}
		>
			{children}
		</Box>
	);
}
