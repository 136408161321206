import axios from "axios";
import queryString from "query-string";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import { AversiLine } from "./AversiSessionsPage";
import Layout from "../../Components/Layout/Layout";
import PaperTable from "../../Components/PaperTable";
import formatCurrency from "../../system/formatCurrency";
import useAsyncEffect from "../../system/useAsyncEffect";
import FetchNextButton from "../../Components/FetchNextButton";
import { Box, CircularProgress, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";



const AversiLinesPage = () => {
	const [fetchParams, setFetchParams] = useState({
		skip: 0,
		take: 20
	});
	const [loading, setLoading] = useState(false);
	const [lines, setLines] = useState<AversiLine[]>([]);
	const [url, setUrl] = useState<string | null>("");
	const location = useLocation();

	useEffect(() => {
		const search = location.search;
		const query = queryString.parse(search);

		const { insuranceId, aversiSessionId, tenantName, type, contractId } = query;

		if (!aversiSessionId) return;

		let url = `/api/admin/aversi/lines?aversiSessionId=${aversiSessionId}`;

		if (insuranceId) {
			url = url += `&insuranceId=${insuranceId}`;
		}

		if (tenantName) {
			url = url += `&tenantName=${tenantName}`;
		}

		if (type) {
			url = url += `&type=${type}`;
		}

		if (contractId) {
			url = url += `&contractId=${contractId}`;
		}

		setUrl(url);
	}, [location]);

	useAsyncEffect(async () => {
		if (!url) return;

		setLoading(true);
		const { data } = await axios.get<AversiLine[]>(`${url}&skip=${fetchParams.skip}&take=${fetchParams.take}`);
		setLines(lines => [...lines, ...data]);
		setLoading(false);
	}, [url, fetchParams]);

	const fetchNext = () => {
		setFetchParams(params => ({
			skip: params.skip + params.take,
			take: params.take
		}));
	};

	return (
		<Layout>
			<Box my={2}>
				<Typography variant="h4">Aversi Buchungen</Typography>
			</Box>
			<PaperTable>
				<colgroup>
					<col width="10%" />
					<col width="5%" />
					<col width="5%" />
					<col width="5%" />
					<col width="10%" />
					<col width="5%" />
					<col width="auto" />
					<col width="5%" />
					<col width="5%" />
					<col width="5%" />
					<col width="5%" />
				</colgroup>
				<TableHead>
					<TableRow>
						<TableCell>Mandant</TableCell>
						<TableCell>Typ</TableCell>
						<TableCell>Produkt</TableCell>
						<TableCell>Kennzeichen</TableCell>
						<TableCell>FIN</TableCell>
						<TableCell>Kundennummer</TableCell>
						<TableCell>Kunde</TableCell>
						<TableCell>Typ</TableCell>
						<TableCell>Aufbau</TableCell>
						<TableCell>HSN/TSN</TableCell>
						<TableCell align="right">Betrag</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{loading && (
						<TableRow sx={{
							"& td": {
								borderBottom: "none"
							}
						}}>
							<TableCell colSpan={9} align="center">
								<CircularProgress />
							</TableCell>
						</TableRow>
					)}

					{lines && lines.map(d => {
						const hsnTsn = d.hsn ? `${d.hsn}/${d.tsn}` : "na";

						return (
							<TableRow>
								<TableCell>{d.tenantName}</TableCell>
								<TableCell>{d.type}</TableCell>
								<TableCell>{d.productCode}</TableCell>
								<TableCell>{d.plateNumber}</TableCell>
								<TableCell>{d.vin}</TableCell>
								<TableCell>{d.customerId}</TableCell>
								<TableCell>{d.customerName}</TableCell>
								<TableCell>{d.vehicleType}</TableCell>
								<TableCell>{d.vehicleBody}</TableCell>
								<TableCell>{hsnTsn}</TableCell>
								<TableCell align="right">{formatCurrency(d.amount)}</TableCell>
							</TableRow>
						)
					})}
				</TableBody>
			</PaperTable>
			<FetchNextButton
				hidden={lines.length < fetchParams.take + fetchParams.skip}
				my={3}
				onNext={fetchNext}
				disabled={loading}
			/>
		</Layout>
	)
};

export default AversiLinesPage;
