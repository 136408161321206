import { PricingRequestPurchaser } from "./Domain";

export const formatVehiclePricingRequestPurchaser = (purchaser : PricingRequestPurchaser) : string => {
	switch (purchaser) {
		case "Driver":
			return "Fahrer";
		case "Lessee":
			return "Leasingnehmer";
		case "Dealer":
			return "Autohaus";
		case "BusinessClient":
			return "Gewerbliche Dritte";
	}
}
