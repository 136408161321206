import axios from "axios";
import EmptyList from "Components/EmptyList";
import List from "Components/List";
import ListTitle from "Components/ListTitle";
import LoadingIndicator from "Components/LoadingIndicator";
import Stack from "Components/Stack";
import _ from "lodash";
import moment, { Moment } from "moment";
import { PropsWithChildren, useEffect, useState } from "react";
import Tile, { TileProps } from "../../Components/Tiles/Tile";
import { BatchFile, BatchFileQuery } from "../../system/Domain";
import useAxiosEffect from "../../system/useAxiosEffect";
import useChannel from "../../system/useChannel";
import useEvent from "../../system/useEvent";
import BatchFileListItem from "./BatchFileListItem";

interface Props extends TileProps {
	query: BatchFileQuery;
	onDelete?: (batchFile: BatchFile) => Promise<void>;
}

interface BatchGroup {
	date: Moment;
	files: BatchFile[];
}

export default ({ query, onDelete, children, ...rest }: PropsWithChildren<Props>) => {
	const [batchFiles, setBatchFiles] = useState<BatchFile[]>();
	const [loadingBatchFiles, loadBatchFiles] = useAxiosEffect(async (config) => {
		const { data } = await axios.post<BatchFile[]>(`/api/batch-files/search`, query, config);
		setBatchFiles(data);
	}, []);

	const [batchGroups, setBatchGroups] = useState<BatchGroup[]>();
	useEffect(() => {
		setBatchGroups(
			Object.entries(_.groupBy(batchFiles, (b) => moment(b.dateCreated).startOf("day").format())).map(
				([date, files]) => ({ date: moment(date), files }),
			),
		);
	}, [batchFiles]);

	useChannel("batch-files");

	useEvent("batch-file:created", async (data) => {
		if (!!query.types && query.types.includes(data.type)) {
			await loadBatchFiles();
		}
	});

	useEvent(
		"batch-file:updated",
		async (data) => {
			const { id, version } = data;

			if (!batchFiles || !id || !version) return;

			const batchFile = batchFiles.find((b) => b.id === id);

			if (batchFile && batchFile.version < version) {
				await loadBatchFiles();
			}
		},
		[batchFiles],
	);

	useEvent(
		"batch-file:deleted",
		async (data) => {
			const { id } = data;

			if (!batchFiles || !id) return;

			const batchFile = batchFiles.find((b) => b.id === id);

			if (batchFile) {
				setBatchFiles(batchFiles.filter((b) => b.id !== batchFile.id));
			}
		},
		[batchFiles],
	);

	return (
		<Tile {...rest}>
			{children}
			{batchFiles?.length === 0 && <EmptyList />}
			{batchGroups && (
				<Stack spacing={6}>
					{batchGroups.map((g) => (
						<List key={g.date.toISOString()}>
							<ListTitle>{g.date.format("D. MMMM YYYY")}</ListTitle>
							{g.files.map((b) => (
								<BatchFileListItem key={b.id} batchFile={b} onDelete={onDelete} />
							))}
						</List>
					))}
				</Stack>
			)}
			{loadingBatchFiles && <LoadingIndicator />}
		</Tile>
	);
};
