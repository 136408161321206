import useCustomerOrderFromMatch from "../../system/useCustomerOrderFromMatch";
import Layout from "../../Components/Layout/Layout";
import CustomerTestModeNotification from "../Orders/Customers/CustomerTestModeNotification";
import LoadingIndicator from "../../Components/LoadingIndicator";
import { Box, Typography } from "@mui/material";
import CustomerVehicleHeader from "./CustomerVehicleHeader";
import CustomerActionChoices from "./CustomerActionChoices";
import CustomerStatusOverview, { CustomerOrderWithReturnOrderId } from "./CustomerStatusOverview";
import { useRecoilState, useRecoilValue } from "recoil";
import { customerTitle } from "../../system/atoms/customerTitle";
import { CustomerOrder } from "../../system/Domain";
import { customerChoice } from "../../system/atoms/customerChoice";
import OrdersNavigation from "../../Navigation/OrdersNavigation";

const CustomerPage = () => {
	const title = useRecoilValue(customerTitle);
	const [customerOrder, refresh, updateCustomerOrder] = useCustomerOrderFromMatch();
	const [, setCustomerChoice] = useRecoilState(customerChoice);

	const isCancelled = customerOrder?.status === "Cancelled";
	const isCompleted = !!customerOrder?.returnOrderId || !!customerOrder?.pricingRequestId;

	const customerCancellation = (customerOrder: CustomerOrder) => {
		updateCustomerOrder(customerOrder);
		setCustomerChoice(undefined);
	};

	const customerDecision = (customerOrder: CustomerOrder) => {
		updateCustomerOrder(customerOrder);
	};

	return (
		<Layout
			hideNavigation
			plateNumber={customerOrder?.plateNumber}
			title={title}
			notification={<CustomerTestModeNotification />}
			navigation={<OrdersNavigation />}
		>
			<LoadingIndicator isLoading={customerOrder === null}>
				{customerOrder === undefined && (
					<Box mt={1}>
						Die angegebene Rückholung konnte nicht gefunden werden. Bitte wenden Sie sich an Ihren Kundenbetreuer.
					</Box>
				)}
				{customerOrder && isCancelled && (
					<CustomerVehicleHeader customerOrder={customerOrder}>
						<Box mt={3}>
							<Typography variant="body1">
								<strong>Diese Fahrzeugrückgabe wurde storniert.</strong>
								<br />
								Bei Fragen oder Problemen, wenden Sie sich bitte an ihren Kundenbetreuer.
							</Typography>
						</Box>
						<Box mt={2}>
							<Typography variant="body1">
								<em>{customerOrder.cancellationReason}</em>
							</Typography>
						</Box>
					</CustomerVehicleHeader>
				)}
				{customerOrder && !isCancelled && !isCompleted && (
					<CustomerVehicleHeader customerOrder={customerOrder}>
						<CustomerActionChoices
							customerOrder={customerOrder}
							toStatusOverview={refresh}
						/>
					</CustomerVehicleHeader>
				)}
				{customerOrder && !isCancelled && isCompleted && (
					<CustomerVehicleHeader customerOrder={customerOrder}>
						<CustomerStatusOverview
							customerOrder={customerOrder as CustomerOrderWithReturnOrderId}
							toStatusOverview={refresh}
							customerCancellation={customerCancellation}
							updateCustomerOrder={updateCustomerOrder}
							customerDecision={customerDecision}
						/>
					</CustomerVehicleHeader>
				)}
			</LoadingIndicator>
		</Layout>
	)
}

export default CustomerPage;
