import { alpha, colors, darken } from "@mui/material";
import _ from "lodash";
import { useHistory } from "react-router";
import { BcaAuction } from "system/Domain";
import formatCurrency from "system/formatCurrency";
import useChartOptions from "system/useChartOptions";
import Highcharts from "./../../../Components/Highcharts";

interface Props {
	auction: BcaAuction;
}

export default ({ auction }: Props) => {
	const history = useHistory();
	const [chartOptions] = useChartOptions();

	return (
		<Highcharts
			options={{
				...chartOptions,
				chart: {
					animation: false,
					height: 96,
					spacing: [0, 0, 0, 0],
					margin: 0,
					backgroundColor: "transparent",
					borderWidth: 0,
				},
				legend: {
					enabled: false,
				},
				xAxis: {
					visible: false,
				},
				yAxis: {
					visible: false,
					title: null,
					labels: {
						enabled: true,
					},
					max: _.max(auction.vehicles.map((v) => v.highestBid.grossAmount)),
					gridLineWidth: 0,
				},
				tooltip: {
					enabled: true,
					formatter: function () {
						const vehicle = auction.vehicles.find((v) => v.vin === this.point.name);
						if (!vehicle) {
							return null;
						}

						const color = vehicle.result === "Sold" ? colors.green[500] : colors.amber[500];

						return `<span style="font-size: 0.6rem">
<span style="color: ${darken(color, 0.5)}"><b>${vehicle.vin}</b></span>
<br /><span>Mindestpreis: <b>${formatCurrency(vehicle.minimumPrice.grossAmount)}</b></span>
<br /><span>Höchstgebot: <b>${formatCurrency(vehicle.highestBid.grossAmount)}</b></span>
<br /><span>Differenz: <b>${formatCurrency(vehicle.priceDifference.grossAmount)}</b></span>
</span>`;
					},
				},
				plotOptions: {
					column: {
						borderWidth: 0,
						colorByPoint: true,
						cursor: "pointer",
						stacking: "overlap",
						point: {
							events: {
								click: function () {
									const sale = auction.sales.find((s) => s.vin === this.name);
									if (!!sale) {
										history.push(`/sales/${sale.id}`);
									}
								},
							},
						},
					},
				},
				series: [
					{
						type: "column",
						data: auction.vehicles.map((v) => {
							let color: string = v.result === "Sold" ? colors.green["A200"] : colors.red[500];

							const sale = auction.sales.find((s) => s.vin === v.vin);
							if (!sale) {
								color = alpha(color, 0.1);
							}

							return {
								name: v.vin,
								y: Math.abs(v.highestBid.grossAmount - v.minimumPrice.grossAmount),
								color,
							};
						}),
						animation: false,
					},
					{
						type: "column",
						data: auction.vehicles.map((v) => {
							let color: string = v.result === "Sold" ? colors.green[500] : colors.amber[500];

							const sale = auction.sales.find((s) => s.vin === v.vin);
							if (!sale) {
								color = alpha(color, 0.1);
							}

							return {
								name: v.vin,
								y: Math.min(v.highestBid.grossAmount, v.minimumPrice.grossAmount),
								color,
							};
						}),
						animation: false,
					},
				],
			}}
		/>
	);
};
