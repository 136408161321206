import { Add, RotateLeft } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import Sort, { SortItem } from "Components/Sort";
import { Dispatch, SetStateAction } from 'react';
import { CustomerSettingsQuery } from "./CustomerSettingsOverview";
import useUser from "../../../system/useUser";
import { CustomerSettings, UserRole } from "../../../system/Domain";
import upsertCustomerSettings from "./upsertCustomerSettings";
import axios from "axios";

interface Props {
	query: CustomerSettingsQuery
	setQuery: Dispatch<SetStateAction<CustomerSettingsQuery>>
	resetQuery: () => CustomerSettingsQuery
}

const sortItems: SortItem[] = [
	{
		title: "Name",
		field: "name"
	},
	{
		title: "Email",
		field: "email"
	}
];

const CustomerSettingsSidebar = (props: Props) => {
	const [, , hasRole] = useUser();
	const allowCreation = hasRole(UserRole.AldManager);

	const customerSettingsDialog = async () => {
		const result = await upsertCustomerSettings({ edit: false, customerSettings: null });
		if (result) {
			if (result.edit) {
				await axios.put<CustomerSettings>(`/api/customer-settings/${result.customerSettings.id}`, {
					...result.customerSettings
				});
			} else {
				await axios.post<CustomerSettings>(`/api/customer-settings/${result.customerSettings.id}`, {
					...result.customerSettings
				});
			}

			props.setQuery(prev => ({ ...prev, skip: 0, take: prev.skip + prev.take }));
		}
	};

	return (
		<Grid container spacing={4}>
			{allowCreation && (
				<Grid item xs={12}>
					<Button
						variant="outlined"
						fullWidth
						startIcon={<Add />}
						onClick={customerSettingsDialog}
					>
						Neue Einstellung anlegen
					</Button>
				</Grid>
			)}
			<Grid item xs={12}>
				<Sort
					items={sortItems}
					value={props.query.sort}
					onChange={sort => props.setQuery(q => ({
						...q,
						skip: 0,
						sort
					}))}
				/>
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Button
							variant="outlined"
							startIcon={<RotateLeft />}
							fullWidth
							onClick={props.resetQuery}
						>
							Filter zurücksetzen
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
};

export default CustomerSettingsSidebar;
