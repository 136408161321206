import { Box, BoxProps, Typography } from "@mui/material";
import { SalesCustomer } from "../../../system/Domain";

interface Props extends BoxProps {
	address : SalesCustomer
	variant? : "small" | "normal"
}

const SalesAddressInfo = ({ address, variant = "normal", ...rest } : Props) => {
	let name = address.name;
	if (address.name2) {
		name = `${name} ${address.name2}`;
	}
	return (
		<Box {...rest} display="flex" justifyContent="space-between">
			<Box>
				<Typography variant="h6">{name}</Typography>
				{address.email && (
					<Typography>{address.email}</Typography>
				)}
				<Typography>{address.street}</Typography>
				<Typography>{address.zipCode} {address.city}</Typography>
				<Typography>{address.vatId}</Typography>
			</Box>
			{variant === "normal" && (
				<Box>
					<Typography
						display="block"
						variant="caption"
						color="textSecondary"
						style={{ lineHeight: 1 }}
					>
						Adress-Nummer
					</Typography>
					<Typography
						align="right"
						display="block"
						variant="caption"
						color="textSecondary"
						style={{ lineHeight: 1 }}
					>
						{address.id}
					</Typography>
				</Box>
			)}
		</Box>
	);
};

export default SalesAddressInfo;
