import { Launch } from "@mui/icons-material";
import { useHistory } from "react-router";
import { ThemeColor, Vehicle, VehicleSource } from "system/Domain";
import useCompoundPlaces from "system/useCompoundPlaces";
import TextBlock from "./Layout/TextBlock";
import ListItem from "./ListItem";
import ListItemAction from "./ListItemAction";
import ListItemActions from "./ListItemActions";
import ListItemChip from "./ListItemChip";
import ListItemChips from "./ListItemChips";
import ListItemContent from "./ListItemContent";
import { Plate } from "./Plate";

interface Props {
	vehicle: Vehicle;
	onClick?: () => void;
	selected: boolean;
	customerDetails?: boolean;
}

export default ({ vehicle, onClick, selected = false, customerDetails = true }: Props) => {
	const history = useHistory();
	const [places] = useCompoundPlaces();
	const place = places?.find((p) => p.id === vehicle.placeId)?.name || "-";

	const handleClick = !!onClick ? onClick : () => history.push(`/vehicles/${vehicle.id}`);

	let color: ThemeColor = "info";
	if (selected) {
		color = "primary";
	}

	if (vehicle.remarketing.status === "Ausgang") {
		color = "info";
	}

	let source: VehicleSource = "TradeIn";
	if (!!vehicle.remarketing.leasmanVehicleId) {
		source = "Ald";
	} else if (!!vehicle.remarketing.fleetnetVehicleId) {
		source = "Cpm";
	}

	const isRemarketing = vehicle.remarketing.status !== "Registriert";
	const { fleetnetVehicle, leasmanContract } = vehicle.remarketing;

	return (
		<ListItem color={color}>
			<ListItemActions>
				<ListItemAction icon={<Launch />} color="inherit" onClick={handleClick} />
			</ListItemActions>
			<ListItemContent onClick={handleClick}>
				<TextBlock
					primary={<Plate plateNumber={vehicle.plateNumber} style={{ zoom: 0.6 }} />}
					secondary={
						!!vehicle.type &&
						(vehicle.type.description
							? `${vehicle.type.description} · ${vehicle.vin}`
							: `${vehicle.type.make.name} ${vehicle.type.model.version} ${vehicle.type.model.variant} · ${vehicle.vin}`)
					}
				/>
				<ListItemChips>
					{isRemarketing && (
						<ListItemChip
							label={`Remarketing: ${vehicle.remarketing.processStep.displayName}, ${place}`}
							color="primary"
						/>
					)}
					{source === "Ald" && !!leasmanContract && (
						<>
							<ListItemChip
								label={`Leasing: ${leasmanContract.tenant}, ${leasmanContract.id}`}
								color={leasmanContract.status?.processStatus === "Active" ? "success" : "info"}
							/>
							{customerDetails && leasmanContract.customer && (
								<ListItemChip label={`${leasmanContract.customer?.fullName}`} />
							)}
						</>
					)}
					{source === "Cpm" && !!fleetnetVehicle && (
						<>
							<ListItemChip
								label={`Dienstwagen: ${fleetnetVehicle.client.nr.toString()} ${fleetnetVehicle.client.name}`}
								color="primary"
							/>
							{customerDetails && fleetnetVehicle.driver && (
								<ListItemChip
									label={`${fleetnetVehicle.driver.firstName} ${fleetnetVehicle.driver.lastName}`}
								/>
							)}
						</>
					)}
				</ListItemChips>
			</ListItemContent>
		</ListItem>
	);
};
