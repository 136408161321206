import axios, { AxiosResponse } from "axios";
import fileDownload from "js-file-download";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import fileTypeExcel2 from "@iconify-icons/vscode-icons/file-type-excel2";
import { Icon } from "@iconify/react";
import { AttachFile, Delete, Save } from "@mui/icons-material";
import { Box, Grid, Link, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Actions from "../../Components/Actions";
import Layout from "../../Components/Layout/Layout";
import confirm from "../../Dialogs/confirm";
import { Import as ImportModel, ImportStatus, UserRole } from "../../system/Domain";
import parseContentDispositionFilename from "../../system/parseContentDispositionFilename";
import useAsyncEffect from "../../system/useAsyncEffect";
import VehicleRegistrationDocumentArrivalsImportEditView from "../Vehicles/DocumentManagement/VehicleRegistrationDocumentArrivalsImportEditView";
import VehicleRegistrationDocumentArrivalsImportView from "../Vehicles/DocumentManagement/VehicleRegistrationDocumentArrivalsImportView";
import VehicleRegistrationDocumentRequestsImportView from "../Vehicles/DocumentManagement/VehicleRegistrationDocumentRequestsImportView";
import VehicleRegistrationDocumentUnregistrationsImportView from "../Vehicles/DocumentManagement/VehicleRegistrationDocumentUnregistrationsImportView";
import CompleteUnregistrationsView from "./CompleteUnregistrationOrders/CompleteUnregistrationsView";
import ImportProgress from "./ImportProgress";
import LogisticOrders from "./LogisticOrders";
import ReturnOrders from "./ReturnOrders";
import VehicleServices from "./VehicleServices";
import LeaseplanVehiclesView from "./LeaseplanVehicles/LeaseplanVehiclesView";
import LayoutSubTitle from "Components/Layout/LayoutSubTitle";
import PaperBox from "Components/PaperBox";
import useUser from "../../system/useUser";
import ImportHistory from "./ImportHistory";

const ImportError = ({ imp }: { imp: ImportModel }) => {
	if (!imp.error) {
		return null;
	}

	return (
		<Box>
			<Typography>Der Import ist fehlerhaft. Bitte Protokolle überprüfen</Typography>
			<Typography color="error" gutterBottom>
				{imp.error}
			</Typography>
		</Box>
	);
};

export default () => {
	const { id } = useParams<{ id: string }>();
	const [isLoading, setIsLoading] = useState(false);
	const [imp, setImp] = useState<ImportModel | null>(null);
	const [, , hasRole] = useUser();
	const isAdmin = hasRole(UserRole.AldAdmin);

	useAsyncEffect(async () => {
		setIsLoading(true);
		const { data: imp } = await axios.get<ImportModel>(`/api/imports/${id}`);
		setIsLoading(false);
		setImp(imp);
	}, [id]);

	useEffect(() => {
		if (!imp) {
			return;
		}

		if (!imp.error && imp.currentTaskId) {
			// watch for changes...
			setTimeout(async () => {
				const response = await axios.get<ImportModel>(`/api/imports/${id}`);
				await new Promise((r) => setTimeout(r, 2000));
				setImp(response.data);
			}, 2000);
		}
	}, [imp]);

	const handleDelete = async () => {
		const answer = await confirm("Soll der Import wirklich gelöscht werden?");

		if (answer.ok) {
			setIsLoading(true);
			const { data: result } = await axios.delete<ImportModel>(`/api/imports/${id}`);
			setImp(result);
			setIsLoading(false);
		}
	};

	const handle = (operation: string) => async () => {
		setIsLoading(true);
		try {
			const { data: result } = await axios.post<ImportModel>(`/api/imports/${id}/${operation}`);
			setImp(result);
		} finally {
			setIsLoading(false);
		}
	};

	const handleDownload = (response: AxiosResponse<Blob>) => {
		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);
	};

	const handleDownloadFile = async () => {
		const response = await axios.get<Blob>(`/api/storage/${id}`, {
			responseType: "blob"
		});

		handleDownload(response);
	};

	const handleExportToExcel = async () => {
		const response = await axios.get(`/api/imports/${id}?format=xls`, {
			responseType: "blob"
		});

		handleDownload(response);
	};

	const handleExportAssignedToExcel = async () => {
		const response = await axios.get(`/api/imports/${id}/transport-orders.csv?company=all`, {
			responseType: "blob"
		});

		fileDownload(response.data, `auftraege-all.csv`);
	};

	const status = imp && imp.status;

	const renderImport = () => {
		if (!imp) return null;

		if (imp.status === ImportStatus.Deleted) {
			if (imp.importType === "ReturnOrdersImport") return <></>;

			return (
				<Grid container spacing={2} mt={2}>
					<Grid item xs={12}>
						<LayoutSubTitle label="Import" />
						<PaperBox>
							<ImportError imp={imp} />
							<Typography>
								Import wurde gelöscht. Zum reimportieren
								<Link onClick={handle("import")}>
									<span> hier klicken</span>
								</Link>
							</Typography>
						</PaperBox>
					</Grid>
					{isAdmin && (
						<Grid item xs={12} md={8}>
							<LayoutSubTitle sx={{ p: 2 }} label="Historie" />
							<PaperBox>
								<ImportHistory imp={imp} />
							</PaperBox>
						</Grid>
					)}
				</Grid>
			);
		}

		return (
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<LayoutSubTitle label="Import" />
					<ImportError imp={imp} />

					{imp.importType === "LeaseplanVehicleImport" && <LeaseplanVehiclesView imp={imp} />}
					{imp.importType === "LogisticOrdersImport" && <LogisticOrders imp={imp} />}
					{imp.importType === "VehicleServiceImport" && <VehicleServices imp={imp} />}
					{imp.importType === "ReturnOrdersImport" && <ReturnOrders imp={imp} />}
					{imp.importType === "CompleteUnregistrationOrdersImport" && <CompleteUnregistrationsView imp={imp} />}
					{imp.importType === "VehicleRegistrationDocumentRequestsImport" && <VehicleRegistrationDocumentRequestsImportView imp={imp} />}
					{imp.importType === "VehicleRegistrationDocumentUnregistrationsImport" && <VehicleRegistrationDocumentUnregistrationsImportView imp={imp} />}
					{imp.importType === "VehicleRegistrationDocumentArrivalsImport" && (
						<>
							{imp.status === ImportStatus.Committed && <VehicleRegistrationDocumentArrivalsImportView imp={imp} />}
							{imp.status !== ImportStatus.Committed && <VehicleRegistrationDocumentArrivalsImportEditView imp={imp} />}
						</>
					)}
					{imp.importType === "VehicleRegistrationDocumentHandoversImport" && <VehicleRegistrationDocumentRequestsImportView imp={imp} />}
				</Grid>
				{isAdmin && (
					<Grid item xs={12} md={8}>
						<LayoutSubTitle sx={{ p: 2 }} label="Historie" />
						<PaperBox>
							<ImportHistory imp={imp} />
						</PaperBox>
					</Grid>
				)}
			</Grid>
		);
	};

	if (isLoading) {
		return <Layout disablePadding loading></Layout>;
	}

	if (!Boolean(imp?.error)) {
		if (imp?.currentTaskId) {
			return <ImportProgress taskId={imp.currentTaskId} jobId={imp.id} importId={imp.id} />;
		}
	}

	return (
		<Layout title="Importe">
			<Box mb={2}>
				<Actions>
					{imp?.importType !== "ReturnOrdersImport" && (
						<Button variant="outlined" size="medium" color="primary" onClick={handleDownloadFile} startIcon={<AttachFile />}>
							Originaldatei herunterladen
						</Button>
					)}
					{imp?.importType !== "ReturnOrdersImport" && (
						<Button
							variant="outlined"
							size="medium"
							color="primary"
							startIcon={<Icon icon={fileTypeExcel2} />}
							disabled={status !== ImportStatus.Imported && status !== ImportStatus.Committed}
							onClick={handleExportToExcel}
						>
							Excel
						</Button>
					)}
					<Button
						variant="outlined"
						size="medium"
						color="primary"
						disabled={status !== ImportStatus.Imported && status !== ImportStatus.Created}
						startIcon={<Delete />}
						onClick={handleDelete}
					>
						Import Löschen
					</Button>
					<Button variant="outlined" size="medium" color="primary" disabled={status !== ImportStatus.Imported} startIcon={<Save />} onClick={handle("commit")}>
						Import Abschließen
					</Button>
					{imp?.importType === "ReturnOrdersImport" && (
						<Button
							variant="outlined"
							size="medium"
							color="primary"
							disabled={status === ImportStatus.Imported || status === ImportStatus.Deleted}
							startIcon={<AttachFile />}
							onClick={handleExportAssignedToExcel}
						>
							Export
						</Button>
					)}
				</Actions>
			</Box>
			{renderImport()}
		</Layout>
	);
};
