import { AtomEffect } from "recoil";

// Type for the localStorageEffect function
type LocalStorageEffect = (key: string, defaultValue: any) => AtomEffect<any>;

// Recoil effect for localStorage persistence
const localStorageEffect: LocalStorageEffect =
	(key, defaultValue) =>
	({ setSelf, onSet }) => {
		// Load the saved value from localStorage and initialize the atom
		const savedValue = localStorage.getItem(key);
		console.log(`Loading ${key} from local storage`, savedValue);
		if (savedValue != null) {
			try {
				setSelf({ ...defaultValue, ...JSON.parse(savedValue) });
			} catch (error) {
				console.error(`Error parsing localStorage key "${key}":`, error);
			}
		}

		// Subscribe to state changes and update localStorage
		onSet((newValue, _, isReset) => {
			if (isReset) {
				console.log(`Removing ${key}`, newValue);
				localStorage.removeItem(key);
			} else {
				console.log(`Setting ${key}`, newValue);
				localStorage.setItem(key, JSON.stringify(newValue));
			}
		});
	};

export default localStorageEffect;
