import List from "./List";
import ListItem from "./ListItem";
import ListItemContent from "./ListItemContent";

export default () => {
	return (
		<List>
			<ListItem>
				<ListItemContent>Keine Einträge vorhanden</ListItemContent>
			</ListItem>
		</List>
	);
};
