import { ReturnOrderTerminationReason } from "../../system/Domain";

function formatReturnOrderTerminationReason(reason : ReturnOrderTerminationReason) {
	switch (reason) {
		case "Other":
			return "Andere";
		case "CustomerNotAvailable":
			return "Leasingnehmer nicht erreichbar";
		case "LesseeRefers":
			return "Leasingnehmer unauffindbar";
		case "VehicleStolen":
			return "Fahrzeug gestohlen";
		case "NotDelivered":
			return "Fahrzeug verschollen";
		default:
			return reason;
	}
}

export default formatReturnOrderTerminationReason;
