import { Snackbar, Alert, AlertColor } from "@mui/material";

interface Props {
	message : string
	onClose : () => void
	color : AlertColor
}

const NotificationSnackbar = ({ message, onClose, color } : Props) => {
	return (
		<Snackbar
			open={!!message}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center"
			}}
			autoHideDuration={4000}
			ClickAwayListenerProps={{
				onClickAway: event => event.preventDefault()
			}}
			onClose={onClose}
		>
			<Alert
				severity={color}
				onClose={onClose}
			>
				{message}
			</Alert>
		</Snackbar>
	);
};

export default NotificationSnackbar;
