import moment from "moment";
import { useEffect, useRef } from "react";
import { lightBlue, lightGreen } from "@mui/material/colors";
import { Chart as HighChart, SeriesOptionsType, Options } from "highcharts";
import { Box, CircularProgress, useMediaQuery, useTheme } from "@mui/material";

export interface MarkerInfo {
	series : string,
	x : string
}

export interface TimeSeriesData {
	x : string
	y : number
}

export interface Series {
	name : string
	title : string
	data : Array<TimeSeriesData>
}

export interface ChartProps {
	variant? : "area" | "column",
	loading?: boolean
	series : Array<Series>
	yMax? : number
	onMarkerClick? : (marker : MarkerInfo) => void
	options? : Options
}

export default ({ variant = "column", yMax, series, loading, onMarkerClick, options} : ChartProps) => {

	const canvasRef = useRef<HTMLDivElement>(null);
	const theme = useTheme();
	const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));
	
	useEffect(() => {
		let seriesData : Array<SeriesOptionsType> = series.map(s => ({
			type: variant,
			name: s.title,
			seriesName: s.name,
			data: s.data.map(d => ({
				x: moment(d.x).toDate().getTime(),
				y: d.y
			}))
		}));

		const element = canvasRef!.current;
		const chart = new HighChart(element!, {
			colors: [lightBlue[400], lightGreen[400]],
			chart: {
				backgroundColor: 'transparent',
				style: {
					color: theme.palette.text.primary
				},
				className: 'dark-container',
			},
			credits: {
				enabled: false
			},
			title: undefined,
			legend: {
				enabled: false
			},
			xAxis: {
				type: "datetime",
				visible: !isSmallDevice,
				tickInterval: 7 * 24 * 3600 * 1000
			},
			yAxis: {
				title: {
					text: "Anzahl"
				},
				max: yMax,
				gridLineWidth: 0,
				visible: !isSmallDevice
			},
			tooltip: {
				enabled: true,
				formatter: function () {
					const date = moment(this.x).format("L");
					return `<b>${date} ${this.series.name}</b><br/>${this.y} Einträge`;
				}
			},
			plotOptions: {
				column: {
					borderWidth: 0
				},
				series: {
					animation: false,
					point: {
						events: {
							click: function () {
								if (onMarkerClick) {
									const seriesElement = series.find(s => s.title === this.series.name);
									if(seriesElement) {
										onMarkerClick({
											series: seriesElement.name,
											x: moment(this.x).toISOString()
										});
									}
								}
							}
						}
					}
				}
			},
			series: seriesData,
			...options
		});

		return () => {
			chart.destroy();
		};

	}, [series, isSmallDevice]);

	return (
		<>
			{Boolean(loading) && (
				<Box textAlign="center">
					<CircularProgress/>
				</Box>

			)}
			<div ref={canvasRef}>
			</div>
		</>
	);
};
