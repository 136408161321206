import {
	AssessmentOrderDefectItem,
	ThemeColor,
	UserRole
} from "../../../system/Domain";
import { useState } from "react";
import TextBlock from "../../../Components/Layout/TextBlock";
import ListItem from "../../../Components/ListItem";
import ListItemAction from "../../../Components/ListItemAction";
import ListItemActions from "../../../Components/ListItemActions";
import ListItemContent from "../../../Components/ListItemContent";
import { Clear, Delete } from "@mui/icons-material";
import { Box, Input } from "@mui/material";
import formatDateTime from "../../../system/formatDateTime";
import useUser from "system/useUser";
import moment from "moment";

type EditProps = {
	defectItem: Partial<AssessmentOrderDefectItem>
	new?: false
};

type NewProps = {
	defectItem: Partial<AssessmentOrderDefectItem>
	new: true
	cancel: () => void
	onAddingNewItem: (defectItem: Partial<AssessmentOrderDefectItem>) => void
};

interface CommonProps {
	disabled?: boolean
	onDeletingItem?: (id: string) => void
}

type SpecificProps = EditProps | NewProps;

const ExpertAssesmentDefectListItem = (props: CommonProps & SpecificProps) => {
	const [user, , hasRole] = useUser();
	const [editing, setEditing] = useState(!!props.new);
	const [, setDescription] = useState<string>(props.defectItem.description);

	const color: ThemeColor = editing ? "primary" : "info";

	const stopEditing = () => {
		setEditing(false);

		if (props.new) {
			props.cancel();
		}
	};

	const onDefectItemChange = (newDescription: string) => {
		setDescription(newDescription);

		if (props.new) {
			props.onAddingNewItem({ id: props.defectItem.id, description: newDescription });
		} else {
			props.defectItem.description = newDescription;
		}
	};

	const onDeletingItem = (id: string) => {
		props.onDeletingItem(id);
	};

	const canEditDefectItem = hasRole(UserRole.AldManager)
		|| (hasRole(UserRole.DamageAssessor) && !props.defectItem?.createdBy)
		|| (hasRole(UserRole.DamageAssessor) && props?.defectItem?.createdBy?.id === user?.id && moment(props?.defectItem?.dateCreated).isSame(moment(), 'day'));

	return (
		<>
			{props.disabled && (
				<TextBlock primary={props.defectItem.description} />
			)}

			{!props.disabled && (
				<ListItem color={color} sx={{ margin: 16 }} boxShadow={0}>
					<ListItemActions color={color}>
						<>
							{props.new && (
								<ListItemAction
									icon={<Clear />}
									disabled={!canEditDefectItem}
									onClick={stopEditing}
								/>
							)}

							{!props.new && (
								<ListItemAction
									icon={<Delete />}
									disabled={!canEditDefectItem}
									onClick={() => onDeletingItem(props.defectItem.id)}
								/>
							)}
						</>
					</ListItemActions>

					<ListItemContent color={color}>
						<TextBlock
							primary={
								<>
									<Box>
										<Input
											fullWidth
											autoFocus
											disabled={props.disabled}
											value={props.defectItem.description}
											sx={{ lineHeight: "1.5em" }}
											onChange={(e) => onDefectItemChange(e.target.value)}
										/>
									</Box>

									{props.defectItem.id && !props.defectItem.id.startsWith('local') && (
										<TextBlock
											secondary={
												<>
													{`${formatDateTime(props.defectItem.dateCreated)} (${props.defectItem.createdBy?.name})`}
													{props.defectItem.dateUpdated && (
														<>
															{` - ${formatDateTime(props.defectItem.dateUpdated)} (${props.defectItem.updatedBy?.name})`}
														</>
													)}
												</>
											}
										/>
									)}
								</>
							}
							flat
						/>
					</ListItemContent>
				</ListItem>
			)}
		</>
	);
};

export default ExpertAssesmentDefectListItem;
