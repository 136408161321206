import { useCallback, useState } from "react";

interface UseTimeoutOptions {
	autoStart? : boolean,
	callback? : ((value : any) => void) | null
}

export default (timeout : number, { autoStart = false, callback = null } : UseTimeoutOptions) => {
	const [value, setValue] = useState<any>(null);
	const [handle, setHandle] = useState<any>();

	const start = useCallback(() => {
		if (handle) {
			stop();
		}

		const newHandle = setTimeout(() => {
			const newValue = new Date().getTime();
			setValue(newValue);

			if (callback) {
				callback(newValue);
			}
		}, timeout);

		setHandle(newHandle);

		return () => {
			clearTimeout(newHandle);
			setValue(null);
			setHandle(null);
		};
	}, [handle]);

	const stop = useCallback(() => {
		if (!handle) {
			return;
		}

		clearTimeout(handle);
		setValue(null);
		setHandle(null);
	}, [handle]);

	if (autoStart) {
		start();
	}

	return [
		value,
		start,
		stop
	];
};
