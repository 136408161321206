import axios from 'axios';
import moment from 'moment';
import { useState } from 'react';
import { Check, Clear, Close, Edit, ThumbUp } from '@mui/icons-material';
import { Box, Input, MenuItem, Select } from '@mui/material';
import TextBlock from '../../Components/Layout/TextBlock';
import ListItem from '../../Components/ListItem';
import ListItemAction from '../../Components/ListItemAction';
import ListItemActions from '../../Components/ListItemActions';
import ListItemChip from '../../Components/ListItemChip';
import ListItemChips from '../../Components/ListItemChips';
import ListItemContent from '../../Components/ListItemContent';
import askForTextAnswer from '../../system/askForTextAnswer';
import {
	RemarketingStop, RemarketingStopReason, remarketingStopReasons, ThemeColor, UserRole, Vehicle
} from '../../system/Domain';
import formatDateTime from '../../system/formatDateTime';
import useUser from '../../system/useUser';
import formatRemarketingStopReason from './formatRemarketingStopReason';

type EditProps = {
	stop: RemarketingStop
	new?: false
}

type NewProps = {
	stop: Partial<RemarketingStop>
	new: true
	cancel: () => void
}

interface CommonProps {
	excluded?: RemarketingStopReason[]
	vehicle: Vehicle
	save: (vehicle: Vehicle) => void
	disabled?: boolean
}

type SpecificProps = EditProps | NewProps;

export default (props: CommonProps & SpecificProps) => {
	const [description, setDescription] = useState<string>("");
	const [reason, setReason] = useState<RemarketingStopReason>();
	const [user, , , , hasAnyRole] = useUser();

	const [editing, setEditing] = useState(!!props.new);

	const canSave = reason && description;
	const saveRemarketingStop = async () => {
		if (!canSave) return;

		if (props.new) {
			const { data: vehicle } = await axios.post<Vehicle>(`/api/vehicles/${props.vehicle.id}/remarketing/stops`, {
				reason: reason,
				description: description
			});

			props.save(vehicle);
		} else {
			const { data: vehicle } = await axios.put<Vehicle>(`/api/vehicles/${props.vehicle.id}/remarketing/stops/${props.stop.id}`, {
				...props.stop,
				description: description,
				reason: reason
			});

			setEditing(false);
			props.save(vehicle);
		}
	};

	const confirmStopResolved = async () => {
		const answer = await askForTextAnswer({
			text: "",
			title: "Vermarktungsstop abschließen?",
			label: "Abschlusskommentar",
			required: true
		});

		if (answer) {
			const { data: vehicle } = await axios.put<Vehicle>(`/api/vehicles/${props.vehicle.id}/remarketing/stops/${props.stop.id}`, {
				...props.stop,
				dateResolved: moment().toISOString(true),
				resolutionMessage: answer
			});

			setEditing(false);
			props.save(vehicle);
		}
	};

	const startEditing = () => {
		setEditing(true);
		setDescription(props.stop.description ?? "");
		setReason(props.stop.reason);
	};

	const stopEditing = () => {
		setEditing(false);

		if (props.new) {
			props.cancel();
		}
	};

	let color: ThemeColor = "info";
	if (editing) {
		color = "primary";
	}

	const hasElevatedPermissions = hasAnyRole([UserRole.SalesAdmin, UserRole.SalesBackoffice, UserRole.SalesManager]);

	const canResolveStop = hasElevatedPermissions;
	const canEditStop = hasElevatedPermissions || props.stop.startedBy?.id === user?.id;

	return (
		<ListItem color={color}>
			{(props.stop.isActive || props.new) && !props.disabled && (
				<ListItemActions color={color}>
					{!editing && (
						<>
							<ListItemAction
								disabled={!canEditStop}
								icon={<Edit />}
								onClick={startEditing}
							/>
							<ListItemAction
								disabled={!canResolveStop}
								icon={<ThumbUp />}
								onClick={confirmStopResolved}
							/>
						</>
					)}
					{editing && (
						<>
							<ListItemAction
								icon={<Check />}
								disabled={!canSave}
								onClick={saveRemarketingStop}
							/>
							<ListItemAction
								icon={<Clear />}
								onClick={stopEditing}
							/>
						</>
					)}
				</ListItemActions>
			)}
			<ListItemContent color={color}>
				<TextBlock
					primary={
						<>
							{editing && (
								<Box mb={1} display="flex" flexDirection="row">
									<Box mr={2} minWidth={150}>
										<Select
											label="Stopgrund"
											value={reason}
											onChange={(e) => setReason(e.target.value as RemarketingStopReason)}
											fullWidth
											variant="standard"
										>
											{remarketingStopReasons.map(r =>
												<MenuItem
													key={r}
													value={r}
													disabled={props.excluded && props.excluded.includes(r)}>
													{formatRemarketingStopReason(r)}
												</MenuItem>
											)}
										</Select>
									</Box>
									<Input
										fullWidth
										autoFocus
										value={description}
										sx={{ lineHeight: "1.5em" }}
										onChange={e => setDescription(e.target.value)}
									/>
								</Box>
							)}
							{!editing && (
								<>
									<TextBlock
										primary={props.stop.description}
										secondary={props.stop.resolutionMessage}
										tertiary={<>
											<span>{formatDateTime(props.stop.dateStarted)} ({props.stop.startedBy?.name})</span>
											{props.stop.dateResolved && (
												<span> - {formatDateTime(props.stop.dateResolved)} ({props.stop.resolvedBy?.name})</span>
											)}
										</>}
									/>
								</>
							)}
						</>
					}
					flat
					secondary={editing && props.stop.description}
				/>
				<ListItemChips>
					{!editing && (
						<ListItemChip
							label={formatRemarketingStopReason(props.stop.reason!)}
						/>
					)}
					{props.new && (
						<ListItemChip
							label="Neu"
						/>
					)}
					{!props.new && (
						<ListItemChip
							label={props.stop.isActive ? "Aktiv" : "Gelöst"}
							icon={props.stop.isActive ? <Close /> : <Check />}
							color={props.stop.isActive ? "error" : "success"}
						/>
					)}
				</ListItemChips>
			</ListItemContent>
		</ListItem>
	);
};
