import { useState } from "react";
import { Sale, Vehicle, VehicleAssessment } from "../../../system/Domain";
import { Box, Grid } from "@mui/material";
import TextBlock from "../../../Components/Layout/TextBlock";
import Tile from "../../../Components/Tiles/Tile";
import TileContent from "../../../Components/Tiles/TileContent";
import Properties from "../../../Components/Properties";
import Property from "../../../Components/Property";
import formatNumber from "../../../system/formatNumber";
import formatCurrency from "../../../system/formatCurrency";
import { formatYesNo } from "../../../Components/LeasmanContract";
import { formatRimType, formatTireType } from "../../../Components/FormTiresAndRims";
import axios from "axios";
import { useAsync } from "react-use";
import useVat from "../../../system/useVat";
import RemarketingAssessmentTile from "Pages/Vehicles/RemarketingAssessmentTile";

interface Props {
	sale: Sale;
	updateButton: JSX.Element;
	vehicle?: Vehicle;
}

const SalesInformation = ({ sale, updateButton, vehicle }: Props) => {
	const [assessment, setAssessment] = useState<VehicleAssessment | null>(null);
	const vat = useVat();

	useAsync(async () => {
		if (!sale.assessment?.id) {
			setAssessment(null);
			return;
		}

		const { data } = await axios.get<VehicleAssessment>(`/api/assessments/${sale.assessment.id}`);
		setAssessment(data);
	}, [sale]);

	const offer = sale.offers.find((o) => o.status === "Reserved");
	let bidder = null;
	const bid = offer?.bids.find((b) => b.status === "Reserved");
	if (offer) {
		if (bid) {
			bidder = bid.bidder;
		}
	}

	return (
		<Grid container spacing={6}>
			{bidder && (
				<Grid item xs={12} md={6}>
					<Tile
						title={
							<Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
								<Box>Käufer</Box>
								{sale.leaseplanInvoiceNumber && (
									<Box>LPDE Rechnungsnummer: {sale.leaseplanInvoiceNumber}</Box>
								)}
							</Box>
						}
					>
						<TileContent>
							<Box mb={1}>
								<TextBlock
									primary={bidder.company}
									secondary={`${bidder.street} - ${bidder.zipCode}`}
									tertiary={
										<Box>
											<Box>
												{bidder.city} - {bidder.country}
											</Box>
											{bidder.email && <Box>{bidder.email}</Box>}
										</Box>
									}
								/>
							</Box>
							<TextBlock secondary={`Sitenumber ${bidder.externalId || `unbekannt`}`} />
						</TileContent>
					</Tile>

					{updateButton}
				</Grid>
			)}

			<Grid item xs={12} md={6}>
				<Tile title="Verkauf">
					<TileContent>
						<Properties>
							<Property name="Vorbesitzer" value={formatNumber(sale.previousOwners)} />
							<Property name="Kilometerstand" value={formatNumber(sale.returnMileage || 0)} />
							{bid && <Property name="Höchstgebot (Brutto)" value={formatCurrency(bid.price)} />}
							{bid && bidder && bidder.country !== "Germany" && (
								<>
									<Property
										name="Höchstgebot (Netto)"
										value={formatCurrency(vat.calculateNetAmount(bid.price))}
									/>

									<Property
										name="Höchstgebot (Differenz)"
										value={formatCurrency(bid.price - vat.calculateNetAmount(bid.price))}
									/>
								</>
							)}
							<Property name="Schäden" value={formatYesNo(sale.hadAccident)} />
							<Property name="Austauschmotor" value={formatYesNo(sale.hadEngineReplacement)} />
							<Property name="KM-Stand bei Tausch" value={formatNumber(sale.engineReplacementMileage)} />
							<Property name="Weitere Reifen" value={formatYesNo(sale.hasAdditionalTires)} />
							<Property name="Reifenart" value={formatTireType(sale.tiresAndRims?.tireType)} />
							<Property name="Felgenart" value={formatRimType(sale.tiresAndRims?.rimType)} />
						</Properties>
					</TileContent>
				</Tile>
			</Grid>

			{assessment && (
				<Grid item xs={12} md={6}>
					<RemarketingAssessmentTile vehicle={vehicle} assessment={assessment} />
				</Grid>
			)}
		</Grid>
	);
};

export default SalesInformation;
