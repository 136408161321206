import moment from "moment";
import { Dispatch, SetStateAction } from "react";

import { GetApp, RotateLeft } from "@mui/icons-material";
import { FormControl } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import Sidebar from "../../../Components/Sidebar/Sidebar";
import SidebarButton from "../../../Components/Sidebar/SidebarButton";
import SidebarForm from "../../../Components/Sidebar/SidebarForm";
import SidebarGroup from "../../../Components/Sidebar/SidebarGroup";
import useFileExport from "../../../system/useFileExport";
import { CarmarketOverviewQuery } from "./CarmarketOverview";

interface Props {
	query: CarmarketOverviewQuery;
	setQuery: Dispatch<SetStateAction<CarmarketOverviewQuery>>;
	resetQuery: () => CarmarketOverviewQuery;
}

const CarmarketOverviewSidebar = (props: Props) => {
	const [exportState, triggerExport] = useFileExport(props.query, "/api/sales/carmarket/b2b/overview");

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarForm>
					<FormControl fullWidth>
						<DatePicker
							// disableToolbar
							defaultValue=""
							// variant="inline"
							format="DD.MM.YYYY"
							// margin="none"
							label="Exportdatum"
							slotProps={{ textField: { variant: "standard" } }}
							value={props.query.date === undefined ? null : moment(props.query.date)}
							onChange={(d: any) =>
								props.setQuery((q) => ({
									...q,
									date: (moment(d) || moment()).startOf("d").toISOString(true),
								}))
							}
						/>
					</FormControl>
					<SidebarButton
						startIcon={<GetApp />}
						label="Reservierungsexport"
						onClick={triggerExport}
						disabled={exportState.loading || !props.query.date}
					/>
				</SidebarForm>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarButton startIcon={<RotateLeft />} label="Filter zurücksetzen" onClick={props.resetQuery} />
			</SidebarGroup>
		</Sidebar>
	);
};

export default CarmarketOverviewSidebar;
