import { useEffect, useState } from 'react';
import Layout from "../../Components/Layout/Layout";
import LotScanContextMenu from "./LotScanContextMenu";
import VehicleHeaderMenu from "./VehicleHeaderMenu";
import VehicleSubMenu from "./VehicleSubMenu";
import { useParams } from "react-router";
import useVehicle from "../../system/useVehicle";
import { Box, CircularProgress, Grid } from "@mui/material";
import VehicleHeader from "./VehicleHeader";
import RemarketingUnregistrationTile from "./RemarketingUnregistrationTile";
import Tile from "../../Components/Tiles/Tile";
import TileContent from "../../Components/Tiles/TileContent";
import ListItemChips from "../../Components/ListItemChips";
import ListItemChip from "../../Components/ListItemChip";
import TextGroup from "../VehicleSales/TextGroup";
import TextLine from "../VehicleSales/TextLine";
import formatDateTime from "../../system/formatDateTime";
import {
	translateVehicleRegistrationDocumentStatus
} from "./DocumentManagement/VehicleRegistrationDocumentsStatusPill";
import PicturesTile, { PictureReference } from "../../Components/PicturesTile";
import { DocumentsPictureProof, UserRole, Vehicle } from "../../system/Domain";
import { RegistrationDocuments as RegistrationDocumentsDto } from "../../system/Domain";
import ActionButton from "../../Components/ActionButton";
import Actions from "../../Components/Actions";
import ask from "../../Dialogs/ask";
import axios from "axios";
import useUser from "../../system/useUser";
import VehicleNavigation from 'Navigation/VehicleNavigation';
import ArrivalTransmissionNotesTile from "./ArrivalTransmissionNotesTile";

export function* enumeratePictures(reference: DocumentsPictureProof): Generator<PictureReference> {
	if (!!reference.registrationDocumentFront) {
		yield {
			title: "Fahrzeugschein Vorderseite",
			picture: reference.registrationDocumentFront
		}
	}
	if (!!reference.registrationDocumentBack) {
		yield {
			title: "Fahrzeugschein Rückseite",
			picture: reference.registrationDocumentBack
		}
	}
	if (!!reference.registrationTitle) {
		yield {
			title: "Fahrzeugbrief",
			picture: reference.registrationTitle
		}
	}
};

const RegistrationDocuments = () => {
	const { id } = useParams<{ id: string }>();
	const [, , hasRole] = useUser();

	const [vehicle, setVehicle] = useVehicle(id);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [, setRegistrationDocuments] = useState<RegistrationDocumentsDto>();

	const cancelDocumentHandover = async () => {
		if (!vehicle) return;

		const answer = await ask("Soll die Dokumentenausgabe wirklich storniert werden?");

		if (answer.yes) {
			try {
				setIsSubmitting(true);
				const { data: resultVehicle } = await axios.post<Vehicle>(`/api/vehicles/${vehicle.id}/remarketing/documents/handover/cancel`, {});
				setVehicle(resultVehicle);
			} finally {
				setIsSubmitting(false);
			}
		}
	};

	useEffect(() => {
		if (vehicle?.remarketing?.registrationDocuments) {
			setRegistrationDocuments(vehicle.remarketing.registrationDocuments);
		}
	}, [vehicle?.remarketing?.registrationDocuments]);

	return (
		<Layout
			title="Papiere"
			plateNumber={vehicle?.plateNumber}
			navigation={<VehicleNavigation vehicle={vehicle} />}
			contextMenu={[
				<LotScanContextMenu />,
				<VehicleHeaderMenu vehicle={vehicle} />
			]}
			subMenu={vehicle && <VehicleSubMenu vehicle={vehicle} />}
		>
			{!vehicle && (
				<CircularProgress size={48} />
			)}
			{vehicle && (
				<>
					<Grid container justifyContent="space-between" alignItems="flex-end" spacing={2}>
						<Grid item>
							<VehicleHeader vehicle={vehicle} />
						</Grid>
						<Grid item>
							<Grid container spacing={2} alignItems="center" justifyContent="space-between">
								<Grid item>
									<Actions>
										{vehicle.remarketing.registrationDocuments?.status === "HandedOver" && hasRole(UserRole.AldManager) && (
											<ActionButton
												color="secondary"
												disabled={isSubmitting}
												onClick={cancelDocumentHandover}>
												Ausgabe stornieren
											</ActionButton>
										)}
									</Actions>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Box mt={4}>
						<Grid container spacing={6}>
							<Grid item xs={12} sm={6} md={6} lg={4}>
								<RemarketingUnregistrationTile vehicle={vehicle} />
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={4}>
								<Tile title="Papiere">
									<TileContent>
										<TextGroup title="Statushistorie">
											{vehicle.remarketing.registrationDocuments?.statusHistory.map((s, i) => (
												<TextLine
													key={i}
													label={translateVehicleRegistrationDocumentStatus(s.value)}
													value={formatDateTime(s.date)}
												/>
											))}
										</TextGroup>

										{vehicle.remarketing.registrationDocuments?.status === "HandedOver" && vehicle.remarketing.registrationDocuments?.handover && (
											<TextGroup title="Ausgabe">
												<TextLine
													label="Entnommen durch"
													value={vehicle.remarketing.registrationDocuments.handover.removedBy}
												/>
												<TextLine
													label="Ausgabetyp"
													value={vehicle.remarketing.registrationDocuments.handover.handoverType}
												/>
												{vehicle.remarketing.registrationDocuments.handover.trackingReference && (
													<TextLine
														label="Tracking Referenz"
														value={vehicle.remarketing.registrationDocuments.handover.trackingReference}
													/>
												)}
											</TextGroup>
										)}

										<ListItemChips>
											<ListItemChip
												label={translateVehicleRegistrationDocumentStatus(vehicle.remarketing.registrationDocuments?.status)}
												color={vehicle.remarketing.registrationDocuments?.status ? "primary" : "info"}
											/>
										</ListItemChips>
									</TileContent>
								</Tile>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={4}>
								<ArrivalTransmissionNotesTile vehicle={vehicle} setVehicle={setVehicle} />
							</Grid>
							{vehicle.remarketing.registrationDocuments?.documentsPictureProof && (
								<Grid item xs={12} sm={6} md={6} lg={4}>
									<PicturesTile
										pictures={Array.from(enumeratePictures(vehicle.remarketing.registrationDocuments.documentsPictureProof))}
									/>
								</Grid>
							)}
						</Grid>
					</Box>
				</>
			)}
		</Layout>
	);
};

export default RegistrationDocuments;
