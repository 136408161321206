import axios from "axios";
import { useAsync } from "react-use";
import { useCallback, useState } from "react";
import moment from "moment";

interface Vat {
	fullVatRate : number
}

export default (date : Date | string | null = null) => {
	const [vat, setVat] = useState<Vat | null>(null);

	useAsync(async () => {
		let url = "/api/vat";
		if (date) {
			url = `/api/vat?date=${moment(date).format("YYYY-MM-DD")}`
		}
		const { data: vat } = await axios.get<Vat>(url);

		if (!vat) {
			throw new Error("No Vat");
		}

		setVat(vat);
	}, [date]);

	const calculateVatAmount = useCallback((netAmount : number) => {
		if (!vat) {
			return 0;
		}
		return Math.round(netAmount * vat.fullVatRate * 100) / 100;
	}, [vat]);

	const calculateNetAmount = useCallback((grossAmount : number) => {
		if (!vat) {
			return 0;
		}
		return Math.round(grossAmount / (1 + vat.fullVatRate) * 100) / 100;
	}, [vat]);

	const calculateGrossAmount = useCallback((netAmount : number) => {
		if (!vat) {
			return 0;
		}
		return Math.round(netAmount * (1 + vat.fullVatRate) * 100) / 100;
	}, [vat]);

	return { ...vat, calculateVatAmount, calculateNetAmount, calculateGrossAmount };
}
