import './Plate.css';

import PlateNumber from 'german-plate';
import useCopyWithAltKey from "../../system/useCopyWithAltKey";

const getClassName = (c : string) => {
	c = c.toLowerCase();

	if (!isNaN(parseInt(c, 0))) {
		return 'n' + c;
	}

	switch (c) {
		case 'ä':
			return 'ae';
		case 'ü':
			return 'ue';
		case 'ö':
			return 'oe';
		default:
			return c;
	}
};

const mapElements = (character : string, index : number) => {
	return <span key={index} className={getClassName(character)} />;
};

const Plate = ({ plateNumber, style } : { plateNumber : string, style? : any }) => {
	const copyWithAltKey = useCopyWithAltKey(plateNumber);

	const elementStyle = {
		display: 'inline-block',
		...style,
	};

	let t = 0;
	plateNumber = plateNumber.replace(/-/g, match => {
		t++;
		return (t > 1) ? " " : match;
	});

	let plate, kreis, buchstaben, ziffern, bundesland;
	try {
		plate = PlateNumber.parse(plateNumber);
		kreis = plate.registrationCommunity.id;
		buchstaben = plate.alphanumeric;
		ziffern = `${plate.numeric}${plate.suffix}`;
		bundesland = plate.registrationCommunity.state.shortCut;
	} catch (e) {
		const plateNumberParts = plateNumber.split(/[-\s]+/);
		if (plateNumberParts.length === 3) {
			[kreis, buchstaben, ziffern] = plateNumberParts;
		} else {
			console.error("unknown plate number format.", plateNumber);
			[kreis, buchstaben, ziffern] = ["X", "XX", "1111"];
		}
		bundesland = "";
	}

	kreis = kreis.split('').map(mapElements);
	buchstaben = buchstaben.split('').map(mapElements);
	ziffern = ziffern.split('').map(mapElements);

	return (
		<div onClick={copyWithAltKey} style={elementStyle}>
			<div className="kennzeichen">
				<div className="schild">
					<span className="kreis">{kreis}</span>
					<span className="plakette">
						<span style={{ visibility: 'hidden' }} />
					</span>{' '}
					<span className="siegel" style={{ marginLeft: 2, marginRight: 2 }}>
						<span className={bundesland} />
					</span>
					<span className="buchstaben">{buchstaben}</span>
					<span className="ziffern">{ziffern}</span>
				</div>
			</div>
		</div>
	);
};

export default Plate;
