import { useEffect, useState } from "react";
import { alpha, Box, useTheme } from "@mui/material";
import { GeoPoint, CompoundPlace } from "../../system/Domain";
import Map from "../../Components/Map";
import useGoogleMaps from "../../system/useGoogleMaps";
import useCompoundPlaces from "../../system/useCompoundPlaces";

const getPolygonCenter = (vertices: any[]): google.maps.LatLng => {
	const lats: any[] = [];
	const lngs: any[] = [];
	for (let i = 0; i < vertices.length; i++) {
		lngs.push(vertices[i].lng);
		lats.push(vertices[i].lat);
	}

	lats.sort();
	lngs.sort();
	const lowx = lats[0];
	const highx = lats[vertices.length - 1];
	const lowy = lngs[0];
	const highy = lngs[vertices.length - 1];
	const center_x = lowx + (highx - lowx) / 2;
	const center_y = lowy + (highy - lowy) / 2;

	return new google.maps.LatLng(center_x, center_y);
};

export interface LotMapsProps {
	currentPlace?: CompoundPlace;
	selectedPlace?: CompoundPlace;
	onPlaceClick?: (place: CompoundPlace) => void;
	onLocationChanged?: (location: GeoPoint) => void;
}

export default ({ currentPlace, selectedPlace, onPlaceClick, onLocationChanged }: LotMapsProps) => {
	const [location, setLocation] = useState<GeoPoint>();
	const [geoPosition, setGeoPosition] = useState<GeolocationPosition>();
	const [geoPositionError, setGeoPositionError] = useState<GeolocationPositionError>();
	const requestGeoLocation = () => {
		setGeoPosition(undefined);
		setGeoPositionError(undefined);

		navigator.geolocation.watchPosition(
			(pos) => {
				setGeoPosition(pos);
			},
			(error) => {
				setGeoPositionError(error);
			},
			{ enableHighAccuracy: true, maximumAge: 5000 },
		);
	};
	useEffect(() => {
		requestGeoLocation();
	}, []);

	useEffect(() => {
		setLocation(undefined);

		if (!geoPosition) {
			return;
		}

		setLocation({
			lat: geoPosition.coords.latitude,
			lon: geoPosition.coords.longitude,
		});
	}, [geoPosition]);

	useEffect(() => {
		if (location && onLocationChanged) {
			onLocationChanged(location);
		}
	}, [location]);

	const [isGoogleMapsLoaded] = useGoogleMaps();
	const [places] = useCompoundPlaces();

	useEffect(() => {
		if (!currentPlace || !places || !isGoogleMapsLoaded) return;

		const place = places.find((p) => p.id === currentPlace!.id);
		if (!place) return;

		setMapCenter(
			getPolygonCenter(
				place.geoArea.points.map((point) => ({
					lat: point.lat,
					lng: point.lon,
				})),
			),
		);
	}, [currentPlace, places, isGoogleMapsLoaded]);

	const [mapCenter, setMapCenter] = useState<google.maps.LatLng>();
	const [mapMarkers, setMapMarkers] = useState<google.maps.ReadonlyMarkerOptions[]>();
	const [mapPolygons, setMapPolygons] = useState<google.maps.PolygonOptions[]>();
	const theme = useTheme();
	useEffect(() => {
		setMapMarkers(undefined);
		setMapPolygons(undefined);

		if (!isGoogleMapsLoaded || !places) {
			return;
		}

		const markers: google.maps.ReadonlyMarkerOptions[] = [];
		const polygons: google.maps.PolygonOptions[] = [];

		for (const place of places.filter((f) => f.isActive !== false)) {
			const isCurrent = place === currentPlace;
			const isSelected = place === selectedPlace;

			const polygon: google.maps.PolygonOptions = {
				strokeColor: theme.palette.primary.dark,
				strokeOpacity: 0.4,
				strokeWeight: 2,
				fillColor: isCurrent
					? theme.palette.primary.main
					: isSelected
						? alpha(theme.palette.primary.main, 0.5)
						: // : theme.palette.secondary.light,
							"rgba(0, 0, 0, 0.6)",
				fillOpacity: isSelected ? 0.7 : isCurrent ? 0.7 : 0.7,
				clickable: true,
				paths: place.geoArea.points.map((point) => ({
					lat: point.lat,
					lng: point.lon,
				})),
			};

			polygons.push(polygon);

			const markerSelected = (isCurrent && (isSelected || !selectedPlace)) || isSelected;

			const markerLabel: google.maps.MarkerLabel = {
				text: place.name,
				fontFamily: "Arial",
				fontWeight: markerSelected ? "bold" : "bold",
				fontSize: "0.9rem",
			};

			const marker: google.maps.ReadonlyMarkerOptions = {
				position: getPolygonCenter(polygon.paths as any[]),
				label: markerLabel,
				opacity: markerSelected ? 1 : 0.9,
				clickable: false,
				icon: {
					url: "",
					size: new google.maps.Size(32, 32),
					scaledSize: new google.maps.Size(16, 16),
					origin: new google.maps.Point(0, 0),
					anchor: new google.maps.Point(16, 16),
				},
			};

			markers.push(marker);

			const handlers = {
				click: () => {
					if (onPlaceClick) {
						onPlaceClick(place);
					}
				},
			};

			// (marker as any).handlers = handlers;
			(polygon as any).handlers = handlers;

			if (!mapCenter && isCurrent) {
				// setMapCenter(marker.position);
			}
		}

		if (!!location) {
			const offset = {
				lat: 0, // -0.6687563,
				lng: 0, //-0.2502307
			};

			const locationMarker: google.maps.ReadonlyMarkerOptions = {
				position: {
					lat: location.lat + offset.lat,
					lng: location.lon + offset.lng,
				},
				// opacity: 0.5,
				icon: {
					url: "/static/maps_location.png",
					// url: "about:blank",
					size: new google.maps.Size(32, 32),
					scaledSize: new google.maps.Size(16, 16),
					origin: new google.maps.Point(0, 0),
					// anchor: new google.maps.Point(8, 8)
				},
			};

			markers.push(locationMarker);
		}

		setMapPolygons(polygons);
		setMapMarkers(markers);
	}, [isGoogleMapsLoaded, places, currentPlace, selectedPlace, location]);

	return (
		<Box display="flex" flexGrow={1} flexDirection="column">
			{currentPlace && currentPlace.isActive === false && (
				<Box mt={1} mb={2}>
					Aktueller Platz: {currentPlace.name}
				</Box>
			)}
			{!!geoPositionError && (
				<Box
					mt={1}
					mb={2}
					sx={{
						color: theme.palette.error.main,
					}}
				>
					Fehler bei Ermittlung des Standorts: <b>{geoPositionError?.message}</b>
				</Box>
			)}
			<Map
				opts={{
					draggable: true,
					fullscreenControl: true,
					zoom: 18,
				}}
				center={mapCenter}
				markers={mapMarkers}
				polygons={mapPolygons}
			/>
		</Box>
	);
};
