import { Link, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import _ from "lodash";
import { Import } from "system/Domain";
import formatDateTime from "system/formatDateTime";
import importStatus from "./importStatus";

export default function ImportHistory({ imp }: { imp: Import }) {
	const items = _.orderBy(imp.history, (h) => h.date, "desc");

	return (
		<Table>
			<colgroup>
				<col width="20%" />
				<col width="20%" />
				<col width="60%" />
			</colgroup>
			<TableHead>
				<TableRow>
					<TableCell>Job</TableCell>
					<TableCell>Zeitpunkt</TableCell>
					<TableCell>Letzter Status</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{items.map((h) => (
					<TableRow key={h.date}>
						<TableCell>
							<Link href={`/admin/jobs/${h.taskId}`}>{h.taskId}</Link>
						</TableCell>
						<TableCell>{formatDateTime(h.date)}</TableCell>
						<TableCell>{importStatus[h.status]}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
}
