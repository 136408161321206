import { Box, Typography } from "@mui/material";
import ContactPersonView from "./ContactPersonView";
import { Address, ContactPerson } from "../../../system/Domain";
import TextBlock from "../../../Components/Layout/TextBlock";

interface AddressProps {
	address: Address;
	contact: ContactPerson;
}

export default ({ address, contact }: AddressProps) => {
	return (
		<>
			<Box mb={1}>
				<TextBlock
					primary={
						<>
							<Box>{address.name}</Box>
							{address.name1 && <Box>{address.name1}</Box>}
						</>
					}
					secondary={address.additionalInformation}
				/>
			</Box>
			{contact && <ContactPersonView contact={contact} />}
			<Box mt={1}>
				<Typography component="div">{address.street}</Typography>

				<Typography component="div">
					{address.zipCode} {address.city}
				</Typography>
			</Box>
		</>
	);
};
