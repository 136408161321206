import axios from "axios";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { Inventory } from "./Domain";
import useAsyncEffect from "./useAsyncEffect";

export default (id : string) : [Inventory | undefined, Dispatch<SetStateAction<Inventory | undefined>>, (id : string) => Promise<void>] => {
	const [inventory, setInventory] = useState<Inventory>();

	const load = useCallback(async (id : string) => {
		if (!id) {
			return;
		}

		const { data: inventory } = await axios.get<Inventory>(`/api/inventories/${id}`);
		setInventory(inventory);
	}, [id, inventory]);

	useAsyncEffect(async () => {
		setInventory(undefined);
		await load(id);
	}, [id]);

	return [
		inventory,
		setInventory,
		load
	];
};