import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import ReactJson from "react-json-view";

interface Props {
	json: object | undefined;
}

const JsonViewer = (props: Props) => {
	const theme = useTheme();
	const darkTheme = theme.palette.mode === "dark";

	return (
		<>
			{!props.json && (
				<Box textAlign="center">
					<CircularProgress />
				</Box>
			)}
			{props.json && <ReactJson src={props.json} theme={darkTheme ? "ocean" : "rjv-default"} />}
		</>
	);
};

export default JsonViewer;
