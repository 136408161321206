import axios from "axios";
import qs from "query-string";
import { useState } from "react";
import { Box } from "@mui/material";
import VinQrScanner from "./VinQrScanner";
import { useHistory } from "react-router-dom";
import useAsyncEffect from "system/useAsyncEffect";
import { Vehicle, TransportOrder } from "system/Domain";

export default () => {
	const history = useHistory();
	const [vin, setVin] = useState<string>();

	useAsyncEffect(async () => {
		if (!vin) {
			return;
		}

		try {
			const { data: vehicle } = await axios.post<Vehicle>(`/api/compounds/scans`, {
				vin,
			});

			if (vehicle.remarketing.status === "Registriert") {
				const { data: orders } = await axios.get<TransportOrder[]>(`/api/orders/transports`, {
					params: {
						vin,
						status: ["Assigned", "Completed"],
					},
					paramsSerializer: (params) => qs.stringify(params),
				});

				if (orders.length === 1) {
					history.push({
						pathname: `/orders/transports/${orders[0].id}`,
					});

					return;
				}
			}

			history.push({
				pathname: `/vehicles/${vehicle.id}`,
			});
		} finally {
		}
	}, [vin]);

	return (
		<Box>
			<VinQrScanner setVin={setVin} />
		</Box>
	);
};
