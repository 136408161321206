import { CircularProgress } from "@mui/material";
import axios from "axios";
import { useState } from 'react';
import { useParams } from "react-router";
import Layout from "../../../Components/Layout/Layout";
import { AssessmentOrder, Vehicle } from "../../../system/Domain";
import useAsyncEffect from "../../../system/useAsyncEffect";
import AssessmentOrdersForm, { calculateAssessmentOrderFormData } from "./AssessmentOrdersForm";
import OrdersNavigation from "../../../Navigation/OrdersNavigation";

const AssessmentOrdersEdit = () => {
	const { id } = useParams<{ id: string }>();
	const [assessmentOrder, setAssessmentOrder] = useState<AssessmentOrder>();
	const [vehicle, setVehicle] = useState<Vehicle>();

	useAsyncEffect(async () => {
		if (!id) {
			setAssessmentOrder(undefined);
			return;
		}

		const { data } = await axios.get<AssessmentOrder>(`/api/orders/assessments/${id}`);
		setAssessmentOrder(data);
	}, [id]);

	useAsyncEffect(async () => {
		if (!assessmentOrder) {
			setVehicle(undefined);
			return;
		}

		const { data } = await axios.get(`/api/vehicles/${assessmentOrder.vehicle.id}`);
		setVehicle(data);
	}, [assessmentOrder?.vehicle.id])

	const formData = assessmentOrder ? { ...calculateAssessmentOrderFormData(assessmentOrder) } : {};

	return (
		<Layout
			plateNumber={assessmentOrder?.plateNumber}
			title="Gutachtenauftrag bearbeiten"
			navigation={<OrdersNavigation />}
		>
			{(!assessmentOrder || !vehicle) && (
				<CircularProgress size={48} />
			)}
			{assessmentOrder && vehicle && (
				<AssessmentOrdersForm vehicle={vehicle} formData={formData} id={assessmentOrder.id} />
			)}
		</Layout>
	);
};

export default AssessmentOrdersEdit;
