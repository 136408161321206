import { CompoundPlace, CompoundReference } from "system/Domain";

export type CompoundPlaceMarker = google.maps.Marker & {
	id: string;
};

export type CompoundPlacePolygon = google.maps.Polygon & {
	id: string;
};

export type CompoundPlaceDesign = CompoundPlace & {
	isNew: boolean;
	lot: CompoundReference & {
		id: number;
	};
};

let i = 0;

const getNextLocation = () => {
	i = (i + 1) % locations.length;
	return locations[i].coordinates;
};

export const ControlButton = (div: Element, map: google.maps.Map, text: string, title: string) => {
	// Set CSS for the control border.
	const controlUI = document.createElement("div");
	controlUI.style.backgroundColor = "#fff";
	controlUI.style.border = "2px solid #fff";
	controlUI.style.borderRadius = "3px";
	controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
	controlUI.style.cursor = "pointer";
	controlUI.style.marginBottom = "22px";
	controlUI.style.textAlign = "center";
	controlUI.style.margin = "10px";
	controlUI.title = title;
	div.appendChild(controlUI);

	// Set CSS for the control interior.
	const controlText = document.createElement("div");
	controlText.style.color = "rgb(25,25,25)";
	controlText.style.fontFamily = "Roboto,Arial,sans-serif";
	controlText.style.fontSize = "20px";
	controlText.style.lineHeight = "25px";
	controlText.style.fontWeight = "100";
	controlText.style.paddingLeft = "5px";
	controlText.style.paddingRight = "5px";
	controlText.style.letterSpacing = "0.4px";
	controlText.style.webkitTextFillColor = "rgb(25,25,25)";
	controlText.style.width = "25px";
	controlText.innerHTML = text;
	controlUI.appendChild(controlText);

	// Set up the click event listeners: simply set the map to Norderstedt.
	controlUI.addEventListener("click", () => {
		map.setCenter(getNextLocation());
	});
};

export const polygonDefaultOptions = { fillColor: "#107ba9", strokeColor: "#107ba9", fillOpacity: 0.2 };
export const polygonInactiveOptions = { fillColor: "#344955", strokeColor: "#344955", fillOpacity: 0.3 };

const norderstedt = {
	id: 1,
	coordinates: { lat: 53.6677, lng: 9.975 },
	name: "Norderstedt",
	address: {
		name: "ALD AUTOLEASING D GMBH",
		name1: "Zentraler Gebrauchtwagenvertrieb",
		street: "Becklinger Str. 23",
		zipCode: "29683",
		city: "Dorfmark",
		country: "Deutschland",
	},
};
const dorfmark = {
	id: 2,
	coordinates: { lat: 52.898, lng: 9.778 },
	name: "Dorfmark",
	address: {
		name: "ALD AUTOLEASING D GMBH",
		name1: "Zentraler Gebrauchtwagenvertrieb",
		street: "Becklinger Str. 23",
		zipCode: "29683",
		city: "Dorfmark",
		country: "Deutschland",
	},
};
const dorfmarkBahnhof = {
	id: 201,
	coordinates: { lat: 52.898865763267004, lng: 9.771296959439226 },
	name: "Dorfmark Bahnhof",
	address: {
		name: "ALD AUTOLEASING D GMBH",
		name1: "Zentraler Gebrauchtwagenvertrieb",
		street: "Becklinger Str. 23",
		zipCode: "29683",
		city: "Dorfmark",
		country: "Deutschland",
	},
};
const dorfmarkSiemsglüss = {
	id: 202,
	coordinates: { lat: 52.89722368466212, lng: 9.777427527119778 },
	name: "Dorfmark Siemsglüss",
	address: {
		name: "ALD AUTOLEASING D GMBH",
		name1: "Zentraler Gebrauchtwagenvertrieb",
		street: "Becklinger Str. 32",
		zipCode: "29683",
		city: "Dorfmark",
		country: "Deutschland",
	},
};
const badFallingbostel = {
	id: 3,
	coordinates: { lat: 52.85084, lng: 9.692551 },
	name: "Bad Fallingbostel",
	address: {
		name: "ALD AUTOLEASING D GMBH",
		name1: "Zentraler Gebrauchtwagenvertrieb",
		street: "Becklinger Str. 23",
		zipCode: "29683",
		city: "Dorfmark",
		country: "Deutschland",
	},
};
const bochum = {
	id: 4,
	coordinates: { lat: 51.486347729215424, lng: 7.2976849003447155 },
	name: "Bochum",
	address: {
		name: "HARTMANN FLEET Professionals GmbH",
		street: "Arnoldschacht 9 - 11",
		zipCode: "44894",
		city: "Bochum",
		country: "Deutschland",
	},
};
const dueren = {
	id: 5,
	coordinates: { lat: 50.784914899805486, lng: 6.503077887480988 },
	name: "Düren",
	address: {
		name: "ALD AUTOLEASING D GMBH",
		name1: "Zentraler Gebrauchtwagenvertrieb",
		street: "Becklinger Str. 23",
		zipCode: "29683",
		city: "Düren",
		country: "Deutschland",
	},
};
const emmering = {
	id: 6,
	coordinates: { lat: 48.17836244331753, lng: 11.304976398792734 },
	name: "Emmering",
	address: {
		name: "ALD AUTOLEASING D GMBH",
		name1: "Zentraler Gebrauchtwagenvertrieb",
		street: "Becklinger Str. 23",
		zipCode: "29683",
		city: "Emmering",
		country: "Deutschland",
	},
};
const seddinerSee = {
	id: 7,
	coordinates: { lat: 52.28889502951106, lng: 13.006424236593212 },
	name: "Seddiner See",
	address: {
		name: "ALD AUTOLEASING D GMBH",
		name1: "Zentraler Gebrauchtwagenvertrieb",
		street: "Becklinger Str. 23",
		zipCode: "29683",
		city: "Seddiner See",
		country: "Deutschland",
	},
};
const bremen = {
	id: 8,
	coordinates: { lat: 53.17567600844559, lng: 8.587037325469405 },
	name: "Bremen",
	address: {
		name: "ALD AUTOLEASING D GMBH",
		name1: "Zentraler Gebrauchtwagenvertrieb",
		street: "Becklinger Str. 23",
		zipCode: "29683",
		city: "Bremen",
		country: "Deutschland",
	},
};
const mueckeAtzenhain = {
	id: 9,
	coordinates: { lat: 50.64883229657196, lng: 8.98902513083316 },
	name: "Mücke-Atzenhain",
	address: {
		name: "ALD AUTOLEASING D GMBH",
		name1: "Zentraler Gebrauchtwagenvertrieb",
		street: "Becklinger Str. 23",
		zipCode: "29683",
		city: "Mücke-Atzenhain",
		country: "Deutschland",
	},
};
const neuss = {
	id: 10,
	coordinates: { lat: 51.21413238627201, lng: 6.711731423252748 },
	name: "Neuss",
	address: {
		name: "ALD AUTOLEASING D GMBH",
		name1: "Zentraler Gebrauchtwagenvertrieb",
		street: "Becklinger Str. 23",
		zipCode: "29683",
		city: "Dorfmark",
		country: "Deutschland",
	},
};
const heidenheim = {
	id: 11,
	coordinates: { lat: 48.69057866033265, lng: 10.163080839217546 },
	name: "Heidenheim",
	address: {
		name: "BCA Autoauktionen GmbH",
		street: "Siemensstraße 85",
		zipCode: "89520",
		city: "Heidenheim",
		country: "Deutschland",
	},
};
const hoppegarten = {
	id: 12,
	coordinates: { lat: 52.514591648222144, lng: 13.655254047818486 },
	name: "Hoppegarten",
	address: {
		name: "BCA Autoauktionen GmbH",
		street: "Neuer Hönower Weg 4",
		zipCode: "15366",
		city: "Hoppegarten",
		country: "Deutschland",
	},
};
const kerpen = {
	id: 13,
	coordinates: { lat: 50.89576037913442, lng: 6.6498368581360285 },
	name: "Kerpen",
	address: {
		name: "TGC Autotransporte GmbH",
		name1: "C/O BCA Autoauktionen GmbH",
		street: "Hüttenstraße 130",
		zipCode: "50170",
		city: "Kerpen",
		country: "Deutschland",
	},
};

export const locations = [
	dorfmark,
	dorfmarkBahnhof,
	dorfmarkSiemsglüss,
	badFallingbostel,
	bochum,
	bremen,
	dueren,
	emmering,
	heidenheim,
	hoppegarten,
	kerpen,
	mueckeAtzenhain,
	neuss,
	norderstedt,
	seddinerSee,
];

export const generateTempId = (length: number = 6): string => {
	const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let result = "temp-";

	for (let i = 0; i < length; i++) result += characters.charAt(Math.floor(Math.random() * characters.length));

	return result;
};
