import axios from 'axios';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { AssignmentTurnedIn, GetApp } from '@mui/icons-material';

import TextBlock from '../../Components/Layout/TextBlock';
import ListItem from '../../Components/ListItem';
import ListItemAction from '../../Components/ListItemAction';
import ListItemActions from '../../Components/ListItemActions';
import ListItemChip from '../../Components/ListItemChip';
import ListItemChips from '../../Components/ListItemChips';
import ListItemContent from '../../Components/ListItemContent';
import dateFormats from '../../system/dateFormats';
import {
    CarmarketSale, CarmarketSaleStatus, CarmarketSaleType, ThemeColor, WorkSession
} from '../../system/Domain';
import parseContentDispositionFilename from '../../system/parseContentDispositionFilename';
import { setWorkSession } from '../../system/reducers/workSessions/actions';
import formatWorkSessionPerspective from '../WorkSessions/formatWorkSessionPerspective';

interface Props {
	carmarketSale : CarmarketSale
}

export const formatCarmarketAuctionType = (type : CarmarketSaleType) => {
	switch (type) {
		case "auction":
			return "Auktion";
		case "fixedPrice":
			return "Sofortkaufauktion";
		default:
			return type;
	}
};

export const formatCarmarketSaleStatus = (status : CarmarketSaleStatus) => {
	switch (status) {
		case "Created":
			return "Erstellt";
		case "Published":
			return "Veröffentlicht";
		case "Opened":
			return "Geöffnet";
		case "Closed":
			return "Geschlossen";
		case "Awarded":
			return "Ausgewertet";
		case "Withdrawn":
			return "Zurückgezogen";
		default:
			return status;
	}
};

export const paintCarmarketSaleStatus = (status : CarmarketSaleStatus) : ThemeColor => {
	switch (status) {
		case "Closed":
			return "primary";
		case "Awarded":
			return "success";
		case "Withdrawn":
			return "warning";
		case "Created":
		case "Published":
		case "Opened":
		default:
			return "info";
	}
};

const CarmarketAuctionListItem = (props : Props) => {
	const dispatch = useDispatch();

	const createWorkSession = async (session : Partial<WorkSession>) => {
		const { data: workSession } = await axios.post(`/api/work-sessions`, {
			isPublic: true,
			title: formatWorkSessionPerspective(session.perspective || "Manual"),
			...session
		});

		if (workSession) {
			dispatch(setWorkSession(workSession));
		}
	};

	const startCarmarketEvaluationWorkSession = () => createWorkSession({
		perspective: "CarmarketSalesEvaluation",
		referenceId: props.carmarketSale.id
	});

	const exportCarmarketEvaluation = async () => {
		const response = await axios.get(`/api/carmarket/b2b/sales/${props.carmarketSale.id}/evaluation`, {
			responseType: "blob",
			params: {
				output: "xlsx"
			}
		});

		const fileName = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, fileName);
	};

	return (
		<ListItem color="info">
			<ListItemActions>
				<ListItemAction
					icon={<GetApp />}
					onClick={exportCarmarketEvaluation}
				/>
				<ListItemAction
					icon={<AssignmentTurnedIn/>}
					onClick={startCarmarketEvaluationWorkSession}
				/>
			</ListItemActions>
			<ListItemContent
				onClick={startCarmarketEvaluationWorkSession}
			>
				<TextBlock
					primary={props.carmarketSale.name}
					secondary={props.carmarketSale.code}
				/>
				<ListItemChips>
					<ListItemChip
						label={formatCarmarketAuctionType(props.carmarketSale.type)}
					/>
					<ListItemChip
						label={formatCarmarketSaleStatus(props.carmarketSale.status)}
						color={paintCarmarketSaleStatus(props.carmarketSale.status)}
					/>
					<ListItemChip
						label={moment(props.carmarketSale.effectiveEndDate ?? props.carmarketSale.plannedEndDate).format(dateFormats.dateTime)}
					/>
				</ListItemChips>
			</ListItemContent>
		</ListItem>
	);
};

export default CarmarketAuctionListItem;
