import { Box } from "@mui/material";
import InventoryStatus from "./InventoryStatus";
import InventoryProgress from "./InventoryProgress";
import { InventoryProps } from "./Inventory";

export default ({ inventory } : InventoryProps) => (
	<Box mb={4}>
		<InventoryProgress inventory={inventory} />
		<Box mt={2}>
			<InventoryStatus inventory={inventory} />
		</Box>
	</Box>
);
