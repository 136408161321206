import { Close } from '@mui/icons-material';
import { Box, IconButton, Paper, Typography, useTheme } from '@mui/material';

interface Props {
	title: string
	onClose?: () => void
}

const SalesDocumentTitle = ({ title, onClose }: Props) => {
	const theme = useTheme();

	return (
		<Paper sx={{
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			alignItems: "center",
			padding: theme.spacing(2)
		}}>
			<Box>
				<Typography>{title}</Typography>
			</Box>
			{onClose && (
				<IconButton
					onClick={onClose}
					size="small"
					sx={{
						padding: 0
					}}
				>
					<Close />
				</IconButton>
			)}
		</Paper>
	);
};

export default SalesDocumentTitle;
