import { Sale } from "../../../system/Domain";
import { useHistory } from "react-router";
import SidebarButton from "../../../Components/Sidebar/SidebarButton";
import SidebarGroup from "../../../Components/Sidebar/SidebarGroup";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import { Grid } from "@mui/material";

interface Props {
	sales?: Sale | null
}

const SalesOrdersSidebar = ({ sales }: Props) => {
	const history = useHistory();
	const reservedOffer = sales && sales.offers.find(o => o.status === "Reserved");

	const handleBack = () => {
		history.push(`/sales/${sales?.id}`);
	};

	const handleCreateOrder = () => {
		history.push(`/sales/${sales?.id}/order`);
	};

	const handleCreateDirectOrder = () => {
		history.push(`/sales/${sales?.id}/direct-sales`);
	};

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarButton
					onClick={handleBack}
				>
					Übersicht Verkauf
				</SidebarButton>
				{sales && sales.status === "Reserved" && (
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<SidebarButton
								label="Neue Bestellung"
								disabled={!Boolean(reservedOffer)}
								onClick={handleCreateOrder}
							/>
						</Grid>
						<Grid item xs={12}>
							<SidebarButton
								label="Direktverkauf"
								disabled={!Boolean(reservedOffer)}
								onClick={handleCreateDirectOrder}
							/>
						</Grid>
					</Grid>
				)}
			</SidebarGroup>
		</Sidebar>
	);
};

export default SalesOrdersSidebar;
