import SidebarGroup from "../../Components/Sidebar/SidebarGroup";
import SidebarButton from "../../Components/Sidebar/SidebarButton";
import Sidebar from "../../Components/Sidebar/Sidebar";
import useAction from "../../system/useAction";
import ask from "../../Dialogs/ask";
import axios from "axios";
import useUser from "../../system/useUser";
import { UserRole } from "../../system/Domain";
import useCompounds from "system/useCompounds";
import SidebarForm from "Components/Sidebar/SidebarForm";
import { Box, Divider, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { ArrivalTransmissionQuery } from "./DadArrivalTransmissionsLayout";
import { Dispatch, SetStateAction } from "react";

export interface Props {
	query: ArrivalTransmissionQuery;
	setQuery: Dispatch<SetStateAction<ArrivalTransmissionQuery>>;
	resetQuery: () => ArrivalTransmissionQuery;
}

export default ({ query, setQuery, resetQuery }: Props) => {
	const [, , hasRole] = useUser();
	const [compounds] = useCompounds({ includeAmazonCompounds: false });

	const [order, isOrdering] = useAction(async () => {
		if (!query.compound) {
			return;
		}

		const answer = await ask(
			"Sollen für die abgeschlossenen Transportaufträge jetzt die Packlisten erstellt werden?"
		);
		if (answer.yes) {
			await axios.post(`/api/arrivaltransmissions/dad`, {
				compound: query.compound
			});
		}
	});

	return (
		<Sidebar>
			<SidebarGroup>
				{hasRole(UserRole.LotManager) && (
					<SidebarButton
						label="Erzeugen"
						color="primary"
						disabled={isOrdering || !query.compound}
						onClick={order}
					/>
				)}
			</SidebarGroup>
			<SidebarGroup>
				<SidebarForm>
					{compounds && (
						<FormControl variant="standard" fullWidth>
							<InputLabel>Standort</InputLabel>
							<Select
								value={query.compound || "-"}
								onChange={(e) => {
									let value = e.target.value as string | undefined;
									if (value === "-") {
										value = undefined;
									}

									setQuery((g) => ({
										...g,
										skip: 0,
										compound: value as string
									}));
								}}
							>
								<MenuItem value="-">Alle</MenuItem>
								<Box my={2}>
									<Divider />
								</Box>
								{compounds.map((c) => (
									<MenuItem key={c.name} value={c.name}>
										{c.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
				</SidebarForm>
			</SidebarGroup>
		</Sidebar>
	);
};
