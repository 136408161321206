import { useHistory } from 'react-router';
import { CloudUpload } from '@mui/icons-material';
import {
	Box, Chip, IconButton, PropTypes, TableBody, TableHead, TableRow
} from '@mui/material';
import TextBlock from '../../Components/Layout/TextBlock';
import PaperTable from '../../Components/PaperTable';
import { BillingServices } from '../../system/Domain';
import formatCurrency from '../../system/formatCurrency';
import formatNumber from '../../system/formatNumber';
import getBillingInvoices from './getBillingInvoices';
import { BillingPropsWithSetter } from './useBilling';
import useBillingInvoiceUpload from './useBillingInvoiceUpload';
import useBillingPermissions from './useBillingPermissions';
import { Colgroup, TableCell } from "../../Components/BreakpointStyledComponents";

export default ({ billing, setBilling }: BillingPropsWithSetter) => {
	const history = useHistory();
	const [uploadInvoice, isUploadingInvoice] = useBillingInvoiceUpload();

	const permissions = useBillingPermissions();
	const showActions = permissions.canViewInvoices(billing) && billing.status === "Closed";

	const row = (name: string, services: BillingServices, color: Exclude<PropTypes.Color, 'inherit'> = "default") => {
			const title = (
				<Chip
					color={color}
					label={name}
				/>
			);

			const billingClient = billing.clients.find(c => c.name === name);
			const billingInvoices = getBillingInvoices(billing, billingClient);

			const summary = (
				<TextBlock
					primary={formatCurrency(services.netAmount)}
					secondary={<>
						<span>{formatNumber(services.total)} Tätigkeiten</span>
						{showActions && billingInvoices.length > 0 && (
							<span> · {formatNumber(billingInvoices.length)} {billingInvoices.length === 1 ? "Rechnung" : "Rechnungen"}</span>
						)}
					</>}
				/>
			);

			const actions = showActions && (
				<>
					{permissions.canUploadInvoices(billing) && billingClient && (
						<IconButton
							// variant="text"
							// color="default"
							size="small"
							// startIcon={<CloudUploadIcon />}
							onClick={async (e) => {
								e.stopPropagation();
								await uploadInvoice(billing, billingClient.name, setBilling);
							}}
							disabled={isUploadingInvoice}
						>
							<CloudUpload />
						</IconButton>
					)}
				</>
			);

			return (
				<TableRow
					key={name}
					onClick={() => history.push(`/billings/${billing.id}/clients/${name}`)}
					hover
				>
					<TableCell>
						{title}
						<Box sx={{ display: { xs: "contents", sm: "none" } }}>
							<Box mt={2}>
								{summary}
							</Box>
							{showActions && (
								<Box mt={2}>
									{actions}
								</Box>
							)}
						</Box>
					</TableCell>
					<TableCell sm md lg xl>
						{summary}
					</TableCell>
					{showActions && (
						<TableCell sm md lg xl>
							{actions}
						</TableCell>
					)}
				</TableRow>
			);
		}
	;

	return (
		<PaperTable>
			{!showActions && (
				<Colgroup sm md lg xl>
					<col width="50%" />
					<col width="50%" />
				</Colgroup>
			)}
			{showActions && (
				<Colgroup sm md lg xl>
					<col width="35%" />
					<col width="35%" />
					<col width="30%" />
				</Colgroup>
			)}
			<TableHead>
				<TableRow>
					<TableCell>Mandant</TableCell>
					<TableCell sm md lg xl>Zusammenfassung</TableCell>
					{showActions && (
						<TableCell sm md lg xl>Aktionen</TableCell>
					)}
				</TableRow>
			</TableHead>
			<TableBody>
				{billing.clients.length > 1 && row("Alle", billing.services, "primary")}
				{billing.clients.map(c => row(c.name, c.services))}
			</TableBody>
		</PaperTable>
	);
}
