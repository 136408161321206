import { Box, useTheme } from "@mui/material";
import { useEffect } from "react";
import FormElementLabel from "../../../../Components/FormElementLabel";
import FormYesNo from "../../../../Components/FormYesNo";
import { Vehicle } from "../../../../system/Domain";
import { Form } from "../../../../system/useForm";

interface Props {
	vehicle: Vehicle;
	form: Form;
}

const CustomerDeliveryNew = ({ vehicle, form }: Props) => {
	const canDoAssessment = !vehicle.remarketing.assessment?.orderId;
	const doAssessment = form.getValue("doAssessment");
	const theme = useTheme();

	useEffect(() => {
		if (doAssessment && !canDoAssessment) {
			form.setValue("doAssessment", false);
		}
	}, [form, vehicle]);

	return (
		<>
			<FormYesNo label="Fahrzeug zugelassen" name="isRegistered" fullWidth form={form} />
			<FormYesNo
				label={
					<Box mb={0.25}>
						<FormElementLabel text="Gutachtenauftrag erstellen" form={form} name="isDrivable" />
						{!canDoAssessment && (
							<Box color={theme.palette.secondary.light}>Gutachten ist bereits beauftragt</Box>
						)}
					</Box>
				}
				name="doAssessment"
				disabled={!canDoAssessment}
				fullWidth
				form={form}
			/>
			<FormYesNo label="Ald Flex Fahrzeug" name="isPoolfleet" fullWidth form={form} />
			<FormYesNo label="Stornierung" name="isCancellation" fullWidth form={form} />
		</>
	);
};

export default CustomerDeliveryNew;
