import FormTiresAndRims from "../../../../Components/FormTiresAndRims";
import FormYesNo from "../../../../Components/FormYesNo";
import { Form } from "../../../../system/useForm";

export default ({ form } : { form : Form }) => {
	return (
		<>
			<FormTiresAndRims
				name="installedTiresAndRims"
				form={form} />
			<FormYesNo
				name="installedTiresAndRims.spareTire"
				label="Reserverad vorhanden"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormYesNo
				name="installedTiresAndRims.wheelTrimCap"
				label="Radzierblenden vorhanden"
				form={form}
				options={{
					required: true
				}}
			/>

		</>
	);
};
