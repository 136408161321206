import { Popover } from "@mui/material";

export default ({ open, anchorEl, children, onClose }) => {
	return (
		<Popover
			anchorEl={anchorEl}
			open={open}
			onClose={onClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
		>
			{children}
		</Popover>
	);
};
