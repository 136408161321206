import { ChangeEvent, useEffect, useState } from "react";
import { CircularProgress, InputAdornment, TextField, TextFieldProps, Tooltip, useTheme } from "@mui/material";
import { KeyboardReturn, Search } from "@mui/icons-material";

type SearchProps = TextFieldProps & {
	onSearch?: (value: string) => void
	loading?: boolean
	realtime?: boolean
	placeholder?: string
	disableEnter?: boolean,
	help?: string
}

export default ({ onSearch, loading = false, help = null, realtime = false, placeholder = "Suchen...", disableEnter = false, value, ...props }: SearchProps) => {
	const theme = useTheme();
	const [searchValue, setSearchValue] = useState<string>(value as string || "");

	useEffect(() => {
		if (realtime && onSearch) {
			onSearch(searchValue);
		}
	}, [searchValue]);

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchValue(event.target.value);
	};

	return (
		<TextField
			{...props}
			value={searchValue}
			fullWidth
			variant="outlined"
			size="medium"
			onChange={handleChange}
			onKeyUp={e => {
				if (!disableEnter && e.key === "Enter") {
					if (onSearch) {
						onSearch(searchValue);
					}
					e.preventDefault();
				}
			}}
			placeholder={placeholder}
			sx={{
				root: {
					"& input": {
						paddingTop: theme.spacing(1.25),
						paddingBottom: theme.spacing(1.25)
					}
				}
			}}
			InputProps={{
				endAdornment: (
					<>
						{!realtime && (
							<Tooltip title="Drücken Sie die Eingabetaste, um zu suchen.">
								<InputAdornment position="end">
									<KeyboardReturn color="disabled" />
								</InputAdornment>
							</Tooltip>
						)}
						{loading && (
							<CircularProgress size={24} sx={{ marginRight: 2 }} />
						)}
					</>),
				startAdornment: (
					<Tooltip title={help}>
						<InputAdornment position="start">
							<Search color="disabled" />
						</InputAdornment>
					</Tooltip>
				),

			}}
		/>
	);
}
