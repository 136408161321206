import { PropsWithChildren } from "react";
import { Box } from "@mui/material";

type TileActionPropsJustify = {
	alignRight? : never
	justifyContent : string
}

type TileActionPropsAlign = {
	alignRight? : boolean
	justifyContent? : never
}

type TileActionsProps = TileActionPropsJustify | TileActionPropsAlign;
export default (props : PropsWithChildren<TileActionsProps>) => {
	return (
		<Box my={2}>
			{props.alignRight && (
				<Box display="flex" flexGrow={1} justifyContent="flex-end">
					{props.children}
				</Box>
			)}
			{props.justifyContent && (
				<Box display="flex" flexGrow={1} justifyContent={props.justifyContent}>
					{props.children}
				</Box>
			)}
			{!props.alignRight && !props.justifyContent && props.children}
		</Box>
	);
};
