import _ from "lodash";

export interface UseAreaClustersProps {
	areaCodes: string[]
	groupCountInCluster: number
}

export interface AreaCluster {
	firstAreaCode: string
	lastAreaCode: string
	firstAreaCodeIndex: number
	lastAreaCodeIndex: number
}

export interface AreaClusters {
	groupDigits: string[]
	clusters: AreaCluster[]
}

export default ({ areaCodes, groupCountInCluster }: UseAreaClustersProps) => {
	const groupDigits: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
	let areaClusters: Array<AreaCluster> = []

	if (areaCodes) {
		for (let i = 0; i <= groupDigits.length - groupCountInCluster; i += groupCountInCluster) {
			const cluster = {
				firstAreaCode: _.find(areaCodes, v => v.charAt(0) === groupDigits[i]),
				firstAreaCodeIndex: 0,
				lastAreaCode: _.findLast(areaCodes, v => v.charAt(0) === groupDigits[i + groupCountInCluster - 1]),
				lastAreaCodeIndex: 0
			};
			cluster.firstAreaCodeIndex = _.indexOf(areaCodes, cluster.firstAreaCode);
			cluster.lastAreaCodeIndex = _.indexOf(areaCodes, cluster.lastAreaCode);

			areaClusters.push(cluster);
		}
	}

	return { groupDigits: groupDigits, clusters: areaClusters };
}
