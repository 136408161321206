import FormYesNo from "../../../Components/FormYesNo";
import { Form } from "../../../system/useForm";
import { Box, Typography, useTheme } from "@mui/material";
import FormUpload from "../../../Components/FormUpload";
import FormChoices from "Components/FormChoices";
import FormElementLabel from "Components/FormElementLabel";
import QuickFAQ from "../../Customer/CustomerQuickFAQ";
import FormTiresAndRims from "Components/FormTiresAndRims";

interface Props {
	form: Form
	hasAssessment?: boolean
	showExternalProtocolInfo: boolean
}

export default (props: Props) => {
	const theme = useTheme();

	const plateNumbers = +props.form.getValue("plateNumbers");
	const canEditTires = !!props.form.getValue("installedTiresAndRims.tireType");

	return (
		<Box sx={{ display: "flex", gap: 3, flexDirection: "column" }}>
			<FormYesNo
				name="isRegistered"
				label="Fahrzeug zugelassen"
				fullWidth
				form={props.form}
				options={{ required: true }}
			/>
			<FormYesNo
				name="registrationDocument"
				label="ZLB 1 vorhanden"
				fullWidth
				form={props.form}
				options={{ required: true }}
			/>
			{props.form.getValue("registrationDocument") === true && (
				<Box sx={{ display: "flex", gap: 3 }}>
					<FormUpload
						name="registrationDocumentFront"
						label="ZB 1 Vorderseite"
						form={props.form}
						options={{
							required: true
						}}
					/>
					<FormUpload
						name="registrationDocumentBack"
						label="ZB 1 Rückseite"
						form={props.form}
						options={{
							required: true
						}}
					/>
				</Box>)}
			<FormChoices
				name="plateNumbers"
				label="Anzahl Kennzeichen"
				form={props.form}
				choices={{
					"2": "2",
					"1": "1",
					"0": "0"
				}}
				options={{ required: true }}
			/>
			{plateNumbers > 0 && (
				<Box sx={{ display: "flex", gap: 3 }}>
					<FormUpload
						name="plateNumbersFront"
						label="AKZ Vorne"
						form={props.form}
						options={{
							required: true
						}}
					/>
					{plateNumbers === 2 && (
						<FormUpload
							name="plateNumbersBack"
							label="AKZ Hinten"
							form={props.form}
							options={{
								required: true
							}}
						/>
					)}
				</Box>
			)}
			<FormYesNo
				name="doAssessment"
				label={
					<Box mb={0.25}>
						<FormElementLabel text="Gutachtenauftrag erstellen" />
						{props.hasAssessment && (
							<Box color={theme.palette.secondary.light}>Gutachten ist bereits beauftragt</Box>
						)}
					</Box>
				}
				fullWidth
				form={props.form}
				disabled={props.hasAssessment}
				options={{ required: true }}
			/>
			<FormYesNo
				name="isPoolfleet"
				label="ALD Flex Fahrzeug"
				fullWidth
				form={props.form}
				options={{ required: true }}
			/>
			<FormYesNo
				name="isCancellation"
				label={
					<Box mb={0.25}>
						<FormElementLabel
							text="Anlieferung Verkaufstorno"
							form={props.form}
							name="isCancellation"
						/>
						<QuickFAQ faqKey="delivery-cancellation" />
					</Box>
				}
				fullWidth
				form={props.form}
				options={{ required: true }}
			/>
			<Box sx={{ display: "flex", gap: 2.5, flexDirection: "column" }}>
				<FormYesNo
					name="unstickService"
					label={
						<Box mb={0.25}>
							<FormElementLabel
								text="Fahrzeug foliert/ beklebt"
								form={props.form}
								name="unstickService"
							/>
							<QuickFAQ faqKey="unstick-service" />
						</Box>
					}
					fullWidth
					form={props.form}
					options={{ required: true }}
				/>
				{props.form.getValue("unstickService") === true && (
					<FormChoices
						name="unstickServiceType"
						label=""
						form={props.form}
						choices={{
							"PKW": "PKW",
							"NKW": "NKW",
							"Voll": "Voll"
						}}
						options={{ required: true }}
					/>)}
			</Box>
			<FormYesNo
				name="changeTiresService"
				label={
					<Box mb={0.25}>
						<FormElementLabel
							text="Radwechsel"
							form={props.form}
							name="changeTiresService"
						/>
						<QuickFAQ faqKey="change-tires-service" />
					</Box>
				}
				fullWidth
				form={props.form}
				options={{ required: true }}
			/>
			{props.form && canEditTires && (
				<>
					<FormTiresAndRims
						name={"installedTiresAndRims"}
						form={props.form} />
					<FormYesNo
						name="installedTiresAndRims.spareTire"
						label="Reserverad vorhanden"
						form={props.form}
						options={{
							required: true
						}}
					/>
					<FormYesNo
						name="installedTiresAndRims.wheelTrimCap"
						label="Radzierblenden vorhanden"
						form={props.form}
						options={{
							required: true
						}}
					/>
					<FormYesNo
						name="additionalTiresAndRims"
						label="Wurden weitere Reifen und Felgen abgegeben?"
						form={props.form}
						options={{
							required: true
						}}
					/>
					{props.form.getValue("additionalTiresAndRims") && (
						<>
							<FormTiresAndRims
								canHaveNoRims
								name="suppliedTiresAndRims"
								form={props.form}
							/>
							<FormUpload
								name="suppliedTiresAndRims.picture"
								label="Foto"
								form={props.form}
								options={{
									required: true
								}}
							/>
						</>
					)}
				</>
			)}
			{props.showExternalProtocolInfo && (
				<Box>
					<Typography>Ein externes Rücknahmeprotokoll ist bereits vorhanden. Für die weiteren Informationen, bitte das Dokument herunterladen.</Typography>
				</Box>
			)}
		</Box>
	)
}

