import { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';

import { Add, RotateLeft } from '@mui/icons-material';
import { Button, CircularProgress, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from '@mui/material';

import Sidebar from '../../../Components/Sidebar/Sidebar';
import SidebarButton from '../../../Components/Sidebar/SidebarButton';
import SidebarForm from '../../../Components/Sidebar/SidebarForm';
import SidebarGroup from '../../../Components/Sidebar/SidebarGroup';
import Sort, { SortItem } from '../../../Components/Sort';
import { UnregistrationOrdersOverviewPerspective } from '../../../system/Domain';
import { UnregistrationOrdersOverviewQuery } from './UnregistrationOrdersOverview';
import IndeterminateCheckbox from "../../../Components/IndeterminateCheckbox";

interface Props {
	query: UnregistrationOrdersOverviewQuery
	setQuery: Dispatch<SetStateAction<UnregistrationOrdersOverviewQuery>>
	resetQuery: () => UnregistrationOrdersOverviewQuery
	canProcess: boolean
	isProcessing: boolean
	handleProcess: () => Promise<void>
}

const sortItems: SortItem[] = [
	{
		title: "Erstellungsdatum",
		field: "dateCreated"
	}
];

const UnregistrationOrdersOverviewSidebar = (props: Props) => {
	const history = useHistory();

	const setIsTransferred = (newValue: boolean | undefined) => {
		props.setQuery(g => ({
			...g,
			isTransferred: newValue
		}));
	};

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarButton
					color="primary"
					startIcon={<Add />}
					label="Neuer Abmeldeauftrag"
					onClick={() => history.push("/orders/unregistrations/new")}
				/>
				<SidebarButton
					disabled={!props.canProcess || props.isProcessing}
					color="primary"
					onClick={props.handleProcess}
				>
					{props.isProcessing && (
						<CircularProgress sx={{ marginRight: 2.5 }} size={16} />
					)} Beauftragen
				</SidebarButton>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarButton
					color="primary"
					label="Importe"
					onClick={() => history.push("/orders/unregistrations/imports")}
				/>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarForm>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Perspektive</InputLabel>
						<Select
							value={props.query.perspective}
							onChange={(e) => props.setQuery(g => ({
								...g,
								skip: 0,
								perspective: e.target.value as UnregistrationOrdersOverviewPerspective
							}))}
						>
							<MenuItem value="All">Alle</MenuItem>
							<MenuItem value="Processed">Beauftragt</MenuItem>
							<MenuItem value="Unprocessed">Neu</MenuItem>
							<MenuItem value="Cancelled">Storniert</MenuItem>
							<MenuItem value="Completed">Abgeschlossen</MenuItem>
							<MenuItem value="Own">Von mir erstellt</MenuItem>
						</Select>
					</FormControl>
					<Sort
						items={sortItems}
						value={props.query.sort}
						onChange={(sort) => props.setQuery(g => ({
							...g,
							skip: 0,
							sort
						}))}
					/>
					{(props.query.perspective === "Processed" || props.query.perspective === "Cancelled" || props.query.perspective === "Completed") && (
						<FormControl fullWidth>
							<FormControlLabel
								control={<IndeterminateCheckbox value={props.query.isTransferred} set={setIsTransferred} />}
								label="Übertragung durchgeführt"
							/>
						</FormControl>
					)}
				</SidebarForm>
			</SidebarGroup>
			<SidebarGroup>
				<Button
					variant="outlined"
					startIcon={<RotateLeft />}
					fullWidth
					onClick={props.resetQuery}
					color='inherit'
				>
					Filter zurücksetzen
				</Button>
			</SidebarGroup>
		</Sidebar>
	);
};

export default UnregistrationOrdersOverviewSidebar;
