import { useState } from "react";
import { ReturnForm as ReturnFormType, ReturnForm, Vehicle } from "../../system/Domain";
import useAsyncEffect from "../../system/useAsyncEffect";
import axios from "axios";
import extendReturnForm from "../../system/extendReturnForm";
import ReturnFormTable from "../Orders/Transports/ReturnForms/ReturnFormTable";
import Tile from "Components/Tiles/Tile";
import TileContent from "Components/Tiles/TileContent";

interface Props {
	vehicle: Vehicle
}

export default (props: Props) => {
	const [returnForms, setReturnForms] = useState<ReturnForm[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	useAsyncEffect(async () => {
		setReturnForms([]);
		setIsLoading(false);

		if (!props.vehicle) {
			return;
		}

		setIsLoading(true);

		try {
			const response = await axios.post<Array<ReturnFormType>>(`/api/orders/transports/return-forms/search`, {
				vin: props.vehicle.vin
			});

			setReturnForms(response.data.map(r => extendReturnForm(r)));
		} finally {
			setIsLoading(false);
		}
	}, [props.vehicle]);

	return (
		<>
			{props.vehicle && (
				<Tile title="Rücknahmeprotokolle">
					<TileContent>
						<ReturnFormTable
							returnForms={returnForms}
							isLoading={isLoading}
							showHeader={false}
						/>
					</TileContent>
				</Tile>
			)}
		</>
	);
};
