import axios from "axios";
import { useDropzone } from "react-dropzone";
import { useCallback, useState } from "react";
import ThemedDialog from "../../Components/ThemedDialog";
import uploadImage from "../..//cloud-upload.png";
import executeWithProgressDialog from "../../Dialogs/executeWithProgressDialog";
import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";
import {
	alpha,
	Box,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	SxProps,
	Theme,
	Typography,
	useTheme,
} from "@mui/material";

interface Props extends ReactConfirmProps<any> {
	show: boolean;
}

interface Result {
	transportOrdersCreated: number;
}

const UploadFileDialog = ({ url, props }: { url: string; props: Props }) => {
	const theme = useTheme();
	const color = theme.palette.primary.main;

	const styles: Record<string, SxProps<Theme> | undefined> = {
		root: {
			position: "relative",
			border: "2px dashed",
			borderColor: alpha(color, 0.12),
			borderRadius: 4,
			width: "100%",
			height: 150,
			cursor: "pointer",
			color: alpha(color, 0.3),
			"&:hover": {
				borderColor: alpha(color, 0.3),
				color: alpha(color, 0.6),
			},
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			textAlign: "center",
			fontSize: "0.75rem",
			transition: "border 200ms ease-out, color 200ms ease-out",
			outline: "none",
			lineHeight: "1.9em",
			overflow: "hidden",
		},
		error: {
			borderColor: alpha(theme.palette.error.light, 0.75),
			color: theme.palette.error.light,
		},
	};
	const [result, setResult] = useState<Result>();
	const [error, setError] = useState<string>();

	const handleClose = () => {
		props.cancel();
	};

	const onDrop = useCallback(async (files: File[]) => {
		await executeWithProgressDialog(async (progress) => {
			let formData = new FormData();
			formData.append("file", files[0]);

			try {
				const response = await axios.post<Result>(url, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
					onUploadProgress: (event) => {
						const completed = Math.ceil((event.loaded / event.total) * 100);
						progress(completed);
					},
				});

				setResult(response.data);
			} catch (e) {
				setError((await e.response?.data) ?? e.message);
			}
		});
	}, []);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		multiple: false,
		noKeyboard: true,
		noDragEventsBubbling: true,
	});

	return (
		<ThemedDialog open={props.show} maxWidth="sm" fullWidth onClose={handleClose}>
			<DialogTitle>File Upload</DialogTitle>
			<DialogContent>
				{!result && !error && (
					<Box {...getRootProps({ outline: "none" })} sx={styles.root}>
						<input {...getInputProps()} />
						<img src={uploadImage} alt="upload" />
						<Typography gutterBottom>Dateien per Drag and Drop hierher verschieben</Typography>
					</Box>
				)}
				{(result || error) && (
					<Box mx={2}>
						{error && <Box sx={styles.error}>{error}</Box>}
						{result && (
							<Box p={2}>
								<Box>Transportaufträge erstellt: {result.transportOrdersCreated}</Box>
							</Box>
						)}
					</Box>
				)}
			</DialogContent>
			<DialogActions>
				<Button variant="outlined" color="primary" href="" onClick={() => props.proceed({})}>
					Ok
				</Button>
			</DialogActions>
		</ThemedDialog>
	);
};

export default (url: string) => {
	const dialog = confirmable((props) => <UploadFileDialog url={url} props={props} />);
	return createConfirmation(dialog)();
};
