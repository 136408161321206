import moment from "moment";
import {
	Box,
	Chip,
	PropTypes,
	TableBody,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import { Invoice, InvoiceStatus } from "../../system/Domain";
import TextBlock from "../../Components/Layout/TextBlock";
import { useHistory } from "react-router-dom";
import numberFormatter from "../../system/currencyFormatter";
import PaperTable from "../../Components/PaperTable";
import { Colgroup, TableCell } from "../../Components/BreakpointStyledComponents";

type InvoiceTableProps = {
	invoices: Invoice[]
}

export default ({ invoices }: InvoiceTableProps) => {
	const history = useHistory();

	return (
		<PaperTable>
			<Colgroup sm md lg xl>
				<col width="auto" />
				<col width="100%" />
				<col width="auto" />
			</Colgroup>
			<Colgroup xs>
				<col width="100%" />
			</Colgroup>
			<TableHead>
				<TableRow sx={{ display: { xs: "contents", sm: "none" } }}>
					<TableCell>Rechnung</TableCell>
				</TableRow>
				<TableRow sx={{ display: { xs: "none", sm: "contents" } }}>
					<TableCell>Status</TableCell>
					<TableCell>Rechnung</TableCell>
					<TableCell>Gesamtbetrag</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{invoices.map(i => {
					let color: Exclude<PropTypes.Color, 'inherit'> = "default";
					if (i.status === InvoiceStatus.Approved) {
						color = "primary";
					} else if (i.status === InvoiceStatus.Rejected) {
						color = "secondary";
					}

					const invoiceBlock = <TextBlock
						primary={i.company.name}
						secondary={`Rechnungsnummer ${i.invoiceNumber} vom ${moment(i.invoiceDate).format("L")}`}
					/>;
				
					return (
						<TableRow
							key={i.id}
							hover
							onClick={() => history.push(`/invoices/${i.id}`)}
						>
							<TableCell xs>
								<Box>
									<Chip
										size="medium"
										color={color}
										label={i.status}
									/>
								</Box>
								<Box mt={2}>
									{invoiceBlock}
								</Box>
							</TableCell>
							<TableCell sm md lg xl>
								<Chip
									size="medium"
									color={color}
									label={i.status}
								/>
							</TableCell>
							<TableCell sm md lg xl>
								{invoiceBlock}
							</TableCell>
							<TableCell sm md lg xl sx={{ textAlign: "center" }}>
								<Typography>
									{numberFormatter.format(i.netAmount.valueOf())}
								</Typography>
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		</PaperTable>
	)
}
