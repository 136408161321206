import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { Add } from '@mui/icons-material';
import Sidebar from '../../Components/Sidebar/Sidebar';
import SidebarButton from '../../Components/Sidebar/SidebarButton';
import SidebarGroup from '../../Components/Sidebar/SidebarGroup';
import executeWithProgressDialog from '../../Dialogs/executeWithProgressDialog';
import getFilesForCompany from '../../Dialogs/getFilesForCompany';
import { Invoice } from '../../system/Domain';

interface Props {
	invoices: Invoice[]
	setInvoices: Dispatch<SetStateAction<Invoice[]>>
}

const supportedCompanies = ["Car24", "Concept", "Carkontor", "Hartmann", "Hess"];

export default ({ invoices, setInvoices }: Props) => {
	const handleNewInvoice = async () => {
		const result = await getFilesForCompany("Rechnungsdatei", supportedCompanies);

		if (result.ok) {
			await executeWithProgressDialog(async progress => {
				let formData = new FormData();

				formData.append("file", result.files[0]);

				const invoicesUrl = result.company ? `/api/invoices?company=${result.company}` : '/api/invoices';

				const response = await axios.post(invoicesUrl, formData, {
						headers: {
							'Content-Type': 'multipart/form-data'
						},
						onUploadProgress: evt => {
							const completed = Math.ceil((evt.loaded / evt.total) * 100);
							progress(completed);
						}
					}
				);

				setInvoices([
					...response.data,
					...invoices
				]);
			});
		}
	};

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarButton
					color="primary"
					onClick={handleNewInvoice}
					startIcon={<Add />}
					label="Neue Rechnung"
				/>
			</SidebarGroup>
		</Sidebar>
	);
}
