import axios from "axios";
import Loading from "Components/Loading";
import LoginError from "Pages/Login/LoginError";
import queryString from "query-string";
import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useAsync } from "react-use";
import auth0 from "system/auth0";
import { User } from "system/Domain";
import useUser from "./system/useUser";

export default () => {
	const [user, setUser] = useUser();
	const [apiError, setApiError] = useState<string | null>(null);
	const location = useLocation();
	const history = useHistory();

	const authenticationEvaluation = useAsync(async () => {
		if (user) {
			return true;
		}

		var client = await auth0.createClient();

		const handleError = async (e: any) => {
			if (e.isAxiosError && e.response.status === 403) {
				setApiError("FORBIDDEN");
			} else {
				setApiError(e.message);
			}
		};

		const loginWithAuth0 = async () => {
			const auth0_accessToken = await client.getTokenSilently();
			const auth0_user = await client.getUser();
			try {
				const response = await axios.post<User>("/api/login-with-auth0", { accessToken: auth0_accessToken });
				setUser({
					...response.data,
					pictureUrl: auth0_user?.picture,
				});

				return true;
			} catch (e) {
				await handleError(e);
			}
		};

		const { search } = location;
		const query = queryString.parse(search);

		if (query.access_token) {
			try {
				const { data } = await axios.get("/api/users/whoami", {
					headers: {
						Authorization: `Bearer ${query.access_token}`,
					},
				});

				setUser({
					...data,
					loginToken: {
						token: query.access_token,
					},
				});

				history.replace({
					pathname: location.pathname,
					search: queryString.stringify({
						...query,
						access_token: undefined,
					}),
				});

				return true;
			} catch (e) {
				if (e.isAxiosError && e.response.status === 401 && e.response.headers.authenticationhandler) {
					setApiError(e.response.headers.authenticationhandler);
				} else {
					setApiError(e.message);
				}
				return false;
			}
		}

		const isAuthenticated = await client.isAuthenticated();
		if (isAuthenticated) {
			try {
				await loginWithAuth0();
				return true;
			} catch (e) {
				handleError(e);
			}
		} else {
			const { search } = location;

			if (search && search.includes("code=") && search.includes("state=")) {
				await client.handleRedirectCallback();

				try {
					await loginWithAuth0();
					return true;
				} catch (e) {
					handleError(e);
				}
			} else {
				try {
					await client.loginWithRedirect({
						redirect_uri: window.location.origin,
						scopes: ["email"],
					});

					// await forever so that the evaluation keeps in loading state during redirects
					await new Promise(() => {});
				} catch (error) {
					console.error(error);
				}
			}
		}

		return false;
	}, [user]);

	if (authenticationEvaluation.loading) {
		return <Loading />;
	}

	if (!authenticationEvaluation.value) {
		return <LoginError error={apiError || "Unbekannt"} />;
	}

	return null;
};
