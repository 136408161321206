import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { SaleQuery } from "./Domain";

export default (sessionKey: string, defaultQuery: SaleQuery): [SaleQuery, Dispatch<SetStateAction<SaleQuery>>, () => SaleQuery] => {
	const jsonQuery = sessionStorage[sessionKey];

	let sessionQuery = defaultQuery;
	if (jsonQuery) {
		sessionQuery = {
			...sessionQuery,
			...JSON.parse(jsonQuery),
		};
	}

	const [query, setQuery] = useState<SaleQuery>(sessionQuery);

	useEffect(() => {
		sessionStorage[sessionKey] = JSON.stringify({
			...query,
		});
	}, [query]);

	const resetQuery = useCallback(() => {
		setQuery(defaultQuery);
		return defaultQuery;
	}, [defaultQuery]);

	return [query, setQuery, resetQuery];
};
