import { useState } from "react";
import Layout from "../../../Components/Layout/Layout";
import { Box, Button, Grid } from "@mui/material";
import { useHistory, useParams } from "react-router";
import { useAsync } from "react-use";
import { Sale } from "../../../system/Domain";
import useForm from "../../../system/useForm";
import axios from "axios";
import FormDateTimePicker from "../../../Components/FormDateTimePicker";
import FormTextField from "../../../Components/FormTextField";
import Actions from "../../../Components/Actions";
import TextBlock from "../../../Components/Layout/TextBlock";
import InlineList from "../../../Components/InlineList";
import VinDisplay from "../../../Components/VinDisplay";
import { Plate } from "../../../Components/Plate";
import Tile from "../../../Components/Tiles/Tile";
import TileContent from "../../../Components/Tiles/TileContent";
import FormText from "../../../Components/FormText";

interface ManualSale {
	customerId: string
	salesAmount : number
	invoiceNumber : string
	invoiceDate : string
}

const ManualSalesmanualSalePage = () => {
	const { id } = useParams<{ id : string }>();
	const [manualSale, setManualSale] = useState<ManualSale | null>(null);
	const [, setError] = useState<string | null>(null);
	const history = useHistory();

	const form = useForm({
		values: manualSale,
		setValues: setManualSale,
		deltaValues: null
	});

	const { loading } = useAsync(async () => {
		if (!id) {
			setManualSale(null);
			return;
		}

		setError(null);
		try {
			const { data } = await axios.post<ManualSale>(`/api/sales/${id}/prepare-manual-order`);
			setManualSale(data);
		} catch (error) {
			if (error.isAxiosError) {
				setError(error.response.data);
			} else {
				throw error;
			}
		}
	}, [id]);

	const { value: sales } = useAsync(async () => {
		if (!id) {
			return null;
		}
		const { data } = await axios.get<Sale>(`/api/sales/${id}`);
		return data;
	}, [id]);

	const handleSave = async () => {
		try {
			const { data: newSales } = await axios.post<Sale>(`/api/sales/${id}/manual-order`, manualSale);
			const newOrder = newSales.orders.find(o => o.status === "Invoiced");
			if (newOrder) {
				history.push(`/sales/${id}/orders/${newOrder.id}`);
			}
		} catch (error) {
			if (error.isAxiosError) {
				setError(error.response.data);
			} else {
				throw error;
			}
		}
	};

	return (
		<Layout
			title="Manuellen Verkauf anlegen"
			loading={loading}
		>
			{manualSale && sales && (
				<>
					<Box mb={4}>
						<TextBlock
							primary={<Plate plateNumber={sales.plateNumber}/>}
							secondary={
								<InlineList>
									<VinDisplay vin={sales.vin}/>
									{sales.vehicle.businessLine}
								</InlineList>
							}
						/>
					</Box>
					<Box my={2}>
						<Box my={2}>
							<Grid container>
								<Tile>
									<TileContent>
										<Grid container spacing={4}>
											<Grid item xs={12}>
												<FormTextField
													label="Adressnummer des Käufers in Leasman"
													name="customerId"
													fullWidth
													form={form}
													type="text"
													required
												/>
											</Grid>
											<Grid item xs={12}>
												<FormTextField
													label="Rechnungsnummer der manuellen Rechnung"
													name="invoiceNumber"
													fullWidth
													form={form}
													type="text"
													required
												/>
											</Grid>
											<Grid item xs={12}>
												<FormDateTimePicker
													label="Datum der Rechnung (auch Verkaufsdatum)"
													required
													fullWidth
													name="invoiceDate"
													form={form}
													variant="date"
												/>
											</Grid>
											<Grid item>
												<FormText
													label="Fahrzeugpreis (brutto)"
													name="salesAmount"
													fullWidth
													form={form}
													type="currency"
												/>
											</Grid>
										</Grid>
									</TileContent>
								</Tile>
							</Grid>
						</Box>
					</Box>
					<Actions>
						<Button
							variant="contained"
							color="primary"
							onClick={handleSave}
						>
							Speichern
						</Button>
					</Actions>
				</>
			)}

		</Layout>
	);
};

export default ManualSalesmanualSalePage;
