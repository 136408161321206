import axios from "axios";
import _ from "lodash";
import moment from "moment";
import { useState } from "react";
import { useHistory } from "react-router";

import { Check, Close, ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Divider } from "@mui/material";

import TextBlock from "../../Components/Layout/TextBlock";
import ListItemChip from "../../Components/ListItemChip";
import ListItemChips from "../../Components/ListItemChips";
import Tile from "../../Components/Tiles/Tile";
import TileContent from "../../Components/Tiles/TileContent";
import { ReturnForm, TransportOrder, Vehicle } from "../../system/Domain";
import extendReturnForm from "../../system/extendReturnForm";
import formatDate from "../../system/formatDate";
import formatNumber from "../../system/formatNumber";
import useAxiosEffect from "../../system/useAxiosEffect";
import useCompoundPlaces from "../../system/useCompoundPlaces";
import TextGroup from "../VehicleSales/TextGroup";
import TextLine from "../VehicleSales/TextLine";
import RemarketingVehicleServices from "./RemarketingVehicleServices";
import RemarketingReturnFormDamages from "./RemarketingReturnFormDamages";

interface Props {
	vehicle: Vehicle;
	onReturnFormLoaded?: (rf: ReturnForm) => void;
}

const RemarketingGeneralInformationTile = ({ vehicle, onReturnFormLoaded }: Props) => {
	const history = useHistory();

	const [transportOrder, setTransportOrder] = useState<TransportOrder>();

	const hasPictureSet = vehicle.pictureSets.length > 0;

	useAxiosEffect(
		async (config) => {
			if (!vehicle.orderId) return;

			const response = await axios.get<TransportOrder[]>("/api/orders/transports", {
				...config,
				params: {
					orderId: vehicle.orderId,
				},
			});

			if (response.data.length === 1) {
				setTransportOrder(response.data[0]);
			}
		},
		[vehicle],
	);

	const [returnForm, setReturnForm] = useState<ReturnForm>();
	const [damagesExpanded, setDamagesExpanded] = useState(false);
	const [servicesExpanded, setServicesExpanded] = useState(!vehicle.remarketing?.previousOwners);
	const [serviceCount, setServiceCount] = useState<number>();

	useAxiosEffect(
		async (config) => {
			if (!vehicle) {
				return;
			}

			const { data: returnForms } = await axios.post<ReturnForm[]>(
				`/api/orders/transports/return-forms/search`,
				{
					vin: vehicle.vin,
				},
				config,
			);

			const candidates = _.orderBy(
				returnForms.map(extendReturnForm).filter((f) => !!f.predecessorId && f.isSigned()),
				(f) => moment(f.transfer?.date),
				"desc",
			);

			if (candidates.length > 0) {
				setReturnForm(candidates[0]);
				if (onReturnFormLoaded) {
					onReturnFormLoaded(candidates[0]);
				}
			}
		},
		[vehicle?.id],
	);

	const daysDelayed =
		transportOrder?.returnDate &&
		moment(vehicle.history.currentStockDateRange?.to || undefined).diff(moment(transportOrder.returnDate), "d") + 1;

	const [places] = useCompoundPlaces();
	const place = places && places.find((f) => f.id === vehicle.placeId)?.name;

	const withoutTransport = transportOrder?.assignedTo?.name === "Eigenanlieferung";
	const hasWindshieldDamage = !!returnForm?.damagesExterior?.find((d) => d.component === "Windshield");
	const additionalTires = !!returnForm?.additionalTiresAndRims;
	const intDamageCount = returnForm?.damagesInterior?.length ?? 0;
	const extDamageCount = returnForm?.damagesExterior?.length ?? 0;
	const allDamageCount = intDamageCount + extDamageCount;

	const expandDamages = () => {
		if (!damagesExpanded && servicesExpanded) {
			setServicesExpanded(false);
		}

		setDamagesExpanded((de) => !de);
	};

	const expandServices = () => {
		if (!servicesExpanded && damagesExpanded) {
			setDamagesExpanded(false);
		}

		setServicesExpanded((se) => !se);
	};

	return (
		<Tile title="Platzlogistik">
			<TileContent>
				{!transportOrder && <TextBlock flat primary="Transport nicht beauftragt" />}
				{transportOrder && (
					<>
						<TextGroup
							title={
								withoutTransport
									? transportOrder.assignedTo?.name
									: `Anlieferung durch ${transportOrder.assignedTo?.name}`
							}
						>
							{!withoutTransport && (
								<TextLine
									label="Abholung"
									value={formatDate(transportOrder.plannedPickupDate || transportOrder.pickupDate)}
								/>
							)}
							<TextLine label="Anlieferung" value={formatDate(transportOrder.returnDate)} />
						</TextGroup>
						<TextGroup title="Übergabeprotokoll">
							<TextLine
								label="Frontscheibe"
								value={hasWindshieldDamage ? "Beschädigt" : "Nicht beschädigt"}
								color={hasWindshieldDamage ? "warning" : "info"}
							/>
							<TextLine label="Weitere Reifen und Felgen" value={additionalTires ? "Ja" : "Nein"} />
						</TextGroup>
						<Box mt={1}>
							<ListItemChips>
								<ListItemChip
									label="Fotosatz"
									icon={hasPictureSet ? <Check /> : <Close />}
									color={hasPictureSet ? "success" : "error"}
									onClick={() => history.push(`/vehicles/${vehicle.id}/picture-sets`)}
								/>
								{daysDelayed && (
									<ListItemChip
										label={`${formatNumber(daysDelayed)} Standtage`}
										color={daysDelayed > 3 ? "warning" : "info"}
										onClick={() => history.push(`/orders/transports/${transportOrder.id}`)}
									/>
								)}
								<ListItemChip
									label={`${vehicle.remarketing.status} / ${place}`}
									onClick={() => history.push(`/vehicles/${vehicle.id}`)}
								/>
								<ListItemChip
									label={
										(allDamageCount > 0 ? `${extDamageCount} + ${intDamageCount}` : "0") +
										(allDamageCount === 1 ? " Schaden" : " Schäden")
									}
									icon={damagesExpanded ? <ExpandLess /> : <ExpandMore />}
									active={damagesExpanded}
									onClick={expandDamages}
								/>
								<ListItemChip
									label={`${serviceCount ?? "?"} ${serviceCount === 1 ? "Tätigkeit" : "Tätigkeiten"}`}
									icon={servicesExpanded ? <ExpandLess /> : <ExpandMore />}
									active={servicesExpanded}
									onClick={expandServices}
								/>
							</ListItemChips>
						</Box>
						{damagesExpanded && (
							<Box mt={2}>
								<Box mt={1}>
									<Divider />
								</Box>
								<RemarketingReturnFormDamages returnForm={returnForm} />
							</Box>
						)}
						{servicesExpanded && (
							<Box mt={2}>
								<Box mt={1}>
									<Divider />
								</Box>
								<RemarketingVehicleServices vehicle={vehicle} onLoaded={(sc) => setServiceCount(sc)} />
							</Box>
						)}
					</>
				)}
			</TileContent>
		</Tile>
	);
};

export default RemarketingGeneralInformationTile;
