import { ReactComponent as Postman } from "./postman.svg";

const PostmanIcon = () => {
	return (
		<Postman
			width={24}
			color="white"
			fill="#d4d7dc"
			className="MuiSvgIcon-root" />
	)
}

export default PostmanIcon;
