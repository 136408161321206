import _ from "lodash";
import axios from "axios";
import { useAsync } from "react-use";
import Layout from "../../Components/Layout/Layout";
import { AversiInsuranceCompany } from "./AversiSessionsPage";
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";


const AversiInsuranceCompaniesPage = () => {
	const { value: companies, loading } = useAsync(async () => {
		const { data: companies } = await axios.get<AversiInsuranceCompany>(`/api/admin/aversi/insurance-companies`);
		return companies;
	}, []);

	const values = companies || [];

	const groups = _.groupBy(values, "group");

	const formatPercent = (value: number) => Number(value).toLocaleString(undefined, {
		style: 'percent',
		minimumFractionDigits: 2
	});

	return (
		<Layout loading={loading} title="Aversi Versicherer">
			<Table size="small">
				<TableHead>
					<TableRow>
						<TableCell>Id</TableCell>
						<TableCell>Name</TableCell>
						<TableCell align="right">Provision</TableCell>
						<TableCell align="right">Provision Carpool</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{Object.keys(groups).map(k => {
						const items = groups[k] as AversiInsuranceCompany[];

						return (
							<>
								<TableRow sx={{
									"& td": {
										borderBottom: "none"
									}
								}}>
									<TableCell colSpan={4}>
										<Box fontWeight={700} my={2}>
											<Typography variant="h6">{k}</Typography>
										</Box>

									</TableCell>
								</TableRow>
								{items.map(c => {

									return (
										<TableRow sx={{
											"& td": {
												borderBottom: "none"
											}
										}}>
											<TableCell>{c.id}</TableCell>
											<TableCell>{c.name}</TableCell>
											<TableCell align="right">{formatPercent(c.commission)}</TableCell>
											<TableCell align="right">{formatPercent(c.commissionCarPool)}</TableCell>
										</TableRow>
									)
								})}
							</>
						)
					})}
				</TableBody>
			</Table>

		</Layout>
	)
};

export default AversiInsuranceCompaniesPage;
