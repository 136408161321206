import axios from 'axios';
import { useHistory } from 'react-router';

import { Done, FormatListNumbered } from '@mui/icons-material';

import Sidebar from '../../Components/Sidebar/Sidebar';
import SidebarButton from '../../Components/Sidebar/SidebarButton';
import SidebarGroup from '../../Components/Sidebar/SidebarGroup';
import { PricingRequest } from '../../system/Domain';
import useRunAction from '../../system/useRunAction';

interface Props {
	request: Partial<PricingRequest>
}

export default ({ request }: Props) => {
	const [actionRunning, runAction] = useRunAction();
	const history = useHistory();

	const createRequest = () => runAction(async () => {
		const { data: newRequest } = await axios.post<PricingRequest>(`/api/pricing-requests`, request);
		history.push(`/sales/pricing-requests/${newRequest.id}`);
	});

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarButton
					startIcon={<Done />}
					disabled={!request.contractId || actionRunning}
					onClick={createRequest}
				>
					Anfrage erstellen
				</SidebarButton>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarButton
					startIcon={<FormatListNumbered />}
					label="Kaufpreisanfragen"
					onClick={() => history.push(`/sales/pricing-requests`)}
				/>
			</SidebarGroup>
		</Sidebar>
	);
}
