import { Delete } from "@mui/icons-material";
import { Box, Button, useTheme } from "@mui/material";
import { RepairOrder } from "system/Domain";
import useAction from "system/useAction";
import { GeneralRepairOrderAction } from "./GeneralRepairOrderListItem";

export interface Props {
	order: RepairOrder
	generalRepairOrderAction: GeneralRepairOrderAction
	onRepairOrderDelete: (order: RepairOrder, action: GeneralRepairOrderAction) => void
}

export default ({ order, onRepairOrderDelete, generalRepairOrderAction }: Props) => {
	const theme = useTheme();
	const [deleteRepairOrder, isDeletingRepairOrder] = useAction(async () => {
		onRepairOrderDelete(order, generalRepairOrderAction);
	});

	const buttonText = generalRepairOrderAction === GeneralRepairOrderAction.Delete ? "Reparaturauftrag löschen" : "Reparaturauftrag stornieren";

	return (
		<Box>
			<Box mt={3} mb={1} color={generalRepairOrderAction === GeneralRepairOrderAction.Cancel ? theme.palette.warning.light : theme.palette.error.light}>
				<Button
					color="inherit"
					variant="text"
					onClick={deleteRepairOrder}
					disabled={isDeletingRepairOrder}
					startIcon={<Delete />}
				>
					{buttonText}
				</Button>
			</Box>
		</Box>
	);
}
