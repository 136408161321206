import MenuItem from 'Components/Layout/MenuItem';
import {
	AccountTree, Assignment, AssignmentTurnedIn, Explore, History, Money,
	NoCrash,
	PhotoLibrary
} from '@mui/icons-material';
import { UserRole, Vehicle } from '../../system/Domain';

export default ({ vehicle }: { vehicle: Vehicle }) => {
	return (
		<>
			<MenuItem
				icon={<Explore />}
				name="Übersicht"
				path={`/vehicles/${vehicle.id}`}
				matchProps={{
					exact: true
				}}
				variant="secondary"
			/>
			<MenuItem
				icon={<History />}
				name="Verlauf"
				path={`/vehicles/${vehicle.id}/history`}
				variant="secondary"
			/>
			<MenuItem
				icon={<PhotoLibrary />}
				name="Fotos"
				path={`/vehicles/${vehicle.id}/picture-sets`}
				variant="secondary"
			/>
			<MenuItem
				icon={<Assignment />}
				name="Aufträge"
				path={`/vehicles/${vehicle.id}/orders`}
				variant="secondary"
			/>
			<MenuItem
				icon={<AssignmentTurnedIn />}
				name="Tätigkeiten"
				path={`/vehicles/${vehicle.id}/services`}
				variant="secondary"
			/>
			<MenuItem
				roles={[UserRole.LotManager, UserRole.AldUser]}
				icon={<NoCrash />}
				name="Gutachten"
				path={`/vehicles/${vehicle.id}/assessments/latest`}
				variant="secondary"
			/>
			<MenuItem
				roles={[UserRole.SalesUser]}
				icon={<Money />}
				name="Verkauf"
				path={`/vehicles/${vehicle.id}/remarketing`}
				variant="secondary"
			/>
			<MenuItem
				roles={[UserRole.LotManager, UserRole.AldUser]}
				icon={<AccountTree />}
				name="Dokumente"
				path={`/vehicles/${vehicle.id}/documents`}
				variant="secondary"
			/>
		</>
	);
}
