import createAuth0Client from "@auth0/auth0-spa-js";

const client = createAuth0Client({
	domain: "ald-germany-logistics.eu.auth0.com",
	client_id: "0NgmTLYEs8dk97Em1UNX4Etwlg6xwNOW",
	audience: "https://ald-germany-logistics.eu.auth0.com/api/v2/",
	redirect_uri: window.location.origin,
});

export default {
	createClient() {
		return client;
	},
};
