import { Image } from '@mui/icons-material';
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';

import { VehicleAssessmentAttachment } from '../../../system/Domain';

interface Props {
	onSelect?: (attachment: VehicleAssessmentAttachment) => void
	attachments: VehicleAssessmentAttachment[]
}

const AssessmentAttachmentList = ({ attachments, onSelect }: Props) => (
	<List>
		{attachments.map(a => {
			const handleClick = () => {
				onSelect && onSelect(a);
			};

			return (
				<ListItem
					key={a.storageFile.hash}
					button
					onClick={handleClick}
				>
					<ListItemAvatar>
						<Avatar>
							<Image />
						</Avatar>
					</ListItemAvatar>
					<ListItemText
						primary={a.type}
						secondary={a.storageFile.filename}
					/>
				</ListItem>
			)
		})}
	</List>
);

export default AssessmentAttachmentList;
