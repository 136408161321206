import { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import FormSelect from "Components/FormSelect";
import FormDateTimePicker from "../../../Components/FormDateTimePicker";
import FormTextField from "../../../Components/FormTextField";
import FormYesNo from "../../../Components/FormYesNo";
import { Form } from "../../../system/useForm";
import useUser from "../../../system/useUser";
import DeliveryRadioChoice from "./DeliveryRadioChoice";
import { VehicleAddresses, DeliveryType, UserRole, neussContactInfo, Address, ContactInformation, Compound } from "../../../system/Domain";
import { addressGetter, addressSetter, contactGetter, contactSetter, setContactFromCompound } from "./ReturnOrderUtils";
import { ReturnOrderFormType } from "./ReturnOrdersForm";

interface Props {
	form: Form;
	returnOrder: ReturnOrderFormType;
	addresses: VehicleAddresses;
	compounds: Compound[];
}

const StepDelivery = ({ form, addresses, returnOrder, compounds }: Props) => {
	const [, , hasRole] = useUser();
	const [lastDeliveryType, setLastDeliveryType] = useState<string>();
	const [customEntry, setCustomEntry] = useState<{address: Address, contact: ContactInformation}>();

	const deliveryType = form.getValue("delivery.deliveryType") ?? "";
	const isCustomerDelivery = deliveryType === DeliveryType.CustomerDelivery;
	const isUsedCars = deliveryType === DeliveryType.UsedCars;
	const canCreateAsCrossTransport =
		hasRole(UserRole.AldManager) &&
		(deliveryType === DeliveryType.CustomEntry || deliveryType === DeliveryType.Neuss);

	const canEdit = deliveryType === DeliveryType.CustomEntry;
	const canEditContactInfo = deliveryType === DeliveryType.CustomEntry;

	useEffect(() => {
		const compound =
			!!returnOrder.delivery?.compound &&
			!!compounds &&
			compounds.find((c) => c.name === returnOrder.delivery.compound.name);

		if (lastDeliveryType === DeliveryType.CustomEntry) {
			setCustomEntry({ address: addressGetter("delivery.address", form), contact: contactGetter("delivery.contact", form) })
		}

		if (!!lastDeliveryType) {
			form.setValue("delivery.contact", null);
		}

		switch (deliveryType) {
			case DeliveryType.CustomerDelivery:
				addressSetter("delivery.address", form, addresses.carParkAddress);
				form.setValue("delivery.compound", null);
				break;

			case DeliveryType.RebuyerAddress:
				addressSetter("delivery.address", form, addresses.rkvAddress);
				break;

			case DeliveryType.UsedCars:
				if (!compound) {
					form.setValue("delivery.address", null);
					form.setValue("delivery.contact", null);
					if (!returnOrder.delivery.compound && !!compounds) {
						const defaultCompound = compounds?.find((c) => c.isDefault);
						if (!!defaultCompound) {
							form.setValue("delivery.compound.name", defaultCompound.name);
						}
					}
				} else {
					addressSetter("delivery.address", form, compound.address);
					setContactFromCompound("delivery.contact", form, compound);
				}
				break;
			
			case DeliveryType.Neuss:
				addressSetter("delivery.address", form, addresses.neussAddress);
				contactSetter("delivery.contact", form, neussContactInfo);
				form.setValue("delivery.compound", null);
				break;

			case DeliveryType.CustomEntry:
				addressSetter("delivery.address", form, customEntry?.address);
				contactSetter("delivery.contact", form, customEntry?.contact);
				form.setValue("delivery.compound", null);
				break;

			default:
				form.setValue("delivery.address", null);
				form.setValue("delivery.contact", null);
				form.setValue("delivery.compound", null);
				break;
		}

		setLastDeliveryType(deliveryType);

	}, [compounds, returnOrder.delivery?.compound?.name, deliveryType]);

	return (
		<>
			<Typography variant="h6" gutterBottom sx={{ mt: 2, mb: 1 }}>
				{isCustomerDelivery ? "Eigenanlieferung Dorfmark" : "Anlieferung an"}
			</Typography>
			{!isCustomerDelivery && (
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<DeliveryRadioChoice
							name="delivery.deliveryType"
							form={form}
							aldAddressData={addresses}
							required
						/>
					</Grid>
				</Grid>
			)}
			{returnOrder?.delivery?.deliveryType === DeliveryType.UsedCars && compounds && (
				<Grid container spacing={3}>
					<Grid item xs={12} md={7} lg={5}>
						<Grid container spacing={3}>
							<FormSelect
								fullWidth={false}
								form={form}
								name="delivery.compound.name"
								label="Standort"
								choices={compounds
									.map((c) => c.name)
									.reduce((acc, curr) => ((acc[curr] = curr), acc), {})}
							/>
						</Grid>
					</Grid>
				</Grid>
			)}
			{deliveryType && (
				<>
					{canCreateAsCrossTransport && (
						<Box mt={2}>
							<FormYesNo
								label="Quertransport"
								name="delivery.isCrossTransport"
								form={form}
								options={{ required: false }}
							/>
						</Box>
					)}
					<Typography variant="h6" gutterBottom sx={{ mt: 8, mb: 2 }}>
						Addresse
					</Typography>
					<Grid container spacing={3}>
						<Grid item xs={12} md={7} lg={5}>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<FormTextField
										form={form}
										fullWidth
										name="delivery.address.name"
										label="Name"
										disabled={!canEdit}
										required
									/>
								</Grid>
								<Grid item xs={12}>
									<FormTextField
										form={form}
										fullWidth
										name="delivery.address.name1"
										label="Name"
										disabled={!canEdit}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<FormTextField
										form={form}
										fullWidth
										name="delivery.address.street"
										label="Straße und Hausnummer"
										disabled={!canEdit}
										required
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<FormTextField
										form={form}
										fullWidth
										name="delivery.address.additionalInformation"
										label="Adresszusatz"
										disabled={!canEdit}
										maxLength={30}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<FormTextField
										form={form}
										required
										name="delivery.address.zipCode"
										maxLength={5}
										label="PLZ"
										disabled={!canEdit}
										fullWidth
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<FormTextField
										form={form}
										required
										name="delivery.address.city"
										label="Stadt"
										disabled={!canEdit}
										fullWidth
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<FormTextField
										form={form}
										required
										name="delivery.address.country"
										label="Land"
										fullWidth
										disabled={!canEdit}
										defaultValue="Deutschland"
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					{!isUsedCars && !isCustomerDelivery && (
						<>
							<Typography variant="h6" gutterBottom sx={{ marginTop: 8 }}>
								Ansprechpartner
							</Typography>
							<Grid container spacing={3}>
								<Grid item xs={12} md={7} lg={5}>
									<Grid container spacing={3}>
										<Grid item xs={12} sm={6}>
											<FormTextField
												form={form}
												required
												name="delivery.contact.firstName"
												label="Vorname"
												fullWidth
												disabled={!canEditContactInfo}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<FormTextField
												form={form}
												required
												name="delivery.contact.lastName"
												label="Nachname"
												fullWidth
												disabled={!canEditContactInfo}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<FormTextField
												form={form}
												required
												name="delivery.contact.email"
												label="E-Mail"
												fullWidth
												email
												disabled={!canEditContactInfo}
											/>
										</Grid>
										<Grid item xs={12} sm={6}>
											<FormTextField
												required
												form={form}
												name="delivery.contact.telephone"
												label="Telefon"
												fullWidth
												disabled={!canEditContactInfo}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</>
					)}
					{!isUsedCars && (
						<>
							<Typography variant="h6" gutterBottom sx={{ mt: 8 }}>
								Wunschtermin
							</Typography>
							<Grid container spacing={3}>
								<Grid item xs={12} md={7} lg={5}>
									<Grid container spacing={3}>
										<Grid item xs={12} md={6}>
											<FormDateTimePicker
												name="delivery.appointment.notBefore"
												form={form}
												label={isCustomerDelivery ? "am" : "ab dem"}
												margin="normal"
												variant="date"
												format="DD.MM.YYYY"
												disablePast
												required={isCustomerDelivery}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={3}>
											<FormDateTimePicker
												name="delivery.appointment.from"
												form={form}
												label="Zeit von"
												variant="time"
												ampm={false}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={3}>
											<FormDateTimePicker
												name="delivery.appointment.to"
												form={form}
												label="bis"
												variant="time"
												ampm={false}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</>
					)}
					<Typography variant="h6" gutterBottom sx={{ mt: 8 }}>
						Zusatzinformationen
					</Typography>
					<Grid container spacing={3}>
						<Grid item xs={12} md={7} lg={5}>
							<FormTextField form={form} name="delivery.comment" label="Bemerkung" fullWidth />
						</Grid>
					</Grid>
				</>
			)}
		</>
	);
};

export default StepDelivery;
