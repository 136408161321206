import moment from 'moment';
import { Dispatch, FC, SetStateAction } from "react";
import { useHistory } from 'react-router-dom';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers';
import Sidebar from '../../../Components/Sidebar/Sidebar';
import SidebarButton from '../../../Components/Sidebar/SidebarButton';
import SidebarForm from '../../../Components/Sidebar/SidebarForm';
import SidebarGroup from '../../../Components/Sidebar/SidebarGroup';
import { VehicleQuery } from "../../../system/useVehicleQuery";

interface DocumentArrivalsSidebarProps {
	dateUntil?: string;
	setDateUntil: Dispatch<SetStateAction<string | undefined>>;
	refresh: () => void;
	query: VehicleQuery;
	setQuery: Dispatch<SetStateAction<VehicleQuery>>;
	resetQuery: () => VehicleQuery;
}

const DocumentArrivalsSidebar: FC<DocumentArrivalsSidebarProps> = ({ query, setQuery, dateUntil, setDateUntil, refresh
}) => {
	const history = useHistory();

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarButton
					label="Dashboard"
					onClick={() => history.push("/vehicles/registration-documents/dashboard")}
				/>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarForm>
					<FormControl fullWidth>
						<DatePicker
							// disableToolbar
							// variant="inline"
							format="DD.MM.YYYY"
							// margin="none"
							label="Angefragt vor dem"
							slotProps={{ textField: { variant: 'standard' } }}
							value={moment(dateUntil)}
							onChange={(d : any) => setDateUntil(q => (
								(d || moment()).startOf("d").toISOString(true)
							))}
						/>
					</FormControl>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Mandant</InputLabel>
						<Select
							value={query.businessLine}
							onChange={(e) =>
								setQuery((q) => ({
									...q,
									businessLine: e.target.value as string,
								}))
							}
						>
							<MenuItem value="-">Alle</MenuItem>
							<MenuItem value="BusinessPartner">BusinessPartner</MenuItem>
							<MenuItem value="Flotte">Flotte</MenuItem>
							<MenuItem value="KOOP">KOOP</MenuItem>
							<MenuItem value="FordLease">FordLease</MenuItem>
							<MenuItem value="Leaseplan">Leaseplan</MenuItem>
							<MenuItem value="Renault">Renault</MenuItem>
							<MenuItem value="Sonderflotte">Sonderflotte</MenuItem>
							<MenuItem value="TradeIn">TradeIn</MenuItem>
						</Select>
					</FormControl>
				</SidebarForm>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarButton
					label="Neu laden"
					onClick={refresh}
				/>
			</SidebarGroup>
		</Sidebar>
	);
};

export default DocumentArrivalsSidebar;
