import { Vehicle } from "../system/Domain";
import LayoutNavigation from "../Components/Layout/LayoutNavigation";
import VehicleNavigationSection from "./VehicleNavigationSection";
import VehiclesNavigationSection from "./VehiclesNavigationSection";
import RootNavigationSection from "./RootNavigationSection";

export default ({ vehicle }: { vehicle?: Vehicle }) => {
	return (
		<LayoutNavigation>
			{vehicle && (
				<VehicleNavigationSection vehicle={vehicle} />
			)}
			<VehiclesNavigationSection />
			<RootNavigationSection />
		</LayoutNavigation>
	);
};
