import moment from 'moment';
import { useState } from 'react';

type VatHistory = {
	dateFrom: moment.Moment,
	dateTo?: moment.Moment,
	fullVat: number,
};

function useVatHistory() {
	const [history] = useState<VatHistory[]>([
		{
			dateFrom: moment('2018-01-31'),
			dateTo: moment('2020-06-30'),
			fullVat: 0.19
		},
		{
			dateFrom: moment('2020-06-30'),
			dateTo: moment('2020-12-31'),
			fullVat: 0.16
		},
		{
			dateFrom: moment('2020-12-31'),
			fullVat: 0.19
		}
	]);

	const getFullVatRate = (serviceDate: moment.Moment): number => {
		const historyItem = history.find(h => h.dateFrom.isBefore(serviceDate) && (!h.dateTo || h.dateTo.isSameOrAfter(serviceDate)));
		if (!historyItem) {
			throw new Error(`No VAT rate found for date ${serviceDate.format()}`);
		}
		return historyItem.fullVat;
	};

	const getNetAmount = (serviceDate: moment.Moment, grossAmount: number): number => {
		const vatRate = getFullVatRate(serviceDate);
		return Math.round(grossAmount / (1 + vatRate) * 100) / 100;
	};

	const getGrossAmount = (serviceDate: moment.Moment, netAmount: number): number => {
		const vatRate = getFullVatRate(serviceDate);
		return Math.round(netAmount * (1 + vatRate) * 100) / 100;
	};

	return [getNetAmount, getGrossAmount, getFullVatRate] as const;
}

export default useVatHistory;
