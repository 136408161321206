import { Add } from "@mui/icons-material";
import axios from "axios";
import Sidebar from "Components/Sidebar/Sidebar";
import SidebarButton from "Components/Sidebar/SidebarButton";
import SidebarGroup from "Components/Sidebar/SidebarGroup";
import executeWithProgressDialog from "Dialogs/executeWithProgressDialog";
import getFiles from "Dialogs/getFiles";
import { BcaAuction } from "system/Domain";
import useAction from "system/useAction";

interface Props {}

export default ({}: Props) => {
	const [createSales, createSalesRunning] = useAction(async () => {
		await axios.post(`/api/sales/bca/export`);
	});

	const [importAuction, importAuctionRunning] = useAction(async () => {
		const result = await getFiles("BCA Auktion importieren", { multiple: false });
		if (!result.ok) {
			return;
		}

		const file = result.files[0];
		await executeWithProgressDialog(async (progress) => {
			const formData = new FormData();
			formData.append("file", file);

			const { data } = await axios.post<BcaAuction>(`/api/sales/bca/auctions`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
				onUploadProgress: (evt) => {
					const completed = Math.ceil((evt.loaded / evt.total) * 100);
					progress(completed);
				},
			});
		});
	});

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarButton
					color="primary"
					startIcon={<Add />}
					label="Verkäufe erstellen"
					onClick={createSales}
					disabled={createSalesRunning}
				/>
				<SidebarButton
					color="info"
					startIcon={<Add />}
					label="Auktion importieren"
					onClick={importAuction}
					disabled={importAuctionRunning}
				/>
			</SidebarGroup>
		</Sidebar>
	);
};
