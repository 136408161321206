import { ThemeColor } from "../system/Domain";
import { Box, BoxProps, Paper } from "@mui/material";
import { Children, cloneElement } from "react";

interface Props extends BoxProps {
	color?: ThemeColor
	transparent?: boolean
	missing?: boolean
	boxShadow?: number
}

export default ({ color = "info", transparent = false, missing = false, boxShadow = 1, children, ...rest }: Props) => {
	children = Children.map(children, (c: any) =>
		!!c && cloneElement(c, {
			color
		})
	);

	return (
		<Paper sx={{
			boxShadow: boxShadow,
			overflow: "hidden",
			margin: 0,
			transition: "opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
			flexGrow: 1,
			...(transparent && {
				opacity: 0.4,
				"&:hover": {
					opacity: 1
				}
			}),
			...(missing && {
				opacity: 0.4,
				backgroundImage: "repeating-linear-gradient(135deg, rgba(0, 0, 0, 0.2) 10px, rgba(0, 0, 0, 0.2) 10px, transparent 12px, transparent 20px)"
			})
		}}>
			<Box display="flex" flexDirection="row">
				{children}
			</Box>
		</Paper>
	);
}
