// import { Color } from "@material-ui/lab"; // Dominik
import { Actions } from "./actions";
import { CLEAR_USER_MESSAGE, SET_USER_MESSAGE } from "./actionTypes";

interface UserMessage {
	message : string
	color : any
}

interface State {
	userMessage : UserMessage
}

const initialState : State = {
	userMessage: {
		message: "",
		color: "success"
	}
};

const reducer = (state : State = initialState, action : Actions) : State => {
	const newState = { ...state };

	if (action.type === SET_USER_MESSAGE) {
		newState.userMessage = {
			message: action.message,
			color: action.color
		};
	} else if (action.type === CLEAR_USER_MESSAGE) {
		newState.userMessage = { ...initialState.userMessage };
	}

	return newState;
}

export default reducer;
