import { useEffect, useState } from 'react';

import { ContactSupport } from '@mui/icons-material';
import { Box, BoxProps, Collapse, IconButton } from '@mui/material';

import faqList from './FaqList';
import TextBlock from "../../Components/Layout/TextBlock";

interface Props {
	faqKey: string,
	open?: boolean
	boxProps?: BoxProps
	hideTitle?: boolean
}

const CustomerQuickFAQ = ({ faqKey, open, ...props }: Props) => {
	const faqEntry = faqList.find(item => item.key === faqKey);
	const [show, setShow] = useState(open ?? false);

	useEffect(() => {
		if (open) setShow(open);
	}, [open]);

	if (!faqEntry) {
		return null;
	}

	const handleClickOpen = () => {
		setShow(o => !o);
	};

	return (
		<Box pl={0.5} pb={0.5} display="inline">
			<IconButton onClick={handleClickOpen} size="medium">
				{show && (
					<ContactSupport fontSize="small" color="secondary" />
				)}
				{!show && (
					<ContactSupport fontSize="small" color="inherit" />
				)}
			</IconButton>
			<Collapse in={show} timeout={250}>
				<Box pl={2} pb={2.5} {...props.boxProps}>
					<TextBlock primary={props.hideTitle ? undefined : faqEntry.title} secondary={faqEntry.answer} />
				</Box>
			</Collapse>
		</Box>
	);
}

export default CustomerQuickFAQ;
