import { Box, Link, Menu, MenuItem, Typography } from '@mui/material'
import axios from 'axios'
import ActionButton from 'Components/ActionButton'
import Actions from 'Components/Actions'
import Tile from 'Components/Tiles/Tile'
import TileContent from 'Components/Tiles/TileContent'
import TextLine from 'Pages/VehicleSales/TextLine'
import { useMemo, useState, MouseEvent } from 'react'
import { useHistory } from 'react-router'
import { useAsync } from 'react-use'
import { PoolFleetLotStatus, PoolFleetVehicle, Vehicle } from 'system/Domain'
import poolFleetLotStatus from './poolFleetLotStatus'
import { ExpandMore } from "@mui/icons-material";

interface Props {
	vehicle: PoolFleetVehicle
	disabled?: boolean
	onStatusChange?: (status: PoolFleetLotStatus) => void
}

const lotStatus: PoolFleetLotStatus[] = [
	"Refurbishing",
	"Registration",
	"Ready",
	"Unknown"
]

export const PoolFleetLotTile = ({ vehicle, disabled, onStatusChange }: Props) => {
	const [lotStatusRef, setLotStatusRef] = useState<HTMLButtonElement | null>(null);
	const history = useHistory();

	const { value: lotVehicle } = useAsync(async () => {
		const { data: vehicles } = await axios.get<Vehicle[]>(`/api/vehicles?vin=${vehicle.vin}&perspective=all`);
		return vehicles.length === 1 ? vehicles[0] : null;
	}, [vehicle]);

	function handleNavigateToVehicle(vehicle: Vehicle) {
		return () => {
			history.push(`/vehicles/${vehicle.id}`);
		}
	}

	const handleStatusChange = useMemo(() => {
		if (onStatusChange && !disabled) {
			if (vehicle.status === "InPreparation" || vehicle.status === "ReadyForRent") {
				return (status: PoolFleetLotStatus) => {
					return () => {
						setLotStatusRef(null);
						onStatusChange(status);
					}
				}
			}
		}
	}, [disabled, onStatusChange, vehicle]);

	return (
		<Box>
			<Typography gutterBottom variant="h5">Platzstatus</Typography>
			<Tile>
				<TileContent>
					<TextLine
						label="Platzstatus"
						value={poolFleetLotStatus[vehicle.lotStatus]}
					/>
					{lotVehicle && (
						<>
							<TextLine
								label="Kennzeichen"
								value={(
									<Link
										href="#"
										onClick={handleNavigateToVehicle(lotVehicle)}
									>
										{lotVehicle.plateNumber}
									</Link>
								)}
							/>
							<TextLine
								label="Bemerkung"
								value={lotVehicle.note?.text}
							/>
						</>
					)}
					<Box mt={2}>
						<Actions>
							<>
								<ActionButton
									variant="outlined"
									size='small'
									disabled={!Boolean(handleStatusChange)}
									color="inherit"
									endIcon={<ExpandMore />}
									onClick={(e: MouseEvent<HTMLButtonElement>) => setLotStatusRef(e.currentTarget)}
								>
									Status ändern auf
								</ActionButton>
								<Menu
									variant="menu"
									anchorEl={lotStatusRef}
									anchorReference="anchorEl"
									anchorOrigin={{
										horizontal: "right",
										vertical: "bottom"
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'right',
									}}
									open={!!lotStatusRef}
									onClose={() => setLotStatusRef(null)}
								>
									{lotStatus.map(s => (
										<MenuItem
											key={s}
											onClick={handleStatusChange && handleStatusChange(s)}
										>
											{poolFleetLotStatus[s]}
										</MenuItem>
									))}
								</Menu>
							</>
						</Actions>
					</Box>
				</TileContent>
			</Tile>
		</Box>
	)
}
