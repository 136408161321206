import { useState } from "react";
import { Box } from "@mui/material";
import axios from "axios";
import Layout from "../../../Components/Layout/Layout";
import useAsyncEffect from "../../../system/useAsyncEffect";
import { TransportOrder, UserRole } from "../../../system/Domain";
import useAutoResetState from "../../../system/useAutoResetState";
import TransportOrderTable from "./TransportOrderTable";
import useUser from "../../../system/useUser";
import OrderScanContextMenu from "./TransportOrderScanContextMenu";
import OrdersSubMenu from "../OrdersSubMenu";
import useOrderQuery from "../../../system/useOrderQuery";
import Search from "../../../Components/Search";
import OrdersSidebar from "./TransportOrdersSidebar";
import OrdersNavigation from "Navigation/OrdersNavigation";
import moment from "moment";
import FetchNextButton from "../../../Components/FetchNextButton";
import useCompounds from 'system/useCompounds';

export default () => {
	const [orders, setOrders] = useState<Array<TransportOrder>>([]);
	const [isFetching, setIsFetching] = useState(false);
	const [fetchingJustStarted, setFetchingJustStarted] = useAutoResetState(false, 100);
	const [compounds] = useCompounds({ includeAmazonCompounds: false });

	const [user] = useUser();

	const [query, setQuery, resetQuery] = useOrderQuery('orderQuery', {
		perspective: user?.roles.includes(UserRole.LotManager)
			? "ScheduledForReturn"
			: "Active",
		sort: "dateAssigned:desc",
		dateFrom: moment().add(-2, 'years').startOf('d').toISOString(true),
		skip: 0,
		take: 20,
		fullText: ""
	});

	useAsyncEffect(async (cancelToken) => {
		setIsFetching(true);
		setFetchingJustStarted(true);
		try {
			const { data: orders } = await axios.get<TransportOrder[]>(`/api/orders/transports`, {
				params: query,
				cancelToken: cancelToken.getAxiosToken()
			});

			if (query.skip > 0) {
				setOrders(o => [
					...o,
					...orders
				]);
			} else {
				setOrders(orders);
			}
		} finally {
			setFetchingJustStarted(false);
			setIsFetching(false);
		}
	}, [query]);

	return (
		<Layout
			title="Transportaufträge"
			navigation={<OrdersNavigation />}
			subMenu={<OrdersSubMenu />}
			sidebar={<OrdersSidebar query={query} setQuery={setQuery} resetQuery={resetQuery} compounds={compounds} />}
			contextMenu={[
				<OrderScanContextMenu />
			]}
		>
			<Box mb={2}>
				<Search
					value={query.fullText}
					realtime
					onSearch={(value) => value !== query.fullText && setQuery(q => ({
						...q,
						skip: 0,
						fullText: value
					}))}
				/>
			</Box>
			<TransportOrderTable
				orders={!isFetching || fetchingJustStarted || query.skip > 0 ? orders : []}
				isLoading={isFetching && !fetchingJustStarted}
				compounds={compounds}
			/>
			<FetchNextButton
				my={3}
				hidden={orders.length < query.take}
				disabled={isFetching}
				onNext={() => setQuery(q => ({
					...q,
					skip: q.skip + q.take
				}))}
			/>
		</Layout>
	);
};
