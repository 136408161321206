import { Dispatch, SetStateAction, useState } from 'react';
import { Grid, Divider, Box } from "@mui/material";
import Tile from "../../Components/Tiles/Tile";
import TileContent from "../../Components/Tiles/TileContent";
import ListItemChips from "../../Components/ListItemChips";
import ListItemChip from "../../Components/ListItemChip";
import TextGroup from "../VehicleSales/TextGroup";
import TextLine from "../VehicleSales/TextLine";
import formatDateTime from "../../system/formatDateTime";
import {
	translateVehicleRegistrationDocumentStatus
} from "./DocumentManagement/VehicleRegistrationDocumentsStatusPill";
import { UserRole, Vehicle } from "../../system/Domain";
import ask from "../../Dialogs/ask";
import axios from "axios";
import useUser from "../../system/useUser";

interface Props {
	vehicle: Vehicle
	setVehicle: Dispatch<SetStateAction<Vehicle | undefined>>
}

const VehiclePapers = (props: Props) => {
	const [, , hasRole] = useUser();
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const cancelDocumentHandover = async () => {
		if (!props.vehicle) return;

		const answer = await ask("Soll die Dokumentenausgabe wirklich storniert werden?");

		if (answer.yes) {
			try {
				setIsSubmitting(true);
				const { data: resultVehicle } = await axios.post<Vehicle>(`/api/vehicles/${props.vehicle.id}/remarketing/documents/handover/cancel`, {});
				props.setVehicle(resultVehicle);
			} finally {
				setIsSubmitting(false);
			}
		}
	};

	return (
		<Tile title="Zulassungsbescheinigungen">
			<TileContent>
				<TextGroup title="Statushistorie">
					{props.vehicle.remarketing.registrationDocuments?.statusHistory.map((s, i) => (
						<TextLine
							key={i}
							label={translateVehicleRegistrationDocumentStatus(s.value)}
							value={formatDateTime(s.date)}
						/>
					))}
				</TextGroup>

				{props.vehicle.remarketing.registrationDocuments?.status === "HandedOver" && props.vehicle.remarketing.registrationDocuments?.handover && (
					<>
						<Box mt={2}>
							<Divider />
						</Box>
						<Grid container pt={3} display="flex" flexDirection="row">
							<Grid item flexGrow={1}>
								<TextGroup title="Ausgabe">
									<TextLine
										label="Entnommen durch"
										value={props.vehicle.remarketing.registrationDocuments.handover.removedBy}
									/>
									<TextLine
										label="Ausgabetyp"
										value={props.vehicle.remarketing.registrationDocuments.handover.handoverType}
									/>
									{props.vehicle.remarketing.registrationDocuments.handover.trackingReference && (
										<TextLine
											label="Tracking Referenz"
											value={props.vehicle.remarketing.registrationDocuments.handover.trackingReference}
										/>
									)}
								</TextGroup>
							</Grid>
							{hasRole(UserRole.AldManager) && (
								<Grid item>
									<ListItemChip
										label="Ausgabe stornieren"
										color="warning"
										loading={isSubmitting}
										onClick={cancelDocumentHandover}
									/>
								</Grid>
							)}
						</Grid>
					</>
				)}
				<ListItemChips>
					<ListItemChip
						label={translateVehicleRegistrationDocumentStatus(props.vehicle.remarketing.registrationDocuments?.status)}
						color={props.vehicle.remarketing.registrationDocuments?.status ? "primary" : "info"}
					/>
				</ListItemChips>
			</TileContent>
		</Tile>
	)
}

export default VehiclePapers;
