import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Link, LinkOff } from '@mui/icons-material';
import { Box, Chip, TableBody, TableHead, TableRow, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import TextBlock from '../../../Components/Layout/TextBlock';
import PaperTable from '../../../Components/PaperTable';
import { Plate } from '../../../Components/Plate';
import dateFormats from '../../../system/dateFormats';
import { UnregistrationOrder, UserRole, Vehicle } from '../../../system/Domain';
import UnregistrationOrderStatusPill from './UnregistrationOrderStatusPill';
import { Colgroup, TableCell } from "../../../Components/BreakpointStyledComponents";
import { SyntheticEvent, useState } from "react";
import useUser from 'system/useUser';
import axios from 'axios';

interface Props {
	unregistrationOrders: UnregistrationOrder[]
	isLoading?: boolean
	noRowsMessage?: string
	align?: boolean
	vehicle?: Vehicle
	reloadVehicle?: (() => Promise<void>) | undefined;
}

const UnregistrationOrdersOverviewTable = (props: Props) => {
	const history = useHistory();
	const [, , hasRole, , hasAnyRole] = useUser();
	const isAldAdmin = hasRole(UserRole.AldAdmin);
	const [isLinking, setIsLinking] = useState(false);


	const linkUnregistrationOrder = async (e: SyntheticEvent, unregistrationOrderId: string) => {
		if (!props.vehicle || !unregistrationOrderId || !props.reloadVehicle) {
			return;
		}

		e.stopPropagation();

		setIsLinking(true);
		try {
			await axios.put(`/api/vehicles/${props.vehicle.id}/remarketing/unregistrations/${unregistrationOrderId}/link`);
		} finally {
			await props.reloadVehicle();
			setIsLinking(false);
		}
	};

	const unlinkUnregistrationOrder = async (e: SyntheticEvent, unregistrationOrderId: string) => {
		if (!props.vehicle || !unregistrationOrderId || !props.reloadVehicle) {
			return;
		}

		e.stopPropagation();

		setIsLinking(true);
		try {
			await axios.put(`/api/orders/unregistrations/${unregistrationOrderId}/unlink`);
		} finally {
			await props.reloadVehicle();
			setIsLinking(false);
		}
	};


	return (
		<PaperTable>
			{props.align ?
				<TableHead>
					<TableRow>
						<TableCell xs sm>Auftrag</TableCell>
						<TableCell md lg xl sx={{ width: { md: "30%", lg: "20%", xl: "20%" } }}>Fahrzeug</TableCell>
						<TableCell xl sx={{ width: "10%" }}>Status</TableCell>
						<TableCell md lg xl sx={{width: {md: "25%", lg: "25%", xl: "20%"}}}>Dienstleister</TableCell>
						<TableCell md lg xl>Erstellt</TableCell>
					</TableRow>
				</TableHead>
				:
				<>
					<Colgroup sm md lg xl>
						<col width="auto" />
						<col width="auto" />
						<col width="auto" />
						<col width="auto" />
					</Colgroup>
					<TableHead>
						<TableRow>
							<TableCell xs sm>Abmeldung</TableCell>
							<TableCell md lg xl>Fahrzeug</TableCell>
							<TableCell md lg xl>Status</TableCell>
							<TableCell md lg xl>Dienstleister</TableCell>
							<TableCell md lg xl>Erstellt</TableCell>
						</TableRow>
					</TableHead>
				</>}
			<TableBody>
				{!props.isLoading && props.unregistrationOrders && props.unregistrationOrders.map(unregistrationOrder => {
					const status = <UnregistrationOrderStatusPill unregistrationOrder={unregistrationOrder} />;
					const creation = <TextBlock
						primary={unregistrationOrder.createdBy.name}
						secondary={moment(unregistrationOrder.dateCreated).format(dateFormats.dateTime)}
					/>;

					return (
						<TableRow
							key={unregistrationOrder.id}
							hover
							onClick={() => history.push(`/orders/unregistrations/${unregistrationOrder.id}`)}
						>
							<TableCell>
								<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
									<Box>
										<Plate plateNumber={unregistrationOrder.plateNumber} style={{ zoom: 0.6 }} />
										<TextBlock
											primary={!props.align && unregistrationOrder.vehicle.type?.description}
											secondary={unregistrationOrder.vin}
											compact
										/>
										<Box sx={props.align ?
											{ display: { xs: "contents", xl: "none" } }
											:
											{ display: { xs: "contents", md: "none" } }}>
											<Box mt={2}>{status}</Box>
										</Box>
										<Box sx={{ display: { xs: "contents", md: "none" } }}>
											<Box mt={2}>{creation}</Box>
										</Box>
									</Box>
									{props.align && (
										<Box>
											{isLinking ?
												<CircularProgress size={24} /> :
												(props.vehicle.remarketing?.unregistration?.orderId === unregistrationOrder.id ?
													<Tooltip title={"Abmeldeauftrag abtrennen"} disableHoverListener={!isAldAdmin}>
														<Link onClick={(e) => isAldAdmin ? unlinkUnregistrationOrder(e, unregistrationOrder.id) : undefined} />
													</Tooltip>
													:
													<Tooltip title={"Abmeldeauftrag verknüpfen"} disableHoverListener={!isAldAdmin}>
														<LinkOff onClick={(e) => isAldAdmin ? linkUnregistrationOrder(e, unregistrationOrder.id) : undefined} />
													</Tooltip>
												)}
										</Box>
									)}
								</Box>
							</TableCell>
							{props.align ?
								<TableCell xl>{status}</TableCell>
								:
								<TableCell md lg xl>{status}</TableCell>}
							<TableCell md lg xl>
								<TextBlock
									primary={
										<Chip
											label={unregistrationOrder.assignedTo.name}
											size="small"
										/>
									}
									secondary={
										<>
											{unregistrationOrder.transferInformation?.dateTransferred && (
												moment(unregistrationOrder.transferInformation.dateTransferred).format(dateFormats.dateTime)
											)}
											{!unregistrationOrder.transferInformation?.dateTransferred && unregistrationOrder.transferInformation?.mode === "Api" && (
												"-"
											)}
											{!unregistrationOrder.transferInformation?.dateTransferred && unregistrationOrder.transferInformation?.mode === "Manual" && (
												"manuell übertragen"
											)}
										</>
									}
								/>
							</TableCell>
							<TableCell md lg xl>{creation}</TableCell>
						</TableRow>
					)
				})}
				{props.isLoading === true && (
					<TableRow>
						<TableCell
							colSpan={4}
							style={{ textAlign: "center" }}
						>
							<CircularProgress />
						</TableCell>
					</TableRow>
				)}
				{props.unregistrationOrders.length === 0 && !props.isLoading && (
					<TableRow>
						<TableCell
							colSpan={4}
							style={{ textAlign: "center" }}
						>
							{props.noRowsMessage}
						</TableCell>
					</TableRow>
				)}
			</TableBody>
		</PaperTable>
	);
};

export default UnregistrationOrdersOverviewTable;
