import {List, AccountTree} from '@mui/icons-material';
import MenuItem from "Components/Layout/MenuItem";
import { UserRole } from "../../system/Domain";

export default () => {
	return (
		<>
			<MenuItem
				roles={[UserRole.AldUser, UserRole.LotManager]}
				icon={<List />}
				name="Fahrzeuge"
				path="/vehicles"
				variant="secondary"
			/>
			<MenuItem
				roles={[UserRole.AldUser, UserRole.LotManager]}
				icon={<AccountTree />}
				name="Inventuren"
				path="/inventories"
				variant="secondary"
			/>
		</>
	);
};
