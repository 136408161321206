import { AsyncFnReturn } from "react-use/lib/useAsyncFn";
import { useAsyncFn } from "react-use";
import parseContentDispositionFilename from "./parseContentDispositionFilename";
import fileDownload from "js-file-download";
import axios from "axios";

export default (query : any, uri : string, output : string = "xlsx") : AsyncFnReturn<() => Promise<void>> =>
	useAsyncFn(async () => {
		const response = await axios.get(uri, {
			responseType: "blob",
			params: {
				...query,
				output: output
			}
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);
	}, [query]);
