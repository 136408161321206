import MenuItem from "Components/Layout/MenuItem";
import { UserRole } from "../../system/Domain";
import { Receipt, AssignmentTurnedIn, PriceChange } from "@mui/icons-material";

export default () => {
	return (
		<>
			<MenuItem
				roles={[UserRole.AldManager]}
				icon={<AssignmentTurnedIn />}
				name="Jobs"
				path="/admin/jobs"
				variant="secondary"
			/>
			<MenuItem
				roles={[UserRole.AldUser]}
				icon={<Receipt />}
				name="Kundeneinstellungen"
				path="/customer-settings"
				variant="secondary"
			/>
			<MenuItem
				roles={[UserRole.AldManager]}
				icon={<PriceChange />}
				name="Überführungskosten"
				path="/transport-pricing"
				variant="secondary"
			/>
		</>
	);
};
