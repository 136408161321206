import { useState } from "react";
import Sidebar from '../../../Components/Sidebar/Sidebar';
import SidebarButton from '../../../Components/Sidebar/SidebarButton';
import SidebarGroup from '../../../Components/Sidebar/SidebarGroup';
import axios from "axios";
import { AssessmentOrder, UserRole } from "system/Domain";
import askForTextAnswer from "system/askForTextAnswer";
import useUser from "system/useUser";
import { useHistory } from "react-router-dom";
import { calculateAssessmentOrderFormData } from "./AssessmentOrdersForm";
import moment from "moment";
import dateFormats from "system/dateFormats";
import { useTheme } from "@mui/material";

export interface Props {
	assessmentOrder: AssessmentOrder
	setAssessmentOrder?: (order: AssessmentOrder) => void
	isEditingDefect: boolean
	startDefect?: () => void
	canNavigateToAssessments: boolean;
	startInquiryRequest?: () => void;
	isEditingInquiryRequest: boolean;
}

export default ({ assessmentOrder, setAssessmentOrder, isEditingDefect, startDefect, canNavigateToAssessments, startInquiryRequest, isEditingInquiryRequest }: Props) => {
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [user, , hasRole] = useUser();
	const history = useHistory();
	const theme = useTheme();
	const isAldUser = hasRole(UserRole.AldUser);
	const isAldManager = hasRole(UserRole.AldManager);
	const isDamageAssessor = hasRole(UserRole.DamageAssessor);
	const handleCancellation = async () => {
		if (!assessmentOrder) {
			return;
		}

		const cancellationReason = await askForTextAnswer({ text: '', required: true, label: 'Stornierungsgrund', title: "Stornierung" });

		if (!cancellationReason) {
			return;
		}

		setIsSubmitting(true);

		try {
			const response = await axios.post<AssessmentOrder>(`/api/orders/assessments/${assessmentOrder.id}/cancel`, {
				reason: cancellationReason
			});

			setAssessmentOrder(response.data);
		} finally {
			setIsSubmitting(false);
		}
	};

	const handleReactivation = async () => {
		if (!assessmentOrder) {
			return;
		}

		setIsSubmitting(true);

		try {
			const response = await axios.post<AssessmentOrder>(`/api/orders/assessments/${assessmentOrder.id}/reactivate`, {});

			setAssessmentOrder(response.data);
		} finally {
			setIsSubmitting(false);
		}
	};

	const damageAssessorCanModifyDefect = assessmentOrder?.defect &&
		isDamageAssessor &&
		assessmentOrder.defect?.createdBy.id === user.id &&
		moment(new Date(assessmentOrder.defect?.dateCreated)).format(dateFormats.date) === moment(new Date()).format(dateFormats.date);
	const canModifyDefect = isAldManager || damageAssessorCanModifyDefect;

	return (
		<>
			{assessmentOrder && (
				<Sidebar>
					<SidebarGroup>
						{isAldUser && (assessmentOrder.status === "Created" || assessmentOrder.status === "Processed") && (
							<SidebarButton
								sx={{
									borderColor: theme.palette.warning.main,
									color: theme.palette.warning.main,
								}}
								disabled={isSubmitting}
								onClick={handleCancellation}
								label="Stornieren"
							/>
						)}
					</SidebarGroup>
					<SidebarGroup>
						{isAldUser && assessmentOrder.status === "Cancelled" && !assessmentOrder.dateProcessed && (
							<SidebarButton
								color="secondary"
								disabled={isSubmitting}
								onClick={handleReactivation}
								label="Reaktivieren"
							/>
						)}
						{(isAldUser || assessmentOrder.vehicle?.id) && (
							<SidebarButton
								color="inherit"
								disabled={isSubmitting}
								onClick={() => history.push("/orders/assessments/new", {
									...calculateAssessmentOrderFormData(assessmentOrder),
									vehiclePlateNumber: assessmentOrder?.vehicle?.plateNumber
								})}
								label="Als Vorlage nutzen"
							/>
						)}
					</SidebarGroup>
					<SidebarGroup>
						{assessmentOrder.status === "Created" && (
							<SidebarButton
								color="inherit"
								onClick={() => history.push(`/orders/assessments/${assessmentOrder.id}/edit`)}
								label="Bearbeiten"
							/>
						)}
						{assessmentOrder.status === "Processed" && !assessmentOrder.defect && !isEditingDefect && (
							<SidebarButton
								color="inherit"
								onClick={startDefect}
								label="Mängelmeldung"
								sx={{
									borderColor: theme.palette.primary.main,
									color: theme.palette.primary.main
								}}
							/>
						)}
						{assessmentOrder.status === "Processed" && assessmentOrder.subjectInquiries.length === 0 && (
							<SidebarButton
								color="inherit"
								onClick={startInquiryRequest}
								sx={{
									borderColor: theme.palette.primary.main,
									color: theme.palette.primary.main
								}}
								label={isEditingInquiryRequest ? "Sachverhaltsanfrage abbrechen" : "Sachverhaltsanfrage"}
							/>
						)}
						{assessmentOrder.defect && canModifyDefect && !isEditingDefect && (
							<SidebarButton
								color="inherit"
								onClick={startDefect}
								label="Mängelmeldung anpassen"
							/>
						)}
					</SidebarGroup>
					<SidebarGroup>
						{assessmentOrder.status === "Processed" && (
							<SidebarButton
								color="inherit"
								onClick={() => history.push(`/orders/assessments/${assessmentOrder.id}/change`)}
								label="Auftragsdaten aktualisieren"
							/>
						)}
						{canNavigateToAssessments && (
							<SidebarButton
								color="inherit"
								onClick={() => history.push(`/vehicles/${assessmentOrder.vehicle?.id}/assessments`)}
								label="Zum Gutachten"
							/>
						)}
					</SidebarGroup>
				</Sidebar>
			)}
		</>
	);
};

