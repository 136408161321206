import { Launch } from "@mui/icons-material";
import { Box } from "@mui/material";
import FluentGrid from "Components/FluentGrid";
import TextBlock from "Components/Layout/TextBlock";
import ListItem from "Components/ListItem";
import ListItemAction from "Components/ListItemAction";
import ListItemActions from "Components/ListItemActions";
import ListItemChip from "Components/ListItemChip";
import ListItemChips from "Components/ListItemChips";
import ListItemContent from "Components/ListItemContent";
import { Plate } from "Components/Plate";
import { useHistory } from "react-router";
import { DelegatedLeasingStatus, ThemeColor, Vehicle } from "system/Domain";
import formatDate from "system/formatDate";

interface Props {
	vehicle: Vehicle;
	onClick?: () => void;
	selected?: boolean;
	customerDetails?: boolean;
}

export const formatDelegatedLeasingStatus = (status: DelegatedLeasingStatus): string => {
	switch (status) {
		case "Active":
			return "Aktiv";
		case "PreDeployment":
			return "Pre-Deployment";
		case "Idle":
		default:
			return status;
	}
};

export const paintDelegatedLeasingStatus = (status: DelegatedLeasingStatus): ThemeColor => {
	switch (status) {
		case "Active":
			return "primary";
		case "Idle":
			return "warning";
		case "PreDeployment":
			return "info";
	}
};

export default ({ vehicle, onClick, selected = false }: Props) => {
	const history = useHistory();

	const handleClick = !!onClick ? onClick : () => history.push(`/vehicles/${vehicle.id}/leasing`);

	let color: ThemeColor = "info";
	if (selected) {
		color = "primary";
	}

	return (
		<ListItem color={color}>
			<ListItemActions>
				<ListItemAction icon={<Launch />} color="inherit" onClick={handleClick} />
			</ListItemActions>
			<ListItemContent onClick={handleClick}>
				<TextBlock
					primary={<Plate plateNumber={vehicle.plateNumber} style={{ zoom: 0.6 }} />}
					secondary={
						!!vehicle.type &&
						(vehicle.type.description
							? `${vehicle.type.description}`
							: `${vehicle.type.make.name} ${vehicle.type.model.version} ${vehicle.type.model.variant}`)
					}
					// tertiary={
					// 	<InlineList>
					// 		{!!vehicle.leasing.main?.dateBegin && (
					// 			<span>Vertragsbeginn: {formatDate(vehicle.leasing.main.dateBegin)}</span>
					// 		)}
					// 		{!!vehicle.leasing.main?.dateEnd && (
					// 			<span>Vertragsende: {formatDate(vehicle.leasing.main.dateEnd)}</span>
					// 		)}
					// 	</InlineList>
					// }
				/>
				<Box mt={2} mb={1}>
					<FluentGrid itemWidth={150}>
						<TextBlock primary={vehicle.vin} secondary="FIN" reduced />
						<TextBlock primary={vehicle.leasing.main.id.toString()} secondary="Vertragsnummer" reduced />
						<TextBlock
							primary={formatDate(vehicle.leasing.main.dateBegin)}
							secondary="Vertragsbeginn"
							reduced
						/>
						<TextBlock
							primary={formatDate(vehicle.leasing.main.dateEnd)}
							secondary="Vertragsende"
							reduced
						/>
					</FluentGrid>
				</Box>
				<ListItemChips>
					{!!vehicle.leasing.delegated && (
						<ListItemChip
							label={formatDelegatedLeasingStatus(vehicle.leasing.delegated.status)}
							color={paintDelegatedLeasingStatus(vehicle.leasing.delegated.status)}
						/>
					)}
					{!!vehicle.leasing.supplementary && (
						<ListItemChip
							label={`${vehicle.leasing.supplementary.customer.fullName} (${formatDate(vehicle.leasing.supplementary.dateBegin)})`}
							color={vehicle.leasing.supplementary.status.processStatus === "Active" ? "success" : "info"}
						/>
					)}
					{!!vehicle.leasing.main && !vehicle.leasing.delegated && (
						<>
							<ListItemChip
								label={`Vertragsbeginn: ${formatDate(vehicle.leasing.main.dateBegin)}`}
								color="info"
							/>
							{vehicle.leasing.main.dateEnd && (
								<ListItemChip
									label={`Vertragsende: ${formatDate(vehicle.leasing.main.dateEnd)}`}
									color="info"
								/>
							)}
						</>
					)}
				</ListItemChips>
			</ListItemContent>
		</ListItem>
	);
};
