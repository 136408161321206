import { User } from "./Domain";
import { AnyAction } from "redux";

export interface AuthState {
	user : User | null
}

export default (state: AuthState = { user: null }, action : AnyAction) : AuthState => {
	if (action.type === 'SET_USER') {
		return { ...state, user: action.user };
	} else {
		return state;
	}
};
