import axios from "axios";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import useAsyncEffect from "../../system/useAsyncEffect";
import { Billing } from "../../system/Domain";

export interface BillingProps {
	billing : Billing
}

export interface BillingPropsWithSetter extends BillingProps {
	setBilling : Dispatch<SetStateAction<Billing | undefined>>
}

export default (id : string) : [Billing | undefined, Dispatch<SetStateAction<Billing | undefined>>, (id : string) => Promise<void>] => {
	const [billing, setBilling] = useState<Billing>();

	const load = useCallback(async (id : string) => {
		if (!id) {
			return;
		}

		const { data: billing } = await axios.get<Billing>(`/api/billings/${id}`);
		setBilling(billing);
	}, [id]);

	useAsyncEffect(async () => {
		setBilling(undefined);
		await load(id);
	}, [id]);

	return [
		billing,
		setBilling,
		load
	];
};
