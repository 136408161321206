import { useState } from "react";
import { Box } from "@mui/material";
import { CatalogPreview, CatalogPreviewQuery } from "../../system/Domain";
import Tile from "../../Components/Tiles/Tile";
import formatNumber from "../../system/formatNumber";
import CatalogPreviewListItem from "./CatalogPreviewListItem";
import useAxiosEffect from "../../system/useAxiosEffect";
import axios from "axios";
import Search from "../../Components/Search";
import List from "../../Components/List";
import ListItemContent from "../../Components/ListItemContent";
import ListItem from "../../Components/ListItem";

interface Props {
	// query : CatalogPreviewQuery
	// setQuery : Dispatch<SetStateAction<CatalogPreviewQuery>>
	isSelected? : (vehicle : CatalogPreview) => boolean
	onSelect? : (catalogPreview? : CatalogPreview) => void
}

export default ({ isSelected, onSelect } : Props) => {
	const [query, setQuery] = useState<CatalogPreviewQuery>({
		fullText: ""
	});

	const [catalogPreviews, setCatalogPreviews] = useState<CatalogPreview[]>();
	const [isLoading] = useAxiosEffect(async config => {
		if (!query.fullText) {
			setCatalogPreviews(undefined);
			return;
		}

		const { data } = await axios.post<CatalogPreview[]>(`/api/catalog/previews/search`, query, config);
		setCatalogPreviews(data);
	}, [query]);

	return (
		<Tile
			title="Fahrzeugtypen"
			subtitle={!isLoading && catalogPreviews && (
				<span>({formatNumber(catalogPreviews.length)})</span>
			)}
		>
			<Box mt={2.5} mb={2}>
				<Search
					defaultValue={query.fullText}
					loading={isLoading}
					realtime
					onSearch={(value) => value !== query.fullText && setQuery(q => ({
						...q,
						fullText: value
					}))}
				/>
			</Box>
			<List>
				{!isLoading && catalogPreviews && catalogPreviews.length === 0 && (
					<ListItem>
						<ListItemContent>Keine Fahrzeugtypen gefunden</ListItemContent>
					</ListItem>
				)}
				{catalogPreviews && catalogPreviews.length > 0 && catalogPreviews.map(v => (
					<CatalogPreviewListItem
						key={v.id}
						catalogPreview={v}
						isSelected={(isSelected && isSelected(v)) || false}
						onSelect={onSelect}
					/>
				))}
			</List>
		</Tile>
	);
}
