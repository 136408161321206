import TextBlock from "../../Components/Layout/TextBlock";
import { colors, PropTypes } from "@mui/material";
import formatDateTime from "../../system/formatDateTime";
import { SaleBidStatus, SaleOffer, SaleOfferStatus, ThemeColor } from "../../system/Domain";

export interface VehicleSaleOfferStatusProps {
	offer : SaleOffer
	historyId? : string
	showDate? : boolean
}

export const formatSaleOfferStatus = (status : SaleOfferStatus) => {
	switch (status) {
		case "Pricing":
			return "Bepreisung";
		case "Publishing":
			return "Wird veröffentlicht";
		case "PublishingFailed":
			return "Veröffentlichung fehlgeschlagen";
		case "Active":
			return "Aktiv";
		case "Reserved":
			return "Reserviert";
		case "Stopping":
			return "Wird gestoppt";
		case "Stopped":
			return "Gestoppt";
		case "Sold":
			return "Verkauft";
		case "Archived":
			return "Archiviert";
		default:
			return status;
	}
};

export const paintSaleOfferStatus = (status : SaleOfferStatus): ThemeColor => {
	switch (status) {
		case "Pricing":
		case "Archived":
		case "Stopped":
			return "info";
		case "Publishing":
		case "Stopping":
			return "warning";
		case "PublishingFailed":
			return "error";
		case "Active":
		case "Reserved":
			return "primary";
		case "Sold":
			return "success";
		default:
			return "info";
	}
};

export const paintSaleBidStatus = (status : SaleBidStatus): ThemeColor => {
	switch (status) {
		case "Reserved":
			return "primary";
		case "Sold":
			return "success";
		case "Active":
		case "Withdrawn":
		default:
			return "info";
	}
};

export default ({ offer, historyId, showDate = true } : VehicleSaleOfferStatusProps) => {
	const historyEntry = !!historyId
		? offer.statusHistory.find(h => h.id === historyId)
		: offer.statusHistory[offer.statusHistory.length - 1];

	if (!historyEntry) {
		return null;
	}

	const date = showDate && formatDateTime(historyEntry.date);
	let color : Exclude<PropTypes.Color, 'inherit'> = "default";
	let explicitColor : string = "";

	switch (historyEntry.value) {
		case "Publishing":
		case "Stopping":
		case "Stopped":
			color = "secondary";
			break;
		case "PublishingFailed":
			explicitColor = colors.red[500];
			break;
		case "Active":
			color = "primary";
			break;
		case "Sold":
			explicitColor = colors.green[500];
			break;
		default:
			color = "default";
			break;
	}

	return (
		<TextBlock
			chip={{
				label: formatSaleOfferStatus(historyEntry.value),
				color,
				explicitColor
			}}
			secondary={date}
		/>
	)

}
