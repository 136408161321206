import { ReturnOrderSession, TransportOrder } from "../../../../system/Domain";
import { useAsync } from "react-use";
import axios from "axios";
import { useHistory } from "react-router";
import _ from "lodash";
import fileDownload from "js-file-download";
import { Box, Chip, Link, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import TextBlock from "../../../../Components/Layout/TextBlock";
import TransportDirection from "../../../Imports/LogisticOrders/TransportDirection";
import PaperTable from "../../../../Components/PaperTable";

const SessionReturnOrdersView = ({ session }: { session: ReturnOrderSession }) => {
	const { value: orders } = useAsync(async () => {
		const { data } = await axios.get<TransportOrder[]>(
			`/api/orders/returns/sessions/${session.id}/transport-orders`,
		);
		return data;
	}, [session]);

	const history = useHistory();
	const companies = _.groupBy(orders, "assignedTo.name");

	return (
		<>
			{Object.keys(companies).map((c) => {
				const handleDownloadPdf = async () => {
					const response = await axios.get(
						`/api/orders/returns/sessions/${session.id}/transport-orders.pdf`,
						{
							params: {
								company: c,
							},
							responseType: "blob",
						},
					);
					fileDownload(response.data, `auftraege-${c}.pdf`);
				};

				const handleDownloadCsv = async () => {
					const response = await axios.get(
						`/api/orders/returns/sessions/${session.id}/transport-orders.csv`,
						{
							params: {
								company: c,
							},
							responseType: "blob",
						},
					);
					fileDownload(response.data, `auftraege-${c}.csv`);
				};

				const handleDownloadCsvWithDelegateLinks = async () => {
					const response = await axios.get(
						`/api/orders/returns/sessions/${session.id}/transport-orders.csv`,
						{
							params: {
								company: c,
								createDelegateLinks: true,
							},
							responseType: "blob",
						},
					);
					fileDownload(response.data, `auftraege-${c}.csv`);
				};

				const orders = companies[c];

				return (
					<Box key={c} my={4}>
						<Box
							mb={1}
							display="flex"
							flexDirection="row"
							justifyContent="space-between"
							alignItems="flex-end"
						>
							<Box>
								<Typography variant="h5" component="div">
									{c}
								</Typography>
								<Typography variant="caption" component="div" noWrap>
									{`${orders.length} Aufträge`}
								</Typography>
							</Box>
							<Typography variant="caption" component="span">
								<Link onClick={handleDownloadPdf}>Download Auftragsdatei</Link>
								<span> | </span>
								<Link onClick={handleDownloadCsv}>Download CSV</Link>
								<span> | </span>
								<Link
									onClick={handleDownloadCsvWithDelegateLinks}
									title="Erzeugt RNP Delegate Links nur, wenn noch kein RNP existiert. Danach wird kein weiterer Link erzeugt."
								>
									Download CSV mit Delegate Links
								</Link>
							</Typography>
						</Box>
						<PaperTable>
							<colgroup>
								<col width="auto" />
								<col width="auto" />
								<col width="100%" />
							</colgroup>
							<TableBody>
								{orders.map((o) => (
									<TableRow
										key={o.id}
										hover
										onClick={() => history.push(`/orders/transports/${o.id}`)}
									>
										<TableCell>
											<Chip label={o.orderRef ? "Änderung" : "Neu"} />
										</TableCell>
										<TableCell>
											<TextBlock nowrap primary={o.plateNumber} secondary={o.businessLine} />
										</TableCell>
										<TableCell>{o.leasmanContract?.customer.name1}</TableCell>
										<TableCell>
											<TransportDirection
												from={o.pickupAddress.zipCode}
												to={o.destinationAddress.zipCode}
											/>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</PaperTable>
					</Box>
				);
			})}
		</>
	);
};

export default SessionReturnOrdersView;
