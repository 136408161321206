import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { TransportOrderStatus } from "./Domain";

export interface OrderQuery {
	perspective? : string
	status? : TransportOrderStatus[]
	vin? : string
	plateNumber? : string
	assignedTo? : string
	dateFrom? : string
	dateCreated? : string
	plannedReturnDate? : string
	confirmed? : boolean
	scheduledForReturn? : boolean
	overdueReturns? : boolean
	hasReturnOrder? : boolean
	sort : string
	skip : number
	take : number
	fullText? : string
	orderType?: string
	pickupCompound?: string
	destinationCompound?: string
}

export default (sessionKey : string, defaultQuery : OrderQuery) : [OrderQuery, Dispatch<SetStateAction<OrderQuery>>, () => OrderQuery] => {
	const jsonQuery = sessionStorage[sessionKey];

	let sessionQuery = defaultQuery;
	if (jsonQuery) {
		sessionQuery = {
			...sessionQuery,
			...JSON.parse(jsonQuery),
			skip: sessionQuery.skip,
			take: sessionQuery.take
		};
	}

	const [query, setQuery] = useState<OrderQuery>(sessionQuery);

	useEffect(() => {
		sessionStorage[sessionKey] = JSON.stringify({
			...query,
			skip: defaultQuery.skip,
			take: defaultQuery.take
		});
	}, [query]);

	const resetQuery = useCallback(() => {
		setQuery(defaultQuery);
		return defaultQuery;
	}, [defaultQuery]);

	return [query, setQuery, resetQuery];
};
