import { useState } from "react";
import { RepairOrder, UserRole, Vehicle } from "../../system/Domain";
import useAsyncEffect from "../../system/useAsyncEffect";
import axios from "axios";
import { CircularProgress, Grid } from "@mui/material";
import VehicleHeader from "./VehicleHeader";
import { useParams } from "react-router";
import useVehicle from "../../system/useVehicle";
import LotScanContextMenu from "./LotScanContextMenu";
import VehicleHeaderMenu from "./VehicleHeaderMenu";
import VehicleSubMenu from "./VehicleSubMenu";
import Layout from "../../Components/Layout/Layout";
import VehicleNavigation from "Navigation/VehicleNavigation";
import DekraRepairOrderListItem, { ViewModeAssessment } from "./DekraRepairOrderListItem";
import CarglassRepairOrderListItem, { ViewModeCarglass } from "./CarglassRepairOrderListItem";
import VehicleRepairOrderAdd, { GeneralRepairFormType } from "./VehicleRepairOrderAdd";
import Tile from "Components/Tiles/Tile";
import ActionButton from "../../Components/ActionButton";
import GeneralRepairOrderListItem from "./GeneralRepairOrderListItem";
import Actions from "../../Components/Actions";
import List from "Components/List";
import useUser from "../../system/useUser";

export default () => {
	const { id } = useParams<{ id: string }>();
	const [vehicle] = useVehicle(id);
	const [repairOrders, setRepairOrders] = useState<RepairOrder[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isEditingGeneralRepair, setIsEditingGeneralRepair] = useState<boolean>(false);
	const [, , hasRole] = useUser();

	const getRepairOrders = async (vehicle: Vehicle) => {
		setIsLoading(true);
		try {
			const response = await axios.get<RepairOrder[]>("/api/orders/repairs", {
				params: {
					vin: vehicle.vin,
					sort: "dateAssigned:desc"
				}
			});

			setRepairOrders(response.data);
		} finally {
			setIsLoading(false);
		}
	};

	useAsyncEffect(async () => {
		setRepairOrders([]);
		setIsLoading(false);

		if (!vehicle) {
			return;
		}

		await getRepairOrders(vehicle);
	}, [vehicle]);

	const saveVehicleDefects = async (formData: GeneralRepairFormType): Promise<RepairOrder[]> => {
		if (!formData) {
			return;
		}

		setIsLoading(true);

		try {
			const response = await axios.post<RepairOrder>(`/api/orders/repairs/vehicles/${vehicle.id}/general-repair`, formData);
			setRepairOrders([...repairOrders, response.data]);
		} finally {
			setIsEditingGeneralRepair(false);
			setIsLoading(false);
		}
	};

	return (
		<Layout
			title="Werkstattaufträge"
			plateNumber={vehicle?.plateNumber}
			navigation={<VehicleNavigation vehicle={vehicle} />}
			contextMenu={[
				<LotScanContextMenu />,
				<VehicleHeaderMenu vehicle={vehicle} />
			]}
			subMenu={vehicle && <VehicleSubMenu vehicle={vehicle} />}
		>
			{!vehicle && <CircularProgress size={48} />}
			{vehicle && (
				<>
					<VehicleHeader vehicle={vehicle} />

					{hasRole(UserRole.AldUser) && (
						<>
							<Actions>
								<ActionButton
									onClick={() => setIsEditingGeneralRepair(true)}
									disabled={isLoading || isEditingGeneralRepair}
								>
									Allgemeine Reparatur hinzufügen
								</ActionButton>
							</Actions>

							{isEditingGeneralRepair && (
								<Grid item>
									<VehicleRepairOrderAdd
										isNew
										vehicle={vehicle}
										setIsEditingGeneralRepair={setIsEditingGeneralRepair}
										isEditingInterruption={isEditingGeneralRepair}
										saveVehicleDisruption={saveVehicleDefects} />
								</Grid>
							)}
						</>
					)}

					<Grid container spacing={6}>
						<Grid item xs={12}>
							<Tile title="Werkstattaufträge">
								<List>
									{repairOrders.map((repairOrder) => {
										switch (repairOrder.repairOrderType) {
											case "AssessmentReport": {
												return <DekraRepairOrderListItem
													compact
													key={repairOrder.id}
													order={repairOrder}
													setIsFetching={setIsLoading}
													viewMode={ViewModeAssessment.List}
													defaultViewMode={ViewModeAssessment.List}
												/>
											}
											case "Carglass": {
												return <CarglassRepairOrderListItem
													compact
													key={repairOrder.id}
													order={repairOrder}
													viewMode={ViewModeCarglass.Overview}
													defaultViewMode={ViewModeCarglass.Overview}
												/>
											}
											case "GeneralRepair": {
												return <GeneralRepairOrderListItem
													compact
													order={repairOrder}
													key={repairOrder.id}
													isFetching={isLoading}
													setIsFetching={setIsLoading}
													viewMode={ViewModeAssessment.List}
													defaultViewMode={ViewModeAssessment.List}
													orders={repairOrders}
													setOrders={setRepairOrders}
												/>
											}
										}
									})}
								</List>
							</Tile>
						</Grid>
					</Grid>
				</>
			)}
		</Layout>
	);
};
