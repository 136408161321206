import {
	CollectionType,
	ReturnOrderStatus,
	CustomerOrderType,
	VehicleServiceStatus,
	RepairOrderStatus,
} from "./Domain";

export const translateReturnOrderDeliveryType = (value: string) => {
	switch (value) {
		case "CustomEntry":
			return "Freie Eingabe";
		case "RebuyerAddress":
			return "RKV-Geberadresse";
		case "UsedCars":
			return "Gebrauchtwagenplatz";
		case "CustomerDelivery":
			return "Eigenanlieferung";
		case "Neuss":
				return "ATN Neuss";
		default:
			return value;
	}
};

export const translateReturnOrderTireType = (value: string) => {
	switch (value) {
		case "gjr":
			return "Ganzjahresreifen";
		case "sr":
			return "Sommerreifen";
		case "wr":
			return "Winterreifen";
		case "unk":
			return "Unbekannt";
		default:
			return value;
	}
};

export const translateReturnOrderStatus = (value: ReturnOrderStatus): string => {
	switch (value) {
		case "Created":
			return "Erstellt";
		case "Processed":
			return "Verarbeitet";
		case "Cancelled":
			return "Storniert";
		case "Imported":
			return "In Bearbeitung";
		case "Assigned":
			return "Zugewiesen";
		case "CustomerCreated":
			return "Kundeneingabe";
		case "Approved":
			return "Bestätigt";
		case "Terminated":
			return "Ausgesetzt";
		default:
			return value;
	}
};

export const translateCollectionType = (value: CollectionType): string => {
	switch (value) {
		case "CustomEntry":
			return "Freie Eingabe";
		case "CustomersAddress":
			return "Kundenadresse";
		case "DriversAddress":
			return "Fahreradresse";
		case "TraderAddress":
			return "Händleradresse";
		case "CustomerDelivery":
			return "Eigenanlieferung";
		case "UsedCars":
			return "Gebrauchtwagenplatz";
		default:
			return value;
	}
};

export const translateCustomerOrderType = (value: CustomerOrderType): string => {
	switch (value) {
		case "Return":
			return "Abholung";
		case "CustomerDelivery":
			return "Eigenanlieferung";
		case "PricingRequest":
			return "Kaufpreisanfrage";
		default:
			return value;
	}
};

export const translateVehicleServiceStatus = (status: VehicleServiceStatus) =>
	({
		Created: "Erstellt",
		Requested: "Angefragt",
		Rejected: "Abgewiesen",
		Cancelled: "Abgebrochen",
		Assigned: "Zugewiesen",
		Started: "Gestartet",
		Completed: "Fertiggestellt",
		Billed: "Abgerechnet",
	})[status];

export const translateRepairOrderStatus = (status: RepairOrderStatus) =>
	({
		Created: "Erstellt",
		Assignable: "Zuweisbar",
		Assigned: "Zugewiesen",
		Ordered: "Beauftragt",
		CostEstimated: "KVA vorhanden",
		Completed: "Abgeschlossen",
		Cancelled: "Storniert",
	})[status];
