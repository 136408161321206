import moment from 'moment';
import { SyntheticEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { Print } from '@mui/icons-material';
import { Box, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import TextBlock from '../../../Components/Layout/TextBlock';
import PaperTable from '../../../Components/PaperTable';
import dateFormats from '../../../system/dateFormats';
import { VehicleArrival } from '../../../system/Domain';
import { Plate } from "../../../Components/Plate";
import axios from 'axios';
import fileDownload from 'js-file-download';
import parseContentDispositionFilename from 'system/parseContentDispositionFilename';
import ActionButton from 'Components/ActionButton';

const VehicleArrivalsOverviewTable = ( { vehicleArrivals } : { vehicleArrivals : VehicleArrival[] } ) => {
	const history = useHistory();

	const downloadServicesQrCodesFile = async (e: SyntheticEvent, vehicleId: string) => {
		e.stopPropagation();

		const servicesQrCodesFile = await axios.get(`/api/vehicles/${vehicleId}/services/qr-code`, {
			responseType: "blob"
		});

		const filename = parseContentDispositionFilename(servicesQrCodesFile.headers["content-disposition"]);

		fileDownload(servicesQrCodesFile.data, filename);
	};

	const downloadVehicleQrCode = async (e: SyntheticEvent, vehicleId: string) => {
		e.stopPropagation();
		const vehicleQrCodeFile = await axios.get(`/api/vehicles/${vehicleId}/qr-code`, {
			responseType: "blob"
		});

		const filename = parseContentDispositionFilename(vehicleQrCodeFile.headers["content-disposition"]);

		fileDownload(vehicleQrCodeFile.data, filename);
	};

	return (
		<PaperTable>
			<colgroup>
				<col width="auto" />
				<col width="auto" />
				<col width="auto" />
			</colgroup>
			<TableHead>
				<TableRow>
					<TableCell>Fahrzeug</TableCell>
					<TableCell>Erstellt von</TableCell>
					<TableCell>Aktion</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{vehicleArrivals && vehicleArrivals.map(va => (
					<TableRow
						key={va.id}
						hover
						onClick={() => history.push(`/vehicles/arrivals/${va.id}`)}
					>
						<TableCell>
							{va.vehicle &&
								<Box>
									<Plate plateNumber={va.vehicle?.plateNumber} style={{ zoom: 0.6 }} />
									<TextBlock
										primary={va.vehicle?.type?.description}
										secondary={va.vehicle?.vin}
									/>
								</Box>
							}
						</TableCell>
						<TableCell>
							<TextBlock
								primary={va.createdBy.name}
								secondary={moment(va.dateCreated).format(dateFormats.dateTime)}
							/>
						</TableCell>
						<TableCell>
							<Box sx={{ display: "flex", gap: 1.25, flexDirection: 'column' }}>
								<ActionButton
									startIcon={<Print />}
									onClick={(e) => downloadVehicleQrCode(e, va.vehicleId)}
									size='small'
								>
									QR Code drucken
								</ActionButton>
								<ActionButton
									startIcon={<Print />}
									onClick={(e) => downloadServicesQrCodesFile(e, va.vehicleId)}
									size='small'
									disabled={va.changeTiresService !== true && va.unstickService !== true}
								>
									Tätigkeiten drucken
								</ActionButton>
							</Box>
						</TableCell>
					</TableRow>
				))}
			</TableBody>

		</PaperTable>
	);
};

export default VehicleArrivalsOverviewTable;
