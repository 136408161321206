import { AnyAction } from "redux";
import { Notification } from "./Domain";

const initialState : Array<Notification> = [];

export default (state = initialState, action : AnyAction) : Array<Notification> => {
	switch (action.type) {
		case "NOTIFICATION_CHANGED": {
			const index = state.findIndex(o => o.id === action.notification.id);
			if (index >= 0) {
				return [
					...state.slice(0, index),
					action.notification,
					...state.slice(index + 1)
				];
			} else {
				if (action.notification.dateRead) {
					return state
				}
				return [action.notification, ...state];
			}
		}
		case "SET_NOTIFICATIONS":
			return action.notifications;
		default:
			return state;
	}
};
