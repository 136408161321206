import axios from "axios";
import List from "./List";
import Search from "./Search";
import { Vehicle } from "system/Domain";
import VehicleScan from "./VehicleScan";
import { useHistory } from "react-router";
import useHotkeys from "system/useHotkeys";
import { useCallback, useState } from "react";
import { Videocam } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import VehicleListItem from "./VehicleListItem";

export default () => {
	const history = useHistory();
	const [vehicles, setVehicles] = useState<Vehicle[]>();
	const [mode, setMode] = useState<"search" | "scan">("search");

	const search = async (fullText?: string) => {
		const { data } = await axios.post<Vehicle[]>(`/api/vehicles/search`, {
			fullText,
			perspective: "All",
			sort: "lastUpdated:desc",
		});

		setSelectedVehicle(undefined);
		setVehicles(data);
	};

	const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>();
	const moveSelection = useCallback((offset: number) => {
		if (!vehicles) {
			return;
		}

		setSelectedVehicle(v => {
			let index = !v ? 0 : vehicles.indexOf(v) + offset;
			if (index < 0) {
				index = 0;
			} else if (index > vehicles.length - 1) {
				index = vehicles.length - 1;
			}
			return vehicles[index];
		})
	}, [vehicles]);

	useHotkeys("up", () => moveSelection(-1), [moveSelection]);
	useHotkeys("down", () => moveSelection(1), [moveSelection]);
	useHotkeys("enter", () => {
		if (!selectedVehicle) {
			return;
		}
		history.push(`/vehicles/${selectedVehicle.id}/dashboard`);
	}, [selectedVehicle]);

	return (
		<Box p={4}>
			{mode === "search" && (
				<>
					<Box display="flex" flexDirection="row" alignItems="center">
						<Search
							onSearch={search}
							realtime
							autoFocus
							disableEnter
						/>
						<Box pl={2}>
							<IconButton
								onClick={() => setMode("scan")}
							>
								<Videocam />
							</IconButton>
						</Box>
					</Box>
					<List pt={2}>
						{vehicles && vehicles.map(v => (
							<VehicleListItem
								key={v.id}
								vehicle={v}
								selected={v === selectedVehicle}
							/>
						))}
					</List>
				</>
			)}
			{mode === "scan" && (
				<VehicleScan />
			)}
		</Box>
	);
};
