import { useState } from "react";
import Layout from "../../Components/Layout/Layout";
import axios, { AxiosRequestConfig } from "axios";
import useAxiosEffect from "../../system/useAxiosEffect";
import formatNumber from "../../system/formatNumber";
import DashboardKpi, { kpiPlaceholder } from "../../Components/Dashboard/DashboardKpi";
import { setWorkSession } from "../../system/reducers/workSessions/actions";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import Tile from "../../Components/Tiles/Tile";
import TileContent from "../../Components/Tiles/TileContent";
import TileContentActions from "../../Components/Tiles/TileContentActions";
import TileContentAction from "../../Components/Tiles/TileContentAction";
import VehiclesNavigation from "Navigation/VehiclesNavigation";
import useUser from "../../system/useUser";
import {
	KpisGroup,
	UserRole,
	VehicleForSaleKpis,
	VehiclesKpis,
	VehiclesKpisQuery,
	WorkItemType,
	WorkSessionSalePreparationsPerspective,
} from "../../system/Domain";
import VehiclesMovementSidebar from "./VehiclesMovementSidebar";
import moment from "moment";
import { axiosDownload } from "system/axiosDownload";
import LayoutSubTitle from "Components/Layout/LayoutSubTitle";
import PieChart from "Components/PieChart";
import KpisGroupTile from "../../Components/KpisGroupTile";

const VehiclesDashboard = () => {
	const dispatch = useDispatch();
	const [, , hasRole] = useUser();

	const canStartSessions = hasRole(UserRole.AldUser);

	const [presalesKpis, setPresalesKpis] = useState<VehiclesKpis>();

	const loadPresalesKpis = async (axiosConfig: AxiosRequestConfig) => {
		const { data } = await axios.get<VehiclesKpis>(`/api/kpis/vehicles`, axiosConfig);

		setPresalesKpis(data);
	};

	const createPresalesWorkSession = async (perspective: WorkSessionSalePreparationsPerspective, title: string) => {
		const { data: workSession } = await axios.post(`/api/work-sessions/presales`, {
			perspective: perspective,
			title: title,
		});

		if (workSession) {
			dispatch(setWorkSession(workSession));
		}
	};

	useAxiosEffect(loadPresalesKpis, []);

	const [vehiclesForSaleKpis, setVehiclesForSaleKpis] = useState<VehicleForSaleKpis>();

	const loadVehiclesForSaleKpis = async (axiosConfig: AxiosRequestConfig) => {
		const { data } = await axios.get<VehicleForSaleKpis>(`/api/kpis/vehicles/preparation`, axiosConfig);

		setVehiclesForSaleKpis(data);
	};

	const startVehiclesKpisSession = async (group: KpisGroup<VehiclesKpisQuery>) => {
		let workItemType: WorkItemType = "Presale";

		const { data: workSession } = await axios.post(`/api/vehicles/kpis/work-session`, {
			title: group.name,
			workItemType,
			query: group.query,
		});

		if (workSession) {
			dispatch(setWorkSession(workSession));
		}
	};

	const exportVehiclesKpis = async (group: KpisGroup<VehiclesKpisQuery>) => {
		const response = await axios.post(`/api/vehicles/kpis/export`, group.query, {
			responseType: "blob",
		});

		axiosDownload(response, `${moment().format("YYYY-MM-DD")} ${group.name}.xlsx`);
	};

	useAxiosEffect(loadVehiclesForSaleKpis, []);

	return (
		<Layout title="Dashboard" navigation={<VehiclesNavigation />} sidebar={<VehiclesMovementSidebar />}>
			<Box sx={{ display: "flex", gap: "30px", flexDirection: "column" }}>
				<Box sx={{ display: "flex", gap: "30px", flexWrap: "wrap" }}>
					<Box sx={{ minWidth: "270px" }}>
						<Tile title="Eingänge">
							<TileContent dense>
								<Box p={2}>
									{presalesKpis && <DashboardKpi primary={formatNumber(presalesKpis.stock.in)} />}
									{!presalesKpis && kpiPlaceholder}
								</Box>
							</TileContent>
						</Tile>
					</Box>
					<Box sx={{ minWidth: "270px" }}>
						<Tile title="Ausgänge">
							<TileContent dense>
								<Box p={2} pb={5}>
									{presalesKpis && <DashboardKpi primary={formatNumber(presalesKpis.stock.out)} />}
									{!presalesKpis && kpiPlaceholder}
								</Box>
							</TileContent>
						</Tile>
					</Box>
					<Box sx={{ minWidth: "270px" }}>
						<Tile title="Fehlende Fotosätze">
							<TileContent dense>
								<Box p={2}>
									{presalesKpis && (
										<DashboardKpi
											primary={formatNumber(presalesKpis.presales.missingPhotos)}
											secondary={undefined}
										/>
									)}
									{!presalesKpis && kpiPlaceholder}
								</Box>
								{canStartSessions && (
									<TileContentActions align="right">
										<TileContentAction
											disabled={presalesKpis?.presales.missingPhotos === 0}
											onClick={() =>
												createPresalesWorkSession("MissingPictures", "Fehlende Fotosätze")
											}
											label="Überprüfen"
										/>
									</TileContentActions>
								)}
							</TileContent>
						</Tile>
					</Box>
				</Box>
				<Box sx={{ display: "flex", gap: "30px", flexWrap: "wrap" }}>
					<Box>
						<LayoutSubTitle
							label={"Bestand nach Compounds"}
							sx={{ width: "100%", mb: "12px", ml: "5px" }}
						/>
						{presalesKpis && (
							<PieChart
								data={presalesKpis.stock.todayByCompound}
								today={presalesKpis.stock.today}
								lastWeek={presalesKpis.stock.lastWeek}
							/>
						)}
					</Box>
					<Box sx={{ minWidth: "270px" }}>
						{vehiclesForSaleKpis && (
							<KpisGroupTile
								group={vehiclesForSaleKpis.preparation}
								startSession={startVehiclesKpisSession}
								exportKpis={exportVehiclesKpis}
							/>
						)}
					</Box>
				</Box>
			</Box>
		</Layout>
	);
};

export default VehiclesDashboard;
