import { ChangeEvent, useState } from "react";
import Layout from "../../../Components/Layout/Layout";
import { useParams } from "react-router";
import { useAsync } from "react-use";
import axios from "axios";
import { Sale } from "../../../system/Domain";
import { Box, Button, FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import TextBlock from "../../../Components/Layout/TextBlock";
import { Plate } from "../../../Components/Plate";
import SalesOrdersSidebar from "./SalesOrdersSidebar";
import SalesOrderCard from "./SalesOrderCard";
import _ from "lodash";

const SalesOrdersPage = () => {
	const { id } = useParams<{ id: string }>();
	const [sales, setSales] = useState<Sale | null>(null);
	const [showAll, setShowAll] = useState(false);

	useAsync(async () => {
		if (!id) {
			return null;
		}

		const { data: sales } = await axios.get<Sale>(`/api/sales/${id}`);
		setSales(sales);
	}, [id]);

	const currentOrder = _.last(sales?.orders || []);

	const handleShowAll = (event: ChangeEvent<HTMLInputElement>) => {
		setShowAll(event.target.checked);
	};

	const inactiveOrders = currentOrder ? sales!.orders.filter(o => o.id !== currentOrder.id) : [];
	const canMigrate = sales && sales.dateSold && sales.orders.length === 0;

	return (
		<Layout
			title="Übersicht Fahrzeugbestellungen"
			sidebar={(
				<SalesOrdersSidebar sales={sales} />
			)}
		>
			{sales && (
				<Box>
					<TextBlock
						primary={<Plate plateNumber={sales.plateNumber} />}
						secondary={
							<>
								<Box display="inline-block" mr={1}>
									{sales.vin} ∙ {sales.contract.tenant}
								</Box>
							</>
						}
					/>
					{inactiveOrders.length > 0 && (
						<Box mt={2} display="flex" flexDirection="row-reverse">
							<FormControlLabel
								labelPlacement="start"
								control={(
									<Switch
										color="primary"
										checked={showAll}
										onChange={handleShowAll}
									/>
								)}
								label="Alle Bestellungen"
							/>
						</Box>
					)}
					{!currentOrder && (
						<Box mt={2}>
							<Typography>Keine aktiven Bestellungen vorhanden</Typography>
							{canMigrate && (
								<Button
									variant="contained"
								>
									Verkauf aus Leasman rekonstruieren
								</Button>
							)}
						</Box>
					)}
					<Box mt={4}>
						{currentOrder && (
							<Grid container>
								<Grid item xs={6}>
									<SalesOrderCard
										sale={sales}
										order={currentOrder}
									/>
								</Grid>
							</Grid>
						)}
						{showAll && (
							<Box mt={4}>
								<Typography color="textSecondary">Inaktive Bestellungen</Typography>
								<Box mt={2}>
									<Grid container spacing={2}>
										{inactiveOrders.map(order => {
											return (
												<Grid
													key={order.id}
													item
													xs={6}
												>
													<SalesOrderCard order={order} sale={sales} />
												</Grid>
											);
										})}
									</Grid>
								</Box>
							</Box>
						)}
					</Box>
				</Box>
			)}
		</Layout>
	);
};

export default SalesOrdersPage;
