import {
	Build, ControlPointDuplicate, DriveEta, EvStation, LocalCarWash, LocalGasStation, PlaylistAdd,
	Search, Toll, ZoomIn, AddAPhoto, Assignment
} from '@mui/icons-material';

import { VehicleServiceType } from '../../system/Domain';
import { SxProps } from '@mui/system';

interface Props {
	vehicleServiceType: VehicleServiceType
	class?: string,
	sx?: SxProps
}

const VehicleServiceTypeIcon = (props: Props) => {
	let icon;

	switch (props.vehicleServiceType.id) {
		case "Picture": {
			icon = <AddAPhoto sx={props.sx} />;
			break;
		}
		case "LotVehicleHandling": {
			icon = <Assignment sx={props.sx} />;
			break;
		}
		case "AdditionalDeliveryForVehicle": {
			icon = <DriveEta sx={props.sx} />;
			break;
		}
		case "TiresArrival": {
			icon = <ControlPointDuplicate sx={props.sx} />;
			break;
		}
		case "TiresRemove":
		case "ChangeTires": {
			icon = <Toll sx={props.sx} />;
			break;
		}
		case "Preparating": {
			icon = <LocalCarWash sx={props.sx} />;
			break;
		}
		case "SearchInVehicle": {
			icon = <ZoomIn sx={props.sx} />;
			break;
		}
		case "HuAu":
		case "HuAu-Follow-up": {
			icon = <Search sx={props.sx} />;
			break;
		}
		case "RefuelPower": {
			icon = <EvStation sx={props.sx} />;
			break;
		}
		case "RefuelGas": {
			icon = <LocalGasStation sx={props.sx} />;
			break;
		}
		case "LicensePlateMontage": {
			icon = <Build sx={props.sx} />;
			break;
		}
		default: {
			icon = <PlaylistAdd sx={props.sx} />;
		}
	}

	return icon;
};

export default VehicleServiceTypeIcon;
