import moment from 'moment';
import { ChangeEvent, Dispatch, SetStateAction, SyntheticEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { Block, Check } from '@mui/icons-material';
import {
	Box, Checkbox, CircularProgress, TableBody, TableHead, TableRow, Tooltip, Typography, useTheme
} from '@mui/material';
import Chip from '../../../Components/Chip';
import TextBlock from '../../../Components/Layout/TextBlock';
import PaperTable from '../../../Components/PaperTable';
import { Plate } from '../../../Components/Plate';
import dateFormats from '../../../system/dateFormats';
import {
	CarType, Compound, CompoundReference, DeliveryType, ReturnOrder, ReturnOrdersOverviewPerspective, ReturnOrderTireType
} from '../../../system/Domain';
import {
	translateCollectionType, translateReturnOrderDeliveryType
} from '../../../system/translate';
import { getEnumName } from '../../../utils';
import ReturnOrderStatusPill from './ReturnOrderStatusPill';
import { joinDisplay } from './ReturnOrderUtils';
import { Colgroup, TableCell } from 'Components/BreakpointStyledComponents';

interface CommonProps {
	returnOrders: ReturnOrder[]
	isLoading?: boolean
	noRowsMessage?: string
	align?: boolean
	compounds?: Compound[];
}

type uncheckableProps = {
	checkable?: false
	perspective?: never
	checked?: never
	setChecked?: never
}

type checkableProps = {
	checkable: true
	perspective: ReturnOrdersOverviewPerspective
	checked: string[]
	setChecked: Dispatch<SetStateAction<string[]>>
}

type SpecificProps = uncheckableProps | checkableProps;

export enum AssessmentLocation {
	CollectionAddress = "Abholadresse",
	DeliveryAddress = "Zustelladresse",
	RkvAddress = "RKV-Geberadresse"
}

export enum AssessmentType {
	Minderwertsgutachten = "Minderwertsgutachten",
	Restwertgutachten = "Restwertgutachten"
}

const ReturnOrdersOverviewTable = (props: CommonProps & SpecificProps) => {
	const theme = useTheme();
	const history = useHistory();

	const assessmentPill = (returnOrder: ReturnOrder) => {
		if (!returnOrder.doAssessment) {
			return <Typography component="div">Nicht beauftragt</Typography>
		}

		return (
			<>
				<TextBlock
					primary={getEnumName(AssessmentType, returnOrder.assessment?.assessmentType ?? "")}
					secondary={getEnumName(AssessmentLocation, returnOrder.assessment?.assessmentLocation ?? "")}
				/>
				{returnOrder.assessmentIsPreprocessed && (
					<Box mt={2}>
						<Chip label="Beauftragt" />
					</Box>
				)}
			</>
		);
	};

	const compoundPill = (compoundRef: CompoundReference) => {
		const compound = props.compounds?.find( c => c.name === compoundRef.name);

		if (!compound) {
			return <></>;
		}

		return (
			<Tooltip title={
				<Typography component="div">
					{compound.address.name}<br />
					{compound.address.zipCode} - {compound.address.city} - {compound.address.street}
				</Typography>}>
				<Box sx={{ width: "fit-content" }}>
					<TextBlock
						chip={{
							label: compound?.name,
							color: "default",
						}}
					/>
				</Box>
			</Tooltip>
		);
	}

	const collectionPill = (returnOrder: ReturnOrder) => {
		const differentCountry = returnOrder.collection.address.country !== "Deutschland";

		return (
			<>
				<TextBlock
					primary={translateCollectionType(returnOrder.collection.collectionType)}
					secondary={
						<>
							{(returnOrder.collection?.collectionType === "UsedCars" && props.compounds && returnOrder.collection.compound) ?
								compoundPill(returnOrder.collection.compound)
								:
								<Typography component="div">
									{returnOrder.collection.address.zipCode} - {returnOrder.collection.address.city} - {returnOrder.collection.address.street}
									{differentCountry && (
										<>
											&nbsp;-&nbsp;
											<Box display="inline" color={theme.palette.secondary.light}>
												{returnOrder.collection.address.country}
											</Box>
										</>
									)}
								</Typography>}
						</>}
					tertiary={moment(returnOrder.collectionReferenceDate).format(dateFormats.date)}
				/>
				<Box mt={1}>
					<TextBlock
						primary={returnOrder.leasmanContract?.customer.name1}
					/>
				</Box>
				<Box fontStyle="italic">
					{joinDisplay([getEnumName(ReturnOrderTireType, returnOrder.tireType) as string, (returnOrder.carType === CarType.Van || returnOrder.carType === CarType.SUV) ? getEnumName(CarType, returnOrder.carType ?? "unknown") as string : ""])}
				</Box>
				{returnOrder.collection.internalComment && (
					<Box mt={1} color={theme.palette.secondary.light}>
						<TextBlock
							primary={undefined}
							secondary={returnOrder.collection.internalComment}
						/>
					</Box>
				)}
			</>
		);
	};

	const deliveryPill = (returnOrder: ReturnOrder) => {
		if (!returnOrder.delivery) {
			return <></>;
		}

		return (
			<TextBlock
				primary={translateReturnOrderDeliveryType(returnOrder.delivery.deliveryType)}
				secondary={returnOrder.delivery.deliveryType === DeliveryType.UsedCars && props.compounds && returnOrder.delivery.compound &&
					compoundPill(returnOrder.delivery.compound)}
				tertiary={returnOrder.delivery.appointment?.notBefore ?
					moment(returnOrder.delivery.appointment.notBefore).format(dateFormats.date)
					: undefined
				}
			/>
		);
	};

	const unregistrationPill = (returnOrder: ReturnOrder) => {
		return (
			!returnOrder.doUnregistration ?
				<Typography component="div">Nicht beauftragt</Typography>
				:
				<Typography component="div">Beauftragt</Typography>
		);
	};

	const isDrivable = (returnOrder: ReturnOrder) => {
		return (
			returnOrder.isDrivable ? (
				<Check />
			) : (
				<Block />
			)
		);
	};

	const isRegistered = (returnOrder: ReturnOrder) => {
		return (
			returnOrder.isRegistered ? (
				<Check />
			) : (
				<Block />
			)
		);
	};

	const handleCheck = (e: SyntheticEvent, returnOrderId: string) => {
		e.stopPropagation();

		if (props.checked.includes(returnOrderId)) {
			const newChecked = props.checked.filter(f => f !== returnOrderId);

			props.setChecked(newChecked);
		} else {
			const newChecked = [...props.checked];

			newChecked.push(returnOrderId);

			props.setChecked(newChecked);
		}
	};

	const handleAllCheck = (e: ChangeEvent<HTMLInputElement>) => {
		const checked = e.target.checked;

		if (checked) {
			props.setChecked(props.returnOrders.map(m => m.id));
		} else {
			props.setChecked([]);
		}
	}

	const showCheckboxColumn = props.perspective === "Unprocessed" || props.perspective === "UnprocessedExternalAssessments";
	const colSpan = 6 + (showCheckboxColumn ? 1 : 0);

	const displaySpinner = () => {
		if (props.isLoading === true) {
			return (
				<TableRow>
					<TableCell
						colSpan={colSpan}
						sx={{ textAlign: "center" }}
					>
						<CircularProgress />
					</TableCell>
				</TableRow>
			);
		}
		return <></>;
	}

	const displayNoRows = () => {
		if (props.returnOrders.length === 0 && !props.isLoading) {
			return (
				<TableRow>
					<TableCell
						colSpan={colSpan}
						sx={{ textAlign: "center" }}
					>
						{props.noRowsMessage}
					</TableCell>
				</TableRow>
			);
		}
		return <></>;
	}

	if (props.align) {
		return (
			<PaperTable>
				<TableHead>
					<TableRow>
						<TableCell xs sm>Rückholung</TableCell>
						<TableCell md lg xl sx={{width: {md: "30%", lg: "20%", xl: "20%"}}}>Fahrzeug</TableCell>
						<TableCell xl sx={{width:"10%"}}>Status</TableCell>
						<TableCell lg xl sx={{width:{lg: "25%", xl: "20%"}}}>Abholung</TableCell>
						<TableCell md lg xl sx={{width: {md: "25%", lg: "20%", xl: "15%"}}}>Anlieferung</TableCell>
						<TableCell md lg xl sx={{width: {md: "25%", lg: "15%", xl: "15%"}}}>Gutachten</TableCell>
						<TableCell md lg xl>Abmeldung</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.returnOrders && props.returnOrders.map(returnOrder => (
						<TableRow
							key={returnOrder.id}
							hover
							onClick={() => history.push(`/orders/returns/${returnOrder.id}`)}
						>
							<TableCell>
								<Plate plateNumber={returnOrder.plateNumber} style={{ zoom: 0.6 }} />
								<TextBlock
									secondary={returnOrder.vin}
									compact
								/>
								<Box sx={{ display: { xs: "contents", xl: "none" } }} mt={2}>
									<ReturnOrderStatusPill returnOrder={returnOrder} />
								</Box>
								<Box sx={{ display: { xs: "contents", lg: "none" } }}>
									<Box mt={2}>Abholung: {collectionPill(returnOrder)}</Box>
								</Box>
								<Box sx={{ display: { xs: "contents", md: "none" } }}>
									<Box mt={2}>Anlieferung: {deliveryPill(returnOrder)}</Box>
									<Box mt={2}>Gutachten: {assessmentPill(returnOrder)}</Box>
									<Box mt={2}>Abmeldung: {unregistrationPill(returnOrder)}</Box>
								</Box>
							</TableCell>
							<TableCell xl>
								<Box mt={2}>
									<ReturnOrderStatusPill returnOrder={returnOrder} />
								</Box>
							</TableCell>
							<TableCell lg xl>{collectionPill(returnOrder)}</TableCell>
							<TableCell md lg xl>{deliveryPill(returnOrder)}</TableCell>
							<TableCell md lg xl>{assessmentPill(returnOrder)}</TableCell>
							<TableCell md lg xl>{unregistrationPill(returnOrder)}</TableCell>
						</TableRow>
					))}
					{displaySpinner()}
					{displayNoRows()}
				</TableBody>
			</PaperTable>
		);
	}

	return (
		<PaperTable>
			<Colgroup sm md lg xl>
				{showCheckboxColumn && (
					<col width="auto" />
				)}
				<col width="auto" />
				<col width="auto" />
				<col width="auto" />
				<col width="auto" />
				<col width="auto" />
			</Colgroup>
			<TableHead>
				<TableRow>
					{showCheckboxColumn && (
						<TableCell>
							<Checkbox
								checked={props.returnOrders.length === props.checked.length}
								onChange={(e) => handleAllCheck(e)}
							/>
							<Box width="100px">
								{props.checked.length} / {props.returnOrders.length}
							</Box>
						</TableCell>
					)}
					<TableCell xs sm>Rückholung</TableCell>
					<TableCell md lg xl>Fahrzeug</TableCell>
					<TableCell md lg xl>Fahrbereit/<br />Zugelassen</TableCell>
					<TableCell xl>Abholung</TableCell>
					<TableCell xl>Anlieferung</TableCell>
					<TableCell lg xl>Gutachten</TableCell>
					<TableCell lg xl>Abmeldung</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{props.returnOrders && props.returnOrders.map(returnOrder => (
					<TableRow
						key={returnOrder.id}
						hover
						onClick={() => history.push(`/orders/returns/${returnOrder.id}`)}
					>
						{showCheckboxColumn && (
							<TableCell
								onClick={(e) => handleCheck(e, returnOrder.id)}
							>
								<Checkbox
									checked={props.checked.includes(returnOrder.id)}
									onChange={(e) => handleCheck(e, returnOrder.id)}
								/>
							</TableCell>
						)}
						<TableCell>
							<Plate plateNumber={returnOrder.plateNumber} style={{ zoom: 0.6 }} />
							<TextBlock
								primary={returnOrder.vehicle.type?.description}
								secondary={returnOrder.vin}
								compact
							/>
							<Box mt={2}>
								<ReturnOrderStatusPill returnOrder={returnOrder} />
							</Box>
							<Box sx={{ display: { xs: "contents", md: "none" } }}>
								<Box mt={2}>Fahrbereit: &nbsp;
									{isDrivable(returnOrder)}
									<br />
									Zugelassen: &nbsp;
									{isRegistered(returnOrder)}
								</Box>
							</Box>
							<Box sx={{ display: { xs: "contents", xl: "none" } }}>
								<Box mt={2}>Abholung: {collectionPill(returnOrder)}</Box>
								<Box mt={2}>Anlieferung: {deliveryPill(returnOrder)}</Box>
							</Box>
							<Box sx={{ display: { xs: "contents", lg: "none" } }}>
								<Box mt={2}>Gutachten: {assessmentPill(returnOrder)}</Box>
								<Box mt={2}>Abmeldung: {unregistrationPill(returnOrder)}</Box>
							</Box>
						</TableCell>
						<TableCell md lg xl>
							<Box>
								{isDrivable(returnOrder)}
								{isRegistered(returnOrder)}
							</Box>
						</TableCell>
						<TableCell xl>{collectionPill(returnOrder)}</TableCell>
						<TableCell xl>{deliveryPill(returnOrder)}</TableCell>
						<TableCell lg xl>{assessmentPill(returnOrder)}</TableCell>
						<TableCell lg xl>{unregistrationPill(returnOrder)}</TableCell>
					</TableRow>
				))}
				{displaySpinner()}
				{displayNoRows()}
			</TableBody>
		</PaperTable>
	);
};

export default ReturnOrdersOverviewTable;
