import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";
import ThemedDialog from "../../../Components/ThemedDialog";
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

interface Props {
	title: string
	message: string
	buttonLabel: string
}

const CustomerPricingRequestConfirmationDialog = (props: ReactConfirmProps<boolean> & Props) => {
	return (
		<ThemedDialog open={props.show} maxWidth="sm" fullWidth onClose={() => props.proceed(false)}>
			<DialogTitle>{props.title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{props.message}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					color="secondary"
					onClick={() => props.proceed(false)}
				>
					Abbrechen
				</Button>
				<Button
					color="primary"
					onClick={() => props.proceed(true)}
				>
					{props.buttonLabel}
				</Button>
			</DialogActions>
		</ThemedDialog>
	)
};

export default (inputProps: Props) => {
	const dialog = confirmable(props => <CustomerPricingRequestConfirmationDialog {...props} {...inputProps} />);
	return createConfirmation<boolean>(dialog)();
}
