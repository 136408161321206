import moment from "moment";
import dateFormats from "./dateFormats";

export default (date? : string | null) : string => {
	if (!date) {
		return "";
	}

	return moment(date).format(dateFormats.dateTime);
}
