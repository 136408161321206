import { Box, Grid } from "@mui/material";
import axios from "axios";
import ActionButton from "Components/ActionButton";
import FormChoices from "Components/FormChoices";
import FormYesNo from "Components/FormYesNo";
import AdminNavigation from "Navigation/AdminNavigation";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { settingsAtom } from "system/atoms/settings";
import { UserSettings } from "system/Domain";
import useAction from "system/useAction";
import useForm from "system/useForm";
import Layout from "../../Components/Layout/Layout";

export default () => {
	const [settings, setSettings] = useRecoilState(settingsAtom);

	const [values, setValues] = useState<UserSettings>({ ...settings });
	const form = useForm({
		values,
		setValues,
	});

	const [saveSettings, isSaving] = useAction(async () => {
		const { data: newSettings } = await axios.put<UserSettings>(`/api/users/me/settings`, values);
		setSettings(newSettings);
	});

	useEffect(() => {
		setValues({ ...settings });
	}, [settings]);

	return (
		<Layout title="Einstellungen" navigation={<AdminNavigation />}>
			<Grid container spacing={3}>
				<FormYesNo form={form} label="Menü anzeigen" name="menuOpen" />
				<FormChoices
					form={form}
					label="Orientierung der Navigation"
					name="navigationOrientation"
					choices={{ horizontal: "Horizontal", vertical: "Vertikal" }}
				/>
				<FormChoices form={form} label="Farbpalette" name="theme" choices={{ dark: "Dunkel", light: "Hell" }} />
				<Grid item xs={12}>
					<Box mt={4}>
						<ActionButton color="primary" variant="outlined" disabled={isSaving} onClick={saveSettings}>
							Speichern
						</ActionButton>
					</Box>
				</Grid>
			</Grid>
		</Layout>
	);
};
