import axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";
import { useState } from "react";
import { useHistory } from "react-router";

import { Add, AttachFile, Gavel, GetApp, ListAlt, Refresh } from "@mui/icons-material";
import { FormControl } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import Sidebar from "../../Components/Sidebar/Sidebar";
import SidebarButton from "../../Components/Sidebar/SidebarButton";
import SidebarForm from "../../Components/Sidebar/SidebarForm";
import SidebarGroup from "../../Components/Sidebar/SidebarGroup";
import WebmobileIndicator from "../../Components/WebmobileIndicator";
import { UserRole } from "../../system/Domain";
import parseContentDispositionFilename from "../../system/parseContentDispositionFilename";
import useAction from "../../system/useAction";
import useUser from "../../system/useUser";
import DadExportSidebarForm from "./DadExportSidebarForm";
import uploadEkzgv from "./uploadEkzgv";

const SalesSidebar = () => {
	const history = useHistory();
	const [, , hasRole] = useUser();
	const [commissionDate, setCommissionDate] = useState<string>(moment().subtract(1, "month").toISOString(true));
	const [carmarketPreparationDate, setCarmarketPreparationDate] = useState<string>(
		moment().startOf("day").toISOString(true),
	);

	const [exportingCarmarketPreparation, setExportCarmarketPreparation] = useState<boolean>(false);

	const downloadCarmarketInstantAuctionExport = async () => {
		try {
			setExportCarmarketPreparation(true);

			const response = await axios.get(`/api/sales/carmarket-preparation`, {
				responseType: "blob",
				params: {
					date: carmarketPreparationDate,
				},
			});

			const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
			fileDownload(response.data, filename);
		} finally {
			setExportCarmarketPreparation(false);
		}
	};

	const downloadRetailPreparationExport = async () => {
		const response = await axios.get(`/api/sales/retail-preparation`, {
			responseType: "blob",
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);
	};

	const downloadCommissionsExport = async () => {
		const response = await axios.get(`/api/sales/commissions`, {
			responseType: "blob",
			params: {
				date: commissionDate,
			},
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);
	};

	const downloadKycExport = async () => {
		const response = await axios.get(`/api/sales/kyc`, {
			responseType: "blob",
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);
	};

	const downloadYearlySalesExport = async () => {
		const response = await axios.get(`/api/sales/yearly-reporting`, {
			responseType: "blob",
		});

		const fileName = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, fileName);
	};

	const [syncKyc, isSyncingKyc] = useAction(async () => {
		await axios.post("/api/sales/kyc/sync");
	});

	const isSalesUser = hasRole(UserRole.SalesUser);
	const isSalesAdmin = hasRole(UserRole.SalesAdmin);
	const isSalesBackofficeUser = hasRole(UserRole.SalesBackoffice);

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarButton
					color="primary"
					startIcon={<Add />}
					label="Neue Kaufpreisanfrage"
					onClick={() => history.push(`/sales/pricing-requests/new`)}
				/>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarButton
					startIcon={<AttachFile />}
					label="Upload EKZGV"
					disabled={!isSalesUser}
					onClick={async () => await uploadEkzgv()}
				/>
				<SidebarButton
					startIcon={<GetApp />}
					label="Retail Aufbereitung"
					disabled={!isSalesUser}
					onClick={downloadRetailPreparationExport}
				/>
				<SidebarButton
					startIcon={<GetApp />}
					label="Verkaufsreporting"
					disabled={!isSalesAdmin && !isSalesBackofficeUser}
					onClick={downloadYearlySalesExport}
				/>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarForm>
					<FormControl fullWidth>
						<DatePicker
							format="DD.MM.YYYY"
							label=""
							value={moment(carmarketPreparationDate)}
							slotProps={{ textField: { variant: "standard" } }}
							onChange={(d: any) =>
								setCarmarketPreparationDate((moment(d) || moment()).startOf("day").toISOString(true))
							}
						/>
					</FormControl>
				</SidebarForm>
				<SidebarButton
					startIcon={<Gavel />}
					label="Carmarket Auktionsvorbereitung"
					disabled={!isSalesUser || exportingCarmarketPreparation}
					onClick={downloadCarmarketInstantAuctionExport}
				/>
			</SidebarGroup>
			{isSalesBackofficeUser && (
				<SidebarGroup>
					<SidebarForm>
						<FormControl fullWidth>
							<DatePicker
								format="MM.YYYY"
								label="Abrechnungsmonat"
								openTo="year"
								views={["year", "month"]}
								value={moment(commissionDate)}
								slotProps={{ textField: { variant: "standard" } }}
								onChange={(d: any) =>
									setCommissionDate((moment(d) || moment()).startOf("m").toISOString(true))
								}
							/>
						</FormControl>
					</SidebarForm>
					<SidebarButton
						startIcon={<ListAlt />}
						label="Provisionen"
						disabled={!isSalesBackofficeUser}
						onClick={downloadCommissionsExport}
					/>
				</SidebarGroup>
			)}
			<SidebarGroup>
				<SidebarButton
					startIcon={<AttachFile />}
					label="KYC Prüfung"
					disabled={!isSalesUser}
					onClick={downloadKycExport}
				/>
				<SidebarButton
					startIcon={<Refresh />}
					label="KYC Abgleich"
					disabled={isSyncingKyc || !isSalesUser}
					onClick={syncKyc}
				/>
			</SidebarGroup>
			<SidebarGroup>
				<WebmobileIndicator />
			</SidebarGroup>
			<SidebarGroup>
				<DadExportSidebarForm />
			</SidebarGroup>
		</Sidebar>
	);
};

export default SalesSidebar;
