import { PropTypes } from "@mui/material";
import { VehicleHistoryEntry, Vehicle } from "../../system/Domain";
import TextBlock from "../../Components/Layout/TextBlock";
import dateFormats from "../../system/dateFormats";
import moment from "moment";
import { useHistory } from "react-router";

export interface VehicleStatusProps {
	vehicle : Vehicle
	historyEntry? : VehicleHistoryEntry
	showDate? : boolean
	showStatusChangedDate? : boolean
	defaultColor? : Exclude<PropTypes.Color, 'inherit'>
	strikethrough? : boolean
}

// const getDate = (history : VehicleHistory, entry : VehicleHistoryEntry) => {
// 	const historyIndex = history.entries.indexOf(entry);
// 	for (let i = historyIndex; i >= 0; i--) {
// 		const e = history.entries[i];
// 		if (e.statusChanged) {
// 			return moment(e.date).format(dateFormats.dateTime);
// 		}
// 	}
// 	return undefined;
// }

export default ({ vehicle, historyEntry, showDate = false, showStatusChangedDate = false, defaultColor = "default", strikethrough = false } : VehicleStatusProps) => {
	const history = useHistory();

	if (!historyEntry && vehicle.history.entries.length > 0) {
		historyEntry = vehicle.history.entries[vehicle.history.entries.length - 1];
	}

	if (!historyEntry) {
		return null;
	}

	let date = undefined;
	if (showDate) {
		date = moment(historyEntry.date).format(dateFormats.dateTime);
	} else if (showStatusChangedDate) {
		const historyIndex = vehicle.history.entries.indexOf(historyEntry);
		for (let i = historyIndex; i >= 0; i--) {
			const e = vehicle.history.entries[i];
			if (e.statusChanged) {
				date = moment(e.date).format(dateFormats.dateTime);
				break;
			}
		}
	}

	let color = defaultColor;
	switch (historyEntry.processStep.status) {
		case "Eingang":
			color = "primary";
			break;
		case "Ausgang":
			color = "secondary";
			break;
	}

	let clickHandler;

	if (historyEntry.processStep.status === "Ausliefern") {
		clickHandler = () => {
			if (vehicle.remarketing.saleId) {
				history.push(`/vehicles/ship?saleId=${vehicle.remarketing.saleId}`);
			}
		}
	}

	if (historyEntry.processStep.status === "Ausgang") {
		clickHandler = () => {
			if (vehicle.remarketing.saleId) {
				history.push(`/vehicles/${vehicle.id}/after-shipping`);
			}
		}
	}
	
	return (
		<TextBlock
			chip={{
				label: historyEntry.processStep.displayName,
				color: color,
				strikethrough: strikethrough,
				clickable: clickHandler !== undefined,
				onClick: clickHandler
			}}
			secondary={date}
		/>
	);
}
