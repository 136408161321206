import axios from 'axios';
import moment from 'moment';
import { useParams } from 'react-router';
import { useAsync } from 'react-use';

import { AttachFile } from '@mui/icons-material';
import { Box, Link, List, ListItem, Paper, Typography } from '@mui/material';

import Layout from '../../../Components/Layout/Layout';
import handleDownload from '../../../system/handleDownload';

interface SaleOrderEmailParams {
	id : string
	orderId : string
	messageId : string
}

export interface EmailAttachment {
	hash: string
	filename: string
}

export interface Email {
	to: string
	cc: string
	subject: string
	info: {
		arrivedAt: string
		status: string
	}
	attachments: EmailAttachment[]
	html: string
}

const SaleOrderEmailPage = () => {
	const params = useParams<SaleOrderEmailParams>();

	const { loading, value: email } = useAsync(async () => {
		const { id, orderId, messageId } = params;

		const { data } = await axios.get<Email>(`/api/sales/${id}/orders/${orderId}/emails/${messageId}`);
		return data;
	}, [params]);

	return (
		<Layout
			loading={loading}
			title="Email"
		>
			{email && (
				<Paper>
					<Box m={2}>
						<Box display="flex" justifyContent="space-between">
							<Box>
								<Typography variant="h6">To</Typography>
								<Typography variant="h6">{email.to}</Typography>
							</Box>
							<Box>
								<Typography component="div" variant="caption">{moment(email.info.arrivedAt).format("LLLL")}</Typography>
								<Typography component="div" variant="caption">{email.info.status}</Typography>
							</Box>
						</Box>
						{email.cc && (
							<Box mt={2}>
								<Typography>CC</Typography>
								<Typography>{email.cc}</Typography>
							</Box>
						)}
						<Box mt={3}>
							<Typography variant="h5" style={{ fontWeight: 700 }}>{email.subject}</Typography>
						</Box>
						<Box mt={2} mb={1}>
							<List style={{ display: "inline-flex" }}>
								{email.attachments.map((a : any) => {
									return (
										<ListItem component="a">
											<AttachFile fontSize="small"/>
											<Link onClick={handleDownload(`/api/sales/${params.id}/documents/${a.hash}`)}>{a.filename}</Link>
										</ListItem>
									);
								})}
							</List>
						</Box>
						<Box
							padding={2}
							bgcolor="white"
							color="black"
							dangerouslySetInnerHTML={{ __html: email.html }}
						/>
					</Box>
				</Paper>
			)}
		</Layout>
	);
};

export default SaleOrderEmailPage;
