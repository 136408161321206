import { Box, Typography, useTheme } from "@mui/material";


interface KpiProps {
	title: string,
	value: number,
	isSelected?: boolean
	onClick?: () => void
}

export default ({ title, value, isSelected = false, onClick }: KpiProps) => {
	const theme = useTheme();
	return (
		<Box
			p={2}
			width="12em"
			height="10em"
			display="flex"
			flexDirection="column"
			alignItems="center"
			textAlign="center"
			sx={{
				...(isSelected && {
					border: "2px solid",
					borderColor: theme.palette.primary.main
				})
			}}
			onClick={onClick}
		>
			<Typography
				variant="h2"
				component="div"
				style={{ fontWeight: 700 }}
			>
				{value}
			</Typography>
			<Typography
				variant="caption"
				component="div"
				color="textSecondary"
			>
				{title}
			</Typography>
		</Box>
	)
}
