import axios from 'axios';
import { ChangeEvent, useState } from 'react';
import { useAsync } from 'react-use';
import { Block, Check } from '@mui/icons-material';
import { Chip, TableBody, TableHead, TableRow } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextBlock from '../../../Components/Layout/TextBlock';
import PaperTable from '../../../Components/PaperTable';
import { Import, TransportOrder } from '../../../system/Domain';
import useLogisticCompanies from '../../../system/useLogisticCompanies';
import TransportDirection from './TransportDirection';
import { TableCell } from "../../../Components/BreakpointStyledComponents";

export default ({ imp }: { imp: Import }) => {
	const [companies] = useLogisticCompanies();
	const [orders, setOrders] = useState<TransportOrder[]>([]);
	const [orderToUpdate, setOrderToUpdate] = useState<TransportOrder | null>(null);

	useAsync(async () => {
		const { data: orders } = await axios.get<TransportOrder[]>(`/api/imports/${imp.id}/transport-orders`);
		setOrders(orders)
	}, [imp]);

	if (orders.length === 0) {
		return null;
	}

	return (
		<PaperTable>
			<colgroup>
				<col width="auto" />
				<col width="auto" />
				<col width="auto" />
				<col width="70%" />
				<col width="auto" />
				<col width="30%" />
			</colgroup>
			<TableHead>
				<TableRow>
					<TableCell>Status</TableCell>
					<TableCell>Fahrbereit/<br />Zugelassen</TableCell>
					<TableCell>Fahrzeug</TableCell>
					<TableCell md lg xl>Kunde</TableCell>
					<TableCell>Transport</TableCell>
					<TableCell>Logistiker</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{orders.map(o => {

					const handleCompanyChanged = async (event: ChangeEvent<any>) => {
						const companyName = event.target.value;
						const company = companies?.find(c => c.name === companyName);

						if (orderToUpdate !== null) return;

						setOrderToUpdate(o);
						const { data: order } = await axios.put<TransportOrder>(`/api/orders/transports/${o.id}`, {
							...o,
							assignedTo: !company ? null : {
								name: company.name
							}
						});

						setOrders(orders => {
							const index = orders.findIndex(order => order.id === o.id);
							if (index >= 0) {
								return [
									...orders.slice(0, index),
									order,
									...orders.slice(index + 1),
								]
							}
							return orders;
						});

						setOrderToUpdate(null);
					};

					return (
						<TableRow key={o.id}>
							<TableCell>
								<Chip
									label={o.orderRef ? "Änderung" : "Neu"}
								/>
							</TableCell>
							<TableCell>
								{o.isVehicleReadyForDriving ? (
									<Check />
								) : (
									<Block />
								)}
								{o.isRegistered ? (
									<Check />
								) : (
									<Block />
								)}
							</TableCell>
							<TableCell>
								<TextBlock
									primary={o.plateNumber}
									secondary={
										<span style={{ whiteSpace: "nowrap" }}>
											{o.businessLine} | {o.leasmanContract?.id}
										</span>
									}
									tertiary={o.vehicle.type?.description}
								/>
							</TableCell>
							<TableCell md lg xl>
								<TextBlock
									primary={o.leasmanContract?.customer.name1}
									secondary={o.pickupAddress.name}
									tertiary={`${o.pickupAddress.street} - ${o.pickupAddress.city}`}
								/>
							</TableCell>
							<TableCell>
								<TransportDirection
									from={o.pickupAddress.zipCode}
									to={o.destinationAddress.zipCode}
								/>
							</TableCell>
							<TableCell>
								<Select
									fullWidth
									readOnly={orderToUpdate === o}
									value={o.assignedTo?.name || ""}
									onChange={handleCompanyChanged}
								>
									{companies && companies.filter(c => c.transports?.active === true).map(c => (
										<MenuItem key={c.name} value={c.name}>{c.name}</MenuItem>
									))}
								</Select>
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		</PaperTable>
	);
};
