import { Box, Typography } from "@mui/material";


export interface Props {
	primary : JSX.Element | string
	secondary? : JSX.Element | string
}

const DashboardKpi = ({ primary, secondary } : Props) => {
	return (
		<Box flexDirection="column" display="flex" alignItems="end">
			<Typography variant="h2">
				{primary}
			</Typography>
			{secondary && (
				<Box mt={0.5}>
					<Typography variant="subtitle2" color="textSecondary">
						{secondary}
					</Typography>
				</Box>
			)}
		</Box>
	);
};

export const kpiPlaceholder = <DashboardKpi primary="-" secondary="-" />;

export default DashboardKpi;
