import { useState } from "react";
import { TabPanel, TabPanelProps } from "@mui/lab";
import { Box, List, Paper, Typography } from "@mui/material";
import AversiSessionListItem from "./AversiSessionListItem";
import { AversiSession } from "./AversiSessionsPage";
import _ from "lodash";
import { useHistory } from "react-router";
import Actions from "../../Components/Actions";
import Filter from "./Filter";

interface Props extends TabPanelProps {
	aversiSession : AversiSession
}

const filters = {
	all: "Alle",
	errors: "Nur fehlerhafte",
	itemsMissing: "Daten fehlen",
	unpaid: "Nur unbezahlte"
};

const AversiSessionAttachmentsTab = ({ aversiSession, ...rest } : Props) => {
	const history = useHistory();
	const [filter, setFilter] = useState("all");

	const gotoDetails = (itemId : string) => {
		history.push(`/aversi/sessions/${aversiSession!.id}/details/${itemId}`);
	};

	const handleFilterChange = (filter : string) => {
		setFilter(filter);
	};

	let items;
	switch (filter) {
		case "unpaid":
			items = aversiSession.attachments.filter(a => !a.paymentDate);
			break;
		case "itemsMissing":
			items = aversiSession
				.attachments
				.filter(a => {
					const item = aversiSession.reconciliations.find(r => r.id === a.id);
					return item && item.status === "MissingItem";
				});
			break;
		case "errors":
			items = aversiSession
				.attachments
				.filter(a => {
					const item = aversiSession.reconciliations.find(r => r.id === a.id);
					return !item || item.status !== "Ok";
				});
			break;
		default:
			items = aversiSession.attachments;
	}

	const attachments = _.groupBy(items, "tenantName");
	const hasAttachments = Object.keys(attachments).length > 0;

	return (
		<TabPanel {...rest}>
			<>
				{hasAttachments && (
					<Actions>
						<Filter values={filters} selected={filter} onChange={handleFilterChange}/>
					</Actions>
				)}

				{!hasAttachments && (
					<Typography>Keine Daten gefunden</Typography>
				)}

				{Object.keys(attachments).map(d => {
					return (
						<div key={d}>
							<Typography>{d}</Typography>
							<Box my={2}>
								<Paper>
									<List disablePadding>
										{attachments[d].map(i => {
											return (
												<AversiSessionListItem
													key={i.id}
													item={i}
													reconciliationItem={aversiSession.reconciliations.find(r => r.id === i.id)}
													onClick={() => gotoDetails(i.id)}
												/>
											)
										})}
									</List>
								</Paper>
							</Box>
						</div>
					)
				})}
			</>
		</TabPanel>
	)
};

export default AversiSessionAttachmentsTab;
