import { useHistory } from 'react-router';
import { Dispatch, SetStateAction } from 'react';
import PaperTable from "../../../Components/PaperTable";
import { TransportPricing } from "../../../system/Domain";
import TextBlock from "../../../Components/Layout/TextBlock";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import moment from "moment";
import dateFormats from "system/dateFormats";

interface Props {
	transportPricing: TransportPricing[]
	setTransportPricing: Dispatch<SetStateAction<TransportPricing[] | undefined>>
}

const TransportPricingsTable = (props: Props) => {
	const history = useHistory();

	return (
		<PaperTable>
			<colgroup>
				<col width="auto" />
				<col width="auto" />
				<col width="auto" />
			</colgroup>
			<TableHead>
				<TableRow>
					<TableCell>Name</TableCell>
					<TableCell>Anzahl Gebiete</TableCell>
					<TableCell>Zuletzt angepasst</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{props.transportPricing && props.transportPricing.map(tp => (
					<TableRow
						key={tp.company.name}
						hover
						onClick={() => history.push(`/transport-pricing/${tp.company.name}`)}
					>
						<TableCell>{tp.company.name}</TableCell>
						<TableCell>{tp.areasPricing.length}</TableCell>
						<TableCell>
							<TextBlock
								primary={tp.dateUpdated ? moment(tp.dateUpdated).format(dateFormats.dateTime) : 'niemals'}
								secondary={`${tp.updatedBy?.name ?? ''}`}
							/>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</PaperTable>
	);
};

export default TransportPricingsTable;
