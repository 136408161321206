import { Dispatch, SetStateAction } from "react";
import useAsyncEffect from "./useAsyncEffect";
import axios from "axios";
import { useParams } from "react-router-dom";
import { TransportOrder } from "./Domain";
import useOrder, { OrderType } from "./useOrder";

export default function () : [OrderType | null, Dispatch<SetStateAction<TransportOrder | null>>] {
	const { id } = useParams<{ id : string }>();
	const [order, setOrder] = useOrder(null);

	useAsyncEffect(async () => {
		if (!id || (order && id === order.id)) {
			setOrder(null);
		} else {
			const response = await axios.get(`/api/orders/transports/${id}`);
			setOrder(response.data);
		}
	}, [id]);

	return [
		order,
		setOrder
	];
};
