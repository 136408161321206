import { Undo } from "@mui/icons-material";
import { Box, Button, useTheme } from "@mui/material";
import axios from "axios";
import { RepairOrder } from "system/Domain";
import useAction from "system/useAction";
import { RepairOrderViewModeBase } from "./DekraRepairOrderListItem";
import { Dispatch, SetStateAction } from "react";

export interface Props {
	order: RepairOrder

	setOrder?(order: RepairOrder): void

	setMode: Dispatch<SetStateAction<RepairOrderViewModeBase>>
}

export default ({ order, setOrder, setMode }: Props) => {
	const theme = useTheme();
	const [undoCostEstimation, isUndoingCostEstimation] = useAction(async () => {
		const { data } = await axios.put<RepairOrder>(`/api/orders/repairs/${order.id}/cost-estimate/undo`, { id: order.id });
		if (setOrder) {
			setOrder(data);
			setMode(RepairOrderViewModeBase.View)
		}
	});

	return (
		<Box>
			<Box mt={3} mb={1} color={theme.palette.error.light}>
				<Button
					color="inherit"
					variant="text"
					onClick={undoCostEstimation}
					disabled={isUndoingCostEstimation}
					startIcon={<Undo />}
				>
					Upload KVA rückgängig machen
				</Button>
			</Box>
		</Box>
	);
}
