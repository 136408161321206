import { List, ListItem, ListItemText } from "@mui/material";

interface Props {
	lines : string[]
	highlightFirst? : boolean
}

const LineList = (props : Props) => {
	if (props.lines.length === 0) return (<></>);

	return (
		<List disablePadding>
			{props.lines && props.lines.map((line, i) => (
				<ListItem
					key={i}
					style={{ padding: 0 }}
				>
					{props.highlightFirst && (
						<ListItemText
							primary={i === 0 ? line : undefined}
							secondary={i === 0 ? undefined : line}
						/>
					)}
					{!props.highlightFirst && (
						<ListItemText
							primary={line}
						/>
					)}
				</ListItem>
			))}
		</List>
	);
};

export default LineList;
