import ReactJson from "react-json-view";
import PaperBox from "../../../Components/PaperBox";
import { JobProps } from "./JobLayout";

export default ({ job }: JobProps) => {
	if (!job.args) {
		return null;
	}

	const args = JSON.parse(job.args);

	return (
		<PaperBox>
			<ReactJson
				src={args}
				name={null}
				onAdd={false}
				onEdit={false}
				onDelete={false}
				onSelect={false}
				theme="bright"
				displayObjectSize={false}
				displayDataTypes={false}
				style={{
					backgroundColor: "transparent",
				}}
				// onSelect={false}
			/>
		</PaperBox>
	);
};
