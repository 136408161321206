import { Sync } from "@mui/icons-material";
import { alpha, Box, CircularProgress, IconButton, useTheme } from "@mui/material";
import { SyncReference } from "../../system/Domain";

interface Props {
	reference?: SyncReference
	action: () => void
}

export default ({ reference, action }: Props) => {
	const theme = useTheme();

	return (
		<Box sx={{ position: "absolute", right: theme.spacing(1), top: theme.spacing(1) }}>
			{["Planned", "Started"].indexOf(reference?.status) !== -1 && (
				<Box display="flex" sx={{ padding: 1.75 }}>
					<CircularProgress size={20} sx={{
						color: alpha(theme.palette.text.primary, 0.8)
					}} />
				</Box>
			)}
			{[undefined, "Succeeded", "Failed", "Cancelled"].indexOf(reference?.status) !== -1 && (
				<IconButton size="small" onClick={action}>
					<Sync sx={{ fontSize: "1.5rem" }} />
				</IconButton>
			)}
		</Box>
	);
}
