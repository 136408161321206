import { Box, CircularProgress, Grid } from "@mui/material";
import axios from "axios";
import LayoutSubTitle from "Components/Layout/LayoutSubTitle";
import AdminNavigation from "Navigation/AdminNavigation";
import { Dispatch, SetStateAction, useState } from "react";
import { useParams } from "react-router";
import Layout from "../../../Components/Layout/Layout";
import Tile from "../../../Components/Tiles/Tile";
import { Job } from "../../../system/Domain";
import useAsyncEffect from "../../../system/useAsyncEffect";
import useChannel from "../../../system/useChannel";
import useEvent from "../../../system/useEvent";
import JobArguments from "./JobArguments";
import JobHistory from "./JobHistory";
import { formatJobName } from "./JobListItem";
import JobLogs from "./JobLogs";
import JobOverview from "./JobOverview";
import JobSidebar from "./JobSidebar";

export interface JobProps {
	job: Job;
	importId?: string;
}

export interface JobPropsWithSetter extends JobProps {
	setJob: Dispatch<SetStateAction<Job | undefined>>;
}

export default () => {
	const { id } = useParams<{ id: string }>();
	const [job, setJob] = useState<Job>();

	useAsyncEffect(async () => {
		setJob(undefined);

		if (!id) {
			return;
		}

		const { data: job } = await axios.get<Job>(`/api/admin/jobs/${id}`);
		setJob(job);
	}, [id]);

	useChannel(id);

	useEvent(
		"job:updated",
		(job: Job) => {
			if (!id || !job || job.id !== id) {
				return;
			}

			setJob(job);
		},
		[id],
	);

	return (
		<Layout
			title={!job ? "Job" : formatJobName(job)}
			navigation={<AdminNavigation />}
			sidebar={job && <JobSidebar job={job} setJob={setJob} />}
		>
			{!job && <CircularProgress size={48} />}
			{job && (
				<>
					<Grid container spacing={6}>
						<Grid item xs={12}>
							<Box mb={2}>
								<LayoutSubTitle label="Job" />
							</Box>
							<JobOverview job={job} />
						</Grid>

						{!!job.args && (
							<Grid item xs={12}>
								<Box mt={4} mb={2}>
									<LayoutSubTitle label="Arguments" />
								</Box>
								<JobArguments job={job} />
							</Grid>
						)}

						<Grid item xs={12}>
							<Box mt={4} mb={2}>
								<LayoutSubTitle label="History" />
							</Box>
							<JobHistory job={job} />
						</Grid>

						<Grid item xs={12}>
							<Tile title="Log">
								<JobLogs job={job} />
							</Tile>
						</Grid>
					</Grid>
				</>
			)}
		</Layout>
	);
};
