import { useState } from "react";
import { Box, Button, useTheme } from "@mui/material";

// @ts-ignore
import QrReader from "react-qr-reader";
import { useHistory } from "react-router";
export interface VinQrScannerProps {
	setVin: (vin: string) => void
}

export default ({ setVin }: VinQrScannerProps) => {
	const history = useHistory();
	const theme = useTheme();

	const [error, setError] = useState<Error>();

	const handleError = (error: any) => {
		setError(error);
	};

	const handleScan = (data: string) => {
		if (!data) {
			return;
		}

		if (data.startsWith("pickup://")) {
			history.push(`/vehicles/ship?pickupToken=${data.substring(9)}`);
			return;
		}

		const match = data.match(/^fin:\/\/(?<vin>[0-9A-HJ-NPR-Z]{17})$/i);
		if (!match || !match.groups || !match.groups["vin"]) {
			setError(new Error(`Ungültiges Format der FIN im QR Code. Folgender Text wurde extrahiert: ${data}`));
			return;
		}

		setVin(match.groups["vin"]);
	};

	return (
		<Box sx={{
			display: "flex",
			flexDirection: "column",
			flexGrow: 1,
			lineHeight: "1.4rem"
		}}>
			{!!error && (
				<Box>
					<Button
						fullWidth
						variant="contained"
						size="medium"
						onClick={() => setError(undefined)}
					>Erneut versuchen</Button>
					<Box mt={3} sx={{
						color: theme.palette.error.main
					}}>
						<Box sx={{
							fontWeight: 600
						}}>Fehler</Box>
						<div>{error.message}</div>
					</Box>
				</Box>
			)}
			{!error && (
				<Box sx={{
					display: "flex",
					flexGrow: 1,
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center"
				}}>
					<QrReader
						delay={100}
						onError={handleError}
						onScan={handleScan}
						style={{ width: "100%", maxWidth: 500 }} />
				</Box>
			)}
		</Box>
	);
};
