import axios from "axios";
import List from "./List";
import Search from "./Search";
import { Box } from "@mui/material";
import { Vehicle } from "system/Domain";
import { useHistory } from "react-router";
import useHotkeys from "system/useHotkeys";
import { useCallback, useState } from "react";
import VehicleListItem from "./VehicleListItem";
import useAxiosEffect from "system/useAxiosEffect";

interface VehicleSearchProps {
	onClick?: (vehicle: Vehicle) => void

	fullText?: string
	perespective?: string
	sort?: string
	placeholder?: string
	customerDetails?: boolean
}

export default (props: VehicleSearchProps) => {
	const history = useHistory();
	const [vehicles, setVehicles] = useState<Vehicle[]>();

	const handleClick = !!props.onClick ? props.onClick : (vehicle: Vehicle) => history.push(`/vehicles/${vehicle.id}`);
	const [fullText, setFullText] = useState(props.fullText || '');

	useAxiosEffect(async config => {
		if (!fullText) {
			setSelectedVehicle(undefined);
			setVehicles(undefined);
			return;
		}

		const { data } = await axios.post<Vehicle[]>(`/api/vehicles/search`, {
			fullText,
			perspective: props.perespective ?? "All",
			sort: props.sort ?? "lastUpdated:desc"
		}, config);

		setSelectedVehicle(undefined);
		setVehicles(data);
	}, [fullText]);

	const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>();
	const moveSelection = useCallback((offset: number) => {
		if (!vehicles) {
			return;
		}

		setSelectedVehicle(v => {
			let index = !v ? 0 : vehicles.indexOf(v) + offset;
			if (index < 0) {
				index = 0;
			} else if (index > vehicles.length - 1) {
				index = vehicles.length - 1;
			}
			return vehicles[index];
		})
	}, [vehicles]);

	useHotkeys("up", () => moveSelection(-1), [moveSelection]);
	useHotkeys("down", () => moveSelection(1), [moveSelection]);
	useHotkeys("enter", () => {
		if (!selectedVehicle) {
			return;
		}

		handleClick(selectedVehicle);
	}, [selectedVehicle]);

	return (
		<Box>
			<Search
				onSearch={(v => setFullText(v))}
				placeholder={props.placeholder ?? "Fahrzeug suchen..."}
				realtime
				autoFocus
				disableEnter
				value={fullText}
			/>
			<List pt={2}>
				{vehicles && vehicles.map(v => (
					<VehicleListItem
						key={v.id}
						vehicle={v}
						selected={v === selectedVehicle}
						onClick={() => handleClick(v)}
						customerDetails={props.customerDetails}
					/>
				))}
			</List>
		</Box>
	);
};
