import Chart, { ChartProps } from "../../Components/Chart";
import { useAsync } from "react-use";
import axios from "axios";
import { Interval } from "../../Components/getInterval";

interface DekraChartProps extends Partial<ChartProps> {
	interval: Interval
}

interface StatisticData {
	serviceTypeId: string
	date: string
	count: number
	totalAmount: number
}

type ServiceTypeIds = "Picture" | "PicturePreparation" | "LotVehicleHandling" | "LotVehicleExit"

const serviceTypes = {
	"Picture": "Fotobox",
	"PicturePreparation": "Vorbereitung Fotobox",
	"LotVehicleHandling": "Handling Gebrauchtwagenplatz",
	"LotVehicleExit": "Ausgang Gebrauchtwagenplatz"
}

export default ({ interval, ...props }: DekraChartProps) => {
	const chartData = useAsync(async () => {
		const { data: services } = await axios.post<StatisticData[]>(
			`/api/statistics/vehicle-services?company=Dekra`, interval
		);

		const createSeries = (serviceTypeId: ServiceTypeIds, services: StatisticData[]) => ({
			name: serviceTypeId,
			title: serviceTypes[serviceTypeId],
			data: services
				.filter(vs => vs.serviceTypeId === serviceTypeId)
				.map(vs => ({
					x: vs.date,
					y: vs.count
				})),
		})

		return [
			createSeries("LotVehicleHandling", services),
			createSeries("PicturePreparation", services),
			createSeries("Picture", services),
			createSeries("LotVehicleExit", services)
		];

	}, [interval]);

	return (
		<Chart
			{...props}
			loading={chartData.loading}
			series={chartData.value || []}
		/>
	);
}
