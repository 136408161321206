import { Button, CircularProgress, Grid } from "@mui/material";
import axios from "axios";
import SaleNavigation from "Navigation/SaleNavigation";
import RemarketingAssessmentTile from "Pages/Vehicles/RemarketingAssessmentTile";
import { Dispatch, SetStateAction, useState } from "react";
import { useParams } from "react-router";
import Layout from "../../Components/Layout/Layout";
import List from "../../Components/List";
import Tile from "../../Components/Tiles/Tile";
import TileContent from "../../Components/Tiles/TileContent";
import { LeasmanVehicle, Sale, UserRole, VehicleAssessment, VehicleAssessmentAttachment } from "../../system/Domain";
import parseContentDispositionFilename from "../../system/parseContentDispositionFilename";
import useAsyncEffect from "../../system/useAsyncEffect";
import useAxiosEffect from "../../system/useAxiosEffect";
import useEvent from "../../system/useEvent";
import useUser from "../../system/useUser";
import useVehicle from "../../system/useVehicle";
import AssessmentDamageList from "../Vehicles/Assessments/AssessmentDamageList";
import RemarketingStopList from "../Vehicles/RemarketingStopsTile";
import SaleDetailsTile from "./SaleDetailsTile";
import SaleLeasingContract from "./SaleLeasingContractTile";
import SaleOffersTile from "./SaleOffersTile";
import SaleOrderListItem from "./SaleOrderListItem";
import SalesReporting from "./SalesReporting";
import VehiclePicturesTile from "./VehiclePicturesTile";
import VehicleHeader from "Pages/Vehicles/VehicleHeader";
import PicturesTile, { PictureReference } from "Components/PicturesTile";

export interface SaleProps {
	sale: Sale;
}

export interface SalePropsWithSetter extends SaleProps {
	setSale: Dispatch<SetStateAction<Sale | undefined>>;
}

const downloadFile = (data: any, filename: string, mime?: string, bom?: any) => {
	const blobData = typeof bom !== "undefined" ? [bom, data] : [data];
	const blob = new Blob(blobData, { type: mime || "application/octet-stream" });

	if (typeof (window.navigator as any).msSaveBlob !== "undefined") {
		// IE workaround for "HTML7007: One or more blob URLs were
		// revoked by closing the blob for which they were created.
		// These URLs will no longer resolve as the data backing
		// the URL has been freed."
		(window.navigator as any).msSaveBlob(blob, filename);
	} else {
		const blobURL =
			window.URL && window.URL.createObjectURL
				? window.URL.createObjectURL(blob)
				: window.webkitURL.createObjectURL(blob);
		const tempLink = document.createElement("a");
		tempLink.style.display = "none";
		tempLink.href = blobURL;
		tempLink.setAttribute("download", filename);
		// tempLink.setAttribute('target', '_blank');

		// Safari thinks _blank anchor are pop-ups. We only want to set _blank
		// target if the browser does not support the HTML5 download attribute.
		// This allows you to download files in desktop safari if pop up blocking
		// is enabled.
		if (typeof tempLink.download === "undefined") {
			tempLink.setAttribute("target", "_blank");
		}

		document.body.appendChild(tempLink);
		tempLink.click();

		// Fixes "webkit blob resource error 1"
		setTimeout(() => {
			document.body.removeChild(tempLink);
			window.URL.revokeObjectURL(blobURL);
		}, 200);
	}
};

export const downloadAssessmentAttachment = async (
	assessment: VehicleAssessment,
	attachment: VehicleAssessmentAttachment
) => {
	if (!assessment) {
		return;
	}

	const response = await axios.get(`/api/assessments/${assessment.id}/attachments/${attachment.storageFile.hash}`, {
		responseType: "blob"
	});

	const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
	downloadFile(response.data, filename);
};

const SaleLayout = () => {
	const { id } = useParams<{ id: string }>();
	const [sale, setSale] = useState<Sale>();
	const [vehicle, setVehicle] = useVehicle(sale?.vehicle.id);
	const [leasmanVehicle, setLeasmanVehicle] = useState<LeasmanVehicle>();
	const [assessment, setAssessment] = useState<VehicleAssessment>();
	const [, , hasRole] = useUser();

	useAsyncEffect(async () => {
		setSale(undefined);

		if (!id) {
			return;
		}

		const { data: sale } = await axios.get<Sale>(`/api/sales/${id}`);

		setSale(sale);
	}, [id]);

	useAxiosEffect(async (config) => {
		if (!sale?.vehicle.leasmanId) {
			setLeasmanVehicle(undefined);

			return;
		}

		const isLeasmanVehicle = !(sale?.vehicle?.businessLine === "Leaseplan" || sale?.vehicle?.businessLine === "Sonderflotte");

		if (isLeasmanVehicle) {
			const { data } = await axios.get<LeasmanVehicle>(`/api/leasman/vehicles/${sale.vehicle.leasmanId}`, config);

			setLeasmanVehicle(data);
		}
	}, [sale?.vehicle.leasmanId]);

	useAxiosEffect(async (config) => {
		if (!sale?.assessment?.id) {
			setAssessment(undefined);

			return;
		}

		const { data } = await axios.get<VehicleAssessment>(`/api/assessments/${sale.assessment.id}`, config);

		setAssessment(data);
	}, [sale?.assessment?.id]);

	useEvent("sale:updated", async (data) => {
		const { id, version } = data;

		if (!sale || !id || !version) {
			return;
		}

		if (id === sale.id && sale.version < version) {
			console.log(`reloading sale (${sale.version} < ${version})`);
			const { data: updatedSale } = await axios.get<Sale>(`/api/sales/${sale.id}`);
			setSale(updatedSale);
		}
	}, [sale]);

	const migrateArmsSale = async () => {
		if (!sale?.vehicle?.id) {
			return;
		}

		await axios.post(`/api/admin/jobs/migrate-arms-sales`, {
			vehicleId: sale.vehicle.id,
			reuseSaleIds: true,
			force: true
		});
	};

	const order = sale?.orders.find((o) => o.status !== "Cancelled");

	return (
		<Layout
			title="Verkauf"
			plateNumber={sale?.plateNumber}
			navigation={<SaleNavigation sale={sale} vehicle={vehicle} />}
		>
			{!vehicle && <CircularProgress size={48} />}
			{vehicle && (
				<>
					<VehicleHeader vehicle={vehicle} />
					{sale && (
						<Grid container spacing={6}>
							<Grid item xs={12} md={3}>
								<Grid container spacing={6}>
									<Grid item xs={12}>
										<SaleDetailsTile
											sale={sale}
											setSale={setSale}
											vehicle={vehicle}
											leasmanVehicle={leasmanVehicle}
										/>
									</Grid>
									<Grid item xs={12}>
										<SaleLeasingContract leasmanVehicle={leasmanVehicle} />
									</Grid>
								</Grid>
							</Grid>

							<Grid item xs={12} md={5}>
								<Grid container spacing={6}>
									{order && (
										<Grid item xs={12}>
											<Tile title="Verkauf">
												<List>
													<SaleOrderListItem sale={sale} order={order} />
												</List>
											</Tile>
										</Grid>
									)}
									<Grid item xs={12}>
										<SaleOffersTile sale={sale} setSale={setSale} vehicle={vehicle} />
									</Grid>
									<Grid item xs={12}>
										<VehiclePicturesTile
											tileTitle="Vermarktungsfotos"
											pictureSet={vehicle.pictureSets[vehicle.pictureSets.length - 1]}
											limit={1}
										/>
									</Grid>
									<Grid item xs={12}>
										<RemarketingStopList
											excluded={["VehicleAssessmentRejection"]}
											vehicle={vehicle}
											setVehicle={setVehicle}
										/>
									</Grid>
									{assessment && (
										<Grid item xs={12}>
											<Tile title="Schäden">
												<TileContent dense>
													<AssessmentDamageList assessment={assessment} />
												</TileContent>
											</Tile>
										</Grid>
									)}
								</Grid>
							</Grid>

							<Grid item xs={12} md={4}>
								<Grid container spacing={6}>
									<Grid item xs={12}>
										<SalesReporting
											type={sale.vehicle.type}
											highlights={sale.vehicle.highlights}
											duration={sale.contract?.duration}
											mileage={sale.returnMileage}
										/>
									</Grid>
									{assessment && (
										<>
											{assessment.attachments
												.some(a => a.storageFile.filename.endsWith('.jpeg') || a.storageFile.filename.endsWith('.jpg')) && (
												<Grid item xs={12}>
													<PicturesTile
														tileTitle="Gutachtenfotos"
														pictures={assessment.attachments
															.filter(a => a.storageFile.filename.endsWith('.jpeg') || a.storageFile.filename.endsWith('.jpg'))
															.map((ai) => ({
																title: ai.type,
																picture: ai.storageFile
															}) as PictureReference)}
													/>
												</Grid>
											)}
											<Grid item xs={12}>
												<RemarketingAssessmentTile vehicle={vehicle} assessment={assessment} />
											</Grid>
										</>
									)}
									{sale.source === "Arms" && hasRole(UserRole.AldAdmin) && (
										<Grid item xs={12}>
											<Button variant="text" onClick={migrateArmsSale}>
												ARMS Migration
											</Button>
										</Grid>
									)}
								</Grid>
							</Grid>
						</Grid>
					)}
				</>
			)}
		</Layout>
	);
};

export default SaleLayout;
