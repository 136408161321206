import ShipVehiclePage from "./ShipVehiclePage";
import { useState } from "react";
import { Sale, Vehicle } from "../../../system/Domain";
import { useHistory, useParams } from "react-router";
import { useAsync } from "react-use";
import axios from "axios";

const AfterShippingVehiclePage = () => {
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const [sale, setSale] = useState<Sale | null>();
	const [vehicle, setVehicle] = useState<Vehicle | null>(null);

	useAsync(async () => {
		const { data: vehicle } = await axios.get<Vehicle>(`/api/vehicles/${id}`);

		if (vehicle.remarketing.status !== "Ausgang") {
			history.push(`/orders/transports/`);
		}

		setVehicle(vehicle);
	}, [id]);

	useAsync(async () => {
		if (vehicle.remarketing.saleId) {
			const { data: sale } = await axios.get<Sale>(`/api/sales/${vehicle.remarketing.saleId}/after-shipping/`);
			setSale(sale);
			return;
		}

		history.push(`/orders/transports/`);
	}, [vehicle]);

	return (
		<>
			{sale && vehicle &&
				<ShipVehiclePage sale={sale} vehicle={vehicle} />
			}
		</>
	);
};

export default AfterShippingVehiclePage;
