import axios from 'axios';
import moment from 'moment';
import { useState } from 'react';

import { Check, Close, Edit } from '@mui/icons-material';
import { Box, FormControl } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import TextBlock from '../../../Components/Layout/TextBlock';
import ListItem from '../../../Components/ListItem';
import ListItemAction from '../../../Components/ListItemAction';
import ListItemActions from '../../../Components/ListItemActions';
import ListItemChip from '../../../Components/ListItemChip';
import ListItemChips from '../../../Components/ListItemChips';
import ListItemContent from '../../../Components/ListItemContent';
import { Plate } from '../../../Components/Plate';
import VinDisplay from '../../../Components/VinDisplay';
import {
	ThemeColor, UserRole, Vehicle, VehicleRegistrationDocumentsProjection
} from '../../../system/Domain';
import useUser from '../../../system/useUser';
import { arrivalTypes } from './ArrivalToggleButtonGroup';

interface Props {
	vehicle: VehicleRegistrationDocumentsProjection
	completionCallback: (vehicle: VehicleRegistrationDocumentsProjection) => void
}

type Mode = "view" | "editing";

const OpenUnregistrationsListItem = (props: Props) => {
	const [mode, setMode] = useState<Mode>("view");
	const [, , hasRole] = useUser();
	const [unregistrationDate, setUnregistrationDate] = useState<string>();

	let color: ThemeColor = "info";
	switch (mode) {
		case "view":
		default:
			color = "info";
			break;
	}

	const handleConfirmation = async () => {
		if (!unregistrationDate) {
			return;
		}

		await axios.put<Vehicle>(`/api/vehicles/${props.vehicle.id}/remarketing/documents/unregistration/complete`, {
			unregistrationDate: unregistrationDate
		});

		props.completionCallback(props.vehicle);
	};

	return (
		<ListItem color={color}>
			{hasRole(UserRole.AldManager) && (
				<ListItemActions>
					{mode === "view" && (
						<ListItemAction
							icon={<Edit />}
							onClick={() => setMode("editing")}
						/>
					)}
					{mode === "editing" && (
						<>
							<ListItemAction
								icon={<Check />}
								onClick={handleConfirmation}
								disabled={!unregistrationDate}
							/>
							<ListItemAction
								icon={<Close />}
								onClick={() => setMode("view")}
							/>
						</>
					)}
				</ListItemActions>
			)}
			<ListItemContent>
				<TextBlock
					primary={<Plate plateNumber={props.vehicle.plateNumber} style={{ zoom: 0.5 }} />}
					secondary={(
						<span>{props.vehicle.makeModel} <VinDisplay vin={props.vehicle.vin} /></span>
					)}
					compact
				/>
				{mode === "editing" && (
					<Box mt={2}>
						<FormControl>
							<DatePicker
								// disableToolbar
								// variant="inline"
								format="DD.MM.YYYY"
								// margin="none"
								label="Abmeldedatum"
								value={moment(unregistrationDate)}
								disableFuture
								slotProps={{ textField: { variant: 'standard' } }}
								onChange={(d: any) => setUnregistrationDate(q => (d || moment()).startOf("d").toISOString(true))}
							/>
						</FormControl>
					</Box>
				)}
				<ListItemChips>
					<ListItemChip
						label={
							props.vehicle.registrationDocuments.status === "Requested"
								? "Angefragt"
								: arrivalTypes[props.vehicle.registrationDocuments.arrivalType].label
						}
					/>
				</ListItemChips>
			</ListItemContent>
		</ListItem>
	);
};

export default OpenUnregistrationsListItem;
