import axios from "axios";
import moment from "moment";
import { useState } from "react";
import dateFormats from "../../../system/dateFormats";
import useAsyncEffect from "../../../system/useAsyncEffect";
import TextBlock from "../../../Components/Layout/TextBlock";
import { Notification, TransportOrder } from "../../../system/Domain";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";

export interface OrderNotificationTableProps {
	order: TransportOrder
}

export default ({ order }: OrderNotificationTableProps) => {
	const [notifications, setNotifications] = useState<Notification[]>([]);
	const [, setLoading] = useState(false);

	useAsyncEffect(async () => {
		if (!order) {
			setNotifications([]);
			return;
		}

		setLoading(true);
		try {
			const response = await axios.get<Notification[]>(`/api/notifications?folder=all&category=Orders&entityId=${order.id}`);
			setNotifications(response.data);
		} finally {
			setLoading(false);
		}
	}, [order]);

	if (!notifications || notifications.length === 0) {
		return null;
	}

	const sortedNotifications = notifications.sort((a, b) => moment(b.dateCreated).valueOf() - moment(a.dateCreated).valueOf());

	return (
		<Table>
			<colgroup>
				<col width="25%" />
				<col width="75%" />
			</colgroup>
			<TableBody>
				{sortedNotifications.map((n, i) => {
					return (
						<TableRow
							key={i}
							sx={{ verticalAlign: "top" }}
						// onClick={() => history.push(`/notifications/all/${n.id}`)}
						>
							<TableCell>
								<TextBlock
									primary={moment(n.dateCreated).format(dateFormats.dateTime)}
									secondary={moment(n.dateCreated).fromNow()}
								/>
							</TableCell>
							<TableCell>
								<TextBlock
									primary={n.subject}
									secondary={n.to.email}
								/>
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		</Table>
	);
};
