import { useState } from 'react';
import Layout from "../../Components/Layout/Layout";
import SecurityDocumentationSidebar from "./SecurityDocumentationSidebar";
import { Box } from "@mui/material";
import SecurityDocumentationTable from "./SecurityDocumentationTable";
import axios from "axios";
import useAsyncEffect from "../../system/useAsyncEffect";
import AdminNavigation from 'Navigation/AdminNavigation';

export interface ActionSecurityDocumentation {
	operationId: string
	httpMethod: string
	actionName: string
	relativePath: string
	roles: string[]
	allowAnonymous: boolean
	description?: string
	roleDescriptions: string[]
}

const SecurityDocumentation = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [actionSecurityDocumentationLines, setActionSecurityDocumentationLines] = useState<ActionSecurityDocumentation[]>([]);

	useAsyncEffect(async () => {
		await loadDocumentation();
	}, []);

	const loadDocumentation = async () => {
		if (isLoading) return;

		setIsLoading(true);

		try {
			const { data: lines } = await axios.get<ActionSecurityDocumentation[]>(`/api/documentation/security`);

			setActionSecurityDocumentationLines(lines);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Layout
			title="Dokumentation"
			navigation={<AdminNavigation />}
			sidebar={<SecurityDocumentationSidebar
				isLoading={isLoading}
				setIsLoading={setIsLoading}
				loadDocumentation={loadDocumentation}
			/>}
		>
			<Box mb={2}>
				<SecurityDocumentationTable
					actionSecurityDocumentationLines={actionSecurityDocumentationLines}
					isLoading={isLoading}
				/>
			</Box>
		</Layout>
	);
};

export default SecurityDocumentation;
