import { FormEvent, useEffect, useState } from "react";
import Layout from "../../../Components/Layout/Layout";
import {
	Box,
	Button,
	Checkbox,
	FormControl,
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography
} from "@mui/material";
import { useAsync } from "react-use";
import { useHistory, useParams } from "react-router";
import axios from "axios";
import { SaleDocument, SaleEmailsBundleType, SalesDocumentType } from "../../../system/Domain";
import useForm, { validateEmail } from "../../../system/useForm";
import FormSelect from "../../../Components/FormSelect";
import FormText from "../../../Components/FormText";
import produce from "immer";
import { Mail } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import LoadingIndicator from "../../../Components/LoadingIndicator";

interface SaleAttachmentSelectionModel {
	selected: boolean
	attachment: SaleDocument
}

interface EmailForm {
	subject: string
	from: string
	template: SaleEmailsBundleType
	selectedAttachments: SaleAttachmentSelectionModel[]
}

interface SaleEmailBundleForm {
	recipient: string;
	to: string
	subject: string
	procedure: SaleEmailsBundleType
	emails: EmailForm[]
	extraText: string
}

interface PartialSaleEmail {
	subject: string
	from: string
	template: SaleEmailsBundleType
	attachments: SaleDocument[]
	templateDefaultSelection: SalesDocumentType[]
}

interface SaleEmailBundle {
	subject: string
	recipient: string
	to: string
	cc: string
	bcc: string
	procedure: SaleEmailsBundleType
	emails: PartialSaleEmail[]
	extraText: string
}

const emailTemplatesTranslation = {
	"SaleDomestic": "Verkauf Inland",
	"SaleInternational": "Verkauf Ausland",
	"RenaultBatteriesHandoverProtocolDomestic": "Renault Batterie Übergabeprotokoll Inland",
	"RenaultBatteriesHandoverProtocolInternational": "Renault Batterie Übergabeprotokoll Ausland",
	"PickupOrderDomestic": "Pick-Up Order Inland",
	"PickupOrderInternational": "Pick-Up Order Ausland",
	"SaleCancellation": "Verkauf Storno",
	"SaleInvoiceCancellation": "Verkaufsrechnung Storno"
};

type SaleEmailsBundles = { K: SaleEmailsBundleType, V: string };

const SendDocumentsPage = () => {
	const params = useParams<{ id: string, orderId: string }>();
	const [availableSalesEmailTemplates, setAvailableSalesEmailTemplates] = useState<SaleEmailsBundles>(null);
	const [emailBundle, setEmailBundle] = useState<SaleEmailBundleForm | null>(null);
	const [busy, setBusy] = useState(false);
	const history = useHistory();

	const form = useForm({
		values: emailBundle,
		setValues: setEmailBundle,
		deltaValues: null
	});

	useAsync(async () => {
		if (!params?.id) {
			return;
		}

		try {
			setBusy(true);
			const { data: saleEmailTemplates } = await axios.get<SaleEmailsBundleType[]>(`/api/sales/${params.id}/available-email-bundles`);

			const preparedSalesEmailTemplates = {} as SaleEmailsBundles;
			for (const template of saleEmailTemplates) {
				preparedSalesEmailTemplates[template] = emailTemplatesTranslation[template];
			}

			setAvailableSalesEmailTemplates(preparedSalesEmailTemplates);
		} catch (e) {
			setBusy(false);
			throw e;
		}
		setBusy(false);
	}, [params?.id]);

	useAsync(async () => {
		if (!emailBundle?.procedure || !params) {
			return;
		}

		try {
			setBusy(true);
			const { data: preparedEmailBundle } = await axios.get<SaleEmailBundle>(`/api/sales/${params.id}/orders/${params.orderId}/prepare-emails?template=${emailBundle.procedure}`);

			setEmailBundle({
				...preparedEmailBundle,
				emails: preparedEmailBundle.emails.map(em => ({
					subject: em.subject,
					from: em.from,
					template: em.template,
					selectedAttachments: em.attachments.map(a => ({
						selected: em.templateDefaultSelection && em.templateDefaultSelection.includes(a.documentType),
						attachment: a
					}))
				}))
			});
		} catch (e) {
			setBusy(false);
			throw e;
		}

		setBusy(false);
	}, [emailBundle?.procedure]);

	const toggleAttachmentSelection = (outerInder: number, innerIndex: number) => {
		const newEmail = produce(emailBundle, prev => {
			prev.emails[outerInder].selectedAttachments[innerIndex].selected = !prev.emails[outerInder].selectedAttachments[innerIndex].selected;
		});
		setEmailBundle(newEmail);
	};

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();
		const isValid = form.validateAll();
		if (!isValid) {
			return;
		}

		try {
			setBusy(true);

			await axios.post(`/api/sales/${params.id}/orders/${params.orderId}/emails`, emailBundle.emails.map(email => (
				{
					template: email.template,
					subject: email.subject,
					name: emailBundle.recipient,
					to: emailBundle.to,
					bcc: form.getValue("bcc"),
					cc: form.getValue("cc"),
					attachments: email?.selectedAttachments.filter(a => a.selected).map(a => a.attachment),
					extraText: emailBundle.extraText
				})));

			history.push(`/sales/${params.id}/orders/${params.orderId}`);
		} catch (e) {
			setBusy(false);

			throw e;
		}
		setBusy(false);
	};

	const ccField = form.getValue("cc");
	const bccField = form.getValue("bcc");

	const validateEmailsFormElement = (name: string, value: any) => {
		const emails = value.split(";");
		let isError = false;
		let i = 0;
		while (!isError && i <= emails.length - 1) {
			isError = !validateEmail(emails[i]);
			i++;
		}

		const error: any = {};
		if (isError) {
			error.email = true;
		}

		if (Object.keys(error).length === 0) {
			if (form.errors[name] !== undefined) {
				form.setErrors((errors: any) => {
					const { [name]: currentError, ...newErrors } = errors;
					return newErrors;
				});
			}

			return true;
		}

		form.setErrors((errors: any) => ({
			...errors,
			[name]: error
		}));
	};

	useEffect(() => {
		if (!ccField)
			return;

		validateEmailsFormElement("cc", ccField)
	}, [ccField]);

	useEffect(() => {
		if (!bccField)
			return;

		validateEmailsFormElement("bcc", bccField)
	}, [bccField]);

	return (
		<Layout
			title="Verkaufsdokumente versenden"
		>
			<form onSubmit={handleSubmit}>
				<LoadingIndicator isLoading={busy} />
				<Grid container spacing={6}>
					<Grid item xs={12} md={6}>
						<FormControl
							margin="normal"
							fullWidth
						>
							<FormSelect
								label="Vorlage"
								form={form}
								name="procedure"
								choices={availableSalesEmailTemplates}
								options={{ required: true }}
							/>
						</FormControl>
						<FormControl
							margin="normal"
							fullWidth
						>
							<FormText
								type="text"
								fullWidth
								label="Name"
								form={form}
								name="recipient"
								options={{
									required: true
								}}
							/>
						</FormControl>
						<FormControl
							margin="normal"
							fullWidth
						>
							<FormText
								type="email"
								fullWidth
								label="An"
								form={form}
								name="to"
								options={{
									required: true,
									email: true
								}}
							/>
						</FormControl>
						<FormControl
							margin="normal"
							fullWidth
						>
							<FormText
								// we use custom validation, see useEffect
								fullWidth
								label="Cc"
								form={form}
								name="cc"
								options={{
									required: false
								}}
							/>
						</FormControl>
						<FormControl
							margin="normal"
							fullWidth
						>
							<FormText

								fullWidth
								label="Bcc"
								form={form}
								name="bcc"
								options={{
									required: false
								}}
							/>
						</FormControl>
						<FormControl
							margin="normal"
							fullWidth
						>
							<FormText
								fullWidth
								label="Zusatzinformation"
								form={form}
								name="extraText"
								options={{
									required: false
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item>
						{emailBundle && emailBundle.emails && emailBundle.emails.length === 0 &&
							<Box>
								Keine E-mails vorhanden
							</Box>
						}
						{emailBundle && emailBundle.emails && emailBundle.emails.map((ef, efIndex) =>
							<Grid item>
								<Grid item>
									<Typography padding={1} bgcolor={grey[700]}>
										<Box mt={1} display="inline-flex">
											<Mail />
											<Box mx={2} fontStyle="italic">{ef.subject}</Box>
										</Box>
										<Box fontSize="smaller">Antwort geht an</Box>
										<Box fontWeight="bolder">{ef.from}</Box>
									</Typography>
									<Typography mt={1} mx={2}>Anlagen</Typography>
									<List dense disablePadding>
										{ef.selectedAttachments.map((a, index) => {
											return (
												<ListItem
													key={a.attachment.file.hash}
													button
													onClick={() => toggleAttachmentSelection(efIndex, index)}
												>
													<ListItemIcon>
														<Checkbox
															edge="start"
															disableRipple
															checked={a.selected}
														/>
													</ListItemIcon>
													<ListItemText
														primary={a.attachment.documentType}
														secondary={a.attachment.file.filename}
													/>
												</ListItem>
											);
										})}
									</List>
								</Grid>
							</Grid>)}
					</Grid>
					<Grid item xs={12}>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disabled={busy || !emailBundle || emailBundle?.emails?.length === 0}
						>
							Senden
						</Button>
					</Grid>
				</Grid>
			</form>
		</Layout>
	);
};

export default SendDocumentsPage;
