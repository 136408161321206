import { Box, Button, useTheme } from "@mui/material";
import axios from "axios";
import { RepairOrder } from "system/Domain";
import useAction from "system/useAction";
import { RepairOrderViewModeBase } from "./DekraRepairOrderListItem";
import { Done } from "@mui/icons-material";
import { Dispatch, SetStateAction } from "react";

interface Props {
	order: RepairOrder
	setMode: Dispatch<SetStateAction<RepairOrderViewModeBase>>
	setOrder?(order: RepairOrder): void
}

export default (props: Props) => {
	const theme = useTheme();

	const [completeOrder, isCompleting] = useAction(async () => {
		const { data: responseData } = await axios.post<RepairOrder>(`/api/orders/repairs/${props.order.id}/complete`);

		if (props.setOrder) {
			props.setOrder(responseData);
		}

		props.setMode(RepairOrderViewModeBase.View);
	});

	return (
		<Box>
			<Box mt={3} mb={1} color={theme.palette.primary.light}>
				<Button
					color="inherit"
					variant="text"
					onClick={completeOrder}
					disabled={isCompleting}
					startIcon={<Done />}
				>
					Auftrag abschließen
				</Button>
			</Box>
		</Box>
	);
}
