import clsx from "clsx";
import useUser from "../../system/useUser";
import { Info } from "@mui/icons-material";
import { UserRole } from "../../system/Domain";
import { PropsWithChildren, ReactNode } from "react";
import LayoutSubTitle from "../Layout/LayoutSubTitle";
import { Box, IconButton, SxProps, Tooltip } from "@mui/material";

export interface TileProps {
	roles?: Array<UserRole>
	className?: string
	fullHeight?: boolean

	title?: ReactNode
	subtitle?: ReactNode
	tooltip?: ReactNode
	sxProp?: SxProps
}

export default ({
	roles,
	children,
	className,
	fullHeight = false,
	title,
	subtitle,
	tooltip,
	sxProp
}: PropsWithChildren<TileProps>) => {
	const [user] = useUser();

	if (roles && !user) return null;
	if (roles && user && !user.roles.some(r => roles.includes(r))) return null;

	return (
		<Box sx={sxProp}>
			<Box mb={2}>
				<Box sx={{
					display: "flex",
					justifyContent: "space-between"
				}}>
					<LayoutSubTitle label={title} sx={{ width: "100%" }} />
					{tooltip && (
						<Tooltip title={tooltip}>
							<IconButton size="small">
								<Info color="disabled" />
							</IconButton>
						</Tooltip>
					)}
				</Box>
				{subtitle && (
					<Box sx={{
						fontWeight: 400,
						fontSize: "0.7rem",
						opacity: 0.75
					}}>{subtitle}</Box>
				)}
			</Box>
			<Box
				sx={{
					flexGrow: 1,
					...(fullHeight && { height: "100%" })
				}}
				className={clsx(className)}>
				{children}
			</Box>
		</Box>
	);
};
