import { Box, useTheme } from "@mui/material";
import { ThemeColor } from "../../system/Domain";
import { PropsWithChildren, ReactNode } from "react";
import useCopyWithAltKey from "../../system/useCopyWithAltKey";

interface Props {
	label: string;
	value: ReactNode;
	actions?: ReactNode;
	info?: ReactNode;
	color?: ThemeColor;
	invalid?: boolean;
	inactive?: boolean;
	bold?: boolean;
}

export default ({
	label,
	value,
	info,
	actions,
	bold,
	color = "info",
	invalid = false,
	inactive = false,
	children,
}: PropsWithChildren<Props>) => {
	const theme = useTheme();
	const getColor = (color: string): string => {
		switch (color) {
			case "primary":
				return theme.palette.primary.light;
			case "secondary":
				return theme.palette.secondary.light;
			case "success":
				return theme.palette.success.light;
			case "warning":
				return theme.palette.warning.light;
			case "error":
				return theme.palette.error.light;
			case "grey":
				return theme.palette.grey[500];
		}
	};
	const copyWithAltKey = useCopyWithAltKey(value);

	return (
		<Box
			sx={{
				display: "flex",
				gap: theme.spacing(2),
				alignItems: "self-start",
				fontSize: "0.7rem",
				lineHeight: "1.15rem",
				opacity: inactive ? 0.5 : "unset",
			}}
			onClick={copyWithAltKey}
		>
			<Box
				sx={{
					fontSize: "0.55rem",
					textTransform: "uppercase",
					color: theme.palette.text.secondary,
					width: 130,
					flexShrink: 0,
					marginRight: 1,
				}}
			>
				{label}
			</Box>
			<Box
				sx={{
					flexGrow: 1,
					color: getColor(color),
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexGrow: 1,
						justifyContent: "space-between",
						"&:hover > $actions": {
							opacity: 1,
						},
						textDecoration: invalid ? "line-through" : "unset",
						fontWeight: bold ? "bold" : "unset",
					}}
				>
					{value}
					{actions && (
						<Box
							sx={{
								opacity: 0,
								transitionProperty: "opacity",
								transitionDuration: "250ms",
							}}
						>
							{actions}
						</Box>
					)}
				</Box>
				{info && <Box>{info}</Box>}
			</Box>
		</Box>
	);
};
