import PaperTable from 'Components/PaperTable';
import moment from 'moment';
import { Link as RouterLink, useHistory } from "react-router-dom";
import Link from "@mui/material/Link";
import { EmojiTransportation, Link as LinkIcon, LinkOff, NoTransfer } from '@mui/icons-material';
import { Box, Button, Chip, TableBody, TableHead, TableRow, Tooltip, useTheme } from '@mui/material';

import TextBlock from '../../Components/Layout/TextBlock';
import TransportInfo from '../../Components/TransportInfo';
import numberFormatter from '../../system/currencyFormatter';
import { InvoiceItem, InvoiceLineOccuranceDTO } from '../../system/Domain';
import Surcharges from './Surcharges';
import { TableCell } from "../../Components/BreakpointStyledComponents";
import selectOrder from './selectOrder';
import useUser from 'system/useUser';
import dateFormats from 'system/dateFormats';

const createChip = (item: InvoiceItem) => {
	if (!item.orderId) {
		return (
			<Chip
				size="small"
				color="secondary"
				label="Nicht zugeordnet"
			/>
		);
	}

	if (item.isCancelled) {
		return (
			<Chip
				size="small"
				color="secondary"
				label="Abgebrochen"
			/>
		);
	}

	return (
		<Chip
			size="small"
			color="primary"
			label="Angenommen"
		/>
	);
};

type InvoiceItemsTableProps = {
	items: InvoiceItem[]
	occurances: InvoiceLineOccuranceDTO[]
	assignOrder: (orderId: string, orderCompanyName: string, plateNumber: string, deliveryDate: string) => Promise<void>
	unassignOrder: (plateNumber: string, deliveryDate: string) => Promise<void>
}

export default ({ items, occurances, assignOrder, unassignOrder }: InvoiceItemsTableProps) => {
	const history = useHistory();
	const [user] = useUser();

	const theme = useTheme();

	const canAssign = (item: InvoiceItem) => {
		return !item.orderId && !item.isOrderUserAssigned && (assignOrder);
	}

	const canUnassign = (item: InvoiceItem) => {
		return item.isOrderUserAssigned && (unassignOrder);
	}

	const selectAndAssign = async (e, item: InvoiceItem) => {
		e.stopPropagation();

		const result = await selectOrder(item.plateNumber, user);
		if (result) {
			assignOrder(result.id, result.company.name, item.plateNumber, item.deliveryDate)
		}
	}

	const rows = items.map(i => ({
		item: i,
		invoices: occurances.find(o => o.plateNumber == i.plateNumber)?.invoices
	}))

	return (
		<PaperTable>
			<colgroup>
				<col width="auto" />
				<col width="50%" />
				<col width="50%" />
				<col width="auto" />
				<col width="auto" />
				<col width="auto" />
				<col width="auto" />
				<col width="auto" />
			</colgroup>
			<TableHead>
				<TableRow>
					<Box sx={{ display: { xs: "contents", lg: "none" } }}>
						<TableCell>Auftrag</TableCell>
					</Box>
					<Box sx={{ display: { xs: "none", lg: "contents" } }}>
						<TableCell />
						<TableCell>
							Kennzeichen
						</TableCell>
						<TableCell>
							Transport
						</TableCell>
					</Box>
					<TableCell>
						Andere Rechnungen
					</TableCell>
					<Box sx={{ display: { sm: "none", md: "contents" } }}>
						<TableCell>
							Tage
						</TableCell>
						<TableCell>
							Protokolle
						</TableCell>
					</Box>
					<Box sx={{ display: { xs: "none", sm: "contents" } }}>
						<TableCell>
							Zuschläge
						</TableCell>
					</Box>
					<TableCell style={{ textAlign: "right" }}>
						Preis
					</TableCell>
					<TableCell />
				</TableRow>
			</TableHead>
			<TableBody>
				{rows.map(r => {
					const key = `${r.item.plateNumber}#${moment(r.item.deliveryDate).toISOString()}`;

					const dateAssigned = r.item.dateAssigned ? moment(r.item.dateAssigned).format("L") : "";
					const dateReturned = r.item.returnDate ? moment(r.item.returnDate).format("L") : "";

					const vehicleTextBox = (
						<TextBlock
							primary={r.item.plateNumber}
							secondary={`${r.item.businessLine || ""} ${moment(r.item.deliveryDate).format("L")}`}
						/>
					);

					let transportInfoBox: {};
					if (r.item.orderId) {
						transportInfoBox = (
							<Box
								display="flex"
								flexDirection="row"
								alignItems="center"
							>
								<TransportInfo
									align="right"
									mr={2}
									zipCode={r.item.pickupAddress?.zipCode}
									dateAssigned={dateAssigned}
									zipColor={r.item.isMissedTransport ? "warning" : null}
								/>
								{r.item.isMissedTransport && (
									<Box color={theme.palette.warning.light}>
										<NoTransfer />
									</Box>
								)}
								{!r.item.isMissedTransport && (
									<EmojiTransportation />
								)}
								<TransportInfo
									align="left"
									ml={2}
									zipCode={r.item.destinationAddress?.zipCode}
									dateAssigned={dateReturned}
									zipColor={r.item.isMissedTransport ? "warning" : null}
								/>
							</Box>
						);
					}

					return (
						<TableRow
							key={key}
							hover
							onClick={r.item.orderId ? () => history.push(`/orders/transports/${r.item.orderId}`) : undefined}
						>
							<Box sx={{ display: { xs: "contents", lg: "none" } }}>
								<TableCell>
									<Box>
										{vehicleTextBox}
										{transportInfoBox}
									</Box>
								</TableCell>
							</Box>
							<Box sx={{ display: { xs: "none", lg: "contents" } }}>
								<TableCell>
									{createChip(r.item)}
								</TableCell>
								<TableCell>
									{vehicleTextBox}
								</TableCell>
								<TableCell>
									{transportInfoBox}
								</TableCell>
							</Box>
							<TableCell sx={{ whiteSpace: 'nowrap', display: "flex", flexDirection: "row" }}>
								{!r.invoices && (
									<>?</>
								)}
								{r.invoices && (
									r.invoices.map(i => {
										return (
											<Link sx={{ display: "flex" }}
												  key={i.invoiceId}
												  component={RouterLink}
												  to={`/invoices/${i.invoiceId}`}>
												{i.invoiceNumber} {moment(i.invoiceDate).format(dateFormats.date)}
											</Link>
										)
									})
								)}
							</TableCell>
							<Box sx={{ display: { sm: "none", md: "contents" } }}>
								<TableCell style={{ textAlign: "center" }}>
									<Chip
										label={r.item.numberOfDays || "-"}
									/>
								</TableCell>
								<TableCell style={{ textAlign: "center" }}>
									<Chip
										label={r.item.returnForms}
									/>
								</TableCell>
							</Box>
							<Box sx={{ display: { xs: "none", sm: "contents" } }}>
								<TableCell>
									<Surcharges item={r.item} />
								</TableCell>
							</Box>
							<TableCell style={{ textAlign: "right" }}>
								<TextBlock
									primary={numberFormatter.format(r.item.netAmount)}
									secondary={r.item.customerNetAmount ? numberFormatter.format(r.item.customerNetAmount) : "-"}
								/>
							</TableCell>
							<TableCell>
								{canAssign(r.item) && (
									<Tooltip title="Auftrag manuell zuordnen">
										<Button
											onClick={(e) => selectAndAssign(e, r.item)}
										>
											<LinkOff /> {/* (maybe a bit confusing) - shows current state i.e. that line is currently unassigned */}
										</Button>
									</Tooltip>)
								}
								{canUnassign(r.item) && (
									<Tooltip title="Auftrag aufheben">
										<Button
											onClick={(e) => {
												e.stopPropagation();
												unassignOrder(r.item.plateNumber, r.item.deliveryDate)
											}}
										>
											<LinkIcon /> {/* (maybe a bit confusing) - shows current state i.e. that line is currently manually assigned */}
										</Button>
									</Tooltip>)
								}
							</TableCell>
						</TableRow>
					)
				})}
			</TableBody>
		</PaperTable>
	)
}
