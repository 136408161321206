import { useEffect } from "react";
import { Form } from "../../../system/useForm";
import FormYesNo from "../../../Components/FormYesNo";
import { Box, Grid, InputAdornment, Typography } from "@mui/material";
import FormChoices from "../../../Components/FormChoices";
import FormDateTimePicker from "../../../Components/FormDateTimePicker";
import AssessmentRadioChoice from "./AssessmentRadioChoice";
import { VehicleAddresses, DeliveryType } from "../../../system/Domain";
import FormText from "../../../Components/FormText";
import { EditMode } from "./ReturnOrdersForm";

interface Props {
	form: Form;
	addresses: VehicleAddresses;
	editMode: EditMode;
}

const StepAssessment = (props: Props) => {
	const isPoolfleet = props.form.getValue("isPoolfleet");
	const isRkv = props.addresses.isRkv;
	const entryType = props.form.getValue("delivery.deliveryType") ?? "";
	const isCustomerDelivery = entryType === DeliveryType.CustomerDelivery;
	const isCompoundDelivery = entryType === DeliveryType.UsedCars;
	const changingAssessmentInstructionsForbidden = props.editMode === "AssessmentPreProcessed";

	const salesTypeChoices = () => {
		if (isRkv) return { Rkv: "Rkv" };
		if (isPoolfleet) return { Flex: "Flex" };

		return { Sale: "Ja", NoSale: "Nein" };
	};

	useEffect(() => {
		if (isCustomerDelivery) {
			props.form.setValue("doAssessment", true);
			props.form.setValue("assessment.assessmentType", "Minderwertsgutachten");
			props.form.setValue("assessment.assessmentLocation", "DeliveryAddress");
			props.form.setValue(
				"assessment.appointment.notBefore",
				props.form.getValue("delivery.appointment.notBefore"),
			);
		}
	}, [entryType]);

	useEffect(() => {
		const salesType = props.form.getValue("salesType");

		if (isPoolfleet) {
			props.form.setValue("salesType", "Flex");
		} else if (isRkv) {
			props.form.setValue("salesType", "Rkv");
		} else if (isCustomerDelivery) {
			props.form.setValue("salesType", "Sale");
		} else if (!isCompoundDelivery) {
			props.form.setValue("salesType", "NoSale");
		} else if (salesType === "Flex" || salesType === "Rkv") {
			props.form.setValue("salesType", undefined);
		}
	}, [isPoolfleet, isRkv]);

	return (
		<Box mb={2} mt={2}>
			<Grid container spacing={4}>
				<FormChoices
					name="salesType"
					label={isRkv || isPoolfleet ? "Verkaufstyp" : "Frei zum Verkauf"}
					form={props.form}
					options={{ required: true }}
					choices={salesTypeChoices()}
					disabled={isRkv || isPoolfleet}
				/>
				{props.form.getValue("salesType") === "NoSale" && isCompoundDelivery && (
					<FormText
						name="salesTypeComment"
						form={props.form}
						label="Verkaufskommentar"
						type="text"
						options={{ required: true }}
					/>
				)}
				<FormYesNo
					name="doAssessment"
					label="Soll ein Gutachten erstellt werden?"
					form={props.form}
					options={{
						required: true,
					}}
					disabled={isCustomerDelivery || changingAssessmentInstructionsForbidden}
				/>
				{props.form.getValue("doAssessment") === true && (
					<>
						<FormChoices
							name="assessment.assessmentType"
							label="Welcher Gutachtentyp?"
							form={props.form}
							choices={{
								Restwertgutachten: "R1 - Restwertgutachten",
								Minderwertsgutachten: "R2 - Minderwertsgutachten",
							}}
							options={{ required: true }}
							disabled={isCustomerDelivery || changingAssessmentInstructionsForbidden}
						/>
						{props.form.getValue("assessment.assessmentType") && (
							<>
								<Grid item xs={12}>
									<AssessmentRadioChoice
										name="assessment.assessmentLocation"
										form={props.form}
										isRkv={
											false
										} /*this (!=false) leads to confusion, and because there are no stops, all assessments are done on either collection or delivery stations, this is intentionally disabled*/
										aldAddressData={props.addresses}
										required
										disabled={isCustomerDelivery || changingAssessmentInstructionsForbidden}
									/>
								</Grid>
								{props.form.getValue("assessment.assessmentLocation") && (
									<Grid item xs={12}>
										<Box mb={2}>
											<FormText
												name="assessment.assessmentComment"
												form={props.form}
												label="Bemerkung zum Gutachten"
												type="text"
												readOnly={changingAssessmentInstructionsForbidden}
											/>
										</Box>
										<Typography variant="h6" gutterBottom>
											Wunschtermin
										</Typography>
										<Grid container spacing={3}>
											<Grid item xs={12} md={6}>
												<FormDateTimePicker
													name="assessment.appointment.notBefore"
													form={props.form}
													label="ab dem"
													margin="normal"
													variant="date"
													format="DD.MM.YYYY"
													disablePast
													disabled={changingAssessmentInstructionsForbidden}
												/>
											</Grid>
											<Grid item xs={12} sm={6} md={3}>
												<FormDateTimePicker
													name="assessment.appointment.from"
													form={props.form}
													label="Zeit von"
													variant="time"
													ampm={false}
													disabled={changingAssessmentInstructionsForbidden}
												/>
											</Grid>
											<Grid item xs={12} sm={6} md={3}>
												<FormDateTimePicker
													name="assessment.appointment.to"
													form={props.form}
													label="bis"
													variant="time"
													ampm={false}
													disabled={changingAssessmentInstructionsForbidden}
												/>
											</Grid>
										</Grid>
									</Grid>
								)}
							</>
						)}
					</>
				)}
				{props.form.getValue("isRegistered") === true && (
					<>
						<FormYesNo
							name="doUnregistration"
							label="Soll das Auto abgemeldet werden?"
							form={props.form}
							options={{
								required: true,
							}}
						/>
					</>
				)}
				<Typography variant="h6" gutterBottom sx={{ marginTop: 8 }}>
					Kostenverteilung
				</Typography>
				<FormText
					name="transportCostInstallments"
					form={props.form}
					label={"Transportkosten in Rate kalkuliert"}
					type="number"
					startAdornment={<InputAdornment position="start">€</InputAdornment>}
				/>
				<FormText
					name="costTransferCustomer"
					form={props.form}
					label={"Weiterbelastung an Kunden"}
					type="number"
					startAdornment={<InputAdornment position="start">€</InputAdornment>}
				/>
				<FormText
					name="debitingAld"
					form={props.form}
					label={"Kosten zu Lasten ALD"}
					type="number"
					startAdornment={<InputAdornment position="start">€</InputAdornment>}
				/>
			</Grid>
		</Box>
	);
};

export default StepAssessment;
