const translations: any = {
	"No Leasman vehicle sales found": "Keinen Leasman Verkaufsvorgang gefunden. Erstellen Sie zunächst einen Verkaufsvorgang in Leasman",
	"Current vehicle sales process step is invalid": "Der Verkaufsprozess befindet sich in einem ungültigen Status",
	"No active sales channel offer found": "Es gibt keinen aktiven Vertriebskanal für das Fahrzeug",
	"Leasman sales price is below minimum price": "Der Leasman Verkaufspreis ist unterhalb des im Pricing vorgegebenen minimalem Verkaufspreis",
	"Vehicle not invoiced in Leasman sales": "Das Fahrzeug ist noch nicht in Leasman verkauft. Bitte zunächst eine Verkaufsrechnung in Leasman erstellen",
	"Leasman sale is still active. You have to credit leasman sale first": "Der Verkauf in Leasman muss zunächst storniert werden",
	"Current leasman vehicle sales process step is invalid": "Der Verkauf in Leasman ist in einem ungültigen Status. Bitte zunächst einen gültigen Verkauf anlegen.",
	"You cannot confirm payment for sales you have sold": "Aus Compliance-Gründen können Sie den Zahlungseingang für ein Fahrzeug, das Sie verkauft haben, nicht selber bestätigen",
	"Not ready for shipping": "Der Verkaufsprozess ist nicht in einem Status der es erlaubt das Fahrzeug auszuliefern",
	"Cannot add pictures as there is no open picture service": "Es gibt keine offene Fototätigkeit für das Zielfahrzeug",
	"There is already open service of requested type": "Es gibt bereits eine offene Tätigkeit des gewünschten Typs für das Fahrzeug",
	"Vehicle status Ausgang can't be set since the vehicle has a sale in status": "Fahrzeugstatus Ausgang kann nicht gesetzt werden, da das Fahrzeug im Verkauf ist",
	"There is already an active return form attached to order": "Ein aktives Protokoll ist noch nicht final abgeschlossen."
}

function translateError(errorMessage: string, partialMatch: boolean = false) {
	if (partialMatch) {
		const translatedError = Object.entries(translations).find(
			([key]) => errorMessage.startsWith(key)
		);

		return translatedError ? translatedError[1] : errorMessage;
	} else {
		const translatedError = translations[errorMessage];
		if (translatedError) {
			return translatedError;
		}
	}
	return errorMessage;
}

export default translateError;
