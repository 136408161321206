import VehicleHeader from "../VehicleHeader";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { FileReference, Vehicle, VehiclePictureSet } from "../../../system/Domain";
import { Box, CircularProgress } from "@mui/material";
import useVehicle from "../../../system/useVehicle";
import Layout from "../../../Components/Layout/Layout";
import VehicleHeaderMenu from "../VehicleHeaderMenu";
import LotScanContextMenu from "../LotScanContextMenu";
import VehicleSubMenu from "../VehicleSubMenu";
import VehiclePictureList from "../../../Components/VehiclePictureList";
import VehicleNavigation from "Navigation/VehicleNavigation";
import VehiclePictureSetsSidebar from "./VehiclePictureSetsSidebar";
import axios, { AxiosResponse } from "axios";
import useAsyncEffect from "../../../system/useAsyncEffect";

export interface ImageUploadProps {
	fileReference?: FileReference,
	propertyName?: string
}

const pictureNameMappings = (pictureName: string) => {
	switch (pictureName) {
		case "vorne-links":
			return "frontLeft"
		case "hinten-links":
			return "backLeft"
		case "hinten-rechts":
			return "backRight"
		case "vorne-rechts":
			return "frontRight"
		case "scheinwerfer":
			return "headlights"
		case "reifen-und-felgen":
			return "tiresAndRims"
		case "innenraum-vorne":
			return "interiorFront"
		case "innenraum-hinten":
			return "interiorBack"
		case "kofferraum":
			return "trunk"
		case "cockpit":
			return "cockpit"
		case "infotainment":
			return "infotainment"
		case "instruments":
			return "instruments"
		default:
			break;
	}
};

export default () => {
	const { id } = useParams<{ id: string }>();
	const [vehicle, setVehicle, reloadVehicle] = useVehicle(id);
	const [currentPictureSet, setCurrentPictureSet] = useState<VehiclePictureSet | null>(null);
	const [updatedSets, setUpdatedSets] = useState<string[]>([]);
	const [replaceMode, setReplaceMode] = useState(false);

	useEffect(() => {
		if (vehicle && vehicle.pictureSets.length > 0) {
			setCurrentPictureSet(vehicle.pictureSets[vehicle.pictureSets.length - 1]);
		} else {
			setCurrentPictureSet(null);
		}
	}, [vehicle]);

	useAsyncEffect(async () => {
		if (!replaceMode && vehicle) {
			setUpdatedSets([]);
			await reloadVehicle();
		}
	}, [replaceMode]);

	const handlePicureReplaceSetter = (replaceImageProps: ImageUploadProps) => {
		const picturePosition = pictureNameMappings(replaceImageProps.propertyName);
		const updatedPictureSet = currentPictureSet;
		updatedPictureSet[picturePosition] = replaceImageProps.fileReference;
		setCurrentPictureSet(updatedPictureSet);
		updatedSets.push(updatedPictureSet.id);
		setUpdatedSets([...updatedSets]);
	};

	const handleUploadUpdatedPictureSet = async () => {
		if (!vehicle || !currentPictureSet) return;

		const { data: updatedVehicle } = await axios.put<VehiclePictureSet, AxiosResponse<Vehicle>>(`/api/vehicles/${vehicle.id}/picture-sets`, currentPictureSet);
		setVehicle(updatedVehicle);
		setCurrentPictureSet(updatedVehicle.pictureSets[updatedVehicle.pictureSets.length - 1]);
		setUpdatedSets(updatedSets.filter(us => us !== currentPictureSet.id));
		setReplaceMode(false);
	};

	return (
		<Layout
			title="Fotosätze"
			plateNumber={vehicle?.plateNumber}
			navigation={<VehicleNavigation vehicle={vehicle} />}
			contextMenu={[
				<LotScanContextMenu />,
				<VehicleHeaderMenu vehicle={vehicle} />
			]}
			subMenu={vehicle && <VehicleSubMenu vehicle={vehicle} />}
			sidebar={
				<VehiclePictureSetsSidebar
					vehicle={vehicle}
					setVehicle={setVehicle}
					currentPictureSet={currentPictureSet}
					setCurrentPictureSet={setCurrentPictureSet}
					replaceMode={replaceMode}
					setReplaceMode={setReplaceMode}
					handleUploadUpdatedPictureSet={handleUploadUpdatedPictureSet}
					updatedSets={updatedSets}
				/>
			}
		>
			{!vehicle && (
				<CircularProgress size={48} />
			)}
			{vehicle && (
				<>
					<VehicleHeader vehicle={vehicle} />
					{currentPictureSet && (
						<Box mt={2}>
							<VehiclePictureList
								vin={vehicle.vin}
								pictureSet={currentPictureSet}
								replaceMode={replaceMode}
								setter={handlePicureReplaceSetter}
							/>
						</Box>
					)}
				</>
			)}
		</Layout>
	);
};
