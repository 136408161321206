import FormUpload from "../../../../Components/FormUpload";
import { Form } from "../../../../system/useForm";

export default ({ form }: { form: Form }) => {
	return (
		<>
			<FormUpload
				name="vehiclePictures.frontLeft"
				label="Vorne Links"
				form={form}
				previewUrl="/static/vehiclePictures.frontLeft.jpg"
				options={{
					required: true
				}}
			/>
			<FormUpload
				name="installedTiresAndRims.rimFrontLeft"
				label="Felge Vorne Links"
				form={form}
				previewUrl="/static/vehiclePictures.rimFrontLeft.jpg"
				options={{
					required: true
				}}
			/>
			<FormUpload
				name="vehiclePictures.backLeft"
				label="Hinten Links"
				form={form}
				previewUrl="/static/vehiclePictures.backLeft.jpg"
				options={{
					required: true
				}}
			/>
			<FormUpload
				name="installedTiresAndRims.rimBackLeft"
				label="Felge Hinten Links"
				form={form}
				previewUrl="/static/vehiclePictures.rimBackLeft.jpg"
				options={{
					required: true
				}}
			/>
			<FormUpload
				name="vehiclePictures.backCenter"
				label="Hinten Mitte"
				form={form}
				previewUrl="/static/vehiclePictures.backCenter.jpg"
				options={{
					required: true
				}}
			/>
			<FormUpload
				name="vehiclePictures.trunk"
				label="Gepäckraum"
				form={form}
				previewUrl="/static/vehiclePictures.trunk.jpg"
				options={{
					required: true
				}}
			/>
			<FormUpload
				name="vehiclePictures.backRight"
				label="Hinten Rechts"
				form={form}
				previewUrl="/static/vehiclePictures.backRight.jpg"
				options={{
					required: true
				}}
			/>
			<FormUpload
				name="installedTiresAndRims.rimBackRight"
				label="Felge Hinten Rechts"
				form={form}
				previewUrl="/static/vehiclePictures.rimBackRight.jpg"
				options={{
					required: true
				}}
			/>
			<FormUpload
				name="vehiclePictures.frontRight"
				label="Vorne Rechts"
				form={form}
				previewUrl="/static/vehiclePictures.frontRight.jpg"
				options={{
					required: true
				}}
			/>
			<FormUpload
				name="installedTiresAndRims.rimFrontRight"
				label="Felge Vorne Rechts"
				form={form}
				previewUrl="/static/vehiclePictures.rimFrontRight.jpg"
				options={{
					required: true
				}}
			/>
			<FormUpload
				name="vehiclePictures.tiresAndRims"
				label="Rad vorne rechts"
				form={form}
				previewUrl="/static/vehiclePictures.tiresAndRims.jpg"
				options={{
					required: true
				}}
			/>
			<FormUpload
				name="vehiclePictures.frontCenter"
				label="Vorne Mitte"
				form={form}
				previewUrl="/static/vehiclePictures.frontCenter.jpg"
				options={{
					required: true
				}}
			/>
			<FormUpload
				name="vehiclePictures.windshield"
				label="Windschutzscheibe"
				form={form}
				previewUrl="/static/vehiclePictures.windshield.jpg"
				options={{
					required: true
				}}
			/>
			<FormUpload
				name="vehiclePictures.interior"
				label="Innenraum"
				form={form}
				previewUrl="/static/vehiclePictures.interior.jpg"
				options={{
					required: true
				}}
			/>
		</>
	);
};
