import { PropsWithChildren } from "react";

export interface SidebarGroupsProps {
}

export default ({ children } : PropsWithChildren<SidebarGroupsProps>) => {
	return <>
		{children}
	</>;
}
