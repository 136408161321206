import { useState } from 'react';
import OrdersSubMenu from "../OrdersSubMenu";
import ReturnOrdersOverviewSidebar from "./ReturnOrdersOverviewSidebar";
import Layout from "../../../Components/Layout/Layout";
import useQuery, { IQuery } from "../../../system/useQuery";
import moment from "moment";
import { ReturnOrder, ReturnOrderSession, ReturnOrdersOverviewPerspective } from "../../../system/Domain";
import useAsyncEffect from "../../../system/useAsyncEffect";
import axios, { AxiosRequestConfig } from "axios";
import { useHistory } from "react-router";
import { Box, CircularProgress, Grid, IconButton } from "@mui/material";
import FluentGrid from "../../../Components/FluentGrid";
import Tile from "../../../Components/Tiles/Tile";
import TileContent from "../../../Components/Tiles/TileContent";
import DashboardKpi, { kpiPlaceholder } from "../../../Components/Dashboard/DashboardKpi";
import TileContentAction from "../../../Components/Tiles/TileContentAction";
import TileContentActions from "../../../Components/Tiles/TileContentActions";
import useAxiosEffect from "../../../system/useAxiosEffect";
import formatNumber from "../../../system/formatNumber";
import Search from "../../../Components/Search";
import ReturnOrdersOverviewTable from "./ReturnOrdersOverviewTable";
import FetchNextButton from "../../../Components/FetchNextButton";
import OrdersNavigation from 'Navigation/OrdersNavigation';
import { Cached } from "@mui/icons-material";
import useCompounds from 'system/useCompounds';

interface ReturnOrderKpis {
	unprocessed: number
	processed: number
	imported: number
	cancelled: number
	linkedAssessments: number
	unprocessedExternalAssessments: number
	customerCreatedToComplete: number
	processedLastWeek: number
	activeTransportOrders: number
	deliveriesToForeignAddresses: number
}

export interface ReturnOrdersOverviewQuery extends IQuery {
	perspective: ReturnOrdersOverviewPerspective
	dateTo?: string
	dateFrom?: string
	sort: string
	fullText?: string
	customerOrderApproved?: boolean
	isDrivable?: boolean
	isRegistered?: boolean
	hasValidMainInspection?: boolean
	tireTypes?: string
	dateFilter?: string
	compound?: string
}

export type ReturnOrderDateFilter = "DateCreated" | "DateCancelled" | "CollectionReferenceDate";

const ReturnOrdersDashboard = () => {
	const history = useHistory();

	const [isProcessing, setIsProcessing] = useState(false);
	const [isProcessingAll, setIsProcessingAll] = useState(false);
	const [isProcessingExternalAssessments, setIsProcessingExternalAssessments] = useState(false);
	const [isAdding, setIsAdding] = useState(false);

	const [checkedReturnOrders, setCheckedReturnOrders] = useState<string[]>([]);
	const [returnOrderKpis, setReturnOrderKpis] = useState<ReturnOrderKpis>();
	const [isFetching, setIsFetching] = useState(false);
	const [returnOrders, setReturnOrders] = useState<ReturnOrder[]>();
	const [showProcessedLastWeek, setShowProcessedLastWeek] = useState(false);
	const [activeSession, setActiveSession] = useState<ReturnOrderSession>();
	const [isLoading, setIsLoading] = useState(true);
	const [compounds] = useCompounds({ includeAmazonCompounds: false });

	const loadKpis = async (axiosConfig: AxiosRequestConfig = {}) => {
		const { data: kpiData } = await axios.get<ReturnOrderKpis>("/api/kpis/orders/returns", axiosConfig);
		setReturnOrderKpis(kpiData);
	};

	useAxiosEffect(loadKpis, []);

	const [query, setQuery, fetchNext, resetQuery] = useQuery<ReturnOrdersOverviewQuery>('returnOrdersOverviewQuery', {
		perspective: "All",
		skip: 0,
		take: 20,
		fullText: "",
		dateFilter: "DateCreated",
		dateTo: moment().add(5, 'd').startOf('d').toISOString(true),
		dateFrom: moment().add(-1, 'year').startOf('d').toISOString(true),
		sort: "dateCreated:desc"
	});

	const loadActiveSession = async () => {
		setIsLoading(true);

		try {
			const response = await axios.get<ReturnOrderSession>(`/api/orders/returns/sessions/latest`);

			if (response.data) {
				setActiveSession(response.data);
			}
		} finally {
			setIsLoading(false);
		}
	};

	useAsyncEffect(loadActiveSession, [query]);

	const loadReturnOrders = async (cancelToken) => {
		setCheckedReturnOrders([]);

		const axiosCancelTokenSource = axios.CancelToken.source();
		cancelToken?.promise.then(() => axiosCancelTokenSource.cancel());

		setIsFetching(true);

		try {
			const { data: ro } = await axios.get<ReturnOrder[]>(`/api/orders/returns`, {
				params: query,
				cancelToken: axiosCancelTokenSource.token
			});

			if (query.skip > 0) {
				setReturnOrders(o => [
					...(o ?? []),
					...ro
				]);
			} else {
				setReturnOrders(ro)
			}
		} finally {
			setIsFetching(false);
		}
	};

	useAsyncEffect(loadReturnOrders, [query]);

	const handleNewSession = async () => {
		if (!returnOrders) return;

		setIsProcessing(true);
		let sessionId: string;

		try {
			const returnOrderIds =
				checkedReturnOrders.length > 0 && query.perspective === "Unprocessed"
					? checkedReturnOrders
					: returnOrders.filter(f => !f.importId && (f.status === "Created" || f.status === "Approved")).map(m => m.id);

			const { data: responseSessionId } = await axios.post<string>(`/api/orders/returns/session/create`,
				returnOrderIds
			);

			sessionId = responseSessionId;
		} finally {
			setIsProcessing(false);
		}

		if (sessionId) {
			loadActiveSession();
			loadReturnOrders(null);
		}
	};

	const addToLastSession = async () => {
		if (!returnOrders) return;

		setIsAdding(true);
		let sessionId: string;

		try {
			const returnOrderIds =
				checkedReturnOrders.length > 0 && query.perspective === "Unprocessed"
					? checkedReturnOrders
					: returnOrders.filter(f => !f.importId && (f.status === "Created" || f.status === "Approved")).map(m => m.id);

			const { data: responseSessionId } = await axios.post<string>(`/api/orders/returns/session/add`,
				returnOrderIds
			);

			sessionId = responseSessionId;
		} finally {
			setIsAdding(false);
		}

		if (sessionId) {
			loadReturnOrders(null);
		}
	};

	const handleProcessExternalAssessments = async () => {
		if (!returnOrders) return;
		if (checkedReturnOrders.length === 0) return;

		setIsProcessingExternalAssessments(true);

		try {
			await axios.post<string>(`/api/orders/returns/import/external-assessments`,
				checkedReturnOrders
			);

			history.push(`/orders/returns/sessions`);
		} finally {
			setIsProcessingExternalAssessments(false);
		}
	}

	const canProcess = returnOrders?.some(f => !f.importId && (f.status === "Created" || f.status === "Approved")) ?? false;

	return (
		<Layout
			title="Rückholungen"
			navigation={<OrdersNavigation />}
			subMenu={<OrdersSubMenu />}
			sidebar={<ReturnOrdersOverviewSidebar
				query={query}
				setQuery={setQuery}
				resetQuery={resetQuery}
				canProcess={canProcess}
				isProcessing={isProcessing}
				isProcessingAll={isProcessingAll}
				isProcessingExternalAssessments={isProcessingExternalAssessments}
				isAdding={isAdding}
				handleProcessExternalAssessments={handleProcessExternalAssessments}
				handleNewSession={handleNewSession}
				addToLastSession={addToLastSession}
				refresh={() => loadKpis()}
				activeSession={activeSession}
				isLoading={isLoading}
				compounds={compounds}
			/>}
		>
			<Box>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<FluentGrid itemWidth={300} itemGap={6}>
							<Tile title="Bereit zur Beauftragung">
								<TileContent dense>
									<Box p={2}>
										{returnOrderKpis && (
											<DashboardKpi
												primary={formatNumber(returnOrderKpis.unprocessed)}
												secondary="Insgesamt"
											/>
										)}
										{!returnOrderKpis && kpiPlaceholder}
									</Box>
									<TileContentActions align="right">
										<TileContentAction
											disabled={!returnOrderKpis?.unprocessed}
											onClick={() => {
												setReturnOrders([]);
												setQuery(q => ({
													...q,
													perspective: "Unprocessed"
												}));
											}}
											label="Anzeigen"
										/>
									</TileContentActions>
								</TileContent>
							</Tile>
							<Tile title="In Bearbeitung">
								<TileContent dense>
									<Box p={2}>
										{returnOrderKpis && (
											<DashboardKpi
												primary={formatNumber(returnOrderKpis.imported)}
												secondary="Insgesamt"
											/>
										)}
										{!returnOrderKpis && kpiPlaceholder}
									</Box>
									<TileContentActions align="right">
										<TileContentAction
											disabled={!returnOrderKpis?.imported}
											onClick={() => {
												setReturnOrders([]);
												setQuery(q => ({
													...q,
													perspective: "Imported"
												}));
											}}
											label="Anzeigen"
										/>
									</TileContentActions>
								</TileContent>
							</Tile>
							<Tile title="Beauftragt">
								<TileContent dense>
									<Box p={2}>
										{returnOrderKpis && showProcessedLastWeek && (
											<DashboardKpi
												primary={formatNumber(returnOrderKpis.processedLastWeek)}
												secondary={"in den letzten 7 Tagen"}
											/>
										)}
										{returnOrderKpis && !showProcessedLastWeek && (
											<DashboardKpi
												primary={formatNumber(returnOrderKpis.activeTransportOrders)}
												secondary="Aktive Transportaufträge"
											/>
										)}
										{!returnOrderKpis && kpiPlaceholder}
									</Box>
									<TileContentActions
										align="space-between"
										icon={
											<IconButton
												size="small"
												onClick={() => setShowProcessedLastWeek(v => !v)}>
												<Cached />
											</IconButton>
										}
									>
										{showProcessedLastWeek && <TileContentAction
											disabled={!returnOrderKpis?.processedLastWeek && showProcessedLastWeek}
											onClick={() => {
												setReturnOrders([]);
												setQuery(q => ({
													...q,
													perspective: ("Processed")
												}));
											}}
											label="Alle anzeigen"
										/>}
									</TileContentActions>
								</TileContent>
							</Tile>
							<Tile title="Storniert">
								<TileContent dense>
									<Box p={2}>
										{returnOrderKpis && (
											<DashboardKpi
												primary={formatNumber(returnOrderKpis.cancelled)}
												secondary={"in den letzten 7 Tagen"}
											/>
										)}
										{!returnOrderKpis && kpiPlaceholder}
									</Box>
									<TileContentActions align="right">
										<TileContentAction
											disabled={!returnOrderKpis?.cancelled}
											onClick={() => {
												setReturnOrders([]);
												setQuery(q => ({
													...q,
													perspective: "Cancelled"
												}));
											}}
											label="Alle Anzeigen"
										/>
									</TileContentActions>
								</TileContent>
							</Tile>
							<Tile title="Fremdauslieferungen" tooltip={
								<Box>
									Fremdauslieferungen:
									<br /><br />
									- Lieferziel ist nicht Dorfmark (inkl. Quertransporte)
									<br />
									- keine RKV Auslieferungen
								</Box>
							}>
								<TileContent dense>
									<Box p={2}>
										{returnOrderKpis && (
											<DashboardKpi
												primary={formatNumber(returnOrderKpis.deliveriesToForeignAddresses)}
												secondary="Insgesamt"
											/>
										)}
										{!returnOrderKpis && kpiPlaceholder}
									</Box>
									<TileContentActions align="right">
										<TileContentAction
											disabled={!returnOrderKpis?.deliveriesToForeignAddresses}
											onClick={() => {
												setReturnOrders([]);
												setQuery(q => ({
													...q,
													perspective: "DeliveriesToForeignAddresses"
												}));
											}}
											label="Anzeigen"
										/>
									</TileContentActions>
								</TileContent>
							</Tile>
							<Tile title="Externe Gutachten">
								<TileContent dense>
									<Box p={2}>
										{returnOrderKpis && (
											<DashboardKpi
												primary={formatNumber(returnOrderKpis.unprocessedExternalAssessments)}
												secondary="nicht beauftragt"
											/>
										)}
										{!returnOrderKpis && kpiPlaceholder}
									</Box>
									<TileContentActions align="right">
										<TileContentAction
											disabled={!returnOrderKpis?.unprocessedExternalAssessments}
											onClick={() => {
												setReturnOrders([]);
												setQuery(g => ({
													...g,
													perspective: "UnprocessedExternalAssessments"
												}));
											}}
											label="Anzeigen"
										/>
									</TileContentActions>
								</TileContent>
							</Tile>
							<Tile title="Kundenaufträge">
								<TileContent dense>
									<Box p={2}>
										{returnOrderKpis && (
											<DashboardKpi
												primary={formatNumber(returnOrderKpis.customerCreatedToComplete)}
												secondary="nicht vervollständigt"
											/>
										)}
										{!returnOrderKpis && kpiPlaceholder}
									</Box>
									<TileContentActions align="right">
										<TileContentAction
											disabled={!returnOrderKpis?.customerCreatedToComplete}
											onClick={() => {
												setReturnOrders([]);
												setQuery(g => ({
													...g,
													perspective: "CustomerCreated",
													customerOrderApproved: false
												}));
											}}
											label="Anzeigen"
										/>
									</TileContentActions>
								</TileContent>
							</Tile>
						</FluentGrid>
					</Grid>
					<Grid item xs={12}>
						<Box mb={2}>
							<Search
								value={query.fullText}
								onSearch={(value) => value !== query.fullText && setQuery(q => ({
									...q,
									skip: 0,
									fullText: value
								}))}
							/>
						</Box>
						{!returnOrders && (
							<CircularProgress size={48} />
						)}
						{returnOrders && (
							<>
								<ReturnOrdersOverviewTable
									checkable
									returnOrders={returnOrders}
									perspective={query.perspective}
									checked={checkedReturnOrders}
									setChecked={setCheckedReturnOrders}
									compounds={compounds}
								/>
								<FetchNextButton
									hidden={returnOrders.length < query.take + query.skip}
									mb={3}
									onNext={fetchNext}
									disabled={isFetching}
									onAltNext={() => fetchNext(100)}
								/>
							</>
						)}
					</Grid>
				</Grid>
			</Box>
		</Layout>
	);
};

export default ReturnOrdersDashboard;
