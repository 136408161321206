import { useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import { TransportOrder } from "../../../system/Domain";
import axios from "axios";
import { useHistory } from "react-router-dom";
import useAsyncEffect from "../../../system/useAsyncEffect";
import qs from "query-string";
import Layout from "../../../Components/Layout/Layout";
import OrdersSubMenu from "../OrdersSubMenu";

// @ts-ignore
import QrReader from "react-qr-reader";
import OrdersNavigation from "../../../Navigation/OrdersNavigation";


export default () => {
	const theme = useTheme();
	const history = useHistory();

	const [error, setError] = useState<Error>();
	const [vin, setVin] = useState<string>();

	const handleError = (error: any) => {
		console.error(error);
		setError(error);
	};

	const handleScan = async (data: string) => {
		if (!data) {
			return;
		}

		if (data.startsWith("pickup://")) {
			history.push(`/vehicles/ship?pickupToken=${data.substring(9)}`);
			return;
		}

		const match = data.match(/^fin:\/\/(?<vin>[0-9A-HJ-NPR-Z]{17})$/i);
		if (!match || !match.groups || !match.groups["vin"]) {
			setError(new Error(`Ungültiges Format der FIN im QR Code. Folgender Text wurde extrahiert: ${data}`));
			return;
		}

		setVin(match.groups["vin"]);
	};

	useAsyncEffect(async () => {
		if (!vin) {
			return;
		}

		const { data: orders } = await axios.get<TransportOrder[]>(`/api/orders/transports`, {
			params: {
				vin,
				status: ["Assigned", "Completed"]
			},
			paramsSerializer: params => qs.stringify(params)
		});

		if (orders.length === 1) {
			history.push({
				pathname: `/orders/transports/${orders[0].id}`
			});
		} else if (orders.length > 1) {
			setError(new Error("Mehr als einen möglichen Auftrag gefunden. Inkonsistenter Datenstand."));
		} else {
			setError(new Error(`Zu der FIN ${vin} existiert kein Auftrag.`));
		}
	}, [vin]);

	return (
		<Layout
			navigation={<OrdersNavigation />}
			subMenu={<OrdersSubMenu/>}
		>
			{!!error && (
				<>
					<Box sx={{
						color: theme.palette.error.main,
						fontSize: "1.2rem",
						textAlign: "center"
					}}>
						<div>{error.message}</div>
					</Box>
					<Box mt={3} textAlign="center">
						<Button
							variant="text"
							size="medium"
							// color="secondary"
							onClick={() => {
								setError(undefined);
								setVin(undefined);
							}}
						>Erneut versuchen</Button>
					</Box>
				</>
			)}
			{!error && !vin && (
				<Box sx={{
					display: "flex",
					flexGrow: 1,
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center"
				}}>
					<QrReader
						delay={100}
						onError={handleError}
						onScan={handleScan}
						style={{ width: "100%", maxWidth: 500 }} />
				</Box>
			)}
		</Layout>
	);
};
