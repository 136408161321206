import _ from "lodash";
import { TabPanel, TabPanelProps } from "@mui/lab";
import { AversiSession } from "./AversiSessionsPage";
import formatCurrency from "../../system/formatCurrency";
import handleDownload from "../../system/handleDownload";
import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";

interface Props extends TabPanelProps {
	aversiSession : AversiSession
}

const AversiInvoiceTab = ({ aversiSession, ...rest } : Props) => {
	const tenants = _.groupBy(aversiSession.attachments, "tenantName");
	const hasData = Object.keys(tenants).length > 0;

	return (
		<TabPanel {...rest}>

			{!hasData && (
				<Box my={2}>
					<Typography>Keine Rechnungsdaten vorhanden. Begleitschreiben sind noch nicht erstellt</Typography>
				</Box>
			)}

			{hasData && (
				<Box mx={2}>
					<List>
						{Object.keys(tenants).map(tenant => {
							const totalAmount = _.sumBy(tenants[tenant], "totalAmount");

							return (
								<ListItem
									button
									onClick={handleDownload(`/api/admin/aversi/sessions/${aversiSession.id}/invoices/${tenant}`)}
								>
									<ListItemText
										primary={tenant}
										secondary={`Gesamtbetrag ${formatCurrency(totalAmount)}`}
									/>
								</ListItem>
							)
						})}
					</List>
				</Box>
			)}
		</TabPanel>
	)
};

export default AversiInvoiceTab;
