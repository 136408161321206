import useReturnOrder from "../../../system/useReturnOrder";
import { useEffect, useState } from "react";
import CustomerConfirmationDialog from "../CustomerConfirmationDialog";
import axios from "axios";
import { CustomerOrder, ReturnOrder } from "../../../system/Domain";
import LoadingIndicator from "../../../Components/LoadingIndicator";
import { Box, Divider, Grid, Typography } from "@mui/material";
import Tile from "../../../Components/Tiles/Tile";
import TileContent from "../../../Components/Tiles/TileContent";
import { ReturnList } from "../Steps/StepCollectionFinalRemarks";
import CustomerCarState from "../CustomerCarState";
import CollectionOverview from "./CollectionOverview";
import CollectionChoice, { CustomerReturnFormType } from "./CollectionChoice";
import { CustomerOrderWithReturnOrderId } from "../CustomerStatusOverview";
import CustomerChoiceDisplay from "../CustomerChoiceDisplay";
import { useRecoilState } from "recoil";
import { customerTitle } from "../../../system/atoms/customerTitle";
import { Commute } from "@mui/icons-material";

interface Props {
	customerOrder: CustomerOrderWithReturnOrderId
	toStatusOverview: () => void
	customerCancellation: (customerOrder: CustomerOrder) => void
}

const calculateFormData = (returnOrder: ReturnOrder): CustomerReturnFormType => {
	return {
		plateNumber: returnOrder.plateNumber,
		contractNumber: returnOrder.leasmanContract?.id || 0,
		isElectricCar: returnOrder.isElectricCar,
		isDrivable: returnOrder.isDrivable,
		drivableComment: returnOrder.drivableComment,
		isRegistered: returnOrder.isRegistered,
		hasValidMainInspection: returnOrder.hasValidMainInspection,
		mainInspectionValidUntil: returnOrder.mainInspectionValidUntil,
		tireType: returnOrder.tireType,
		carType: returnOrder.carType,
		collection: returnOrder.collection,
		engineType: returnOrder.engineType
	}
};

const CollectionStatusOverview = (props: Props) => {
	const [, setCustomerTitle] = useRecoilState(customerTitle);
	const [returnOrder, loadingCompleted] = useReturnOrder(props.customerOrder.returnOrderId);
	const couldEdit = returnOrder?.status === "CustomerCreated" || returnOrder?.status === "Approved";
	const [wantsToEdit, setWantsToEdit] = useState<boolean>(false);

	useEffect(() => setCustomerTitle("Status Abholung"), []);

	const edit = () => {
		setWantsToEdit(true);
	};

	const back = () => {
		setWantsToEdit(false);
		setCustomerTitle("Status Abholung");
	};

	const confirmCancellation = async () => {
		const answer = await CustomerConfirmationDialog({
			title: "Abholung stornieren",
			message: "Sind Sie sich sicher, dass sie die Abholung stornieren wollen?"
		});

		if (answer.yes) {
			const { data: customerOrder } = await axios.post<CustomerOrder>(`/api/orders/customers/${props.customerOrder.id}/revoke`);

			props.customerCancellation(customerOrder);
		}
	};

	return (
		<LoadingIndicator isLoading={!loadingCompleted}>
			{returnOrder && !wantsToEdit && (
				<>
					{!couldEdit && (
						<Box mb={3} mt={3}>
							<Typography variant="body1">
								<strong>Der angegebene Kundenauftrag wurde bereits ausgefüllt und intern bearbeitet.</strong>
								<br />
								Daher kann sie nicht bearbeitet werden.
								<br />
								Falls Sie einen Fehler festgestellt haben, wenden Sie sich bitte an ihren Kundenbetreuer.
							</Typography>
						</Box>
					)}
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12} lg={10}>
							<Box mt={2}>
								<CustomerChoiceDisplay
									title="Abholung geplant"
									icon={<Commute />}
								/>
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={10} xl={6}>
							<Tile>
								<Box p={2}>
									<Typography variant="h6">Rückgabehinweise</Typography>
								</Box>
								<Divider />
								<TileContent>
									<ReturnList />
								</TileContent>
							</Tile>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={2} xl={6}></Grid>
						<Grid item xs={12} sm={6} md={6} lg={5} xl={3}>
							<CustomerCarState returnOrder={returnOrder} />
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={5} xl={3}>
							<CollectionOverview
								returnOrder={returnOrder}
								primary={couldEdit ? { title: "Bearbeiten", action: edit } : undefined}
								secondary={couldEdit ? { title: "Stornieren", action: confirmCancellation } : undefined}
							/>
						</Grid>
					</Grid>
				</>
			)}
			{couldEdit && wantsToEdit && (
				<CollectionChoice
					customerOrder={props.customerOrder}
					toStatusOverview={props.toStatusOverview}
					formData={calculateFormData(returnOrder)}
					backAction={back}
				/>
			)}
		</LoadingIndicator>
	);
};

export default CollectionStatusOverview;
