import { Route, RouteProps } from "react-router";
import { UserRole } from "../system/Domain";
import useUser from "../system/useUser";
import { Box } from "@mui/material";

export interface PrivateRouteProps extends RouteProps {
	roles?: UserRole[];
	companies?: string[];
}

export default ({ roles, companies, ...rest }: PrivateRouteProps) => {
	const [user, , hasRole, , hasAnyRole] = useUser();

	const hasCorrectCompany = !companies || companies.includes(user.company?.name);

	if (!roles || hasRole(UserRole.AldAdmin) || (hasAnyRole(roles) && hasCorrectCompany)) {
		return <Route {...rest} />;
	}

	return (
		<Box m={6} textAlign="center">
			Not authorized
		</Box>
	);
};
