import { useState } from 'react';
import { UserRole } from "../../system/Domain";
import ThemedDialog from "../../Components/ThemedDialog";
import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";
import { Button, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";

interface Props extends AlterPermissionsOptions, ReactConfirmProps<Permissions> {
	show: boolean
}

interface Permissions {
	roles: UserRole[]
}



const AlterPermissionsDialog = (props: Props) => {
	const [roles, setRoles] = useState<UserRole[]>(props.roles);

	let enumValues: Array<string> = [];
	for (let value in UserRole) {
		enumValues.push(value);
	}

	const handleClose = () => {
		props.cancel();
	};

	const handleChange = (event: SelectChangeEvent<UserRole[]>) => {
		setRoles(event.target.value as UserRole[]);
	};

	return (
		<ThemedDialog open={props.show} maxWidth="sm" fullWidth onClose={handleClose}>
			<DialogTitle>
				Berechtigungen anpassen
			</DialogTitle>
			<DialogContent sx={{
				paddingTop: 2,
				paddingBottom: 4,
				paddingLeft: 3,
				paddingRight: 3
			}}>
				<FormControl fullWidth variant="standard">
					<InputLabel>Berechtigungen</InputLabel>
					<Select
						multiple
						fullWidth
						value={roles}
						onChange={handleChange}
					>
						{enumValues.map((name) => (
							<MenuItem key={name} value={name}>
								{name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="secondary"
					href=""
					onClick={handleClose}
				>
					Abbrechen
				</Button>
				<Button
					variant="outlined"
					color="primary"
					href=""
					onClick={() => props.proceed({ roles })}
				>
					Ok
				</Button>
			</DialogActions>
		</ThemedDialog>
	);
};

interface AlterPermissionsOptions {
	roles: UserRole[]
}

export default (options: AlterPermissionsOptions) => {
	const dialog = confirmable(props => <AlterPermissionsDialog {...props} {...options} />);
	return createConfirmation<Permissions>(dialog)();
}
