import { useCallback } from "react";
import { useDropzone } from 'react-dropzone'
import uploadImage from '../cloud-upload.png';
import ThemedDialog from "../Components/ThemedDialog";
import { confirmable, createConfirmation } from "react-confirm";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

const FileDialog = ({ show, proceed, title, multiple }) => {
	const onDrop = useCallback(files => proceed({
		ok: true,
		files
	}), [proceed]);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		multiple
	});

	return (
		<ThemedDialog open={show} maxWidth="md">
			<DialogTitle>{title || "Dateien laden"}</DialogTitle>
			<DialogContent>
				<Box {...getRootProps()} sx={{
					padding: 36,
					paddingTop: 0,
					paddingBottom: 0,
					minHeight: 240,
					minWidth: 600,
					borderWidth: 2,
					borderColor: "#757575",
					borderStyle: 'dashed',
					borderRadius: 5,
					textAlign: 'center'
				}}>
					<input {...getInputProps()} />
					<img src={uploadImage} alt="upload" />
					<Typography gutterBottom>Dateien per Drag and Drop hierher verschieben</Typography>
				</Box>
			</DialogContent>
			<DialogActions sx={{
				root: {
					marginRight: 12,
					marginBottom: 6
				}
			}}>
				<Button variant="contained" color="primary" onClick={() => proceed({})}>
					Abbrechen
				</Button>
			</DialogActions>
		</ThemedDialog>
	)
};

export default (title, options) => {
	const dialog = confirmable(props => <FileDialog {...props} title={title} {...options} />);
	return createConfirmation(dialog)();
};
