import { Box, List, ListItem, ListItemText, Paper, Typography } from '@mui/material';
import { PoolFleetContract } from "system/Domain";
import poolFleetContractStatus from './poolFleetContractStatus';

interface Props {
	contracts: PoolFleetContract[]
	currentContract?: PoolFleetContract | null
	onSelect?: (contract: PoolFleetContract) => void
	disabled?: boolean
}

function PoolFleetContractList({ contracts, onSelect, currentContract, disabled }: Props) {

	return (
		<Box>
			<Typography gutterBottom variant="h5">Verträge</Typography>
			<Paper sx={{ padding: 2 }}>
				<List>
					{contracts.map(contract => {
						function handleClick() {
							onSelect && onSelect(contract)
						}

						return (
							<ListItem
								button
								disabled={disabled}
								onClick={handleClick}
								selected={contract.contractId === currentContract?.contractId}
								key={contract.contractId}
							>
								<ListItemText
									primary={`${contract.customer.name1}, ${contract.customer.name2}`}
									secondary={`${contract.contractId} • ${poolFleetContractStatus[contract.status]}`}
								/>
							</ListItem>
						)
					})}
				</List>
			</Paper >
		</Box >
	)
}

export default PoolFleetContractList
