import { useState } from "react";
import { Button, Grid } from "@mui/material";
import { WorkSession } from "../../system/Domain";
import Layout from "Components/Layout/Layout";
import useForm from "../../system/useForm";
import FormYesNo from "../../Components/FormYesNo";
import FormText from "../../Components/FormText";
import formatWorkSessionPerspective from "./formatWorkSessionPerspective";
import FormSelect from "../../Components/FormSelect";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setWorkSession } from "../../system/reducers/workSessions/actions";
import { useHistory } from "react-router";

export default () => {
	const [newWorkSession, setNewWorkSession] = useState<Partial<WorkSession>>({
		perspective: "Manual",
		isPublic: true
	});
	const dispatch = useDispatch();
	const history = useHistory();

	const form = useForm({
		values: newWorkSession,
		setValues: setNewWorkSession
	});

	const [saving, setSaving] = useState(false);
	const saveWorkSession = async () => {
		setSaving(true);

		try {
			const { data: workSession } = await axios.post(`/api/work-sessions`, newWorkSession);

			if (workSession) {
				dispatch(setWorkSession(workSession));
				history.push("/work-sessions");
			}
		} finally {
			setSaving(false);
		}
	};

	return (
		<Layout>
			<Grid container spacing={0}>
				<Grid item xs={12} md={6} lg={4}>
					<Grid container spacing={4}>
						<FormSelect
							name="perspective"
							form={form}
							label="Perspektive"
							choices={{
								"Manual": formatWorkSessionPerspective("Manual"),
								"ActiveSales": formatWorkSessionPerspective("ActiveSales"),
								"Presales": formatWorkSessionPerspective("Presales"),
								"BlockedPresales": formatWorkSessionPerspective("BlockedPresales"),
								"OpenPricingRequests": formatWorkSessionPerspective("OpenPricingRequests"),
							}}
							options={{
								required: true
							}}
						/>

						<FormText
							name="title"
							form={form}
							label="Beschreibung"
							fullWidth
						/>

						<FormYesNo
							name="isPublic"
							form={form}
							label="Öffentliche Sitzung"
							options={{
								required: true
							}}
						/>

						<Grid item xs={12}>
							<Button
								variant="contained"
								color="primary"
								disabled={saving || Object.keys(form.errors).length > 0}
								onClick={saveWorkSession}
							>
								Sitzung anlegen
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Layout>
	);
}
