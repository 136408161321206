import axios from "axios";
import { useCallback, useState } from "react";
import Layout from "../../Components/Layout/Layout";
import { useHistory, useParams } from "react-router";
import useAsyncEffect from "../../system/useAsyncEffect";
import { Delete, Refresh } from "@mui/icons-material"
import { Box, CircularProgress, Typography } from "@mui/material";
import { Invoice, InvoiceItem, InvoiceLineOccuranceDTO, InvoiceStatus } from "../../system/Domain";
import executeWithProgressDialog from "../../Dialogs/executeWithProgressDialog";
import ActionButton from "../../Components/ActionButton";
import getFiles from "../../Dialogs/getFiles";
import confirm from "../../Dialogs/confirm";
import fileDownload from "js-file-download";
import Actions from "../../Components/Actions";
import InvoiceItemsTable from "./InvoiceItemsTable";
import Search from "../../Components/Search";
import parseContentDispositionFilename from "system/parseContentDispositionFilename";
import { setUserMessage } from "../../system/reducers/userMessage/actions";
import { useDispatch } from "react-redux";
import OrdersNavigation from "Navigation/OrdersNavigation";

export default () => {
	const { id } = useParams<{ id: string }>();
	const [invoice, setInvoice] = useState<Invoice | null>();
	const [items, setItems] = useState<InvoiceItem[]>([]);
	const [busy, setBusy] = useState(false);
	const history = useHistory();
	const [itemsDuplicates, setItemsDuplicates] = useState<InvoiceLineOccuranceDTO[]>([]);

	useAsyncEffect(async () => {
		if (!id) {
			setInvoice(null);
			return;
		}

		setBusy(true);
		const { data: invoice } = await axios.get<Invoice>(`/api/invoices/${id}`);
		setInvoice(invoice);
		setItems(invoice.lines);
		setBusy(false);
	}, [id]);

	const approveInvoice = useCallback(() => {

		if (invoice?.status === InvoiceStatus.New) {
			return async () => {
				if (invoice?.lines.some(l => !l.orderId && !l.isCancelled)) {

					dispatch(setUserMessage("Einige Rechnungspositionen sind weder zugewiesen, noch storniert. Genehmigung abgebrochen.", "warning"));
					return;
				}

				const result = await confirm("Soll die Rechnung wirklich genehmigt werden?");

				if (result.ok) {
					const { data } = await axios.post<Invoice>(`/api/invoices/${invoice.id}/approve`, {});
					setInvoice(data);
				}
			}
		}

		return undefined;
	}, [invoice]);

	const refresh = useCallback(() => {
		if (invoice?.status === InvoiceStatus.New) {
			return async () => {
				try {
					setBusy(true);
					const { data } = await axios.post<Invoice>(`/api/invoices/${invoice.id}/refresh`, {});
					setInvoice(data);
				} finally {
					setBusy(false);
				}
			}
		}
	}, [invoice]);

	const rejectInvoice = useCallback(() => {
		if (invoice?.status === InvoiceStatus.New) {
			return async () => {
				const result = await confirm("Soll die Rechnung wirklich zurückgewiesen werden?");
				if (result.ok) {
					const { data } = await axios.post<Invoice>(`/api/invoices/${invoice.id}/reject`, {});
					setInvoice(data);
				}
			}
		}
	}, [invoice]);

	const creditInvoice = useCallback(() => {
		if (invoice?.status === InvoiceStatus.Approved) {
			return async () => {
				const result = await confirm("Soll die Rechnung wirklich storniert werden?");
				if (result.ok) {
					setBusy(true);
					try {
						const { data } = await axios.post<Invoice>(`/api/invoices/${invoice.id}/credit`, {});
						setInvoice(data);
						setItems(data.lines);
					} finally {
						setBusy(false);
					}
				}
			}
		}
	}, [invoice]);

	const syncInvoiceDataWithOrders = useCallback(() => {
		if (invoice) {
			return async () => {
				const result = await confirm("Sollen die Auftragsdaten mit den Daten aus der Rechnung aktualisiert werden?");
				if (result.ok) {
					try {
						setBusy(true);
						const { data } = await axios.post<Invoice>(`/api/invoices/${invoice.id}/sync-with-orders`, {});
						setInvoice(data);
						setItems(data.lines);
					} finally {
						setBusy(false);
					}
				}
			}
		}
	}, [invoice]);

	const deleteInvoice = useCallback(() => {
		if (invoice?.status === InvoiceStatus.New) {
			return async () => {
				const result = await confirm("Soll die Rechnung wirklich gelöscht werden?");
				if (result.ok) {
					await axios.delete<Invoice>(`/api/invoices/${invoice.id}`);
					history.push("/invoices");
				}
			}
		}
	}, [invoice]);

	const handleDownload = useCallback(() => {
		if (invoice?.document?.filename) {
			return async () => {
				const response = await axios.get(`/api/invoices/${invoice.id}/document`, {
					responseType: "blob"
				});
				fileDownload(response.data, invoice?.document.filename);
			}
		}
	}, [invoice]);

	const dispatch = useDispatch();
	const handleDownloadSAP = useCallback(() => {

		return async () => {
			if (invoice.lines.filter(il => il.pickupAddress).length > 50) { // if the invoice is really large, display info so that user knows something is in progress
				dispatch(setUserMessage("SAP Export wurde angefragt. Bitte warten...", "success"));
			}

			const response = await axios.get(`/api/invoices/${invoice.id}/sap`, {
				responseType: "blob"
			});

			const fileName = parseContentDispositionFilename(response.headers["content-disposition"]);
			fileDownload(response.data, fileName);
		}
	}, [invoice]);

	const handleSearch = (search: string) => {
		if (!invoice) return;

		if (!search) {
			setItems(invoice.lines);
			return;
		}

		const items = invoice.lines.filter(l => l.plateNumber.toLowerCase().includes(search.toLowerCase()));
		setItems(items);
	};

	const onAssignOrderToLine = useCallback(() => {
		if (invoice) {
			return async (orderId: string, orderCompanyName: string, plateNumber: string, deliveryDate: string) => {
				let goOn = true;
				if (invoice.company.name !== orderCompanyName) {
					goOn = (await confirm(`Die ausgewählte Auftrag für anderen Logistiker (${orderCompanyName}) ist. Trotzdem fortfahren?`)).ok;
				}
				if (goOn) {
					setBusy(true);
					const { data } = await axios.patch<Invoice>(`/api/invoices/${invoice.id}/assign-line-order`,
						{
							orderId: orderId,
							plateNumber: plateNumber,
							deliveryDate: deliveryDate
						}
					);
					setInvoice(data);
					setItems(data.lines);
					setBusy(false);
				}
			}
		}
	}, [invoice]);

	const onUnassignOrder = useCallback(() => {
		if (invoice) {
			return async (plateNumber: string, deliveryDate: string) => {
				const result = await confirm(`Auftrag von der Rechnungsposition ${plateNumber} aufheben?`);
				if (result.ok) {
					setBusy(true);
					const { data } = await axios.patch<Invoice>(`/api/invoices/${invoice.id}/reset-line-order`,
						{
							plateNumber: plateNumber,
							deliveryDate: deliveryDate
						}
					);
					setInvoice(data);
					setItems(data.lines);
					setBusy(false);
				}
			}
		}
	}, [invoice]);

	const checkLinesDuplicates = useCallback(() => {
		if (invoice) {
			return async () => {
				setBusy(true);
				const { data } = await axios.get<InvoiceLineOccuranceDTO[]>(`/api/invoices/${invoice.id}/lines-duplicates`);

				setItemsDuplicates(data)
				setBusy(false);
			}
		}
	}, [invoice]);

	const uploadAttachment = useCallback(() => {
		return async () => {
			const result = await getFiles("Rechnungsdokument hochladen");

			if (result.ok) {
				await executeWithProgressDialog(async progress => {

					let formData = new FormData();
					formData.append("file", result.files[0]);

					const { data } = await axios.post<Invoice>(`/api/invoices/${invoice.id}/attach`,
						formData, {
						headers: {
							'Content-Type': 'multipart/form-data'
						},
						onUploadProgress: evt => {
							const completed = Math.ceil((evt.loaded / evt.total) * 100);
							progress(completed);
						}
					}
					);
					setInvoice(data);
				});
			}
		}
	}, [invoice]);

	return (
		<Layout
			title="Rechnung bearbeiten"
			navigation={<OrdersNavigation />}
		>
			<Actions>
				<ActionButton
					icon
					hideOnDisabled={false}
					onClick={deleteInvoice()}
				>
					<Delete />
				</ActionButton>
				<ActionButton
					icon
					hideOnDisabled={false}
					onClick={refresh()}
				>
					<Refresh />
				</ActionButton>
				<ActionButton
					hideOnDisabled={false}
					disabled={!invoice?.canGoToSAP}
					onClick={handleDownloadSAP()}
				>
					Download SAP
				</ActionButton>
				<ActionButton
					hideOnDisabled={false}
					onClick={handleDownload()}
				>
					Download
				</ActionButton>
				<ActionButton
					hideOnDisabled={false}
					onClick={syncInvoiceDataWithOrders()}
				>
					Mit Auftragsdaten synchronisieren
				</ActionButton>
				<ActionButton
					hideOnDisabled
					color="secondary"
					onClick={rejectInvoice()}
				>
					Ablehnen
				</ActionButton>
				<ActionButton
					hideOnDisabled
					color="secondary"
					onClick={creditInvoice()}
				>
					Stornieren
				</ActionButton>
				<ActionButton
					hideOnDisabled={false}
					onClick={approveInvoice()}
				>
					Genehmigen
				</ActionButton>
				<ActionButton
					hideOnDisabled={false}
					onClick={checkLinesDuplicates()}
				>
					Andere Rechnungen überprüfen
				</ActionButton>
				<ActionButton
					hideOnDisabled={false}
					onClick={uploadAttachment()}
				>
					Dokument Hochladen
				</ActionButton>
			</Actions>
			<Box mt={2}>
				<Search
					onSearch={handleSearch}
					fullWidth
				/>
			</Box>
			<Box mt={2}>
				{busy && (
					<Typography align="center" component="div">
						<CircularProgress />
					</Typography>
				)}
				{!busy && (
					<InvoiceItemsTable
						assignOrder={onAssignOrderToLine()}
						unassignOrder={invoice?.status != InvoiceStatus.Approved ? onUnassignOrder() : undefined}
						items={items}
						occurances={itemsDuplicates}
					/>
				)}
			</Box>
		</Layout>
	)
}
