import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Box, TableBody, TableHead, TableRow } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import TextBlock from '../../Components/Layout/TextBlock';
import PaperTable from '../../Components/PaperTable';
import { Plate } from '../../Components/Plate';
import VehicleBookmark from '../../Components/VehicleBookmark';
import VehicleBookmarkDisplay from '../../Components/VehicleBookmarkDisplay';
import dateFormats from '../../system/dateFormats';
import { Vehicle, VehiclePerspective } from '../../system/Domain';
import VehiclePlace from './VehiclePlace';
import VehicleStatus from './VehicleStatus';
import { Colgroup, TableCell } from "../../Components/BreakpointStyledComponents";

export interface VehicleListProps {
	vehicles?: Vehicle[]
	isLoading?: boolean
	onClick?: (vehicle: Vehicle) => void
	onUpdate?: (vehicle: Vehicle) => void
	perspective: VehiclePerspective
}

export default ({ vehicles = [], isLoading, onClick, onUpdate, perspective }: VehicleListProps) => {
	const history = useHistory();

	const isBookmarkedPerspective = perspective === "Bookmarked";

	const width = isBookmarkedPerspective ? "20%" : "25%";

	return (
		<PaperTable>
			<Colgroup md lg xl>
				<col style={{ width: width }} />
				<col style={{ width: width }} />
				<col style={{ width: width }} />
				<col style={{ width: width }} />
				{isBookmarkedPerspective && (
					<col style={{ width: width }} />
				)}
				<col style={{ width: "1px" }} />
			</Colgroup>
			<TableHead>
				<TableRow>
					<TableCell>Fahrzeug</TableCell>
					<TableCell md lg xl>Status</TableCell>
					<TableCell md lg xl>Platz</TableCell>
					<TableCell md lg xl>Info</TableCell>
					{isBookmarkedPerspective && (
						<TableCell md lg xl>Kommentare</TableCell>
					)}
					<TableCell md lg xl></TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{vehicles.map((v: Vehicle) => {
					const status = <VehicleStatus vehicle={v} showStatusChangedDate />;
					const place = <VehiclePlace vehicle={v} showPlaceChangedDate />;

					const info = [];
					if (v.history.currentStockDateRange?.from) {
						info.push(
							<TextBlock
								primary="Eingang"
								secondary={moment(v.history.currentStockDateRange?.from).format(dateFormats.dateTime)}
								compact
							/>
						);
					}

					if (v.history.currentStockDateRange?.to) {
						info.push(
							<TextBlock
								primary="Ausgang"
								secondary={moment(v.history.currentStockDateRange?.to).format(dateFormats.dateTime)}
								compact
							/>
						);
					}

					return (
						<TableRow
							hover
							key={v.id}
							onClick={() => history.push({ pathname: `/vehicles/${v.id}` })}
						>
							<TableCell>
								<div>
									{v.plateNumber && (
										<>
											<Plate plateNumber={v.plateNumber} style={{ zoom: 0.6 }} />
											<Box sx={{ display: { xs: "contents", md: "none" } }}>
												<VehicleBookmark
													float
													vehicle={v}
													updateCallback={onUpdate}
												/>
											</Box>
										</>
									)}
								</div>
								<TextBlock
									primary={v.type?.description}
									secondary={v.vin}
									compact
								/>
								<Box sx={{ display: { xs: "contents", md: "none" } }}>
									<Box mt={2}>
										{status}
									</Box>
									<Box mt={2}>
										{place}
									</Box>
									{info.map((e, i) => (
										<Box mt={2} key={i}>
											{e}
										</Box>
									))}
									{isBookmarkedPerspective && (
										<Box mt={2}>
											<VehicleBookmarkDisplay vehicle={v} />
										</Box>
									)}
								</Box>
							</TableCell>
							<TableCell md lg xl>
								{status}
							</TableCell>
							<TableCell md lg xl>
								{place}
							</TableCell>
							<TableCell md lg xl>
								{info.map((e, i) => (
									<Box mt={i > 0 ? 2 : 0} key={i}>
										{e}
									</Box>
								))}
							</TableCell>
							{isBookmarkedPerspective && (
								<TableCell md lg xl>
									<VehicleBookmarkDisplay vehicle={v} />
								</TableCell>
							)}
							<TableCell md lg xl>
								<VehicleBookmark vehicle={v} updateCallback={onUpdate} />
							</TableCell>
						</TableRow>
					)
				})}
				{isLoading === true && (
					<TableRow>
						<TableCell
							colSpan={4}
							style={{ textAlign: "center" }}
						>
							<CircularProgress />
						</TableCell>
					</TableRow>
				)}
			</TableBody>
		</PaperTable>
	);
};
