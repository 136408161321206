import { RegistrationDocuments } from "../../../system/Domain";
import { green, red } from "@mui/material/colors";
import { Chip } from "@mui/material";

interface Props {
	isUnregistered: boolean,
	registrationDocuments : RegistrationDocuments
}

const UnregistrationStatusPill = (props : Props) => {
	const isUnregistered = props.isUnregistered;
	const unregistrationDateImported = !!props.registrationDocuments?.arrivalImport?.unregistrationDate;

	const status = isUnregistered ? { label: "Abgemeldet", backgroundColor: green[500] } : (
		unregistrationDateImported ? { label: props.registrationDocuments.arrivalImport?.unregistrationDate, backgroundColor: green[500] }
			: { label: "Unbekannt", backgroundColor: red[500] }
	);

	return (
		<Chip
			label={status.label}
			size="small"
			style={{ backgroundColor: status.backgroundColor }}
		/>
	);
};

export default UnregistrationStatusPill;
