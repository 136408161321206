import { BoxProps } from "@mui/material";
import { PropsWithChildren } from "react";
import Stack from "./Stack";

export default ({ children, ...rest }: PropsWithChildren<BoxProps>) => {
	return (
		<Stack spacing={2} {...rest}>
			{children}
		</Stack>
	);
};
