import Sidebar from 'Components/Sidebar/Sidebar'
import SidebarForm from 'Components/Sidebar/SidebarForm'
import SidebarGroup from 'Components/Sidebar/SidebarGroup'
import { useHistory, useLocation } from 'react-router'
import queryString from "query-string";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import poolFleetLotStatus from './poolFleetLotStatus';

export const PoolFleetVehiclesSidebar = () => {
	const location = useLocation();
	const query = queryString.parse(location.search);
	const history = useHistory();

	const status = query.status ? query.status as string : "All";
	const lotStatus = query.lotStatus ? query.lotStatus as string : "All";

	const changeFilter = (status: string, lotStatus: string) => {
		let query: any = {};
		if (status !== "All") {
			query.status = status;
		}
		if (lotStatus !== "All") {
			query.lotStatus = lotStatus;
		}

		const search = queryString.stringify(query);

		history.push({
			pathname: "/pool-fleet/vehicles",
			search
		});
	};

	const handleFilterChange = (event: SelectChangeEvent) => {
		changeFilter(event.target.value as string, lotStatus);
	};

	const handleLotStatusChange = (event: SelectChangeEvent) => {
		changeFilter(status, event.target.value as string);
	};

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarForm>
					<FormControl fullWidth variant='standard'>
						<InputLabel>Status</InputLabel>
						<Select
							value={status}
							onChange={handleFilterChange}
						>
							<MenuItem value="All">Bestand</MenuItem>
							<MenuItem value="InPreparation">Im Vorlauf</MenuItem>
							<MenuItem value="ReadyForRent">Verfügbar</MenuItem>
							<MenuItem value="Reserved">Reserviert</MenuItem>
							<MenuItem value="InDelivery">In Auslieferung</MenuItem>
							<MenuItem value="Rented">Vermietet</MenuItem>
							<MenuItem value="InReturn">In Abholung</MenuItem>
							<MenuItem value="Unavailable">Eingang</MenuItem>
						</Select>
					</FormControl>
					<FormControl fullWidth variant='standard'>
						<InputLabel>Platzstatus</InputLabel>
						<Select
							value={lotStatus}
							onChange={handleLotStatusChange}
						>
							<MenuItem value="All">Alles</MenuItem>
							{Object.keys(poolFleetLotStatus).map((ls, i) => {
								const value = (poolFleetLotStatus as any)[ls];

								return (
									<MenuItem value={ls} key={i}>{value}</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</SidebarForm>
			</SidebarGroup>
		</Sidebar>
	)
}
