import { Box, Typography } from "@mui/material";
import axios from "axios";
import JobLogs from "Pages/Admin/Jobs/JobLogs";
import { useState } from "react";
import useAsyncEffect from "system/useAsyncEffect";
import Layout from "../../Components/Layout/Layout";
import { Job } from "../../system/Domain";
import useChannel from "../../system/useChannel";
import useEvent from "../../system/useEvent";

export default ({ taskId, jobId, importId }: { taskId: string; jobId: string; importId: string }) => {
	const [job, setJob] = useState<Job>();

	useAsyncEffect(async () => {
		setJob(undefined);

		if (!taskId) {
			return;
		}

		const { data: job } = await axios.get<Job>(`/api/imports/${jobId}/job`);
		setJob(job);
	}, [taskId]);

	useEvent(
		"job:updated",
		(job: Job) => {
			if (!taskId || !job || job.id !== taskId) {
				return;
			}

			setJob(job);
		},
		[taskId],
	);

	useChannel(taskId);

	return (
		<Layout>
			<Typography variant="h5" gutterBottom>
				Job in progress....
			</Typography>
			<Box>
				<Typography gutterBottom>Protokoll</Typography>
				{job && <JobLogs job={job} importId={importId} />}
			</Box>
		</Layout>
	);
};
