import { Add, AttachFile, GetApp, RotateLeft } from "@mui/icons-material";
import { Dispatch, SetStateAction } from 'react';
import { TransportPricingQuery } from "./TransportPricingsPage";
import Sidebar from "Components/Sidebar/Sidebar";
import SidebarGroup from "Components/Sidebar/SidebarGroup";
import SidebarButton from "Components/Sidebar/SidebarButton";

interface Props {
	query: TransportPricingQuery
	setQuery: Dispatch<SetStateAction<TransportPricingQuery>>
	resetQuery: () => TransportPricingQuery
	export: () => Promise<void>
	upload: () => Promise<void>
	addNew: () => void
}

const TransportPricingsSidebar = (props: Props) => {
	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarButton
					color="primary"
					startIcon={<Add />}
					label="Neuer Anbieter"
					onClick={props.addNew}
				/>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarButton
					color="inherit"
					startIcon={<RotateLeft />}
					label="Filter zurücksetzen"
					onClick={props.resetQuery}
				/>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarButton
					color="inherit"
					startIcon={<GetApp />}
					label="Excel-Export"
					onClick={async () => await props.export()}
				/>
				<SidebarButton
					startIcon={<AttachFile />}
					label="Upload Preise"
					onClick={async () => await props.upload()}
				/>
			</SidebarGroup>
		</Sidebar>
	)
};

export default TransportPricingsSidebar;
