import { Dispatch, SetStateAction } from 'react';

import { RotateLeft } from '@mui/icons-material';
import { Button } from '@mui/material';

import Sidebar from '../../../Components/Sidebar/Sidebar';
import SidebarForm from '../../../Components/Sidebar/SidebarForm';
import SidebarGroup from '../../../Components/Sidebar/SidebarGroup';
import Sort, { SortItem } from '../../../Components/Sort';
import { VehicleArrivalsOverviewQuery } from './VehicleArrivalsOverview';

interface Props {
	query: VehicleArrivalsOverviewQuery,
	setQuery: Dispatch<SetStateAction<VehicleArrivalsOverviewQuery>>
	resetQuery: () => VehicleArrivalsOverviewQuery
}

const sortItems: SortItem[] = [
	{
		title: "Erstellungsdatum",
		field: "dateCreated"
	}
];

const VehicleArrivalsOverviewSidebar = (props: Props) => {
	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarForm>
					<Sort
						items={sortItems}
						value={props.query.sort}
						onChange={(sort) => props.setQuery(g => ({
							...g,
							skip: 0,
							sort
						}))}
					/>
				</SidebarForm>
			</SidebarGroup>
			<SidebarGroup>
				<Button
					variant="outlined"
					startIcon={<RotateLeft />}
					fullWidth
					color="inherit"
					onClick={props.resetQuery}
				>
					Filter zurücksetzen
				</Button>
			</SidebarGroup>
		</Sidebar>
	);
};

export default VehicleArrivalsOverviewSidebar;
