import { AnyAction } from "redux";

export interface LayoutState {
	fullscreen : boolean
}

export const layoutActions = {
	setFullscreen: (fullscreen : boolean) => ({
		type: "SET_FULLSCREEN",
		fullscreen
	})
}

const initialState : LayoutState = {
	fullscreen: false
};

export default (state : LayoutState = initialState, action : AnyAction) : LayoutState => {
	switch (action.type) {
		case "SET_FULLSCREEN":
			return {
				...state,
				fullscreen: action.fullscreen
			};
		default:
			return state;
	}
};
