import { FleetnetVehicle } from "../../system/Domain";
import Tile from "../../Components/Tiles/Tile";
import formatNumber from "../../system/formatNumber";
import FleetnetVehicleListItem from "./FleetnetVehicleListItem";
import List from "../../Components/List";
import ListItemContent from "../../Components/ListItemContent";
import ListItem from "../../Components/ListItem";

interface Props {
	isLoading : boolean
	fleetnetVehicles? : FleetnetVehicle[]

	isSelected? : (vehicle : FleetnetVehicle) => boolean
	onSelect : (fleetnetVehicle? : FleetnetVehicle) => void
}

export default ({ isLoading, fleetnetVehicles, isSelected, onSelect } : Props) => {
	return (
		<Tile
			title="Fleetnet Fahrzeuge"
			subtitle={!isLoading && fleetnetVehicles && (
				<span> ({formatNumber(fleetnetVehicles.length)})</span>
			)}
		>
			<List>
				{!isLoading && fleetnetVehicles && fleetnetVehicles.length === 0 && (
					<ListItem>
						<ListItemContent>Keine Fahrzeuge gefunden</ListItemContent>
					</ListItem>
				)}
				{fleetnetVehicles && fleetnetVehicles.length > 0 && fleetnetVehicles.map(v => (
					<FleetnetVehicleListItem
						key={v.id}
						fleetnetVehicle={v}
						isSelected={(isSelected && isSelected(v)) || false}
						onSelect={onSelect}
					/>
				))}
			</List>
		</Tile>
	);
}
