import { useState } from "react";
import useForm from "../../../system/useForm";
import { Box, Button, CircularProgress, IconButton, Typography, colors, useTheme } from "@mui/material";
import { useHistory, useParams } from "react-router";
import { CompanyReference, InstalledTiresAndRims, LeasmanContractType, SuppliedTiresAndRims, TransportOrder, UserRole, Vehicle, VehicleArrival, VehicleArrivalFormRequest } from "../../../system/Domain";
import Layout from "Components/Layout/Layout";
import OrdersNavigation from "Navigation/OrdersNavigation";
import VehicleSearch from "Components/VehicleSearch";
import useAsyncEffect from "system/useAsyncEffect";
import axios from "axios";
import Chip from "Components/Chip";
import dateFormats from "system/dateFormats";
import moment from "moment";
import AldVehicleHeader from "Components/AldVehicleHeader";
import useAction from "system/useAction";
import VehicleArrivalFormSidebar from "./VehicleArrivalFormSidebar";
import TransportOrderNotesList from "../../Orders/Transports/TransportOrderNotesList";
import useUser from "system/useUser";
import TileContent from "Components/Tiles/TileContent";
import confirm from "../../../Dialogs/confirm";
import fileDownload from "js-file-download";
import parseContentDispositionFilename from "system/parseContentDispositionFilename";
import { isDorfmarkAddress } from "system/isDorfmarkAddress";
import NotificationSnackbar from "Components/Layout/NotificationSnackbar";
import showMessage from "Dialogs/showMessage";
import useOrderFromMatch from "system/useOrderFromMatch";
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import VehicleArrivalForm from "./VehicleArrivalForm";

interface CreationReference {
	allowSelfDelivery: boolean
	contractEndDate: string
	isTooEarly: boolean
}

interface VehicleArrivalFormSupplementaryInfo {
	leasmanContractType: LeasmanContractType;
	contractReference: CreationReference;
	doAssessment?: boolean;
	isPoolfleet: boolean;
	previousTransportOrder: TransportOrder;
	activeReturnFormExists: boolean;
	assignedTo: CompanyReference;
	isRkv: boolean;
	expectedInstalledTiresAndRims: InstalledTiresAndRims;
	expectedSuppliedTiresAndRims: SuppliedTiresAndRims;
	externalReturnformExists: boolean;
}

interface EditableVehicleArrivalFormRequest extends VehicleArrivalFormRequest {
	additionalTiresAndRims?: boolean;
}

export default () => {
	const { vehicleId } = useParams<{ vehicleId: string }>();
	const [vehicle, setVehicle] = useState<Vehicle>();
	const [formData, setFormData] = useState<VehicleArrivalFormSupplementaryInfo>();
	const [error, setError] = useState<string>('');
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [previousTransportOrderInfo, setPreviousTransportOrderInfo] = useOrderFromMatch();
	const [, , , , hasAnyRole] = useUser();
	const displayNotes = hasAnyRole([UserRole.AldUser, UserRole.LogisticCompany, UserRole.LotManager])
		&& formData && formData.previousTransportOrder
		&& (formData.previousTransportOrder.notes.length > 0 || formData.previousTransportOrder.internalNotes.length > 0)
		&& formData.previousTransportOrder.status === "Assigned"
		&& !formData.previousTransportOrder.vehicleArrivalId;
	const [request, setRequest] = useState<EditableVehicleArrivalFormRequest>();
	const [showAllNotes, setShowAllNotes] = useState(false);
	const theme = useTheme();

	const handleClearError = () => {
		setError('');
	};

	const handleVehicleSelect = async (vehicle: Vehicle) => {
		if (!vehicle || !vehicleId) {
			return;
		}

		const { data } = await axios.get<VehicleArrivalFormSupplementaryInfo>('/api/vehicles/arrivals/form-info', {
			params: {
				vehicleId: vehicleId
			}
		});

		setVehicle(vehicle);
		setFormData(data);

		if (data && data.previousTransportOrder) {
			setPreviousTransportOrderInfo(data.previousTransportOrder);
		}

		setRequest({
			vehicleId: vehicleId,
			doAssessment: data.doAssessment,
			isPoolfleet: data.isPoolfleet,
			previousTransportOrderId: data.previousTransportOrder?.id,
			installedTiresAndRims: data.expectedInstalledTiresAndRims,
			additionalTiresAndRims: data.expectedSuppliedTiresAndRims != null,
			suppliedTiresAndRims: data.expectedSuppliedTiresAndRims
		});
	};

	useAsyncEffect(async () => {
		if (!vehicleId) {
			return;
		}
		setIsLoading(true);

		try {
			setRequest(request);
			const { data } = await axios.get(`/api/vehicles/${vehicleId}`);
			await handleVehicleSelect(data);
		}
		finally {
			setIsLoading(false);
		}
	}, [vehicleId]);

	useAsyncEffect(async () => {
		if (!vehicle || !formData) {
			return;
		}
		setIsLoading(true);

		try {
			await checkVehicleAndOrderState();
		}
		finally {
			setIsLoading(false);
		}

	}, [formData]);

	const checkVehicleAndOrderState = async () => {
		let alert = "";
		let answer = null;

		if (vehicle.isInStock) {
			alert += "Dieses Fahrzeug ist bereits im Bestand. ";
		}
		if (formData.previousTransportOrder) {
			if (formData.previousTransportOrder.status === "Created") {
				alert += "Für dieses Fahrzeug wurde bereits ein Transportauftrag erstellt. ";
			}
			else if (formData.previousTransportOrder.status === "Assigned") {
				if (formData.previousTransportOrder.destinationAddress && !isDorfmarkAddress(formData.previousTransportOrder.destinationAddress)) {
					alert += "Der Bestimmungsort des Transportauftrages muss Dorfmark sein. ";
				}
				if (formData.previousTransportOrder.vehicleArrivalId) {
					alert += "Dieses Fahrzeug wurde bereits angeliefert. ";
				}
				if (formData.activeReturnFormExists) {
					alert += "Ein aktives Protokoll ist für diesen Transportauftrag bereits vorhanden. ";
				}
			}
		}

		if (alert) {
			await showMessage({
				message: alert
			});
		}
		else if (formData.previousTransportOrder && (formData.previousTransportOrder.status === "Completed" || formData.previousTransportOrder.status === "Cancelled")) {
			const statusTranslation = formData.previousTransportOrder.status === "Completed" ? "abgeschlossener" : "stornierter";

			answer = await confirm(`Für dieses Fahrzeug liegt bereits ein ${statusTranslation} Transportauftrag vor. Möchten Sie fortfahren?`);
		}

		if (alert || (answer && !answer.ok)) {
			history.push(`/orders/transports/`);
		}
	};

	const form = useForm({
		values: request,
		setValues: setRequest,
		deltaValues: null
	});

	const [save, isSaving] = useAction(async () => {
		if (!vehicle) return;

		const validated = form.validateAll();
		if (!validated) {
			let error = "";
			if (request.plateNumbers === "1" && !request.plateNumbersFront) {
				error += "Kennzeichenfotos müssen hinzugefügt werden. ";
			}
			else if (request.plateNumbers === "2" && (!request.plateNumbersFront || !request.plateNumbersBack)) {
				error += "Kennzeichenfotos müssen hinzugefügt werden. ";
			}

			if (request.registrationDocument && (!request.registrationDocumentFront || !request.registrationDocumentBack)) {
				error += "Fotos von dem Zulassungsdokument müssen hinzugefügt werden. ";
			}

			if (error !== "") {
				setError(error);
			}

			return;
		}

		// we need to remove the property which is used only for editing purposes
		const { additionalTiresAndRims, ...apiRequest } = request;

		const response = await axios.post<VehicleArrival>("/api/vehicles/arrivals", apiRequest);

		if (form && (form.getValue("unstickService") === true || form.getValue("changeTiresService") === true)) {
			const servicesQrCodesFile = await axios.get(`/api/vehicles/${vehicleId}/services/qr-code`, {
				responseType: "blob"
			});

			const filename = parseContentDispositionFilename(servicesQrCodesFile.headers["content-disposition"]);

			fileDownload(servicesQrCodesFile.data, filename);
		}

		history.push(`/orders/transports/${response.data.transportOrderId}`);
	});

	const formatLeasmanContractType = (type: LeasmanContractType) => {
		switch (type) {
			case "Mileage":
				return "Kilometervertrag";
			case "ResidualValue":
				return "Restwertvertrag";
			case "External":
				return "Externer Vertrag";
			case "Service":
				return "Servicevertrag";
			case "Supplementary":
				return "Ergänzungsvertrag";
			case "Finance":
				return "Finanzierung";
			case "Installment":
				return "Abzahlungsvereinbarung";
			default:
				return `Vertragstyp: ${type}`;
		}
	};

	const rkvIndicator = formData?.isRkv ?
		<span style={{ color: theme.palette.secondary.light }}>RKV Vertrag</span> : undefined;

	const contractDateColor = !formData?.contractReference?.allowSelfDelivery
		? colors.red[500]
		: (formData?.contractReference?.isTooEarly) ? colors.orange[500] : colors.blue[500];

	const hasAssessment = formData && formData.doAssessment != null && !formData.doAssessment;
	const showPreviousTransportOrder = formData && formData.assignedTo
		&& formData.previousTransportOrder
		&& formData.previousTransportOrder.status === "Assigned"
		&& !formData.previousTransportOrder.vehicleArrivalId;

	return (
		<Layout
			plateNumber={vehicle?.plateNumber}
			title="Anlieferung"
			navigation={<OrdersNavigation />}
			sidebar={vehicleId && vehicle && (
				<VehicleArrivalFormSidebar
					vehicleId={vehicleId}
					orderId={formData?.previousTransportOrder?.id}
					allowProtocolDownload={!!formData?.previousTransportOrder.protocol?.filename}
				/>
			)}
		>
			<NotificationSnackbar
				message={error}
				onClose={handleClearError}
				color="error"
			/>
			{!vehicleId && !vehicle && (
				<VehicleSearch
					onClick={v => history.push(`/vehicles/${v.id}/arrivals/new`)}
				/>
			)}
			{
				isLoading ?
					<CircularProgress sx={{ marginRight: "10px" }} size={36} />
					: vehicle && formData &&
					<Box sx={{ display: "flex", gap: 3, flexDirection: "column" }} >
						<AldVehicleHeader
							plateNumber={vehicle.plateNumber}
							entries={[vehicle.type?.description, vehicle.vin]}
							additionalEntries={[rkvIndicator]}
						/>
						<Box sx={{ display: "flex", gap: 3 }} >
							{formData.contractReference.contractEndDate && (
								<Chip
									label={`Vertragsende: ${moment(formData.contractReference.contractEndDate).format(dateFormats.date)}`}
									explicitColor={contractDateColor}
								/>
							)}
							<Chip
								label={formatLeasmanContractType(formData.leasmanContractType)}
								explicitColor={colors.green[500]}
							/>
							{showPreviousTransportOrder ?
								<Chip
									label={`Transportauftrag: ${formData.assignedTo.name}`}
									explicitColor={colors.orange[500]}
								/>
								:
								<Chip
									label={`Keine aktive Transportaufträge`}
									explicitColor={colors.grey[700]}
								/>
							}
						</Box>
						{previousTransportOrderInfo && (
							<Typography variant="caption" color="textSecondary">
								{previousTransportOrderInfo.deliveryStatus}
							</Typography>
						)}
						{displayNotes && (
							<Box>
								<Box sx={{ display: "flex" }}>
									<Box sx={{ paddingTop: 1.25 }}>
										Notizen
									</Box>
									<IconButton size="small" onClick={() => setShowAllNotes(s => !s)}>
										{!showAllNotes && <ArrowDropDown />}
										{showAllNotes && <ArrowDropUp />}
									</IconButton>
								</Box>
								<TileContent dense>
									<TransportOrderNotesList order={formData.previousTransportOrder} displayAll={showAllNotes} />
								</TileContent>
							</Box>
						)}
						<VehicleArrivalForm
							form={form}
							hasAssessment={hasAssessment}
							showExternalProtocolInfo={formData.externalReturnformExists}
						/>
						<Button
							sx={{
								width: 1 / 4
							}}
							variant="outlined"
							color="primary"
							onClick={save}
							disabled={isSaving}
						>
							Anlieferung abschließen
						</Button>
					</Box>
			}
		</Layout>
	);
};
