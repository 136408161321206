import { Box, Grid } from "@mui/material";
import axios from "axios";
import ActionButton from "Components/ActionButton";
import TextBlock from "Components/Layout/TextBlock";
import List from "Components/List";
import ListItem from "Components/ListItem";
import ListItemChip from "Components/ListItemChip";
import ListItemChips from "Components/ListItemChips";
import ListItemContent from "Components/ListItemContent";
import Loading from "Components/Loading";
import Tile from "Components/Tiles/Tile";
import TileActions from "Components/Tiles/TileActions";
import TileContent from "Components/Tiles/TileContent";
import VehicleNavigation from "Navigation/VehicleNavigation";
import { formatDelegatedLeasingStatus, paintDelegatedLeasingStatus } from "Pages/Leasing/VehicleListItem";
import TextGroup from "Pages/VehicleSales/TextGroup";
import TextLine from "Pages/VehicleSales/TextLine";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import formatCurrency from "system/formatCurrency";
import formatDate from "system/formatDate";
import formatNumber from "system/formatNumber";
import Layout from "../../Components/Layout/Layout";
import { CatalogVehicle, LeasmanVehicle } from "../../system/Domain";
import useAxiosEffect from "../../system/useAxiosEffect";
import useVehicle from "../../system/useVehicle";

export default () => {
	const { id } = useParams<{ id: string }>();
	const [vehicle, setVehicle, reloadVehicle] = useVehicle(id);
	const [catalogVehicle, setCatalogVehicle] = useState<CatalogVehicle>();
	const [leasmanVehicles, setLeasmanVehicles] = useState<LeasmanVehicle[]>();
	const [leasmanVehicle, setLeasmanVehicle] = useState<LeasmanVehicle>();

	const [, reloadCatalog] = useAxiosEffect(
		async (config) => {
			if (!vehicle?.type.id) {
				return;
			}

			const { data } = await axios.get<CatalogVehicle>(`/api/catalog/vehicles/${vehicle.type.id}`, config);

			setCatalogVehicle(data);
		},
		[vehicle?.type?.id],
	);

	const [, reloadLeasman] = useAxiosEffect(
		async (config) => {
			if (!vehicle) {
				setLeasmanVehicles(undefined);
				return;
			}

			const { data } = await axios.post<LeasmanVehicle[]>(
				`/api/leasman/vehicles/search`,
				{
					target: "database",
					query: {
						vins: [vehicle.vin],
					},
				},
				config,
			);

			setLeasmanVehicles(data);
		},
		[vehicle?.vin],
	);

	useEffect(() => {
		if (!leasmanVehicles || !vehicle?.leasing.main) {
			setLeasmanVehicle(undefined);
			return;
		}

		const main = leasmanVehicles.find(
			(v) => v.tenant === vehicle.leasing.main.tenant && v.contract.id === vehicle.leasing.main.id,
		);
		setLeasmanVehicle(main);
	}, [leasmanVehicles, vehicle?.leasing.main]);

	const [mode, setMode] = useState<"view" | "new-lessor">("view");

	const reload = () => Promise.all([reloadVehicle(), reloadCatalog(), reloadLeasman()]);

	const type = vehicle?.type;

	return (
		<Layout title="Leasing" plateNumber={vehicle?.plateNumber} navigation={<VehicleNavigation vehicle={vehicle} />}>
			{!vehicle && <Loading />}
			{vehicle && (
				<Grid container spacing={6}>
					<Grid item xs={12} md={4}>
						<Tile title="Informationen">
							<TileContent>
								<TextGroup title="Allgemein">
									<TextLine label="FIN" value={vehicle.vin} />
									<TextLine label="Schwacke Code" value={type?.id} />
									{type && (
										<Box mt={1}>
											<TextLine label="Hersteller" value={type.make.name} />
											<TextLine label="Modell" value={type.model.name} />
											<TextLine label="Variante" value={type.model.variant} />
											<TextLine label="Version" value={type.model.version} />
											<TextLine label="Bauart" value={type.body.type} />
											<TextLine label="Farbe" value={type.body.paint} />
											<TextLine label="Kraftstoff" value={type.engine.fuelType} />
											<TextLine
												label="Leistung"
												value={`${formatNumber(type.engine.power)}kW / ${formatNumber(type.engine.horsePower)}PS`}
											/>
										</Box>
									)}
								</TextGroup>
								{leasmanVehicle?.contract && (
									<TextGroup title="Leasingvertrag">
										<TextLine
											label="Vertragsnummer"
											value={leasmanVehicle.contract.id.toString()}
										/>
										<TextLine
											label="Vorraussichtliches Ende"
											value={formatDate(leasmanVehicle.contract.end.date)}
										/>
										<TextLine
											label="Laufzeit"
											value={formatNumber(leasmanVehicle.contract.duration)}
										/>
										<TextLine
											label="Laufleistung"
											value={formatNumber(leasmanVehicle.contract.mileage)}
										/>
										<Box mt={1}>
											<TextLine
												label="Finanzrate"
												value={formatCurrency(leasmanVehicle.contract.finance.fee)}
											/>
											{leasmanVehicle.contract.services.map((s) => (
												<TextLine
													key={s.id}
													label={s.type.name}
													value={formatCurrency(s.fee)}
												/>
											))}
										</Box>
									</TextGroup>
								)}
							</TileContent>
						</Tile>
					</Grid>
					<Grid item xs={12} md={8}>
						<Tile title="Lebenslauf">
							{!!leasmanVehicles && (
								<List>
									{leasmanVehicles
										.filter((v) => v.contract.type === "Supplementary")
										.map((v) => (
											<ListItem key={v.id}>
												<ListItemContent>
													<TextBlock
														primary={`Leasingnehmer zugewiesen: ${v.contract.customer.fullName}`}
														secondary={formatDate(v.contract.begin.date)}
													/>
													<ListItemChips>
														<ListItemChip
															label={formatDelegatedLeasingStatus("Active")}
															color={paintDelegatedLeasingStatus("Active")}
														/>
													</ListItemChips>
												</ListItemContent>
											</ListItem>
										))}
									{/* {!!leasmanVehicle?.order?.dateDelivered && ( */}
									{/* 	<ListItem> */}
									{/* 		<ListItemContent> */}
									{/* 			<TextBlock */}
									{/* 				primary="Fahrzeug geliefert" */}
									{/* 				secondary={formatDate(leasmanVehicle.order.dateDelivered)} */}
									{/* 			/> */}
									{/* 			<ListItemChips> */}
									{/* 				<ListItemChip */}
									{/* 					label={formatDelegatedLeasingStatus("Idle")} */}
									{/* 					color={paintDelegatedLeasingStatus("Idle")} */}
									{/* 				/> */}
									{/* 			</ListItemChips> */}
									{/* 		</ListItemContent> */}
									{/* 	</ListItem> */}
									{/* )} */}
									{!!leasmanVehicle && (
										<ListItem>
											<ListItemContent>
												<TextBlock
													primary={`Mantelvertrag mit ${leasmanVehicle.contract.customer.fullName}`}
													secondary={formatDate(leasmanVehicle.contract.begin.date)}
												/>
												<ListItemChips>
													<ListItemChip
														label={formatDelegatedLeasingStatus("PreDeployment")}
														color={paintDelegatedLeasingStatus("PreDeployment")}
													/>
												</ListItemChips>
											</ListItemContent>
										</ListItem>
									)}
								</List>
							)}
							<TileActions>
								{mode === "view" && (
									<ActionButton variant="text" color="primary" onClick={() => {}}>
										Neuer Leasingnehmer
									</ActionButton>
								)}
							</TileActions>
						</Tile>
					</Grid>
				</Grid>
			)}
		</Layout>
	);
};
