import axios from 'axios';
import { Dispatch, SetStateAction, useState } from 'react';
import { Add, Check, Clear, Delete, Edit } from '@mui/icons-material';
import { Box, Button, Input, useTheme } from '@mui/material';
import InlineList from '../../Components/InlineList';
import TextBlock from '../../Components/Layout/TextBlock';
import ListItem from '../../Components/ListItem';
import ListItemAction from '../../Components/ListItemAction';
import ListItemActions from '../../Components/ListItemActions';
import ListItemChips from '../../Components/ListItemChips';
import ListItemContent from '../../Components/ListItemContent';
import {
	CatalogVehicle, Equipment, LeasmanVehicle, ThemeColor, Vehicle
} from '../../system/Domain';
import EquipmentSourceChip from './EquipmentSourceChip';

interface Props {
	vehicle: Vehicle
	setVehicle: Dispatch<SetStateAction<Vehicle | undefined>>
	equipment: Equipment | null

	leasmanVehicle?: LeasmanVehicle
	leasmanVehicles?: LeasmanVehicle[]
	catalogVehicle?: CatalogVehicle

	disabled: boolean
}

type Mode = "view" | "edit" | "delete";

export default ({ vehicle, setVehicle, equipment, leasmanVehicle, catalogVehicle, disabled }: Props) => {
	const catalogEquipment = catalogVehicle?.equipment.find(e => e.id === equipment?.id);
	const leasmanEquipment = leasmanVehicle?.equipment.find(e => e.id === equipment?.id);

	const [updating, setUpdating] = useState(false);

	const [mode, setMode] = useState<Mode>("view");
	const [text, setText] = useState<string>();

	const startEditing = (text?: string) => {
		if (disabled) {
			return;
		}

		setMode("edit");
		setText(text || equipment?.text);
	};

	const stopEditing = () => {
		setMode("view");
		setText(undefined);
	};

	const addEquipment = async (text?: string) => {
		if (updating || !equipment || !text) {
			return;
		}

		setUpdating(true);
		try {
			const { data } = await axios.post<Vehicle>(`/api/vehicles/${vehicle.id}/equipment`, {
				...equipment,
				text
			});
			setVehicle(data);
		} finally {
			setUpdating(false);
		}

		stopEditing();
	};

	const updateEquipment = async (text?: string) => {
		if (updating || !equipment || !text) {
			return;
		}

		setUpdating(true);
		try {
			const { data } = await axios.put<Vehicle>(`/api/vehicles/${vehicle.id}/equipment/${equipment.id}`, {
				...equipment,
				text
			});
			setVehicle(data);
		} finally {
			setUpdating(false);
		}

		stopEditing();
	};

	const deleteEquipment = async () => {
		if (updating || !equipment) {
			return;
		}

		setUpdating(true);
		try {
			const { data } = await axios.delete<Vehicle>(`/api/vehicles/${vehicle.id}/equipment/${equipment.id}`);
			setVehicle(data);
		} finally {
			setUpdating(false);
		}

		setMode("view");
	};

	const isNew = !!equipment && !vehicle.equipment?.find(e => e.id === equipment.id);
	const theme = useTheme();

	let color: ThemeColor = "info";
	if (mode === "edit") {
		color = "primary";
	} else if (mode === "delete") {
		color = "error";
	}

	return (
		<ListItem
			color={color}
			missing={isNew && mode !== "edit"}
		>
			{!disabled && (
				<ListItemActions color={color} disabled={updating}>
					{isNew && (
						<>
							<ListItemAction
								icon={<Add />}
								onClick={() => addEquipment(text || equipment?.text)}
							/>

							{mode === "edit" && (
								<ListItemAction
									icon={<Clear />}
									onClick={() => stopEditing()}
								/>
							)}
						</>
					)}
					{!isNew && mode === "view" && (
						<>
							<ListItemAction
								icon={<Edit />}
								onClick={() => startEditing()}
							/>

							<ListItemAction
								icon={<Delete />}
								onClick={() => setMode("delete")}
							/>
						</>
					)}
					{!isNew && mode === "edit" && (
						<>
							<ListItemAction
								icon={<Check />}
								onClick={() => updateEquipment(text)}
							/>

							<ListItemAction
								icon={<Clear />}
								onClick={() => stopEditing()}
							/>
						</>
					)}
					{!isNew && mode === "delete" && (
						<ListItemAction
							icon={<Clear />}
							onClick={() => setMode("view")}
						/>
					)}
				</ListItemActions>
			)}
			<ListItemContent color={color}>
				{equipment && (
					<>
						<TextBlock
							primary={(
								<>
									{(mode === "view" || mode === "delete") && (
										equipment.text
									)}
									{mode === "edit" && (
										<Box mb={1}>
											<Input
												fullWidth
												autoFocus
												multiline
												sx={{
													lineHeight: "1.5em"
												}}
												disabled={updating}
												onChange={e => setText(e.target.value)}
												value={text}
											/>
										</Box>
									)}
								</>
							)}
							secondary={(
								<InlineList>
									<span>{equipment.id}</span>
									{equipment.categories?.map(c => (
										<span key={c.id}>{c.name}</span>
									))}
								</InlineList>
							)}
						/>
						{mode === "delete" && (
							<Box mt={3} color={theme.palette.error.light}>
								<Button
									color="inherit"
									variant="text"
									onClick={() => deleteEquipment()}
									startIcon={<Delete />}
								>
									Ausstattung entfernen
								</Button>
							</Box>
						)}
						{(mode === "view" || mode === "edit") && (
							<ListItemChips>
								<EquipmentSourceChip
									source="Fahrzeugkatalog"
									loading={catalogVehicle === undefined}
									exists={!!catalogEquipment}
									matches={catalogEquipment?.text === (text || equipment.text)}
									onClick={!catalogEquipment || disabled ? undefined : () => startEditing(catalogEquipment.text)}
								/>

								<EquipmentSourceChip
									source="Leasman"
									loading={leasmanVehicle === undefined}
									exists={!!leasmanEquipment}
									matches={leasmanEquipment?.text === (text || equipment.text)}
									onClick={!leasmanEquipment || disabled ? undefined : () => startEditing(leasmanEquipment.text)}
								/>

								{/*<ListItemChip*/}
								{/*	label={formatNumber(catalogEquipment?.current.flag)}*/}
								{/*/>*/}
							</ListItemChips>
						)}
					</>
				)}
			</ListItemContent>
		</ListItem>
	);
}
