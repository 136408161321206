import { AssessmentOrder, RepairOrder } from "../../../system/Domain";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
import useAsyncEffect from "../../../system/useAsyncEffect";
import axios from "axios";
import DekraRepairOrderListItem, { ViewModeAssessment } from "../../Vehicles/DekraRepairOrderListItem";
import Tile from "../../../Components/Tiles/Tile";

interface Props {
	assessmentOrder: AssessmentOrder;
}

const RepairOrderDisplay = (props: Props) => {
	const [repairOrder, setRepairOrder] = useState<RepairOrder>();
	const [isLoading, setIsLoading] = useState(false);

	useAsyncEffect(async () => {
		setIsLoading(true);

		try {
			const { data: repairOrders } = await axios.get<RepairOrder[]>("/api/orders/repairs", {
				params: {
					type: "AssessmentReport",
					assessmentOrderId: props.assessmentOrder.id,
				},
			});

			if (repairOrders) {
				const repairOrder = repairOrders.find((o) => o.status !== "Cancelled");
				if (repairOrder) {
					setRepairOrder(repairOrder);
				}
			}
		} finally {
			setIsLoading(false);
		}
	}, [props.assessmentOrder.defect]);

	return (
		<Tile title="Werkstattauftrag">
			{isLoading && <CircularProgress size={48} />}
			{!isLoading && repairOrder && (
				<DekraRepairOrderListItem
					key={repairOrder.id}
					order={repairOrder}
					setIsFetching={setIsLoading}
					viewMode={ViewModeAssessment.List}
					defaultViewMode={ViewModeAssessment.List}
				/>
			)}
		</Tile>
	);
};

export default RepairOrderDisplay;
