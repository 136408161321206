import { useState } from "react";
import Layout from "../../Components/Layout/Layout";
import { Box, CircularProgress, Grid, Link } from "@mui/material";
import useAsyncEffect from "../../system/useAsyncEffect";
import useUser from "../../system/useUser";
import axios from "axios";
import { JwtToken, User } from "../../system/Domain";

export default () => {
	const [apiUser, setApiUser] = useState<User | null>(null);
	const [user] = useUser();
	const [loading, setLoading] = useState(false);
	const [apiToken, setApiToken] = useState<JwtToken | null>(null);

	const generateApiKey = async () => {
		const { data } = await axios.post("/api/users/me/api-user/token");
		setApiToken(data);
	};

	useAsyncEffect(async () => {
		setLoading(true);
		const { data } = await axios.get("/api/users/me/api-user");
		setApiUser(data || null);

		if (data) {
			await generateApiKey();
		}

		setLoading(false);
	}, [user]);

	const handleCreateUser = async () => {
		const { data } = await axios.post("/api/users/me/api-user");
		setApiUser(data);
		await generateApiKey();
	};

	// Never used
	// const refreshToken = async () => {
	// 	const { data } = await axios.post("/api/token/refresh", { refreshToken: apiToken!.refreshToken, email: user?.email });
	// 	//setApiToken(data);
	// };

	if (loading) {
		return <Layout>
			<Box textAlign="center" m={2}>
				<CircularProgress/>
			</Box>
		</Layout>;
	}

	if (!apiUser) {
		return (
			<Layout>
				<Box m={2}>
					Es ist noch kein API User für Ihren User definiert. Bitte hier <Link
					onClick={handleCreateUser}>klicken</Link>, um
					einen API Benutzer zu erzeugen
				</Box>
			</Layout>
		);
	}

	return (
		<Layout>
			<Box m={2}>
				<Grid container>
					<Grid item xs={12} md={6} style={{ overflowWrap: "break-word" }}>
						{apiToken && (
							<>
								<Box m={1}>
									Die Authentifizierung zur Nutzung der Logistikplatform orientiert sich am Standard oAuth Flow.<br/>
									Mithilfe des API-Tokens erhalten Sie für eine begrenzte Anzahl an Tagen Zugriff auf die API. Wenn der Token abgelaufen ist,
									so können Sie einen neuen Token mithilfe des Refresh Tokens erzeugen. Bitte beachten Sie, dass der Refresh Token maximal 365 Tage
									gültig ist.
								</Box>
								<Box m={1}><strong>Gültigkeit des API Access Tokens in Tagen:</strong><br/> {apiToken.daysValid}</Box>
								<Box m={1}><strong>Access Token:</strong> {apiToken?.token}</Box>
								<Box m={1}><strong>Refresh Token:</strong> {apiToken?.refreshToken}</Box>
							</>
						)}
					</Grid>
				</Grid>
			</Box>
		</Layout>
	);
};
