import axios from "axios";
import { useState } from "react";
import { FileReference } from "../system/Domain";
import useAxiosEffect from "../system/useAxiosEffect";
import { Box, BoxProps, CircularProgress } from "@mui/material";

interface Props extends BoxProps {
	picture: FileReference
	thumbnail?: boolean
}

export default ({ picture, thumbnail = true, ...rest }: Props) => {
	const [imageUrl, setImageUrl] = useState<string | null>(null);
	const [fullscreen, setFullscreen] = useState(false);

	useAxiosEffect(async (config) => {
		if (!picture.hash) {
			setImageUrl(null);
			return;
		}

		let url = `/api/storage/${picture.hash}`;
		if (thumbnail) {
			url += "/thumbnail";
		}

		const response = await axios.get(url, {
			...config,
			responseType: "blob",
		});

		setImageUrl(
			URL.createObjectURL(response.data)
		);
	}, [picture.hash, thumbnail]);

	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="center"
			flexGrow={1}
			minWidth={0}
			overflow="hidden"
			sx={(fullscreen && {
				position: "fixed",
				backgroundColor: "rgba(0, 0, 0, 0.8)",
				backdropFilter: "blur(6px)",
				left: 0,
				top: 0,
				width: "100%",
				height: "100%",
				zIndex: 2000
			})}
			{...rest}
		>
			{!imageUrl && (
				<Box
					flexGrow={1}
					minHeight={250}
					alignItems="center"
					justifyContent="center"
					display="flex"
				>
					<CircularProgress size={32} color="inherit" />
				</Box>
			)}
			{imageUrl && (
				<Box component="img"
					sx={{
						cursor: "pointer",
						flexGrow: 1,
						minWidth: 0,
						maxHeight: "100%",
						objectFit: "contain"
					}}
					src={imageUrl}
					alt="Fahrzeugbild"
					onClick={() => setFullscreen(f => !f)}
				/>
			)}
		</Box>
	);
}
