import { ArmsSimpleValue } from "./ArmsVehicle";
import formatCurrency from "../../../system/formatCurrency";
import formatDate from "../../../system/formatDate";
import formatDecimal from "../../../system/formatDecimal";

function armsValueConverter(simpleValue : ArmsSimpleValue) {
	if (simpleValue.dataType === "Date" && simpleValue.value) {
		return formatDate(simpleValue.value);
	}

	if (simpleValue.dataType === "Bool") {
		return simpleValue.value ? "Ja" : "Nein";
	}

	if (simpleValue.dataType === "Currency" && simpleValue.value) {
		return formatCurrency(simpleValue.value);
	}

	if (simpleValue.dataType === "Decimal" && simpleValue.value) {
		return formatDecimal(simpleValue.value);
	}

	if (simpleValue.dataType === "Strings" && simpleValue.value) {
		return simpleValue.value.join(", ");
	}

	return simpleValue.value;
}

export default armsValueConverter;
