import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Layout from "../../Components/Layout/Layout";
import { Box, CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Tile from "../../Components/Tiles/Tile";
import TileContent from "../../Components/Tiles/TileContent";
import Chart, { MarkerInfo } from "../../Components/Chart";
import { Company as CompanyModel, Statistic, StatisticItem, StatisticItems } from "../../system/Domain";
import moment from "moment";
import getInterval, { Interval } from "../../Components/getInterval";
import _ from "lodash";
import ActionButton from "../../Components/ActionButton";
import Actions from "../../Components/Actions";

export default () => {
	const [interval, setInterval] = useState<Interval>({
		dateTo: moment().endOf("month").format(moment.HTML5_FMT.DATE),
		dateFrom: moment().startOf("month").subtract(2, "months").format(moment.HTML5_FMT.DATE)
	});

	const [loading, setLoading] = useState(false);
	const [companies, setCompanies] = useState<Array<CompanyModel>>([]);
	const [data, setData] = useState<Array<Statistic>>([]);

	const history = useHistory();

	useEffect(() => {
		setLoading(true);
		Promise.all([
			axios.get<Array<CompanyModel>>("/api/companies?type=LogisticCompany"),
			axios.post<Array<Statistic>>("/api/statistics", interval)
		]).then(results => {
			setCompanies(results[0].data);
			setData(results[1].data.filter(d => d.isWorkingDay));
			setLoading(false);
		});
	}, [interval]);

	const handleSetInterval = async () => {
		const result = await getInterval("Auswertungszeitraum", interval);
		if (result) {
			setInterval(result);
		}
	};

	const getItem = (items : StatisticItems, key : string) : (StatisticItem | undefined) => {
		const existingKey = Object.keys(items).find(k => k.toLowerCase() === key.toLowerCase());
		if (!existingKey) {
			return undefined;
		}

		return items[existingKey];
	};

	return (
		<Layout
			title="Dienstleister"
		>
			{loading && (
				<Typography component="div" align="center">
					<CircularProgress />
				</Typography>
			)}
			{!loading && (
				<>
					<Actions>
						<ActionButton onClick={handleSetInterval}>Zeitraum festlegen</ActionButton>
					</Actions>
					<Box mt={2}>
						<Grid
							container
							spacing={6}
						>
							{companies.filter(c => c && c.transports && c.transports.active && c.name !== "Eigenanlieferung").map(c => {
								const orders = data.map(s => ({
									x: s.date,
									y: getItem(s.items, c.name)?.orders ?? 0
								}));

								const series = [
									{
										name: "orders",
										title: "Aufträge gesamt",
										data: orders
									}, {
										name: "expectedReturns",
										title: "Aufträge ausserhalb SLA",
										data: data.map(s => ({
											x: s.date,
											y: getItem(s.items, c.name)?.expectedReturns ?? 0
										}))
									}
								];

								// make sure, we only display content if there is any data
								const sumOfDataPoints = _.sum(series.map(s => s.data).flat().map(d => d.y));

								const handleMarkerClick = (info : MarkerInfo) => {
									const dataPoint = data.find(s => moment(s.date).isSame(info.x));
									if (dataPoint) {
										history.push(`/statistics/${dataPoint.id}?eventType=${info.series}&company=${c.name}`);
									}
								};

								return (
									<Grid key={c.name} item xs={12} sm={12} md={12} lg={6} xl={4}>
										<Tile
											title={c.name}
										>
											<TileContent>
												{sumOfDataPoints > 0 && (
													<Chart
														variant="area"
														series={series}
														yMax={300}
														onMarkerClick={handleMarkerClick}
													/>
												)}
												{sumOfDataPoints === 0 && (
													<Typography>Keine Daten gefunden</Typography>
												)}
											</TileContent>
										</Tile>
									</Grid>
								);
							})}
						</Grid>
					</Box>
				</>
			)}
		</Layout>
	);
};
