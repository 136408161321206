import { Adjust, Check, Close } from '@mui/icons-material';
import { BoxProps } from '@mui/material';

import ListItemChip from '../../Components/ListItemChip';

interface Props extends BoxProps {
	source : string
	exists : boolean
	matches : boolean
	loading? : boolean
}

export default ({ source, loading = false, exists, matches, ...rest } : Props) => {
	let icon;
	let color: any = "info";

	if (exists) {
		if (matches) {
			icon = <Check/>;
			color = "success";
		} else {
			icon = <Adjust />;
			color = "warning";
		}
	} else {
		icon = <Close />;
		// color = "error";
	}

	return (
		<ListItemChip
			label={source}
			icon={icon}
			loading={loading}
			color={color}
			{...rest}
		/>
	);
}
