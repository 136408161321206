import moment from "moment";
import { Box } from "@mui/material";
import TextBlock from "./Layout/TextBlock";
import { Appointment } from "../system/Domain";
import dateFormats from "../system/dateFormats";

interface Props {
	appointment: Appointment
	initialAppointment?: Appointment
}

const AppointmentInformation = (props: Props) => {
	const getTimeDisplay = (appointment: Appointment): string => {
		if (appointment.from) {
			if (appointment.to) {
				return `${moment(appointment.from).format(dateFormats.time)} - ${moment(appointment.to).format(dateFormats.time)}`
			} else {
				return `ab ${moment(appointment.from).format(dateFormats.time)}`;
			}
		} else {
			if (appointment.to) {
				return `bis ${moment(appointment.to).format(dateFormats.time)}`
			} else {
				return 'keine Zeit angegeben';
			}
		}
	};

	if (!props.appointment.to && !props.appointment.from && !props.appointment.notBefore) {
		return <></>;
	}

	const showInitialAppointment = props.initialAppointment && (
		props.initialAppointment.notBefore !== props.appointment.notBefore
		|| props.initialAppointment.from !== props.appointment.from
		|| props.initialAppointment.to !== props.appointment.to
	);

	return (
		<Box mt={2}>
			<TextBlock
				primary={`Ab dem: ${moment(props.appointment.notBefore).format(dateFormats.date)}`}
				secondary={getTimeDisplay(props.appointment)}
				tertiary={showInitialAppointment ? `Ursprüngliche Angabe: ${moment(props.initialAppointment.notBefore).format(dateFormats.date)} - ${getTimeDisplay(props.initialAppointment)}` : undefined}
			/>
		</Box>
	);
};

export default AppointmentInformation;
