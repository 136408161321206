import { Fragment, ReactElement, useState, MouseEvent } from "react";
import Tile from "../../../Components/Tiles/Tile";
import TileContent from "../../../Components/Tiles/TileContent";
import { useTheme } from "@mui/styles";
import { CapacityKpisGroup, NumberQuery } from "../../../system/Domain";
import { alpha, Box, BoxProps, Button, colors, IconButton, lighten } from "@mui/material";
import formatNumber from "../../../system/formatNumber";
import useChartOptions from "../../../system/useChartOptions";
import Highcharts from "../../../Components/Highcharts";
import TileContentDivider from "../../../Components/Tiles/TileContentDivider";
import { CloudDownload, Launch } from "@mui/icons-material";
import createKpiStyles from "Components/createKpiStyles";

interface EntryProps<TQuery> {
	group: CapacityKpisGroup<TQuery>;
	active: boolean;
	showNullAge?: boolean;
	startSession: (group: CapacityKpisGroup<TQuery>) => Promise<void>;
	exportKpis: (group: CapacityKpisGroup<TQuery>) => Promise<void>;
}

interface AgeBucket {
	label: string;
	color: string;
	query: NumberQuery;
}

export const KpisGroupTileEntry = <TQuery,>({ group, active, showNullAge = false, startSession, exportKpis, ...rest }: EntryProps<TQuery> & BoxProps) => {
	const theme = useTheme();
	const kpiStyles = createKpiStyles(theme);
	const [options] = useChartOptions();

	const mapAgeToColor = (age: number) => {
		switch (age) {
			case 60:
				return alpha(colors.red["A700"], 0.8);
			case 30:
				return alpha(colors.deepOrange["A700"], 0.8);
			case 14:
				return alpha(colors.orange["A700"], 0.8);
			case 7:
				return alpha(colors.amber["A700"], 0.8);
			case 0:
				return alpha(colors.green["A700"], 0.8);
			default:
				return alpha(colors.grey["300"], 0.8);
		}
	};

	const ageBuckets: AgeBucket[] = group.ages.map((a, index) => ({
		label:
			a.fromAge == null
				? "noch nicht eingegangen"
				: index === 0
					? `mehr als ${a.fromAge} Tage`
					: `${a.fromAge} bis ${group.ages[index - 1].fromAge} Tage`,
		color: mapAgeToColor(a.fromAge),
		query:
			a.fromAge == null
				? null
				: index === 0
					? {
							greaterThanOrEquals: a.fromAge,
						}
					: {
							greaterThanOrEquals: a.fromAge,
							lessThan: group.ages[index - 1].fromAge,
						},
	}));

	return (
		<Box sx={[kpiStyles.entry, group.total === 0 && kpiStyles.opaque, active && kpiStyles.active]} {...rest}>
			<Box sx={[kpiStyles.columns, { justifyContent: "space-between", alignItems: "end" }]}>
				<Box>{group.name}</Box>
				<Box sx={[kpiStyles.columns, { alignItems: "end" }]}>
					<Box>
						{formatNumber(group.total)}
						{group.capacity ? ` / ${formatNumber(group.capacity)}` : ""}
					</Box>
					<Highcharts
						options={{
							...options,
							chart: {
								animation: false,
								width: 64,
								height: 28,
								spacing: [1, 1, 1, 1],
								backgroundColor: "transparent",
								borderWidth: 0,
							},
							legend: {
								enabled: false,
							},
							xAxis: {
								visible: false,
							},
							yAxis: {
								visible: false,
							},
							tooltip: {
								enabled: false,
							},
							plotOptions: {
								column: {
									borderWidth: 0,
									colorByPoint: true,
									cursor: "pointer",
									colors: ageBuckets.map((b) => b.color),
								},
							},
							series: [
								{
									type: "column",
									data: group.ages.map((a) => a.count),
									animation: false,
								},
							],
						}}
					/>
				</Box>
			</Box>
			{active && group.total !== 0 && (
				<Box sx={kpiStyles.details}>
					<Box sx={kpiStyles.detailActions}>
						<Button
							size="small"
							variant="outlined"
							color="inherit"
							onClick={(e) => {
								e.stopPropagation();
								startSession(group);
							}}
							startIcon={<Launch />}
						>
							Bearbeitung starten
						</Button>
						<Button
							size="small"
							variant="outlined"
							color="inherit"
							onClick={(e) => {
								e.stopPropagation();
								exportKpis(group);
							}}
							startIcon={<CloudDownload />}
						>
							Exportieren
						</Button>
					</Box>
					{ageBuckets.length > 0 && <Box sx={kpiStyles.agesInfo}>Sortierung nach Platzeingang</Box>}
					{ageBuckets
						.filter(
							(b, index) =>
								showNullAge || group.ages[index].fromAge !== null || group.ages[index].count !== 0,
						)
						.map((b, index) => ({
							bucket: b,
							count: group.ages[index].count,
						}))
						.map((a, i) => (
							<Box
								key={i}
								sx={[kpiStyles.columns, kpiStyles.ageEntry, a.count === 0 && kpiStyles.opaque]}
							>
								<Box
									sx={kpiStyles.ageLabel}
									style={{
										backgroundColor: a.bucket.color,
										borderColor: lighten(a.bucket.color, 0.2),
										color: theme.palette.getContrastText(a.bucket.color),
									}}
								>
									{formatNumber(a.count)}
								</Box>
								<Box>{a.bucket.label}</Box>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										gap: 1,
									}}
								>
									<IconButton
										size="small"
										color="inherit"
										onClick={(e) => {
											e.stopPropagation();
											startSession({
												...group,
												query: {
													...group.query,
													age: a.bucket.query,
												},
											});
										}}
										disabled={!!!a.bucket.query}
									>
										<Launch sx={{ fontSize: "1.2rem" }} />
									</IconButton>
									<IconButton
										size="small"
										color="inherit"
										onClick={(e) => {
											e.stopPropagation();
											exportKpis({
												...group,
												query: {
													...group.query,
													age: a.bucket.query,
												},
											});
										}}
										disabled={!!!a.bucket.query}
									>
										<CloudDownload sx={{ fontSize: "1.2rem" }} />
									</IconButton>
								</Box>
							</Box>
						))}
				</Box>
			)}
		</Box>
	);
};

interface TileProps<TQuery> {
	tileName?: string
	tileIcon?: ReactElement
	group: CapacityKpisGroup<TQuery>
	showNullAge?: boolean
	startSession: (group: CapacityKpisGroup<TQuery>) => Promise<void>
	exportKpis: (group: CapacityKpisGroup<TQuery>) => Promise<void>
}

export default <TQuery,>({ tileName = null, tileIcon = null, group, showNullAge = false, startSession, exportKpis }: TileProps<TQuery>) => {
	const [activeEntry, setActiveEntry] = useState<string | undefined>();

	const handleClick = (e: MouseEvent<HTMLDivElement>, group: CapacityKpisGroup<TQuery>) => {
		setActiveEntry((value) => (value === group.id ? undefined : group.id));
	};

	const title = !tileIcon ? (tileName ?? group.name) : (
		<Box display="flex" flexDirection="row" justifyContent="space-between">
			<Box>{tileName ?? group.name}</Box>
			<Box paddingRight="1.11vh">
				{tileIcon}
			</Box>
		</Box >
	);

	return (
		<Tile title={title}>
			<TileContent dense>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 1.5,
						paddingTop: 1.5,
						paddingBottom: 1.5,
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<KpisGroupTileEntry
							key={group.id}
							group={{
								...group,
								name: "Gesamt",
							}}
							showNullAge={showNullAge}
							active={activeEntry === group.id}
							startSession={startSession}
							exportKpis={exportKpis}
							onClick={(e) => handleClick(e, group)}
						/>
					</Box>

					{group.sections
						.filter((s) => s.groups.length > 0)
						.map((s, i) => (
							<Fragment key={i}>
								<TileContentDivider dense />
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
									}}
								>
									{s.groups.map((g) => (
										<KpisGroupTileEntry
											key={g.id}
											group={g}
											active={activeEntry === g.id}
											startSession={startSession}
											exportKpis={exportKpis}
											onClick={(e) => handleClick(e, g)}
										/>
									))}
								</Box>
							</Fragment>
						))}
				</Box>
			</TileContent>
		</Tile>
	);
};
