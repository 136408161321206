import moment from "moment/moment";
import dateFormats from "../../system/dateFormats";
import { VehicleService } from "../../system/Domain";
import { translateVehicleServiceStatus } from "../../system/translate";

interface StatusTileProps {
	text: string
	date: string
}

export const vehicleServiceStatusInformation = (vehicleService: VehicleService): StatusTileProps => {
	let statusTile: (StatusTileProps | null) = {
		text: translateVehicleServiceStatus(vehicleService.status),
		date: ""
	};

	if (vehicleService) {
		switch (vehicleService.status) {
			case "Created":
				statusTile.date = moment(vehicleService.dateCreated).format(dateFormats.dateTime);
				break;
			case "Requested":
				statusTile.date = moment(vehicleService.dateCreated).format(dateFormats.dateTime);
				break;
			case "Rejected":
				statusTile.date = moment(vehicleService.dateRejected).format(dateFormats.dateTime);
				break;
			case "Cancelled":
				statusTile.date = moment(vehicleService.dateCancelled).format(dateFormats.dateTime);
				break;
			case "Assigned":
				statusTile.date = moment(vehicleService.dateAssigned).format(dateFormats.dateTime);
				break;
			case "Started":
				statusTile.date = moment(vehicleService.dateStarted).format(dateFormats.dateTime);
				break;
			case "Completed":
				statusTile.date = moment(vehicleService.dateCompleted).format(dateFormats.dateTime);
				break;
			case "Billed":
				statusTile.date = moment(vehicleService.dateBilled).format(dateFormats.dateTime);
				break;
		}
	}

	return statusTile;
}
