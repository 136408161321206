import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Sale, SaleCorrection, UserRole, Vehicle } from "../../system/Domain";
import SidebarGroup from "../../Components/Sidebar/SidebarGroup";
import Sidebar from "../../Components/Sidebar/Sidebar";
import SidebarButton from "../../Components/Sidebar/SidebarButton";
import axios from "axios";
import useUser from "../../system/useUser";
import useAction from "../../system/useAction";
import { useHistory } from "react-router";
import ask from "../../Dialogs/ask";

export interface Props {
	vehicle: Vehicle
	setVehicle: Dispatch<SetStateAction<Vehicle | undefined>>
	sale?: Sale
	setSale: Dispatch<SetStateAction<Sale | undefined>>
}

export default ({ vehicle, setVehicle, sale, setSale }: Props) => {
	if (!vehicle) {
		return null;
	}

	const [, , hasRole, , hasAnyRole] = useUser();
	const history = useHistory();

	const [create, isCreating] = useAction(async () => {
		await axios.post<Sale>(`/api/sales`, { vehicleId: vehicle.id });
		const { data } = await axios.get<Vehicle>(`/api/vehicles/${vehicle.id}`);
		setVehicle(data);
	});

	const [cancel, isCancelling] = useAction(async () => {
		if (!vehicle.remarketing.saleId) {
			return;
		}

		const answer = await ask("Soll der Verkauf sicher unwiderruflich gelöscht werden?");
		if (answer.yes) {
			await axios.delete<Sale>(`/api/sales/${vehicle.remarketing.saleId}`);
			const { data } = await axios.get<Vehicle>(`/api/vehicles/${vehicle.id}`);
			setVehicle(data);
		}
	});

	const [synchronize, isSynchronizing] = useAction(async () => {
		axios.post(`/api/vehicles/${vehicle.id}/synchronize`, {});
	});

	const [correction, setCorrection] = useState<SaleCorrection>();
	useEffect(() => {
		if (!sale) {
			setCorrection(undefined);
			return;
		}

		const correction = sale.corrections.find(c => c.status === "Created");
		setCorrection(correction);
	}, [sale]);

	const [createCorrection, isCreatingCorrection] = useAction(async () => {
		if (correction) {
			return;
		}

		const { data } = await axios.post<Sale>(`/api/sales/${vehicle.remarketing.saleId}/corrections`);
		setSale(data);
	});

	const [cancelCorrection, isCancellingCorrection] = useAction(async () => {
		if (!correction) {
			return;
		}

		const { data } = await axios.post<Sale>(`/api/sales/${vehicle.remarketing.saleId}/corrections/${correction.id}/cancel`);
		setSale(data);
	});

	const [applyCorrection, isApplyingCorrection] = useAction(async () => {
		if (!correction) {
			return;
		}

		const { data } = await axios.post<Sale>(`/api/sales/${vehicle.remarketing.saleId}/corrections/${correction.id}/apply`);
		setSale(data);
	});

	return (
		<Sidebar>
			<SidebarGroup>
				{!vehicle.remarketing.saleId && (
					<SidebarButton
						label="Verkauf anlegen"
						color="primary"
						disabled={isCreating || !vehicle.remarketing.isReadyForSale}
						onClick={create}
					/>
				)}
				{!!vehicle.remarketing.saleId && (
					<SidebarButton
						label="Zum Verkauf"
			
						onClick={() => history.push(`/sales/${vehicle.remarketing.saleId}`)}
					/>
				)}
				{hasRole(UserRole.AldAdmin) && !!vehicle.remarketing.saleId && (
					<SidebarButton
						label="Verkauf stornieren"
						color="secondary"
						disabled={isCancelling}
						onClick={cancel}
					/>
				)}
			</SidebarGroup>
			<SidebarGroup>
				{!!sale && !correction && (
					<SidebarButton
						label="Korrektur starten"
				
						disabled={isCreatingCorrection}
						onClick={createCorrection}
					/>
				)}

				{!!sale && !!correction && (
					<SidebarButton
						label="Korrektur bestätigen"
						color="primary"
						disabled={isApplyingCorrection}
						onClick={applyCorrection}
					/>
				)}

				{!!sale && !!correction && (
					<SidebarButton
						label="Korrektur abbrechen"
						color="secondary"
						disabled={isCancellingCorrection}
						onClick={cancelCorrection}
					/>
				)}

				{hasAnyRole([UserRole.SalesAdmin, UserRole.SalesBackoffice]) && (
					<SidebarButton
						label="Synchronisieren"
				
						disabled={isSynchronizing}
						onClick={synchronize}
					/>
				)}
			</SidebarGroup>
		</Sidebar>
	);
};
