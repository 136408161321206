import { useState } from "react";

export default (action: () => Promise<void>) => {
	const [actionRunning, setActionRunning] = useState(false);

	const runAction = async () => {
		if (actionRunning) {
			return;
		}

		try {
			setActionRunning(true);
			await action();
		} finally {
			setActionRunning(false);
		}
	};

	return [runAction, actionRunning] as const;
};
