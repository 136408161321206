import Tile from "../../../Components/Tiles/Tile";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TileContent from "../../../Components/Tiles/TileContent";
import TextBlock from "../../../Components/Layout/TextBlock";
import TileActions from "../../../Components/Tiles/TileActions";
import ActionButton from "../../../Components/ActionButton";
import { dorfmarkAddress, ReturnOrder } from "../../../system/Domain";
import moment from "moment";
import dateFormats from "../../../system/dateFormats";

interface Props {
	returnOrder: ReturnOrder
	primary?: ButtonProps
	secondary?: ButtonProps
}

interface ButtonProps {
	title: string
	action: () => void
}

const CustomerDeliveryOverview = (props: Props) => {
	return (
		<Tile>
			<Box p={2}>
				<Typography variant="h6">Anlieferung</Typography>
			</Box>
			<Divider />
			<TileContent>
				<Box>
					<TextBlock
						primary={dorfmarkAddress.name}
						secondary={`${dorfmarkAddress.street} - ${dorfmarkAddress.zipCode}`}
						tertiary={`${dorfmarkAddress.city} - Deutschland`}
					/>
					<Box mt={2}>
						<TextBlock
							primary={moment(props.returnOrder.delivery.appointment.notBefore).format(dateFormats.date)}
							secondary="Vereinbarter Anlieferungstermin"
						/>
					</Box>
				</Box>
			</TileContent>
			<TileActions>
				{props.secondary && (
					<ActionButton
						variant="text"
						hideOnDisabled
						color="secondary"
						title={props.secondary.title}
						onClick={props.secondary.action}
					>
						{props.secondary.title}
					</ActionButton>
				)}
				{props.primary && (
					<ActionButton
						variant="text"
						hideOnDisabled
						title={props.primary.title}
						onClick={props.primary.action}
					>
						{props.primary.title}
					</ActionButton>
				)}
			</TileActions>
		</Tile>
	);
}

export default CustomerDeliveryOverview;
