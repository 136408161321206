import { UserRole } from "system/Domain";
import LayoutNavigationItem from "../Components/Layout/LayoutNavigationItem";
import { CarCrash, Explore, FormatListNumbered, Receipt, Search } from "@mui/icons-material";
import LayoutNavigationSection from "../Components/Layout/LayoutNavigationSection";

export default () => {
	return (
		<LayoutNavigationSection
			title="Fahrzeuge"
		>
			<LayoutNavigationItem
				roles={[UserRole.AldUser, UserRole.LotManager]}
				icon={<Explore />}
				title="Dashboard"
				path="/vehicles/dashboard"
			/>
			<LayoutNavigationItem
				roles={[UserRole.AldUser, UserRole.LotManager]}
				icon={<Search />}
				title="Fahrzeuge"
				path="/vehicles/search"
			/>
			<LayoutNavigationItem
				roles={[UserRole.AldUser, UserRole.LotManager]}
				icon={<FormatListNumbered />}
				title="Inventuren"
				path="/inventories"
			/>
			<LayoutNavigationItem
				roles={[UserRole.SalesUser]}
				icon={<Receipt />}
				title="Papiere"
				path="/vehicles/registration-documents/dashboard"
			/>
			<LayoutNavigationItem
				roles={[UserRole.AldUser]}
				icon={<CarCrash />}
				title="Anlieferungen"
				path="/vehicles/arrivals"
			/>
		</LayoutNavigationSection>
	);
};
