import { CircularProgress } from "@mui/material";
import axios from "axios";
import ActivityTile, { Activity } from "Components/Activities/ActivityTile";
import moment from "moment";
import VehicleNavigation from "Navigation/VehicleNavigation";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { LeasmanVehicle } from "system/Domain";
import useAxiosEffect from "system/useAxiosEffect";
import Layout from "../../Components/Layout/Layout";
import useVehicle from "../../system/useVehicle";
import VehicleHeader from "./VehicleHeader";

export default () => {
	const { id } = useParams<{ id: string }>();
	const [vehicle] = useVehicle(id);
	const [leasmanVehicles, setLeasmanVehicles] = useState<LeasmanVehicle[]>([]);

	const [activities, setActivities] = useState<Activity[]>([]);
	const [vehicleActivities, setVehicleActivities] = useState<Activity[]>([]);
	const [leasmanVehicleActivities, setLeasmanVehicleActivities] = useState<Activity[]>([]);

	useEffect(() => {
		setActivities([...vehicleActivities, ...leasmanVehicleActivities]);
	}, [vehicleActivities, leasmanVehicleActivities]);

	useAxiosEffect(
		async (config) => {
			if (!vehicle) {
				setLeasmanVehicles(undefined);
				return;
			}

			const { data } = await axios.get<LeasmanVehicle[]>(`/api/leasman/vehicles`, {
				...config,
				params: {
					vin: vehicle.vin,
				},
			});

			setLeasmanVehicles(data);
		},
		[vehicle?.vin],
	);

	useEffect(() => {
		if (!vehicle) {
			setVehicleActivities([]);
			return;
		}

		let orderId = 0;
		const activities = [];

		// activities.push({
		// 	type: "generic",
		// 	date: moment(vehicle.dateCreated),
		// 	payload: {
		// 		message: "Anlage in Carlos",
		// 	},
		// });

		if (!!vehicle.history.currentStockDateRange) {
			activities.push({
				type: "generic",
				date: moment(vehicle.history.currentStockDateRange.from),
				orderId: ++orderId,
				payload: {
					message: "Registrierung in der Platzlogistik",
				},
			});

			for (const entry of vehicle.history.entries) {
				if (entry.statusChanged) {
					activities.push({
						type: "remarketing.status",
						date: moment(entry.date),
						orderId: ++orderId,
						payload: {
							status: entry.status,
						},
					});
				}
			}
		}

		setVehicleActivities(activities);
	}, [vehicle]);

	useEffect(() => {
		if (!leasmanVehicles) {
			setLeasmanVehicleActivities([]);
			return;
		}

		let orderId = 0;
		const activities = [];

		for (const leasmanVehicle of leasmanVehicles) {
			activities.push({
				type: "generic",
				date: moment(leasmanVehicle.contract.begin.date),
				orderId: ++orderId,
				payload: {
					message: `Beginn Leasingvertrag ${leasmanVehicle.contract.id} mit ${leasmanVehicle.contract.customer.fullName}`,
				},
			});

			if (leasmanVehicle.order?.dateDelivered) {
				activities.push({
					type: "generic",
					date: moment(leasmanVehicle.order.dateDelivered),
					orderId: ++orderId,
					payload: {
						message: `Lieferung des Fahrzeugs`,
					},
				});
			}

			if (leasmanVehicle.contract.end?.date) {
				activities.push({
					type: "generic",
					date: moment(leasmanVehicle.contract.end.date),
					orderId: ++orderId,
					payload: {
						message: `Ende Leasingvertrag ${leasmanVehicle.contract.id} mit ${leasmanVehicle.contract.customer.fullName}`,
					},
				});
			}
		}

		setLeasmanVehicleActivities(activities);
	}, [leasmanVehicles]);

	return (
		<Layout
			title="Dashboard"
			plateNumber={vehicle?.plateNumber}
			navigation={<VehicleNavigation vehicle={vehicle} />}
		>
			{!vehicle && <CircularProgress size={48} />}
			{vehicle && (
				<>
					<VehicleHeader vehicle={vehicle} />
				</>
			)}
			<ActivityTile activities={activities} />
		</Layout>
	);
};
