import { useHistory } from "react-router-dom";
import getFiles from "../Dialogs/getFiles";
import executeWithProgressDialog from "../Dialogs/executeWithProgressDialog";
import axios from "axios";
import assignPickupDate from "../Pages/Orders/Transports/assignPickupDate";
import cancelOrder from "../Pages/Orders/Transports/cancelOrder";
import fileDownload from "js-file-download";
import { Dispatch, SetStateAction, useState } from "react";
import getNote from "../Dialogs/getNote";
import parseContentDispositionFilename from "./parseContentDispositionFilename";
import { CompoundReference, CompoundRegistrationReference, TransportOrder, UserRole, VehiclePicture } from "./Domain";
import moment from "moment";
import dateFormats from "./dateFormats";
import getDateDialog from "../Components/getDateDialog";
import useUser from "./useUser";

export interface OrderType extends TransportOrder {
	pickupStatus: string;
	returnStatus: string;
	deliveryStatus: string;
	isFlexDelivery: boolean;
	confirm: () => Promise<void>;
	cancel: () => Promise<void>;
	return: () => Promise<void>;
	assignPickupDate: () => Promise<void>;
	assignReturnDate: () => Promise<void>;
	requestCancellation: () => Promise<void>;
	completeDelivery: () => Promise<void>;
	reopen: () => Promise<void>;
	uploadProtocol: () => Promise<void>;
	addNote: () => Promise<void>;
	addInternalNote: () => Promise<void>;
	resetUploadProtocol: () => Promise<void>;
	downloadProtocol: () => Promise<void>;
	downloadManualProtocol: () => Promise<void>;
	saveRemarks: (remark: string) => Promise<void>;
	downloadOrder: () => Promise<void>;
	downloadQRCode: () => Promise<void>;
	getCompoundReference: () => Promise<CompoundRegistrationReference>;
	getVehiclePicture: () => Promise<VehiclePicture | null>;
	postCompoundReference: () => Promise<CompoundRegistrationReference>;
	getHistory: () => Promise<any>;
	downloadCommissionCsv: () => Promise<void>;
}

export default function (
	initialOrder: TransportOrder | null,
): [OrderType | null, Dispatch<SetStateAction<TransportOrder | null>>] {
	const [order, setOrder] = useState<TransportOrder | null>(initialOrder);
	const [user] = useUser();

	const history = useHistory();

	async function onUploadProtocol() {
		const result = await getFiles("Rücknahmeprotokoll hochladen", {
			multiple: false,
		});

		if (result.ok) {
			await executeWithProgressDialog(async (progress) => {
				let formData = new FormData();
				formData.append("file", result.files[0]);

				const response = await axios.post(`/api/orders/transports/${order!.id}/protocol`, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
					onUploadProgress: (evt) => {
						const completed = Math.ceil((evt.loaded / evt.total) * 100);
						progress(completed);
					},
				});
				setOrder(response.data);
			});
		}
	}

	async function onResetUploadProtocol() {
		const response = await axios.post(`/api/orders/transports/${order!.id}/protocol`, new FormData(), {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
		setOrder(response.data);
	}

	async function onCancelPickup() {
		const response = await axios.put(`/api/orders/transports/${order!.id}/planned-pickup`, {
			date: null,
			driver: null,
			remarks: order!.remarks,
		});
		setOrder(response.data);
	}

	async function handleAssignPickupDate() {
		if (!order) return;

		const result = await assignPickupDate({ order });

		if (!result) return;

		const response = await axios.put<TransportOrder>(`/api/orders/transports/${order!.id}/plan-pickup`, result);
		setOrder(response.data);
	}

	async function requestCancellation() {
		const isAld = order && user && user.roles.includes(UserRole.AldUser);

		const result = await cancelOrder({ order, canSeeWillRecreate: isAld ?? false });
		if (result) {
			await axios.post(`/api/orders/transports/${order!.id}/cancel`, {
				cancellationReason: result.reason,
				cancellationComment: result.comment,
				returnDate: new Date(),
				willRecreate: result.willRecreate,
			});
		}
	}

	function onReturn() {
		history.push(`/orders/transports/${order!.id}/return`);
	}

	async function handleAssignReturnDate() {
		const result = await getDateDialog("Rückgabedatum angeben");
		if (result) {
			await axios.post(`/api/orders/transports/${order!.id}/complete`, { dateReturned: result });
		}
	}

	async function completeDelivery() {
		await axios.post(`/api/orders/transports/${order!.id}/complete`, {});
	}

	async function confirm() {
		await axios.post(`/api/orders/transports/${order!.id}/confirm`, {});
	}

	async function getHistory() {
		const { data } = await axios.get(`/api/orders/transports/${order!.id}/history`);
		return data;
	}

	async function getVehiclePicture() {
		const { data } = await axios.get<VehiclePicture>(`/api/orders/transports/${order!.id}/vehicle/picture`);
		return data;
	}

	async function onDownloadProtocol() {
		const response = await axios.get(`/api/orders/transports/${order!.id}/protocol`, {
			responseType: "blob",
		});
		fileDownload(response.data, order!.protocol!.filename);
	}

	async function onDownloadManualProtocol() {
		const response = await axios.get(`/api/orders/transports/${order!.id}/protocol/manual`, {
			responseType: "blob",
		});
		fileDownload(response.data, order!.protocol!.filename);
	}

	async function onDownloadOrder() {
		const response = await axios.get(`/api/orders/transports/${order!.id}/order.pdf`, {
			responseType: "blob",
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);
	}

	async function onDownloadQRCode() {
		const response = await axios.get(`/api/orders/transports/${order!.id}/qr-code`, {
			responseType: "blob",
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);
	}

	async function downloadCommissionCsv() {
		const response = await axios.get(`/api/orders/transports/${order!.id}/commission.csv`, {
			responseType: "blob",
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);
	}

	async function handleSaveRemarks(remarks: string) {
		const response = await axios.put(`/api/orders/transports/${order!.id}/remarks`, { remarks });
		setOrder(response.data);
	}

	async function addNote() {
		const result = await getNote();
		if (result.ok) {
			await axios.post(`/api/orders/transports/${order!.id}/notes`, { text: result.text });
		}
	}

	async function addInternalNote() {
		const result = await getNote();
		if (result.ok) {
			await axios.post(`/api/orders/transports/${order!.id}/notes/internal`, { text: result.text });
		}
	}

	const getCompoundReference = async () => {
		const response = await axios.get<CompoundReference>(`/api/orders/transports/${order!.id}/compound-reference`);
		return response.data;
	};

	const postCompoundReference = async () => {
		const response = await axios.post<CompoundReference>(`/api/orders/transports/${order!.id}/compound-reference`);
		return response.data;
	};

	const reopen = async () => {
		const { data } = await axios.post<CompoundReference>(`/api/orders/transports/${order!.id}/reopen`);
		return data;
	};

	if (order && user) {
		const isAld = user.roles.includes(UserRole.AldUser);
		const isAldManager = user.roles.includes(UserRole.AldManager);
		const isLotManager = user.roles.includes(UserRole.LotManager);
		const isLogisticCompany = user.roles.includes(UserRole.LogisticCompany);
		const isDriver = user.roles.includes(UserRole.Driver);

		Object.assign(order, {
			addNote,
			addInternalNote,
			get isFlexDelivery() {
				return order?.source === "Stock";
			},
			get pickupStatus() {
				if (!order) return "";

				if (order.pickupDate) {
					return `Wurde am ${moment(order.pickupDate).format(dateFormats.date)} abgeholt`;
				}

				if (order.plannedPickupDate) {
					return `Geplanter Abholtermin ${moment(order.plannedPickupDate).format(dateFormats.dateTime)}`;
				}

				return "Abholung ungeplant";
			},
			get returnStatus() {
				if (!order) return "";

				if (!order.assignedTo) {
					return "Auftrag wurde noch keinem Dienstleister zugewiesen";
				}

				if (order.returnDate) {
					return `Wurde am ${moment(order.returnDate).format(dateFormats.date)} zurückgegeben`;
				}
				if (order.plannedReturnPeriod) {
					const from = moment(order.plannedReturnPeriod.from);
					const to = moment(order.plannedReturnPeriod.to);
					return `Anlieferung zum ${from.format(dateFormats.date)} zwischen ${from.format("HH:mm")} Uhr und ${to.format("HH:mm")} Uhr geplant`;
				}

				return `Soll bis zum ${moment(order.expectedReturnDate).format(dateFormats.date)} zurückgegeben werden`;
			},
			get deliveryStatus() {
				if (!order) return "";

				if (!order.assignedTo) {
					return "Auftrag wurde noch keinem Dienstleister zugewiesen";
				}

				if (order.returnDate) {
					return `Wurde am ${moment(order.returnDate).format(dateFormats.date)} ausgeliefert`;
				}

				if (order.plannedReturnPeriod) {
					const from = moment(order.plannedReturnPeriod.from);
					const to = moment(order.plannedReturnPeriod.to);
					return `Auslieferung zum ${from.format(dateFormats.date)} zwischen ${from.format("HH:mm")} Uhr und ${to.format("HH:mm")} Uhr geplant`;
				}

				return `Soll bis zum ${moment(order.expectedReturnDate).format(dateFormats.date)} ausgeliefert werden`;
			},
			requestCancellation: order.status === "Assigned" ? requestCancellation : null,
			confirm: isLogisticCompany && order.status === "Assigned" && !order.confirmationDate ? confirm : null,
			assignReturnDate: isAld && order.status === "Assigned" && !order.returnDate ? handleAssignReturnDate : null,
			completeDelivery: isAld && order.status === "Assigned" && !order.vehicleId ? completeDelivery : null,
			assignPickupDate:
				isLogisticCompany && order.status === "Assigned" && !order.pickupDate ? handleAssignPickupDate : null,
			uploadProtocol: isLogisticCompany && order.plannedPickupDate && !order.protocol ? onUploadProtocol : null,
			downloadProtocol: order.protocol ? onDownloadProtocol : null,
			downloadManualProtocol: order.manualProtocol ? onDownloadManualProtocol : null,
			resetUploadProtocol:
				isLogisticCompany && order.status === "Assigned" && order.protocol && !order.returnDate
					? onResetUploadProtocol
					: null,
			return: order.returnDate || (isLotManager && !order.returnDate && order.pickupDate) ? onReturn : null,
			cancelPickup:
				isLogisticCompany && order.status === "Assigned" && !order.returnDate && !order.pickupDate
					? onCancelPickup
					: null,
			downloadOrder: onDownloadOrder,
			getVehiclePicture: getVehiclePicture,
			downloadQRCode: order.vin && onDownloadQRCode,
			setRemarks: handleSaveRemarks,
			getCompoundReference: isAld || isLotManager ? getCompoundReference : null,
			postCompoundReference:
				isLotManager && (order.status === "Assigned" || order.status === "Completed")
					? postCompoundReference
					: null,
			getHistory: isAld || isLogisticCompany || isDriver ? getHistory : null,
			reopen: isAldManager && order.status === "Cancelled" ? reopen : null,
			downloadCommissionCsv: downloadCommissionCsv,
		});
	}

	return [order as OrderType, setOrder];
}
