import axios from 'axios';
import { Dispatch, SetStateAction, useState } from 'react';

import { Add } from '@mui/icons-material';
import { Box, CircularProgress, Typography } from '@mui/material';

import List from '../../Components/List';
import ListItem from '../../Components/ListItem';
import ListItemAction from '../../Components/ListItemAction';
import ListItemActions from '../../Components/ListItemActions';
import ListItemContent from '../../Components/ListItemContent';
import Tile from '../../Components/Tiles/Tile';
import { Vehicle } from '../../system/Domain';
import ArrivalTransmissionNoteListItem from './ArrivalTransmissionNoteListItem';

interface Props {
	vehicle: Vehicle
	setVehicle: Dispatch<SetStateAction<Vehicle | undefined>>
}

interface Note {
	text: string
}

const ArrivalTransmissionNotesTile = (props: Props) => {
	const [newNote, setNewNote] = useState<Note>();
	const [saving, setSaving] = useState(false);

	const addNewNote = () => setNewNote({ text: "" });

	const save = async (text: string) => {
		setSaving(true);
		setNewNote(undefined);

		try {
			const { data: resultVehicle } = await axios.post<Vehicle>(`/api/vehicles/${props.vehicle.id}/remarketing/documents/arrivaltransmissionnote`,
				{
					"note": text
				});

			props.setVehicle(resultVehicle);
		} finally {
			setSaving(false);
		}
	};

	const history = props.vehicle.remarketing?.registrationDocuments?.arrivalTransmissionNoteHistory;

	return (
		<>
			<Tile title="Packlistennotizen">
				<List>
					{!newNote && !saving && !props.vehicle.remarketing?.registrationDocuments?.arrivalTransmissionNote && (
						<ListItem transparent>
							<ListItemActions>
								<ListItemAction
									icon={<Add />}
									onClick={addNewNote}
								/>
							</ListItemActions>
							<ListItemContent onClick={addNewNote}>
								<Typography variant="subtitle2">Notiz für die nächste Packliste hinzufügen</Typography>
							</ListItemContent>
						</ListItem>
					)}
					{newNote && (
						<ArrivalTransmissionNoteListItem
							vehicle={props.vehicle}
							save={save}
							new
							cancel={() => setNewNote(undefined)}
						/>
					)}
					{saving && (
						<CircularProgress size={48} />
					)}
					{!saving && props.vehicle.remarketing?.registrationDocuments?.arrivalTransmissionNote && (
						<ArrivalTransmissionNoteListItem
							vehicle={props.vehicle}
							save={save}
						/>
					)}
				</List>
			</Tile>
			{history && history.length > 0 && (
				<Box mt={2}>
					<Tile title="Historie">
						<List>
							{history && (
								<>
									{history && history.map((h, i) => (
										<ArrivalTransmissionNoteListItem
											key={i}
											vehicle={props.vehicle}
											history
											note={h}
										/>
									))}
								</>
							)}
						</List>
					</Tile>
				</Box>
			)}
		</>
	);
}

export default ArrivalTransmissionNotesTile;
