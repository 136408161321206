import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Cancel, Check } from '@mui/icons-material';
import List from '../../../Components/List';
import ListItem from '../../../Components/ListItem';
import ListItemAction from '../../../Components/ListItemAction';
import ListItemActions from '../../../Components/ListItemActions';
import ListItemContent from '../../../Components/ListItemContent';
import { Remarketing, ReturnOrder, TransportOrder } from '../../../system/Domain';
import { DoOperationIndicator, LinkingIndicator, openLink } from './StatusActionTile';

export const calculateUnregistrationStatus = (props : UnregistrationStatusProps) => {

	const linkedUnregistration = props.remarketing?.unregistration?.orderId;
	const newUnregistration = props.returnOrder?.unregistrationOrderId;

	return {
		doUnregistration : props?.returnOrder?.doUnregistration ?? false,
		linkedUnregistration  : linkedUnregistration,
		linkingMissmatch : linkedUnregistration && linkedUnregistration !== newUnregistration
	}
};

interface UnregistrationStatusProps{
	transportOrder : TransportOrder
	returnOrder? : ReturnOrder
	remarketing? : Remarketing
}

interface Props {
	setDoUnregistration : Dispatch<SetStateAction<boolean>>
	setUnlinkUnregistration : Dispatch<SetStateAction<boolean>>
}

const UnregistrationStatusActionTile = (props : UnregistrationStatusProps & Props) => {
	const stats = calculateUnregistrationStatus(props);

	const [doUnregistration, setDoUnregistration] = useState(stats.doUnregistration);
	const [unlinkUnregistration, setUnlinkUnregistration] = useState(!stats.linkedUnregistration);
	const canDoUnregistration = !stats.linkedUnregistration || unlinkUnregistration;

	useEffect(() => {
		props.setDoUnregistration(doUnregistration);
	}, [
		doUnregistration
	]);

	useEffect(() => {
		props.setUnlinkUnregistration(unlinkUnregistration);
	}, [
		unlinkUnregistration
	]);


	const linkRemarketingUnregistration = () => openLink(`${window.origin}/orders/unregistrations/${props.remarketing?.unregistration?.orderId}`);

	return (
		<List>
				<>
					<DoOperationIndicator {...props}
										  doOperation={doUnregistration}
										  setDoOperation={setDoUnregistration}
										  captionDoOperation="Abmeldung wird beauftragt"
										  captionNotDoOperation="Abmeldung wird nicht beauftragt"
					/>
					{stats.linkedUnregistration && doUnregistration && (

						<LinkingIndicator {...props}
										  link={linkRemarketingUnregistration}
										  changeLink={unlinkUnregistration}
										  setChangeLink={setUnlinkUnregistration}
										  captionIsLinked="Eine andere Abmeldung ist mit dem Fahrzeug verlinkt"
										  captionChangeLink="Die mit dem Fahrzeug verbundene Abmeldung wird entlinkt."/>
					)}

					{doUnregistration && (
						<ListItem color={canDoUnregistration ?"success" : "error"}>
							<ListItemActions>
								<ListItemAction
									icon={
										<>
											{!canDoUnregistration && (
												<Cancel />
											)}
											{canDoUnregistration && (
												<Check />
											)}
										</>
									}
								/>
							</ListItemActions>
							<ListItemContent>
								{!canDoUnregistration  && (
									<>Abmeldung kann nicht beauftragt werden</>
								)}
								{canDoUnregistration && (
									<>Abmeldung kann beauftragt werden</>
								)}
							</ListItemContent>
						</ListItem>
					)}
				</>
		</List>
	);
};









export default UnregistrationStatusActionTile;
