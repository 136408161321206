import { PropsWithChildren } from "react";
import { Box, BoxProps, useTheme } from "@mui/material";

export interface Props extends BoxProps {
	itemWidth?: number,
	itemGap?: number
}

export default ({ children, itemWidth = 200, itemGap = 2, ...boxProps }: PropsWithChildren<Props>) => {
	const theme = useTheme();
	return (
		<Box sx={{
			display: "grid",
			gridTemplateColumns: `repeat(auto-fill, minmax(${itemWidth || 200}px, 1fr))`,
			gap: itemGap ? theme.spacing(itemGap) : 0
		}} {...boxProps}>
			{children}
		</Box>
	);
}
