import { Box, BoxProps, PaletteColor, Typography, useTheme } from "@mui/material";
import { ThemeColor } from "system/Domain";

type TransportInfoProps = BoxProps & {
	zipCode?: string
	align: "left" | "right"
	dateAssigned?: string
	zipColor?: "initial" | "inherit" | ThemeColor
}

export default ({ align, zipCode, dateAssigned, zipColor, ...rest }: TransportInfoProps) => {
	const theme = useTheme();

	const actualZipColor = (theme.palette[zipColor] as PaletteColor)?.light ?? zipColor;

	return (
		<Box {...rest}>
			<Typography
				component="div"
				variant="body2"
				align={align}
				color={actualZipColor}
			>
				{zipCode}
			</Typography>
			{dateAssigned && (
				<Typography
					component="div"
					variant="body2"
					align={align}
				>
					{dateAssigned}
				</Typography>
			)}
		</Box>
	);
}
