import * as actionTypes from './actionTypes';

// import { Color } from "@material-ui/lab"; DOMINIK

export function setUserMessage(message: string, color: any) {
	return {
		type: actionTypes.SET_USER_MESSAGE,
		message,
		color
	}
};

export interface SetUserMessageAction {
	type: typeof actionTypes.SET_USER_MESSAGE,
	message: string
	color: any
}

export function clearUserMessage() {
	return {
		type: actionTypes.CLEAR_USER_MESSAGE
	}
};

export interface ClearUserMessageAction {
	type: typeof actionTypes.CLEAR_USER_MESSAGE
}

export type Actions =
	SetUserMessageAction
	| ClearUserMessageAction
	;
