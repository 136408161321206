import Tile from "Components/Tiles/Tile";
import TileContent from "Components/Tiles/TileContent";
import TransportProtocolTable from "./TransportProtocolTable";
import useOrder from "system/useOrder";
import useAsyncEffect from "system/useAsyncEffect";
import axios from "axios";
import { Grid } from "@mui/material";

export interface Props {
	vehicleId: string
}

const VehicleTransportProtocol = ({ vehicleId }: Props) => {
	const [order, setOrder] = useOrder(null);

	useAsyncEffect(async () => {
		if (!vehicleId) return;

		const order = (await axios.get(`/api/orders/transports`, {
			params: {
				vehicleId: vehicleId
			}
		})).data[0];

		if (order) setOrder(order);
	}, [vehicleId]);

	return (
		<>
			{order?.manualProtocol &&
				<Grid item xs={12} sm={6} md={6} lg={4}>
					<Tile title="Protokolle">
						<TileContent dense>
							<TransportProtocolTable
								protocol={order.manualProtocol}
								downloadProtocol={order.downloadManualProtocol}
							/>
						</TileContent>
					</Tile>
				</Grid>
			}
		</>
	);
}

export default VehicleTransportProtocol;
