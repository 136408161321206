import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Refresh } from '@mui/icons-material';
import { Paper, Table, TableRow, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';

import Layout from '../../Components/Layout/Layout';
import Loading from '../../Components/Loading';
import TooltipIconButton from '../../Components/TooltipIconButton';
import { Notification } from '../../system/Domain';
import formatDateTime from '../../system/formatDateTime';
import { AppState } from '../../system/store';
import useAsyncEffect from '../../system/useAsyncEffect';
import NotificationIcon from './NotificationIcon';

export default () => {
	const [inboxNotifications, setInboxNotifications] = useState<Array<Notification>>([]);
	const [notifications, setNotifications] = useState<Array<Notification>>([]);
	const unreadNotifications = useSelector((state: AppState) => state.notifications);
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	const match = useRouteMatch<{ folder?: string }>();
	const theme = useTheme();
	const folder = match.params.folder || "all";

	const loadItems = async () => {
		setLoading(true);
		const response = await axios.get<Array<Notification>>(`/api/notifications?folder=${folder}`);
		setInboxNotifications(response.data);
		setLoading(false);
	};

	useAsyncEffect(async () => {
		await loadItems();
	}, [match]);

	useEffect(() => {
		if (folder === "inbox") {
			// merge the notifications sent by the WebSocket events into the inbox
			const newNotifications = unreadNotifications.filter(n => !notifications.some(nn => nn.id === n.id));
			setNotifications([
				...newNotifications,
				...notifications
			]);
		}
	}, [inboxNotifications, unreadNotifications]);

	const select = async (item: Notification) => {
		history.push(`/notifications/${folder}/${item.id}`);
	};

	const handleRefresh = async () => {
		await loadItems();
	};

	return (
		<Layout title="Nachrichten">
			<Loading loading={loading} />
			{!loading && <>
				<div>
					<TooltipIconButton
						onClick={handleRefresh}
						title="Aktualisieren"
					>
						<Refresh />
					</TooltipIconButton>
				</div>
				{!loading && notifications.length === 0 && <Box mx={2}>Keine Daten gefunden</Box>}
				<Paper sx={{
					marginLeft: theme.spacing(2),
					marginRight: theme.spacing(2)
				}}>
					<Table sx={{
						width: "100%",
						tableLayout: "fixed",
						"& tr td:nth-child(1)": {
							width: "3em"
						},
						"& tr td:nth-child(2)": {
							width: "6em"
						},
						"& tr td:nth-child(3)": {
							width: "20%"
						},
						"& tr td:nth-child(4)": {
							width: "80%"
						}
					}}>
						<TableBody>
							{notifications.map(n => {
								return (
									<TableRow hover onClick={() => select(n)} key={n.id}>
										<TableCell>
											<NotificationIcon category={n.category} />
										</TableCell>
										<TableCell>
											{formatDateTime(n.dateCreated)}
										</TableCell>
										<TableCell style={{
											overflow: "hidden",
											textOverflow: "ellipsis",
											whiteSpace: "nowrap"
										}}>
											{folder === "inbox" ? n.from.name : n.to.name}
										</TableCell>
										<TableCell style={{
											overflow: "hidden",
											textOverflow: "ellipsis",
											whiteSpace: "nowrap"
										}}>
											{n.subject}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</Paper>
			</>}
		</Layout>
	);
};
