import { useState } from "react";
import { VehicleReference } from "system/Domain";
import useAsyncEffect from "system/useAsyncEffect";
import axios from "axios";
import Box from "@mui/material/Box";
import TextBlock from "Components/Layout/TextBlock";
import { ReturnForm as ReturnFormType } from "../../system/Domain";
import { formatTireType, formatRimType } from "Components/FormTiresAndRims";
import { Typography } from "@mui/material";

interface Props {
	vehicle: VehicleReference
}

const VehicleTiresInfo = ({ vehicle }: Props) => {
	const [returnForm, setReturnForm] = useState<ReturnFormType>({});

	useAsyncEffect(async () => {
		if (!vehicle) {
			return;
		}

		const { data: returnForms } = await axios.post<ReturnFormType[]>('/api/orders/transports/return-forms/search',
			{
				vin: vehicle.vin
			});

		// (forms search returns them in descendening order - and we want to take the newest one)
		if (returnForms.length > 0) {
			setReturnForm(returnForms[0]);
		}

	}, [vehicle])

	return (
		<>
			<Box sx={{
				display: "flex",
				flexDirection: "row"
			}}>
				<Box>
					<Typography variant="caption">Montierte Reifen und Felgen</Typography>
					<Box mt={1}>
						<TextBlock primary={formatTireType(returnForm.installedTiresAndRims?.tireType)} secondary="Reifenart" />
					</Box >
					<Box mt={1}>
						<TextBlock primary={formatRimType(returnForm.installedTiresAndRims?.rimType)} secondary="Felgenart" />
					</Box >

					{returnForm.installedTiresAndRims?.spareTire && (
						<Box mt={1}>
							<TextBlock primary="Reserverad vorhanden" />
						</Box >
					)}
					{returnForm.installedTiresAndRims?.wheelTrimCap && (
						<Box mt={1}>
							<TextBlock primary="Radzierblenden vorhanden" />
						</Box>
					)}
				</Box>
				{returnForm.additionalTiresAndRims && (
					<Box sx={{ marginLeft: 4 }}>
						<Typography variant="caption">Mitabgegebene Reifen und Felgen</Typography>
						<Box mt={1} >
							<TextBlock primary={formatTireType(returnForm.suppliedTiresAndRims.tireType)} secondary="Reifenart" />
						</Box >
						<Box mt={1}>
							<TextBlock primary={formatRimType(returnForm.suppliedTiresAndRims.rimType)} secondary="Felgenart" />
						</Box>
					</Box>
				)}
			</Box>
		</>
	);
};

export default VehicleTiresInfo;
