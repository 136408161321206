import { UserRole } from "system/Domain";
import LayoutNavigationItem from "../Components/Layout/LayoutNavigationItem";
import LayoutNavigationSection from "../Components/Layout/LayoutNavigationSection";
import { SettingsBackupRestore, AccountBox, Subject, Receipt, PriceChange } from "@mui/icons-material";
import PostmanIcon from "../Components/Icons/PostmanIcon";

export default () => {
	return (
		<LayoutNavigationSection
			title="Verwaltung"
		>
			<LayoutNavigationItem
				roles={[UserRole.AldAdmin]}
				icon={<SettingsBackupRestore />}
				title="Jobs"
				path="/admin/jobs"
			/>
			<LayoutNavigationItem
				roles={[UserRole.AldUser, UserRole.LotManager, UserRole.LogisticCompany, UserRole.UserAdmin]}
				icon={<AccountBox />}
				title="Benutzer"
				path="/users"
			/>
			<LayoutNavigationItem
				roles={[UserRole.AldAdmin]}
				icon={<Subject />}
				title="Dokumentation"
				path="/admin/documentation/security"
			/>
			<LayoutNavigationItem
				roles={[UserRole.AldUser]}
				icon={<Receipt />}
				title="Kundeneinstellungen"
				path="/customer-settings"
			/>
			<LayoutNavigationItem
				roles={[UserRole.AldManager]}
				icon={<PriceChange />}
				title="Überführungskosten"
				path="/transport-pricing"
			/>
			<LayoutNavigationItem
				roles={[UserRole.AldAdmin]}
				icon={<PostmanIcon />}
				title="Postman"
				path="/admin/documentation/postman"
			/>
		</LayoutNavigationSection>
	);
};
