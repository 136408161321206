import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import NumberFormat from 'react-number-format';
import { TextField, BaseTextFieldProps } from "@mui/material";

interface NumberFormatCustomProps {
	inputRef : (instance : NumberFormat | null) => void;
	onChange : (event : { target : { name : string; value : string } }) => void;
	name : string;
}

const NumberFormatCustom = ({ inputRef, onChange, name, ...other } : NumberFormatCustomProps) => (
	<NumberFormat
		name={name}
		getInputRef={inputRef}
		onValueChange={(values) => {
			onChange({
				target: {
					name: name,
					value: values.value,
				},
			});
		}}
		decimalSeparator=','
		fixedDecimalScale
		decimalScale={2}
		thousandSeparator='.'
		isNumericString
		suffix="€"
		allowNegative={false}
		{...other}
	/>
);

interface CurrencyInputProps extends BaseTextFieldProps {
	value? : number
	setValue? : Dispatch<SetStateAction<number | undefined>> | Dispatch<SetStateAction<number>> | ((value : number) => void)
	onChange? : (e : ChangeEvent<HTMLInputElement>) => void
	align? : "left" | "right"
}

const CurrencyInput = ({ value, setValue, onChange, align = "right", variant, ...rest } : CurrencyInputProps) => {
	const handleChange = (event : ChangeEvent<HTMLInputElement>) => {
		if (setValue) {
			setValue(Number(event.target.value));
		}
	}

	return (
		<TextField
			{...rest}
			inputMode="decimal"
			value={value}
			onChange={onChange || handleChange}
			inputProps={{ style: { textAlign: align } }}
			InputProps={{
				inputComponent: NumberFormatCustom as any,
				style: { textAlign: "right" }
			}}
			variant={variant ?? "standard"}
		/>
	);
};

export default CurrencyInput;
