import List from 'Components/List';
import _ from 'lodash';
import { Dispatch, SetStateAction, useState } from 'react';

import { Add } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';

import ListItem from '../../Components/ListItem';
import ListItemAction from '../../Components/ListItemAction';
import ListItemActions from '../../Components/ListItemActions';
import ListItemContent from '../../Components/ListItemContent';
import Tile from '../../Components/Tiles/Tile';
import { RemarketingStop, RemarketingStopReason, Vehicle } from '../../system/Domain';
import formatNumber from '../../system/formatNumber';
import RemarketingStopListItem from './RemarketingStopListItem';

interface Props {
	vehicle: Vehicle
	setVehicle: Dispatch<SetStateAction<Vehicle | undefined>>
	disabled?: boolean
	excluded?: RemarketingStopReason[]
}

export default (props: Props) => {
	const [newStop, setNewStop] = useState<Partial<RemarketingStop>>();
	const [showAllResolved, setShowAllResolved] = useState(false);

	const newRemarketingSalesStop = () => {
		setNewStop({});
	};

	const cancel = () => {
		setNewStop(undefined);
	};

	const saveNew = (vehicle: Vehicle) => {
		setNewStop(undefined);
		props.setVehicle(vehicle);
	};

	const saveEdit = (vehicle: Vehicle) => {
		props.setVehicle(vehicle);
	};

	let stops = props.vehicle.remarketing.stops || [];

	const maxResolvedStops = 1;
	const resolvedStops = _.orderBy(stops.filter(s => !!s.dateResolved), s => s.dateResolved, 'desc');
	const unresolvedStops = _.orderBy(stops.filter(s => !s.dateResolved), s => s.dateStarted, 'desc');
	const hiddenStopsCount = resolvedStops.length - maxResolvedStops;

	stops = [
		...unresolvedStops,
		...(showAllResolved ? resolvedStops : resolvedStops.slice(0, maxResolvedStops)),
	];

	return (
		<Tile title="Vermarktungsstopps">
			<List>
				{props.disabled && stops.length === 0 && (
					<ListItem transparent>
						<ListItemContent>Keine Vermarktungsstopps vorhanden</ListItemContent>
					</ListItem>
				)}
				{!props.disabled && !newStop && (
					<ListItem transparent>
						<ListItemActions>
							<ListItemAction
								icon={<Add />}
								onClick={newRemarketingSalesStop}
							/>
						</ListItemActions>
						<ListItemContent
							onClick={newRemarketingSalesStop}
						>
							<Typography variant="subtitle2">Vermarktungsstopp hinzufügen</Typography>
						</ListItemContent>
					</ListItem>
				)}
				{!props.disabled && newStop && (
					<RemarketingStopListItem
						stop={newStop}
						new
						cancel={cancel}
						vehicle={props.vehicle}
						save={saveNew}
						excluded={props.excluded}
					/>
				)}
				{stops.map((stop) => (
					<RemarketingStopListItem
						key={stop.id}
						disabled={props.disabled}
						stop={stop}
						vehicle={props.vehicle}
						save={saveEdit}
						excluded={props.excluded}
					/>
				))}
				<Box>
					{!showAllResolved && resolvedStops.length > maxResolvedStops && (
						<Button
							variant="text"
							color="primary" //changed from default
							onClick={() => setShowAllResolved(true)}
						>
							{formatNumber(hiddenStopsCount)} {hiddenStopsCount === 1 ? "weiterer gelöster Stopp" : "weitere gelöste Stopps"} anzeigen
						</Button>
					)}
					{showAllResolved && resolvedStops.length > maxResolvedStops && (
						<Button
							variant="text"
							color="primary" //changed from default
							onClick={() => setShowAllResolved(false)}
						>
							{formatNumber(hiddenStopsCount)} {hiddenStopsCount === 1 ? "bereits gelöster Stopp" : "bereits gelöste Stopps"} ausblenden
						</Button>
					)}
				</Box>
			</List>
		</Tile>
	);
};
