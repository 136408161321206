import { ExpandMore } from "@mui/icons-material";
import { Box, BoxProps, Fab } from "@mui/material";

type FetchNextButtonProps = BoxProps & {
	onNext?: () => void
	hidden?: boolean
	disabled?: boolean
	onAltNext?: () => void
}

export default ({ onNext, onAltNext, hidden, disabled, ...rest }: FetchNextButtonProps) => {
	if (hidden) {
		return null;
	}

	return (
		<Box {...rest} textAlign="center">
			<Fab
				color="primary"
				disabled={disabled}
				onClick={(e) => e.altKey ? onAltNext && onAltNext() : onNext && onNext()}
			>
				<ExpandMore />
			</Fab>
		</Box>
	);
}
