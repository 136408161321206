import { CheckBoxOutlineBlank, CheckBoxOutlined, Launch } from "@mui/icons-material";
import InlineList from "Components/InlineList";
import TextBlock from "../../Components/Layout/TextBlock";
import ListItem from "../../Components/ListItem";
import ListItemAction from "../../Components/ListItemAction";
import ListItemActions from "../../Components/ListItemActions";
import ListItemChip from "../../Components/ListItemChip";
import ListItemChips from "../../Components/ListItemChips";
import ListItemContent from "../../Components/ListItemContent";
import { LeasmanAddress, ThemeColor } from "../../system/Domain";

interface Props {
	address: LeasmanAddress;
	isSelected?: boolean;
	onSelect?: (leasmanAddress?: LeasmanAddress) => void;
	onClick?: () => void;
}

export default ({ address, isSelected = false, onSelect, onClick }: Props) => {
	let color: ThemeColor = "info";
	if (isSelected) {
		color = "primary";
	}

	const handleClick = () => {
		if (!!onClick) {
			onClick();
		} else if (!!onSelect) {
			onSelect(isSelected ? undefined : address);
		}
	};

	return (
		<ListItem color={color}>
			<ListItemActions color={color}>
				{!!onClick && <ListItemAction icon={<Launch />} color="inherit" onClick={handleClick} />}
				{!!onSelect && (
					<ListItemAction
						icon={isSelected ? <CheckBoxOutlined /> : <CheckBoxOutlineBlank />}
						onClick={handleClick}
					/>
				)}
			</ListItemActions>
			<ListItemContent color={color} onClick={handleClick}>
				<TextBlock
					primary={address.fullName}
					secondary={
						<InlineList delimiter=", ">
							{!!address.street && <span>{address.street}</span>}
							{!!address.city && (
								<span>
									{address.postal} {address.city}
								</span>
							)}
							{!!address.country && <span>{address.country.name}</span>}
						</InlineList>
					}
				/>
				<ListItemChips>
					<ListItemChip color="primary" label={address.tenant} />
					<ListItemChip label={`${address.addressId}`} />
				</ListItemChips>
			</ListItemContent>
		</ListItem>
	);
};
