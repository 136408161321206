import { DependencyList, useEffect } from "react";
import useEvents, { EventListenerFunction } from "./useEvents";

export default (event : string, fn : EventListenerFunction, deps : DependencyList = []) => {
	const events = useEvents();

	useEffect(() => {
		events.on(event, fn);
		return () => events.off(event, fn);
	}, deps);
}
