import axios from "axios";
import { CompoundPlace } from "./Domain";
import useGlobalPromise from "./useGlobalPromise";

export default () =>
	useGlobalPromise(
		"ald_compound_places",
		async () =>
			(
				await axios.get<CompoundPlace[]>(`/api/compounds/places`, {
					params: {
						take: 1000,
					},
				})
			).data,
	);
