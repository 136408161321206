import { useState } from 'react'
import Layout from "../../../Components/Layout/Layout";
import axios, { AxiosRequestConfig } from "axios";
import useAxiosEffect from "../../../system/useAxiosEffect";
import DashboardKpi from "../../../Components/Dashboard/DashboardKpi";
import formatNumber from "../../../system/formatNumber";
import VehicleDocumentManagementSidebar from "./VehicleDocumentManagementSidebar";
import { Box, CircularProgress, Grid } from "@mui/material";
import FluentGrid from "../../../Components/FluentGrid";
import Tile from "../../../Components/Tiles/Tile";
import TileContent from "../../../Components/Tiles/TileContent";
import TileContentActions from "../../../Components/Tiles/TileContentActions";
import TileContentAction from "../../../Components/Tiles/TileContentAction";
import { useHistory } from "react-router";
import List from "../../../Components/List";
import { VehicleRegistrationDocumentsProjection } from "../../../system/Domain";
import MissingDocumentsListItem from "./MissingDocumentsListItem";
import useWorkSessions from "../../WorkSessions/useWorkSessions";
import OpenUnregistrationsListItem from "./OpenUnregistrationsListItem";
import parseContentDispositionFilename from "../../../system/parseContentDispositionFilename";
import fileDownload from "js-file-download";

interface VehicleDocumentManagementKpis {
	requestable : number,
	requested : number,
	arrived : number,
	partiallyArrived : number,
	readyHandovers : number,
	unregistrationDateMissing : number,
	notArrived : number,
	dueHandovers : number
}

const VehicleDocumentManagementDashboard = () => {
	const history = useHistory();
	const [createWorkSession] = useWorkSessions();

	const [documentKpis, setDocumentKpis] = useState<VehicleDocumentManagementKpis>();

	const [unfinishedArrivals, setUnfinishedArrivals] = useState<VehicleRegistrationDocumentsProjection[]>([]);
	const [openUnregistrations, setOpenUnregistrations] = useState<VehicleRegistrationDocumentsProjection[]>([]);

	const loadUnfinishedArrivals = async (axiosConfig : AxiosRequestConfig) => {
		const { data } = await axios.get<VehicleRegistrationDocumentsProjection[]>(`/api/vehicles/documents/unfinished`, axiosConfig);

		setUnfinishedArrivals(data);
	};

	const loadOpenUnregistrations = async (axiosConfig : AxiosRequestConfig) => {
		const { data } = await axios.get<VehicleRegistrationDocumentsProjection[]>(`/api/vehicles/documents/open-unregistrations`, axiosConfig);

		setOpenUnregistrations(data);
	};

	const loadKpis = async (axiosConfig : AxiosRequestConfig) => {
		const { data } = await axios.get<VehicleDocumentManagementKpis>(`/api/kpis/vehicles/registration-documents`, axiosConfig);

		setDocumentKpis(data);
	};

	const startHandoverSession = () => createWorkSession({
		perspective: "DocumentHandovers"
	});

	const [isLoadingKpis] = useAxiosEffect(loadKpis, []);
	const [isLoadingUnfinishedArrivals] = useAxiosEffect(loadUnfinishedArrivals, []);
	const [isLoadingOpenUnregistrations] = useAxiosEffect(loadOpenUnregistrations, []);

	const missingDocumentsListItemCallback = (vehicle : VehicleRegistrationDocumentsProjection) => {
		if (vehicle.registrationDocuments.arrivalType === "All") {
			setUnfinishedArrivals(unfinishedArrivals.filter(v => v.id !== vehicle.id));
		} else {
			const index = unfinishedArrivals.findIndex(v => v.id === vehicle.id);

			setUnfinishedArrivals([
				...unfinishedArrivals.slice(0, index),
				vehicle,
				...unfinishedArrivals.slice(index + 1)
			]);
		}

		loadKpis({});
	};

	const openUnregistrationsListItemCallback = (vehicle : VehicleRegistrationDocumentsProjection) => {
		setOpenUnregistrations(openUnregistrations.filter(v => v.id !== vehicle.id));

		loadKpis({});
	};

	const downloadRequests = async () => {
		const response = await axios.get(`/api/vehicles/unregistrations/open`, {
			responseType: "blob"
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);
	};

	const downloadDueHandovers = async () => {
		const response = await axios.get(`/api/vehicles/remarketing/registration-documents/export/due-handovers`, {
			responseType: "blob"
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);
	};

	return (
		<Layout
			title="Fahrzeugpapiere"
			sidebar={<VehicleDocumentManagementSidebar />}
		>
			<Grid container spacing={6}>
				<Grid item xs={12}>
					<FluentGrid itemWidth={300} itemGap={6}>
						<Tile title="Aktuelle Abmeldungen">
							<TileContent dense>
								<Box p={2}>
									{documentKpis && (
										<DashboardKpi
											primary={formatNumber(documentKpis.requestable)}
										/>
									)}
								</Box>
								<TileContentActions align="right">
									<TileContentAction
										onClick={downloadRequests}
										label="Abmeldungen herunterladen"
									/>
								</TileContentActions>
							</TileContent>
						</Tile>
						<Tile title="Abgefragt">
							<TileContent dense>
								<Box p={2}>
									{documentKpis && (
										<DashboardKpi
											primary={formatNumber(documentKpis.requested)}
										/>
									)}
								</Box>
								<TileContentActions align="right">
									<TileContentAction
										onClick={() => history.push("/vehicles/registration-documents/imports/requests")}
										label="Abfragen importieren"
									/>
								</TileContentActions>
							</TileContent>
						</Tile>
						<Tile title="Vollständig angekommen">
							<TileContent dense>
								<Box p={2}>
									{documentKpis && (
										<DashboardKpi
											primary={formatNumber(documentKpis.arrived)}
										/>
									)}
								</Box>
								<TileContentActions align="right">
									<TileContentAction
										onClick={() => history.push("/vehicles/registration-documents/imports/arrivals")}
										label="Ankünfte importieren"
									/>
								</TileContentActions>
							</TileContent>
						</Tile>
						<Tile title="Teilweise angekommen">
							<TileContent dense>
								<Box p={2}>
									{documentKpis && (
										<DashboardKpi
											primary={formatNumber(documentKpis?.partiallyArrived)}
										/>
									)}
								</Box>
								<TileContentActions align="right">
									<TileContentAction
										disabled
										label="&nbsp;"
									/>
								</TileContentActions>
							</TileContent>
						</Tile>
						<Tile title="Nicht angekommen">
							<TileContent dense>
								<Box p={2}>
									{documentKpis && (
										<DashboardKpi
											primary={formatNumber(documentKpis?.notArrived)}
										/>
									)}
								</Box>
								<TileContentActions align="right">
									<TileContentAction
										disabled
										label="&nbsp;"
									/>
								</TileContentActions>
							</TileContent>
						</Tile>
						<Tile title="Ausgaben">
							<TileContent dense>
								<Box p={2}>
									{documentKpis && (
										<DashboardKpi
											primary={formatNumber(documentKpis?.readyHandovers)}
										/>
									)}
								</Box>
								<TileContentActions align="right">
									<TileContentAction
										disabled={isLoadingKpis || true}
										onClick={startHandoverSession}
										label="Ausgabe starten"
									/>
								</TileContentActions>
							</TileContent>
						</Tile>
						<Tile title="Fehlende Abmeldedaten">
							<TileContent dense>
								<Box p={2}>
									{documentKpis && (
										<DashboardKpi
											primary={formatNumber(documentKpis?.unregistrationDateMissing)}
										/>
									)}
								</Box>
								<TileContentActions align="right">
									<TileContentAction
										disabled
										label="Abmeldedaten nachtragen"
									/>
								</TileContentActions>
							</TileContent>
						</Tile>
						<Tile title="Überfällige Ausgaben">
							<TileContent dense>
								<Box p={2}>
									{documentKpis && (
										<DashboardKpi
											primary={formatNumber(documentKpis?.dueHandovers)}
										/>
									)}
								</Box>
								<TileContentActions align="right">
									<TileContentAction
										label="Export"
										onClick={downloadDueHandovers}
									/>
								</TileContentActions>
							</TileContent>
						</Tile>
					</FluentGrid>
				</Grid>
				<Grid item xs={12} md={6}>
					{isLoadingUnfinishedArrivals && (
						<CircularProgress size={48} />
					)}
					{!isLoadingUnfinishedArrivals && (
						<Tile title="Unvollständig angekommenene Dokumente">
							{unfinishedArrivals.length > 0 && (
								<List>
									{unfinishedArrivals.map(v => (
										<MissingDocumentsListItem
											key={v.id}
											vehicle={v}
											completionCallback={missingDocumentsListItemCallback}
										/>
									))}
								</List>
							)}
							{unfinishedArrivals.length === 0 && (
								<>Keine</>
							)}
						</Tile>
					)}
				</Grid>
				<Grid item xs={12} md={6}>
					{isLoadingOpenUnregistrations && (
						<CircularProgress size={48} />
					)}
					{!isLoadingOpenUnregistrations && (
						<Tile title="Fehlende Abmeldedaten">
							{openUnregistrations.length > 0 && (
								<List>
									{openUnregistrations.map(v => (
										<OpenUnregistrationsListItem
											key={v.id}
											vehicle={v}
											completionCallback={openUnregistrationsListItemCallback}
										/>
									))}
								</List>
							)}
							{openUnregistrations.length === 0 && (
								<>Keine</>
							)}
						</Tile>
					)}
				</Grid>
			</Grid>
		</Layout>
	);
};

export default VehicleDocumentManagementDashboard;
