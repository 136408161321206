import List from 'Components/List';
import moment from 'moment';
import { Dispatch, SetStateAction, useState } from 'react';

import { Add } from '@mui/icons-material';
import { Typography } from '@mui/material';

import ListItem from '../../Components/ListItem';
import ListItemAction from '../../Components/ListItemAction';
import ListItemActions from '../../Components/ListItemActions';
import ListItemContent from '../../Components/ListItemContent';
import Tile from '../../Components/Tiles/Tile';
import { Note, Vehicle } from '../../system/Domain';
import RemarketingNoteListItem from './RemarketingNoteListItem';

interface Props {
	vehicle : Vehicle
	setVehicle : Dispatch<SetStateAction<Vehicle | undefined>>
}

const RemarketingNotesTile = (props : Props) => {
	const [newNote, setNewNote] = useState<Partial<Note>>();

	const notes = props.vehicle.remarketing.notes || [];

	const cancel = () => {
		setNewNote(undefined);
	};

	const saveNew = (vehicle : Vehicle) => {
		setNewNote(undefined);
		props.setVehicle(vehicle);
	};

	const addNewNote = () => {
		setNewNote({});
	};

	return (
		<Tile title="Notizen">
			<List>
				{!newNote && (
					<ListItem transparent>
						<ListItemActions>
							<ListItemAction
								icon={<Add />}
								onClick={addNewNote}
							/>
						</ListItemActions>
						<ListItemContent
							onClick={addNewNote}
						>
							<Typography variant="subtitle2">Notiz hinzufügen</Typography>
						</ListItemContent>
					</ListItem>
				)}
				{newNote && (
					<RemarketingNoteListItem
						note={newNote}
						new
						cancel={cancel}
						vehicle={props.vehicle}
						save={saveNew}
					/>
				)}
				{notes.sort((a, b) => moment(b.dateCreated).valueOf() - moment(a.dateCreated).valueOf()).map((note, i) => (
					<RemarketingNoteListItem
						key={i}
						vehicle={props.vehicle}
						note={note}
					/>
				))}
			</List>
		</Tile>
	);
};

export default RemarketingNotesTile;
