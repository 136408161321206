import { useState } from 'react';
import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";
import ThemedDialog from "../../Components/ThemedDialog";
import { Box, Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import CurrencyInput from "../../Components/CurrencyInput";
import { BillingInvoice } from "../../system/Domain";

interface Props extends EditBillingInvoiceProps, ReactConfirmProps<BillingInvoice> {
}

const EditBillingInvoiceDialog = ({ show, proceed, cancel, invoice } : Props) => {
	const [netAmount, setNetAmount] = useState(invoice.netAmount);

	return (
		<ThemedDialog open={show} maxWidth="sm" fullWidth onClose={() => cancel()}>
			<DialogTitle>Rechnung bearbeiten</DialogTitle>
			<DialogContent>
				<Box mb={2}>
					<CurrencyInput
						fullWidth
						value={netAmount}
						setValue={setNetAmount}
						variant="standard"
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="secondary"
					href=""
					onClick={() => cancel()}
				>
					Abbrechen
				</Button>
				<Button
					variant="outlined"
					color="primary"
					href=""
					onClick={() => {
						invoice.netAmount = netAmount;
						proceed(invoice);
					}}
				>
					Ok
				</Button>
			</DialogActions>
		</ThemedDialog>
	);
};

export interface EditBillingInvoiceProps {
	invoice : BillingInvoice
}

export default (options : EditBillingInvoiceProps) => {
	const dialog = confirmable(props => <EditBillingInvoiceDialog {...props} {...options} />);
	return createConfirmation<number>(dialog)();
}
