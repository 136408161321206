import ServicesNavigation from "Navigation/ServicesNavigation";
import Layout from "../../Components/Layout/Layout";
import Imports from "../Imports/Imports";

export default () => (
	<Layout
		title="Importe"
		navigation={<ServicesNavigation />}
	>
		<Imports importType="VehicleServiceImport" />
	</Layout>
)
