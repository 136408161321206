import moment from "moment";
import { LogEvent } from "system/Domain";
import { Box, colors, darken, useTheme } from "@mui/material";

interface LogEntryProps {
	index: number;
	item: LogEvent;
}

const formatLevel = (level: string) => {
	switch (level) {
		case "Verbose":
			return "VRB";
		case "Debug":
			return "DBG";
		case "Information":
			return "INF";
		case "Warning":
			return "WRN";
		case "Error":
			return "ERR";
		case "Fatal":
			return "FTL";
		default:
			return level;
	}
};

export default ({ item }: LogEntryProps) => {
	const theme = useTheme();
	const styles = {
		root: {
			color: darken(theme.palette.text.primary, 0.1),
		},
		level: {
			fontWeight: "bold",
		},
		darkerText: {
			// color: darken(theme.palette.text.primary, 0.3)
			opacity: 0.8,
		},
		darkText: {
			// color: darken(theme.palette.text.primary, 0.5)
			// opacity: 0.5,
		},
		debug: {
			color: darken(theme.palette.text.primary, 0.3),
		},
		information: {
			color: theme.palette.text.primary,
		},
		warning: {
			color: colors.amber["A700"],
		},
		error: {
			color: colors.red["A700"],
		},
		fatal: {
			color: colors.red["A700"],
			fontWeight: "bold",
		},
	};

	return (
		<Box
			sx={[
				styles.root,
				item.level === "Debug" && styles.debug,
				item.level === "Information" && styles.information,
				item.level === "Warning" && styles.warning,
				item.level === "Error" && styles.error,
				item.level === "Fatal" && styles.fatal,
			]}
		>
			<span>[</span>
			<Box component={"span"} sx={styles.darkText}>
				{moment(item.date).format("HH:mm:ss")}{" "}
			</Box>
			<Box component={"span"} sx={styles.level}>
				{formatLevel(item.level)}
			</Box>
			<span>] </span>
			<span>{item.message}</span>
			{!!item.exception && <Box m={1}>{item.exception}</Box>}
		</Box>
	);
};
