import FormChoices from "./FormChoices";

export default (props : any) => (
	<FormChoices
		{...props}
		choices={{
			true: "In Ordnung",
			false: "Nicht in Ordnung"
		}}
	/>
);
