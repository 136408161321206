import { cloneElement, PropsWithChildren, ReactElement } from "react";
import { alpha, Box, CircularProgress, colors, useTheme } from "@mui/material";

interface Props {
	isBusy?: boolean
	icon?: ReactElement | null
	align?: "left" | "right" | "space-between"
}

export default ({ isBusy = false, icon = null, align = "left", children }: PropsWithChildren<Props>) => {
	const theme = useTheme();

	if (isBusy) {
		icon = <CircularProgress size="0.9rem" color="inherit" />;
	}

	if (!!icon) {
		icon = cloneElement(icon, {
			sx: {
				fontSize: "1rem",
				opacity: 0.7,
				color: alpha(colors.grey[500], 0.5),
				marginRight: theme.spacing(1)
			}
		});
	}

	return (
		<Box
			sx={align === "right" && { justifyContent: "flex-end" }}
			p={1}
			mt={1}
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			bgcolor="rgba(100, 181, 246, 0.1)"
		>
			{align === "space-between" && icon}
			<Box sx={{
				gap: theme.spacing(1),
				display: "flex"
			}}>
				{children}
			</Box>
			{align !== "space-between" && icon}
		</Box>
	);
};
