import axios from "axios";
import fileDownload from "js-file-download";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";

import {
	Add,
	Backspace,
	Cancel,
	Check,
	Clear,
	Delete,
	Edit,
	KeyboardArrowDown,
	KeyboardArrowUp,
	PictureAsPdf,
	RotateLeft,
	ShoppingCart,
	Stop,
	ThumbUp,
	Undo,
} from "@mui/icons-material";
import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Slider, useTheme } from "@mui/material";

import FormText from "../../Components/FormText";
import InlineList from "../../Components/InlineList";
import TextBlock from "../../Components/Layout/TextBlock";
import ListItem from "../../Components/ListItem";
import ListItemAction from "../../Components/ListItemAction";
import ListItemActions from "../../Components/ListItemActions";
import ListItemChip from "../../Components/ListItemChip";
import ListItemChips from "../../Components/ListItemChips";
import ListItemContent from "../../Components/ListItemContent";
import TextLineAction from "../../Components/TextLineAction";
import dateFormats from "../../system/dateFormats";
import {
	Sale,
	SaleBid,
	SaleBidWithdrawal,
	SaleExternalOffer,
	SaleOffer,
	SaleOfferLeasing,
	SalePricing,
	ThemeColor,
	Vehicle,
} from "../../system/Domain";
import formatCurrency from "../../system/formatCurrency";
import formatDateTime from "../../system/formatDateTime";
import formatNumber from "../../system/formatNumber";
import formatPercentage from "../../system/formatPercentage";
import formatRelativeDate from "../../system/formatRelativeDate";
import parseContentDispositionFilename from "../../system/parseContentDispositionFilename";
import useForm from "../../system/useForm";
import useRunAction from "../../system/useRunAction";
import useUser from "../../system/useUser";
import { SalePropsWithSetter } from "./SaleLayout";
import { formatSaleOfferStatus, paintSaleBidStatus, paintSaleOfferStatus } from "./SaleOfferStatus";
import TextGroup from "./TextGroup";
import TextLine from "./TextLine";
import useSalePermissions from "./useSalePermissions";

interface Props extends SalePropsWithSetter {
	offer: SaleOffer;
	vehicle: Vehicle;
}

type Mode =
	| "view"
	| "pricing-history"
	| "status-history"
	| "bid-history"
	| "external-offer-history"
	| "pricing"
	| "sell"
	| "unreserve"
	| "bid"
	| "withdraw-bid"
	| "stop"
	| "reactivate"
	| "delete"
	| "archive"
	| "confirm-offer"
	| "retract-offer-confirmation";

export const downloadFile = async (hash?: string) => {
	if (!hash) {
		return;
	}

	const response = await axios.get(`/api/storage/${hash}`, {
		responseType: "blob",
	});

	const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
	fileDownload(response.data, filename);
};

const clonePartialPricing = (pricing: SalePricing | undefined): Partial<SalePricing> => ({
	minimumPrice: pricing?.minimumPrice,
	targetPrice: pricing?.targetPrice,
	comment: pricing?.comment,
});

const clonePartialLeasing = (leasing?: SaleOfferLeasing | null): Partial<SaleOfferLeasing> => ({
	active: leasing?.active || false,
});

export default ({ sale, setSale, offer, vehicle }: Props) => {
	const theme = useTheme();
	const [user, ,] = useUser();

	const [actionRunning, runAction] = useRunAction();
	const [showFullBidHistory, setShowFullBidHistory] = useState(false);
	const [mode, setMode] = useState<Mode>("view");

	const [pricing, setPricing] = useState<Partial<SalePricing>>(clonePartialPricing(offer.pricing));
	const formPricing = useForm({
		values: pricing,
		setValues: setPricing,
	});

	const [leasing, setLeasing] = useState<Partial<SaleOfferLeasing>>(clonePartialLeasing(offer.leasing));

	const [newBid, setNewBid] = useState<Partial<SaleBid>>({});
	const [selectedBid, setSelectedBid] = useState<SaleBid>();
	const [bidWithdrawal, setBidWithdrawal] = useState<Partial<SaleBidWithdrawal>>({});
	const formBidWithdrawal = useForm({
		values: bidWithdrawal,
		setValues: setBidWithdrawal,
	});

	const order = sale.orders.find((o) => o.offerId === offer.id && o.status !== "Cancelled");

	useEffect(() => {
		setPricing(clonePartialPricing(offer.pricing));
	}, [offer.pricing?.minimumPrice, offer.pricing?.targetPrice, offer.pricing?.comment]);

	useEffect(() => {
		setLeasing(clonePartialLeasing(offer.leasing));
	}, [offer.leasing?.active]);

	useEffect(() => {
		if (!pricing) {
			return;
		}

		if (useAutomatedMinimumPrice) {
			if (!pricing.targetPrice) {
				setPricing((p) => ({
					...p,
					minimumPrice: undefined,
				}));

				return;
			} else {
				setPricing((p) => ({
					...p,
					minimumPrice: pricing.targetPrice! > 200 ? pricing.targetPrice! - 200 : 0,
				}));

				return;
			}
		}
	}, [pricing?.targetPrice]);

	useEffect(() => {
		if (offer.status === "Pricing" && mode !== "pricing" && mode !== "archive" && mode !== "delete") {
			setMode("pricing");
			return;
		}
	}, [offer.status, mode]);

	const pricingChanged =
		pricing.minimumPrice !== offer.pricing?.minimumPrice ||
		pricing.targetPrice !== offer.pricing?.targetPrice ||
		pricing.comment !== offer.pricing?.comment;

	const leasingChanged =
		leasing.active !== offer.leasing?.active && (offer.leasing?.manualOverride === true || leasing.active);

	const resetPricing = () => {
		setPricing(clonePartialPricing(offer.pricing));
	};

	const publishOffer = () =>
		runAction(async () => {
			if (pricingChanged) {
				const { data: updatedSale } = await axios.put<Sale>(
					`/api/sales/${sale.id}/offers/${offer.id}/pricing`,
					pricing,
				);
				setSale(updatedSale);
			}

			if (leasingChanged) {
				const { data: updatedSale } = await axios.put<Sale>(
					`/api/sales/${sale.id}/offers/${offer.id}/leasing`,
					leasing,
				);
				setSale(updatedSale);
			}

			const { data: updatedSale } = await axios.post<Sale>(`/api/sales/${sale.id}/offers/${offer.id}/publish`);
			setSale(updatedSale);
			setMode("view");
		});

	const stopOffer = () =>
		runAction(async () => {
			const { data: updatedSale } = await axios.post<Sale>(`/api/sales/${sale.id}/offers/${offer.id}/stop`);
			setSale(updatedSale);
			setMode("view");
		});

	const reactivateOffer = () =>
		runAction(async () => {
			const { data: updatedSale } = await axios.post<Sale>(`/api/sales/${sale.id}/offers/${offer.id}/reactivate`);
			setSale(updatedSale);
			setMode("view");
		});

	const deleteOffer = () =>
		runAction(async () => {
			const { data: updatedSale } = await axios.delete<Sale>(`/api/sales/${sale.id}/offers/${offer.id}`);
			setSale(updatedSale);
		});

	const archiveOffer = () =>
		runAction(async () => {
			const { data: updatedSale } = await axios.post<Sale>(`/api/sales/${sale.id}/offers/${offer.id}/archive`);
			setSale(updatedSale);
			setMode("view");
		});

	const reserveOffer = () =>
		runAction(async () => {
			const { data: updatedSale } = await axios.post<Sale>(`/api/sales/${sale.id}/offers/${offer.id}/reserve`);
			setSale(updatedSale);
		});

	const unreserveOffer = () =>
		runAction(async () => {
			const { data: updatedSale } = await axios.post<Sale>(`/api/sales/${sale.id}/offers/${offer.id}/unreserve`);
			setSale(updatedSale);
			setMode("view");
		});

	const createBid = (bid: Partial<SaleBid>) =>
		runAction(async () => {
			if (!bid.price || !bid.name) {
				return;
			}

			const { data: updatedSale } = await axios.post<Sale>(`/api/sales/${sale.id}/offers/${offer.id}/bids`, {
				price: bid.price,
				name: bid.name,
			});

			setSale(updatedSale);
			setNewBid({});
			setMode("view");
		});

	const withdrawBid = () =>
		runAction(async () => {
			if (!selectedBid || !bidWithdrawal?.comment) {
				return;
			}

			const { data } = await axios.post<Sale>(
				`/api/sales/${sale.id}/offers/${offer.id}/bids/${selectedBid.id}/withdraw`,
				bidWithdrawal,
			);
			setSale(data);

			setSelectedBid(undefined);
			setBidWithdrawal({});
			setMode("bid-history");
		});

	const formBid = useForm({
		values: newBid,
		setValues: setNewBid,
	});

	const confirmCarmarketOffer = () =>
		runAction(async () => {
			const { data: updatedSale } = await axios.post<Sale>(`/api/sales/${sale.id}/offers/${offer.id}/confirm`);
			setSale(updatedSale);
			setMode("view");
		});

	const retractCarmarketOffer = () =>
		runAction(async () => {
			const { data: updatedSale } = await axios.post<Sale>(
				`/api/sales/${sale.id}/offers/${offer.id}/retract-confirmation`,
			);
			setSale(updatedSale);
			setMode("view");
		});

	const isReserved = sale.status === "Reserved";

	const history = useHistory();

	const createOrder = () => {
		history.push(`/sales/${sale.id}/order`);
	};

	const createManualOrder = () => {
		history.push(`/sales/${sale.id}/manual-sales`);
	};

	const createDirectSale = () => {
		history.push(`/sales/${sale.id}/direct-sales`);
	};

	let color: ThemeColor = "info";
	switch (mode) {
		case "view":
			color = "info";
			break;
		case "pricing":
		case "reactivate":
		case "unreserve":
		case "bid":
		case "sell":
			color = "primary";
			break;
		case "stop":
		case "archive":
			color = "warning";
			break;
		case "delete":
			color = "error";
			break;
	}

	if (offer.status === "Reserved" || offer.status === "Sold") {
		color = "success";
	}
	if (offer.status === "Reserved") {
		color = "primary";
	}

	const getPricingMarks = (): any[] => {
		const marks: any[] = [];

		if (sale.market1Price) {
			marks.push({ label: "HEK", value: sale.market1Price });
		}

		if (sale.ekzgv && sale.ekzgv > 1) {
			marks.push({ label: "EKZGV", value: sale.ekzgv });
		}

		return marks;
	};

	const isSaleActive =
		(sale.status === "ReadyToAllocate" || sale.status === "ReadyToSell" || sale.status === "Reserved") &&
		!vehicle.remarketing.isStopped;
	const isSaleReserved = sale.status === "Reserved";

	let externalOffers: SaleExternalOffer[] = [];
	if (offer.externalOffer) {
		externalOffers.push(offer.externalOffer);
	}

	if (offer.externalOfferHistory?.length > 0) {
		externalOffers = _.orderBy(
			[...externalOffers, ...offer.externalOfferHistory.map((h) => h.value)],
			(e) => e.startDate,
			"desc",
		);
	}

	const permissions = useSalePermissions();

	if (!permissions.canView) {
		return null;
	}

	const isResalePlattformSalesChannel = offer.channel.id === "DE - BCA" || offer.channel.id === "DE - Autorola";

	const canConfirmOffer =
		permissions.canConfirmOffers &&
		(offer.status === "Active" ||
			offer.status === "Reserved" ||
			offer.status === "Publishing" ||
			offer.status === "PublishingFailed") &&
		(offer.channel.isCarmarket || isResalePlattformSalesChannel) &&
		offer.externalOffer?.status === "Closed";

	const useAutomatedMinimumPrice = offer.channel.hasAutomatedMinimumPrice;

	return (
		<ListItem color={color} transparent={offer.status === "Archived"}>
			<ListItemActions>
				{mode === "view" && (
					<>
						{canConfirmOffer &&
							(offer.externalOffer?.firstConfirmation?.user.id === user?.id ||
								offer.externalOffer?.secondConfirmation?.user.id === user?.id) && (
								<ListItemAction
									icon={<Backspace />}
									onClick={() => setMode("retract-offer-confirmation")}
								/>
							)}
						{canConfirmOffer &&
							(!offer.externalOffer?.firstConfirmation || !offer.externalOffer?.secondConfirmation) && (
								<ListItemAction icon={<ThumbUp />} onClick={() => setMode("confirm-offer")} />
							)}
						{offer.canSell && !order && (
							<ListItemAction
								icon={<ShoppingCart />}
								disabled={!isSaleActive || actionRunning}
								onClick={() => setMode("sell")}
							/>
						)}
						{permissions.canPrice && !isReserved && offer.canSetPricing && (
							<ListItemAction
								icon={<Edit />}
								disabled={!isSaleActive || actionRunning}
								onClick={() => setMode("pricing")}
							/>
						)}
						{permissions.canManageOffers && offer.canStop && offer.status !== "Pricing" && (
							<ListItemAction
								icon={<Stop />}
								disabled={!isSaleActive || actionRunning}
								onClick={() => setMode("stop")}
							/>
						)}
						{permissions.canManageOffers && offer.canReactivate && (
							<ListItemAction
								icon={<Add />}
								disabled={
									!isSaleActive || actionRunning || (isSaleReserved && offer.status !== "Reserved")
								}
								onClick={() => setMode("reactivate")}
							/>
						)}
						{permissions.canReserve && offer.status === "Reserved" && (
							<ListItemAction
								icon={<Undo />}
								disabled={!isSaleActive || actionRunning}
								onClick={() => setMode("unreserve")}
							/>
						)}
					</>
				)}
				{permissions.canPrice && mode === "pricing" && (
					<>
						<ListItemAction
							icon={<Check />}
							disabled={
								!isSaleActive ||
								!offer.canSetPricing ||
								actionRunning ||
								!pricing.targetPrice ||
								!pricing.minimumPrice ||
								pricing.targetPrice <= pricing.minimumPrice
							}
							onClick={publishOffer}
						/>
						<ListItemAction
							icon={<RotateLeft />}
							disabled={!isSaleActive || !offer.canSetPricing || !pricingChanged || actionRunning}
							onClick={resetPricing}
						/>
					</>
				)}
				{permissions.canBid && mode === "bid" && (
					<ListItemAction
						icon={<Check />}
						disabled={!isSaleActive || actionRunning || !newBid.price || !newBid.name}
						onClick={() => createBid(newBid)}
					/>
				)}
				{permissions.canWithdrawBid && mode === "withdraw-bid" && (
					<ListItemAction
						icon={<Check />}
						disabled={actionRunning || !bidWithdrawal?.comment || !selectedBid}
						onClick={withdrawBid}
					/>
				)}
				{permissions.canManageOffers &&
					(mode === "view" || (mode === "pricing" && offer.status === "Pricing")) && (
						<>
							{offer.canDelete && (
								<ListItemAction
									icon={<Delete />}
									disabled={!isSaleActive || actionRunning}
									onClick={() => setMode("delete")}
								/>
							)}
							{!offer.canDelete && offer.canArchive && (
								<ListItemAction
									icon={<Delete />}
									disabled={!isSaleActive || actionRunning}
									onClick={() => setMode("archive")}
								/>
							)}
						</>
					)}
				{mode === "bid-history" && (
					<ListItemAction
						icon={showFullBidHistory ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
						onClick={() => setShowFullBidHistory((v) => !v)}
					/>
				)}
				{mode !== "view" && !(mode === "pricing" && offer.status === "Pricing") && (
					<ListItemAction icon={<Clear />} onClick={() => setMode("view")} />
				)}
			</ListItemActions>
			<ListItemContent>
				<TextBlock
					flat
					primary={offer.channel.name}
					secondary={`Erstellt ${formatRelativeDate(offer.dateCreated)}`}
				/>
				{mode === "view" && (
					<Box mt={1}>
						{offer.externalOffer?.status === "Closed" && (
							<Box mt={3} mb={1}>
								<TextGroup title={offer.externalOffer.name}>
									<TextLine
										label="aktuelles Höchstgebot"
										value={formatCurrency(offer.externalOffer.bestOfferAmount)}
										color="primary"
									/>
									<TextLine
										label="Auktionszeitraum"
										value={`${moment(offer.externalOffer.startDate).format(dateFormats.dateTime)} - ${moment(offer.externalOffer.plannedEndDate ?? offer.externalOffer.effectiveEndDate).format(dateFormats.dateTime)}`}
									/>
									<TextLine
										label="Höchstgebot - EKZGV"
										value={
											offer.externalOffer.bestOfferAmount && sale.ekzgv
												? formatCurrency(
														(offer.externalOffer.bestOfferAmount * 100 - sale.ekzgv * 100) /
															100,
													)
												: "-"
										}
									/>
									<TextLine
										label="Höchstgebot / UPE"
										value={
											offer.externalOffer.bestOfferAmount &&
											vehicle.type?.costs.totalPrice.grossAmount
												? formatPercentage(
														(offer.externalOffer.bestOfferAmount * 100) /
															(vehicle.type.costs.totalPrice.grossAmount * 100),
													)
												: "-"
										}
									/>
									<TextLine
										label="Höchstgebot / Händler EKP"
										value={
											sale.market1Price && offer.externalOffer.bestOfferAmount
												? formatPercentage(
														(offer.externalOffer.bestOfferAmount * 100) /
															(sale.market1Price * 100),
													)
												: "-"
										}
									/>
									<TextLine
										label="Abweichung Maximalgebot"
										value={
											offer.externalOffer.bestOfferAmount && offer.highestBid?.price
												? formatCurrency(
														(offer.externalOffer.bestOfferAmount * 100 -
															offer.highestBid.price * 100) /
															100,
													)
												: "-"
										}
									/>

									<Box mt={2}>
										<TextLine
											label="Zuschlagserlaubnis"
											value={
												<>
													{offer.externalOffer.firstConfirmation && (
														<>
															{offer.externalOffer.firstConfirmation.user.name}
															<br />
															{moment(
																offer.externalOffer.firstConfirmation.dateConfirmed,
															).format(dateFormats.dateTime)}
														</>
													)}
													{!offer.externalOffer.firstConfirmation && "-"}
												</>
											}
										/>
									</Box>
									<Box mt={2}>
										<TextLine
											label="Zuschlagserlaubnis"
											value={
												<>
													{offer.externalOffer.secondConfirmation && (
														<>
															{offer.externalOffer.secondConfirmation.user.name}
															<br />
															{moment(
																offer.externalOffer.secondConfirmation.dateConfirmed,
															).format(dateFormats.dateTime)}
														</>
													)}
													{!offer.externalOffer.secondConfirmation && "-"}
												</>
											}
										/>
									</Box>
								</TextGroup>
							</Box>
						)}
					</Box>
				)}
				{mode === "status-history" && (
					<Box mt={3} mb={1}>
						<TextGroup title="Statushistorie">
							{[...offer.statusHistory].reverse().map((h) => (
								<>
									<TextLine
										label={formatDateTime(h.date)}
										value={formatSaleOfferStatus(h.value)}
										color={paintSaleOfferStatus(h.value)}
									/>
									{h.createdBy && <TextLine label="" value={h.createdBy.name} />}
								</>
							))}
						</TextGroup>
					</Box>
				)}
				{mode === "pricing-history" && (
					<Box mt={3} mb={1}>
						<TextGroup title="Preishistorie" gap="wide">
							{[...offer.pricingHistory].reverse().map((h) => (
								<TextLine
									label={formatDateTime(h.date)}
									value={
										<Box>
											<Box>
												{formatCurrency(h.value.minimumPrice)} -{" "}
												{formatCurrency(h.value.targetPrice)}
											</Box>
											{h.value.pricedBy && (
												<Box>
													<InlineList delimiter=", ">
														<span>{h.value.pricedBy.name}</span>
														{h.value.approvedBy && <span>{h.value.approvedBy.name}</span>}
													</InlineList>
												</Box>
											)}
											{h.value.comment && <Box>{h.value.comment}</Box>}
										</Box>
									}
								/>
							))}
						</TextGroup>
					</Box>
				)}
				{mode === "external-offer-history" && (
					<Box mt={3} mb={1}>
						<TextGroup title="Auktionshistorie" gap="wide">
							{externalOffers.map((e, i) => {
								const bid = offer.bids.find(
									(b) =>
										b.externalBidderId === e.bestOfferTrader?.id && b.price === e.bestOfferAmount,
								);
								const isStopped = e.status === "Stopped";
								return (
									<TextLine
										key={i}
										label={formatDateTime(e.startDate)}
										color={isStopped ? "grey" : bid?.status === "Reserved" ? "primary" : "info"}
										value={
											<Box>
												<Box>
													{e.name} {isStopped ? "(Widerrufen)" : undefined}
												</Box>
												{e.firstConfirmation && e.secondConfirmation && (
													<Box>
														<InlineList delimiter=", ">
															<span>{e.firstConfirmation.user.name}</span>
															<span>{e.secondConfirmation.user.name}</span>
														</InlineList>
													</Box>
												)}
												<Box>
													<InlineList>
														{formatCurrency(e.bestOfferAmount)}
														{bid && bid.name}
													</InlineList>
												</Box>
											</Box>
										}
									/>
								);
							})}
						</TextGroup>
					</Box>
				)}
				{mode === "bid-history" && (
					<Box mt={3} mb={1}>
						<TextGroup title="Gebotshistorie" gap="wide">
							{offer.bids
								.filter((f) => showFullBidHistory || f.isBestBid || f.isManualBid)
								.map((b) => (
									<TextLine
										label={formatDateTime(b.date)}
										color={paintSaleBidStatus(b.status)}
										value={
											<Box>
												<Box>{formatCurrency(b.price)}</Box>
												<Box>{b.name}</Box>
												{b.createdBy && <Box>Eingestellt durch {b.createdBy.name}</Box>}
											</Box>
										}
										actions={
											!b.withdrawal &&
											b.status !== "Reserved" &&
											permissions.canWithdrawBid && (
												<TextLineAction
													icon={<Cancel />}
													onClick={() => {
														setSelectedBid(b);
														setMode("withdraw-bid");
													}}
												/>
											)
										}
										info={
											b.withdrawal && (
												<Box mb={2} fontStyle="italic" color={theme.palette.primary.light}>
													<InlineList>
														{formatDateTime(b.withdrawal.date)}
														{b.withdrawal.approvedBy.name}
													</InlineList>
													<Box>{b.withdrawal.comment}</Box>
												</Box>
											)
										}
										invalid={b.status === "Withdrawn"}
									/>
								))}
						</TextGroup>
					</Box>
				)}
				{permissions.canWithdrawBid && mode === "withdraw-bid" && selectedBid && (
					<Box mt={3} mb={2}>
						<TextBlock
							reduced
							primary="Gebot zurückziehen"
							secondary={
								<InlineList>
									{formatDateTime(selectedBid.date)}
									{selectedBid.name}
								</InlineList>
							}
							tertiary={formatCurrency(selectedBid.price)}
						/>
						<Box mt={3}>
							<Grid container spacing={6}>
								<FormText fullWidth name="comment" label="Kommentar" form={formBidWithdrawal} />
							</Grid>
						</Box>
					</Box>
				)}
				{permissions.canBid && mode === "bid" && (
					<Box mt={3} mb={3}>
						<Grid container spacing={3}>
							<FormText
								name="price"
								label="Preis"
								form={formBid}
								type="currency"
								inline
								options={{
									required: true,
								}}
								fullWidth
							/>
							<FormText
								name="name"
								label="Name"
								form={formBid}
								type="text"
								inline
								options={{
									required: true,
								}}
								fullWidth
							/>
						</Grid>
					</Box>
				)}
				{permissions.canPrice && mode === "pricing" && (
					<Box mt={3}>
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<Grid container>
									<FormText
										label="Startpreis"
										name="minimumPrice"
										form={formPricing}
										type="currency"
										readOnly={!offer.canSetPricing || useAutomatedMinimumPrice}
										fullWidth
										inline
									/>
								</Grid>
							</Grid>
							<Grid item xs={6}>
								<Grid container>
									<FormText
										label="Mindestpreis / Zielpreis"
										name="targetPrice"
										form={formPricing}
										type="currency"
										readOnly={!offer.canSetPricing}
										fullWidth
										inline
									/>
								</Grid>
							</Grid>
						</Grid>

						<Box mt={2}>
							{useAutomatedMinimumPrice && (
								<Slider
									min={1000}
									max={
										sale.vehicle.type?.costs?.totalPrice?.grossAmount ||
										sale.market2Price ||
										Math.floor(((offer.pricing?.targetPrice || 0) + 15000) / 10000) * 10000
									}
									step={100}
									value={pricing.targetPrice || 0}
									marks={getPricingMarks()}
									onChange={(e, v) =>
										setPricing((p) => ({
											...p,
											targetPrice: v as number,
										}))
									}
									// valueLabelFormat={v => formatCurrency(v)}
									disabled={!offer.canSetPricing}
								/>
							)}
							{!useAutomatedMinimumPrice && (
								<Slider
									min={1000}
									max={
										sale.vehicle.type?.costs?.totalPrice?.grossAmount ||
										sale.market2Price ||
										Math.floor(((offer.pricing?.targetPrice || 0) + 15000) / 10000) * 10000
									}
									step={100}
									value={[pricing.minimumPrice || 0, pricing.targetPrice || 0]}
									marks={getPricingMarks()}
									onChange={(e, v) => {
										const range = v as number[];

										setPricing((p) => ({
											...p,
											minimumPrice: range[0],
											targetPrice: range[1],
										}));
									}}
									// valueLabelFormat={v => formatCurrency(v)}
									disabled={!offer.canSetPricing}
								/>
							)}
						</Box>

						{offer.channel.supportsLeasing && (
							<Box my={2}>
								<FormControl fullWidth>
									<FormControlLabel
										control={
											<Checkbox
												disabled={!offer.canSetPricing}
												checked={leasing.active}
												onChange={(event, checked) => setLeasing({ active: checked })}
											/>
										}
										label="Gebrauchtwagen Leasing"
									/>
								</FormControl>
							</Box>
						)}
					</Box>
				)}
				{mode === "sell" && (
					<Box mt={3} mb={1} color={theme.palette.primary.light}>
						{permissions.canReserve && (
							<Box>
								<Button
									color="inherit"
									variant="text"
									disabled={offer.status === "Reserved"}
									onClick={reserveOffer}
									startIcon={<Check />}
								>
									Fahrzeug reservieren
								</Button>
							</Box>
						)}
						{permissions.canBid && !order && offer.status === "Active" && (
							<Box mt={1}>
								<Button
									color="primary"
									variant="text"
									onClick={() => setMode("bid")}
									startIcon={<Add />}
								>
									Manuelles Gebot aufgeben
								</Button>
							</Box>
						)}
						{permissions.canManageDirectSaleOrders &&
							!order &&
							sale.vehicle.businessLine !== "Sonderflotte" &&
							(offer.channel.isCarmarket || isResalePlattformSalesChannel) && (
								<Box mt={1}>
									<Button
										color="inherit"
										variant="text"
										disabled={offer.status !== "Reserved"}
										onClick={createDirectSale}
										startIcon={<Add />}
									>
										Neuer Direktverkauf
									</Button>
								</Box>
							)}
						{permissions.canManageOrders &&
							!order &&
							sale.vehicle.businessLine !== "Sonderflotte" &&
							!(offer.channel.isCarmarket || isResalePlattformSalesChannel) && (
								<Box mt={1}>
									<Button
										color="inherit"
										variant="text"
										disabled={offer.status !== "Reserved"}
										onClick={createOrder}
										startIcon={<Add />}
									>
										Neue Bestellung
									</Button>
								</Box>
							)}
						{permissions.canManageOrders &&
							!order &&
							(
								sale.vehicle.businessLine === "Sonderflotte"
								||
								!(offer.channel.isCarmarket || isResalePlattformSalesChannel)
							) && (
								<Box mt={1}>
									<Button
										color="inherit"
										variant="text"
										disabled={offer.status !== "Reserved"}
										onClick={createManualOrder}
										startIcon={<Add />}
									>
										Neue manuelle Bestellung
									</Button>
								</Box>
							)}
					</Box>
				)}
				{permissions.canConfirmOffers && mode === "confirm-offer" && (
					<Box mt={3} mb={1} color={theme.palette.primary.light}>
						<Button color="inherit" variant="text" onClick={confirmCarmarketOffer} startIcon={<Check />}>
							Zuschlagserlaubnis bestätigen
						</Button>
					</Box>
				)}
				{permissions.canConfirmOffers && mode === "retract-offer-confirmation" && (
					<Box mt={3} mb={1} color={theme.palette.error.light}>
						<Button color="inherit" variant="text" onClick={retractCarmarketOffer} startIcon={<Delete />}>
							Zuschlagserlaubnis zurückziehen
						</Button>
					</Box>
				)}
				{permissions.canManageOffers && mode === "stop" && (
					<Box mt={3} mb={1} color={theme.palette.warning.light}>
						<Button color="inherit" variant="text" onClick={stopOffer} startIcon={<Stop />}>
							Vertriebskanal stoppen
						</Button>
					</Box>
				)}
				{permissions.canManageOffers && mode === "reactivate" && (
					<Box mt={3} mb={1} color={theme.palette.primary.light}>
						<Button color="inherit" variant="text" onClick={reactivateOffer} startIcon={<Add />}>
							Vertriebskanal reaktivieren
						</Button>
					</Box>
				)}
				{permissions.canReserve && mode === "unreserve" && (
					<Box mt={3} mb={1} color={theme.palette.primary.light}>
						<Button color="inherit" variant="text" onClick={unreserveOffer} startIcon={<Undo />}>
							Reservierung aufheben
						</Button>
					</Box>
				)}
				{permissions.canManageOffers && mode === "delete" && (
					<Box mt={3} mb={1} color={theme.palette.error.light}>
						<Button color="inherit" variant="text" onClick={deleteOffer} startIcon={<Delete />}>
							Vertriebskanal entfernen
						</Button>
					</Box>
				)}
				{permissions.canManageOffers && mode === "archive" && (
					<Box mt={3} mb={1} color={theme.palette.warning.light}>
						<Button color="inherit" variant="text" onClick={archiveOffer} startIcon={<Delete />}>
							Vertriebskanal archivieren
						</Button>
					</Box>
				)}
				<ListItemChips>
					<ListItemChip
						label={formatSaleOfferStatus(offer.status)}
						color={paintSaleOfferStatus(offer.status)}
						active={mode === "status-history"}
						onClick={() => setMode((m) => (m === "status-history" ? "view" : "status-history"))}
					/>
					{offer.pricing && (
						<ListItemChip
							// label={offer.pricing.minimumPrice < offer.pricing.targetPrice
							// 	? `${formatCurrency(offer.pricing.minimumPrice)} - ${formatCurrency(offer.pricing.targetPrice)}`
							// 	: formatCurrency(offer.pricing.targetPrice)
							// }
							label={formatCurrency(offer.pricing.targetPrice)}
							// color={mode === "history" ? "success" : "info"}
							color="info"
							active={mode === "pricing-history"}
							onClick={() => setMode((m) => (m === "pricing-history" ? "view" : "pricing-history"))}
						/>
					)}
					{externalOffers.length > 0 && (
						<ListItemChip
							label={formatNumber(externalOffers.length, "-", "Auktionen", "Auktion")}
							color={offer.externalOffer.status === "Active" ? "primary" : "info"}
							active={mode === "external-offer-history"}
							onClick={() =>
								setMode((m) => (m === "external-offer-history" ? "view" : "external-offer-history"))
							}
						/>
					)}
					{offer.highestBid && (
						<ListItemChip
							label={`Höchstgebot: ${formatCurrency(offer.highestBid.price)}`}
							color={offer.highestBid.price < offer.pricing!.targetPrice ? "warning" : "success"}
							active={mode === "bid-history"}
							onClick={() => setMode((m) => (m === "bid-history" ? "view" : "bid-history"))}
						/>
					)}
					{!offer.highestBid && offer.bids.length > 0 && (
						<ListItemChip
							label={formatNumber(
								offer.bids.length,
								"-",
								"zurückgezogene Gebote",
								"zurückgezogenes Gebot",
							)}
							color="info"
							active={mode === "bid-history"}
							onClick={() => setMode((m) => (m === "bid-history" ? "view" : "bid-history"))}
						/>
					)}
					{offer.pricingTag && (
						<ListItemChip
							icon={<PictureAsPdf />}
							label="Preisschild"
							color={offer.pricingTag.batchId || offer.status === "Stopped" ? "info" : "warning"}
							onClick={() => downloadFile(offer.pricingTag?.file?.hash)}
						/>
					)}
				</ListItemChips>
			</ListItemContent>
		</ListItem>
	);
};
