import { LeasmanVehicle } from "../../system/Domain";
import Tile from "../../Components/Tiles/Tile";
import formatNumber from "../../system/formatNumber";
import LeasmanVehicleListItem from "./LeasmanVehicleListItem";
import List from "../../Components/List";
import ListItemContent from "../../Components/ListItemContent";
import ListItem from "../../Components/ListItem";

interface Props {
	isLoading : boolean
	leasmanVehicles? : LeasmanVehicle[]

	isSelected? : (vehicle : LeasmanVehicle) => boolean
	onSelect? : (leasmanVehicle? : LeasmanVehicle) => void
}

export default ({ isLoading, leasmanVehicles, isSelected, onSelect } : Props) => {
	return (
		<Tile
			title="Leasman Fahrzeuge"
			subtitle={!isLoading && leasmanVehicles && (
				<span> ({formatNumber(leasmanVehicles.length)})</span>
			)}
		>
			<List>
				{!isLoading && leasmanVehicles && leasmanVehicles.length === 0 && (
					<ListItem>
						<ListItemContent>Keine Fahrzeuge gefunden</ListItemContent>
					</ListItem>
				)}
				{leasmanVehicles && leasmanVehicles.length > 0 && leasmanVehicles.map(v => (
					<LeasmanVehicleListItem
						key={v.id}
						leasmanVehicle={v}
						isSelected={(isSelected && isSelected(v)) || false}
						onSelect={onSelect}
					/>
				))}
			</List>
		</Tile>
	);
}
