import OrdersNavigation from "Navigation/OrdersNavigation";
import Layout from "../../Components/Layout/Layout";
import Imports from "../Imports/Imports";
import OrdersSubMenu from "./OrdersSubMenu";

export default () => (
	<Layout
		title="Importe"
		navigation={<OrdersNavigation />}
		subMenu={<OrdersSubMenu />}
	>
		<Imports importType="ReturnOrdersImport" secondaryImportType="ReturnOrdersImport" />
	</Layout>
)
