import { useHistory } from 'react-router-dom';

import { Warning } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

import { UserRole } from '../../../system/Domain';
import useUser from '../../../system/useUser';

export default () => {
	const [, , hasRole] = useUser();
	const history = useHistory();

	const isCustomer = hasRole(UserRole.Customer);
	const isAdmin = hasRole(UserRole.AldAdmin);

	if (!isCustomer) {
		return (
			<Box
				bgcolor={isAdmin ? "error.light" : "warning.light"}
				padding={1}
				display="flex"
				alignItems="center"
				justifyContent="center"
				onClick={() => history.goBack()}
			>
				<Box lineHeight="1em">
					<Warning />
				</Box>
				<Box pl={2}>
					<Typography color="textPrimary">
						{isAdmin
							? "Kundenauftrags Testmodus - Ald Admin - Speichern möglich!"
							: "Kundenauftrags Testmodus - Speichern nicht möglich"
						}
					</Typography>
				</Box>
			</Box>
		)
	}

	return null;
}
