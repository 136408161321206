import { ReactElement } from "react";
import Chip, { ChipProps } from "../Chip";
import { Box, PropTypes, useTheme } from "@mui/material";
import useCopyWithAltKey from "../../system/useCopyWithAltKey";

interface TextBlockProps {
	color?: Exclude<PropTypes.Color, "inherit">;
	primary?: string | ReactElement | null | boolean;
	secondary?: string | ReactElement | null | boolean;
	tertiary?: string | ReactElement | null | boolean;
	nowrap?: boolean;
	compact?: boolean;
	gutterBottom?: boolean;
	chip?: ChipProps;
	align?: "left" | "right" | "start" | "end" | "center";
	flat?: boolean;
	reduced?: boolean;
}

export default ({
	color = "primary",
	primary,
	secondary,
	tertiary,
	chip,
	compact = false,
	nowrap = false,
	align = "start",
	gutterBottom,
	flat,
	reduced,
}: TextBlockProps) => {
	const theme = useTheme();
	const styles = {
		primary: {
			fontSize: "1rem",
			paddingBottom: theme.spacing(0.3),
		},
		bold: {
			fontWeight: "bold",
		},
		compactWithChip: {
			marginTop: -theme.spacing(0.25),
		},
		secondary: {
			fontSize: "0.7rem",
			opacity: 0.8,
		},
		tertiary: {
			fontSize: "0.7rem",
			opacity: 0.8,
		},
		nowrap: {
			whiteSpace: "nowrap",
		},
		primaryCompact: {
			fontSize: "0.9rem",
			marginTop: "0.05rem",
			marginBottom: "0.05rem",
		},
		primaryWithChip: {
			marginTop: -theme.spacing(0.25),
		},
		secondaryWithChip: {
			marginLeft: theme.spacing(1),
		},
		reducedTertiary: {
			opacity: 1.0,
			fontSize: "0.8rem",
			fontVariantCaps: "all-small-caps",
			fontWeight: 500,
			color: theme.palette.text.secondary,
			lineHeight: "1em",
		},
		reducedSecondary: {
			opacity: 1.0,
			fontSize: "0.8rem",
			fontVariantCaps: "all-small-caps",
			fontWeight: 500,
			color: theme.palette.text.secondary,
			lineHeight: "1em",
		},
		reducedPrimary: {
			fontSize: "0.8rem",
			padding: 0,
			margin: 0,
			marginBottom: "0.2rem",
			fontWeight: 400,
		},
	};
	const copyWithAltKey = useCopyWithAltKey(primary);

	return (
		<Box textAlign={align} marginBottom={gutterBottom ? 1 : 0} onClick={copyWithAltKey}>
			{(primary || chip) && (
				<Box
					sx={[
						styles.primary,
						!flat && styles.bold,
						nowrap && styles.nowrap,
						compact && styles.primaryCompact,
						reduced && styles.reducedPrimary,
						!!chip && !!secondary && styles.primaryWithChip,
					]}
				>
					{chip && <Chip {...chip} />}
					{primary}
				</Box>
			)}
			{secondary && (
				<Box
					sx={[
						styles.tertiary,
						nowrap && styles.nowrap,
						compact && !!chip && styles.compactWithChip,
						reduced && styles.reducedSecondary,
						!!chip && styles.secondaryWithChip,
					]}
				>
					{secondary}
				</Box>
			)}
			{tertiary && (
				<Box
					sx={[
						styles.tertiary,
						nowrap && styles.nowrap,
						compact && !!chip && styles.compactWithChip,
						reduced && styles.reducedTertiary,
					]}
				>
					{tertiary}
				</Box>
			)}
		</Box>
	);
};
