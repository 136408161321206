import { CircularProgress } from "@mui/material";
import axios from "axios";
import ServicesNavigation from "Navigation/ServicesNavigation";
import { useState } from "react";
import FetchNextButton from "../../Components/FetchNextButton";
import Layout from "../../Components/Layout/Layout";
import { Billing, BillingStatus } from "../../system/Domain";
import useAsyncEffect from "../../system/useAsyncEffect";
import useQuery, { IQuery } from "../../system/useQuery";
import BillingsSidebar from "./BillingsSidebar";
import BillingTable from "./BillingTable";

export interface BillingQuery extends IQuery {
	status?: BillingStatus
	sort: string
	company?: string
}

export default () => {
	const take = 20;
	const [query, setQuery, fetchNext, resetQuery] = useQuery<BillingQuery>('billingQuery', {
		sort: "dateCreated:desc",
		skip: 0,
		take: take,
	});
	const [isFetching, setIsFetching] = useState(false);

	const [billings, setBillings] = useState<Billing[]>([]);

	useAsyncEffect(async (cancelToken) => {
		setIsFetching(true);

		try {
			const { data: billings } = await axios.get<Billing[]>(`/api/billings`, {
				params: query,
				cancelToken: cancelToken.getAxiosToken()
			});

			if (query.skip > 0) {
				setBillings(b => [
					...b,
					...billings
				]);
			} else {
				setBillings(billings);
			}
		} finally {
			setIsFetching(false);
		}
	}, [query]);

	return (
		<Layout
			title="Abrechnungen"
			navigation={<ServicesNavigation />}
			sidebar={<BillingsSidebar query={query} setQuery={setQuery} resetQuery={resetQuery} />}
		>
			{!billings && (
				<CircularProgress size={48} />
			)}
			{billings && (
				<>
					<BillingTable billings={billings} />
					<FetchNextButton
						hidden={billings.length < take + query.skip}
						my={3}
						onNext={fetchNext}
						disabled={isFetching}
					/>
				</>
			)}
		</Layout>
	);
}
