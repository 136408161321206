import axios from "axios";
import moment, { Moment } from "moment";
import VehiclesNavigation from "Navigation/VehiclesNavigation";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import { Box, Button, CircularProgress, MenuItem, Select } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import Layout from "../../Components/Layout/Layout";
import NotificationSnackbar from "../../Components/Layout/NotificationSnackbar";
import TextBlock from "../../Components/Layout/TextBlock";
import VinQrScanner from "../../Components/VinQrScanner";
import { CompoundPlace, RemarketingStatus, Vehicle } from "../../system/Domain";
import useAsyncEffect from "../../system/useAsyncEffect";
import useInventory from "../../system/useInventory";
import useCompoundPlaces from "../../system/useCompoundPlaces";
import useCompoundProcessSteps from "../../system/useCompoundProcessSteps";
import LotMap from "../Vehicles/LotMap";
import VehicleHeader from "../Vehicles/VehicleHeader";
import InventoryScanContextMenu from "./InventoryScanContextMenu";
import InventoryTitle from "./InventoryTitle";

export default (props: any) => {
	const inputState: string = (props.location && props.location.state) || undefined;
	const { id } = useParams<{ id: string }>();
	const [inventory] = useInventory(id);
	const history = useHistory();

	const [vin, setVin] = useState<string | undefined>(inputState);
	const [vehicle, setVehicle] = useState<Vehicle>();
	const [, setSaving] = useState<boolean>(false);

	const [processSteps] = useCompoundProcessSteps();
	const [status, setStatus] = useState<RemarketingStatus>();

	const [places] = useCompoundPlaces();
	const [currentPlace, setCurrentPlace] = useState<CompoundPlace>();
	const [selectedPlace, setSelectedPlace] = useState<CompoundPlace>();
	const [date, setDate] = useState<Moment>();

	const [resultMessage, setResultMessage] = useState("");
	const [resultMessageColor, setResultMessageColor] = useState<any>("success"); //COLOR to check DOMINIK

	useAsyncEffect(async () => {
		if (!vin) {
			setVehicle(undefined);
			setStatus(undefined);
			setCurrentPlace(undefined);
			setSelectedPlace(undefined);
			return;
		}

		setSaving(true);

		try {
			const { data: vehicle } = await axios.post<Vehicle>(`/api/compounds/scans`, {
				vin,
			});

			setVehicle(vehicle);
		} finally {
			setSaving(false);
		}
	}, [vin]);

	useEffect(() => {
		if (!vehicle?.placeId || !places) {
			setCurrentPlace(undefined);
			return;
		}

		const place = places.find((p) => p.id === vehicle.placeId);
		setCurrentPlace(place);
	}, [vehicle?.placeId, places]);

	useEffect(() => {
		if (!vehicle) {
			setStatus(undefined);
			return;
		}

		setStatus(vehicle.remarketing.status);
	}, [vehicle]);

	useEffect(() => {
		if (!vehicle || !status) {
			setDate(undefined);
			return;
		}

		if (status === "Eingang") {
			setDate(moment(vehicle.history.currentStockDateRange?.from || undefined));
		} else if (status === "Ausgang") {
			setDate(moment(vehicle.history.currentStockDateRange?.to || undefined));
		} else {
			setDate(undefined);
		}
	}, [vehicle, status]);

	const save = async () => {
		if (!vehicle) {
			return;
		}

		await axios.put<Vehicle>(`/api/inventories/${inventory!.id}/vehicles/${vehicle.id}`, {
			date: date?.toISOString(),
			status,
			placeId: selectedPlace?.id,
		});

		if (inputState) {
			history.push(`/inventories/${inventory!.id}`);
		}

		setResultMessageColor("success");
		setResultMessage("Fahrzeug zur Inventur hinzugefügt");

		setVin(undefined);
	};

	const cancel = () => setVin(undefined);

	const alreadyInInventory = vehicle?.history?.entries.some((f) => f.inventoryId === id) ?? false;

	return (
		<Layout
			title={inventory && <InventoryTitle inventory={inventory} />}
			navigation={<VehiclesNavigation />}
			contextMenu={inventory && [<InventoryScanContextMenu inventory={inventory} />]}
		>
			{!inventory && <CircularProgress size={48} />}
			{inventory && (
				<>
					{!vin && <VinQrScanner setVin={setVin} />}
					{vin && vehicle && (
						<>
							<VehicleHeader vehicle={vehicle} />
							{alreadyInInventory && (
								<>
									<Box mt={3}>
										<TextBlock primary="Dieses Fahrzeug wurde in dieser Inventur bereits gescannt." />
									</Box>
									<Box mt={3}>
										<Button
											fullWidth={false}
											variant="contained"
											size="medium"
											color="primary"
											disabled={!status || status === "Registriert"}
											onClick={save}
										>
											Erneut zur Inventur hinzufügen
										</Button>
										<Box ml={1} display="inline">
											<Button
												fullWidth={false}
												variant="outlined"
												size="medium"
												color="primary"
												onClick={cancel}
											>
												Abbrechen
											</Button>
										</Box>
									</Box>
								</>
							)}
							{!alreadyInInventory && (
								<Box mt={3}>
									<Button
										fullWidth={false}
										variant="contained"
										size="medium"
										color="primary"
										disabled={!status || status === "Registriert"}
										onClick={save}
									>
										Zur Inventur hinzufügen
									</Button>
								</Box>
							)}

							<Box mt={3}>
								<TextBlock primary="Status" />
								<Box>
									<Select
										value={status || ""}
										variant="standard"
										onChange={(e) => {
											setStatus(e.target.value as RemarketingStatus);
										}}
									>
										{processSteps
											?.filter((s) => s.status !== "Registriert")
											.map((s) => (
												<MenuItem key={s.status} value={s.status}>
													{s.displayName}
												</MenuItem>
											))}
									</Select>
								</Box>
								{(status === "Eingang" || status === "Ausgang") && (
									<Box mt={3}>
										<TextBlock primary="Datum" />
										<DatePicker
											format="DD.MM.YYYY"
											slotProps={{ textField: { variant: "standard" } }}
											value={date ?? null}
											onChange={(d: any) => setDate(d?.endOf("day"))}
										/>
									</Box>
								)}
							</Box>

							<Box mt={3} display="flex" flexDirection="column" flexGrow={1}>
								<TextBlock primary="Platz" />
								<Box display="flex" flexDirection="column" flexGrow={1} minHeight={300}>
									<LotMap
										currentPlace={currentPlace}
										selectedPlace={selectedPlace}
										onPlaceClick={(p) =>
											setSelectedPlace(p === currentPlace || p === selectedPlace ? undefined : p)
										}
									/>
								</Box>
							</Box>
						</>
					)}
				</>
			)}
			<NotificationSnackbar
				message={resultMessage}
				onClose={() => setResultMessage("")}
				color={resultMessageColor}
			/>
		</Layout>
	);
};
