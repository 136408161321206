
import TextBlock from "../../Components/Layout/TextBlock";
import { Plate } from "../../Components/Plate";
import { PoolFleetVehicle } from "../../system/Domain";
import poolFleetStatus from "./poolFleetStatus";

interface Props {
	vehicle: PoolFleetVehicle
}

function PoolFleetVehicleHeader({ vehicle }: Props) {
	return (
		<TextBlock
			primary={<Plate plateNumber={vehicle?.plateNumber} />}
			secondary={(
				<span>
					{poolFleetStatus[vehicle.status]}・{vehicle.vin}・{vehicle.makeModel}
				</span>
			)}
		/>
	);
}

export default PoolFleetVehicleHeader
