import Highcharts from "../../Components/Highcharts";
import moment from "moment";
import { SalesReporting } from "./SalesReporting";
import useChartOptions from "../../system/useChartOptions";
import formatCurrency from "../../system/formatCurrency";

interface Props {
	reporting: SalesReporting
}

export default ({ reporting }: Props) => {
	const [options, formatters] = useChartOptions();

	return (
		<Highcharts
			options={{
				...options,
				tooltip: {
					formatter: function () {
						return formatters.default(this,
							`Woche vom ${moment(this.x).format("DD. MMMM YYYY")}`,
							!!this.y ? `${formatCurrency(this.y)}` : "-"
						);
					}
				},
				series: [
					{
						type: "line",
						name: "50. Perzentile",
						data: reporting.histogram.map(h => ({
							x: moment(h.date).valueOf(),
							y: h.percentiles[1]
						}))
					},
					{
						type: "line",
						name: "25. Perzentile",
						data: reporting.histogram.map(h => ({
							x: moment(h.date).valueOf(),
							y: h.percentiles[0]
						}))
					},
					{
						type: "line",
						name: "75. Perzentile",
						data: reporting.histogram.map(h => ({
							x: moment(h.date).valueOf(),
							y: h.percentiles[2]
						}))
					}
				]
			}}
		/>
	);
}
