import { atom } from "recoil";

const logoutAtom = atom({
	key: "logout",
	default: false,
});

export {
	logoutAtom
};
