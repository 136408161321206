import { CircularProgress, Box } from "@mui/material";

export default ({ loading = true }) => {
	if (!loading) {
		return null;
	}

	return (
		<Box m={2} textAlign="center">
			<CircularProgress />
		</Box>
	);
};
