import { DependencyList, useCallback, useEffect } from "react";
import hotkeys from "hotkeys-js";

hotkeys.filter = () => true;

export default (keys : string, fn : (event : any, handler : any) => any, deps : DependencyList = []) => {
	const cb = useCallback(fn, deps);
	useEffect(() => {
		hotkeys(keys, cb);
 
		return () => {
			hotkeys.unbind(keys, cb);
		};
 	}, deps);
}
