import useUser from "../../system/useUser";
import { UserRole } from "../../system/Domain";

export interface SalePermissions {
	canView : boolean
	canCreate : boolean
	canReserve : boolean
	canManageOffers : boolean
	canConfirmOffers : boolean
	canPrice : boolean
	canBid : boolean
	canWithdrawBid : boolean
	canManageOrders : boolean
	canManageDirectSaleOrders : boolean
}

export default () : SalePermissions => {
	const [, , hasRole, , hasAnyRole] = useUser();

	const permissions : SalePermissions = {
		canView: hasRole(UserRole.SalesUser),
		canCreate: hasRole(UserRole.SalesUser),
		canReserve: hasRole(UserRole.SalesManager),
		canManageOffers: hasAnyRole([UserRole.SalesAdmin, UserRole.SalesBackoffice]),
		canConfirmOffers: hasAnyRole([UserRole.SalesAdmin, UserRole.SalesBackoffice]),
		canPrice: hasAnyRole([UserRole.SalesAdmin, UserRole.SalesBackoffice]),
		canBid: hasAnyRole([UserRole.SalesManager]),
		canWithdrawBid: hasAnyRole([UserRole.SalesAdmin, UserRole.SalesBackoffice]),
		canManageOrders: hasRole(UserRole.SalesManager),
		canManageDirectSaleOrders: hasRole(UserRole.SalesUser),
	};

	return permissions;
}
