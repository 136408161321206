import { TableProps, Table as MuiTable } from "@mui/material";

interface Props extends TableProps {
	noBorder?: boolean
}

const Table = ({ noBorder, ...rest }: Props) => {
	if (noBorder) {
		return (
			<Table sx={{
				"& td": {
					borderBottom: "none"
				},
				"& th": {
					borderBottom: "none"
				}
			}} {...rest} />
		);
	}

	return (
		<MuiTable {...rest} />
	);
};

export default Table;
