import { useLayoutEffect, useState } from "react";

const getWindowSize = () => ({
	width: window.innerWidth,
	height: window.innerHeight
});

export default () => {
	const [size, setSize] = useState(getWindowSize());

	useLayoutEffect(() => {
		const updateSize = () => setSize(getWindowSize());

		window.addEventListener("resize", updateSize);

		return () => {
			window.removeEventListener('resize', updateSize);
		};
	}, []);

	return size;
}
