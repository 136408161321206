import axios from 'axios';
import List from 'Components/List';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';

import { Add } from '@mui/icons-material';
import { Box, Divider } from '@mui/material';

import InlineList from '../../Components/InlineList';
import TextBlock from '../../Components/Layout/TextBlock';
import ListItem from '../../Components/ListItem';
import ListItemAction from '../../Components/ListItemAction';
import ListItemActions from '../../Components/ListItemActions';
import ListItemChip from '../../Components/ListItemChip';
import ListItemChips from '../../Components/ListItemChips';
import ListItemContent from '../../Components/ListItemContent';
import Search from '../../Components/Search';
import { CatalogVehicle, Equipment, ThemeColor, Vehicle } from '../../system/Domain';

interface Props {
	vehicle : Vehicle
	setVehicle : Dispatch<SetStateAction<Vehicle | undefined>>
	catalogVehicle : CatalogVehicle
}

export default ({ vehicle, setVehicle, catalogVehicle } : Props) => {
	const [searchTokens, setSearchTokens] = useState<string[]>([]);
	const [equipments, setEquipments] = useState<Equipment[]>([]);
	const [updating, setUpdating] = useState(false);

	const tokenize = (text : string) => text.toLowerCase().split(' ').filter(t => !!t);

	const isMatch = (tokens : string[], equipment : Equipment) => {
		const equipmentText = equipment.text.toLowerCase();
		return tokens.every(t => equipmentText.includes(t));
	};

	const searchEquipments = (text : string) => {
		if (!text) {
			setSearchTokens([]);
			return;
		}

		const tokens = tokenize(text);
		setSearchTokens(tokens);
	};

	const addEquipment = async (equipment : Equipment) => {
		setUpdating(true);
		try {
			const { data } = await axios.post<Vehicle>(`/api/vehicles/${vehicle.id}/equipment`, {
				id: equipment.id,
				typeId: equipment.typeId,
				text: equipment.text,
				isExtra: true,
				categories: equipment.categories
			});
			setVehicle(data);
		} finally {
			setUpdating(false);
		}
	};

	useEffect(() => {
		if (searchTokens.length === 0) {
			setEquipments([]);
			return;
		}

		const equipments = catalogVehicle.equipment
			.filter(e => !vehicle.equipment.some(e2 => e2.id === e.id) && isMatch(searchTokens, e))
			.slice(0, 20);

		setEquipments(equipments);
	}, [searchTokens, vehicle.equipment.length]);

	const color : ThemeColor = "info";

	return (
		<Box>
			<Box>
				<Search
					placeholder="Neue Ausstattung suchen"
					onSearch={searchEquipments}
					realtime
				/>
			</Box>
			{searchTokens.length > 0 && (
				<List mt={2}>
					{equipments.length === 0 && (
						<ListItem>
							<ListItemContent>Keine passende Ausstattung gefunden</ListItemContent>
						</ListItem>
					)}
					{equipments.map(e => (
						<ListItem key={e.id} color={color}>
							<ListItemActions disabled={updating}>
								<ListItemAction
									icon={<Add/>}
									onClick={() => addEquipment(e)}
								/>
							</ListItemActions>
							<ListItemContent>
								<TextBlock
									primary={(
										<Highlighter
											searchWords={searchTokens}
											textToHighlight={e.text}
										/>
									)}
									secondary={(
										<InlineList>
											<span>{e.id}</span>
											{e.categories?.map(c => (
												<span key={c.id}>{c.name}</span>
											))}
										</InlineList>
									)}
								/>
								<ListItemChips>
									<ListItemChip
										label="Fahrzeugkatalog"
										color="primary"
									/>
								</ListItemChips>
							</ListItemContent>
						</ListItem>
					))}
					<Divider/>
				</List>
			)}
		</Box>
	);
}
