import { FormEvent, useEffect, useState } from "react";
import { FormControlLabel, Switch } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";
import Typography from "@mui/material/Typography";
import * as yup from "yup";
import axios from "axios";
import ThemedDialog from "../../Components/ThemedDialog";
import { Company } from "../../system/Domain";
import Select from "Components/Select";

const schema = yup.object().shape({
	email: yup.string()
		.email("Eine gültige Email Adresse muss angegeben werden")
		.required("Email muss angegeben werden"),
	name: yup.string().required("Ein Name muss angegeben werden")
});

interface InviteUserDialogProps extends InviteUserOptions, ReactConfirmProps<InvitedUser> {
}

interface InvitedUser {
	email: string,
	name: string,
	company?: string,
	isTestUser: boolean
}

const InviteUserDialog = ({ show, proceed, cancel, canSelectCompany, canSelectALD }: InviteUserDialogProps) => {
	const [companies, setCompanies] = useState<Array<Company>>([]);
	const [formState, setFormState] = useState<InvitedUser>({
		company: "",
		email: "",
		name: "",
		isTestUser: false
	});

	const [errors, setErrors] = useState<any>({});

	useEffect(() => {
		axios.get<Array<Company>>("/api/companies").then(response => setCompanies(response.data));
	}, []);

	const onChanged = (field: string) => (event: any) => setFormState({
		...formState,
		[field]: event.target.value
	});

	const handleClose = () => {
		cancel();
	};

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		try {
			await schema.validate(formState, { abortEarly: false });
		} catch (err) {
			setErrors(err.inner.reduce((p: any, c: any) => {
				p[c.path] = c.message;
				return p;
			}, {}));
			return;
		}
		proceed(formState);
	};

	return (
		<ThemedDialog open={show} onClose={handleClose} maxWidth="sm" fullWidth>
			<DialogTitle>
				<Typography variant="inherit" gutterBottom>Externen Benutzer anlegen</Typography>
				<Typography component="div" variant="body2">
					Der Benutzer erhält nach der Anlage eine Email, mit der er sein Password
					ändern kann. Es können nur externe Benuzter angelegt werden. Interne Benutzer
					müssen sich über das Active Directory authentifizieren
				</Typography>
			</DialogTitle>
			<form onSubmit={handleSubmit}>
				<DialogContent>
					{canSelectCompany && <Select
						label="Firma"
						name="company"
						fullWidth
						margin="none"
						value={formState.company}
						autoFocus
						required
						onChange={onChanged("company")}
					>
						<option value="" />
						{companies.filter(f => canSelectALD || f.name !== "ALD").map(c => (
							<option key={c.name} value={c.name}>{c.name}</option>
						))}
					</Select>}
					<TextField
						label="Name des Benutzers"
						value={formState.name}
						name="name"
						onChange={onChanged("name")}
						error={Boolean(errors.name)}
						helperText={errors.name}
						fullWidth
						required
						margin="normal"
						variant="outlined"
					/>
					<TextField
						value={formState.email}
						name="email"
						onChange={onChanged("email")}
						error={Boolean(errors.email)}
						helperText={errors.email}
						label="Emailadresse des Benutzers"
						fullWidth
						required
						margin="normal"
						variant="outlined"
						type="email"
					/>
					<FormControlLabel
						sx={{ marginLeft: 0 }}
						value="end"
						control={
							<Switch
								checked={formState.isTestUser}
								onChange={(e) => setFormState({
									...formState,
									isTestUser: e.target.checked
								})}
								color="primary"
							/>
						}
						label="Test Benutzer"
						labelPlacement="end"
					/>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" color="primary" type="submit">
						OK
					</Button>
					<Button variant="outlined" color="secondary" href="" onClick={handleClose}>
						Abbrechen
					</Button>
				</DialogActions>
			</form>
		</ThemedDialog>
	);
};

interface InviteUserOptions {
	canSelectCompany: boolean
	canSelectALD: boolean
}

export default (options: InviteUserOptions) => {
	const dialog = confirmable(props => <InviteUserDialog {...props} {...options} />);
	return createConfirmation<InvitedUser | null>(dialog)();
};
