import moment from 'moment';
import { useEffect, useState } from 'react';

import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Layout from '../../Components/Layout/Layout';
import { Billing } from '../../system/Domain';
import useCompanies from '../../system/useCompanies';
import useUser from '../../system/useUser';
import useVehicleServicePermissions from '../VehicleServices/useVehicleServicePermissions';
import VehicleServicesSubMenu from '../VehicleServices/VehicleServicesSubMenu';
import NewBillingSidebar from './NewBillingSidebar';

export default () => {
	const [user] = useUser();
	const servicePermissions = useVehicleServicePermissions();

	const [billing, setBilling] = useState<Partial<Billing>>({
		dateFrom: moment().startOf("M").toISOString(true),
		dateTo: moment().endOf("M").toISOString(true),
	});
	const [companies] = useCompanies();

	useEffect(() => {
		if (!user) {
			return;
		}

		if (servicePermissions.isRestrictedToHisCompany) {
			setBilling(b => ({
				...b,
				company: user.company
			}));
		}
	}, [user]);

	return (
		<Layout
			title="Neue Abrechnung"
			subMenu={<VehicleServicesSubMenu />}
			sidebar={<NewBillingSidebar billing={billing} />}
		>
			<Typography variant="h4">
				Neue Abrechnung
			</Typography>
			{servicePermissions.isRestrictedToHisCompany && user?.company && (
				<Box mt={0.5}>
					<Typography variant="body1">
						{user.company.name}
					</Typography>
				</Box>
			)}
			<Box mt={4} maxWidth={250}>
				<Grid container spacing={4}>
					{!servicePermissions.isRestrictedToHisCompany && companies && (
						<Grid item xs={12}>
							<FormControl variant="standard" fullWidth>
								<InputLabel>Dienstleister</InputLabel>
								<Select
									value={billing.company?.name}
									onChange={(e) => {
										const value = e.target.value as string | undefined;
										setBilling(b => ({
											...b,
											company: !value ? undefined : { name: value }
										}));
									}}
								>
									{companies.filter(c => c.services?.allowAccounting).map(c => (
										<MenuItem key={c.name} value={c.name}>{c.name}</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					)}
					<Grid item xs={12}>
						<FormControl fullWidth>
							<DatePicker
								format="DD.MM.YYYY"
								label="Zeitraum Start"
								value={moment(billing.dateFrom)}
								slotProps={{ textField: { variant: 'standard' } }}
								onChange={(d: any) => setBilling(b => ({
									...b,
									dateFrom: moment(d)?.startOf("d").toISOString() //true
								}))}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={12}>
						<FormControl fullWidth>
							<DatePicker
								format="DD.MM.YYYY"
								label="Zeitraum Ende"
								slotProps={{ textField: { variant: 'standard' } }}
								value={moment(billing.dateTo)}
								onChange={(d: any) => setBilling(b => ({
									...b,
									dateTo: moment(d)?.endOf("d").toISOString() //true
								}))}
							/>
						</FormControl>
					</Grid>
				</Grid>
			</Box>
		</Layout>
	);
}
