import { useState } from "react";
import Layout from "../../Components/Layout/Layout";

import { useHistory } from "react-router-dom";
import { Box, Button, useTheme } from "@mui/material";
import axios from "axios";
import { Vehicle } from "../../system/Domain";
import useAsyncEffect from "../../system/useAsyncEffect";
import VinQrScanner from "../../Components/VinQrScanner";


export default () => {
	const theme = useTheme();
	const history = useHistory();

	const [error, setError] = useState<Error>();
	const [vin, setVin] = useState<string>();

	useAsyncEffect(async () => {
		if (!vin) {
			return;
		}

		const { data: vehicles } = await axios.get<Vehicle[]>(`/api/vehicles?vin=${vin}`);
		const vehicle = vehicles.length === 1 ? vehicles[0] : null;

		if (!vehicle) {
			return;
		}

		history.push(`/vehicles/${vehicle.id}/services/new`)
	}, [vin]);

	return (
		<Layout
			title="Neue Tätigkeit"
		>
			{!!error && (
				<>
					<Box sx={{
						color: theme.palette.error.main,
						fontSize: "1.2rem",
						textAlign: "center"
					}}>
						<div>{error.message}</div>
					</Box>
					<Box mt={3} textAlign="center">
						<Button
							variant="text"
							size="medium"
							onClick={() => {
								setError(undefined);
								setVin(undefined);
							}}
						>Erneut versuchen</Button>
					</Box>
				</>
			)}
			{!error && !vin && (
				<Box height="100%" display="flex" alignItems="center">
					<VinQrScanner setVin={setVin} />
				</Box>
			)}
		</Layout>
	);
};
