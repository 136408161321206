import { Form } from "../../../system/useForm";
import { CustomerOrder, CustomerOrderAddressInformation, dorfmarkAddress } from "../../../system/Domain";
import { Box, Grid, Typography } from "@mui/material";
import FormTextField from "../../../Components/FormTextField";
import FormDateTimePicker from "../../../Components/FormDateTimePicker";
import TextBlock from "../../../Components/Layout/TextBlock";
import moment from 'moment';

interface Props {
	form: Form
	aldAddressData: CustomerOrderAddressInformation
	customerOrder: CustomerOrder
}

const StepDelivery = (props: Props) => {
	const maxDate = moment.max(moment(props.customerOrder.dateContractEnd), moment()).add(4, "M");

	const deliveryMinDate =
		props.customerOrder.allowEarlyReturn
			? moment().add(1, 'd').toString()
			: props.customerOrder.dateContractEnd;

	return (
		<Box mt={2} mb={2}>
			<Box mt={2}>
				<Typography variant="h6" gutterBottom>
					Eigenanlieferung Dorfmark
				</Typography>
			</Box>
			<Box mt={4}>
				<TextBlock
					primary={dorfmarkAddress.name}
				/>
				{dorfmarkAddress.street}
				<br />
				{dorfmarkAddress.zipCode} - {dorfmarkAddress.city} - Deutschland
			</Box>
			<Box mt={4}>
				<Typography variant="h6">
					Anlieferungstermin
				</Typography>
			</Box>
			<Grid container spacing={3}>
				<Grid item xs={12} md={6}>
					<FormDateTimePicker
						name="delivery.appointment.notBefore"
						form={props.form}
						label="am"
						margin="normal"
						variant="date"
						format="DD.MM.YYYY"
						required
						disableWeekends
						disablePast
						minDate={deliveryMinDate}
						maxDate={maxDate.format("YYYY-MM-DD")}
						disabledKeyboard
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<FormDateTimePicker
						name="delivery.appointment.from"
						form={props.form}
						label="Zeit von"
						variant="time"
						ampm={false}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<FormDateTimePicker
						name="delivery.appointment.to"
						form={props.form}
						label="bis"
						variant="time"
						ampm={false}
					/>
				</Grid>
			</Grid>

			<Box mt={4}>
				<Typography variant="h6" gutterBottom>
					Zusatzinformationen
				</Typography>
			</Box>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<FormTextField
						form={props.form}
						name="delivery.comment"
						label="Bemerkung"
						fullWidth
					/>
				</Grid>
			</Grid>
		</Box>
	);
}

export default StepDelivery;
