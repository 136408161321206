import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import FluentGrid from "../../Components/FluentGrid";
import TextBlock from "../../Components/Layout/TextBlock";
import Tile from "../../Components/Tiles/Tile";
import TileContent from "../../Components/Tiles/TileContent";
import { EquipmentHighlightReference, SaleQuery, Transmission, VehicleType } from "../../system/Domain";
import useAxiosEffect from "../../system/useAxiosEffect";
import { Box, useTheme } from "@mui/material";
import SalesPercentilesChart from "./SalesPercentilesChart";
import SalesReportingFilter from "./SalesReportingFilter";
import formatNumber from "../../system/formatNumber";
import formatCurrency from "../../system/formatCurrency";
import { formatTransmissionType } from "../Vehicles/CatalogPreviewListItem";
import formatPercentage from "../../system/formatPercentage";
import SalesCountChart from "./SalesCountChart";

interface Props {
	type: VehicleType;
	highlights: EquipmentHighlightReference[];
	duration: number;
	mileage: number;
}

export interface SalesReporting {
	count: number;
	countSales: number;
	countOffers: number;
	histogram: {
		date: string;
		count: number;
		percentiles: number[];
	}[];
	salesAmount: number[];
	damageAmount: number[];
	totalPrice: number[];
	targetPrice: number[];
	bodyType: {
		name: string;
		count: number;
		median: number;
	}[];
	transmission: {
		name: string;
		count: number;
		median: number;
	}[];
}

export interface SalesReportingSettings {
	lastMonths: number;
	model: string;
	// model: "all" | "make" | "model" | "variant";
	highlights: number;
	duration: number;
	mileage: number;
	retail?: boolean;
	previousOwners?: number | null;
	// source?: "sales" | "offers" | null;
	source?: string | null;
}

const buildQuery = (
	settings: SalesReportingSettings,
	type: VehicleType,
	highlights: EquipmentHighlightReference[],
	duration: number,
	mileage: number,
): SaleQuery => {
	const query: SaleQuery = {
		dateFrom: moment().subtract(settings.lastMonths, "months").startOf("month").toISOString(true),
	};

	if (settings.model !== "all" && !!type) {
		query.makes = [type.make.name];

		if (settings.model !== "make") {
			query.models = [type.model.name];
		}

		if (settings.model === "variant") {
			query.modelVariants = [type.model.variant];
		}
	}

	if (settings.duration >= 0) {
		// const tolerance = Math.ceil(sale.contract.duration * (settings.duration! / 100));
		const tolerance = settings.duration;
		query.duration = {
			from: duration - tolerance,
			to: duration + tolerance,
		};
	}

	if (settings.mileage >= 0 && mileage) {
		// const tolerance = sale.returnMileage * (settings.mileage! / 100);
		const tolerance = settings.mileage! * 1000;
		query.mileage = {
			from: mileage - tolerance,
			to: mileage + tolerance,
		};
	}

	if (settings.highlights >= 0) {
		query.highlights = highlights.filter((c) => c.relevance?.pricing).map((c) => c.name);

		query.highlightsShouldMatch =
			settings.highlights === 0
				? query.highlights.length
				: query.highlights.length - Math.ceil(query.highlights.length * (settings.highlights / 100));
	}

	query.retail = settings.retail;
	query.previousOwners = settings.previousOwners;

	if (settings.source) {
		switch (settings.source) {
			case "sales":
				query.status = ["Sold", "ReadyForShipping", "Shipped", "End"];
				break;
			case "offers":
				query.status = ["ReadyToSell", "Reserved"];
				break;
			default:
				break;
		}
	}

	return query;
};

export default ({ type, highlights, duration, mileage }: Props) => {
	const theme = useTheme();
	const styles = {
		panel: {
			marginTop: theme.spacing(1),
			paddingTop: theme.spacing(1),
			borderTop: "1px solid rgba(255, 255, 255, 0.1)",
			"&:first-child": {
				marginTop: 0,
				paddingTop: 0,
				borderTop: 0,
			},
		},
		panelTitle: {
			paddingTop: theme.spacing(2),
			paddingLeft: theme.spacing(2),
		},
		panelText: {
			display: "flex",
			flexDirection: "row",
			padding: theme.spacing(2),
			paddingBottom: theme.spacing(1),
			gap: theme.spacing(0.5),
			justifyContent: "space-between",
			"&:last-child": {
				paddingBottom: theme.spacing(2),
			},
		},
		panelTextColumn: {
			flexDirection: "column",
			gap: theme.spacing(2),
		},
	};
	const [reporting, setReporting] = useState<SalesReporting>();
	const [query, setQuery] = useState<SaleQuery>();

	const [settings, setSettings] = useState<SalesReportingSettings>({
		lastMonths: 18,
		model: "model",
		highlights: 0,
		duration: -1,
		mileage: -1,
		retail: false,
		source: "sales",
		previousOwners: null,
	});

	useEffect(() => {
		if (!type) {
			return;
		}

		setQuery(buildQuery(settings, type, highlights, duration, mileage));
	}, [settings, type, highlights, duration, mileage]);

	useAxiosEffect(
		async (config) => {
			if (!query) {
				setReporting(undefined);
				return;
			}

			const { data } = await axios.post<SalesReporting>(`/api/sales/reporting/search`, query, config);
			setReporting(data);
		},
		[query],
	);

	return (
		<Tile title="Preisanalyse">
			<TileContent dense>
				{reporting && (
					<>
						<Box sx={styles.panel}>
							<Box sx={styles.panelTitle}>
								<TextBlock flat primary="Filter" />
							</Box>
							<Box p={2}>
								<SalesReportingFilter
									reporting={reporting}
									settings={settings}
									setSettings={setSettings}
								/>
							</Box>
						</Box>

						{settings.source !== "offers" && (
							<Box sx={styles.panel}>
								<Box sx={styles.panelText}>
									<TextBlock
										reduced
										primary={formatCurrency(reporting.salesAmount[0])}
										secondary="25. Perzentile"
									/>
									<TextBlock
										reduced
										primary={formatCurrency(reporting.salesAmount[1])}
										secondary="50. Perzentile"
									/>
									<TextBlock
										reduced
										primary={formatCurrency(reporting.salesAmount[2])}
										secondary="75. Perzentile"
									/>
									<TextBlock
										reduced
										primary={formatNumber(reporting.countSales)}
										secondary="Verkäufe"
									/>
								</Box>
							</Box>
						)}

						<Box sx={styles.panel}>
							<FluentGrid itemWidth={140}>
								<Box>
									<Box sx={styles.panelTitle}>
										<TextBlock flat primary="Median" />
									</Box>
									<Box sx={[styles.panelText, styles.panelTextColumn]}>
										<TextBlock
											reduced
											primary={formatCurrency(reporting.totalPrice[1])}
											secondary="UPE"
										/>
										<TextBlock
											reduced
											primary={formatCurrency(reporting.damageAmount[1])}
											secondary="Minderwerte"
										/>
										<TextBlock
											reduced
											primary={
												!reporting.salesAmount[1]
													? "-"
													: `${formatCurrency(reporting.salesAmount[1])} (${formatPercentage(
														reporting.salesAmount[1] / reporting.totalPrice[1],
													)})`
											}
											secondary={`Verkaufspreis (${reporting.countSales})`}
										/>
										<TextBlock
											reduced
											primary={formatCurrency(reporting.targetPrice[1])}
											secondary={`Zielpreis (${reporting.count})`}
										/>
									</Box>
								</Box>
								{/*<Box>*/}
								{/*	<Box sx={styles.panelTitle}>*/}
								{/*		<TextBlock*/}
								{/*			flat*/}
								{/*			primary="UPE"*/}
								{/*		/>*/}
								{/*	</Box>*/}
								{/*	<Box sx={clsx(styles.panelText, styles.panelTextColumn)}>*/}
								{/*		<TextBlock*/}
								{/*			reduced*/}
								{/*			primary={formatCurrency(reporting.totalPrice[0])}*/}
								{/*			secondary="25. Perzentile"*/}
								{/*		/>*/}
								{/*		<TextBlock*/}
								{/*			reduced*/}
								{/*			primary={formatCurrency(reporting.totalPrice[1])}*/}
								{/*			secondary="50. Perzentile"*/}
								{/*		/>*/}
								{/*		<TextBlock*/}
								{/*			reduced*/}
								{/*			primary={formatCurrency(reporting.totalPrice[2])}*/}
								{/*			secondary="75. Perzentile"*/}
								{/*		/>*/}
								{/*	</Box>*/}
								{/*</Box>*/}
								{/*<Box>*/}
								{/*	<Box sx={styles.panelTitle}>*/}
								{/*		<TextBlock*/}
								{/*			flat*/}
								{/*			primary="Minderwerte"*/}
								{/*		/>*/}
								{/*	</Box>*/}
								{/*	<Box sx={clsx(styles.panelText, styles.panelTextColumn)}>*/}
								{/*		<TextBlock*/}
								{/*			reduced*/}
								{/*			primary={formatCurrency(reporting.damageAmount[0])}*/}
								{/*			secondary="25. Perzentile"*/}
								{/*		/>*/}
								{/*		<TextBlock*/}
								{/*			reduced*/}
								{/*			primary={formatCurrency(reporting.damageAmount[1])}*/}
								{/*			secondary="50. Perzentile"*/}
								{/*		/>*/}
								{/*		<TextBlock*/}
								{/*			reduced*/}
								{/*			primary={formatCurrency(reporting.damageAmount[2])}*/}
								{/*			secondary="75. Perzentile"*/}
								{/*		/>*/}
								{/*	</Box>*/}
								{/*</Box>*/}
								<Box>
									<Box sx={styles.panelTitle}>
										<TextBlock flat primary="Bauart" />
									</Box>
									<Box sx={[styles.panelText, styles.panelTextColumn]}>
										{reporting.bodyType.map((t) => (
											<TextBlock
												key={t.name}
												reduced
												primary={formatCurrency(t.median)}
												secondary={`Ø ${t.name} (${formatNumber(t.count)})`}
											/>
										))}
									</Box>
								</Box>
								<Box>
									<Box sx={styles.panelTitle}>
										<TextBlock flat primary="Getriebe" />
									</Box>
									<Box sx={[styles.panelText, styles.panelTextColumn]}>
										{reporting.transmission.map((t) => (
											<TextBlock
												key={t.name}
												reduced
												primary={formatCurrency(t.median)}
												secondary={`Ø ${formatTransmissionType(
													t.name as Transmission,
												)} (${formatNumber(t.count)})`}
											/>
										))}
									</Box>
								</Box>
							</FluentGrid>
						</Box>

						<Box sx={styles.panel}>
							<Box sx={styles.panelTitle}>
								<TextBlock flat primary="Verkaufspreise" />
							</Box>
							<Box pt={2}>
								<SalesPercentilesChart reporting={reporting} />
							</Box>
						</Box>

						<Box sx={styles.panel}>
							<Box sx={styles.panelTitle}>
								<TextBlock flat primary="Verkäufe" />
							</Box>
							<Box pt={2}>
								<SalesCountChart reporting={reporting} />
							</Box>
						</Box>
					</>
				)}
			</TileContent>
		</Tile>
	);
};
