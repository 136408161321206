import { Address, badFallingBostelAddress, dorfmarkAddress } from "./Domain";

export const isDorfmarkAddress = (address: Address) =>
	address.street === dorfmarkAddress.street
	&& address.zipCode === dorfmarkAddress.zipCode
	&& address.city === dorfmarkAddress.city;

export const isBadFallingBostelAddress = (address: Address) =>
	address.street === badFallingBostelAddress.street
	&& address.zipCode == badFallingBostelAddress.zipCode
	&& address.city == badFallingBostelAddress.city;
