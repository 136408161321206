import { VehicleQuery } from "./useVehicleQuery";
import axios from "axios";
import fileDownload from "js-file-download";
import { useAsyncFn } from "react-use";
import { AsyncFnReturn } from "react-use/lib/useAsyncFn";
import parseContentDispositionFilename from "./parseContentDispositionFilename";

export default (query : VehicleQuery) : AsyncFnReturn<() => Promise<void>> =>
	useAsyncFn(async () => {
		const response = await axios.get(`/api/vehicles`, {
			responseType: "blob",
			params: {
				...query,
				format: "xlsx"
			}
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);
	}, [query]);
