import { Box, Grid } from "@mui/material";
import FormDateTimePicker from "../../../../Components/FormDateTimePicker";
import FormSelect from "../../../../Components/FormSelect";
import FormTextField from "../../../../Components/FormTextField";
import FormYesNo from "../../../../Components/FormYesNo";
import TextBlock from "../../../../Components/Layout/TextBlock";
import { Vehicle } from "../../../../system/Domain";
import { Form } from "../../../../system/useForm";
import useLogisticCompanies from "../../../../system/useLogisticCompanies";

interface Props {
	vehicle: Vehicle
	form: Form
}

const PoolfleetDelivery = ({ form }: Props) => {
	const [companies] = useLogisticCompanies();

	const canSetDeliveryTime = !!form.getValue('delivery.deliveryDate');

	return (
		<>
			<Grid item xs={12}></Grid>
			<Grid item xs={12} md={6} lg={4}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<TextBlock primary="Zielort" />
					</Grid>
					<Grid item xs={12}>
						<FormTextField
							form={form}
							fullWidth
							name="delivery.address.name"
							label="Name"
							required
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormTextField
							form={form}
							fullWidth
							name="delivery.address.street"
							label="Straße und Hausnummer"
							required
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormTextField
							form={form}
							required
							name="delivery.address.zipCode"
							maxLength={5}
							label="PLZ"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormTextField
							form={form}
							required
							name="delivery.address.city"
							label="Stadt"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormTextField
							form={form}
							required
							name="delivery.address.country"
							label="Land"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<Box mt={6}>
							<TextBlock primary="Ansprechpartner" />
						</Box>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormTextField
							form={form}
							required
							name="delivery.contact.firstName"
							label="Vorname"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormTextField
							form={form}
							required
							name="delivery.contact.lastName"
							label="Nachname"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormTextField
							form={form}
							required
							name="delivery.contact.email"
							label="E-Mail"
							fullWidth
							email
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormTextField
							required
							form={form}
							name="delivery.contact.telephone"
							label="Telefon"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12}>
						<Box mt={6}>
							<TextBlock primary="Zusatzinformationen" />
						</Box>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormDateTimePicker
							name="collection.collectionDate"
							form={form}
							label="Abholung"
							margin="normal"
							variant="date"
							format="DD.MM.YYYY"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormDateTimePicker
							name="delivery.deliveryDate"
							form={form}
							label="Lieferung"
							margin="normal"
							variant="date"
							format="DD.MM.YYYY"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormDateTimePicker
							name="collection.collectionTime"
							form={form}
							label="Abholzeit (optional)"
							margin="normal"
							variant="time"
							ampm={false}
							format="HH:mm"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormDateTimePicker
							name="delivery.deliveryTime"
							form={form}
							label="Lieferzeit (optional)"
							margin="normal"
							variant="time"
							ampm={false}
							format="HH:mm"
							disabled={!canSetDeliveryTime}
							fullWidth
						/>
					</Grid>
					<FormYesNo
						name="isDrivable"
						label="Ist das Fahrzeug fahrbereit?"
						form={form}
						options={{ required: true }}
					/>
					<Grid item xs={12}>
						<FormTextField
							form={form}
							fullWidth
							name="delivery.comment"
							label="Kommentar"
						/>
					</Grid>
					<Grid item xs={12}>
						<Box mt={6} mb={1}>
							<TextBlock primary="Logistikunternehmen" />
						</Box>
					</Grid>
					<Grid item xs={12}>
						<FormSelect
							name="delivery.company"
							form={form}
							entries={companies?.map(m => {
								return { choice: m.name, label: m.name }
							})}
							options={{ required: true }}
						/>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default PoolfleetDelivery;
