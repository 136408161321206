import { TableCell, TableRow, Tooltip } from "@mui/material";
interface PropertyProps {
	name: string
	value?: string | null | undefined
	tooltip?: string | null
}

const Property = ({ name, value, tooltip }: PropertyProps) => {

	return (
		<TableRow>
			<Tooltip title={tooltip || ""}>
				<TableCell sx={{
					root: {
						borderBottom: "none"
					}
				}}>
					{name}
				</TableCell>
			</Tooltip>
			<TableCell sx={{
				root: {
					borderBottom: "none"
				}
			}}>
				{value}
			</TableCell>
		</TableRow>
	)
};

export default Property;
