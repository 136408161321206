import axios from 'axios';

import { Description, Grading, Info, LocalShipping, Mail, Receipt } from '@mui/icons-material';
import BatteryChargingFull from '@mui/icons-material/BatteryChargingFull';
import {
	Avatar, Box, Card, CardContent, List, ListItem, ListItemAvatar, ListItemText, MenuItem,
	Typography
} from '@mui/material';

import ButtonMenu from '../../../Components/MenuButton';
import executeWithProgressDialog from '../../../Dialogs/executeWithProgressDialog';
import getFiles from '../../../Dialogs/getFiles';
import { Sale, SaleOrder, SalesDocumentType } from '../../../system/Domain';
import handleDownload from '../../../system/handleDownload';

interface Props {
	sale: Sale
	order?: SaleOrder
	onSaleChanged: (sale: Sale) => void
}

const saleDocumentTypes = {
	"CoverLetter": {
		icon: <Mail />,
		label: "Anschreiben",
		supportsUploading: false
	},
	"PreSalesInformation": {
		icon: <Info />,
		label: "Vorvertragliche Informationen",
		supportsUploading: false
	},
	"Order": {
		icon: <LocalShipping />,
		label: "Bestellung",
		supportsUploading: false
	},
	"PickupAttorney": {
		icon: <Receipt />,
		label: "Pickup Vollmacht",
		supportsUploading: false
	},
	"ShippingAttorney": {
		icon: <Receipt />,
		label: "Abholvollmacht",
		supportsUploading: false
	},
	"ShippingPickup": {
		icon: <Receipt />,
		label: "Pickup",
		supportsUploading: false
	},
	"ShippingForm": {
		icon: <Receipt />,
		label: "Übergabeprotokoll",
		supportsUploading: false
	},
	"IdentityCard": {
		icon: <Receipt />,
		label: "Ausweisdokument",
		supportsUploading: false
	},
	"BillOfLading": {
		icon: <Receipt />,
		label: "Frachtpapiere",
		supportsUploading: false
	},
	"PickupOrder": {
		icon: <LocalShipping />,
		label: "Pickup Order",
		supportsUploading: false
	},
	"PickupOrderSigned": {
		icon: <LocalShipping />,
		label: "Pickup Order unterzeichnet",
		supportsUploading: true
	},
	"VatDeposit": {
		icon: <Receipt />,
		label: "VAT Deposit",
		supportsUploading: true
	},
	"Invoice": {
		icon: <Receipt />,
		label: "Rechnung",
		supportsUploading: true
	},
	"RenaultBatteryHandoverProtocol": {
		icon: <BatteryChargingFull />,
		label: "Übergabeprotokoll von Batterien",
		supportsUploading: true
	},
	"RenaultBatteryHandoverProtocolSigned": {
		icon: <BatteryChargingFull />,
		label: "Übergabeprotokoll von Batterien unterzeichnet",
		supportsUploading: true
	},
	"CmrInstructions": {
		icon: <Grading />,
		label: "CMR Beispiel",
		supportsUploading: false
	},
	"Other": {
		icon: <Description />,
		label: "Sonstiges Dokument",
		supportsUploading: true
	}
}

export const getDocumentMetaData = (name: keyof typeof saleDocumentTypes) => {
	const result = saleDocumentTypes[name];
	if (result) {
		return result;
	}
	return saleDocumentTypes["Other"];
};

const SalesDocumentCard = ({ sale, order, onSaleChanged }: Props) => {
	let documents = sale.documents || [];
	if (sale && order) {
		documents = sale.documents.filter(d => d.orderId === order.id);
	}

	const uploadDocument = (documentType: SalesDocumentType) => async () => {
		const result = await getFiles("Dokument hochladen", {
			multiple: false
		});

		if (result.ok) {
			await executeWithProgressDialog(async progress => {
				let formData = new FormData();
				formData.append("file", result.files[0]);
				formData.append("documentType", documentType);

				const { data: newSale } = await axios.post<Sale>(`/api/sales/${sale.id}/documents`,
					formData, {
						headers: {
							'Content-Type': 'multipart/form-data'
						},
						onUploadProgress: evt => {
							const completed = Math.ceil((evt.loaded / evt.total) * 100);
							progress(completed);
						}
					}
				);

				onSaleChanged(newSale);
			});
		}
	};

	return (
		<Card>
			<CardContent>
				<Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
					<Typography variant="h6">Verkaufsdokumente</Typography>
					<Box>
						<ButtonMenu
							color="primary"
							label="Hinzufügen"
						>
							{Object.keys(saleDocumentTypes)
								.map(k => ({
									documentInfo: saleDocumentTypes[k as SalesDocumentType],
									documentType: k as SalesDocumentType,
								}))
								.filter(d => d.documentInfo.supportsUploading)
								.map(d => (
									<MenuItem
										key={d.documentType}
										onClick={uploadDocument(d.documentType)}
									>
										{d.documentInfo.label}
									</MenuItem>
								))}
						</ButtonMenu>
					</Box>
				</Box>
				<Box>
					{documents.length > 0 && (
						<List>
							{documents.map((d, i) => {
								return (
									<ListItem
										key={i}
										button
										dense
										onClick={handleDownload(`/api/sales/${sale.id}/documents/${d.file.hash}`)}
									>
										<ListItemAvatar>
											<Avatar variant="rounded">
												{saleDocumentTypes[d.documentType].icon}
											</Avatar>
										</ListItemAvatar>
										<ListItemText
											primary={saleDocumentTypes[d.documentType].label}
											secondary={d.file.filename}
										/>
									</ListItem>
								);
							})}
						</List>
					)}
					{documents.length === 0 && (
						<Box mt={1}>
							<Typography>Keine Dokumente vorhanden</Typography>
						</Box>
					)}
				</Box>
			</CardContent>
		</Card>
	);
};

export default SalesDocumentCard;
