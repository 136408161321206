import axios from 'axios';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import { Add, GetApp, Refresh, RotateLeft } from '@mui/icons-material';
import {
	CircularProgress, FormControl, FormControlLabel, InputLabel, MenuItem, Select
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import IndeterminateCheckbox from '../../../Components/IndeterminateCheckbox';
import Sidebar from '../../../Components/Sidebar/Sidebar';
import SidebarButton from '../../../Components/Sidebar/SidebarButton';
import SidebarForm from '../../../Components/Sidebar/SidebarForm';
import SidebarGroup from '../../../Components/Sidebar/SidebarGroup';
import Sort, { SortItem } from '../../../Components/Sort';
import {
	Compound,
	ReturnOrderSession,
	ReturnOrdersOverviewPerspective,
	ReturnOrderTireType,
	UserRole
} from '../../../system/Domain';
import parseContentDispositionFilename from '../../../system/parseContentDispositionFilename';
import useUser from '../../../system/useUser';
import { ReturnOrderDateFilter, ReturnOrdersOverviewQuery } from './ReturnOrdersDashboard';
import { translateReturnOrderTireType } from "../../../system/translate";

interface Props {
	query: ReturnOrdersOverviewQuery
	setQuery: Dispatch<SetStateAction<ReturnOrdersOverviewQuery>>
	resetQuery: () => ReturnOrdersOverviewQuery
	canProcess: boolean
	isProcessing: boolean
	isProcessingAll: boolean
	isProcessingExternalAssessments: boolean
	isAdding: boolean
	handleProcessExternalAssessments: () => Promise<void>
	handleNewSession: () => Promise<void>
	addToLastSession: () => Promise<void>
	refresh: () => {}
	activeSession?: ReturnOrderSession
	isLoading: boolean
	compounds: Compound[]
}

const sortItems: SortItem[] = [
	{
		title: "Erstellungsdatum",
		field: "dateCreated"
	},
	{
		title: "Postleitzahl - Abholung",
		field: "returnOrder.collection.address.zipCode"
	},
	{
		title: "Abholdatum",
		field: "returnOrder.collectionReferenceDate"
	},
	{
		title: "Wunschtermin",
		field: "returnOrder.collection.appointment.notBefore"
	}
];

const ReturnOrdersOverviewSidebar = (props: Props) => {
	const history = useHistory();
	const [, , hasRole] = useUser();

	const canProcess = !props.canProcess || props.isProcessing || props.isProcessingAll;

	const hasPermissionsToProcess = hasRole(UserRole.AldManager);

	const triggerExport = async () => {
		const response = await axios.get(`/api/orders/returns`, {
			responseType: "blob",
			params: {
				...props.query,
				output: "xlsx"
			}
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);
	};

	const triggerInvoiceControlExport = async () => {
		const response = await axios.get(`/api/orders/returns`, {
			responseType: "blob",
			params: {
				perspective: "All",
				sort: "dateProcessed:desc",
				output: "invoiceControl.xlsx"
			}
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);
	};

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarButton
					color="primary"
					startIcon={<Add />}
					label="Neue Rückholung"
					onClick={() => history.push("/orders/returns/new")}
				/>
				<SidebarButton
					color="primary"
					startIcon={<Add />}
					label="Neue Eigenanlieferung"
					onClick={() => history.push("/orders/returns/new", {
						collection: {
							collectionType: "CustomerDelivery"
						},
						delivery: {
							deliveryType: "CustomerDelivery"
						}
					})}
				/>
			</SidebarGroup>
			<SidebarGroup>
				{hasPermissionsToProcess && props.query.perspective === "UnprocessedExternalAssessments" && (
					<SidebarButton
						disabled={canProcess}
						color="primary"
						onClick={props.handleProcessExternalAssessments}
					>
						{props.isProcessingExternalAssessments && (
							<CircularProgress sx={{ marginRight: 2.5 }} size={16} />
						)} Externe Gutachten beauftragen
					</SidebarButton>
				)}
				{hasPermissionsToProcess && props.activeSession && props.query.perspective !== "UnprocessedExternalAssessments" && (
					<SidebarButton
						disabled={props.isLoading || props.isAdding}
						color="primary"
						onClick={() => history.push(`/orders/returns/sessions/${props.activeSession.id}`)}
					>
						{props.isProcessing && (
							<CircularProgress sx={{ marginRight: 2.5 }} size={16} />
						)} Zur aktiven Sitzung
					</SidebarButton>
				)}
				{hasPermissionsToProcess && !props.activeSession && props.query.perspective === "Unprocessed" && (
					<SidebarButton
						disabled={canProcess || props.isLoading || props.isAdding}
						color="primary"
						onClick={props.handleNewSession}
					>
						{props.isProcessing && (
							<CircularProgress sx={{ marginRight: 2.5 }} size={16} />
						)} Beauftragung starten
					</SidebarButton>
				)}
				{hasPermissionsToProcess && props.query.perspective === "Unprocessed" && (
					<SidebarButton
						disabled={canProcess || props.isLoading || !props.activeSession}
						color="primary"
						onClick={props.addToLastSession}
					>
						{props.isAdding && (
							<CircularProgress sx={{ marginRight: 2.5 }} size={16} />
						)} Hinzufügen
					</SidebarButton>
				)}
			</SidebarGroup>
			<SidebarGroup>
				<SidebarForm>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Perspektive</InputLabel>
						<Select
							value={props.query.perspective}
							onChange={(e) => props.setQuery(g => ({
								...g,
								skip: 0,
								perspective: e.target.value as ReturnOrdersOverviewPerspective
							}))}
						>
							<MenuItem value="All">Alle</MenuItem>
							<MenuItem value="Imported">In Bearbeitung</MenuItem>
							<MenuItem value="Assigned">Zugewiesen</MenuItem>
							<MenuItem value="Processed">Beauftragt</MenuItem>
							<MenuItem value="Unprocessed">Neu</MenuItem>
							<MenuItem value="Cancelled">Storniert</MenuItem>
							<MenuItem value="CustomerCreated">Kundenauftrag</MenuItem>
							<MenuItem value="CustomerDelivery">Eigenanlieferung</MenuItem>
							<MenuItem value="DeliveriesToForeignAddresses">Fremdlieferungen</MenuItem>
							<MenuItem value="UnprocessedExternalAssessments">Neu - externes Gutachten</MenuItem>
							<MenuItem value="Own">Von mir erstellt</MenuItem>
							<MenuItem value="Terminated">Ausgesetzt</MenuItem>
						</Select>
					</FormControl>
					<Sort
						items={sortItems}
						value={props.query.sort}
						onChange={(sort) => props.setQuery(g => ({
							...g,
							skip: 0,
							sort
						}))}
					/>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Anlieferungsstandort</InputLabel>
						<Select
							value={props.query.compound || "-"}
							onChange={(e) => {
								let value = e.target.value as string | undefined;

								if (value === "-") {
									value = undefined;
								}

								props.setQuery(g => ({
									...g,
									skip: 0,
									compound: value
								}))
							}}
						>
							<MenuItem value={"-"}>
								Alle
							</MenuItem>
							{props.compounds?.map(c =>
								<MenuItem value={c.name} key={c.name}>
									{c.name}
								</MenuItem>
							)}
						</Select>
					</FormControl>
					{(props.query.perspective === "Unprocessed" || props.query.perspective === "UnprocessedExternalAssessments") && (
						<FormControl variant="standard" fullWidth>
							<InputLabel>Bereifung</InputLabel>
							<Select
								multiple
								multiline
								value={props.query.tireTypes?.split(';') || []}
								renderValue={v => v?.map(translateReturnOrderTireType).join(", ")}
								onChange={(e) => {
									const value = e.target.value as string[];

									props.setQuery(q => ({
										...q,
										tireTypes: value?.length === 0 ? undefined : value.join(';')
									}));
								}}
							>
								{(Object.keys(ReturnOrderTireType) as Array<keyof typeof ReturnOrderTireType>).map(s => (
									<MenuItem value={s} key={s}>
										{translateReturnOrderTireType(s)}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
					{(props.query.perspective === "Unprocessed" || props.query.perspective === "Cancelled" || props.query.perspective === "UnprocessedExternalAssessments") && (
						<>
							<FormControl fullWidth>
								<FormControlLabel
									control={<IndeterminateCheckbox value={props.query.isDrivable} set={(newValue: boolean | undefined) => {
										props.setQuery(g => ({
											...g,
											isDrivable: newValue
										}));
									}} />}
									label="Fahrbereit"
								/>
							</FormControl>
							<FormControl fullWidth>
								<FormControlLabel
									control={<IndeterminateCheckbox value={props.query.isRegistered} set={(newValue: boolean | undefined) => {
										props.setQuery(g => ({
											...g,
											isRegistered: newValue
										}));
									}} />}
									label="Zugelassen"
								/>
							</FormControl>
							<FormControl fullWidth>
								<FormControlLabel
									control={<IndeterminateCheckbox value={props.query.hasValidMainInspection} set={(newValue: boolean | undefined) => {
										props.setQuery(g => ({
											...g,
											hasValidMainInspection: newValue
										}));
									}} />}
									label="Hu/Au gültig"
								/>
							</FormControl>
						</>
					)}
					{(props.query.perspective === "CustomerCreated") && (
						<FormControl fullWidth>
							<FormControlLabel
								control={<IndeterminateCheckbox
									value={props.query.customerOrderApproved}
									set={(e) => props.setQuery(g => ({
										...g,
										skip: 0,
										customerOrderApproved: e
									}))}
								/>}
								label="Kundenauftrag bestätigt"
							/>
						</FormControl>
					)}
				</SidebarForm>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarForm>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Datumsfilter</InputLabel>
						<Select
							value={props.query.dateFilter}
							onChange={(e) => props.setQuery(g => ({
								...g,
								dateFilter: e.target.value as ReturnOrderDateFilter
							}))}
						>
							<MenuItem value="DateCreated">Erstellungsdatum</MenuItem>
							<MenuItem value="CollectionReferenceDate">Abholdatum</MenuItem>
							<MenuItem value="DateCancelled">Stornierungsdatum</MenuItem>
						</Select>
					</FormControl>
					<FormControl fullWidth>
						<DatePicker
							format="DD.MM.YYYY"
							label="von"
							value={moment(props.query.dateFrom)}
							slotProps={{ textField: { variant: 'standard' } }}
							onChange={(d: any) => props.setQuery(q => ({
								...q,
								dateFrom: (d || moment()).startOf("d").toISOString(true)
							}))}
						/>
					</FormControl>
					<FormControl fullWidth>
						<DatePicker
							format="DD.MM.YYYY"
							label="bis"
							value={moment(props.query.dateTo)}
							slotProps={{ textField: { variant: 'standard' } }}
							onChange={(d: any) => props.setQuery(q => ({
								...q,
								dateTo: (d || moment()).startOf("d").toISOString(true)
							}))}
						/>
					</FormControl>
				</SidebarForm>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarButton
					variant="outlined"
					startIcon={<GetApp />}
					fullWidth
					disabled={props.isProcessing}
					onClick={triggerExport}
				>
					Excel-Export
				</SidebarButton>
				<SidebarButton
					variant="outlined"
					startIcon={<GetApp />}
					fullWidth
					disabled={props.isProcessing}
					onClick={triggerInvoiceControlExport}
				>
					Rechnungskontrolle
				</SidebarButton>
				<SidebarButton
					variant="outlined"
					startIcon={<RotateLeft />}
					fullWidth
					onClick={props.resetQuery}
				>
					Filter zurücksetzen
				</SidebarButton>
				<SidebarButton
					variant="outlined"
					startIcon={<Refresh />}
					fullWidth
					onClick={props.refresh}
				>
					Aktualisieren
				</SidebarButton>
			</SidebarGroup>
		</Sidebar>
	);
};

export default ReturnOrdersOverviewSidebar;
