import { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import moment from "moment";
import * as yup from "yup";
import ThemedDialog from "../../../Components/ThemedDialog";
import DateTimeRange from "../../../Components/DateTimeRange";
import useAsyncEffect from "../../../system/useAsyncEffect";
import axios from "axios";
import FormHelperText from "@mui/material/FormHelperText";
import { DateTimeRange as DateTimeRangeType, DateTimeRangeInterval, TransportOrder } from "../../../system/Domain";
import { dekra_capacity_afternoon, dekra_capacity_morning } from "../../Companies/DekraNewOrdersTile";

const schema = yup.object().shape({
	date: yup.date().required("Datum muss angegeben werden"),
	email: yup.string().email("Die eingegeben Email ist ungültig"),
	name: yup.string().required("Der Name des Ansprechpartners muss angegeben werden"),
});

interface AssignPickupDateResult {
	info: string;
	email: string | null;
	plannedReturnPeriod: DateTimeRangeType;
	date: string;
}

interface AssignPickupDateProps extends AssignPickupDateOptions, ReactConfirmProps<AssignPickupDateResult | null> {}

const AssignPickupDateDialog = ({ show, proceed, order }: AssignPickupDateProps) => {
	const [formState, setFormState] = useState({
		info: "",
		email: order.pickupContact.email || "",
		name: order.pickupContact.name || "",
		plannedReturnPeriod: {
			date: moment().add(2, "day").format("YYYY-MM-DD"),
			range: DateTimeRangeInterval.Morning,
		},
		date: moment().add(1, "day").startOf("hour").format("YYYY-MM-DDTHH:mm"),
	});

	const [errors, setErrors] = useState<any>({});
	const [numberOffAvailableReturnSlots, setNumberOffAvailableReturnSlots] = useState(0);

	const { plannedReturnPeriod } = formState;

	useAsyncEffect(async () => {
		if (!plannedReturnPeriod || !plannedReturnPeriod.date) {
			setNumberOffAvailableReturnSlots(0);
			return;
		}

		if (moment().isAfter(moment(plannedReturnPeriod.date))) {
			setNumberOffAvailableReturnSlots(0);
			return;
		}

		const { data: capacity } = await axios.get(
			`/api/returns/${moment(plannedReturnPeriod.date).format("YYYY-MM-DD")}`,
			{
				params: {
					compound: order.destinationCompound?.name,
				},
			},
		);

		if (!capacity.isWorkingDay) {
			setNumberOffAvailableReturnSlots(0);
			return;
		}

		switch (plannedReturnPeriod.range) {
			case DateTimeRangeInterval.Morning:
				setNumberOffAvailableReturnSlots(dekra_capacity_morning - capacity.morning);
				break;
			case DateTimeRangeInterval.Afternoon:
				setNumberOffAvailableReturnSlots(dekra_capacity_afternoon - capacity.afternoon);
				break;
			default:
				setNumberOffAvailableReturnSlots(0);
				break;
		}
	}, [plannedReturnPeriod]);

	const handleChange = (field: string) => (event: any) => {
		let fieldError;
		let newState = {
			...formState,
			[field]: event.target.value,
		};

		if (field === "date") {
			const pickupDate = moment(newState.date);

			if (pickupDate.isValid()) {
				if (!moment().isAfter(pickupDate)) {
					if (!moment(formState.date).isSame(pickupDate, "day")) {
						const plannedReturnDate = pickupDate.add(1, "days");
						newState = {
							...newState,
							plannedReturnPeriod: {
								date: plannedReturnDate.format("YYYY-MM-DD"),
								range: DateTimeRangeInterval.Morning,
							},
						};
					}
				} else {
					fieldError = "Datum liegt in der Vergangenheit";
				}
			}
		}

		setFormState(newState);
		setErrors({
			...errors,
			[field]: fieldError,
		});
	};

	const handleClose = () => {
		proceed(null);
	};

	const handleSave = async () => {
		try {
			await schema.validate(formState, { abortEarly: false });
		} catch (err) {
			setErrors(
				err.inner.reduce((p: any, c: any) => {
					p[c.path] = c.message;
					return p;
				}, {}),
			);
			return;
		}

		const { date, info, email, plannedReturnPeriod } = formState;

		const result: AssignPickupDateResult = {
			info,
			email: email === "" ? null : email,
			plannedReturnPeriod: {
				date: moment(plannedReturnPeriod.date).startOf("day").toISOString(true),
				range: plannedReturnPeriod.range,
			},
			date: moment(date).toISOString(true),
		};

		proceed(result);
	};

	return (
		<ThemedDialog open={show} fullWidth maxWidth="sm" onClose={handleClose}>
			<DialogTitle>Abholtermin vereinbaren</DialogTitle>
			<DialogContent>
				<form onSubmit={handleSave}>
					<TextField
						variant="outlined"
						label="Abholtermin"
						type="datetime-local"
						required
						fullWidth
						error={Boolean(errors.date)}
						helperText={errors.date}
						value={formState.date}
						onChange={handleChange("date")}
						autoFocus
						margin="normal"
					/>
					<TextField
						variant="outlined"
						label="Ansprechpartner"
						margin="normal"
						required
						fullWidth
						error={Boolean(errors.name)}
						helperText={errors.name}
						value={formState.name}
						onChange={handleChange("name")}
					/>
					<TextField
						variant="outlined"
						label="Kundenemail"
						required
						margin="normal"
						fullWidth
						error={Boolean(errors.email)}
						helperText={errors.email}
						value={formState.email}
						onChange={handleChange("email")}
					/>
					<DateTimeRange
						margin="normal"
						value={formState.plannedReturnPeriod}
						onChange={handleChange("plannedReturnPeriod")}
						required
						fullWidth
					/>
					{numberOffAvailableReturnSlots > 0 && (
						<FormHelperText>
							Es sind noch <span>{numberOffAvailableReturnSlots}</span> Rückgabeplätze frei
						</FormHelperText>
					)}
					{numberOffAvailableReturnSlots <= 0 && (
						<FormHelperText error>Es gibt keine freien Rückgabeplätze zu diesem Termin</FormHelperText>
					)}
					<TextField
						label="Bemerkung"
						multiline
						maxRows={4}
						rows={4}
						value={formState.info}
						onChange={handleChange("info")}
						margin="normal"
						fullWidth
						variant="outlined"
					/>
				</form>
			</DialogContent>
			<DialogActions style={{ justifyContent: "space-between", flexDirection: "row-reverse" }}>
				<DialogActions>
					<Button variant="outlined" color="secondary" onClick={handleClose} href="">
						Abbrechen
					</Button>
					<Button variant="outlined" color="primary" onClick={handleSave} href="">
						Speichern
					</Button>
				</DialogActions>
			</DialogActions>
		</ThemedDialog>
	);
};

interface AssignPickupDateOptions {
	order: TransportOrder;
}

export default (options: AssignPickupDateOptions) => {
	const dialog = confirmable((props) => <AssignPickupDateDialog {...props} {...options} />);
	return createConfirmation<AssignPickupDateResult | null>(dialog)();
};
