import { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';

import { Add, RotateLeft } from '@mui/icons-material';

import Sort, { SortItem } from 'Components/Sort';
import Sidebar from '../../Components/Sidebar/Sidebar';
import SidebarButton from '../../Components/Sidebar/SidebarButton';
import SidebarForm from '../../Components/Sidebar/SidebarForm';
import SidebarGroup from '../../Components/Sidebar/SidebarGroup';
import { WorkSessionQuery } from './WorkSessions';

export interface WorkSessionsSidebarProps {
	query: WorkSessionQuery
	setQuery: Dispatch<SetStateAction<WorkSessionQuery>>
	resetQuery: () => WorkSessionQuery
}

const sortItems: SortItem[] = [
	{
		title: "Erstellt",
		field: "dateCreated"
	}
];

export default ({ query, setQuery, resetQuery }: WorkSessionsSidebarProps) => {
	const history = useHistory();

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarButton
					color="primary"
					startIcon={<Add />}
					label="Neue Sitzung"
					onClick={() => history.push('/work-sessions/new')}
				/>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarForm>
					<Sort
						items={sortItems}
						value={query.sort}
						onChange={sort => setQuery(q => ({
							...q,
							skip: 0,
							sort
						}))}
					/>
				</SidebarForm>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarButton
					startIcon={<RotateLeft />}
					label="Filter zurücksetzen"
					onClick={resetQuery}
				/>
			</SidebarGroup>
		</Sidebar>
	);
}
