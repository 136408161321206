import { ChangeEvent, useEffect } from "react";
import { Form } from "../../../system/useForm";
import { VehicleAddresses, DeliveryType } from "../../../system/Domain";
import { translateReturnOrderDeliveryType } from "../../../system/translate";
import { FormControl, FormControlLabel, Radio, RadioGroup, useTheme } from "@mui/material";

interface Props {
	name: string;
	form: Form;
	aldAddressData: VehicleAddresses;
	required?: boolean;
}

const DeliveryRadioChoice = (props: Props) => {
	const theme = useTheme();
	useEffect(() => {
		props.form.register(props.name, { required: props.required ?? false });

		return () => {
			props.form.unregister(props.name);
		};
	}, [props.name]);

	const handleEntryTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
		const selectedValue = (event.target as HTMLInputElement).value;

		props.form.setValue(props.name, selectedValue);
		props.form.validate(props.name, selectedValue);
	};

	return (
		<FormControl component="fieldset" sx={!!props.form.errors[props.name] && { color: theme.palette.error.main }}>
			<RadioGroup
				row
				aria-label="position"
				name="position"
				defaultValue="top"
				value={props.form.getValue(props.name) ?? ""}
				onChange={handleEntryTypeChange}
			>
				<FormControlLabel
					value="UsedCars"
					control={
						<Radio
							sx={
								!!props.form.errors[props.name] && {
									color: theme.palette.error.main,
								}
							}
							color="primary"
						/>
					}
					label={translateReturnOrderDeliveryType(DeliveryType.UsedCars)}
					labelPlacement="end"
				/>
				{props.aldAddressData.isRkv && (
					<FormControlLabel
						value="RebuyerAddress"
						control={
							<Radio
								sx={
									!!props.form.errors[props.name] && {
										color: theme.palette.error.main,
									}
								}
								color="primary"
							/>
						}
						label={translateReturnOrderDeliveryType(DeliveryType.RebuyerAddress)}
						labelPlacement="end"
					/>
				)}
				<FormControlLabel
					value="Neuss"
					control={
						<Radio
							sx={
								!!props.form.errors[props.name] && {
									color: theme.palette.error.main,
								}
							}
							color="primary"
						/>
					}
					label="ATN Neuss"
					labelPlacement="end"
				/>
				<FormControlLabel
					value="CustomEntry"
					control={
						<Radio
							sx={
								!!props.form.errors[props.name] && {
									color: theme.palette.error.main,
								}
							}
							color="primary"
						/>
					}
					label={translateReturnOrderDeliveryType(DeliveryType.CustomEntry)}
					labelPlacement="end"
				/>
			</RadioGroup>
		</FormControl>
	);
};

export default DeliveryRadioChoice;
