import { Box } from "@mui/material";
import axios from "axios";
import Tile from "Components/Tiles/Tile";
import TileDivider from "Components/Tiles/TileDivider";
import moment from "moment";
import TextGroup from "Pages/VehicleSales/TextGroup";
import TextLine from "Pages/VehicleSales/TextLine";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import useVatHistory from "system/useVatHistory";
import ActionButton from "../../Components/ActionButton";
import CurrencyInput from "../../Components/CurrencyInput";
import TileActions from "../../Components/Tiles/TileActions";
import TileContent from "../../Components/Tiles/TileContent";
import { PricingRequest, UserRole } from "../../system/Domain";
import formatDateTimeWithSeconds from "../../system/formatDateTimeWithSeconds";
import useRunAction from "../../system/useRunAction";
import useUser from "../../system/useUser";

interface Props {
	request: PricingRequest
	setRequest: Dispatch<SetStateAction<PricingRequest | undefined>>
}

export default ({ request, setRequest }: Props) => {
	const [getNetAmount, getGrossAmount] = useVatHistory();

	const [netPrice, setNetPrice] = useState<number>(request.pricing?.price.netAmount);
	const [grossPrice, setGrossPrice] = useState<number>(request.pricing?.price.grossAmount);

	useEffect(() => {
		setNetPrice(request.pricing?.price.netAmount);
		setGrossPrice(request.pricing?.price.grossAmount);
	}, [request.pricing]);

	const vatDate = moment(request.datePurchase || request.dateCreated);

	useEffect(() => {
		if (!netPrice) {
			setGrossPrice(0);
			return;
		}

		setGrossPrice(
			getGrossAmount(vatDate, netPrice)
		);
	}, [netPrice])

	useEffect(() => {
		if (!grossPrice) {
			setNetPrice(0);
			return;
		}

		setNetPrice(
			getNetAmount(vatDate, grossPrice)
		);
	}, [grossPrice])

	const [user] = useUser();
	const [savingPricing, runAction] = useRunAction();

	const savePricing = () => runAction(async () => {
		if (!request) {
			return;
		}

		const { data } = await axios.put<PricingRequest>(`/api/pricing-requests/${request!.id}/pricing`, {
			price: {
				netAmount: netPrice,
				grossAmount: grossPrice
			}
		});

		setRequest(data);
	});

	const canSetPrice = request.status === "Requested" || request.status === "Pricing";

	if (!request.pricing && !canSetPrice) {
		return null;
	}

	return (
		<Tile title="Bepreisung" roles={[UserRole.SalesManager]}>
			<TileContent>
				<Box maxWidth={250}>
					<CurrencyInput
						label="Netto-Preis"
						value={netPrice}
						setValue={setNetPrice}
						disabled={!canSetPrice || request.pricing?.isConfirmed === true}
						fullWidth
						autoFocus
						variant="standard"
					/>
				</Box>
				<Box maxWidth={250} mt={3}>
					<CurrencyInput
						label="Brutto-Preis"
						value={grossPrice}
						setValue={setGrossPrice}
						disabled={!canSetPrice || request.pricing?.isConfirmed === true}
						fullWidth
						variant="standard"
					/>
				</Box>
				{request.pricing && (
					<>
						<TileDivider />
						<Box mt={0}>
							{request.pricing.isProposed && (
								<TextGroup title="Preisvorschlag">
									<TextLine label="Benutzer" value={request.pricing.user1Name} />
									<TextLine label="Zeitpunkt" value={formatDateTimeWithSeconds(request.pricing.dateProposed)} />
								</TextGroup>
							)}
							{request.pricing.isConfirmed && (
								<TextGroup title="Preisbestätigung">
									<TextLine label="Benutzer" value={request.pricing.user2Name} />
									<TextLine label="Zeitpunkt" value={formatDateTimeWithSeconds(request.pricing.dateConfirmed)} />
								</TextGroup>
							)}
						</Box>
					</>
				)}
			</TileContent>
			{(request.status === "Requested" || request.status === "Pricing") && (
				<TileActions>
					{(!request.pricing || !request.pricing.isProposed || request.pricing.price.netAmount !== netPrice) && (
						<ActionButton
							color="secondary"
							variant="text"
							disabled={savingPricing || !netPrice}
							onClick={savePricing}
						>
							Preis vorschlagen
						</ActionButton>
					)}
					{request.pricing && request.pricing.isProposed && !request.pricing.isConfirmed && request.pricing.user1Name !== user?.name && request.pricing.price.netAmount === netPrice && (
						<ActionButton
							color="primary"
							variant="text"
							disabled={savingPricing}
							onClick={savePricing}
						>
							Preis bestätigen
						</ActionButton>
					)}
				</TileActions>
			)}
		</Tile>
	);
}
