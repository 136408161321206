import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import Tile from '../../Components/Tiles/Tile';
import TileContent from '../../Components/Tiles/TileContent';
import { ReactNode, useCallback, useState } from 'react';
import Loading from 'Components/Loading';

interface ItemText {
	primary: string,
	secondary: string
}

interface Props {
	title: string
	items: any[]
	isLoading?: boolean
	itemIcon: ReactNode
	makeItemKey: (any) => string
	makeItemText: (any) => ItemText
	onItemClick: (any) => Promise<void>
}

const VehicleItemsTile = (props: Props) => {
	const [isClicking, setIsClicking] = useState(false);

	const itemClicked = useCallback(async (i) => {
		if (isClicking) {
			return;
		}

		setIsClicking(true);
		try {
			await props.onItemClick(i);
		}
		finally {
			setIsClicking(false);
		}
	}, [props.onItemClick]);

	return (
		<Tile title={props.title}>
			<TileContent>
				<Loading loading={props.isLoading || isClicking} />
				{props.items && (
					<List>
						{props.items.map(i => {
							const texts = props.makeItemText(i);

							return (
								<ListItem
									key={props.makeItemKey(i)}
									onClick={async () => await itemClicked(i)}
								>
									<ListItemAvatar>
										<Avatar>
											{props.itemIcon}
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										primary={texts.primary}
										secondary={texts.secondary}
									/>
								</ListItem>
							)
						})}
					</List>
				)}
			</TileContent>
		</Tile>
	);
};

export default VehicleItemsTile;
