import _ from "lodash";
import { useState } from "react";
import { TabPanel, TabPanelProps } from "@mui/lab";
import { AversiSession } from "./AversiSessionsPage";
import { Box, List, Paper, Typography } from "@mui/material";
import AversiSessionListItem from "./AversiSessionListItem";
import { useHistory } from "react-router";
import Filter from "./Filter";
import Actions from "../../Components/Actions";

interface Props extends TabPanelProps {
	aversiSession : AversiSession
}

const filters = {
	all: "Alle",
	errors: "Nur fehlerhafte",
	missingAttachment: "Begleitschreiben fehlt"
}

const AversiSessionItemsTab = ({ aversiSession, ...rest } : Props) => {
	const history = useHistory();
	const [filter, setFilter] = useState("all");

	const gotoDetails = (itemId : string) => {
		history.push(`/aversi/sessions/${aversiSession!.id}/details/${itemId}`);
	};

	const handleFilterChange = (filter: string) => {
		setFilter(filter);
	};

	let elements;
	switch (filter) {
		case "errors":
			elements = aversiSession.items
				.filter(i => {
					const item = aversiSession.reconciliations.find(r => r.id === i.id);
					return !item || item.status !== "Ok";
				});
			break;
		case "missingAttachment":
			elements = aversiSession.items
				.filter(i => {
					const item = aversiSession.reconciliations.find(r => r.id === i.id);
					return item && item.status === "MissingAttachment";
				});
			break;
		default:
			elements = aversiSession.items;
	}

	const items = _.groupBy(elements, "tenantName");

	return (
		<TabPanel value="items">
			<>
				<Actions>
					<Filter
						values={filters}
						selected={filter}
						onChange={handleFilterChange}
					/>
				</Actions>

				{Object.keys(items).length === 0 && (
					<Typography>Keine Daten gefunden</Typography>
				)}

				{Object.keys(items).map(d => {
					return (
						<div key={d}>
							<Typography>{d}</Typography>
							<Box my={2}>
								<Paper>
									<List disablePadding>
										{items[d].map(i => {
											return (
												<AversiSessionListItem
													key={i.id}
													item={i}
													reconciliationItem={aversiSession.reconciliations.find(r => r.id === i.id)}
													onClick={() => gotoDetails(i.id)}
												/>
											)
										})}
									</List>
								</Paper>
							</Box>
						</div>
					)
				})}
			</>
		</TabPanel>
	)
};

export default AversiSessionItemsTab;
