import LayoutNavigation from "../Components/Layout/LayoutNavigation";
import RootNavigationSection from "./RootNavigationSection";

export default () => {
	return (
		<LayoutNavigation>
			<RootNavigationSection />
		</LayoutNavigation>
	);
};
