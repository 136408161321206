import { Cancel, Create, Delete } from "@mui/icons-material";
import Sidebar from "Components/Sidebar/Sidebar";
import SidebarGroup from "Components/Sidebar/SidebarGroup";
import SidebarButton from "Components/Sidebar/SidebarButton";

interface Props {
	isEdit: boolean
	setEdit: () => void
	setView: () => void
	delete: () => void
}

const PricingPageSidebar = (props: Props) => {
	return (
		<Sidebar>
			<SidebarGroup>
				{props.isEdit && (
					<SidebarButton
						color="primary"
						startIcon={<Cancel />}
						label="Korrektur beenden"
						onClick={props.setView}
					/>
				)}
				{!props.isEdit && (
					<SidebarButton
						color="primary"
						startIcon={<Create />}
						label="Korrektur starten"
						onClick={props.setEdit}
					/>
				)}
			</SidebarGroup>
			<SidebarGroup>
				<SidebarButton
					color="secondary"
					startIcon={<Delete />}
					label="Löschen"
					onClick={props.delete}
				/>
			</SidebarGroup>
		</Sidebar>
	)
};

export default PricingPageSidebar;
