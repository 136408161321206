import { useState } from 'react';
import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";
import ThemedDialog from "../../Components/ThemedDialog";
import { Box, Button, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";

interface Props extends ChangeAdditionalInformationOptions, ReactConfirmProps<string> {
	show : boolean
}

const ChangeAdditionalInformationDialog = (props : Props) => {
	const [text,setText] = useState(props.text);

	const handleClose = () => {
		props.cancel();
	};

	return (
		<ThemedDialog open={props.show} maxWidth="sm" fullWidth onClose={handleClose}>
			<DialogTitle>
				Zusatzinformationen
			</DialogTitle>
			<DialogContent>
				<Box mb={2}>
					<TextField
						fullWidth
						variant="outlined"
						value={text}
						label="Zusatzinformationen"
						onChange={(e) => setText(e.target.value)}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="secondary"
					href=""
					onClick={handleClose}
				>
					Abbrechen
				</Button>
				<Button
					variant="outlined"
					color="primary"
					href=""
					onClick={() => props.proceed(text)}
				>
					Ok
				</Button>
			</DialogActions>
		</ThemedDialog>
	)
};

interface ChangeAdditionalInformationOptions {
	text : string
}

export default (options:ChangeAdditionalInformationOptions) => {
	const dialog = confirmable(props => <ChangeAdditionalInformationDialog {...props} {...options} />);
	return createConfirmation<string>(dialog)();
};
