import { DependencyList, useEffect, useState } from "react";

export interface UseArraySyncOptions<T, K> {
	isReady : () => boolean
	onAdd : (item : T) => K
	onRemove : (internalItem : K) => void
	onUpdate : (item : T, internalItem : K) => void
};

export default <T, K>(items? : T[], options? : UseArraySyncOptions<T, K>, deps? : DependencyList) => {
	const [internalItems, setInternalItems] = useState<K[]>([]);

	useEffect(() => {
		if (!options || !items || (options.isReady && !options.isReady())) {
			return;
		}

		let newInternalItems = internalItems;
		let updateRange = items.length;

		if (items.length > internalItems.length) {
			newInternalItems = [...internalItems];

			const newItems = items.length - internalItems.length;
			for (let i = 0; i < newItems; i++) {
				const internalItem = options.onAdd(items[i]);
				newInternalItems.push(internalItem);
			}
			updateRange = internalItems.length;
		} else if (items.length < internalItems.length) {
			for (let i = items.length; i < internalItems.length; i++) {
				options.onRemove(newInternalItems[i]);
			}
			newInternalItems = newInternalItems.slice(0, items.length);
		}

		for (let i = 0; i < updateRange; i++) {
			options.onUpdate(items[i], internalItems[i]);
		}

		setInternalItems(newInternalItems);
	}, !!deps ? [items, internalItems, ...deps] : [items, internalItems]);

	return null;
};
