import axios from "axios";
import moment from "moment";
import { useAsync } from "react-use";
import { useEffect, useState } from "react";
import fileDownload from "js-file-download";
import Actions from "../../Components/Actions";
import { AttachFile } from '@mui/icons-material';
import Layout from "../../Components/Layout/Layout";
import { useHistory, useParams } from "react-router";
import formatCurrency from "../../system/formatCurrency";
import AversiSessionDetailRow from "./AversiSessionDetailRow";
import parseContentDispositionFilename from "../../system/parseContentDispositionFilename";
import { Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { AversiAttachment, AversiSession, AversiSessionDetail, AversiSessionReconciliationItem } from "./AversiSessionsPage";


interface AversiDetail {
	insuranceId: number
	tenantName: string
	insuranceName: string
	item: AversiSessionDetail | null
	attachment: AversiAttachment | null
	reconciliationItem: AversiSessionReconciliationItem | null
}

const AversiSessionInsurancePage = () => {
	const { id, itemId } = useParams<{ id: string, itemId: string }>();
	const [aversiSession, setAversiSession] = useState<AversiSession | null>(null);

	const [details, setDetails] = useState<AversiDetail | null>(null);

	const [busy, setBusy] = useState(false);
	const history = useHistory();

	useAsync(async () => {
		if (!id) {
			return null;
		}
		setBusy(true);

		const { data: aversiSession } = await axios.get<AversiSession>(`/api/admin/aversi/sessions/${id}`);
		setAversiSession(aversiSession);

		setBusy(false);
	}, [id]);

	useEffect(() => {
		if (!itemId || !aversiSession) {
			setDetails(null);
			return;
		}

		// reconciliation item is always present..
		const reconciliationItem = aversiSession.reconciliations.find(i => i.id === itemId)!!;

		setDetails({
			insuranceId: reconciliationItem.insuranceId,
			tenantName: reconciliationItem.tenantName,
			insuranceName: reconciliationItem.insuranceName,
			item: aversiSession.items.find(i => i.id === itemId) || null,
			attachment: aversiSession.attachments.find(i => i.id === itemId) || null,
			reconciliationItem
		});
	}, [aversiSession, itemId]);

	const handleRowClick = (type: string) => () => {
		history.push(`/aversi/lines?aversiSessionId=${id}&tenantName=${details?.tenantName}&insuranceId=${details?.insuranceId}&type=${type}`);
	};

	const handleDownloadAttachment = async () => {
		const response = await axios.get(`/api/admin/aversi/sessions/${id}/attachments/${itemId}`, {
			responseType: "blob"
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);
	};

	const item = details?.item;

	return (
		<Layout loading={busy}>
			{aversiSession && details && (
				<Box my={2}>
					<Typography variant="h3">Aversi Abrechnung {moment(aversiSession.startDate).format("MMMM YYYY")}</Typography>
					{details.insuranceId !== 0 && <Typography variant="body1">{details.tenantName}: {details.insuranceId} {details.insuranceName}</Typography>}
					{details.insuranceId === 0 && <Typography variant="body1">Gesamtauswertung</Typography>}
				</Box>
			)}

			<Box mb={2}>
				<Actions>
					<IconButton onClick={handleDownloadAttachment}>
						<AttachFile />
					</IconButton>
				</Actions>
			</Box>

			{item && (
				<Box>
					<Table size="small">
						<colgroup>
							<col width="20%" />
							<col width="auto" />
							<col width="auto" />
							<col width="auto" />
						</colgroup>

						<TableHead>
							<TableRow>
								<TableCell />
								<TableCell align="right">Gesamt</TableCell>
								<TableCell align="right">Begleitschreiben</TableCell>
								<TableCell align="right">Haftpflicht</TableCell>
								<TableCell align="right">Kasko</TableCell>
								<TableCell align="right">SONST</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<AversiSessionDetailRow
								sx={{
									"& td": {
										borderBottom: "none"
									}
								}}
								type="Bestand"
								products={item.inventory}
								onClick={handleRowClick("Bestand")}
							/>
							<AversiSessionDetailRow
								sx={{
									"& td": {
										borderBottom: "none"
									}
								}}
								type="Zugänge"
								products={item.incoming}
								onClick={handleRowClick("Zugänge")}
							/>
							<AversiSessionDetailRow
								sx={{
									"& td": {
										borderBottom: "none"
									}
								}}
								type="Zugänge manuell"
								products={item.incomingManual}
								onClick={handleRowClick("ZugangManuell")}
							/>
							<AversiSessionDetailRow
								sx={{
									"& td": {
										borderBottom: "none"
									}
								}}
								type="Abgänge"
								products={item.outgoing}
								onClick={handleRowClick("Abgänge")}
							/>
							<AversiSessionDetailRow
								sx={{
									"& td": {
										borderBottom: "none"
									}
								}}
								type="Abgänge Manuell"
								products={item.outgoingManual}
								onClick={handleRowClick("AbgangManuell")}
							/>
							<AversiSessionDetailRow
								sx={{
									"& td": {
										borderBottom: "none"
									}
								}}
								type="Sonstiges"
								products={item.misc}
								onClick={handleRowClick("Sonstiges")}
							/>
							<TableRow sx={{
								"& td": {
									borderBottom: "none",
									fontWeight: 700
								}
							}}>
								<TableCell>Gesamt</TableCell>
								<TableCell align="right">{formatCurrency(item.totalAmount)}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</Box>
			)}
		</Layout>
	)
};

export default AversiSessionInsurancePage;
