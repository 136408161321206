import { Divider, useTheme } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props {
	className?: string
	dense?: boolean
}

export default ({ children, dense }: PropsWithChildren<Props>) => {
	const theme = useTheme();

	return (
		<Divider
			sx={(dense && {
				marginLeft: theme.spacing(-2),
				marginRight: theme.spacing(-2)
			})}
		>
			{children}
		</Divider>
	);
};
