import MenuItem from 'Components/Layout/MenuItem';

import { AssignmentTurnedIn, Explore } from '@mui/icons-material';

import { UserRole } from '../../system/Domain';
import { BillingProps } from './useBilling';

export default ({ billing }: BillingProps) => {

	return (
		<>
			{/*<MenuItem*/}
			{/*	// icon={<KeyboardReturnIcon />}*/}
			{/*	// icon={<LaunchIcon />}*/}
			{/*	icon={<ReplayIcon />}*/}
			{/*	onClick={() => history.push("/billings")}*/}
			{/*	variant="secondary"*/}
			{/*/>*/}
			<MenuItem
				roles={[UserRole.AldManager, UserRole.ServiceAccounting]}
				icon={<Explore />}
				name="Übersicht"
				path={`/billings/${billing.id}`}
				matchProps={{
					exact: true,
					path: [
						`/billings/${billing.id}`,
						`/billings/${billing.id}/clients/:client`
					]
				}}
				variant="secondary"
			/>
			{billing.status !== "Created" && (
				<MenuItem
					roles={[UserRole.AldManager, UserRole.ServiceAccounting]}
					icon={<AssignmentTurnedIn />}
					name="Tätigkeiten"
					path={`/billings/${billing.id}/services`}
					variant="secondary"
				/>
			)}
		</>
	);
};
