import axios from "axios";
import SidebarButton from "../../../Components/Sidebar/SidebarButton";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import SidebarGroup from "../../../Components/Sidebar/SidebarGroup";
import fileDownload from "js-file-download";
import { Explore, GetApp, Print } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import parseContentDispositionFilename from "system/parseContentDispositionFilename";

interface Props {
	vehicleId: string;
	orderId: string;
	allowServicesDownload?: boolean;
	allowProtocolDownload?: boolean;
}

export default ({ vehicleId, orderId, allowServicesDownload = false, allowProtocolDownload = false }: Props) => {
	const history = useHistory();

	const downloadVehicleQrCode = async () => {
		const vehicleQrCodeFile = await axios.get(`/api/vehicles/${vehicleId}/qr-code`, {
			responseType: "blob"
		});

		const filename = parseContentDispositionFilename(vehicleQrCodeFile.headers["content-disposition"]);

		fileDownload(vehicleQrCodeFile.data, filename);
	};

	const downloadServicesQrCodesFile = async () => {
		const servicesQrCodesFile = await axios.get(`/api/vehicles/${vehicleId}/services/qr-code`, {
			responseType: "blob"
		});

		const filename = parseContentDispositionFilename(servicesQrCodesFile.headers["content-disposition"]);

		fileDownload(servicesQrCodesFile.data, filename);
	};

	const downloadProtocol = async () => {
		const response = await axios.get(`/api/orders/transports/${orderId}/protocol`, {
			responseType: "blob",
		});

		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
		fileDownload(response.data, filename);
	}

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarButton
					startIcon={<Print />}
					onClick={downloadVehicleQrCode}
					label="QR Code drucken"
				/>
				<SidebarButton
					startIcon={<Print />}
					onClick={downloadServicesQrCodesFile}
					label="Tätigkeiten drucken"
					disabled={!allowServicesDownload}
				/>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarButton
					startIcon={<Explore />}
					onClick={() => history.push(`/orders/transports/${orderId}`)}
					label="Zum Auftrag"
					disabled={!orderId}
				/>
				<SidebarButton
					startIcon={<Explore />}
					onClick={() => history.push(`/vehicles/${vehicleId}`)}
					label="Zur Platzlogistik"
				/>
			</SidebarGroup>
			{allowProtocolDownload && (
				<SidebarGroup>
					<SidebarButton
						startIcon={<GetApp />}
						onClick={downloadProtocol}
						label="Rücknahmeprotokoll herunterladen"
					/>
				</SidebarGroup>
			)}

		</Sidebar>
	);
};
