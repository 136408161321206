import Chart, { ChartProps } from "../../Components/Chart";
import { useAsync } from "react-use";
import moment from "moment";
import axios from "axios";
import { Statistic } from "../../system/Domain";

export default (props: Partial<ChartProps>) => {

	const chartData = useAsync(async () => {
		const endOfThisMonth = moment().endOf("month");
		const beginningOfThisMonth = moment().startOf("month").subtract(1, "month");

		const response = await axios.post<Array<Statistic>>("/api/statistics", {
			dateFrom: beginningOfThisMonth.format(moment.HTML5_FMT.DATE),
			dateTo: endOfThisMonth.format(moment.HTML5_FMT.DATE)
		});

		const ingoingSeries = {
			name: "ingoing",
			title: "Fahrzeugeingänge in Dorfmark",
			data: response.data.map(s => ({
				x: s.date,
				y: s.ingoingVehiclesHistoryEntries
			}))
		};

		const outgoingSeries = {
			name: "outgoing",
			title: "Fahrzeugausgänge in Dorfmark",
			data: response.data.map(s => ({
				x: s.date,
				y: s.outgoingVehiclesHistoryEntries
			}))
		};

		const differenceSeries = {
			name: "differences",
			title: "Bilanz",
			data: response.data.map(s => ({
				x: s.date,
				y: s.ingoingVehiclesHistoryEntries - s.outgoingVehiclesHistoryEntries
			}))
		};

		return [ingoingSeries, outgoingSeries, differenceSeries];
	}, []);

	return (
		<Chart
			{...props}
			loading={chartData.loading}
			series={chartData.value || []}
		/>
	)
}
