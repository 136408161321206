import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useAsync } from 'react-use';

import { TabContext } from '@mui/lab';
import { Box, Button, MenuItem, Tab, Tabs, Typography } from '@mui/material';

import Actions from '../../Components/Actions';
import HeaderMenu from '../../Components/Layout/HeaderMenu';
import Layout from '../../Components/Layout/Layout';
import ask from '../../Dialogs/ask';
import formatCurrency from '../../system/formatCurrency';
import handleDownload from '../../system/handleDownload';
import AversiInvoiceTab from './AversiInvoiceTab';
import AversiSessionAttachmentsTab from './AversiSessionAttachmentsTab';
import AversiSessionItemsTab from './AversiSessionItemsTab';
import AversiSessionPaymentTab from './AversiSessionPaymentTab';
import { AversiSession } from './AversiSessionsPage';

const AversiSessionPage = () => {
	const { id } = useParams<{ id : string }>();
	const history = useHistory();
	const [aversiSession, setAversiSession] = useState<AversiSession | null>(null);
	const [tab, setTab] = useState("items");
	const [busy, setBusy] = useState(false);

	useAsync(async () => {
		if (!id) {
			setAversiSession(null);
			return;
		}

		setBusy(true);
		const { data } = await axios.get<AversiSession>(`/api/admin/aversi/sessions/${id}`);
		setAversiSession(data);
		setBusy(false);
	}, [id]);

	const handleDelete = async () => {
		const answer = await ask("Soll die Aversi Meldung wirklich gelöscht werden?");

		if (answer.yes) {
			setBusy(true);
			await axios.delete(`/api/admin/aversi/sessions/${aversiSession!.id}`);
			history.push(`/aversi/sessions`);
		}
	};

	const addFiles = async (files : File[]) => {
		if (files.length === 0) {
			return;
		}

		let formData = new FormData();

		files.forEach(f => formData.append("files", f));
		const { data } = await axios.post<AversiSession>(`/api/admin/aversi/sessions/${id}`,
			formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		);
		setAversiSession(data);
	};

	const handleDropFiles = useCallback(async (files : File[]) => {
		setBusy(true);

		await addFiles(files);

		setBusy(false);
	}, [id]);

	const handleCompleteSession = async () => {
		const answer = await ask("Soll die Aversi Meldung wirklich abgeschlossen werden?");

		if (answer.yes) {
			setBusy(true);
			const { data: jobId } = await axios.post<AversiSession>(`/api/admin/aversi/sessions/${aversiSession!.id}/complete`);
			history.push(`/admin/jobs/${jobId}`);
		}
	};

	const handleReopenSession = async () => {
		setBusy(true);
		const { data: session } = await axios.post<AversiSession>(`/api/admin/aversi/sessions/${aversiSession!.id}/reopen`);
		setBusy(false);
		setAversiSession(session);
	};

	const canComplete = !Boolean(aversiSession?.dateCompleted);
	const canDelete = !Boolean(aversiSession?.dateCompleted);
	const isCompleted = Boolean(aversiSession?.dateCompleted);

	return (
		<Layout onDropFiles={handleDropFiles} loading={busy}>
			{!busy && aversiSession && (
				<Box my={2}>
					<Box>
						<Typography variant="h3">Aversi Abrechnung {moment(aversiSession.startDate).format("MMMM YYYY")}</Typography>
						<Typography variant="body1">
							<span>
								{formatCurrency(_.sum(aversiSession.items.map(i => i.totalAmount)))}
							</span>
							<span> · </span>
							<span>
								{aversiSession.numberOfInsuranceCompanies} Versicherer
							</span>
						</Typography>
					</Box>
					<Box>
						<Actions>
							<HeaderMenu>
								<MenuItem
									onClick={handleDownload(`/api/admin/aversi/sessions/${aversiSession.id}.xlsx`)}
								>
									Aversi Bestandsliste herunterladen
								</MenuItem>
								<MenuItem
									onClick={handleDownload(`/api/admin/aversi/sessions/${aversiSession.id}/attachments.zip`)}
									disabled={!isCompleted}
								>
									Begleitschreiben herunterladen
								</MenuItem>
								<MenuItem
									onClick={handleDownload(`/api/admin/aversi/sessions/${aversiSession.id}/invoice.xlsx`)}
									disabled={!isCompleted}
								>
									CarPool Ausgangsrechnungen herunterladen
								</MenuItem>
							</HeaderMenu>
							{isCompleted && (
								<Button variant="outlined" color="secondary" onClick={handleReopenSession}>
									Öffnen
								</Button>
							)}
							{canDelete && (
								<Button variant="outlined" color="secondary" onClick={handleDelete}>
									Löschen
								</Button>
							)}
							{canComplete && (
								<Button variant="outlined" color="secondary" onClick={handleCompleteSession}>
									Abschliessen
								</Button>
							)}
						</Actions>
					</Box>
				</Box>
			)}

			{aversiSession && (
				<Box>
					<TabContext value={tab}>
						<Tabs value={tab} onChange={(event, value) => setTab(value)}>
							<Tab value="items" label="Daten"/>
							<Tab value="attachments" label="Begleitschreiben"/>
							<Tab value="payments" label="Zahlungen"/>
							<Tab value="invoices" label="Ausgangsrechnungen"/>
						</Tabs>
						<AversiSessionItemsTab aversiSession={aversiSession} value="items"/>
						<AversiSessionAttachmentsTab aversiSession={aversiSession} value="attachments"/>
						<AversiSessionPaymentTab aversiSession={aversiSession} value="payments"/>
						<AversiInvoiceTab aversiSession={aversiSession} value="invoices"/>
					</TabContext>
				</Box>
			)}
		</Layout>
	)
};

export default AversiSessionPage;
