import { ThumbDown, ThumbUp } from "@mui/icons-material";
import ListItemAction from "Components/ListItemAction";
import executeWithProgressDialog from "Dialogs/executeWithProgressDialog";
import getFiles from "Dialogs/getFiles";
import axios from "axios";
import { RepairOrder } from "system/Domain";

interface Props {
	order: RepairOrder

	setOrder?(order: RepairOrder): void
}

export type CostEstimateAction = "approve" | "reject";

export default ({ order, setOrder }: Props) => {
	const performCostEstimateAction = (action: CostEstimateAction) => {
		return async () => {
			const result = await getFiles(`KVA ${action === "approve" ? "annehmen" : "ablehnen"} - ${order.vehicle.plateNumber} - ${order.vin}`);
			if (result.ok) {
				let formData = new FormData();
				formData.append("file", result.files[0]);

				await executeWithProgressDialog(async progress => {
					const { data } = await axios.post<RepairOrder>(`/api/orders/repairs/${order.id}/cost-estimate/${action}`,
						formData, {
						headers: {
							'Content-Type': 'multipart/form-data'
						},
						onUploadProgress: evt => {
							const completed = Math.ceil((evt.loaded / evt.total) * 100);
							progress(completed);
						}
					});

					if (setOrder) {
						setOrder(data);
					}
				});
			}
		};
	};

	return (
		<>
			<ListItemAction
				tooltip='KVA annehmen'
				icon={<ThumbUp />}
				onClick={performCostEstimateAction("approve")}
			/>
			<ListItemAction
				tooltip='KVA ablehnen'
				icon={<ThumbDown />}
				onClick={performCostEstimateAction("reject")}
			/>
		</>
	);
};
