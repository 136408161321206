import FormYesNo from "../../../../Components/FormYesNo";
import { Form } from "../../../../system/useForm";

export default ({ form } : { form : Form }) => {
	return (
		<>
			<FormYesNo
				name="accessories.tireFit"
				label="TireFit / Kompressor"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormYesNo
				name="accessories.jack"
				label="Wagenheber"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormYesNo
				name="accessories.warningTriangle"
				label="Warndreieck"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormYesNo
				name="accessories.roofRack"
				label="Dachgepäckträger"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormYesNo
				name="accessories.bootCover"
				label="Gepäckraumabdeckung"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormYesNo
				name="accessories.firstAidKit"
				label="Verbandskasten"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormYesNo
				name="accessories.toolKit"
				label="Bordwerkzeug"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormYesNo
				name="accessories.antenna"
				label="Antenne"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormYesNo
				name="accessories.loadSecuringNet"
				label="Ladungssicherungsnetz"
				form={form}
				options={{
					required: true
				}}
			/>
			<FormYesNo
				name="accessories.chargingCable"
				label="Ladekabel"
				form={form}
				options={{
					required: true
				}}
			/>
		</>
	);
};
