import moment from "moment";
import useUser from "../../../system/useUser";
import dateFormats from "../../../system/dateFormats";
import TextBlock from "../../../Components/Layout/TextBlock";
import { Note, TransportOrder, UserRole } from "../../../system/Domain";
import { Box, Collapse, useTheme } from "@mui/material";
import { useLayoutEffect, useRef, useState } from "react";

export interface OrderNoteListProps {
	order: TransportOrder
	displayAll?: boolean
}

interface TypedNote extends Note {
	isInternal: boolean
}

export default ({ order, displayAll = null }: OrderNoteListProps) => {
	const theme = useTheme();
	const [, , hasRole] = useUser();
	const canSeeInternalNotes = hasRole(UserRole.AldUser);
	const notesRefs = useRef([]);
	const [tableHeight, setTableHeight] = useState(0);
	const [columnWidth, setColumnWidth] = useState(0);

	if (!order.notes || (canSeeInternalNotes && !order.internalNotes)) {
		return null;
	}

	const setInternal = (note: Note, isInternal: boolean): TypedNote => {
		let t = note as TypedNote;
		t.isInternal = isInternal;
		return t;
	};

	let allNotes: TypedNote[] = order.notes.map(m => setInternal(m, false));
	if (canSeeInternalNotes && order.internalNotes) {
		allNotes = allNotes.concat(order.internalNotes.map(m => setInternal(m, true)));
	}
	const sortedNotes = allNotes.sort((a, b) => moment(a.dateCreated).valueOf() - moment(b.dateCreated).valueOf());

	useLayoutEffect(() => {
		if (notesRefs) {
			const firstTwoNotesHeights = (notesRefs?.current[0]?.offsetHeight ?? 0) + (notesRefs?.current[1]?.offsetHeight ?? 0);
			setTableHeight(firstTwoNotesHeights);

			const widths = notesRefs.current.map(n => n.firstElementChild.firstElementChild.firstElementChild.offsetWidth);
			const maxWidth = Math.max(...widths);
			setColumnWidth(maxWidth);
		}
	  }, [order.notes]);
	
	return (
		<Collapse in={displayAll} collapsedSize={tableHeight} sx={{overflowY: "auto"}}>
			<Box sx={{
				display: "flex", flexDirection: "column", marginLeft: 1.25
			}}>
				{sortedNotes.map((n, i) => {
					return (
						<Box key={i} ref={(el) => (notesRefs.current[i] = el)}
							sx={{ padding: 1.75, borderBottom: "1px solid rgba(81, 81, 81, 1)" }}>
							<Box sx={{ display: "flex", gap: 5 }}>
								<Box sx={{ minWidth: columnWidth }}>
									<TextBlock
										primary={moment(n.dateCreated).format(dateFormats.dateTime)}
										secondary={n.user.name}
										nowrap
										reduced
									/>
								</Box>
								<TextBlock
									reduced
									primary={<Box fontStyle={n.isInternal ? "italic" : "initial"} color={n.isInternal ? theme.palette.secondary.light : undefined}>{n.text}</Box>}
								/>
							</Box>
						</Box>
					);
				})}
			</Box>
		</Collapse>
	);
};
