import { BillingProps } from "./useBilling";
import formatNumber from "../../system/formatNumber";
import formatDate from "../../system/formatDate";
import { Box, Typography } from "@mui/material";
import BillingStatus from "./BillingStatus";
import useBillingsPermissions from "./useBillingPermissions";

export default ({ billing } : BillingProps) => {
	const permissions = useBillingsPermissions();

	return (
		<Box>
			<Box mb={0.5}>
				<Typography variant="h4">
					{formatDate(billing.dateFrom)} - {formatDate(billing.dateTo)}
				</Typography>
			</Box>
			<Box mb={1}>
				<Typography variant="body1">
					{!permissions.isRestrictedToHisCompany && (
						<span>{billing.company.name} · </span>
					)}
					<span>Abrechnung Nr. {formatNumber(billing.nr)}</span>

				</Typography>
			</Box>
			<BillingStatus billing={billing} showDate={false} />
		</Box>
	);
}
