import { Billing } from "../../system/Domain";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import getFiles from "../../Dialogs/getFiles";
import executeWithProgressDialog from "../../Dialogs/executeWithProgressDialog";
import axios from "axios";
import useBillingPermissions from "./useBillingPermissions";

export default () => {
	const [isUploading, setIsUploading] = useState(false);
	const permissions = useBillingPermissions();

	const upload = useCallback(async (billing : Billing, client : string, setBilling : Dispatch<SetStateAction<Billing | undefined>>) => {
		if (!permissions.canUploadInvoices(billing)) {
			throw new Error("Rechnungsupload ist in diesem Status nicht möglich");
		}

		try {
			setIsUploading(true);

			const result = await getFiles("Rechnung hochladen", { multiple: true });
			if (!result.ok) {
				return;
			}

			for (const file of result.files) {
				await executeWithProgressDialog(async progress => {
					const formData = new FormData();
					formData.append("file", file);
					formData.append("client", client);

					const { data } = await axios.post<Billing>(`/api/billings/${billing.id}/invoices`,
						formData, {
							headers: {
								"Content-Type": "multipart/form-data"
							},
							onUploadProgress: evt => {
								const completed = Math.ceil((evt.loaded / evt.total) * 100);
								progress(completed);
							}
						}
					);

					setBilling(data);
				});
			}
		} finally {
			setIsUploading(false);
		}
	}, []);

	return [upload, isUploading] as const;
};
