import { Box, Grid } from "@mui/material";
import axios from "axios";
import FluentGrid from "Components/FluentGrid";
import SalesNavigation from "Navigation/SalesNavigation";
import { Dispatch, SetStateAction, useState } from "react";
import DashboardKpi from "../../Components/Dashboard/DashboardKpi";
import Layout from "../../Components/Layout/Layout";
import Tile from "../../Components/Tiles/Tile";
import TileContent from "../../Components/Tiles/TileContent";
import TileContentAction from "../../Components/Tiles/TileContentAction";
import TileContentActions from "../../Components/Tiles/TileContentActions";
import { BatchFile, BatchFilePricingTags, BatchFileType, Sale } from "../../system/Domain";
import formatNumber from "../../system/formatNumber";
import useAction from "../../system/useAction";
import useAxiosEffect from "../../system/useAxiosEffect";
import useChannel from "../../system/useChannel";
import useEvent from "../../system/useEvent";
import BatchFilesTile from "./BatchFilesTile";

export interface SaleProps {
	sale: Sale;
}

export interface SalePropsWithSetter extends SaleProps {
	setSale: Dispatch<SetStateAction<Sale | undefined>>;
}

interface PricingTagsKpis {
	availablePricingTags: {
		dorfmarkNew: number;
		norderstedtNew: number;
		dorfmarkUpdated: number;
		norderstedtUpdated: number;
	};
}

const onPricingTagsBatchDelete = async (batchFile: BatchFile) => {
	await axios.delete(`/api/sales/pricing-tags/batch/${batchFile.id}`);
};

export default () => {
	const [kpis, setKpis] = useState<PricingTagsKpis>();
	const [, loadKpis] = useAxiosEffect(async (config) => {
		const { data } = await axios.get<PricingTagsKpis>(`/api/kpis/sales/pricing-tags`, config);
		setKpis(data);
	}, []);

	const kpiPlaceholder = <DashboardKpi primary="-" />;

	const createBatch = async (pricingTags: BatchFilePricingTags) => {
		await axios.post<BatchFile>(`/api/sales/pricing-tags/batch`, pricingTags);
		loadKpis();
	};

	const [createDorfmarkBatchNew, isCreatingDorfmarkBatchNew] = useAction(() =>
		createBatch({
			location: "Dorfmark",
			firstTimeBatching: true,
		}),
	);

	const [createNorderstedtBatchNew, isCreatingNorderstedtBatchNew] = useAction(() =>
		createBatch({
			location: "Norderstedt",
			firstTimeBatching: true,
		}),
	);

	const [createDorfmarkBatchUpdated, isCreatingDorfmarkBatchUpdated] = useAction(() =>
		createBatch({
			location: "Dorfmark",
			firstTimeBatching: false,
		}),
	);

	const [createNorderstedtBatchUpdated, isCreatingNorderstedtBatchUpdated] = useAction(() =>
		createBatch({
			location: "Norderstedt",
			firstTimeBatching: false,
		}),
	);

	useChannel("batch-files");

	const onBatchFiles = async (data: { type: BatchFileType }) => {
		if (data.type === "PricingTags") {
			loadKpis();
		}
	};

	useEvent("batch-file:created", onBatchFiles);
	useEvent("batch-file:updated", onBatchFiles);
	useEvent("batch-file:deleted", onBatchFiles);

	return (
		<Layout title="Preisschilder" navigation={<SalesNavigation />}>
			<Grid container spacing={6}>
				<Grid item xs={12}>
					<FluentGrid itemWidth={300} itemGap={6}>
						<Tile title="Neu für Dorfmark">
							<TileContent dense>
								<Box p={2}>
									{kpis && (
										<DashboardKpi primary={formatNumber(kpis.availablePricingTags.dorfmarkNew)} />
									)}
									{!kpis && kpiPlaceholder}
								</Box>
								<TileContentActions align="right">
									<TileContentAction
										disabled={!kpis?.availablePricingTags.dorfmarkNew || isCreatingDorfmarkBatchNew}
										onClick={createDorfmarkBatchNew}
										label="Sammeldokument erstellen"
									/>
								</TileContentActions>
							</TileContent>
						</Tile>
						<Tile title="Anpassungen für Dorfmark">
							<TileContent dense>
								<Box p={2}>
									{kpis && (
										<DashboardKpi
											primary={formatNumber(kpis.availablePricingTags.dorfmarkUpdated)}
										/>
									)}
									{!kpis && kpiPlaceholder}
								</Box>
								<TileContentActions align="right">
									<TileContentAction
										disabled={
											!kpis?.availablePricingTags.dorfmarkUpdated ||
											isCreatingDorfmarkBatchUpdated
										}
										onClick={createDorfmarkBatchUpdated}
										label="Sammeldokument erstellen"
									/>
								</TileContentActions>
							</TileContent>
						</Tile>
						<Tile title="Neu für Norderstedt">
							<TileContent dense>
								<Box p={2}>
									{kpis && (
										<DashboardKpi
											primary={formatNumber(kpis.availablePricingTags.norderstedtNew)}
										/>
									)}
									{!kpis && kpiPlaceholder}
								</Box>
								<TileContentActions align="right">
									<TileContentAction
										disabled={
											!kpis?.availablePricingTags.norderstedtNew || isCreatingNorderstedtBatchNew
										}
										onClick={createNorderstedtBatchNew}
										label="Sammeldokument erstellen"
									/>
								</TileContentActions>
							</TileContent>
						</Tile>
						<Tile title="Anpassungen für Norderstedt">
							<TileContent dense>
								<Box p={2}>
									{kpis && (
										<DashboardKpi
											primary={formatNumber(kpis.availablePricingTags.norderstedtUpdated)}
										/>
									)}
									{!kpis && kpiPlaceholder}
								</Box>
								<TileContentActions align="right">
									<TileContentAction
										disabled={
											!kpis?.availablePricingTags.norderstedtUpdated ||
											isCreatingNorderstedtBatchUpdated
										}
										onClick={createNorderstedtBatchUpdated}
										label="Sammeldokument erstellen"
									/>
								</TileContentActions>
							</TileContent>
						</Tile>
					</FluentGrid>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={6}>
						<Grid item xs={12} md={6}>
							<BatchFilesTile
								title="Preisschilder Dorfmark"
								query={{
									types: ["PricingTags"],
									pricingTags: {
										location: "Dorfmark",
									},
								}}
								onDelete={onPricingTagsBatchDelete}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<BatchFilesTile
								title="Preisschilder Norderstedt"
								query={{
									types: ["PricingTags"],
									pricingTags: {
										location: "Norderstedt",
									},
								}}
								onDelete={onPricingTagsBatchDelete}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Layout>
	);
};
