import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { VehiclePerspective, RemarketingStatus } from "./Domain";

export interface VehicleQuery {
	perspective: VehiclePerspective
	status?: RemarketingStatus
	businessLine?: string;
	inventoryId?: string,
	locationName?: string,
	placeId?: string
	placeGroup?: string
	date?: string
	currentEntryDateFrom?: string
	sort: string
	fullText?: string
}

export default (sessionKey: string, defaultQuery: VehicleQuery) : [VehicleQuery, Dispatch<SetStateAction<VehicleQuery>>, string | undefined, Dispatch<SetStateAction<string | undefined>>, () => VehicleQuery] => {
	const jsonQuery = sessionStorage[sessionKey];

	let sessionQuery = defaultQuery;
	if (jsonQuery) {
		sessionQuery = {
			...sessionQuery,
			...JSON.parse(jsonQuery)
		};
	}

	const [query, setQuery] = useState<VehicleQuery>(sessionQuery);
	const [place, setPlace] = useState<string | undefined>(query.placeGroup
		? `group:${query.placeGroup}`
		: query.placeId
	);

	const mountRef = useRef(false);

	useEffect(() => {
		if (!mountRef.current) {
			mountRef.current = true;
			return;
		}

		if (place?.indexOf("group:") === 0) {
			setQuery(q => ({
				...q,
				placeGroup: place?.substr(6),
				placeId: undefined
			}));
		} else {
			setQuery(q => ({
				...q,
				placeGroup: undefined,
				placeId: place
			}));
		}
	}, [place]);

	useEffect(() => {
		sessionStorage[sessionKey] = JSON.stringify(query);
	}, [query]);

	const resetQuery = useCallback(() => {
		setQuery(defaultQuery);
		return defaultQuery;
	}, [defaultQuery]);

	const readablePlace = query.placeGroup ? `group:${query.placeGroup}` : query.placeId;

	return [query, setQuery, readablePlace, setPlace, resetQuery];
};
