import axios from 'axios';
import _ from 'lodash';
import { useState } from 'react';
import { useHistory } from 'react-router';

import { AccountBalance, Refresh } from '@mui/icons-material';
import {
    Box, Card, CardContent, Grid, IconButton, TableBody, TableCell, TableHead, TableRow, Tooltip,
    Typography
} from '@mui/material';

import Actions from '../../../Components/Actions';
import ListItemChip from '../../../Components/ListItemChip';
import Table from '../../../Components/Table';
import showMessage from '../../../Dialogs/showMessage';
import { Sale } from '../../../system/Domain';
import formatCurrency from '../../../system/formatCurrency';
import formatDate from '../../../system/formatDate';
import withBusy from '../../../system/withBusy';
import SalesAddressInfo from './SalesAddressInfo';
import { CurrentSalesOrder } from './SalesOrderPage';

interface Props {
  currentSalesOrder: CurrentSalesOrder
  invoiceId: string | null
  onChange?: (sale: Sale) => void
}

const SalesInvoiceCard = ({ invoiceId, currentSalesOrder, onChange }: Props) => {
  const [busy, setBusy] = useState(false);
  const history = useHistory();

  const order = currentSalesOrder.order;
  const sale = currentSalesOrder.sale;
  const lines = order.lines.filter((l: any) => l.invoiceId === invoiceId);

  const datePaid = lines.find(l => l.paymentDate)?.paymentDate;
  const invoiceDate = lines.find(l => l.invoiceDate)?.invoiceDate;

  const createHandleCheckPaymentHandler = () => {
    if (onChange && !datePaid) {
      return async () => {
        const { data: newSales } = await axios.post<Sale>(`/api/sales/${sale.id}/reconcile-payment?invoiceId=${invoiceId}`);

        onChange(newSales);

        const newOrder = newSales.orders.find(o => o.id === order.id);
        if (newOrder) {
          const isPaid = newOrder.lines.some((o: any) => o.invoiceId === invoiceId && o.paymentDate);
          if (!isPaid) {
            await showMessage({message : "Zahlung wurde geprüft, aber es wurde kein Zahlungseingang festgestellt.\n" +
              "Bitte überprüfen Sie Rechnungsnummer und Rechnungsdatum", title : "SAP Zahlungseingang"}
            );
          }
        }
      };
    }
  };

  const createHandleManualPayment = () => {
    if (onChange && !datePaid) {
      return async () => {
        history.push(`/sales/${sale.id}/confirm-payment`);
      }
	}
  };

  const handleCheckPayment = createHandleCheckPaymentHandler();
  const handleManualPayment = createHandleManualPayment();

  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Grid container spacing={1}>
              <Grid item>
                <ListItemChip
                  label="Verkauft"
                />
              </Grid>
              <Grid item>
                <ListItemChip
                  label={datePaid ? "Bezahlt" : "Unbezahlt"}
                  color={datePaid ? "success" : "info"}
                />
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Actions>
              <IconButton
                size="small"
                disabled={busy || !handleCheckPayment}
                onClick={withBusy(setBusy)(handleCheckPayment)}
              >
                <Tooltip title="Zahlung in SAP prüfen">
                  <Refresh />
                </Tooltip>
              </IconButton>
              <IconButton
                size="small"
                disabled={busy || !handleManualPayment}
                onClick={withBusy(setBusy)(handleManualPayment)}
              >
                <Tooltip title="Manuellen Zahlungseingang vermerken">
                  <AccountBalance />
                </Tooltip>
              </IconButton>
            </Actions>
          </Box>
        </Box>
        {datePaid && (
          <Box mt={2}>
            <Typography variant="caption">
              Bezahlt am {formatDate(datePaid)}
            </Typography>
          </Box>
        )}
        <Box mt={2}>
          <Box>
            <Typography
              variant="caption"
              color="textSecondary"
            >
              {invoiceId || "Keine Rechungsnummer vorhanden"}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Box>
                <SalesAddressInfo
                  variant="small"
                  address={order.buyer}
                />
              </Box>
            </Box>
            <Box>
              <Typography
                variant="caption"
                color="textSecondary"
              >
                {formatDate(invoiceDate, "")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box mt={2}>
          <Table noBorder size="small" padding="none">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography color="textSecondary" variant="caption">
                    Text
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography color="textSecondary" variant="caption">
                    Betrag
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {lines.map((l, i) => {
                  return (
                    <TableRow key={i}>
                      <TableCell>{l.text}</TableCell>
                      <TableCell align="right">{formatCurrency(l.grossAmount)}</TableCell>
                    </TableRow>
                  )
                })}
                {lines.length > 1 && (
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      align="right"
                    >
                      <Box component="span" fontWeight="fontWeightBold">
                        {formatCurrency(_.sum(lines.map(l => l.grossAmount)))}
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </>
            </TableBody>
          </Table>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SalesInvoiceCard;
