import { useState } from "react";
import { WorkSession, WorkSessionPerspective } from "../../system/Domain";
import useAsyncEffect from "../../system/useAsyncEffect";
import axios from "axios";
import Layout from "../../Components/Layout/Layout";
import { CircularProgress, Paper } from "@mui/material";
import FetchNextButton from "../../Components/FetchNextButton";
import useQuery, { IQuery } from "../../system/useQuery";
import WorkSessionTable from "./WorkSessionTable";
import WorkSessionsSidebar from "./WorkSessionsSidebar";

export interface WorkSessionQuery extends IQuery {
	perspectives? : WorkSessionPerspective[]
	onlyOpen? : boolean
	onlyPublic? : boolean
	sort : string
}

export default () => {
	const take = 20;
	const [query, setQuery, fetchNext, resetQuery] = useQuery<WorkSessionQuery>('workSessionQuery', {
		sort: "dateCreated:desc",
		skip: 0,
		take: take,
	});
	const [isFetching, setIsFetching] = useState(false);

	const [workSessions, setWorkSessions] = useState<WorkSession[]>([]);

	useAsyncEffect(async (cancelToken) => {
		setIsFetching(true);

		try {
			const { data: workSessions } = await axios.get<WorkSession[]>(`/api/work-sessions`, {
				params: query,
				cancelToken: cancelToken.getAxiosToken()
			});

			if (query.skip > 0) {
				setWorkSessions(s => [
					...s,
					...workSessions
				]);
			} else {
				setWorkSessions(workSessions);
			}
		} finally {
			setIsFetching(false);
		}
	}, [query]);

	return (
		<Layout
			sidebar={<WorkSessionsSidebar query={query} setQuery={setQuery} resetQuery={resetQuery} />}
		>
			{!workSessions && (
				<CircularProgress size={48} />
			)}
			{workSessions && (
				<>
					<Paper>
						<WorkSessionTable workSessions={workSessions} />
					</Paper>

					<FetchNextButton
						hidden={workSessions.length < take + query.skip}
						my={3}
						onNext={fetchNext}
						disabled={isFetching}
					/>
				</>
			)}
		</Layout>
	);
}
