import FormElement from "./FormElement";
import CurrencyInput from "./CurrencyInput";
import { ReactNode, useEffect } from "react";
import FormDeltaValue from "./FormDeltaValue";
import { Form, FormOptions } from "../system/useForm";
import { FormHelperText, Input, OutlinedInput, useTheme } from "@mui/material";

export type FormTextProps = {
	name: string;
	label: string | ReactNode;
	form: Form;
	fullWidth?: boolean;
	options?: FormOptions;
	type?: "text" | "password" | "number" | "email" | "currency";
	startAdornment?: ReactNode;
	readOnly?: boolean;
	rows?: string | number;
	multiline?: boolean;
	inline?: boolean;
};

const parseValue = (value: any) => {
	if (typeof value === "number") {
		if (Number.isNaN(value)) {
			return "";
		}
		return value.toString();
	}

	return value;
};

export default ({
	name,
	label,
	form,
	fullWidth,
	options,
	type = "text",
	startAdornment,
	readOnly,
	rows,
	multiline,
	inline = false,
}: FormTextProps) => {
	const theme = useTheme();
	const styles = {
		root: {
			fontSize: "0.9rem",
			backgroundColor: theme.palette.background.paper,
			maxWidth: (props: any) => (props.fullWidth ? "100%" : 250),
			"& input": {
				fontSize: "0.75rem",
				fontWeight: 400,
				lineHeight: 1.66,
				letterSpacing: "0.03333em",
				height: 40,
				boxSizing: "border-box",
			},
			"&.Mui-disabled": {
				color: theme.palette.mode === "dark" ? theme.palette.common.white : theme.palette.common.black,
			},
			"& fieldset": {},
		},
		disabled: {},
	};
	useEffect(() => {
		form.register(name, options);

		return () => {
			form.unregister(name);
		};
	}, [name]);

	const value = parseValue(form.getValue(name));
	const deltaValue = parseValue(form.getDeltaValue(name));

	if (form.deltaMode && value === deltaValue) {
		return null;
	}

	const set = (value: any) => {
		if (type === "number" && typeof value === "string") {
			value = parseInt(value, 10);
			if (isNaN(value)) {
				value = null;
			}
		} else if (type === "currency" && typeof value === "string") {
			value = parseFloat(value);
			if (isNaN(value)) {
				value = null;
			}
		}

		form.setValue(name, value, true);
	};

	return (
		<FormElement label={label}>
			{type !== "currency" && (
				<>
					{!inline && (
						<OutlinedInput
							name={name}
							fullWidth={fullWidth}
							type={type}
							margin="dense"
							value={value || ""}
							rows={rows}
							multiline={multiline}
							error={!!form.errors[name]}
							sx={styles}
							readOnly={readOnly === undefined ? form.readOnly : readOnly}
							disabled={readOnly === undefined ? form.readOnly : readOnly}
							onChange={(event) => {
								set(event.target.value);
							}}
							//labelWidth={0}
							startAdornment={startAdornment}
						/>
					)}
					{inline && (
						<Input
							name={name}
							fullWidth={fullWidth}
							type={type}
							// margin="dense"
							value={value || ""}
							rows={rows}
							multiline={multiline}
							error={!!form.errors[name]}
							readOnly={readOnly === undefined ? form.readOnly : readOnly}
							disabled={readOnly === undefined ? form.readOnly : readOnly}
							onChange={(event) => {
								set(event.target.value);
							}}
							startAdornment={startAdornment}
							sx={[
								styles,
								{
									backgroundColor: "transparent",
									"& input": {
										fontSize: "1rem",
										height: "1.1876em",
										boxSizing: "content-box",
									},
								},
							]}
						/>
					)}
				</>
			)}
			{type === "currency" && (
				<CurrencyInput
					align="left"
					value={value}
					sx={[
						styles,
						inline && {
							backgroundColor: "transparent",
							"& input": {
								fontSize: "1rem",
								height: "1.1876em",
								boxSizing: "content-box",
							},
						},
					]}
					onChange={(event) => {
						set(event.target.value);
					}}
					disabled={readOnly === undefined ? form.readOnly : readOnly}
					fullWidth={fullWidth}
					variant="standard"
				/>
			)}
			{form.deltaMode && (
				<FormDeltaValue deltaValue={deltaValue || "Keine Angabe"} value={value || "Keine Angabe"} />
			)}
			{form.errors[name]?.message && <FormHelperText error>{form.errors[name].message}</FormHelperText>}
		</FormElement>
	);
};
