import { GetApp } from "@mui/icons-material";
import { FormControl } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { useState } from "react";
import useFileExport from "system/useFileExport";
import SidebarButton from "../../Components/Sidebar/SidebarButton";
import SidebarForm from "../../Components/Sidebar/SidebarForm";

interface ExportQuery {
	date?: string;
}

export default () => {
	const [query, setQuery] = useState<ExportQuery>({});
	const [exportState, triggerExport] = useFileExport(query, "/api/sales/dad-export");

	return (
		<SidebarForm>
			<FormControl fullWidth>
				<DatePicker
					defaultValue=""
					format="DD.MM.YYYY"
					label="Exportdatum"
					slotProps={{ textField: { variant: "standard" } }}
					value={query.date === undefined ? null : moment(query.date)}
					onChange={(d: any) =>
						setQuery((q) => ({
							...q,
							date: (moment(d) || moment()).startOf("d").toISOString(true),
						}))
					}
				/>
			</FormControl>
			<SidebarButton
				startIcon={<GetApp />}
				label="DAD Export"
				onClick={triggerExport}
				disabled={exportState.loading || !query.date}
			/>
		</SidebarForm>
	);
};
