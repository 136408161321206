import { green, red } from "@mui/material/colors";
import { TransportOrder } from "../../../system/Domain";
import TextBlock from "../../../Components/Layout/TextBlock";
import moment from "moment";
import dateFormats from "system/dateFormats";
import { Chip } from "@mui/material";

export interface OrderStatusProps {
	order : TransportOrder
	showAssignee? : boolean
};

export const statusSettings = {
	"Created": {
		label: "Erzeugt",
		backgroundColor: green[500],
		date: (transportOrder: TransportOrder): string => moment(transportOrder.dateCreated).format(dateFormats.dateTime)
	},
	"Assigned": {
		label: "Zugewiesen",
		backgroundColor: green[500],
		date: (transportOrder: TransportOrder): string => moment(transportOrder.dateAssigned).format(dateFormats.dateTime)
	},
	"Cancelled": {
		label: "Zurückgegeben",
		backgroundColor: red[500],
		date: (transportOrder: TransportOrder): string => moment(transportOrder.returnDate).format(dateFormats.dateTime)
	},
	"Completed": {
		label: "Abgeschlossen",
		backgroundColor: green[500],
		date: (transportOrder: TransportOrder): string => moment(transportOrder.returnDate).format(dateFormats.dateTime)
	}
};

export default ({ order, showAssignee = false } : OrderStatusProps) => {
	const setting = statusSettings[order.status];
	let label = setting.label;
	if (showAssignee && order.status === "Assigned") {
		label = `${label} (${order.assignedTo.name})`
	}

	return (
		<TextBlock
			primary={
				<Chip
					label={label}
					size="small"
					style={{ backgroundColor: setting.backgroundColor }}
				/>
			}
			secondary={setting.date(order)}
		/>
	);
};
