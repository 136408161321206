import { Box, CircularProgress } from "@mui/material";
import moment from "moment";
import ServicesNavigation from "Navigation/ServicesNavigation";
import FetchNextButton from "../../Components/FetchNextButton";
import Layout from "../../Components/Layout/Layout";
import useQuery from "../../system/useQuery";
import useVehicleServices, { VehicleServiceQuery } from "./useVehicleServices";
import VehicleServiceOverviewSidebar from "./VehicleServiceOverviewSidebar";
import VehicleServiceTable from "./VehicleServiceTable";

export default () => {
	const [query, setQuery, fetchNext, resetQuery] = useQuery<VehicleServiceQuery>('vehicleServiceOverviewQuery', {
		perspective: "All",
		skip: 0,
		sort: "dateCreated:desc",
		serviceTypeId: "",
		take: 20,
		dateFrom: moment().startOf('M').toISOString(true),
		dateTo: moment().add(1, 'd').startOf('d').toISOString(true)
	});

	const [vehicleServices, setVehicleServices, isFetching] = useVehicleServices(query);

	return (
		<Layout
			title="Tätigkeiten"
			navigation={<ServicesNavigation />}
			sidebar={<VehicleServiceOverviewSidebar query={query} setQuery={setQuery} resetQuery={resetQuery} />}
		>
			<Box>
				{!vehicleServices && (
					<CircularProgress size={48} />
				)}
				{vehicleServices && (
					<>
						<VehicleServiceTable setVehicleServices={setVehicleServices} vehicleServices={vehicleServices} showVehiclePlate />
						<FetchNextButton
							hidden={vehicleServices.length < query.skip + query.take}
							my={3}
							onNext={fetchNext}
							disabled={isFetching}
						/>
					</>
				)}
			</Box>
		</Layout>
	);
}
