import { ReactElement } from 'react';
import { AssessmentOrder } from "../../../system/Domain";
import { Chip } from "@mui/material";
import { green, orange, red } from "@mui/material/colors";
import dateFormats from "../../../system/dateFormats";
import moment from "moment";
import TextBlock from "../../../Components/Layout/TextBlock";

interface Props {
	assessmentOrder : AssessmentOrder
	additionalInformation? : ReactElement | string
}

export const assessmentOrderStatusSettings = {
	"Created": {
		label: "Erstellt",
		backgroundColor: orange[500],
		date: (assessmentOrder : AssessmentOrder) : string => moment(assessmentOrder.dateCreated).format(dateFormats.dateTime)
	},
	"Cancelled": {
		label: "Storniert",
		backgroundColor: red[500],
		date: (assessmentOrder : AssessmentOrder) : string => moment(assessmentOrder.dateCancelled).format(dateFormats.dateTime)
	},
	"Processed": {
		label: "Verarbeitet",
		backgroundColor: green[500],
		date: (assessmentOrder : AssessmentOrder) : string => moment(assessmentOrder.dateProcessed).format(dateFormats.dateTime)
	},
	"Completed": {
		label: "Abgeschlossen",
		backgroundColor: green[500],
		date: (assessmentOrder : AssessmentOrder) : string => moment(assessmentOrder.dateCompleted).format(dateFormats.dateTime)
	},
	"Interrupted": {
		label: "Unterbrochen",
		backgroundColor: red[500],
		date: (assessmentOrder : AssessmentOrder) : string => moment(assessmentOrder.dateInterrupted).format(dateFormats.dateTime)
	}
};

const AssessmentOrderStatusPill = (props : Props) => {
	const setting = assessmentOrderStatusSettings[props.assessmentOrder.status];

	return (
		<TextBlock
			primary={
				<Chip
					label={setting.label}
					size="small"
					style={{ backgroundColor: setting.backgroundColor }}
				/>
			}
			secondary={setting.date(props.assessmentOrder)}
			tertiary={props.additionalInformation}
		/>
	);
};

export default AssessmentOrderStatusPill;
