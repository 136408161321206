import axios from "axios";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import useAsyncEffect from "./useAsyncEffect";
import { VehicleServiceType } from "./Domain";

export default (id : string) : [VehicleServiceType | undefined, Dispatch<SetStateAction<VehicleServiceType | undefined>>, (id : string) => Promise<void>] => {
	const [vehicleServiceType, setVehicleServiceType] = useState<VehicleServiceType>();

	const load = useCallback(async (id : string) => {
		if (!id) {
			return;
		}

		const { data: vehicleServiceType } = await axios.get<VehicleServiceType>(`/api/vehicles/services/types/${id}`);
		setVehicleServiceType(vehicleServiceType);
	}, [id]);

	useAsyncEffect(async () => {
		setVehicleServiceType(undefined);
		await load(id);
	}, [id]);

	return [
		vehicleServiceType,
		setVehicleServiceType,
		load
	];
}
