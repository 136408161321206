import { atom } from "recoil";

interface Navigation {
	filter: string;
	selected: string;
	orientation: "horizontal" | "vertical";
}

const navigationAtom = atom<Navigation>({
	key: "navigation",
	default: {
		filter: "",
		selected: "",
		orientation: "vertical",
	},
});

export { navigationAtom };
