import moment from "moment";
import TextGroup from "Pages/VehicleSales/TextGroup";
import TextLine from "Pages/VehicleSales/TextLine";
import { Vehicle, VehicleAssessment, VehicleType } from "../../system/Domain";

interface Props {
	type: VehicleType
	assessment?: VehicleAssessment
	vehicle?: Vehicle
}

const ModelTextGroup = ({ type, assessment, vehicle }: Props) => {
	return (
		<TextGroup title="Modell">
			<TextLine label="Variante" value={type?.model.variant} />
			<TextLine label="Version" value={type?.model.version} />
			<TextLine label="Bauart" value={type?.body.type} />
			<TextLine label="Farbe" value={assessment?.color?.name ?? type?.body.paint} />
			<TextLine label="Lackierung" value={assessment?.color?.paintwork || "-"} />
			<TextLine label="Polster" value={type?.body.cushions} />
			<TextLine label="Erstzulassungsjahr" value={!vehicle?.dateFirstRegistered ? "-" : moment(vehicle?.dateFirstRegistered).format("yyyy")} />
		</TextGroup>
	);
};

export default ModelTextGroup;
