import RegistrationDocumentsNavigation from 'Navigation/RegistrationDocumentsNavigation';
import Layout from "../../../Components/Layout/Layout";
import Imports from "../../Imports/Imports";

const VehicleRegistrationDocumentRequestsImports = () => {
	return (
		<Layout
			title="Anfragen"
			navigation={<RegistrationDocumentsNavigation />}
		>
			<Imports importType="VehicleRegistrationDocumentRequestsImport" />
		</Layout>
	);
};

export default VehicleRegistrationDocumentRequestsImports;
