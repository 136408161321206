import axios from 'axios';
import moment from 'moment';
import { Dispatch, SetStateAction, useState } from 'react';
import { useParams } from 'react-router';
import { Box, CircularProgress, Grid, Typography, useTheme } from '@mui/material';
import ActionButton from '../../Components/ActionButton';
import Layout from '../../Components/Layout/Layout';
import NotificationSnackbar from '../../Components/Layout/NotificationSnackbar';
import TextBlock from '../../Components/Layout/TextBlock';
import { Plate } from '../../Components/Plate';
import Tile from '../../Components/Tiles/Tile';
import TileActions from '../../Components/Tiles/TileActions';
import TileContent from '../../Components/Tiles/TileContent';
import VinQrScanner from '../../Components/VinQrScanner';
import { VehicleService } from '../../system/Domain';
import useAsyncEffect from '../../system/useAsyncEffect';
import useVehicleServiceType from '../../system/useVehicleServiceType';
import changeText from './changeText';
import uploadPictureProof from './uploadPictureProof';

const VehicleServicesSelectTypeScan = () => {
	const { id } = useParams<{ id: string }>();
	const [vehicleServiceType] = useVehicleServiceType(id);
	const [vin, setVin] = useState<string>();
	const [vehicleServices, setVehicleServices] = useState<VehicleService[]>();

	const [resultMessage, setResultMessage] = useState("");
	const [resultMessageColor, setResultMessageColor] = useState<any>("success");

	useAsyncEffect(async () => {
		if (!vin) return;
		
		try {
			const { data } = await axios.get<VehicleService[]>(`/api/vehicles/services`, {
				params: {
					vin,
					serviceTypeId: id,
					status: "Assigned"
				}
			});

			if (data.length === 0) {
				setResultMessage("Keine offenen Aufträge gefunden.");
				setResultMessageColor("error");
				setVin(undefined);
				setVehicleServices(undefined);
			}

			setVehicleServices(data);
		} catch (e) {
			setVin(undefined);
			setVehicleServices(undefined);
		}
	}, [vin]);

	return (
		<Layout>
			{!vehicleServiceType && (
				<CircularProgress size={48} />
			)}
			{vehicleServiceType && !vin && (
				<Box height="100%">
					<Box>
						<Typography variant="h5">
							{vehicleServiceType.text}
						</Typography>
					</Box>
					<Box height="100%" display="flex" alignItems="center">
						<VinQrScanner setVin={setVin} />
					</Box>
				</Box>
			)}
			{vehicleServiceType && vin && !vehicleServices && (
				<Box height="100%">
					<Box>
						<Typography variant="h5">
							{vehicleServiceType.text}
						</Typography>
					</Box>
					<CircularProgress size={48} />
				</Box>
			)}
			{vehicleServiceType && vin && vehicleServices && (
				<VehicleServiceTypeOptionsDisplay
					vehicleServices={vehicleServices}
					setVin={setVin}
					setNotificationMessage={setResultMessage}
					setNotificationMessageColor={setResultMessageColor}
				/>
			)}
			<NotificationSnackbar
				message={resultMessage}
				onClose={() => setResultMessage("")}
				color={resultMessageColor}
			/>
		</Layout>
	);
};

interface VehicleServiceTypeOptionsDisplayProps {
	vehicleServices: VehicleService[]
	setVin: Dispatch<SetStateAction<string | undefined>>
	setNotificationMessage: Dispatch<SetStateAction<string>>
	setNotificationMessageColor: Dispatch<SetStateAction<any>>
}

interface VehicleServiceTypeOptionDisplayProps {
	vehicleService: VehicleService
	setVin: Dispatch<SetStateAction<string | undefined>>
	setNotificationMessage: Dispatch<SetStateAction<string>>
	setNotificationMessageColor: Dispatch<SetStateAction<any>>
}

const VehicleServiceTypeOptionsDisplay = (props: VehicleServiceTypeOptionsDisplayProps) => {
	return (
		<Grid container alignItems="center" justifyContent="center" spacing={4}>
			{props.vehicleServices.map((item) => (
				<VehicleServiceTypeOptionDisplay
					key={item.id}
					vehicleService={item}
					setVin={props.setVin}
					setNotificationMessage={props.setNotificationMessage}
					setNotificationMessageColor={props.setNotificationMessageColor}
				/>
			))}
		</Grid>
	);
};

const VehicleServiceTypeOptionDisplay = (props: VehicleServiceTypeOptionDisplayProps) => {
	const theme = useTheme();
	const dateCreated = moment(props.vehicleService.dateCreated).format("L LT");
	const dateAssigned = moment(props.vehicleService.dateAssigned).format("L LT");
	const [saving, setSaving] = useState(false);

	const close = async () => {
		if (saving)
			return;

		if (props.vehicleService.serviceType.requiresPictureProof) {
			props.vehicleService.proofPicture = await uploadPictureProof({ headline: "Fotonachweis", fileReference: props.vehicleService.proofPicture });
		}

		if (props.vehicleService.serviceType.canHaveCompletionNote) {
			props.vehicleService.completionNote = await changeText({ headline: "Abschlussnotiz", label: "Notiz", text: "" });
		}

		setSaving(true);

		try {
			await axios.post<VehicleService>(`/api/vehicles/services/${props.vehicleService.id}/close`, {
				fileReference: props.vehicleService.proofPicture,
				completionNote: props.vehicleService.completionNote
			});

			props.setNotificationMessageColor("success");
			props.setNotificationMessage("Tätigkeit erfolgreich abgeschlossen");
		} finally {
			props.setVin(undefined);
		}
	};

	return (
		<Grid item>
			<Tile
				sxProp={{
					display: "inline-block",
					padding: theme.spacing(1)
				}}
				title={<Plate plateNumber={props.vehicleService.plateNumber} />}
			>
				<TileContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextBlock
								primary={props.vehicleService.serviceType.text}
								secondary={`Erstellt am ${dateCreated}`}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextBlock
								primary={props.vehicleService.assignedTo.name ?? ''}
								secondary={`Zugewiesen am ${dateAssigned}`}
							/>
						</Grid>
					</Grid>
				</TileContent>
				<TileActions>
					<ActionButton color="secondary" onClick={() => props.setVin(undefined)}>Abbrechen</ActionButton>
					<Box flexGrow={1}>&nbsp;</Box>
					<ActionButton onClick={close}>Abschließen</ActionButton>
				</TileActions>
			</Tile>
		</Grid>
	);
};

export default VehicleServicesSelectTypeScan;
