import { Check, Clear, Delete, FileDownload } from '@mui/icons-material'
import { Grid, IconButton, ListItem, Tooltip, Typography, useTheme } from '@mui/material'
import TextBlock from 'Components/Layout/TextBlock'
import axios from 'axios'
import fileDownload from 'js-file-download'
import { SyntheticEvent, useState } from 'react'
import { AttachedDocument, SubjectInquiryDefect, SubjectInquiryStatus, UserRole } from 'system/Domain'
import useUser from 'system/useUser'

export enum InquiryDocumentViewMode {
	View = "view",
	Edit = "edit",
	Deleting = "deleting"
}

type Props = {
	defectId: string
	subjectInquiryDefect: SubjectInquiryDefect
	inquiryDocument: AttachedDocument
	viewMode: InquiryDocumentViewMode
	onSubjectInquiryDeleteDocument?: (assessmentOrderId: string, subjectInquiryId: string, documentId: string) => Promise<void>
}

const InquiryDocument = ({ defectId, subjectInquiryDefect, inquiryDocument, viewMode, onSubjectInquiryDeleteDocument }: Props) => {
	const [currentViewMode, setCurrentViewMode] = useState<InquiryDocumentViewMode>(viewMode);
	const theme = useTheme();
	const [, , hasRole] = useUser();

	const isAldEntitledUser = !hasRole(UserRole.DamageAssessor) && (hasRole(UserRole.LotManager) || hasRole(UserRole.AldManager));

	const handleDownloadFile = async (e: SyntheticEvent) => {
		e.stopPropagation();
		const response = await axios.get(`/api/orders/assessments/${defectId}/subject-inquiry/${subjectInquiryDefect.id}/documents/${inquiryDocument.fileReference.hash}`, {
			responseType: "blob"
		});

		fileDownload(response.data, inquiryDocument.fileReference.filename);
	};

	const deleteInquiryDocument = async () => {
		if (!onSubjectInquiryDeleteDocument) {
			return;
		}

		onSubjectInquiryDeleteDocument(defectId, subjectInquiryDefect.id, inquiryDocument.fileReference.hash).then(() => setCurrentViewMode(InquiryDocumentViewMode.View));
	};

	const changeViewMode = (newViewMode: InquiryDocumentViewMode) => {
		setCurrentViewMode(newViewMode);
	};

	return (
		<ListItem key={inquiryDocument.fileReference.hash}
				  sx={{ backgroundColor: currentViewMode === InquiryDocumentViewMode.Deleting ? theme.palette.error.main : undefined, padding: 0, marginTop: 2 }}>
			<Grid container direction="row">
				{currentViewMode !== InquiryDocumentViewMode.Deleting && (
					<Grid item xs={10}>
						<TextBlock
							primary={inquiryDocument.fileReference.filename}
							secondary="Dokument"
						/>
					</Grid>
				)}

				{currentViewMode === InquiryDocumentViewMode.Deleting &&
					<>
						<Grid item xs={9} alignContent={'center'} sx={{ boxSizing: "border-box", padding: 1 }}>
							<TextBlock
								primary={<Typography>Entfern: {inquiryDocument.fileReference.filename}</Typography>}
							/>
						</Grid>

						<Grid item xs={1}>
							<Tooltip title="Bestätigen">
								<IconButton onClick={deleteInquiryDocument}>
									<Check />
								</IconButton>
							</Tooltip>
						</Grid>
						<Grid item xs={1}>
							<Tooltip title="Abbrechen">
								<IconButton onClick={() => changeViewMode(InquiryDocumentViewMode.Edit)}>
									<Clear />
								</IconButton>
							</Tooltip>
						</Grid>
					</>
				}
				<Grid item xs={1} alignContent={'center'}>
					<Tooltip title={"Dokument herunterladen"}>
						<IconButton onClick={handleDownloadFile}>
							<FileDownload />
						</IconButton>
					</Tooltip>
				</Grid>
				{isAldEntitledUser && currentViewMode !== InquiryDocumentViewMode.Deleting && subjectInquiryDefect.status === SubjectInquiryStatus.Created && (
					<Grid item xs={1} alignContent={'center'}>
						<Tooltip title={"Dokument entfernen"}>
							<IconButton onClick={() => changeViewMode(InquiryDocumentViewMode.Deleting)}>
								<Delete />
							</IconButton>
						</Tooltip>
					</Grid>
				)}
			</Grid>
		</ListItem>
	)
}

export default InquiryDocument;
