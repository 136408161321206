import { colors } from "@mui/material";
import { SeriesOptionsType, Options, SeriesPieDataLabelsOptionsObject } from "highcharts";
import Chart from "./Highcharts";
import formatNumber from "system/formatNumber";
import { CompoundsKpis } from "system/Domain";

export default ({ data, today, lastWeek }: { data: CompoundsKpis[], today: number, lastWeek: number }) => {
	const dataProcessed = data.map(d => ({
		name: d.compoundName,
		y: d.amount,
	}))

	const options = {
		colors: [
			colors.blue[900],
			colors.blue[800],
			colors.blue[700],
			colors.blue[600],
			colors.blue[500],
			colors.blue[400],
			colors.blue[300],
			colors.blue[200],
			colors.blue[100],
			colors.indigo[500],
			colors.indigo[400],
			colors.indigo[300],
		],
		chart: {
			type: 'pie',
			backgroundColor: '#2E333D',
			className: 'dark-container',
			height: '345',
			width: '570',
			borderRadius: 5,
			shadow: true
		},
		credits: {
			enabled: false
		},
		title: {
			verticalAlign: 'middle',
			useHTML: true,
			x: -105,
			text: 
				`<div style='text-align: center; font-family: Roboto'>
					<div style='color:white; font-size:xx-large'>
						${formatNumber(today)}
					</div>
					<div style='color:#ffffffb3; font-size:medium'>
						${today >= lastWeek ? "+" : "-"}${formatNumber(today - lastWeek)} letzte Woche
					</div>
				</div>`,
		  },
		legend: {
			align: 'right',
			verticalAlign: 'middle',
			layout: 'vertical',
			symbolPadding: 10,
			itemMarginTop: 5,
			itemMarginBotton: 5,
			labelFormat: '{y} {name}',
			itemHoverStyle: {
				color: colors.teal[300]
			},
			itemStyle: {
				fontSize: '15',
				color: 'white',
				fontWeight: "normal",
				fontFamily: 'Roboto'
			},
		},
		tooltip: {
			headerFormat: '',
			pointFormat: '{point.y} {point.name}'
		},
		plotOptions: {
			series: {
				point: {
					events:
					{
						mouseOver: function (e) {
							if (!(e.target as any)?.legendItem?.label?.element)
								return;
							(e.target as any).legendItem.label.element.dispatchEvent(new MouseEvent('mouseover', { bubbles: true }));
						},
						mouseOut: function (e) {
							if (!(e.target as any)?.legendItem?.label?.element)
								return;
							(e.target as any).legendItem.label.element.dispatchEvent(new MouseEvent('mouseout', { bubbles: true }));
						}
					}
				}
			},
			pie: {
				allowPointSelect: true,
				size: '100%',
				innerSize: '50%',
				startAngle: 135,
				borderWidth: 0,
				
				showInLegend: true,
				dataLabels: {
					enabled: false
				},
			} as SeriesPieDataLabelsOptionsObject
		},
	} as Options;

	const series = [{
		data: dataProcessed,
	}] as Array<SeriesOptionsType>;

	return (
		<Chart
			options={{
				...options,
				series
			}}
		/>
	);
}
