import * as yup from "yup";
import { FormEvent, ReactNode, useState } from 'react';
import { Add, Remove } from "@mui/icons-material";
import ThemedDialog from "../../../Components/ThemedDialog";
import ActionButton from "../../../Components/ActionButton";
import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";
import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	Switch,
	TextField
} from "@mui/material";
import axios from "axios";

interface Props extends ChangeCustomerSettingsOptions, ReactConfirmProps<ChangeCustomerSettingsOptions> {
	edit: boolean
	show: boolean
}

interface CustomerSettings {
	id: number
	name: string
	email: string
	sendReturnForms: boolean
}

interface FormObject {
	name: yup.StringSchema<Exclude<string | undefined, undefined | null>, object>

	[key: string]: yup.StringSchema<Exclude<string | undefined, undefined | null>, object>
}

interface FormFields {
	name: string

	[key: string]: string
}

const CreateOrChangeCustomerSettingsDialog = (props: Props) => {
	const [edit, setEdit] = useState<boolean>(props.edit);
	const [errors, setErrors] = useState<any>({});
	const [idInfo, setIdInfo] = useState<{ warning: string, error: ReactNode }>({ warning: "", error: "" });
	const [formState, setFormState] = useState<CustomerSettings>({
		id: props?.customerSettings?.id,
		name: props?.customerSettings?.name || "",
		email: props?.customerSettings?.email,
		sendReturnForms: props?.customerSettings?.sendReturnForms || false
	});

	const initialData = props?.customerSettings?.email?.split(";") || [];

	const [arr, setArr] = useState<string[]>(initialData.length > 0 ? initialData : [""]);

	const remove = (i: number) => {
		if (i <= 0) return;

		const newArr = [...arr];
		newArr.splice(i, 1);
		setArr(newArr);
	};

	const handleClose = () => {
		props.cancel();
	};

	const onChanged = (field: string) => {
		return (event: any) => setFormState({
			...formState,
			[field]: event.target.value
		});
	};

	const addNewEmailEntry = () => {
		const newArr = [...arr];
		newArr.push('');
		setArr(newArr);
	};

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();

		const fields: FormObject = {
			name: yup.string().required("Ein Name muss angegeben werden"),
			id: yup.string().required("Eine gültige Kundennummer muss angegeben werden"),
		};
		const formFields: FormFields = {
			name: formState.name,
			id: formState.id?.toString() ?? ""
		};

		for (let i = 0; i < arr.length; i++) {
			fields[`email${i + 1}`] = yup.string()
				.email("Eine gültige Email Adresse muss angegeben werden")
				.required("Email muss angegeben werden");
			formFields[`email${i + 1}`] = arr[i];
		}

		const schema = yup.object().shape(fields);

		try {
			await schema.validate(formFields, { abortEarly: false });
		} catch (err) {
			const errorsTemp = err.inner.reduce((p: any, c: any) => {
				p[c.path] = c.message;
				return p;
			}, {});
			setErrors({ ...errorsTemp, id: undefined });
			if (!edit) {
				setIdInfo(prev => ({ warning: "", error: errorsTemp.id ?? prev.error }));
			}
			return;
		}

		if (!edit && (idInfo.warning || idInfo.error)) {
			setErrors({});
			return;
		}

		formState.email = arr.join(';');

		props.proceed({ edit: edit, customerSettings: formState });
	};

	const setModeToEdit = (existingCustomerSettings: CustomerSettings) => {
		setEdit(true);
		setFormState(existingCustomerSettings);
		setArr(existingCustomerSettings.email?.split(";") || [""]);
		setErrors({});
		setIdInfo({ warning: "", error: "" });
	};

	const onBlurId = async () => {
		if (formState?.id) {
			setIdInfo({
				warning: `Es wird geprüft, ob die Kundennummer ${formState?.id} noch verfügbar ist...`,
				error: ""
			});

			try {
				const { data: savedCustomerSettings } = await axios.get<CustomerSettings[]>(`/api/customer-settings`, {
					params: {
						search: formState.id
					}
				});

				if (savedCustomerSettings.length > 0) {
					setIdInfo({
						warning: "",
						error: <>
							<>
								Eine Kundeneinstellung mit Id {formState?.id} existiert bereit
							</>
							<Button
								sx={{ fontSize: "smaller" }}
								onClick={() => setModeToEdit(savedCustomerSettings[0])}
							>
								Diese anpassen
							</Button>
						</>
					});
				} else {
					setIdInfo({ warning: "", error: "" });
				}
			} catch {
				setIdInfo({ warning: "", error: "Es gab eine Störung. Bitte probieren Sie nochmal" });
			}
		}
	}

	return (
		<ThemedDialog open={props.show} maxWidth="sm" fullWidth onClose={handleClose}>
			<DialogTitle>
				{edit ? `Kundeneinstellung anpassen (Kundennummer: ${formState.id})` : "Kundeneinstellung anlegen"}
			</DialogTitle>
			<form onSubmit={handleSubmit}>
				<DialogContent>
					{!edit && <Box my={4}>
						<TextField
							type="number"
							fullWidth
							variant="outlined"
							value={formState.id}
							label="Kundennummer"
							error={Boolean(idInfo.error)}
							helperText={idInfo.error || idInfo.warning}
							onChange={onChanged("id")}
							onBlur={onBlurId}
						/>
					</Box>}
					<Box mb={4} mt={4}>
						<TextField
							fullWidth
							variant="outlined"
							value={formState.name}
							label="Name"
							error={Boolean(errors.name)}
							helperText={errors.name}
							onChange={onChanged("name")}
						/>
					</Box>
					{arr.length > 0 && (
						arr.map((e, i) => {
							return (
								<Box key={i} mb={4}>
									<Grid container alignItems="center">
										<Grid item flexGrow={1}>
											<TextField
												fullWidth
												variant="outlined"
												error={Boolean(errors[`email${i + 1}`])}
												helperText={errors[`email${i + 1}`]}
												value={arr[i]}
												label={arr.length === 1 ? 'Emailadresse' : `Emailadresse ${i + 1}`}
												onChange={(e) => {
													const newArr = [...arr];
													newArr[i] = e.target.value;
													setArr(newArr);
												}}
											/>
										</Grid>
										{i === 0 && (
											<Grid item>
												<ActionButton
													icon
													onClick={addNewEmailEntry}
												>
													<Add />
												</ActionButton>
											</Grid>
										)}
										{i !== 0 && (
											<Grid item>
												<ActionButton
													icon
													onClick={() => remove(i)}
												>
													<Remove />
												</ActionButton>
											</Grid>
										)}
									</Grid>
								</Box>
							)
						})
					)}
					<Box mb={4}>
						<FormControlLabel
							sx={{ marginLeft: 0 }}
							value="end"
							control={
								<Switch
									checked={formState.sendReturnForms}
									onChange={(e) => setFormState({ ...formState, sendReturnForms: e.target.checked })}
									color="primary"
								/>
							}
							label="Versand der Rücknahmeprotokolle"
							labelPlacement="end"
						/>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button
						variant="outlined"
						color="secondary"
						href=""
						onClick={handleClose}
					>
						Abbrechen
					</Button>
					<Button
						variant="outlined"
						color="primary"
						href=""
						type="submit"
					>
						Ok
					</Button>
				</DialogActions>
			</form>
		</ThemedDialog>
	);
};

interface ChangeCustomerSettingsOptions {
	edit: boolean
	customerSettings: CustomerSettings
}

export default (options: ChangeCustomerSettingsOptions) => {
	const dialog = confirmable(props => <CreateOrChangeCustomerSettingsDialog {...props} {...options} />);
	return createConfirmation<ChangeCustomerSettingsOptions>(dialog)();
}
