import {
	AccountBox,
	Assignment,
	AssignmentTurnedIn,
	CarRental,
	DirectionsCar,
	EmojiTransportation,
	ExitToApp,
	Help,
	Money,
	Settings,
	Task,
} from "@mui/icons-material";
import { Box, useTheme } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { logoutAtom } from "system/atoms/logout";
import { UserRole } from "../../system/Domain";
import useUser from "../../system/useUser";
import Ald from "./Ald";
import VehicleServiceUnconfirmedBadge from "./Badges/VehicleServiceUnconfirmedBadge";
import MenuItem from "./MenuItem";

interface Props {
	hideNavigation?: boolean;
}

export default ({ hideNavigation = false }: Props) => {
	const [user, , hasRole] = useUser();
	const theme = useTheme();
	const history = useHistory();
	const [, setLogout] = useRecoilState(logoutAtom);

	return (
		<Box display="flex" alignItems="stretch" height="100%" overflow="auto" bgcolor="#2a2e3c">
			<Box display="flex" flexDirection="column" pt={3}>
				<Box sx={{ paddingLeft: 2, paddingBottom: 4 }}>
					<Ald
						sx={{
							width: 113 * 1.3,
							height: 32 * 1.3,
							marginRight: theme.spacing(2),
						}}
					/>
				</Box>
				{!hideNavigation && (
					<>
						<MenuItem
							roles={[
								UserRole.AldUser,
								UserRole.LotManager,
								UserRole.LogisticCompany,
								UserRole.Driver,
								UserRole.DamageAssessor,
							]}
							icon={<Assignment />}
							name="Aufträge"
							path={`/orders`}
						/>

						<MenuItem
							roles={[UserRole.AldUser, UserRole.LotManager]}
							icon={<DirectionsCar />}
							name="Fahrzeuge"
							path="/vehicles/search"
							matchProps={{
								exact: true,
								path: ["/vehicles/dashboard", "/vehicles/search", "/inventories", "/inventories/*"],
							}}
						/>
						<MenuItem
							roles={[UserRole.AldUser, UserRole.LotManager]}
							icon={
								<VehicleServiceUnconfirmedBadge>
									<AssignmentTurnedIn />
								</VehicleServiceUnconfirmedBadge>
							}
							name="Tätigkeiten"
							path="/vehicles/services"
							matchProps={{
								path: ["/vehicles/services", "/billings"],
							}}
						/>
						<MenuItem roles={[UserRole.AldUser]} icon={<Money />} name="Verkauf" path="/sales/dashboard" />
						<MenuItem
							roles={[UserRole.AldUser]}
							icon={<CarRental />}
							name="Flex"
							path="/pool-fleet/vehicles"
						/>
						<MenuItem
							roles={[
								UserRole.AldUser,
								UserRole.LotManager,
								UserRole.LogisticCompany,
								UserRole.UserAdmin,
							]}
							icon={<AccountBox />}
							name="Benutzer"
							path="/users"
						/>
						<MenuItem
							roles={[UserRole.AldUser, UserRole.LotManager]}
							icon={<EmojiTransportation />}
							name="Dekra"
							path="/companies/dekra"
							companies={["Dekra", "ALD"]}
						/>
						<MenuItem roles={[UserRole.AldAdmin]} icon={<Task />} name="Verwaltung" path="/admin/jobs" />
						<MenuItem
							roles={[UserRole.AldUser, UserRole.LotManager, UserRole.Driver, UserRole.LogisticCompany]}
							icon={<Settings />}
							name="Einstellungen"
							path="/settings"
						/>
					</>
				)}
				<Box
					sx={{
						flexGrow: 1,
						flexDirection: "column",
						display: "flex",
						alignSelf: "flex-start",
						justifyContent: "flex-end",
						width: "100%",
					}}
				>
					{user && hasRole(UserRole.AldUser) && (
						<MenuItem
							onClick={() => window.open("https://jira.ald.de/servicedesk/customer/portal/4", "_blank")}
							icon={<Help />}
							name="Hilfe"
						/>
					)}
					{user && !hasRole(UserRole.AldUser) && !hasRole(UserRole.Customer) && (
						<MenuItem onClick={() => history.push(`/help`)} icon={<Help />} name="Hilfe" />
					)}
					{hasRole(UserRole.Customer) && (
						<MenuItem
							onClick={() => history.push("/orders/customers/faq")}
							icon={<Help />}
							name="Hilfe / FAQ"
						/>
					)}
					{user && <MenuItem onClick={() => setLogout(true)} icon={<ExitToApp />} name="Abmelden" />}
				</Box>
			</Box>
		</Box>
	);
};
