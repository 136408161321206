import { useHistory } from "react-router";

import { Check, Close, ExpandLess, ExpandMore, Refresh } from "@mui/icons-material";
import { Box, Divider, List, ListItem, Typography, useTheme } from "@mui/material";

import ListItemChip from "../../Components/ListItemChip";
import ListItemChips from "../../Components/ListItemChips";
import Tile from "../../Components/Tiles/Tile";
import TileContent from "../../Components/Tiles/TileContent";
import { RemarketingUnregistration, Vehicle } from "../../system/Domain";
import formatDateTime from "../../system/formatDateTime";
import TextGroup from "../VehicleSales/TextGroup";
import TextLine from "../VehicleSales/TextLine";
import { translateVehicleRegistrationDocumentStatus } from "./DocumentManagement/VehicleRegistrationDocumentsStatusPill";
import { useCallback, useState } from "react";
import axios from "axios";
import fileDownload from "js-file-download";
import parseContentDispositionFilename from "../../system/parseContentDispositionFilename";
import moment from "moment";
import dateFormats from "system/dateFormats";
import TextBlock from "Components/Layout/TextBlock";
import ActionButton from "Components/ActionButton";

interface Props {
	vehicle: Vehicle;
}

const RemarketingUnregistrationTile = ({ vehicle }: Props) => {
	const history = useHistory();
	const theme = useTheme();

	const isUnregistered = vehicle.remarketing.isUnregistered;
	const hasOrderId = !!vehicle.remarketing.unregistration?.orderId;

	const registrationDocumentsAreAtLeastRequested =
		vehicle.remarketing.registrationDocuments?.isAtLeastRequested === true;

	const enteredUnregistered = isUnregistered && !hasOrderId;
	const successfullyUnregistered =
		isUnregistered && hasOrderId && !!vehicle.remarketing.unregistration?.dateCompleted;
	const orderMissing = !isUnregistered && !hasOrderId;
	const unfinishedUnregistration = !isUnregistered && hasOrderId;

	const DADDocumentChip = ({ name, docType }: { name: string; docType: string }) => {
		const [isDownloadingDAD, setIsDownloadingDAD] = useState(false);

		const downloadDAD = useCallback(async () => {
			setIsDownloadingDAD(true);
			try {
				const response = await axios.get(`/api/vehicles/${vehicle.id}/documents/${docType}.pdf`, {
					responseType: "blob",
				});

				const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
				fileDownload(response.data, filename);
			} finally {
				setIsDownloadingDAD(false);
			}
		}, [vehicle]);

		return (
			<ListItemChip
				label={
					<Box sx={{ display: "flex", flexDirection: "column" }}>
						<Typography align="center" color={theme.palette.info.main} sx={{ fontSize: "0.7rem" }}>
							{name}
						</Typography>
					</Box>
				}
				loading={isDownloadingDAD}
				onClick={isDownloadingDAD ? null : downloadDAD}
			/>
		);
	};

	const [unregistrationHistoryLength, setUnregistrationHistoryLength] = useState(
		vehicle.remarketing.unregistration?.history?.length,
	);
	const [historyList, setHistoryList] = useState(vehicle.remarketing.unregistration?.history || []);
	const [historyExpanded, setHistoryExpanded] = useState(false);
	const [isRefreshing, setIsRefreshing] = useState(false);
	const refresh = async () => {
		setIsRefreshing(true);
		try {
			const { data } = await axios.post<RemarketingUnregistration>(
				`/api/vehicles/${vehicle.id}/remarketing/unregistration/refresh-history`,
				{},
			);
			setUnregistrationHistoryLength(data.history?.length);
			setHistoryList(data.history || []);
		} finally {
			setIsRefreshing(false);
		}
	};

	const UnregistrationHistory = () => {
		return (
			<Box sx={{ display: "flex", direction: "row" }}>
				<List dense disablePadding sx={{ flexGrow: 1 }}>
					{historyList.map((h) => {
						const taskStart = moment(h.startDate);
						return (
							<>
								<ListItem key={h.taskNumber} sx={{ paddingLeft: 0 }}>
									<TextBlock
										reduced
										primary={h.taskName}
										secondary={taskStart.format(dateFormats.dateTime)}
									/>
								</ListItem>
							</>
						);
					})}
					{historyList.length === 0 && (
						<ListItem>
							<TextBlock reduced primary="Keine Daten gefunden oder noch nicht geladen" />
						</ListItem>
					)}
				</List>
				<Box mr={2}>
					<ActionButton icon hideOnDisabled={false} disabled={isRefreshing} onClick={() => refresh()}>
						<Refresh />
					</ActionButton>
				</Box>
			</Box>
		);
	};

	return (
		<Tile title="Abmeldung">
			<TileContent>
				{enteredUnregistered && (
					<TextGroup title="Extern abgemeldet">
						<Box>
							<Typography variant="caption" color={theme.palette.warning.light}>
								Angabe aus der Rückholung, Verifizierung steht aus...
							</Typography>
						</Box>
						<TextLine label="Abmeldedatum" value={formatDateTime(vehicle.dateUnregistered) || "-"} />
					</TextGroup>
				)}
				{successfullyUnregistered && (
					<TextGroup title="Abgemeldet">
						<TextLine
							label="Beauftragt"
							value={formatDateTime(vehicle.remarketing.unregistration.dateStarted)}
						/>
						<TextLine
							label="Abgeschlossen"
							value={formatDateTime(vehicle.remarketing.unregistration.dateCompleted)}
						/>
					</TextGroup>
				)}

				{orderMissing && (
					<TextGroup title="Abmeldung nicht beauftragt">
						<TextLine label="Abmeldedatum" value={formatDateTime(vehicle.dateUnregistered) || "-"} />
					</TextGroup>
				)}

				{unfinishedUnregistration && (
					<TextGroup title="Abmeldung beauftragt">
						<TextLine
							label="Beauftragt"
							value={formatDateTime(vehicle.remarketing.unregistration.dateStarted)}
						/>
					</TextGroup>
				)}

				<Box mt={1}>
					<ListItemChips>
						<ListItemChip
							label={`${unregistrationHistoryLength ?? "? DAD"} ${unregistrationHistoryLength === 1 ? "Verlaufseintrag" : "Verlaufseinträge"}`}
							icon={historyExpanded ? <ExpandLess /> : <ExpandMore />}
							active={historyExpanded}
							onClick={() => setHistoryExpanded((he) => !he)}
						/>
					</ListItemChips>
				</Box>
				{historyExpanded && (
					<Box mt={1} maxHeight={350} overflow="auto">
						<UnregistrationHistory />
						<Box mt={1}>
							<Divider />
						</Box>
					</Box>
				)}

				<TextGroup title="Downloads">
					<ListItemChips sx={{ paddingTop: 0.5 }}>
						<DADDocumentChip name="ZLB I" docType="zlb1" />
						<DADDocumentChip name="ZLB II" docType="zlb2" />
						<DADDocumentChip name="COC" docType="coc" />
					</ListItemChips>
				</TextGroup>

				{vehicle.remarketing.registrationDocuments && (
					<TextGroup title="Papiere">
						<TextLine
							label="Status"
							value={translateVehicleRegistrationDocumentStatus(
								vehicle.remarketing.registrationDocuments?.status,
							)}
						/>
					</TextGroup>
				)}

				<Box mt={1}>
					<ListItemChips>
						{!successfullyUnregistered && (
							<ListItemChip
								label={isUnregistered ? "Abgemeldet" : "Zugelassen"}
								icon={isUnregistered ? <Check /> : <Close />}
								color={isUnregistered ? "warning" : "error"}
							/>
						)}
						{successfullyUnregistered && (
							<ListItemChip
								label="Abgemeldet"
								icon={isUnregistered ? <Check /> : <Close />}
								color="success"
							/>
						)}
						<ListItemChip
							label={hasOrderId ? "Auftrag" : "Kein Auftrag"}
							icon={hasOrderId ? <Check /> : <Close />}
							color={hasOrderId ? "primary" : isUnregistered ? "info" : "error"}
							onClick={
								hasOrderId
									? () =>
											history.push(
												`orders/unregistrations/${vehicle.remarketing.unregistration.orderId}`,
											)
									: () =>
											history.push(`/orders/unregistrations/new`, {
												plateNumber: vehicle.plateNumber,
											})
							}
						/>
						<ListItemChip
							label={registrationDocumentsAreAtLeastRequested ? "Abgefragt" : "Nicht Abgefragt"}
							icon={registrationDocumentsAreAtLeastRequested ? <Check /> : <Close />}
							color={registrationDocumentsAreAtLeastRequested ? "success" : "error"}
						/>
					</ListItemChips>
				</Box>
			</TileContent>
		</Tile>
	);
};

export default RemarketingUnregistrationTile;
