import axios from "axios";
import { Compound } from "./Domain";
import useGlobalPromise from "./useGlobalPromise";

interface UseCompoundsProps {
	includeAmazonCompounds: boolean;
}

export default (props: UseCompoundsProps) =>
	useGlobalPromise("ald_compounds", async () => (await axios.get<Compound[]>(`/api/compounds`, {})).data, props.includeAmazonCompounds === false ? (data) => data.filter(item => item.amazonRemarketing === false) : undefined);
