import { ChangeEvent, useEffect } from 'react';
import { Form } from "../system/useForm";
import { TextField, TextFieldProps } from "@mui/material";

type Props = TextFieldProps & {
	name : string
	form : Form
	required? : boolean
	email? : boolean
	maxLength? : number
}

const FormTextField = (props : Props) => {
	useEffect(() => {
		props.form.register(props.name, {
			required: props.required ?? false,
			email: props.email ?? false
		});

		return () => {
			props.form.unregister(props.name);
		};
	}, [props.name]);

	const handleChange = (event : ChangeEvent<HTMLInputElement>) => {
		const el = (event.target as HTMLInputElement);

		props.form.setValue(el.name, el.value);
	};

	const variant = props.variant ?? 'standard';

	return (
		<TextField
			value={props.form.getValue(props.name) ?? props.defaultValue ?? ''}
			fullWidth={props.fullWidth ?? false}
			error={!!props.form.errors[props.name]}
			name={props.name}
			disabled={props.disabled ?? false}
			label={props.label}
			onChange={handleChange}
			variant={variant}
			inputProps={props.maxLength ? { maxLength: props.maxLength } : undefined}
		/>
	);
};

export default FormTextField;
