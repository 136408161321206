import { CircularProgress } from "@mui/material";
import axios from "axios";
import moment from "moment";
import AdminNavigation from "Navigation/AdminNavigation";
import { useState } from "react";
import Layout from "../../../Components/Layout/Layout";
import List from "../../../Components/List";
import { Job, JobStatus } from "../../../system/Domain";
import useAxiosEffect from "../../../system/useAxiosEffect";
import useChannel from "../../../system/useChannel";
import useEvent from "../../../system/useEvent";
import useStoredState from "../../../system/useStoredState";
import JobListItem from "./JobListItem";
import JobsSidebar from "./JobsSidebar";

export interface JobQuery {
	take?: number;
	skip?: number;

	states?: JobStatus[];
	types?: string[];
	onlyRecurring?: boolean;
}

export default () => {
	const [jobs, setJobs] = useState<Job[]>([]);
	const [query, setQuery] = useStoredState<JobQuery>("jobQuery", {});

	useAxiosEffect(
		async (config) => {
			const { data: jobs } = await axios.get<Job[]>(`/api/admin/jobs`, {
				...config,
				params: query,
			});

			setJobs(jobs);
		},
		[query],
	);

	useChannel("jobs");

	useEvent("job:updated", (data) => {
		const job = data as Job;
		if (!job) {
			return;
		}

		setJobs((jobs) =>
			[...jobs.filter((j) => j.id !== job.id), job].sort(
				(a, b) => moment(b.dateCreated).valueOf() - moment(a.dateCreated).valueOf(),
			),
		);
	});

	return (
		<Layout
			title="Jobs"
			navigation={<AdminNavigation />}
			sidebar={<JobsSidebar query={query} setQuery={setQuery} />}
		>
			{!jobs && <CircularProgress size={48} />}
			{jobs && (
				<List>
					{jobs.map((j) => (
						<JobListItem key={j.id} job={j} />
					))}
				</List>
			)}
		</Layout>
	);
};
