import { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';

import { Add, RotateLeft } from '@mui/icons-material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import Sort, { SortItem } from 'Components/Sort';
import Sidebar from '../../Components/Sidebar/Sidebar';
import SidebarButton from '../../Components/Sidebar/SidebarButton';
import SidebarForm from '../../Components/Sidebar/SidebarForm';
import SidebarGroup from '../../Components/Sidebar/SidebarGroup';
import { BillingStatus } from '../../system/Domain';
import useCompanies from '../../system/useCompanies';
import { BillingQuery } from './Billings';
import useBillingsPermissions from './useBillingPermissions';

export interface BillingsSidebarProps {
	query: BillingQuery
	setQuery: Dispatch<SetStateAction<BillingQuery>>
	resetQuery: () => BillingQuery
}

const sortItems: SortItem[] = [
	{
		title: "Erstellt",
		field: "dateCreated"
	},
	{
		title: "Zeitraum",
		field: "dateTo"
	},
	{
		title: "Nr",
		field: "nr"
	},
];

export default ({ query, setQuery, resetQuery }: BillingsSidebarProps) => {
	const history = useHistory();
	const permissions = useBillingsPermissions();
	const [companies] = useCompanies();

	return (
		<Sidebar>
			<SidebarGroup>
				{permissions.canCreate && (
					<SidebarButton
						color="primary"
						startIcon={<Add />}
						label="Neue Abrechnung"
						onClick={() => history.push('/billings/new')}
					/>
				)}
			</SidebarGroup>
			<SidebarGroup>
				<SidebarForm>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Status</InputLabel>
						<Select
							value={query.status || "-"}
							onChange={(e) => {
								let value = e.target.value as string | undefined;
								if (value === "-") {
									value = undefined;
								}

								setQuery(q => ({
									...q,
									skip: 0,
									status: value as BillingStatus
								}));
							}}
						>
							<MenuItem value="-">Alle</MenuItem>
							<MenuItem value="Created">Erstellt</MenuItem>
							<MenuItem value="Closed">Abgerechnet</MenuItem>
							<MenuItem value="Approved">Geprüft</MenuItem>
						</Select>
					</FormControl>

					<Sort
						items={sortItems}
						value={query.sort}
						onChange={sort => setQuery(q => ({
							...q,
							skip: 0,
							sort
						}))}
					/>

					{companies && !permissions.isRestrictedToHisCompany && (
						<FormControl variant="standard" fullWidth>
							<InputLabel>Dienstleister</InputLabel>
							<Select
								value={query.company || "-"}
								onChange={(e) => {
									let value = e.target.value as string | undefined;
									if (value === "-") {
										value = undefined;
									}

									setQuery(g => ({
										...g,
										skip: 0,
										company: value
									}));
								}}
							>
								<MenuItem value="-">Alle</MenuItem>
								{companies.filter(c => c.services?.allowAccounting).map(c => (
									<MenuItem key={c.name} value={c.name}>{c.name}</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
				</SidebarForm>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarButton
					startIcon={<RotateLeft />}
					label="Filter zurücksetzen"
					onClick={resetQuery}
				/>
			</SidebarGroup>
		</Sidebar>
	);
}
