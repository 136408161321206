import { useState } from 'react';
import Layout from "../../../Components/Layout/Layout";
import OrdersSubMenu from "../../Orders/OrdersSubMenu";
import { Box, CircularProgress } from "@mui/material";
import VehicleArrivalsOverviewTable from "./VehicleArrivalsOverviewTable";
import FetchNextButton from "../../../Components/FetchNextButton";
import useQuery, { IQuery } from "../../../system/useQuery";
import useAsyncEffect from "../../../system/useAsyncEffect";
import axios from "axios";
import VehicleArrivalsOverviewSidebar from "./VehicleArrivalsOverviewSidebar";
import OrdersNavigation from 'Navigation/OrdersNavigation';
import { VehicleArrival } from 'system/Domain';

export interface VehicleArrivalsOverviewQuery extends IQuery {
	sort: string
}

const VehicleArrivalsOverview = () => {
	const [vehicleArrivals, setVehicleArrivals] = useState<VehicleArrival[]>();
	const [isFetching, setIsFetching] = useState(false);
	const [query, setQuery, fetchNext, resetQuery] = useQuery<VehicleArrivalsOverviewQuery>('returnOrderSessionOverviewQuery', {
		skip: 0,
		take: 20,
		sort: "dateCreated:desc"
	});

	useAsyncEffect(async (cancelToken) => {
		const axiosCancelTokenSource = axios.CancelToken.source();
		cancelToken.promise.then(() => axiosCancelTokenSource.cancel());

		setIsFetching(true);

		try {
			const { data: vehicleArrivals } = await axios.get<VehicleArrival[]>(`/api/vehicles/arrivals`, {
				params: query,
				cancelToken: axiosCancelTokenSource.token
			});

			if (query.skip > 0) {
				setVehicleArrivals(o => [
					...(o ?? []),
					...vehicleArrivals
				]);
			} else {
				setVehicleArrivals(vehicleArrivals)
			}
		} finally {
			setIsFetching(false);
		}
	}, [query]);

	return (
		<Layout
			title="Anlieferungen"
			navigation={<OrdersNavigation />}
			subMenu={<OrdersSubMenu />}
			sidebar={<VehicleArrivalsOverviewSidebar query={query} setQuery={setQuery} resetQuery={resetQuery} />}
		>
			<Box mb={2}>
				{!vehicleArrivals && (
					<CircularProgress size={48} />
				)}
				{vehicleArrivals && (
					<>
						<VehicleArrivalsOverviewTable
							vehicleArrivals={vehicleArrivals}
						/>
						<FetchNextButton
							hidden={vehicleArrivals.length < query.take + query.skip}
							mb={3}
							onNext={fetchNext}
							disabled={isFetching}
						/>
					</>
				)}
			</Box>
		</Layout>
	);
};

export default VehicleArrivalsOverview;
