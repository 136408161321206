import axios from "axios";
import useAction from "../../system/useAction";
import { Dispatch, SetStateAction } from "react";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { LeasmanVehicle, Vehicle } from "../../system/Domain";
import SidebarGroup from "../../Components/Sidebar/SidebarGroup";
import SidebarButton from "../../Components/Sidebar/SidebarButton";

export interface Props {
	vehicle: Vehicle
	setVehicle: Dispatch<SetStateAction<Vehicle | undefined>>
	leasmanVehicle?: LeasmanVehicle
}

export default ({ vehicle, setVehicle, leasmanVehicle }: Props) => {
	if (!vehicle) {
		return null;
	}

	// const [] = useUser(); wrongarray pattern

	const [synchronize, isSynchronizing] = useAction(async () => {
		axios.post(`/api/vehicles/${vehicle.id}/synchronize`, {});
	});

	const [setLeasmanVehicle, isSettingLeasmanVehicle] = useAction(async () => {
		if (!leasmanVehicle) {
			return;
		}

		const { data } = await axios.put(`/api/vehicles/${vehicle.id}/leasman`, {
			leasmanVehicleId: leasmanVehicle.id,
		});

		setVehicle(data);
	});

	return (
		<Sidebar>
			<SidebarGroup>
				{leasmanVehicle && leasmanVehicle.id !== vehicle.remarketing.leasmanVehicleId && (
					<SidebarButton
						label="Leasman Vertrag zuweisen"
						color="primary"
						onClick={setLeasmanVehicle}
						disabled={isSettingLeasmanVehicle}
					/>
				)}
				{/*<SidebarButton*/}
				{/*	label="Fahrzeugtyp zuweisen"*/}
				{/*	color="primary"*/}
				{/*	disabled*/}
				{/*/>*/}
			</SidebarGroup>
			<SidebarGroup>
				<SidebarButton
					label="Synchronisieren"
					disabled={isSynchronizing}
					onClick={synchronize}
				/>
			</SidebarGroup>
		</Sidebar>
	);
};
