import axios from 'axios';
import { useCallback, useState } from 'react';
import { CloudDownload, Delete, Edit } from '@mui/icons-material';
import {
	Box, Chip, Grid, IconButton, TableBody, TableHead, TableRow
} from '@mui/material';
import TextBlock from '../../Components/Layout/TextBlock';
import PaperTable from '../../Components/PaperTable';
import ask from '../../Dialogs/ask';
import { Billing, BillingInvoice } from '../../system/Domain';
import formatCurrency from '../../system/formatCurrency';
import formatDateTime from '../../system/formatDateTime';
import formatFilesize from '../../system/formatFilesize';
import editInvoice from './editInvoice';
import { BillingPropsWithSetter } from './useBilling';
import useBillingInvoiceDownload from './useBillingInvoiceDownload';
import useBillingPermissions from './useBillingPermissions';
import { Colgroup, TableCell } from "../../Components/BreakpointStyledComponents";

export interface BillingInvoiceTableProps extends BillingPropsWithSetter {
	invoices: BillingInvoice[]
	showClient?: boolean
}

export default ({ billing, setBilling, invoices, showClient = false }: BillingInvoiceTableProps) => {
	const permissions = useBillingPermissions();
	const [downloadInvoice, , isDownloading] = useBillingInvoiceDownload();
	const [isDeleting, setIsDeleting] = useState(false);

	const deleteInvoice = useCallback(async (invoice: BillingInvoice) => {
		try {
			setIsDeleting(true);

			const { yes } = await ask(`Soll die Rechnung '${invoice.file.filename}' entfernt werden?`);
			if (!yes) {
				return;
			}

			const { data } = await axios.delete<Billing>(`/api/billings/${billing.id}/invoices/${invoice.file.hash}`);
			setBilling(data);
		} finally {
			setIsDeleting(false);
		}
	}, []);

	const [isEditing, setIsEditing] = useState(false);

	return (
		<PaperTable>
			{showClient && (
				<Colgroup sm md lg xl>
					<col width="20%" />
					<col width="30%" />
					<col width="30%" />
					<col width="20%" />
				</Colgroup>
			)}
			{!showClient && (
				<Colgroup sm md lg xl>
					<col width="40%" />
					<col width="40%" />
					<col width="20%" />
					<col width="20%" />
				</Colgroup>
			)}
			<TableHead>
				<TableRow>
					<TableCell xs>Rechnung</TableCell>
					{showClient && (
						<TableCell sm md lg xl>Mandant</TableCell>
					)}
					<TableCell sm md lg xl>Rechnung</TableCell>
					<TableCell sm md lg xl>Betrag</TableCell>
					<TableCell sm md lg xl>Aktionen</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{invoices.map(invoice => {
					const client = showClient && (
						<Chip label={invoice.client} />
					);

					const title = (
						<TextBlock
							primary={invoice.file.filename}
							secondary={<span>{formatFilesize(invoice.size)} · Hochgeladen am {formatDateTime(invoice.dateUploaded)}</span>}
						/>
					);

					const amount = (
						<TextBlock
							primary={!invoice.netAmount ? "-" : formatCurrency(invoice.netAmount)}
						/>
					);

					const actions = (
						<Grid container spacing={2}>
							<Grid item>
								<IconButton
									disabled={isDownloading}
									onClick={() => downloadInvoice(billing, invoice)}
									size="small"
								>
									<CloudDownload />
								</IconButton>
							</Grid>
							<Grid item>
								<IconButton
									onClick={async () => {
										try {
											setIsEditing(true);

											await editInvoice({ invoice });

											const { data } = await axios.put<Billing>(`/api/billings/${billing.id}`, {
												invoices: billing.invoices
											});
											setBilling(data);
										} finally {
											setIsEditing(false);
										}
									}}
									disabled={isEditing}
									size="small"
								>
									<Edit />
								</IconButton>
							</Grid>
							{permissions.canUploadInvoices(billing) && (
								<Grid item>
									<IconButton
										onClick={() => deleteInvoice(invoice)}
										disabled={isDeleting}
										size="small"
									>
										<Delete />
									</IconButton>
								</Grid>
							)}
						</Grid>
					);

					return (
						<TableRow
							key={invoice.file.hash}
							hover
						>
							<TableCell xs>
								{showClient && (
									<Box mb={2}>
										{client}
									</Box>
								)}
								{title}
								<Box mt={2}>
									{amount}
								</Box>
								<Box mt={2}>
									{actions}
								</Box>
							</TableCell>
							{showClient && (
								<TableCell sm md lg xl>
									{client}
								</TableCell>
							)}
							<TableCell sm md lg xl>
								{title}
							</TableCell>
							<TableCell sm md lg xl>
								{amount}
							</TableCell>
							<TableCell sm md lg xl>
								{actions}
							</TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		</PaperTable>
	);
}
