import { List, Typography } from "@mui/material";
import ExpertAssesmentDefectListItem from "./ExpertAssesmentDefectListItem";
import ListItemActions from "../../../Components/ListItemActions";
import ListItemAction from "../../../Components/ListItemAction";
import { Add } from "@mui/icons-material";
import ListItemContent from "../../../Components/ListItemContent";
import { useState } from "react";
import ListItem from "../../../Components/ListItem";
import { Form } from "system/useForm";
import { AssessmentOrderDefectItem } from "system/Domain";

export interface ItemWithDefects {
	defectItems: AssessmentOrderDefectItem[]
}

export interface Props {
	defectable: ItemWithDefects
	isInEditMode: boolean
	form: Form
}

export default ({ defectable, isInEditMode, form }: Props) => {
	const localIdPrefix = 'local';
	const [newDefectItem, setNewDefectItem] = useState<Partial<AssessmentOrderDefectItem>>();
	const [currentId, setCurrentId] = useState(0);

	const getLocalId = () => {
		const itemId = currentId;
		setCurrentId(currentId + 1);
		return localIdPrefix + itemId;
	};

	const newAssessmentDefectItem = () => {
		setNewDefectItem({ id: getLocalId() });
	};

	const cancel = () => {
		setNewDefectItem(undefined);
	};

	const onAddingNewItem = (defectItem: Partial<AssessmentOrderDefectItem>) => {
		if (!defectable.defectItems) {
			defectable.defectItems = [];
		}

		const newDefectItemIsValid = (defectItem: Partial<AssessmentOrderDefectItem>): defectItem is AssessmentOrderDefectItem => !!defectItem.description;

		if (!newDefectItemIsValid(defectItem)) {
			throw new Error("New defect item is not valid!");
		}

		defectable.defectItems.push(defectItem);
		form.setValue("defectItems", defectable.defectItems);
		setNewDefectItem(undefined);
	};

	const onDeletingItem = (id: string) => {
		form.setValue("defectItems", defectable.defectItems.filter((item) => item.id !== id));
	};

	return (
		<List>
			{defectable?.defectItems?.map((defectItem) => (
				<ExpertAssesmentDefectListItem
					key={`ExpertAssesmentDefectListItem-${defectItem.id}`}
					defectItem={defectItem}
					disabled={!isInEditMode}
					onDeletingItem={onDeletingItem}
				/>
			))}

			{isInEditMode && newDefectItem && (
				<ExpertAssesmentDefectListItem
					defectItem={newDefectItem}
					new
					cancel={cancel}
					onAddingNewItem={onAddingNewItem}
				/>
			)}

			{isInEditMode && (
				<ListItem transparent>
					<ListItemActions>
						<ListItemAction
							icon={<Add />}
							onClick={newAssessmentDefectItem}
						/>
					</ListItemActions>
					<ListItemContent onClick={newAssessmentDefectItem}>
						<Typography variant="subtitle2">
							Mängelposition hinzufügen
						</Typography>
					</ListItemContent>
				</ListItem>
			)}
		</List>
	);
}
