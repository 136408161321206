import axios from "axios";
import EmptyList from "Components/EmptyList";
import List from "Components/List";
import ListTitle from "Components/ListTitle";
import LoadingIndicator from "Components/LoadingIndicator";
import Stack from "Components/Stack";
import Tile, { TileProps } from "Components/Tiles/Tile";
import _ from "lodash";
import moment, { Moment } from "moment";
import { PropsWithChildren, useEffect, useState } from "react";
import { BcaAuction, BcaAuctionQuery, DateFormat } from "system/Domain";
import useAxiosEffect from "system/useAxiosEffect";
import useChannel from "system/useChannel";
import useEvent from "system/useEvent";
import BcaAuctionListItem from "./BcaAutionListItem";

interface Props extends TileProps {
	query: BcaAuctionQuery;
	onDelete?: (bcaAuction: BcaAuction) => Promise<void>;
	dateFormat?: DateFormat;
	showMail?: boolean;
}

interface Group {
	date: Moment;
	files: BcaAuction[];
}

export default ({ query, onDelete, dateFormat, showMail = false, children, ...rest }: PropsWithChildren<Props>) => {
	const [bcaAuctions, setBcaAuctions] = useState<BcaAuction[]>();
	const [loadingBcaAuctions, loadBcaAuctions] = useAxiosEffect(async (config) => {
		const { data } = await axios.post<BcaAuction[]>(`/api/sales/bca/auctions/search`, query, config);
		setBcaAuctions(data);
	}, []);

	const [groups, setGroups] = useState<Group[]>();
	useEffect(() => {
		setGroups(
			Object.entries(_.groupBy(bcaAuctions, (b) => moment(b.dateCreated).startOf("day").format())).map(
				([date, files]) => ({ date: moment(date), files }),
			),
		);
	}, [bcaAuctions]);

	useChannel("bca-auctions");

	useEvent("bca-auction:created", async (data) => {
		await loadBcaAuctions();
	});

	useEvent(
		"bca-auction:updated",
		async (data) => {
			const { id, version } = data;

			if (!bcaAuctions || !id || !version) return;

			const bcaAuction = bcaAuctions.find((b) => b.id === id);

			if (bcaAuction && bcaAuction.version < version) {
				await loadBcaAuctions();
			}
		},
		[bcaAuctions],
	);

	useEvent(
		"bca-auction:deleted",
		async (data) => {
			const { id } = data;

			if (!bcaAuctions || !id) return;

			const bcaAuction = bcaAuctions.find((b) => b.id === id);

			if (bcaAuction) {
				setBcaAuctions(bcaAuctions.filter((b) => b.id !== bcaAuction.id));
			}
		},
		[bcaAuctions],
	);

	return (
		<Tile title="Auktionen" {...rest}>
			{children}
			{bcaAuctions?.length === 0 && <EmptyList />}
			{groups && (
				<Stack spacing={6}>
					{groups.map((g) => (
						<List key={g.date.toISOString()}>
							<ListTitle>{g.date.format("D. MMMM YYYY")}</ListTitle>
							{g.files.map((b) => (
								<BcaAuctionListItem key={b.id} auction={b} />
							))}
						</List>
					))}
				</Stack>
			)}
			{loadingBcaAuctions && <LoadingIndicator />}
		</Tile>
	);
};
