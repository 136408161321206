import { UserRole } from "system/Domain";
import LayoutNavigationItem from "../Components/Layout/LayoutNavigationItem";
import { AccountBalance, ClearAll, PlaylistAddCheck } from "@mui/icons-material";
import LayoutNavigationSection from "../Components/Layout/LayoutNavigationSection";

export default () => {
	return (
		<LayoutNavigationSection
			title="Papiere"
		>
			<LayoutNavigationItem
				roles={[UserRole.AldUser]}
				icon={<PlaylistAddCheck />}
				title="Abmeldungen"
				path="/vehicles/registration-documents/imports/unregistrations"
			/>
			<LayoutNavigationItem
				roles={[UserRole.AldUser]}
				icon={<AccountBalance />}
				title="Ankunft"
				path="/vehicles/registration-documents/imports/arrivals"
			/>
			<LayoutNavigationItem
				roles={[UserRole.AldUser]}
				icon={<ClearAll />}
				title="Ausgänge"
				path="/vehicles/registration-documents/imports/handovers"
			/>
		</LayoutNavigationSection>
	);
};
