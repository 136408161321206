import { Button } from "@mui/material";
import { Sale } from "system/Domain";
import useAction from "system/useAction";
import ask from "../../Dialogs/ask";
import axios from "axios";

export interface Props {
	sale: Sale;
	setSale: (s: Sale) => void;
}

export default ({ sale, setSale }: Props) => {
	const [cancel, isCancelling] = useAction(async () => {
		const answer = await ask("Wollen Sie wirklich den Verkauf abbrechen?");
		if (answer.yes) {
			const { data } = await axios.post<Sale>(`/api/sales/${sale.id}/cancel`);

			setSale(data);
		}
	});

	const [undo, isUndoing] = useAction(async () => {
		const answer = await ask("Wollen Sie den Verkauf Abbruch rückgängig machen?");
		if (answer.yes) {
			const { data } = await axios.post<Sale>(`/api/sales/${sale.id}/undo-cancel`);

			setSale(data);
		}
	});

	return (
		<>
			{sale.status === "Cancelled" && (
				<Button variant="text" color="primary" disabled={isUndoing} onClick={undo}>
					Verkauf Abbruch rückgängig machen
				</Button>
			)}
			{sale.status === "ReadyToSell" && (
				<Button variant="text" color="primary" disabled={isCancelling} onClick={cancel}>
					Verkauf abbrechen
				</Button>
			)}
		</>
	);
};
