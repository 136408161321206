import { Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import axios from "axios";
import moment from "moment";
import OrdersNavigation from "Navigation/OrdersNavigation";
import { useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import ActionButton from "../../../Components/ActionButton";
import Layout from "../../../Components/Layout/Layout";
import Thumbnail from "../../../Components/Thumbnail";
import Tile from "../../../Components/Tiles/Tile";
import TileActions from "../../../Components/Tiles/TileActions";
import TileContent from "../../../Components/Tiles/TileContent";
import {
	AssessmentOrderStatusInformation,
	CompoundRegistrationReference,
	ReturnOrder,
	UserRole,
	VehiclePicture
} from "../../../system/Domain";
import useAsyncEffect from "../../../system/useAsyncEffect";
import useOrderFromMatch from "../../../system/useOrderFromMatch";
import useUser from "../../../system/useUser";
import VehicleInformation from "../Returns/VehicleInformation";
import Address from "./Address";
import ReturnForms from "./ReturnForms/ReturnForms";
import TransportOrderEventTable from "./TransportOrderEventTable";
import OrderHeader from "./TransportOrderHeader";
import TransportOrderNoteTable from "./TransportOrderNoteTable";
import TransportOrderNotificationsTable from "./TransportOrderNotificationsTable";
import OrderScanContextMenu from "./TransportOrderScanContextMenu";
import TransportOrderSidebar from "./TransportOrderSidebar";
import TransportOrderEmailCommunicationTable from "./TransportOrderEmailCommunicationTable";
import TransportProtocolTable from "Pages/Vehicles/TransportProtocolTable";

interface LinkedOrders {
	assessmentOrderId?: string;
	assessmentId?: string;
	unregistrationOrderId?: string;
}

export default () => {
	const [events, setEvents] = useState([]);
	const [order, setOrder] = useOrderFromMatch();
	const [returnOrder, setReturnOrder] = useState<ReturnOrder | null>();
	const [assessmentOrderStatusInformation, setAssessmentOrderStatusInformation] = useState<AssessmentOrderStatusInformation>();
	const [linkedOrders, setLinkedOrders] = useState<LinkedOrders>();
	const history = useHistory();
	const [compoundReference, setCompoundReference] = useState<CompoundRegistrationReference>();
	const [vehiclePicture, setVehiclePicture] = useState<VehiclePicture | null>();
	const [, , hasRole, , hasAnyRole] = useUser();

	const canSeeReturnOrderInformation = hasAnyRole([UserRole.AldUser, UserRole.LogisticCompany, UserRole.LotManager]);
	const canSeeInternalNotes = hasRole(UserRole.AldUser);

	useAsyncEffect(async () => {
		if (!order || !order.getHistory) return;

		const events = await order.getHistory();

		setEvents(events);
	}, [order?.id]);

	useAsyncEffect(async () => {
		if (!order || !order.getCompoundReference || order.vehicleId) {
			setCompoundReference(undefined);

			return;
		}

		const compoundReference = await order.getCompoundReference();

		setCompoundReference(compoundReference);
	}, [order?.id]);

	useAsyncEffect(async () => {
		if (!order) {
			setVehiclePicture(null);

			return;
		}

		const vehiclePicture = await order.getVehiclePicture();

		setVehiclePicture(vehiclePicture);
	}, [order?.id]);

	useAsyncEffect(async () => {
		if (!order || !order.returnOrderId || !canSeeReturnOrderInformation) {
			return;
		}

		const response = await axios.get<ReturnOrder[]>("/api/orders/returns", {
			params: {
				id: order.returnOrderId
			}
		});

		setReturnOrder(response.data.length === 1 ? response.data[0] : null);
	}, [order?.id]);

	useAsyncEffect(async () => {
		if (!order) return;

		const vehicleId = order.vehicleId ?? order.vehicle.id;

		if (!vehicleId) return;

		const response = await axios.get<LinkedOrders>(`/api/vehicles/${vehicleId}/linked`);

		setLinkedOrders(response.data);
	}, [order?.id]);

	useAsyncEffect(async () => {
		if (!linkedOrders || !linkedOrders.assessmentOrderId) return;

		const response = await axios.get<AssessmentOrderStatusInformation>(
			`/api/orders/assessments/${linkedOrders.assessmentOrderId}/status`
		);

		setAssessmentOrderStatusInformation(response.data);
	}, [linkedOrders]);

	const reloadAfter = (func: () => Promise<any> | null) => {
		if (!func) return undefined;

		return async () => {
			await func();

			const response = await axios.get("/api/orders/transports/" + order!.id);
			setOrder(response.data);
		};
	};

	if (!order) return null;

	const { leasmanContract } = order;
	const customer = leasmanContract ? leasmanContract.customer : undefined;
	const { invoiceInfo } = order;

	const isFromStock = order.source !== "Stock";

	return (
		<Layout
			title="Transportauftrag"
			navigation={
				<OrdersNavigation
					vehicle={{
						id: order.vehicle.id,
						leasmanContractId: order.leasmanContract?.id,
						plateNumber: order.plateNumber
					}}
				/>
			}
			plateNumber={order.plateNumber}
			contextMenu={[<OrderScanContextMenu />]}
			sidebar={
				<TransportOrderSidebar
					order={order}
					setOrder={setOrder}
					compoundReference={compoundReference}
					setCompoundReference={setCompoundReference}
				/>
			}
		>
			<OrderHeader order={order} />
			<Box mt={4}>
				<Grid container spacing={6}>
					<Grid item xs={12} md={6}>
						<Grid container spacing={6}>
							<Grid item xs={12}>
								<Tile
									title="Abholung"
									subtitle={
										<>
											<span>{order?.pickupStatus}</span>
											{order.requiresExternalAssessment === true && (
												<span>&nbsp;· Externes Gutachten</span>
											)}
											{order.requiresExternalAssessment === false && (
												<span>&nbsp;· Gutachten in Dorfmark</span>
											)}
											{order.isRegistered === true && (
												<span>&nbsp;· Fahrzeug ist zugelassen</span>
											)}
											{order.isRegistered === false && (
												<span>&nbsp;· Fahrzeug ist abgemeldet</span>
											)}
										</>
									}
								>
									<TileContent>
										<Address address={order.pickupAddress} contact={order.pickupContact} />
									</TileContent>

									<TileActions>
										<ActionButton
											variant="text"
											hideOnDisabled
											onClick={reloadAfter(order.assignPickupDate)}
										>
											Termin vereinbaren
										</ActionButton>
										<ActionButton
											variant="text"
											hideOnDisabled
											onClick={reloadAfter(order.uploadProtocol)}
										>
											Protokoll hochladen
										</ActionButton>
										<ActionButton
											variant="text"
											hideOnDisabled
											onClick={reloadAfter(order.resetUploadProtocol)}
										>
											Protokoll erneut hochladen
										</ActionButton>
									</TileActions>
								</Tile>
							</Grid>

							{isFromStock && (
								<Grid item xs={12}>
									<Tile title="Rückgabe" subtitle={order?.returnStatus}>
										<TileContent>
											<Address
												address={order.destinationAddress}
												contact={order.destinationContact}
											/>
										</TileContent>
									</Tile>
								</Grid>
							)}

							{order.source === "Stock" && (
								<Grid item xs={12}>
									<Tile title="Anlieferung" subtitle={order?.deliveryStatus}>
										<TileContent>
											<Address
												address={order.destinationAddress}
												contact={order.destinationContact}
											/>
										</TileContent>
									</Tile>
								</Grid>
							)}

							{leasmanContract && (
								<Grid item xs={12}>
									<Tile title="Leasing">
										<TileContent>
											{isFromStock && (
												<Typography>Leasingvertrag: {leasmanContract.id}</Typography>
											)}
											<Typography>Businessline: {leasmanContract.businessLine}</Typography>
											{isFromStock && (
												<Typography>
													Kunde: {customer?.name1} ({customer?.id})
												</Typography>
											)}
										</TileContent>
									</Tile>
								</Grid>
							)}

							<Grid item xs={12}>
								<ReturnForms order={order} />
							</Grid>
							{order && order.manualProtocol && (
								<Grid item xs={12}>
									<Tile title="Protokolle">
										<TileContent dense>
											<TransportProtocolTable
												protocol={order.manualProtocol}
												downloadProtocol={order.downloadManualProtocol}
											/>
										</TileContent>
									</Tile>
								</Grid>
							)}
						</Grid>
					</Grid>
					<Grid item xs={12} md={6}>
						<Grid container spacing={6}>
							{invoiceInfo && (
								<Grid item xs={12}>
									<Tile title="Rechnung">
										<TileContent>
											<Typography>
												Rechnung:
												<Link component={RouterLink} to={`/invoices/${invoiceInfo?.invoiceId}`}>
													{invoiceInfo.invoiceNumber}
												</Link>
											</Typography>
											<Typography>
												Datum: {moment(invoiceInfo?.invoiceDate).format("L")}
											</Typography>
											<Typography>Betrag: {invoiceInfo?.netAmount.toString()}</Typography>
										</TileContent>
									</Tile>
								</Grid>
							)}

							{vehiclePicture && (
								<Grid item xs={12}>
									<Tile title="Bilder">
										<Thumbnail
											width={300}
											height={200}
											asyncUrl={`/api/storage/${vehiclePicture.hash}/thumbnail`}
											downloadUrl={`/api/storage/${vehiclePicture.hash}`}
											standalone
										/>
									</Tile>
								</Grid>
							)}

							{canSeeReturnOrderInformation && order.returnOrderId && returnOrder !== null && (
								<Grid item xs={12}>
									<Tile title="Fahrzeuginformationen">
										<TileContent>
											{!returnOrder && <CircularProgress />}
											{returnOrder && (
												<VehicleInformation
													returnOrder={returnOrder}
													assessmentOrderStatusInformation={assessmentOrderStatusInformation}
												/>
											)}
										</TileContent>
										{hasRole(UserRole.AldUser) && (
											<TileActions>
												<ActionButton
													variant="text"
													hideOnDisabled
													title="zur Rückholung navigieren"
													onClick={() =>
														history.push(`/orders/returns/${order?.returnOrderId}`)
													}
												>
													zur Rückholung
												</ActionButton>
											</TileActions>
										)}
									</Tile>
								</Grid>
							)}

							<Grid item xs={12}>
								<Tile
									title="Notizen"
									roles={[UserRole.AldUser, UserRole.LogisticCompany, UserRole.LotManager]}
								>
									{(order.notes.length > 0 || order.internalNotes.length > 0) && (
										<TileContent dense>
											<TransportOrderNoteTable order={order} />
										</TileContent>
									)}
									<TileActions>
										<ActionButton
											variant="text"
											hideOnDisabled
											title="Notiz hinzufügen"
											onClick={reloadAfter(order.addNote)}
										>
											Hinzufügen
										</ActionButton>
										{canSeeInternalNotes && (
											<ActionButton
												variant="text"
												title="Interne Notiz hinzufügen"
												onClick={reloadAfter(order.addInternalNote)}
											>
												Interne Notiz hinzufügen
											</ActionButton>
										)}
									</TileActions>
								</Tile>
							</Grid>

							<Grid item xs={12}>
								<Tile title="Benachrichtigungen" roles={[UserRole.AldUser]}>
									<TileContent dense>
										<TransportOrderNotificationsTable order={order} />
									</TileContent>
								</Tile>
							</Grid>

							{order.emailCommunications && (
								<Grid item xs={12}>
									<Tile title="Email Kommunikation" roles={[UserRole.AldUser]}>
										<TileContent dense>
											<TransportOrderEmailCommunicationTable order={order} />
										</TileContent>
									</Tile>
								</Grid>
							)}

							<Grid item xs={12}>
								<Tile title="Historie" roles={[UserRole.AldUser, UserRole.LogisticCompany]}>
									<TileContent dense>
										<TransportOrderEventTable events={events} />
									</TileContent>
								</Tile>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Layout>
	);
};
