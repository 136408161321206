import _ from 'lodash';
import { useState } from 'react';

import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import { TabPanel, TabPanelProps } from '@mui/lab';
import {
	Box, List, ListItem, ListItemAvatar, ListItemText, Paper, Typography
} from '@mui/material';

import Actions from '../../Components/Actions';
import formatCurrency from '../../system/formatCurrency';
import { AversiSession } from './AversiSessionsPage';
import Filter from './Filter';

interface Props extends TabPanelProps {
	aversiSession: AversiSession
}

const filters = {
	all: "Alle",
	unbalanced: "Ohne Zuordnung"
}

const AversiSessionPaymentTab = ({ aversiSession, ...rest }: Props) => {
	const [filter, setFilter] = useState("all");

	const handleFilterChange = (filter: string) => {
		setFilter(filter);
	};

	let items;
	switch (filter) {
		case "unbalanced":
			items = aversiSession.payments.filter(p => !p.tenantName);
			break;
		default:
			items = aversiSession.payments;
			break;
	}

	const payments = _.groupBy(items.map(s => ({
		...s,
		tenantName: s.tenantName || "Ohne Zuordnung"
	})), "tenantName");

	return (
		<TabPanel {...rest}>
			<>
				<Actions>
					<Filter values={filters} selected={filter} onChange={handleFilterChange} />
				</Actions>

				{Object.keys(payments).length === 0 && (
					<Typography>Keine Daten gefunden</Typography>
				)}
				{Object.keys(payments).map(d => {
					return (
						<div key={d}>
							<Typography>{d}</Typography>
							<Box my={2}>
								<Paper>
									<List disablePadding>
										{payments[d].map(i => {
											const amount = formatCurrency(i.amount);
											const isOk = i.tenantName !== "Ohne Zuordnung";

											return (
												<ListItem key={i.sapDocNumber}>
													<ListItemAvatar>
														{isOk ? (
															<CheckCircle />
														) : (
															<RadioButtonUnchecked />
														)}
													</ListItemAvatar>
													<ListItemText
														primary={`${i.insuranceName} ${i.insuranceId}`}
														secondary={`${amount} • ${i.sapText}`}
													/>
												</ListItem>)
										})}
									</List>
								</Paper>
							</Box>
						</div>
					)
				})}
			</>
		</TabPanel>
	)
};
export default AversiSessionPaymentTab;
