import { useHistory } from "react-router-dom";
import { Email } from "@mui/icons-material";
import NotificationList from "./NotificationList";
import NotificationItem from "./NotificationItem";
import NotificationIcon from "../../../Pages/Notifications/NotificationIcon";

export default ({ items }) => {
	const history = useHistory();

	async function handleSelect(item) {
		history.push(`/notifications/inbox/${item.id}`);
	}

	function handleUnreadNotifications() {
		history.push("/notifications");
	}

	if (!items || items.length === 0) return null;

	let Items;
	if (items.length > 5) {
		Items = (
			<NotificationItem
				primary="Ungelesene Nachrichten"
				secondary={`${items.length} ungelesene Nachrichten`}
				onClick={handleUnreadNotifications}
				icon={<Email />}
			/>
		);
	} else {
		Items = items.map(i => {
			return (
				<NotificationItem
					primary={i.subject}
					secondary="Ungelesen"
					icon={<NotificationIcon category={i.category} />}
					onClick={() => handleSelect(i)}
				/>
			);
		});
	}

	return (
		<NotificationList title="Benachrichtigungen">
			{Items}
		</NotificationList>
	);
};
