import axios from "axios";
import { Dispatch, SetStateAction } from 'react';
import PaperTable from "../../../Components/PaperTable";
import { CustomerSettings, UserRole } from "../../../system/Domain";
import { Check, Clear, Create } from "@mui/icons-material";
import ActionButton from "../../../Components/ActionButton";
import TextBlock from "../../../Components/Layout/TextBlock";
import { Box, Grid, TableBody, TableHead, TableRow } from "@mui/material";
import { Colgroup, TableCell } from "../../../Components/BreakpointStyledComponents";
import useUser from "../../../system/useUser";
import upsertCustomerSettings from "./upsertCustomerSettings";

interface Props {
	customerSettings: CustomerSettings[]
	setCustomerSettings: Dispatch<SetStateAction<CustomerSettings[] | undefined>>
}

const CustomerSettingsTable = (props: Props) => {
	const [, , hasRole] = useUser();
	const canEdit = hasRole(UserRole.AldUser);
	const canCreate = hasRole(UserRole.AldManager);

	const handleEdit = async (customerSettings: CustomerSettings) => {
		const result = await upsertCustomerSettings({ edit: true, customerSettings: customerSettings });

		if (result) {
			const { data: newCustomerSettings } = await axios.put<CustomerSettings>(`/api/customer-settings/${customerSettings.id}`, {
				...result.customerSettings
			});

			const newList = props.customerSettings.map(cs => cs.id === newCustomerSettings.id ? newCustomerSettings : cs);

			props.setCustomerSettings(newList);
		}
	};

	return (
		<PaperTable>
			<Colgroup sm md lg xl>
				<col width="auto" />
				<col width="auto" />
				<col width="auto" />
				<col width="auto" />
				<col width="auto" />
			</Colgroup>
			<TableHead>
				<TableRow>
					<TableCell xs sm md lg>Name - Email</TableCell>
					<TableCell xl>Kundennummber</TableCell>
					<TableCell xl>Name</TableCell>
					<TableCell xl>Email</TableCell>
					<TableCell sm md lg xl>Versand</TableCell>
					<TableCell sm md lg xl></TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{props.customerSettings && props.customerSettings.map(customerSetting => (
					<TableRow
						key={customerSetting.id}
						hover
					>
						<TableCell xl>{customerSetting.id}</TableCell>
						<TableCell xl>{customerSetting.name}</TableCell>
						<TableCell xl>
							<Grid container direction="column">
								{customerSetting.email.split(';').map((e, i) => (
									<Grid item key={i}>
										{e}
									</Grid>
								))}
							</Grid>
						</TableCell>
						<TableCell xs sm md lg>
							<TextBlock
								primary={`${customerSetting.id} - ${customerSetting.name}`}
								secondary={customerSetting.email.replaceAll(';', ' ')} />
							<Box sx={{ display: { xs: "contents", sm: "none" } }}>
								<Box display="flex" alignItems="baseline">
									{customerSetting.sendReturnForms ? <Check /> : <Clear />}
									<ActionButton disabled={!canEdit && !canCreate} icon onClick={() => handleEdit(customerSetting)}>
										<Create />
									</ActionButton>
								</Box>
							</Box>
						</TableCell>
						<TableCell sm md lg xl>
							<Box display="flex" alignItems="baseline">
								{customerSetting.sendReturnForms ? <Check /> : <Clear />}
							</Box>
						</TableCell>
						<TableCell sm md lg xl sx={{ padding: 1.5 }}>
							<ActionButton disabled={!canEdit && !canCreate} icon onClick={() => handleEdit(customerSetting)}>
								<Create />
							</ActionButton>
						</TableCell>
					</TableRow>
				))}
			</TableBody>
		</PaperTable>
	);
};

export default CustomerSettingsTable;
