import axios from 'axios';
import { useState } from 'react';

import { Check, Close } from '@mui/icons-material';

import TextBlock from '../../../Components/Layout/TextBlock';
import ListItem from '../../../Components/ListItem';
import ListItemAction from '../../../Components/ListItemAction';
import ListItemActions from '../../../Components/ListItemActions';
import ListItemChip from '../../../Components/ListItemChip';
import ListItemChips from '../../../Components/ListItemChips';
import ListItemContent from '../../../Components/ListItemContent';
import { Plate } from '../../../Components/Plate';
import VinDisplay from '../../../Components/VinDisplay';
import { ThemeColor, VehicleRegistrationDocumentsProjection } from '../../../system/Domain';
import { arrivalTypes } from './ArrivalToggleButtonGroup';
import documentArrivalPictureProof from './documentArrivalPictureProof';

interface Props {
	vehicle : VehicleRegistrationDocumentsProjection
	completionCallback : (vehicle : VehicleRegistrationDocumentsProjection) => void
}

type Mode = "view" | "complete";

const MissingDocumentsListItem = (props : Props) => {
	const [mode, setMode] = useState<Mode>("view");

	let color : ThemeColor = "info";
	switch (mode) {
		case "view":
		default:
			color = "info";
			break;
	}

	const arrivalType = arrivalTypes[props.vehicle.registrationDocuments.arrivalType];

	const updateArrival = async () => {
		if (arrivalType.key === "RegistrationDocument" || arrivalType.key === "RegistrationTitle") {
			setMode("complete");
		} else {
			const result = await documentArrivalPictureProof({
				document: props.vehicle.registrationDocuments
			});

			const { data: vehicle } = await axios.put<VehicleRegistrationDocumentsProjection>(`/api/vehicles/${props.vehicle.id}/remarketing/documents/arrival/complete`, {
				type: result.type,
				documentsPictureProof: result.proof
			});

			props.completionCallback(vehicle);
		}
	};

	const confirmMissingArrivalType = async () => {
		const { data: vehicle } = await axios.put<VehicleRegistrationDocumentsProjection>(`/api/vehicles/${props.vehicle.id}/remarketing/documents/arrival/complete`, {
			type: arrivalType.missing
		});

		props.completionCallback(vehicle);
	};

	return (
		<ListItem color={color}>
			<ListItemActions>
				{mode === "view" && (
					<ListItemAction
						icon={<Check />}
						onClick={updateArrival}
					/>
				)}
				{mode === "complete" && (
					<ListItemAction
						icon={<Close />}
						onClick={() => setMode("view")}
					/>
				)}
			</ListItemActions>
			<ListItemContent>
				<TextBlock
					primary={<Plate plateNumber={props.vehicle.plateNumber} style={{ zoom: 0.5 }} />}
					secondary={(
						<span>{props.vehicle.makeModel} <VinDisplay vin={props.vehicle.vin} /></span>
					)}
					compact
				/>
				<ListItemChips>
					<ListItemChip
						active
						label={arrivalType.label}
					/>
					{mode === "complete" && (
						<ListItemChip
							label={arrivalType.missingLabel}
							color="primary"
							onClick={confirmMissingArrivalType}
						/>
					)}
				</ListItemChips>
			</ListItemContent>
		</ListItem>
	);
};

export default MissingDocumentsListItem;
