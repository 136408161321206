import { VehicleHistoryEntry, Vehicle } from "../../system/Domain";
import TextBlock from "../../Components/Layout/TextBlock";
import dateFormats from "../../system/dateFormats";
import moment from "moment";
import useCompoundPlaces from "../../system/useCompoundPlaces";
import { PropTypes } from "@mui/material";

export interface VehiclePlaceProps {
	vehicle: Vehicle;
	historyEntry?: VehicleHistoryEntry;
	showDate?: boolean;
	showPlaceChangedDate?: boolean;
	defaultColor?: Exclude<PropTypes.Color, "inherit">;
	strikethrough?: boolean;
}

export default ({
	vehicle,
	historyEntry,
	showDate = false,
	showPlaceChangedDate,
	defaultColor = "default",
	strikethrough = false,
}: VehiclePlaceProps) => {
	const [places] = useCompoundPlaces();

	if (!historyEntry && vehicle.history.entries.length > 0) {
		historyEntry = vehicle.history.entries[vehicle.history.entries.length - 1];
	}

	if (!historyEntry) {
		return null;
	}

	let date = undefined;
	if (showDate) {
		date = moment(historyEntry.date).format(dateFormats.dateTime);
	} else if (showPlaceChangedDate) {
		const historyIndex = vehicle.history.entries.indexOf(historyEntry);
		for (let i = historyIndex; i >= 0; i--) {
			const e = vehicle.history.entries[i];
			if (e.placeChanged) {
				date = moment(e.date).format(dateFormats.dateTime);
				break;
			}
		}
	}

	const compoundPlace = places?.find((p) => p.id === historyEntry!.placeId);
	const compoundPlaceName = compoundPlace?.name ?? "-";
	const compoundPlaceCity = compoundPlace?.compound?.name ?? "-";

	return (
		<>
			{compoundPlaceCity !== compoundPlaceName && (
				<TextBlock
					chip={{
						label: compoundPlaceName,
						color: defaultColor,
						strikethrough: strikethrough,
					}}
				/>
			)}
			<TextBlock
				chip={{
					label: compoundPlaceCity,
					color: defaultColor,
					strikethrough: strikethrough,
				}}
				secondary={date}
			/>
		</>
	);
};
