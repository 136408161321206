import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';

import FluentGrid from '../../Components/FluentGrid';
import InlineList from '../../Components/InlineList';
import TextBlock from '../../Components/Layout/TextBlock';
import ListItem from '../../Components/ListItem';
import ListItemAction from '../../Components/ListItemAction';
import ListItemActions from '../../Components/ListItemActions';
import ListItemChip from '../../Components/ListItemChip';
import ListItemChips from '../../Components/ListItemChips';
import ListItemContent from '../../Components/ListItemContent';
import { Plate } from '../../Components/Plate';
import { FleetnetVehicle, ThemeColor } from '../../system/Domain';
import formatCurrency from '../../system/formatCurrency';

interface Props {
	fleetnetVehicle: FleetnetVehicle
	isSelected: boolean
	onSelect: (fleetnetVehicle?: FleetnetVehicle) => void
}

export default ({ fleetnetVehicle, isSelected, onSelect }: Props) => {
	let color: ThemeColor = "info";
	if (isSelected) {
		color = "primary";
	}

	const toggleSelected = () => onSelect(isSelected ? undefined : fleetnetVehicle);

	return (
		<ListItem color={color}>
			<ListItemActions color={color}>
				{!isSelected && (
					<ListItemAction
						icon={<CheckBoxOutlineBlank />}
						onClick={toggleSelected}
					/>
				)}
				{isSelected && (
					<ListItemAction
						icon={<CheckBoxOutlined />}
						onClick={toggleSelected}
					/>
				)}
			</ListItemActions>
			<ListItemContent color={color} onClick={toggleSelected}>
				<TextBlock
					primary={(
						<Plate plateNumber={fleetnetVehicle.plateNumber} style={{ zoom: 0.6 }} />
					)}
					secondary={`${fleetnetVehicle.type.make.name} ${fleetnetVehicle.type.model.version || fleetnetVehicle.type.model.name || ""} ${fleetnetVehicle.type.model.variant || ""}`.trim()}
				/>
				<Box mt={2} mb={1}>
					<FluentGrid itemWidth={150}>
						<TextBlock
							primary={fleetnetVehicle.vin}
							secondary="FIN"
							reduced
						/>
						<TextBlock
							primary={(
								<InlineList>
									{fleetnetVehicle.client.nr.toString()}
									{fleetnetVehicle.client.name}
								</InlineList>
							)}
							secondary="Fuhrpark"
							reduced
						/>
						<TextBlock
							primary={fleetnetVehicle.nr.toString()}
							secondary="Kfz Nummer"
							reduced
						/>
						<TextBlock
							primary={`${fleetnetVehicle.driver.firstName} ${fleetnetVehicle.driver.lastName}`}
							secondary="Fahrer"
							reduced
						/>
						<TextBlock
							primary={formatCurrency(fleetnetVehicle.type.costs.listPrice.netAmount)}
							secondary="Listenpreis (netto)"
							reduced
						/>
					</FluentGrid>
				</Box>
				<ListItemChips>
					<ListItemChip
						color="primary"
						label={fleetnetVehicle.id.toString()}
					/>
				</ListItemChips>
			</ListItemContent>
		</ListItem>
	);
}
