import { DependencyList, useState } from "react";
import axios, { AxiosRequestConfig } from "axios";
import useAsyncEffect, { CancelToken } from "./useAsyncEffect";
import qs from "query-string";

export default (create: (axiosRequestConfig: AxiosRequestConfig) => Promise<void>, deps?: DependencyList, cleanup?: () => void) => {
	const [running, setRunning] = useState(false);

	const createWrapper = async (cancelToken: CancelToken) => {
		setRunning(true);

		try {
			await create({
				cancelToken: cancelToken.getAxiosToken(),
				paramsSerializer: params => qs.stringify(params)
			});
		} catch (error) {
			if (axios.isCancel(error)) {
				return;
			}
			throw error;
		} finally {
			setRunning(false);
		}
	};

	useAsyncEffect(createWrapper, deps, cleanup);

	const run = () => createWrapper(new CancelToken());

	return [running, run] as const;
};
