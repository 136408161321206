import { Download } from "@mui/icons-material";
import { IconButton, Chip, Box } from "@mui/material";
import TextBlock from "Components/Layout/TextBlock";
import moment from "moment";
import { FileReference } from "system/Domain";
import dateFormats from "system/dateFormats";

interface Props {
	protocol: FileReference
	downloadProtocol?: () => Promise<void>
}

const TransportProtocolTable = ({ protocol, downloadProtocol }: Props) => {
	return (
		<Box sx={{
			display: "flex",
			padding: 1
		}}>
			<Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
				<Box m={1}>
					<TextBlock
						primary={`Protokoll vom ${moment(protocol.referenceCreated).format(dateFormats.dateTime)}`}
					/>
				</Box>
				<Box m={1}>
					<Chip
						label="Manuell hochgeladen"
						color="info"
						size="small"
					/>
				</Box>
			</Box>
			{downloadProtocol && (
				<Box sx={{ margin: 1, display: "flex", justifyContent: "flex-end", flexGrow: 0 }}>
					<Box sx={{alignContent: "center"}}>
						<IconButton onClick={downloadProtocol}>
							<Download />
						</IconButton>
					</Box>
				</Box>
			)}
		</Box>
	);
}

export default TransportProtocolTable;
