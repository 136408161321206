import moment from 'moment';
import { useState } from 'react';
import { confirmable, createConfirmation, ReactConfirmProps } from 'react-confirm';

import { Clear } from '@mui/icons-material';
import {
    Box, Button, Checkbox, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField,
    Theme
} from '@mui/material';

import TextBlock from '../Components/Layout/TextBlock';
import ThemedDialog from '../Components/ThemedDialog';
import dateFormats from './dateFormats';
import { Bookmark, RecursivePartial } from './Domain';

interface Props extends ManageVehicleBookmarksOptions, ReactConfirmProps<RecursivePartial<Bookmark[]>> {
	show : boolean
}

const ManageVehicleBookmarksDialog = (props : Props) => {
	const [text, setText] = useState<string>('');
	const [showAll, setShowAll] = useState<boolean>(false);
	const [bookmarks, setBookmarks] = useState<Bookmark[]>(props.bookmarks.sort((a, b) => moment(a.dateCreated) < moment(b.dateCreated) ? 1 : -1))

	const handleClose = () => {
		props.cancel();
	};

	const hasExistingBookmarks = bookmarks.length > 0;
	const hasBookmarksFromOtherUsers = bookmarks.filter(f => f.user?.id !== props.userId).length > 0;

	const handleRemove = (i : number) => {
		const newBookmarks = [...bookmarks];
		newBookmarks.splice(i, 1);
		setBookmarks(newBookmarks);
	};

	const handleAdd = () => {
		const newBookmarks = [...bookmarks] as RecursivePartial<Bookmark[]>;

		if (text) {
			newBookmarks.push({ remark: text });
		}

		props.proceed(newBookmarks);
	};

	return (
		<ThemedDialog open={props.show} maxWidth="sm" fullWidth onClose={handleClose}>
			<DialogTitle>
				Fahrzeug merken
			</DialogTitle>
			<DialogContent>
				<Box mt={1} mb={2}>
					{bookmarks.map((b, i) => (
						(showAll || b.user?.id === props.userId)
							? (<Box mt={2} key={i} display="flex" justifyContent="space-between" alignItems="center">
								<TextBlock
									flat
									primary={!!b.remark ? b.remark : <em>ohne Kommentar</em>}
									secondary={showAll ? <><Box display='inline' color={props.theme.palette.secondary.light}>{b.user.name}</Box> - {moment(b.dateCreated).format(dateFormats.dateTime)}</> : moment(b.dateCreated).format(dateFormats.dateTime)}
								/>
								<Clear onClick={() => handleRemove(i)} />
							</Box>)
							: undefined
					))}
				</Box>
				{hasExistingBookmarks && (
					<Box>
						<FormControlLabel
							control={
								<Checkbox
									checked={showAll}
									onChange={() => setShowAll(!showAll)}
									disabled={!hasBookmarksFromOtherUsers}
								/>
							}
							label="Alle anzeigen"
						/>
					</Box>
				)}
				<Box mb={2}>
					<TextField
						fullWidth
						variant="standard"
						value={text}
						label="neue Bemerkung"
						onChange={(e) => setText(e.target.value)}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button
					variant="outlined"
					color="secondary"
					onClick={handleClose}
				>
					Abbrechen
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={handleAdd}
				>
					Ok
				</Button>
			</DialogActions>
		</ThemedDialog>
	);
};

interface ManageVehicleBookmarksOptions {
	bookmarks : Bookmark[]
	userId : string
	theme : Theme
}

export default (options : ManageVehicleBookmarksOptions) => {
	const dialog = confirmable(props => <ManageVehicleBookmarksDialog {...props} {...options} />);
	return createConfirmation<RecursivePartial<Bookmark[]>>(dialog)();
};
