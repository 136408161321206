import { Grid } from "@mui/material";
import axios from "axios";
import SalesNavigation from "Navigation/SalesNavigation";
import { useState } from "react";
import { useHistory } from "react-router";
import ActionButton from "../../Components/ActionButton";
import FormNumber from "../../Components/FormNumber";
import Layout from "../../Components/Layout/Layout";
import { PricingRequest } from "../../system/Domain";
import useForm from "../../system/useForm";
import useRunAction from "../../system/useRunAction";
import NewPricingRequestSidebar from "./NewPricingRequestSidebar";

export default () => {
	const [request, setRequest] = useState<Partial<PricingRequest>>({});

	const form = useForm({
		values: request,
		setValues: setRequest,
		deltaValues: null
	});

	const [actionRunning, runAction] = useRunAction();
	const history = useHistory();

	const createRequest = () => runAction(async () => {
		const { data: newRequest } = await axios.post<PricingRequest>(`/api/pricing-requests`, request);
		history.push(`/sales/pricing-requests/${newRequest.id}`);
	});

	return (
		<Layout
			title="Neue Kaufpreisanfrage"
			navigation={<SalesNavigation />}
			sidebar={<NewPricingRequestSidebar request={request} />}
		>
			<Grid container spacing={4}>
				<FormNumber
					form={form}
					label="Vertragsnummer"
					name="contractId"
				/>
				{request.contractId && (
					<Grid item xs={12}>
						<ActionButton
							color="primary"
							variant="text"
							disabled={!request.contractId || actionRunning}
							onClick={createRequest}
						>
							Anfrage erstellen
						</ActionButton>
					</Grid>
				)}
			</Grid>
		</Layout>
	);
}
