import { Box, Grid, useTheme } from "@mui/material";
import { useEffect, useState } from 'react';
import FormChoices from "../../../Components/FormChoices";
import FormDateTimePicker from "../../../Components/FormDateTimePicker";
import FormElementLabel from "../../../Components/FormElementLabel";
import FormText from "../../../Components/FormText";
import FormYesNo from "../../../Components/FormYesNo";
import { EngineType, UserRole } from "../../../system/Domain";
import { Form } from "../../../system/useForm";
import useUser from "../../../system/useUser";

interface Props {
	form: Form
}

const IsElectricCarReason = "Elektroautos gelten als nicht fahrbereit";
const HasNoValidMainInspectionReason = "Fahrzeuge ohne HuAu gelten nicht als fahrbereit";

const StepCarState = (props: Props) => {
	const theme = useTheme();
	const [, , hasRole] = useUser();

	const isElectricCar = !!props.form.getValue('isElectricCar') || props.form.getValue('engineType') === "Electric";

	const [disableDrivable, setDisableDrivable] = useState<boolean>(isElectricCar && hasRole(UserRole.AldManager) === false);
	const [displayReasonNotDrivable, setDisplayReasonNotDrivable] = useState<string>(isElectricCar && disableDrivable ? IsElectricCarReason : "");

	useEffect(() => {
		const isElectricCar = !!props.form.getValue('isElectricCar') || props.form.getValue('engineType') === "Electric";
		const hasNoValidMainInspection = props.form.getValue('hasValidMainInspection') === false;

		const cannotBeDrivable = (isElectricCar && hasRole(UserRole.AldManager) === false) || hasNoValidMainInspection;

		if (disableDrivable !== cannotBeDrivable) {
			if (cannotBeDrivable) {
				props.form.setValue('isDrivable', false);
			}

			setDisplayReasonNotDrivable(isElectricCar && hasRole(UserRole.AldManager) === false ? IsElectricCarReason : (hasNoValidMainInspection ? HasNoValidMainInspectionReason : ""));
			setDisableDrivable(cannotBeDrivable);
		}
	}, [props.form]);

	const engineType: EngineType = props.form.getValue('engineType');

	const engineTypeName = (() => {
		switch (engineType) {
			case "Combustion":
				return "Verbrennungsmotor";
			case "Hybrid":
				return "Hybrid";
			case "Electric":
				return "Elektro";
			case "Unknown":
				return "Unbekannt";
		}
	})();

	return (
		<Box mb={2} mt={2}>
			<Grid container spacing={4}>
				{engineType === "Unknown" && (
					<FormYesNo
						name="isElectricCar"
						label="Ist das Fahrzeug ein Elektroauto? (Hybrid gilt nicht als Elektrofahrzeug)"
						form={props.form}
						options={{ required: true }}
					/>
				)}
				{engineType !== "Unknown" && (
					<Grid item xs={12}>
						Motortyp: {engineTypeName}
					</Grid>
				)}
				<FormYesNo
					name="isRegistered"
					label="Ist das Fahrzeug zugelassen?"
					form={props.form}
					options={{ required: true }}
				/>
				<FormYesNo
					name="hasValidMainInspection"
					label="Ist die HU/AU gültig?"
					form={props.form}
					options={{ required: true }}
				/>
				{props.form.getValue("hasValidMainInspection") === true && (
					<Grid item xs={12}>
						<FormDateTimePicker
							name="mainInspectionValidUntil"
							form={props.form}
							label="HU/AU gültig bis"
							openTo="year"
							views={["year", "month"]}
							variant="date"
							format="MM.YYYY"
							disablePast
						/>
					</Grid>
				)}
				<FormYesNo
					name="isDrivable"
					label={<Box mb={0.25}><FormElementLabel text="Ist das Fahrzeug fahrbereit?" form={props.form} name="isDrivable" /><Box color={theme.palette.secondary.light}>{displayReasonNotDrivable}</Box></Box>}
					form={props.form}
					disabled={disableDrivable}
					options={{ required: true }}
				/>
				{(props.form.getValue("isDrivable") === false || isElectricCar) && (
					<FormText
						name="drivableComment"
						form={props.form}
						label="Bemerkung zur Fahrbereitschaft"
						type="text"
					/>
				)}
				<FormChoices
					name="tireType"
					label="Welche Reifenart ist aufgezogen?"
					form={props.form}
					choices={{
						"wr": "Winterreifen",
						"sr": "Sommerreifen",
						"gjr": "Ganzjahresreifen",
						"unk": "Unbekannt"
					}}
					options={{ required: true }}
				/>
				<FormChoices
					name="carType"
					label="Ist das Auto ein Kastenwagen oder SUV?"
					form={props.form}
					choices={{
						"SUV": "SUV",
						"Van": "Kastenwagen",
						"Unknown": "Unbekannt",
						"None": "Nein"
					}}
					options={{ required: true }}
				/>
				<FormYesNo
					name="isPoolfleet"
					label="Handelt es sich um ein ALD Flex Fahrzeug?"
					form={props.form}
					options={{ required: true }}
				/>
			</Grid>
		</Box>
	);
};

export default StepCarState;
