import { RecursivePartial, UserRole, Vehicle } from "../system/Domain";
import LayoutNavigationItem from "../Components/Layout/LayoutNavigationItem";
import LayoutNavigationSection from "../Components/Layout/LayoutNavigationSection";
import {
	AccountTree,
	Assignment,
	AssignmentTurnedIn,
	CarRental,
	DirectionsCar,
	Explore,
	History,
	Money,
	NoCrash,
	PhotoLibrary,
	PrecisionManufacturing,
} from "@mui/icons-material";

export default ({ vehicle }: { vehicle: RecursivePartial<Vehicle> }) => {
	return (
		<>
			{vehicle?.id && (
				<LayoutNavigationSection title={`Fahrzeug ${vehicle.plateNumber}`}>
					<LayoutNavigationItem
						roles={[UserRole.AldUser]}
						icon={<Explore />}
						title="Dashboard"
						path={`/vehicles/${vehicle.id}/dashboard`}
					/>
					<LayoutNavigationItem
						roles={[UserRole.AldUser, UserRole.LotManager]}
						icon={<Explore />}
						title="Platzlogistik"
						path={`/vehicles/${vehicle.id}`}
					/>
					<LayoutNavigationItem
						roles={[UserRole.AldUser, UserRole.LotManager]}
						icon={<History />}
						title="Verlauf"
						path={`/vehicles/${vehicle.id}/history`}
					/>
					<LayoutNavigationItem
						roles={[UserRole.AldManager]}
						icon={<DirectionsCar />}
						title="Stammdaten"
						path={`/vehicles/${vehicle.id}/base-data`}
					/>
					<LayoutNavigationItem
						roles={[UserRole.AldUser, UserRole.LotManager]}
						icon={<PhotoLibrary />}
						title="Fotosätze"
						path={`/vehicles/${vehicle.id}/picture-sets`}
					/>
					<LayoutNavigationItem
						roles={[UserRole.AldUser, UserRole.LotManager]}
						icon={<Assignment />}
						title="Aufträge"
						path={`/vehicles/${vehicle.id}/orders`}
					/>
					<LayoutNavigationItem
						roles={[UserRole.AldUser, UserRole.AldManager, UserRole.LotManager]}
						icon={<PrecisionManufacturing />}
						title="Werkstattaufträge"
						path={`/vehicles/${vehicle.id}/orders/repairs`}
					/>
					<LayoutNavigationItem
						roles={[UserRole.AldUser, UserRole.LotManager]}
						icon={<AssignmentTurnedIn />}
						title="Tätigkeiten"
						path={`/vehicles/${vehicle.id}/services`}
					/>
					<LayoutNavigationItem
						roles={[UserRole.AldUser, UserRole.LotManager]}
						icon={<NoCrash />}
						title="Gutachten"
						path={`/vehicles/${vehicle.id}/assessments`}
					/>
					<LayoutNavigationItem
						roles={[UserRole.SalesUser]}
						icon={<Money />}
						title="Verkaufsvorbereitung"
						path={`/vehicles/${vehicle.id}/remarketing`}
					/>
					{!!vehicle.remarketing?.saleId && (
						<LayoutNavigationItem
							roles={[UserRole.SalesUser]}
							icon={<Money />}
							title="Verkauf"
							path={`/sales/${vehicle.remarketing.saleId}`}
						/>
					)}
					{vehicle.leasmanContractId && (
						<LayoutNavigationItem
							roles={[UserRole.AldUser]}
							icon={<CarRental />}
							title="Flex"
							path={`/pool-fleet/vehicles/${vehicle.leasmanContractId}`}
						/>
					)}
					<LayoutNavigationItem
						roles={[UserRole.AldUser, UserRole.LotManager]}
						icon={<AccountTree />}
						title="Dokumente"
						path={`/vehicles/${vehicle.id}/documents`}
					/>
				</LayoutNavigationSection>
			)}
		</>
	);
};
