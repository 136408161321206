import { Dispatch, SetStateAction } from "react";
import { RepairOrder, RepairOrderPerspective, RepairOrderStatus, RepairOrderType } from "../../system/Domain";
import SidebarGroup from "../../Components/Sidebar/SidebarGroup";
import Sidebar from "../../Components/Sidebar/Sidebar";
import SidebarButton from "../../Components/Sidebar/SidebarButton";
import axios from "axios";
import useAction from "../../system/useAction";
import ask from "../../Dialogs/ask";
import SidebarForm from "Components/Sidebar/SidebarForm";
import { Box, Button, Divider, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { RepairOrderQuery } from "./RepairOrdersLayout";
import { GetApp, RotateLeft } from "@mui/icons-material";
import useWorkshops from "../../system/useWorkshops";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";
import useCompounds from "system/useCompounds";

export interface Props {
	query: RepairOrderQuery;
	setQuery: Dispatch<SetStateAction<RepairOrderQuery>>;
	resetQuery: () => RepairOrderQuery;
	canProcess: boolean;
	isProcessing: boolean;
	repairOrders: RepairOrder[];
	runExport: () => Promise<void>;
}

export default ({ query, setQuery, resetQuery, repairOrders, runExport }: Props) => {
	const [order, isOrdering] = useAction(async () => {
		const answer = await ask("Sollen alle zugewiesenen Aufträge jetzt beauftragt werden?");

		if (answer.yes) {
			await axios.post(`/api/orders/repairs/order`, {
				compound: !query.compound ? null : { name: query.compound }
			});
		}
	});

	const [workshops] = useWorkshops();
	const [compounds] = useCompounds({ includeAmazonCompounds: false });

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarButton label="Beauftragen" color="primary" disabled={isOrdering} onClick={order} />
			</SidebarGroup>

			<SidebarGroup>
				<SidebarForm>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Perspektive</InputLabel>
						<Select
							value={query.perspective}
							onChange={(e) =>
								setQuery((g) => ({
									...g,
									skip: 0,
									perspective: e.target.value as RepairOrderPerspective,
									company:
										e.target.value === "Actionable" &&
										g &&
										g.workType &&
										(g.workType === "AssessmentReport" || g.workType === "GeneralRepair")
											? undefined
											: g
												? g.company
												: undefined,
									status: e.target.value === "Actionable" ? undefined : g ? g.status : undefined
								}))
							}
						>
							<MenuItem value="Actionable">Offen</MenuItem>
							<MenuItem value="All">Alle</MenuItem>
						</Select>
					</FormControl>
					{compounds && (
						<FormControl variant="standard" fullWidth>
							<InputLabel>Standort</InputLabel>
							<Select
								value={query.compound || "-"}
								onChange={(e) => {
									let value = e.target.value as string | undefined;
									if (value === "-") {
										value = undefined;
									}

									setQuery((g) => ({
										...g,
										skip: 0,
										compound: value as string
									}));
								}}
							>
								<MenuItem value="-">Alle</MenuItem>
								<Box my={2}>
									<Divider />
								</Box>
								{compounds.map((c) => (
									<MenuItem key={c.name} value={c.name}>
										{c.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}
					<FormControl variant="standard" fullWidth>
						<InputLabel>Typ</InputLabel>
						<Select
							value={query.workType || "-"}
							onChange={(e) => {
								let value = e.target.value as string | undefined;
								if (value === "-") {
									value = undefined;
								}

								const getCompanyForTypeChange = (value: string, g: RepairOrderQuery) => {
									if (
										value === undefined ||
										value === "Carglass" ||
										value === "-" ||
										value === "All"
									) {
										return undefined;
									}

									return g ? g.company : undefined;
								};

								setQuery((g) => ({
									...g,
									skip: 0,
									workType: value as RepairOrderType,
									company: getCompanyForTypeChange(value, g)
								}));
							}}
						>
							<MenuItem value="-">Alle</MenuItem>
							<MenuItem value="Carglass">Carglass</MenuItem>
							<MenuItem value="AssessmentReport">Mängelmeldungen</MenuItem>
							<MenuItem value="GeneralRepair">Allgemeine Reparatur</MenuItem>
						</Select>
					</FormControl>

					{query.perspective === "All" && (
						<FormControl variant="standard" fullWidth>
							<InputLabel>Status</InputLabel>
							<Select
								value={query.status || "-"}
								onChange={(e) => {
									let value = e.target.value as string | undefined;
									if (value === "-") {
										value = undefined;
									}

									setQuery((g) => ({
										...g,
										skip: 0,
										status: value as RepairOrderStatus
									}));
								}}
							>
								<MenuItem value="-">Alle</MenuItem>
								<Box my={2}>
									<Divider />
								</Box>
								<MenuItem value="Created">Erstellt</MenuItem>
								<MenuItem value="Assignable">Zuweisbar</MenuItem>
								<MenuItem value="Assigned">Zugewiesen</MenuItem>
								<MenuItem value="Ordered">Beauftragt</MenuItem>
								<MenuItem value="CostEstimated">KVA vorhanden</MenuItem>
								<MenuItem value="Completed">Abgeschlossen</MenuItem>
								<MenuItem value="Cancelled">Storniert</MenuItem>
							</Select>
						</FormControl>
					)}

					{(query.workType === "AssessmentReport" || query.workType === "GeneralRepair") && (
						<FormControl variant="standard" fullWidth>
							<InputLabel>Werkstatt</InputLabel>
							<Select
								defaultValue="-"
								value={query.company || "-"}
								onChange={(e) => {
									let value = e.target.value as string | undefined;
									if (value === "-") {
										value = undefined;
									}

									setQuery((g) => ({
										...g,
										skip: 0,
										company: value === "-" ? undefined : value
									}));
								}}
							>
								<MenuItem value="-">Alle</MenuItem>
								<Box my={2}>
									<Divider />
								</Box>
								{workshops?.map((workshop) => (
									<MenuItem key={workshop.name} value={workshop.name}>
										{workshop.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}

					{query.status && (
						<FormControl fullWidth variant="standard">
							<DatePicker
								format="DD.MM.YYYY"
								sx={{ border: 0 }}
								label="Status seit dem"
								slotProps={{ textField: { variant: "standard" } }}
								value={query.statusDateFrom === undefined ? null : moment(query.statusDateFrom)}
								onChange={(d: any) =>
									setQuery((q) => ({
										...q,
										statusDateFrom: (d || moment()).startOf("d").toISOString(true)
									}))
								}
							/>
						</FormControl>
					)}

					<FormControl fullWidth variant="standard">
						<DatePicker
							format="DD.MM.YYYY"
							sx={{ border: 0 }}
							label="Zuweisbar seit dem"
							slotProps={{ textField: { variant: "standard" } }}
							value={query.dateAssignable === undefined ? null : moment(query.dateAssignable)}
							onChange={(d: any) =>
								setQuery((q) => ({
									...q,
									dateAssignable: (d || moment()).startOf("d").toISOString(true)
								}))
							}
						/>
					</FormControl>
				</SidebarForm>
			</SidebarGroup>

			<SidebarGroup>
				<SidebarButton startIcon={<GetApp />} label="Excel-Export" onClick={runExport} />
				<Button variant="outlined" startIcon={<RotateLeft />} fullWidth color="inherit" onClick={resetQuery}>
					Filter zurücksetzen
				</Button>
			</SidebarGroup>
		</Sidebar>
	);
};
