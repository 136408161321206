import axios from 'axios';
import Tile from 'Components/Tiles/Tile';
import _ from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { Check, Warning } from '@mui/icons-material';
import { Box, FormControlLabel, Switch } from '@mui/material';

import FluentGrid from '../../Components/FluentGrid';
import TileContent from '../../Components/Tiles/TileContent';
import TileContentAction from '../../Components/Tiles/TileContentAction';
import TileContentActions from '../../Components/Tiles/TileContentActions';
import {
    EquipmentHighlight, EquipmentHighlightReference, Remarketing, Vehicle
} from '../../system/Domain';
import useAction from '../../system/useAction';
import useEquipmentHighlights from '../../system/useEquipmentHighlights';

interface Props {
	vehicle : Vehicle
	setVehicle : Dispatch<SetStateAction<Vehicle | undefined>>
	disabled : boolean
}

const clonePartialRemarketing = (vehicle : Vehicle | undefined) : Partial<Remarketing> => ({
	highlights: vehicle?.remarketing?.highlights || []
});

const getReference = (highlight : EquipmentHighlight) : EquipmentHighlightReference => ({
	id: highlight.id,
	name: highlight.name,
	relevance: highlight.relevance
});

export default ({ vehicle, setVehicle, disabled } : Props) => {
	const [remarketing, setRemarketing] = useState<Partial<Remarketing>>(clonePartialRemarketing(vehicle));
	useEffect(() => {
		setRemarketing(clonePartialRemarketing(vehicle));
	}, [vehicle]);

	const [highlights] = useEquipmentHighlights();

	const [save, isSaving] = useAction(async () => {
		if (!vehicle) {
			return;
		}

		const { data } = await axios.put<Vehicle>(`/api/vehicles/${vehicle.id}/remarketing`, remarketing);
		setVehicle(data);
	});

	const toggleHighlight = (category : EquipmentHighlight, active : boolean) => {
		if (disabled) {
			return;
		}
		setRemarketing(r => ({
			highlights: active
				? [...(r.highlights || []), getReference(category)]
				: (r.highlights || []).filter(c => c.id !== category.id)
		}));
	};

	const reset = () => {
		const equipmentCategoryIds = _.chain(vehicle.equipment)
			.flatMap(e => e.categories)
			.filter(c => !!c)
			.map(c => c.id)
			.uniq()
			.value();

		setRemarketing(r => ({
			highlights: highlights
				?.filter(c => c.equipmentCategoryIds.some(id => equipmentCategoryIds.includes(id)))
				.map(getReference)
		}));
	};

	const hasUnsavedChanges = (remarketing.highlights?.length || 0) !== (vehicle?.remarketing.highlights?.length || 0)
		|| remarketing.highlights?.some(c => !vehicle?.remarketing.highlights?.some(c2 => c2.id === c.id));

	return (
		<Tile title="Highlights">
			<TileContent dense>
				<FluentGrid p={2}>
					{highlights && highlights.map(c => (
						<Box key={c.id}>
							<FormControlLabel
								control={(
									<Switch
										color="secondary"
										checked={!!remarketing.highlights?.find(c2 => c2.id === c.id)}
										onChange={e => toggleHighlight(c, e.target.checked)}
									/>
								)}
								label={c.name}
							/>
						</Box>
					))}
				</FluentGrid>
				{!disabled && (
					<TileContentActions
						icon={!hasUnsavedChanges ? <Check /> : <Warning />}
						isBusy={isSaving}
					>
						<TileContentAction
							label={hasUnsavedChanges ? "Speichern" : "Gespeichert"}
							color="primary"
							disabled={!hasUnsavedChanges || isSaving}
							onClick={save}
						/>
						{hasUnsavedChanges && (
							<TileContentAction
								label="Verwerfen"
								color="secondary"
								disabled={isSaving}
								onClick={() => setRemarketing(clonePartialRemarketing(vehicle))}
							/>
						)}
						<TileContentAction
							label="Ausstattung analysieren"
							disabled={isSaving}
							onClick={reset}
						/>
					</TileContentActions>
				)}
			</TileContent>
		</Tile>
	);
}
