import { Box, Button, CircularProgress, colors } from "@mui/material";
import Grid from "@mui/material/Grid";
import axios from "axios";
import VehicleSearch from "Components/VehicleSearch";
import OrdersNavigation from "Navigation/OrdersNavigation";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { TransportOrder, TransportOrderType, Vehicle } from "system/Domain";
import useAction from "system/useAction";
import useAsyncEffect from "system/useAsyncEffect";
import FormSelect from "../../../../Components/FormSelect";
import Layout from "../../../../Components/Layout/Layout";
import useForm from "../../../../system/useForm";
import CustomerDelivery from "./CustomerDelivery";
import PoolfleetDelivery from "./PoolfleetDelivery";
import { useAsync } from "react-use";
import confirm from "../../../../Dialogs/confirm";
import moment from "moment";
import dateFormats from "system/dateFormats";
import Chip from "Components/Chip";

interface CreateTransportOrderRequest {
	vehicleId: string | null
	type: TransportOrderType

	isRegistered: boolean
	isDrivable: boolean
	isPoolfleet: boolean
	isCancellation: boolean

	doAssessment: boolean

	collection?: CollectionDTO
	delivery?: DeliveryDTO
}

interface DeliveryDTO {
	address?: AddressDTO
	contact?: ContactDTO
	company?: string
	comment?: string
	deliveryDate?: string
	deliveryTime?: string
}

interface CollectionDTO {
	collectionDate?: string
	collectionTime?: string
}

interface AddressDTO {
	name?: string
	street?: string
	zipCode?: string
	city?: string
	country?: string
}

interface ContactDTO {
	firstName?: string
	lastName?: string
	email?: string
	telephone?: string
}

interface CreationReference {
	allowSelfDelivery: boolean
	contractEndDate: string
	isTooEarly: boolean
}

export default (props: any) => {
	const { vehicleId } = useParams<{ vehicleId: string }>();
	const [vehicle, setVehicle] = useState<Vehicle>();
	const [creationReference, setCreationReference] = useState<CreationReference>();
	const history = useHistory();

	const initialStateForNewRequest = {
		vehicleId: "-",
		type: "Arrival",
		isRegistered: true,
		isDrivable: true,
		isPoolfleet: false,
		isCancellation: false,
		doAssessment: true,
		delivery: {
			address: {
				country: "Deutschland"
			}
		}
	}

	const { vehiclePlateNumber: plateNumber, ...state } = props.location?.state || {};
	const inputState: CreateTransportOrderRequest = { ...initialStateForNewRequest, ...state };

	const [request, setRequest] = useState<CreateTransportOrderRequest>(inputState);

	useAsyncEffect(async () => {
		if (!vehicleId) {
			return;
		}

		const { data } = await axios.get(`/api/vehicles/${vehicleId}`);
		setVehicle(data);

		setRequest({ ...request, type: !data.isInStock ? "Arrival" : "Delivery" });

		const { data: checkResult } = await axios.get(`/api/orders/transports/creation-reference`, {
			params: {
				vin: data.vin
			}
		});

		setCreationReference(checkResult);
	}, [vehicleId]);

	const contractDateColor = !creationReference?.allowSelfDelivery
		? colors.red[500]
		: (creationReference?.isTooEarly) ? colors.orange[500] : colors.blue[500];

	const form = useForm({
		values: request,
		setValues: setRequest,
		deltaValues: null
	});

	const [save] = useAction(async () => {
		if (!vehicle) {
			return;
		}

		const validated = form.validateAll();
		if (!validated) {
			return;
		}

		const { data: transportOrder } = await axios.post<TransportOrder>("/api/orders/transports", {
			...request,
			vehicleId: vehicle.id,
		});

		history.push(`/orders/transports/${transportOrder.id}`);
	});

	useAsync(async () => {
		if (!request || !vehicle)
			return;

		if (request.type === "Arrival" && vehicle.isInStock) {
			const answer = await confirm("Das Fahrzeug befindet sich bereits in Dorfmark. Sie können nur eine Auslieferung erstellen.", "Warnung");

			if (answer.ok) {
				setRequest({ ...request, type: "Delivery" });
			} else {
				history.push(`/orders/transports/`);
			}
		}

		if (request.type === "Delivery" && !vehicle.isInStock) {
			const answer = await confirm("Das Fahrzeug befindet sich nicht in Dorfmark. Sie können nur eine Anlieferung erstellen.", "Warnung");

			if (answer.ok) {
				setRequest({ ...request, type: "Arrival" });
			} else {
				history.push(`/orders/transports/`);
			}
		}
	}, [request?.type]);

	useAsync(async () => {
		if (request.type === "Arrival" && creationReference) {
			if (!creationReference.allowSelfDelivery) {
				await confirm("Leasingvertrag ist noch zu lange aktiv. Transportauftrag kann daher nicht angelegt werden.", "Warnung");
			}

			if (creationReference.allowSelfDelivery && creationReference.isTooEarly) {
				await confirm("Leasingvertrag ist noch zu lange aktiv. Bitte stellen Sie sicher, dass es sich hier um das korrekte Fahrzeug handelt.", "Warnung");

			}
		}
	}, [creationReference]);

	// allow all deliveries, but if it is an arrival, check creationReference for allowSelfDelivery
	const canSave = ((request.type === "Delivery" || request.type === "Arrival") && (!creationReference || creationReference.allowSelfDelivery));

	return (
		<Layout
			plateNumber={vehicle?.plateNumber}
			title="Neuer Transportauftrag"
			navigation={<OrdersNavigation />}
		>
			{!vehicleId && !vehicle && (
				<VehicleSearch
					onClick={v => history.push(`/vehicles/${v.id}/orders/transports/new`, request)}
					fullText={plateNumber}
				/>
			)}
			{!!vehicleId && !vehicle && (
				<CircularProgress size={48} />
			)}
			{vehicle && (
				<Grid container spacing={3}>
					<Grid item xs={12} md={6} lg={4}>
						<FormSelect
							label="Typ"
							name="type"
							choices={{
								"Arrival": "Anlieferung",
								"Delivery": "Auslieferung",
								// "Internal": "Intern",
							}}
							form={form}
						/>
					</Grid>
					{request.type === "Arrival" && creationReference && (
						<Grid item xs={12}>
							{creationReference.contractEndDate && (
								<Chip
									label={`Vertragsende: ${moment(creationReference.contractEndDate).format(dateFormats.date)}`}
									explicitColor={contractDateColor}
								/>
							)}
						</Grid>
					)}
					{request.type === "Arrival" && (
						<CustomerDelivery vehicle={vehicle} form={form} />
					)}
					{request.type === "Delivery" && (
						<PoolfleetDelivery vehicle={vehicle} form={form} />
					)}
					<Grid item xs={12}>
						<Box mt={2} display="flex">
							<Button
								variant="outlined"
								color="primary"
								onClick={save}
								disabled={!canSave}
							>
								Auftrag erstellen
							</Button>
						</Box>
					</Grid>
				</Grid>
			)}
		</Layout>
	);
};
