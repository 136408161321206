import { useState } from "react";
import useForm from "../../../system/useForm";
import { Box } from "@mui/material";
import { useParams } from "react-router";
import { Vehicle, VehicleArrival } from "../../../system/Domain";
import Layout from "Components/Layout/Layout";
import OrdersNavigation from "Navigation/OrdersNavigation";
import axios from "axios";
import AldVehicleHeader from "Components/AldVehicleHeader";
import VehicleArrivalFormSidebar from "./VehicleArrivalFormSidebar";
import NotificationSnackbar from "Components/Layout/NotificationSnackbar";
import VehicleArrivalForm from "./VehicleArrivalForm";
import useAsyncEffect from "system/useAsyncEffect";
import moment from "moment";
import dateFormats from "system/dateFormats";

interface VehicleArrivalFormData extends VehicleArrival {
	additionalTiresAndRims?: boolean;
}

export default () => {
	const { arrivalId } = useParams<{ arrivalId: string }>();
	const [vehicle, setVehicle] = useState<Vehicle>();
	const [error, setError] = useState<string>('');
	const [output, setOutput] = useState<VehicleArrivalFormData>();

	useAsyncEffect(async () => {
		if (!arrivalId) return;

		const { data: output } = await axios.get<VehicleArrival>(`/api/vehicles/arrivals/${arrivalId}`);
		output.plateNumbers = output.plateNumbers.toString();
		setOutput({
			...output,
			additionalTiresAndRims: !!output.suppliedTiresAndRims
		});

		const { data: vehicle } = await axios.get<Vehicle>(`/api/vehicles/${output.vehicleId}`);
		setVehicle(vehicle);
	}, [arrivalId]);

	const handleClearError = () => {
		setError('');
	};

	useAsyncEffect(async () => {
		form.setReadOnly(true);
	}, [output]);

	const form = useForm({
		values: output,
		setValues: setOutput,
		deltaValues: null
	});

	const allowServicesDownload = form.getValue("unstickService") || form.getValue("changeTiresService");

	return (
		<>
			{vehicle && output && (
				<Layout
					plateNumber={vehicle?.plateNumber}
					title="Anlieferung"
					navigation={<OrdersNavigation />}
					sidebar={
						<VehicleArrivalFormSidebar
							vehicleId={vehicle.id}
							orderId={output?.transportOrderId}
							allowServicesDownload={allowServicesDownload}
						/>
					}
				>
					<NotificationSnackbar
						message={error}
						onClose={handleClearError}
						color="error"
					/>
					<Box sx={{ display: "flex", gap: 3, flexDirection: "column" }} >
						<Box sx={{ display: "flex" }} >
							<AldVehicleHeader
								plateNumber={vehicle.plateNumber}
								entries={[vehicle.type?.description, vehicle.vin]}
							/>
							<Box sx={{ whiteSpace: "pre-wrap", fontSize: "0.7rem", opacity: 0.8 }}>
								<span> · Erstellt von: {output.createdBy.name}, {moment(output.dateCreated).format(dateFormats.dateTime)}</span>
							</Box>
						</Box>
						<VehicleArrivalForm
							form={form}
							showExternalProtocolInfo={false}
						/>
					</Box>
				</Layout>
			)}
		</>
	);
};
