import { ReactNode } from "react";
import { alpha, Box, Typography, useTheme } from "@mui/material";

export type FormDeltaValueProps = {
	deltaValue: any
	value: any
	children?: ReactNode
};

export default ({ deltaValue, value, children }: FormDeltaValueProps) => {
	const theme = useTheme();

	return (
		<>
			<Box mt={0.25}>
				<Typography variant="caption" sx={{
					color: alpha(theme.palette.text.secondary, 0.25)
				}}>
					Vorheriger Wert
					<Box sx={{
						color: theme.palette.secondary.main,
						paddingLeft: theme.spacing(0.5)
					}}>{deltaValue}</Box>
				</Typography>
			</Box>
			{children}
		</>
	);
};
