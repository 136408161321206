import { Box, Grid } from "@mui/material";
import axios from "axios";
import AdminNavigation from "Navigation/AdminNavigation";
import queryString from "query-string";
import { useState } from "react";
import { useAsync } from "react-use";
import FetchNextButton from "../../Components/FetchNextButton";
import Layout from "../../Components/Layout/Layout";
import Search from "../../Components/Search";
import { User, UserRole } from "../../system/Domain";
import useUser from "../../system/useUser";
import addUser from "./addUser";
import UsersSidebar from "./UsersSidebar";
import UserTile from "./UserTile";
import NotificationSnackbar from "Components/Layout/NotificationSnackbar";

export default () => {
	const [user] = useUser();
	const [message, setMessage] = useState<string>();
	const [queryParams, setQueryParams] = useState({
		skip: 0,
		search: ""
	});

	const take = 20;

	const [users, setUsers] = useState<User[]>([]);

	const { loading } = useAsync(async () => {
		const params = queryString.stringify(
			{
				name: queryParams.search,
				skip: queryParams.skip,
				includeRoles: true,
				take: take
			},
			{
				skipEmptyString: true
			}
		);

		const { data: newUsers } = await axios.get<User[]>(`/api/users?${params}`);

		if (queryParams.skip === 0) {
			setUsers(newUsers);
		} else {
			setUsers((users) => [...users, ...newUsers]);
		}
	}, [queryParams, take]);

	const handleNewUser = async () => {
		const result = await addUser({
			canSelectCompany: user !== null && user.roles.includes(UserRole.AldUser),
			canSelectALD: user !== null && user.roles.includes(UserRole.AldAdmin)
		});

		if (result) {
			const response = await axios.post<User>("/api/users", {
				email: result.email,
				name: result.name,
				company: result.company,
				isTestUser: result.isTestUser,
				returnUrl: window.location.origin
			});
			setUsers((u) => [...u, response.data]);
			setMessage("Eine Email an den Benutzer mit der Aufforderung, sein Kennwort zurückzusetzen, wurde erfolgreich versandt.");
		}
	};

	const handleSearch = (search: string) => {
		setQueryParams({
			skip: 0,
			search
		});
	};

	const handleNext = async () => {
		setQueryParams((queryParams) => ({
			...queryParams,
			skip: queryParams.skip + take
		}));
	};

	return (
		<Layout title="Benutzer" navigation={<AdminNavigation />} sidebar={<UsersSidebar handleNewUser={handleNewUser} />}>
			<Box mb={2}>
				<Search loading={loading} onSearch={handleSearch} fullWidth placeholder="Suchen..." />
			</Box>
			<Grid container spacing={2}>
				{users && users.map((u) => (
					<Grid item xs={12}>
						<UserTile user={u} key={u.id} />
					</Grid>
				))}
			</Grid>
			<FetchNextButton my={3} hidden={users.length < take + queryParams.skip} onNext={handleNext} />

			<NotificationSnackbar onClose={() => setMessage(null)} color="success" message={message} />
		</Layout>
	);
};
