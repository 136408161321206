export const damageTypes = {
	interior: {
		"Dirt": "Verschmutzung",
		"Tear": "Riss",
		"Hole": "Loch",
		"Damage": "Beschädigung",
		"Missing": "Fehlt",
		"Other": "Sonstige Beschädigung"
	},
	exterior: {
		"Rockfall": "Steinschlag",
		"Scratch": "Kratzer",
		"Dent": "Delle",
		"Bulge": "Beule",
		"Rust": "Rost",
		"Accident": "Unfallschaden",
		"Paint": "Lackschaden",
		"Other": "Sonstige Beschädigung"
	}
};

export const damageComponents = {
	interior: {
		"DriverSeat": "Fahrersitz",
		"PassengerSeat": "Beifahrersitz",
		"DashboardAndCenterConsole": "Armaturentafel, Mittelkonsole",
		"RearBench": "Rücksitzbank",
		"InteriorTrim": "Innenverkleidung",
		"FloorCovering": "Bodenbelag",
		"RoofLiner": "Dachhimmel",
		"Trunk": "Koffer- / Laderaum",
		"TrunkCover": "Laderaumabdeckung",
		"EntranceAreaFrontLeft": "Einstiegsbereich Tür | Vorne links",
		"EntranceAreaFrontRight": "Einstiegsbereich Tür | Vorne rechts",
		"EntranceAreaBackLeft": "Einstiegsbereich Tür | Hinten links",
		"EntranceAreaBackRight": "Einstiegsbereich Tür | Hinten rechts"
	},
	exterior: {
		"FenderFrontLeft": "Kotflügel | Vorne links",
		"FenderFrontRight": "Kotflügel | Vorne rechts",
		"FenderBackLeft": "Kotflügel | Hinten links",
		"FenderBackRight": "Kotflügel | Hinten rechts",
		"TireAndRimFrontLeft": "Reifen, Felge | Vorne links",
		"TireAndRimFrontRight": "Reifen, Felge | Vorne rechts",
		"TireAndRimBackLeft": "Reifen, Felge | Hinten links",
		"TireAndRimBackRight": "Reifen, Felge | Hinten rechts",
		"FrontSpoiler": "Frontblech",
		"FrontPanelling": "Frontverkleidung",
		"IndicatorLightsLeft": "Blinkleuchte | Links",
		"IndicatorLightsRight": "Blinkleuchte | Rechts",
		"HeadlightsLeft": "Scheinwerfer | Links",
		"HeadlightsRight": "Scheinwerfer | Rechts",
		"FogLightsLeft": "Nebelleuchte | Links",
		"FogLightsRight": "Nebelleuchte | Rechts",
		"TailLightsLeft": "Rückleuchte | Links",
		"TailLightsRight": "Rückleuchte | Rechts",
		"BumperFront": "Stoßfänger | Vorne",
		"BumperBack": "Stoßfänger | Hinten",
		"RadiatorGrille": "Kühlergrill",
		"Hood": "Motorhaube",
		"RoofLeft": "Fahrzeugdach | Links",
		"RoofRight": "Fahrzeugdach | Rechts",
		"Windshield": "Frontscheibe",
		"DoorFrontLeft": "Tür | Vorne links",
		"DoorFrontRight": "Tür | Vorne rechts",
		"DoorBackLeft": "Tür | Hinten links",
		"DoorBackRight": "Tür | Hinten rechts",
		"MirrorsLeft": "Außenspiegel | Links",
		"MirrorsRight": "Außenspiegel | Rechts",
		"WindowFrontLeft": "Fenster | Vorne links",
		"WindowFrontRight": "Fenster | Vorne rechts",
		"WindowBackLeft": "Fenster | Hinten links",
		"WindowBackRight": "Fenster | Hinten rechts",
		"SidewallBackLeft": "Seitenwand | Hinten links",
		"SidewallBackRight": "Seitenwand | Hinten rechts",
		"DoorSillFrontLeft": "Türschweller | Vorne links",
		"DoorSillFrontRight": "Türschweller | Vorne rechts",
		"DoorSillBackLeft": "Türschweller | Hinten links",
		"DoorSillBackRight": "Türschweller | Hinten rechts",
		"RearApron": "Heckschürze",
		"BootLid": "Kofferraumdeckel, Laderaum",
		"RearWindow": "Heckscheibe"
	}
};
