import { Box } from "@mui/material";
import TransportInfo from "../../../Components/TransportInfo";
import {EmojiTransportation} from "@mui/icons-material";
import { dorfmarkAddress } from "../../../system/Domain";

interface TransportDirectionProps {
	from : string
	to : string
}

export default ({ from, to }: TransportDirectionProps) => (
	<Box display="flex" flexDirection="row" alignItems="center">
		<TransportInfo
			align="right"
			mr={1}
			zipCode={from}
		/>
		<EmojiTransportation />
		<TransportInfo
			align="left"
			ml={1}
			zipCode={to}
			zipColor={to !== dorfmarkAddress.zipCode ? "secondary" : undefined}
		/>
	</Box>
);
