import { UserRole } from "system/Domain";
import useBillingPermissions from "Pages/Billings/useBillingPermissions";
import LayoutNavigationItem from "../Components/Layout/LayoutNavigationItem";
import { Add, AssignmentTurnedIn, FormatListNumbered } from "@mui/icons-material";
import LayoutNavigationSection from "../Components/Layout/LayoutNavigationSection";

export default () => {
	const billingPermissions = useBillingPermissions();

	return (
		<LayoutNavigationSection
			title="Tätigkeiten"
		>
			<LayoutNavigationItem
				roles={[UserRole.AldUser, UserRole.LotManager]}
				icon={<AssignmentTurnedIn />}
				title="Tätigkeiten"
				path="/vehicles/services"
			/>
			{billingPermissions.canView && (
				<LayoutNavigationItem
					roles={[UserRole.AldUser, UserRole.AldManager, UserRole.ServiceAccounting]}
					icon={<FormatListNumbered />}
					title="Abrechnungen"
					path="/billings"
				/>
			)}
			<LayoutNavigationItem
				roles={[UserRole.AldUser]}
				icon={<Add />}
				title="Importe"
				path="/vehicles/services/imports"
			/>
		</LayoutNavigationSection>
	);
};
