import { Box, Button, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useLocation, useHistory } from "react-router";
import { ThemeColor, User, UserRole } from "system/Domain";
import useUser from "system/useUser";
import confirm from "../../Dialogs/confirm";
import axios from "axios";
import showMessage from "Dialogs/showMessage";
import alterPermissions from "./alterPermissions";
import ComponentList from "Components/List";
import ComponentListItem from "Components/ListItem";
import ListItemActions from "Components/ListItemActions";
import ListItemAction from "Components/ListItemAction";
import ListItemContent from "Components/ListItemContent";
import TextBlock from "Components/Layout/TextBlock";
import ListItemChips from "Components/ListItemChips";
import ListItemChip from "Components/ListItemChip";
import { Clear, Edit, Person, PersonOff, PlayArrow, Stop, SupervisorAccount, VpnKey } from "@mui/icons-material";

type Mode =
	| "view"
	| "impersonate"
	| "password-reset"
	| "change-to-active"
	| "change-to-inactive"
	| "change-to-test"
	| "change-to-normal"
	| "set-roles";

interface IUserTileProps {
	user: User;
}

export default (props: IUserTileProps) => {
	const theme = useTheme();
	const [user, setUser] = useState<User>(props.user);
	const location = useLocation<{ isNewUser: boolean | undefined }>();
	const [me, setGlobalUser] = useUser();
	const history = useHistory();
	const [mode, setMode] = useState<Mode>("view");

	const isNewUser = location.state?.isNewUser;
	const isInternalUser = Boolean(user?.userId);
	const isAdmin = Boolean(me && me.roles.includes(UserRole.AldAdmin));
	const isUserAdmin = Boolean(me && me.roles.includes(UserRole.UserAdmin));
	const hasImpersonateRole = Boolean(me && me.roles.includes(UserRole.Impersonate));
	const isMe = Boolean(user.id && user.id === me?.id);
	const isProduction = window.location.origin === "https://logistics.ald.digital";
	const canImpersonate = (isAdmin || hasImpersonateRole) && !isMe && !isProduction;

	const handlePasswordReset = async () => {
		if (!user) return;

		setMode("view");

		const answer = await confirm(
			"Das Kennwort für den Benutzer wird zurückgesetzt. Der Benutzer erhält eine Email, in der er aufgefordert wird das Kennwort zu ändern",
			"Kennwort ändern",
		);

		if (answer.ok) {
			await axios.post(`/api/users/${user.id}/reset-password`, {
				returnUrl: window.location.origin,
			});

			await showMessage({
				message: "Die Aufforderung, das Kennwort zu ändern, wurde erfolgreich versandt.",
			});
		}
	};

	const handleAddRole = async () => {
		if (!user) return;

		const result = await alterPermissions({ roles: user.roles });
		const { data: newUser } = await axios.post<User>(`/api/users/${user.id}/permissions`, result);

		setUser(newUser);
	};

	const handleImpersonate = async () => {
		const { data: impersonatedUser } = await axios.post<User>(`/api/impersonate`, {
			userId: user?.id,
		});

		setGlobalUser(impersonatedUser);
	};

	const handleApiUser = () => {
		history.push("/users/api-user");
	};

	const handleDisable = async () => {
		const { data: newUser } = await axios.put<User>(`/api/users/${user.id}/disable`, "{}");
		setUser(newUser);
		setMode("view");
	};

	const handleEnable = async () => {
		const { data: newUser } = await axios.put<User>(`/api/users/${user.id}/enable`, "{}");
		setUser(newUser);
		setMode("view");
	};

	const handleSetTestUser = async () => {
		const { data: newUser } = await axios.patch<User>(`/api/users/${user.id}`, {
			isTestUser: !user.isTestUser,
		});
		setUser(newUser);
		setMode("view");
	};

	const isActionMode = mode !== "view";
	let tileColor: ThemeColor = "info";
	if (mode === "change-to-inactive" || mode === "change-to-active") {
		tileColor = "error";
	} else if (mode === "change-to-normal" || mode === "change-to-test") {
		tileColor = "warning";
	}

	return (
		<Box sx={{ maxWidth: "600px", width: "100%", position: "relative" }}>
			<ComponentList>
				<ComponentListItem color={tileColor}>
					<ListItemActions>
						<>
							{!isActionMode && (
								<>
									{canImpersonate && (
										<ListItemAction
											icon={<SupervisorAccount />}
											tooltip="Identität annehmen"
											onClick={() => setMode("impersonate")}
										/>
									)}
									{!isInternalUser && (
										<ListItemAction
											icon={<VpnKey />}
											tooltip="Password erneut zurücksetzen"
											onClick={() => setMode("password-reset")}
										/>
									)}
								</>
							)}
							{isActionMode && (
								<>
									{isUserAdmin && mode === "set-roles" && (
										<ListItemAction
											tooltip="Rolle hinzufügen"
											icon={<Edit />}
											onClick={() => handleAddRole()}
										/>
									)}
									<ListItemAction icon={<Clear />} onClick={() => setMode("view")} />
								</>
							)}
						</>
					</ListItemActions>
					<ListItemContent>
						<TextBlock flat primary={user.name} secondary={user.email} />
						{mode === "impersonate" && (
							<Box mt={3} mb={1} color={theme.palette.primary.light}>
								<Box>
									<Button
										color="inherit"
										variant="text"
										onClick={handleImpersonate}
										startIcon={<SupervisorAccount />}
									>
										Identität annehmen
									</Button>
								</Box>
							</Box>
						)}
						{isMe && (
							<Button
								sx={{
									position: "absolute",
									top: 1.25,
									right: 1.25,
								}}
								color="secondary"
								onClick={handleApiUser}
							>
								API Benutzer
							</Button>
						)}
						{mode === "password-reset" && (
							<Box mt={3} mb={1} color={theme.palette.primary.light}>
								<Box>
									<Button
										color="inherit"
										variant="text"
										onClick={handlePasswordReset}
										startIcon={<VpnKey />}
									>
										Password erneut zurücksetzen
									</Button>
								</Box>
							</Box>
						)}
						{mode === "change-to-active" && (
							<Box mt={3} mb={1} color={theme.palette.error.light}>
								<Button color="inherit" variant="text" onClick={handleEnable} startIcon={<PlayArrow />}>
									Benutzer aktivieren
								</Button>
							</Box>
						)}
						{mode === "change-to-inactive" && (
							<Box mt={3} mb={1} color={theme.palette.error.light}>
								<Button color="inherit" variant="text" onClick={handleDisable} startIcon={<Stop />}>
									Benutzer deaktivieren
								</Button>
							</Box>
						)}
						{mode === "change-to-normal" && (
							<Box mt={3} mb={1} color={theme.palette.warning.light}>
								<Button
									color="inherit"
									variant="text"
									onClick={handleSetTestUser}
									startIcon={<Person />}
								>
									Normalbenutzer festlegen
								</Button>
							</Box>
						)}
						{mode === "change-to-test" && (
							<Box mt={3} mb={1} color={theme.palette.warning.light}>
								<Button
									color="inherit"
									variant="text"
									onClick={handleSetTestUser}
									startIcon={<PersonOff />}
								>
									Testbenutzer festlegen
								</Button>
							</Box>
						)}
						{mode === "set-roles" && (
							<Box mt={3} mb={1}>
								{user.roles.map((r) => (
									<Box>
										<Typography variant="caption">{r}</Typography>
									</Box>
								))}
							</Box>
						)}
						<ListItemChips>
							<ListItemChip
								label={user.isActive ? "Aktiv" : "Inaktiv"}
								color={user.isActive ? "success" : "info"}
								active={mode === "change-to-inactive" || mode === "change-to-active"}
								onClick={
									isAdmin
										? () =>
												user.isActive
													? setMode("change-to-inactive")
													: setMode("change-to-active")
										: null
								}
							/>
							<ListItemChip
								label={user.isTestUser ? "Testbenutzer" : "Normalbenutzer"}
								color={user.isTestUser ? "warning" : "primary"}
								active={mode === "change-to-normal" || mode === "change-to-test"}
								onClick={
									isAdmin
										? () =>
												user.isTestUser
													? setMode("change-to-normal")
													: setMode("change-to-test")
										: null
								}
							/>
							<ListItemChip
								label={`${user.roles?.length} ${user.roles?.length > 1 ? "Rollen" : "Rolle"}`}
								color="primary"
								active={mode === "set-roles"}
								onClick={() => setMode("set-roles")}
							/>
							<ListItemChip
								label={user.company.name}
								color={user.company.name === "ALD" ? "primary" : "grey"}
							/>
						</ListItemChips>
					</ListItemContent>
				</ComponentListItem>
			</ComponentList>
		</Box>
	);
};
