import axios from "axios";
import { useEffect, useState } from "react";
import { Button, LinearProgress, Typography, useTheme } from "@mui/material";
import useAsyncEffect from "../../../system/useAsyncEffect";
import { IVehiclePicture } from "./NewVehiclePictureSet";

interface PictureSummaryProps {
	picture: IVehiclePicture
	onClick?: () => void
}

export default ({ picture, onClick }: PictureSummaryProps) => {
	const [hash, setHash] = useState<string | null>(null);
	const [error, setError] = useState<string | null>(null)
	const [thumbnailUrl, setThumbnailUrl] = useState<string | null>(null);
	const [progress, setProgress] = useState(0);
	const theme = useTheme();

	useEffect(() => {
		if (picture.uploadPromise === null) {
			setHash(null);
			return;
		}

		picture.uploadPromise
			.then(data => {
				setHash(data.hash);
				setError(null);
			})
			.catch(error => setError(error));

	}, [picture]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setProgress(picture.uploadProgress);
		}, 1500);

		return () => clearTimeout(timer);
	}, [picture])

	useAsyncEffect(async () => {
		if (!hash) {
			return;
		}

		const response = await axios.get(`/api/storage/${hash}/thumbnail`, {
			responseType: "blob"
		});

		const thumbnailUrl = URL.createObjectURL(response.data);
		setThumbnailUrl(thumbnailUrl);

	}, [hash]);

	if (error !== null) {
		return (
			<Button
				sx={{
					width: 300,
					height: 225,
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					padding: theme.spacing(2)
				}}
				style={{
					backgroundImage: `url('/static/${picture.ghostImageName}')`,
					backgroundSize: "contain",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					opacity: 0.2
				}}
				onClick={onClick}
			>
				<Typography color="error">{error}</Typography>
			</Button>
		)
	}

	if (hash === null) {
		return (
			<Button
				style={{
					backgroundImage: `url('/static/${picture.ghostImageName}')`,
					backgroundSize: "contain",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					opacity: 0.2
				}}
				sx={{
					height: "100%", width: "100%",
				}}
				onClick={onClick}
			>
				{picture.uploadPromise && (
					<div>
						<Typography>Wird übertragen...</Typography>
						<LinearProgress variant="determinate" value={progress} />
					</div>
				)}
			</Button>
		)
	}

	if (thumbnailUrl == null) return null;

	return (
		<Button onClick={onClick}>
			<img
				src={thumbnailUrl}
				alt=""
			/>
		</Button>
	);
}
