import { Box } from "@mui/material";
import { RemarketingStatusActivity } from "./ActivityTile";

interface Props {
	activities: RemarketingStatusActivity[];
}

export default ({ activities }: Props) => {
	if (activities.length === 0) {
		return null;
	}

	const firstStatus = activities[0].payload.status;
	const lastStatus = activities.length > 1 ? activities[activities.length - 1].payload.status : null;

	return (
		<Box>
			<span>
				[{activities[0].date.format("DD.MM.YYYY HH:mm")}] Remarketing Status {firstStatus}
			</span>
			{!!lastStatus && (
				<span>
					{" ->"} {lastStatus}
				</span>
			)}
		</Box>
	);
};
