import { useEffect, useRef, useState } from "react";
import ThemedDialog from "../Components/ThemedDialog";
import { CircularProgress, DialogContent, LinearProgress, Typography } from "@mui/material";
import { confirmable, createConfirmation, ReactConfirmProps } from "react-confirm";

const minimumDisplayTime = 500;

/*
	A progress dialog that stays a minimal time open
	the cb has to return a promise and can report progress
	via its progress function

	example:

	await executeWithProgressDialog(async progress => {
		progress(0);
		await expensiveStep(1);
		progress(50);
		await expensiveStep(2);
	});

	Will show a progress dialog and report progress.
	if both expensive operations take less than 2 seconds
	to complete, the dialog will remain open for these 2 seconds
*/

type ProgressCallback = (progress: number) => void;

interface ProgressDialogProps extends ReactConfirmProps<void> {
	cb: (progress: ProgressCallback) => Promise<void>
}

const ProgressDialog = ({ cb, show, proceed, cancel }: ProgressDialogProps) => {
	const [completed, setCompleted] = useState(0);
	const [finished, setFinished] = useState(false);
	const [timerFired, setTimerFired] = useState(false);

	const finishedRef = useRef(finished);
	finishedRef.current = finished;

	const timerRef = useRef(timerFired);
	timerRef.current = timerFired;

	const progress = (completed: number) => {
		setCompleted(completed);
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			setTimerFired(true);
			if (finishedRef.current) {
				proceed();
			}
		}, minimumDisplayTime);

		cb(progress)
			.then(() => {
				if (timerRef.current) {
					proceed();
				} else {
					setFinished(true);
				}
			})
			.catch(error => {
				setFinished(true);
				cancel(error);
			});

		return () => clearTimeout(timer);
	}, [cb]);

	return (
		<ThemedDialog
			open={show}
			maxWidth="md"
			PaperProps={{
				style: { width: "50%" }
			}}
		>
			<DialogContent style={{ margin: 24, marginLeft: 12, marginRight: 12 }}>
				{(completed < 100) &&
					<LinearProgress variant="determinate" value={completed} />}
				{(completed >= 99) &&
					<Typography align="center" component="div">
						<CircularProgress />
					</Typography>}
			</DialogContent>
		</ThemedDialog>
	);
};

export default (progress: (x: ProgressCallback) => void) => {
	const dialog = confirmable(props => <ProgressDialog cb={progress} {...props} />);
	return createConfirmation(dialog)();
}
