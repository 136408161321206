import { useState } from 'react';
import Layout from "../../../Components/Layout/Layout";
import { Box, CircularProgress } from "@mui/material";
import useQuery, { IQuery } from "../../../system/useQuery";
import useAsyncEffect from "../../../system/useAsyncEffect";
import { CustomerSettings } from "../../../system/Domain";
import axios from "axios";
import FetchNextButton from "../../../Components/FetchNextButton";
import CustomerSettingsTable from "./CustomerSettingsTable";
import CustomerSettingsSidebar from "./CustomerSettingsSidebar";
import Search from "../../../Components/Search";
import AdminSubMenu from "../AdminSubMenu";
import AdminNavigation from 'Navigation/AdminNavigation';

export interface CustomerSettingsQuery extends IQuery {
	sort : string
	search : string
}

const CustomerSettingsOverview = () => {
	const take = 20;
	const [query, setQuery, fetchNext, resetQuery] = useQuery<CustomerSettingsQuery>('customerSettingsQuery', {
		skip: 0,
		search: "",
		take: take,
		sort: "name:desc"
	});

	const [isFetching, setIsFetching] = useState(false);

	const [customerSettings, setCustomerSettings] = useState<CustomerSettings[]>();

	useAsyncEffect(async (cancelToken) => {
		setIsFetching(true);

		try {
			const { data: vs } = await axios.get<CustomerSettings[]>(`/api/customer-settings`, {
				params: query,
				cancelToken: cancelToken.getAxiosToken()
			});

			if (query.skip > 0) {
				setCustomerSettings(o => [
					...(o ?? []),
					...vs
				]);
			} else {
				setCustomerSettings(vs);
			}
		} finally {
			setIsFetching(false);
		}
	},[query]);

	return (
		<Layout
			title="Kundeneinstellungen"
			navigation={<AdminNavigation />}
			subMenu={<AdminSubMenu />}
			sidebar={<CustomerSettingsSidebar  query={query} setQuery={setQuery} resetQuery={resetQuery} />}
		>
			<Box mb={2}>
				<Search
					value={query.search}
					realtime={false}
					onSearch={(value) => value !== query.search && setQuery(g => ({
						...g,
						search: value
					}))}
				/>
			</Box>
			<Box mt={2}>
				{!customerSettings && (
					<CircularProgress size={48} />
				)}
				{customerSettings && (
					<>
						<CustomerSettingsTable
							setCustomerSettings={setCustomerSettings}
							customerSettings={customerSettings ?? []}
						/>
						<FetchNextButton
							hidden={customerSettings.length < take + query.skip}
							mb={3}
							onNext={fetchNext}
							disabled={isFetching}
						/>
					</>
				)}
			</Box>
		</Layout>
	);
};

export default CustomerSettingsOverview;
