import axios from 'axios';
import moment from 'moment';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useAsync } from 'react-use';

import { EmojiTransportation } from '@mui/icons-material';
import {
    Avatar, Box, Button, List, ListItem, ListItemAvatar, ListItemText, Typography
} from '@mui/material';

import Actions from '../../Components/Actions';
import Layout from '../../Components/Layout/Layout';
import { FileReference } from '../../system/Domain';
import formatCurrency from '../../system/formatCurrency';
import createNewAversiSession from './createNewAversiSession';

export interface AversiSessionItem {
	id: string
	insuranceId: number
	insuranceName: string
	tenantName: string
	totalAmount: number
}

export interface AversiSessionReconciliationItem {
	id: string
	insuranceId: number
	insuranceName: string
	tenantName: string
	remark: string
	paymentDate: string | null
	status: "Ok" | "MissingAttachment" | "MissingItem" | "AmountDiffers"
}

export interface AversiSessionPayment {
	id: string
	insuranceId: number
	insuranceName: string
	tenantName: string | null
	sapDocNumber: string
	date: string
	bookingDate: string
	sapText: string
	amount: number
}

export interface AversiSessionDetail extends AversiSessionItem {
	incoming: AversiSessionProduct[]
	inventory: AversiSessionProduct[]
	outgoing: AversiSessionProduct[]
	incomingManual: AversiSessionProduct[]
	outgoingManual: AversiSessionProduct[]
	misc: AversiSessionProduct[]
}

export interface AversiSessionProduct {
	productCode: string
	amount: number
}

export interface AversiSession {
	id: string
	startDate: string
	endDate: string
	totalAmount: number
	totalAmountAttachments: number
	numberOfInsuranceCompanies: number
	dateCreated: string
	dateCompleted: string | null
	attachments: AversiAttachment[],
	items: AversiSessionDetail[]
	reconciliations: AversiSessionReconciliationItem[]
	payments: AversiSessionPayment[]
}

export interface AversiAttachment extends AversiSessionItem {
	file: FileReference
	inventory: number
	incoming: number
	incomingManual: number
	outgoing: number
	outgoingManual: number
	misc: number
	paymentDate: string | null
}

export interface AversiLine {
	id: string
	month: number
	year: number
	tenantName: string
	type: string
	productCode: string
	insuranceId: number | null
	insuranceName: string
	contractId: number | null
	plateNumber: string
	vin: string
	hsn: string
	tsn: string
	customerId: number | null
	customerName: string
	amount: number
	vehicleBody: string
	vehicleType: string
	file: FileReference | null
}

export interface AversiLeasmanLine {
	id: string
	year: number
	month: number
	tenant: string
	type: string
	invoiceId: number
	customerId: number
	contractId: number
	deliveryDate: string
	serviceType: string
	amount: number
}

export interface AversiInsuranceCompany {
	id: string
	name: string
	commission: number
	commissionCarPool: number
}

const AversiSessionsPage = () => {
	const history = useHistory();
	const [sessions, setSessions] = useState<AversiSession[]>([]);
	const [busy, setBusy] = useState(false);

	useAsync(async () => {
		setBusy(true);
		const { data: sessions } = await axios.get<AversiSession[]>("/api/admin/aversi/sessions");
		setSessions(sessions);
		setBusy(false);
	}, []);

	const handleNewAversiSession = async () => {
		const result = await createNewAversiSession();

		if (result) {
			const formData = new FormData();
			formData.append("force", result.force.toString());
			const { data: jobId } = await axios.post<string>(`/api/admin/aversi/sessions`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			history.push(`/admin/jobs/${jobId}`);
		}
	};

	const handleDrop = async (files: File[]) => {
		setBusy(true);

		if (files.length !== 1) {
			return;
		}

		let formData = new FormData();
		formData.append("file", files[0]);

		const { data: jobId } = await axios.post<string>(`/api/admin/aversi/sessions`,
			formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}
		);

		history.push(`/admin/jobs/${jobId}`);
	};

	const handleGotoPaymentPlan = () => {
		history.push('/aversi/payment-plans');
	};

	return (
		<Layout loading={busy} onDropFiles={handleDrop}>
			<Box>
				<Typography variant="h4">Aversi</Typography>
			</Box>
			<Actions>
				<Button color="primary" variant="outlined" onClick={handleNewAversiSession}>Neue Aversi
					Monatsmeldung</Button>
				<Button color="primary" variant="outlined" onClick={handleGotoPaymentPlan}>Zahlungspläne</Button>
			</Actions>
			<Box mt={2}>
				<List>
					{sessions.map(s => {

						const handleDetails = () => {
							history.push(`/aversi/sessions/${s.id}`)
						};

						const totalAmount = s.totalAmount;
						const title = `${moment(s.startDate).format("MM/YYYY")}`;

						return (
							<ListItem
								key={s.id} button
								onClick={handleDetails}
							>
								<ListItemAvatar>
									<Avatar>
										<EmojiTransportation />
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary={`Aversi für ${title}`}
									secondary={
										<span>{formatCurrency(totalAmount)} • {s.numberOfInsuranceCompanies} Versicherer</span>}
								/>
							</ListItem>
						);
					})}
				</List>
			</Box>
		</Layout>
	)
};

export default AversiSessionsPage;
