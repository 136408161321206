import axios from "axios";
import { useState } from "react";
import { useParams } from "react-router";
import { useAsync } from "react-use";
import { CircularProgress, Typography } from "@mui/material";
import { TransportPricing } from "../../../system/Domain";
import PricingPage from "./PricingPage";

export default () => {
	const { company } = useParams<{ company: string }>();
	const [pricing, setPricing] = useState<TransportPricing>();

	const { loading } = useAsync(async () => {
		const { data } = await axios.get<TransportPricing>(`/api/companies/${company}/transport-pricing`);

		setPricing(data);
	}, [company]);

	return (
		<>
			{loading && (
				<Typography align="center" component="div">
					<CircularProgress />
				</Typography>
			)}
			{!loading && (
				<PricingPage
					pricing={pricing}
				/>
			)}
		</>
	)
}

