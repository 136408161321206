import { useState } from "react";

export default <T, R = T>(
	key: string,
	executor: () => Promise<T>,
	filter?: (result: T) => R
) => {
	let global_promise: Promise<T> = (window as any)[key];

	if (!global_promise) {
		global_promise = (window as any)[key] = executor();
	}

	const [state, setState] = useState<R>();

	if (!state) {
		global_promise.then(result => {
			const filteredResult = filter ? filter(result) : (result as unknown as R);
			setState(filteredResult);
		});
	}

	return [state];
};
