import { ListSubheader, List, useTheme } from "@mui/material";

export default ({ children, title }) => {
	const theme = useTheme();
	return (
		<List dense component="ul" 
		sx={{
			padding: theme.spacing(2),
			paddingTop: 0
		}}>
			<ListSubheader>{title}</ListSubheader>
			{children}
		</List>
	);
};
