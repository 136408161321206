import { Cancel, Delete, Done, FormatListNumbered } from "@mui/icons-material";
import axios from "axios";
import { useHistory } from "react-router";
import useRunAction from "system/useRunAction";
import Sidebar from "../../../Components/Sidebar/Sidebar";
import SidebarButton from "../../../Components/Sidebar/SidebarButton";
import SidebarGroup from "../../../Components/Sidebar/SidebarGroup";
import { Job } from "../../../system/Domain";
import { JobPropsWithSetter } from "./JobLayout";

export default ({ job, setJob }: JobPropsWithSetter) => {
	const [requeueActionRunning, runRequeueAction] = useRunAction();
	const [cancelActionRunning, runCancelAction] = useRunAction();
	const [deleteActionRunning, runDeleteAction] = useRunAction();
	const history = useHistory();

	const requeueJob = () =>
		runRequeueAction(async () => {
			const { data: updatedJob } = await axios.post<Job>(`/api/admin/jobs/${job.id}/requeue`);
			setJob(updatedJob);
		});

	const cancelJob = () =>
		runCancelAction(async () => {
			const { data: updatedJob } = await axios.post<Job>(`/api/admin/jobs/${job.id}/cancel`);
			setJob(updatedJob);
		});

	const deleteJob = () =>
		runDeleteAction(async () => {
			const { data: updatedJob } = await axios.delete<Job>(`/api/admin/jobs/${job.id}`);
			setJob(updatedJob);
		});

	const allowRequeue = ["Scheduled", "Completed", "Failed", "Deleted", "Cancelled"].some((s) => s === job.status);
	const allowCancel = ["Running"].some((s) => s === job.status);
	const allowDelete = ["Scheduled", "Completed", "Cancelled", "Failed"].some((s) => s === job.status);

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarButton
					startIcon={<FormatListNumbered />}
					label="Alle Jobs"
					onClick={() => history.push(`/admin/jobs`)}
				/>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarButton
					startIcon={<Done />}
					color="primary"
					disabled={requeueActionRunning || !allowRequeue}
					onClick={requeueJob}
				>
					Ausführen
				</SidebarButton>
				<SidebarButton
					startIcon={<Cancel />}
					color="secondary"
					disabled={cancelActionRunning || !allowCancel}
					onClick={cancelJob}
				>
					Abbrechen
				</SidebarButton>
				<SidebarButton
					startIcon={<Delete />}
					color="secondary"
					disabled={deleteActionRunning || !allowDelete}
					onClick={deleteJob}
				>
					Löschen
				</SidebarButton>
			</SidebarGroup>
		</Sidebar>
	);
};
