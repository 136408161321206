import { Box, List, Typography } from "@mui/material";
import ListItem from "../../../Components/ListItem";
import ListItemContent from "../../../Components/ListItemContent";
import FormDateTimePicker from "Components/FormDateTimePicker";
import FormNumber from "../../../Components/FormNumber";
import FormSelect from "../../../Components/FormSelect";
import FormYesNo from "../../../Components/FormYesNo";
import moment from "moment";
import { Form } from "system/useForm";
import { useState } from "react";
import useAssessors from "system/useAssessors";
import { SubjectInquiryDefect, Assessor, AssessmentOrderDefectItem } from "system/Domain";
import ExpertAssesmentDefectListItem from "./ExpertAssesmentDefectListItem";
import RepairOrderDefectListItem from "./RepairOrderDefectListItem";
import ListItemActions from "../../../Components/ListItemActions";
import { Add } from "@mui/icons-material";
import ListItemAction from "../../../Components/ListItemAction";
import { RepairOrderViewModeBase, ViewModeAssessment } from "Pages/Vehicles/DekraRepairOrderListItem";
import TextBlock from "../../../Components/Layout/TextBlock";
import dateFormats from "system/dateFormats";
import FluentGrid from "../../../Components/FluentGrid";
import { InquirySpecificViewMode, InquiryViewMode } from "./InquiryDefectListItem";
import { SubjectInquiryFormType } from "./InquiryAssessmentTile";

export interface Props {
	form: Form
	subjectInquiryDetails?: SubjectInquiryFormType,
	viewMode: InquiryViewMode
	subjectInquiryDefect?: SubjectInquiryDefect
}

const RepairOrderInquiryForm = (props: Props) => {
	const [assessors] = useAssessors();
	const [newDefectItem, setNewDefectItem] = useState<Partial<AssessmentOrderDefectItem>>();
	const localIdPrefix = "local";
	const [currentId, setCurrentId] = useState(0);
	const getLocalId = () => {
		const itemId = currentId;
		setCurrentId(currentId + 1);
		return localIdPrefix + itemId;
	};

	const newAssessmentDefectItem = () => {
		setNewDefectItem({ id: getLocalId() });
	};

	const getAssessorLabel = (assessor: Assessor) => {
		if (!assessor) {
			return null;
		}

		return `${assessor.firstName} ${assessor.lastName}`;
	};

	const onAddingNewItem = (defectItem: Partial<AssessmentOrderDefectItem>) => {
		if (!props.subjectInquiryDetails.defectItems) {
			props.subjectInquiryDetails.defectItems = [];
		}

		const newDefectItemIsValid = (defectItem: Partial<AssessmentOrderDefectItem>): defectItem is AssessmentOrderDefectItem => !!defectItem.description;

		if (!newDefectItemIsValid(defectItem)) {
			throw Error("New defect item is not valid!");
		}

		props.form.validateAll();

		props.subjectInquiryDetails.defectItems.push(defectItem);
		props.form.setValue("defectItems", props.subjectInquiryDetails.defectItems);
		setNewDefectItem(undefined);
	};

	const onDeletingItem = (id: string) => {
		props.form.setValue("defectItems", props.subjectInquiryDetails.defectItems.filter((item) => item.id !== id));
	};

	const cancel = () => {
		setNewDefectItem(undefined);
	};

	return (
		<>
			{(props.viewMode === RepairOrderViewModeBase.NewItem || props.viewMode === RepairOrderViewModeBase.Edit) && (
				<>
					<Box>
						<FormSelect
							label="Gutachter"
							name="assessor.email"
							form={props.form}
							entries={assessors?.map((m) => {
								return {
									choice: m.email,
									label: getAssessorLabel(m)
								};
							})}
							options={{ required: true }}
						/>
					</Box>
					<Box mt={2}>
						<FormNumber
							name="mileage"
							label="Letzter KM-Stand"
							form={props.form}
							inline
							options={{ required: true }}
							fullWidth
						/>
					</Box>
					<Box mt={2}>
						<FormDateTimePicker
							form={props.form}
							label="Letzte Wartung"
							format="DD.MM.YYYY"
							name="dateLastMaintenance"
							variant="date"
							disableFuture
							fullWidth
							minDate={moment(new Date().getDate()).toISOString()}
							views={["year", "month", "day"]}
						/>
					</Box>

					<Box mt={2}>
						<FormYesNo
							name="hasCarsChequebook"
							label="Scheckheft gepflegt"
							form={props.form}
							options={{ required: true }}
						/>
					</Box>

					<Box mt={2}>
						<FormYesNo
							name="isReadyToDrive"
							label="Fahrbereit"
							form={props.form}
							options={{ required: true }}
						/>
					</Box>

					<Box mt={2}>
						<List>
							{props.subjectInquiryDetails?.defectItems?.map((defectItem) => (
								<Box key={defectItem.id} sx={{ marginBottom: 1 }}>
									<ExpertAssesmentDefectListItem
										defectItem={defectItem}
										onDeletingItem={onDeletingItem}
									/>
								</Box>
							))}

							{newDefectItem && (
								<Box sx={{ marginBottom: 1 }}>
									<RepairOrderDefectListItem
										defectItem={newDefectItem}
										new
										cancel={cancel}
										onAddingNewItem={onAddingNewItem}
									/>
								</Box>
							)}

							<ListItem transparent boxShadow={0}>
								<ListItemActions>
									<ListItemAction
										icon={<Add />}
										onClick={newAssessmentDefectItem}
									/>

								</ListItemActions>
								<ListItemContent onClick={newAssessmentDefectItem} boxShadow={0}>
									<Typography variant="subtitle2">
										Sachverhaltsanfrage hinzufügen
									</Typography>
								</ListItemContent>
							</ListItem>
						</List>
					</Box>
				</>
			)}
			{(props.viewMode === RepairOrderViewModeBase.View) && (
				<FluentGrid>
					<Box>
						<TextBlock
							primary={getAssessorLabel(props.subjectInquiryDefect?.assessor) ?? "nicht angegeben"}
							secondary="Gutachter"
						/>
					</Box>
					<Box mt={2}>
						<TextBlock
							primary={props.subjectInquiryDefect?.createdBy?.name}
							secondary="Erstellt von"
						/>
					</Box>
					<Box mt={2}>
						<TextBlock
							primary={props.subjectInquiryDefect?.mileage?.toString() ?? "nicht angegeben"}
							secondary="Letzter Kilometerstand"
						/>
					</Box>
					<Box mt={2}>
						<TextBlock
							primary={
								props.subjectInquiryDefect?.dateLastMaintenance
									? moment(props.subjectInquiryDefect?.dateLastMaintenance).format(dateFormats.date)
									: "nicht angegeben"
							}
							secondary="Letzte Wartung"
						/>
					</Box>
				</FluentGrid>
			)}

			{(props.viewMode === ViewModeAssessment.Details
				|| props.viewMode === InquirySpecificViewMode.DecisionView
				|| props.viewMode === InquirySpecificViewMode.DecisionViewCompleteInquiry
				|| props.viewMode === InquirySpecificViewMode.DecisionViewConvertToAssessment
				|| props.viewMode === InquirySpecificViewMode.DecisionViewAskDekra
				|| props.viewMode === InquirySpecificViewMode.Delete) && (
				<>
					<Box>
						<TextBlock
							primary={getAssessorLabel(props.subjectInquiryDefect.assessor) ?? "nicht angegeben"}
							secondary="Gutachter"
						/>
					</Box>
					<Box mt={2}>
						<TextBlock
							primary={props.subjectInquiryDefect.createdBy.name}
							secondary="Erstellt von"
						/>
					</Box>
					<Box mt={2}>
						<TextBlock
							primary={props.subjectInquiryDefect?.mileage?.toString() ?? "nicht angegeben"}
							secondary="Letzter Kilometerstand"
						/>
					</Box>
					<Box mt={2}>
						<TextBlock
							primary={
								props.subjectInquiryDefect?.dateLastMaintenance
									? moment(props.subjectInquiryDefect?.dateLastMaintenance).format(dateFormats.date)
									: "nicht angegeben"
							}
							secondary="Letzte Wartung"
						/>
					</Box>

					<Box mt={2}>
						<TextBlock
							primary={props.subjectInquiryDefect.defectItems.map(item => item.description).join(', ')}
							secondary="Reparatur"
						/>
					</Box>
				</>
			)}
		</>);
}

export default RepairOrderInquiryForm;
