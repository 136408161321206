import { useState } from "react";
import {
	ReturnOrder,
	ReturnOrderTerminationStatus,
	ReturnOrderTerminationReason,
	returnOrderTerminationReasons,
	ThemeColor,
	UserRole,
} from "../../system/Domain";
import TextBlock from "../../Components/Layout/TextBlock";
import ListItemChip from "../../Components/ListItemChip";
import ListItemActions from "../../Components/ListItemActions";
import ListItem from "../../Components/ListItem";
import ListItemContent from "../../Components/ListItemContent";
import ListItemChips from "../../Components/ListItemChips";
import ListItemAction from "../../Components/ListItemAction";
import formatDateTime from "../../system/formatDateTime";
import useUser from "../../system/useUser";
import formatReturnOrderTerminationReason from "./formatReturnOrderTermination";
import { Box, Input, MenuItem, Select } from "@mui/material";
import { Check, Clear, Edit } from "@mui/icons-material";


type EditProps = {
	termination: ReturnOrderTerminationStatus
	new?: false
};

type NewProps = {
	termination: Partial<ReturnOrderTerminationStatus>
	new: true
	cancel?: () => void
};

interface CommonProps {
	returnOrder: ReturnOrder
	save: (terminationStatus: Partial<ReturnOrderTerminationStatus>) => void
	add: (terminationStatus: Partial<ReturnOrderTerminationStatus>) => void
	disabled?: boolean
}

type SpecificProps = EditProps | NewProps;

export default (props: CommonProps & SpecificProps) => {
	const [description, setDescription] = useState<string>("");
	const [reason, setReason] = useState<ReturnOrderTerminationReason>();
	const [, , hasRole] = useUser();

	const [editing, setEditing] = useState(!!props.new);

	const canSave = reason && description;

	const saveTerminationStatus = async () => {
		if (!canSave) return;

		if (props.new) {
			setEditing(false);
			props.add({
				...props.termination,
				description: description,
				reason: reason,
			})
		} else {
			setEditing(false);
			props.save({
				...props.termination,
				description: description,
				reason: reason,
			});
		}
	};

	const startEditing = () => {
		setEditing(true);
		setDescription(props.termination.description ?? "");
		setReason(props.termination.reason);
	};

	const stopEditing = () => {
		setEditing(false);

		if (props.new && props.cancel) {
			props.cancel();
		}
	};

	let color: ThemeColor = "info";
	if (editing) {
		color = "primary";
	}

	const canEditStop = hasRole(UserRole.AldManager);

	return (
		<ListItem color={color}>
			<ListItemActions color={color}>
				{!editing && (
					<ListItemAction
						disabled={!canEditStop}
						icon={<Edit />}
						onClick={startEditing}
					/>
				)}
				{editing && (
					<>
						<ListItemAction
							icon={<Check />}
							disabled={!canSave}
							onClick={saveTerminationStatus}
						/>
						<ListItemAction
							icon={<Clear />}
							onClick={stopEditing}
						/>
					</>
				)}
			</ListItemActions>
			<ListItemContent color={color}>
				<TextBlock
					primary={
						<>
							{editing && (
								<Box mb={1} display="flex" flexDirection="row">
									<Box mr={2} minWidth={260}>
										<Select
											label="Terminationreason"
											value={reason}
											onChange={(e) => setReason(e.target.value as ReturnOrderTerminationReason)}
											fullWidth
											variant="standard"
										>
											{returnOrderTerminationReasons.map((r) => (
												<MenuItem key={r} value={r}>
													{formatReturnOrderTerminationReason(r)}
												</MenuItem>
											))}
										</Select>
									</Box>
									<Input
										fullWidth
										autoFocus
										value={description}
										sx={
											{ lineHeight: "1.5em" }
										}
									onChange={(e) => setDescription(e.target.value)}
									/>
								</Box>
							)}
							{!editing && (
								<TextBlock
									primary={props.termination.description}
									secondary={`Erstellt: ${props.termination.createdBy?.name} ${formatDateTime(props.termination.dateCreated)}`}
									tertiary={props.termination.updatedBy ? `Angepasst: ${props.termination.updatedBy?.name} ${formatDateTime(props.termination.dateUpdated)}` : undefined}
								/>
							)}
						</>
					}
					flat
					secondary={editing && props.termination.description}
				/>
				<ListItemChips>
					{!editing && (
						<ListItemChip label={formatReturnOrderTerminationReason(props.termination.reason!)} />
					)}
				</ListItemChips>
			</ListItemContent>
		</ListItem>
	);
};
