import { List, ListItem, ListProps } from "@mui/material";
import { VehicleAssessment } from "../../../system/Domain";
import AssessmentDamageListItem from "./AssessmentDamageListItem";
import { useAsync } from "react-use";
import axios from "axios";
import { AssessmentDamage } from "./AssessmentPage";

interface Props extends ListProps {
	assessment: VehicleAssessment;
}

const AssessmentDamageList = ({ assessment }: Props) => {
	const { value } = useAsync(async () => {
		const { data: damages } = await axios.get<AssessmentDamage[]>(`/api/assessments/${assessment.id}/damages`);
		return damages;
	}, [assessment]);

	const damages = value || [];

	return (
		<List>
			{damages.map((d) => {
				return <AssessmentDamageListItem key={d.text} damage={d} />;
			})}
			{damages.length === 0 && <ListItem>Keine Daten gefunden</ListItem>}
		</List>
	);
};

export default AssessmentDamageList;
