import { CheckBoxOutlineBlank, CheckBoxOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';

import FluentGrid from '../../Components/FluentGrid';
import TextBlock from '../../Components/Layout/TextBlock';
import ListItem from '../../Components/ListItem';
import ListItemAction from '../../Components/ListItemAction';
import ListItemActions from '../../Components/ListItemActions';
import ListItemChip from '../../Components/ListItemChip';
import ListItemChips from '../../Components/ListItemChips';
import ListItemContent from '../../Components/ListItemContent';
import { Plate } from '../../Components/Plate';
import { LeasmanContractType, LeasmanVehicle, ThemeColor } from '../../system/Domain';
import formatDate from '../../system/formatDate';

interface Props {
	leasmanVehicle: LeasmanVehicle
	isSelected: boolean
	onSelect?: (leasmanVehicle?: LeasmanVehicle) => void
}

export default ({ leasmanVehicle, isSelected, onSelect }: Props) => {
	let color: ThemeColor = "info";
	if (isSelected) {
		color = "primary";
	}

	const toggleSelected = () => onSelect?.(isSelected ? undefined : leasmanVehicle);

	const formatLeasmanContractType = (type: LeasmanContractType) => {
		switch (type) {
			case "Mileage":
				return "Kilometervertrag";
			case "ResidualValue":
				return "Restwertvertrag";
			case "External":
				return "Externer Vertrag";
			case "Service":
				return "Servicevertrag";
			case "Supplementary":
				return "Ergänzungsvertrag";
			case "Finance":
				return "Finanzierung";
			case "Installment":
				return "Abzahlungsvereinbarung";
			default:
				return `Vertragstyp: ${type}`;
		}
	};

	return (
		<ListItem color={color}>
			{!!onSelect && (
				<ListItemActions color={color}>
					{!isSelected && (
						<ListItemAction
							icon={<CheckBoxOutlineBlank />}
							onClick={toggleSelected}
						/>
					)}
					{isSelected && (
						<ListItemAction
							icon={<CheckBoxOutlined />}
							onClick={toggleSelected}
						/>
					)}
				</ListItemActions>
			)}
			<ListItemContent color={color} onClick={toggleSelected}>
				<TextBlock
					primary={(
						<Plate plateNumber={leasmanVehicle.plateNumber} style={{ zoom: 0.6 }} />
					)}
					secondary={`${leasmanVehicle.type.make.name} ${leasmanVehicle.type.model.version} ${leasmanVehicle.type.model.variant}`}
				/>
				<Box mt={2} mb={1}>
					<FluentGrid itemWidth={150}>
						<TextBlock
							primary={leasmanVehicle.vin}
							secondary="FIN"
							reduced
						/>
						<TextBlock
							primary={leasmanVehicle.contract.id.toString()}
							secondary="Vertragsnummer"
							reduced
						/>
						<TextBlock
							primary={formatDate(leasmanVehicle.contract.begin.date)}
							secondary="Vertragsbeginn"
							reduced
						/>
						<TextBlock
							primary={formatDate(leasmanVehicle.contract.return.date)}
							secondary="Rückgabedatum"
							reduced
						/>
						{/*<TextBlock*/}
						{/*	primary={leasmanVehicle.objectId.toString()}*/}
						{/*	secondary="Objekt-Id"*/}
						{/*	reduced*/}
						{/*/>*/}
					</FluentGrid>
				</Box>
				<ListItemChips>
					<ListItemChip
						color="primary"
						label={leasmanVehicle.tenant}
					/>
					<ListItemChip
						color="warning"
						label={formatLeasmanContractType(leasmanVehicle.contract.type)}
					/>
					<ListItemChip
						label={`${leasmanVehicle.contract.customer.name1} ${leasmanVehicle.contract.customer.name2}`.trim()}
					/>
				</ListItemChips>
			</ListItemContent>
		</ListItem>
	);
}
