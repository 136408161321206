import moment from 'moment';
import { useState } from 'react';

import { Check, Clear, Edit } from "@mui/icons-material";
import { Input } from '@mui/material';

import TextBlock from '../../Components/Layout/TextBlock';
import ListItem from '../../Components/ListItem';
import ListItemAction from '../../Components/ListItemAction';
import ListItemActions from '../../Components/ListItemActions';
import ListItemContent from '../../Components/ListItemContent';
import dateFormats from '../../system/dateFormats';
import { ArrivalTransmissionNote, ThemeColor, Vehicle } from '../../system/Domain';

type EditProps = {
	new?: false
	save: (note: string) => void
	history?: false
}

type CreateProps = {
	new: true
	cancel: () => void
	save: (note: string) => void
	history?: false
}

type DisplayProps = {
	history: true
	new?: false
	note: ArrivalTransmissionNote
}

interface CommonProps {
	vehicle: Vehicle
}

type SpecificProps = EditProps | CreateProps | DisplayProps;

const ArrivalTransmissionNoteListItem = (props: CommonProps & SpecificProps & any) => {
	const [text, setText] = useState("");
	const [editing, setEditing] = useState(!!props.new);
	const [saving, setSaving] = useState(false);

	let color: ThemeColor = "info";
	if (editing) {
		color = "primary";
	}

	const stopEditing = () => {
		setEditing(false);
		if (props.new) {
			props.cancel();
		}
	};

	const save = () => {
		setSaving(true);

		try {
			!props.history && props.save(text);
		} finally {
			setSaving(false);
			setText("");
			setEditing(false);
		}
	};

	const startEditing = () => {
		setEditing(true);

		if (!props.new) {
			setText(props.vehicle.remarketing.registrationDocuments.arrivalTransmissionNote.text);
		}
	};

	const note = props.history ? props.note : props.vehicle.remarketing.registrationDocuments?.arrivalTransmissionNote;

	return (
		<ListItem color={color}>
			{!props.history && (
				<ListItemActions color={color}>
					{!editing && (
						<ListItemAction
							icon={<Edit />}
							onClick={startEditing}
						/>
					)}
					{editing && (
						<>
							<ListItemAction
								icon={<Check />}
								onClick={!saving ? save : undefined}
							/>
							<ListItemAction
								icon={<Clear />}
								onClick={stopEditing}
							/>
						</>
					)}
				</ListItemActions>
			)}
			<ListItemContent color={color}>
				{(props.new || editing) && (
					<Input
						fullWidth
						autoFocus
						value={text}
						onChange={e => setText(e.target.value)}
						onKeyPress={e => e.key === "Enter" && !saving ? save : undefined}
					/>
				)}
				{note && !props.new && !editing && (
					<TextBlock
						primary={note.text}
						secondary={`${note.createdBy.name} - ${moment(note.dateCreated).format(dateFormats.dateTime)}`}
					/>
				)}
			</ListItemContent>
		</ListItem>
	);
};

export default ArrivalTransmissionNoteListItem;
