import axios from "axios";
import moment from "moment";
import { useState } from 'react';
import { useHistory } from "react-router";
import VehicleSearch from "../../../Components/VehicleSearch";
import VehicleHeader from "../VehicleHeader";
import Tile from "../../../Components/Tiles/Tile";
import Layout from "../../../Components/Layout/Layout";
import PictureUpload from "../../../Components/PictureUpload";
import { Vehicle } from "../../../system/Domain";
import TileContent from "../../../Components/Tiles/TileContent";
import TileContentAction from "../../../Components/Tiles/TileContentAction";
import { Box, Divider, FormControl, Grid, Typography } from "@mui/material";
import TileContentActions from "../../../Components/Tiles/TileContentActions";
import { DatePicker } from "@mui/x-date-pickers";
import { ImageUploadProps } from "../PictureSets/VehiclePictureSets";

const ManualVehicleDocumentArrival = () => {
	const history = useHistory();
	const [showForm, setShowForm] = useState(false);
	const [vehicle, setVehicle] = useState<Vehicle>();

	const [titlePictureReference, setTitlePictureReference] = useState<ImageUploadProps>();
	const [registrationDocumentFrontPictureProof, setRegistrationDocumentFrontPictureProof] = useState<ImageUploadProps>();
	const [registrationDocumentBackPictureProof, setRegistrationDocumentBackPictureProof] = useState<ImageUploadProps>();
	const [unregistrationDate, setUnregistrationDate] = useState<string | undefined>(undefined);

	const handleSubmit = async () => {
		if (!vehicle || !unregistrationDate) return;

		await axios.put(`/api/vehicles/${vehicle.id}/remarketing/documents/manual/arrival`, {
			documentsPictureProof: {
				registrationTitle: titlePictureReference.fileReference,
				registrationDocumentBack: registrationDocumentBackPictureProof.fileReference,
				registrationDocumentFront: registrationDocumentFrontPictureProof.fileReference
			},
			unregistrationDate: unregistrationDate
		});

		history.push("/vehicles/registration-documents/dashboard");
	};

	const canSubmit = titlePictureReference?.fileReference && registrationDocumentFrontPictureProof?.fileReference && registrationDocumentBackPictureProof?.fileReference && unregistrationDate;

	return (
		<Layout>
			{!showForm && (
				<Box>
					<Typography variant="h6" marginBottom={2}>
						Manueller Dokumenteneingang
					</Typography>
					<VehicleSearch
						onClick={(v) => {
							setShowForm(true);
							setVehicle(v)
						}}
						perespective="Stock"
						// we want to use default sorting
						placeholder="Kennzeichen oder Vin suchen..."
						customerDetails={false}
					/>
				</Box>
			)}
			{showForm && vehicle && (
				<>
					<Typography variant="h6">
						Manueller Dokumenteneingang
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Box p={3}>
								<VehicleHeader vehicle={vehicle} />
							</Box>
						</Grid>
						<Grid item xs={12} sm={6} lg={4} xl={3}>
							<Box display="inline-block">
								<form onSubmit={handleSubmit}>
									<Tile>
										<TileContent>
											<Grid container spacing={2}>
												<Grid item>
													<Box mx={1}>
														<Box>Fahrzeugschein Vorderseite</Box>
														<PictureUpload
															disabled={false}
															fullWidth={false}
															readOnly={false}
															previewUrl=""
															setter={setRegistrationDocumentFrontPictureProof}
														/>
													</Box>
												</Grid>
												<Grid item>
													<Box mx={1}>
														<Box>Fahrzeugschein Rückseite</Box>
														<PictureUpload
															disabled={false}
															fullWidth={false}
															readOnly={false}
															previewUrl=""
															setter={setRegistrationDocumentBackPictureProof}
														/>
													</Box>
												</Grid>
												<Grid item>
													<Box mx={1}>
														<Box>Fahrzeugbrief</Box>
														<PictureUpload
															disabled={false}
															fullWidth={false}
															readOnly={false}
															previewUrl=""
															setter={setTitlePictureReference}
														/>
													</Box>
												</Grid>
												<Divider />
												<Grid item>
													<Box ml={1}>
														<FormControl fullWidth>
															<DatePicker
																// variant="inline"
																format="DD.MM.YYYY"
																label="Abmeldedatum"
																value={unregistrationDate ? moment(unregistrationDate) : null}
																slotProps={{ textField: { variant: 'standard' } }}
																onChange={(d: any) => setUnregistrationDate((d || moment()).startOf("d").toISOString(true))}
															/>
														</FormControl>
													</Box>
												</Grid>
											</Grid>
										</TileContent>
										<TileContentActions
											align="right"
										>
											<TileContentAction
												onClick={() => {
													setVehicle(undefined);
													setShowForm(false);
												}}
												label="Zurück"
											/>
											<TileContentAction
												color="primary"
												disabled={!canSubmit}
												onClick={handleSubmit}
												label="Eingang bestätigen"
											/>
										</TileContentActions>
									</Tile>
								</form>
							</Box>
						</Grid>
					</Grid>
				</>
			)}
		</Layout>
	);
};

export default ManualVehicleDocumentArrival;
