import { PricingRequest } from "system/Domain";
import LayoutNavigation from "../Components/Layout/LayoutNavigation";
import PricingRequestNavigationSection from "./PricingRequestNavigationSection";
import RootNavigationSection from "./RootNavigationSection";
import SalesNavigationSection from "./SalesNavigationSection";

interface Props {
	request?: PricingRequest
}

export default ({ request }: Props) => {
	return (
		<LayoutNavigation>
			{request && (
				<PricingRequestNavigationSection request={request} />
			)}
			<SalesNavigationSection />
			<RootNavigationSection />
		</LayoutNavigation>
	);
};
