import { Table, TableBody } from "@mui/material";
import { ReactNode } from "react";

const Properties = ({ children }: { children: ReactNode }) => (
	<Table padding="none">
		<TableBody>
			{children}
		</TableBody>
	</Table>
);

export default Properties;
