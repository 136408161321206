import { useState } from 'react';
import Layout from "../../Components/Layout/Layout";
import { Box } from "@mui/material";
import PostmanSidebar from './PostmanSidebar';
import AdminNavigation from 'Navigation/AdminNavigation';
import JsonViewer from 'Components/JsonViewer';
import { getApiAsJson } from './postmanUtils';
import useAsyncEffect from 'system/useAsyncEffect';

const Postman = () => {
	const [apiJson, setApiJson] = useState<object>();

	useAsyncEffect(async () => {
		try {
			const response = await getApiAsJson(false);

			setApiJson(response.data);
		} catch (e) {
			console.log(e);
		}
	}, []);

	return (
		<Layout
			title="Postman"
			navigation={<AdminNavigation />}
			sidebar={<PostmanSidebar />}
		>
			<Box mb={2}>
				<JsonViewer json={apiJson} />
			</Box>
		</Layout>
	);
}

export default Postman;
