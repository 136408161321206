import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { DocumentArrivalType, VehicleRegistrationDocumentStatus } from "../../../system/Domain";
import { MouseEvent } from "react";

type EditProps = {
	onChange: (event: MouseEvent<HTMLElement, MouseEvent>, value: any) => void
	value?: DocumentArrivalType
	previousValue?: DocumentArrivalType
	disable: boolean
	status: VehicleRegistrationDocumentStatus
	hideNone?: boolean
	alwaysShowAll?: boolean
}

type ReadOnlyProps = {
	disable: true
	value: DocumentArrivalType
	onChange?: undefined
	hideNone?: boolean
	alwaysShowAll?: false
}

export const arrivalTypes = {
	"None": {
		key: "None",
		label: "Nichts",
		short: "Nichts",
		missing: "All",
		missingLabel: "Alles",
		missingShort: "Alles"
	},
	"RegistrationTitle": {
		key: "RegistrationTitle",
		label: "Fahrzeugbrief",
		short: "Brief",
		missing: "RegistrationDocument",
		missingLabel: "Fahrzeugschein",
		missingShort: "Schein"
	},
	"RegistrationDocument": {
		key: "RegistrationDocument",
		label: "Fahrzeugschein",
		short: "Schein",
		missing: "RegistrationTitle",
		missingLabel: "Fahrzeugbrief",
		missingShort: "Brief"
	},
	"All": {
		key: "All",
		label: "Beides",
		short: "Beides",
		missing: "None",
		missingLabel: "Nichts",
		missingShort: "Nichts"
	}
};

const ArrivalToggleButtonGroup = (props: EditProps | ReadOnlyProps | any) => {
	const usePreviousValue = !props.disable
		&& props.previousValue
		&& (
			props.status === "Arrived"
			|| props.status === "PartiallyArrived"
			|| props.status === "NotArrived"
		);

	const previousValue = !props.disable && usePreviousValue && props.previousValue ? arrivalTypes[props.previousValue] : undefined;

	return (
		<ToggleButtonGroup
			value={props.value}
			size="small"
			exclusive
			onChange={props.onChange}
		>
			{props.hideNone !== true && (
				<ToggleButton
					key={arrivalTypes.None.key}
					value={arrivalTypes.None.key}
					disabled={props.disable}
				>
					<Typography variant="caption">{arrivalTypes.None.short}</Typography>
				</ToggleButton>
			)}
			<ToggleButton
				key={arrivalTypes.RegistrationTitle.key}
				value={arrivalTypes.RegistrationTitle.key}
				disabled={props.disable || (
					previousValue && previousValue.key === arrivalTypes.RegistrationTitle.key
				)}
			>
				<Typography variant="caption">{arrivalTypes.RegistrationTitle.short}</Typography>
			</ToggleButton>
			<ToggleButton
				key={arrivalTypes.RegistrationDocument.key}
				value={arrivalTypes.RegistrationDocument.key}
				disabled={props.disable || (
					previousValue && previousValue.key === arrivalTypes.RegistrationDocument.key
				)}
			>
				<Typography variant="caption">{arrivalTypes.RegistrationDocument.short}</Typography>
			</ToggleButton>
			{(props.alwaysShowAll || !usePreviousValue || (
				previousValue!.key === arrivalTypes.None.key
			)) && (
				<ToggleButton
					key={arrivalTypes.All.key}
					value={arrivalTypes.All.key}
					disabled={props.disable}
				>
					<Typography variant="caption">{arrivalTypes.All.short}</Typography>
				</ToggleButton>
			)}
		</ToggleButtonGroup>
	);
};

export default ArrivalToggleButtonGroup;
