import { Form } from "../../../system/useForm";
import { Box, InputAdornment, Typography } from "@mui/material";
import FormYesNo from "../../../Components/FormYesNo";
import FormText from "../../../Components/FormText";

interface Props {
	form: Form,
	canEditDetails?: boolean
}

const StepAdditional = ({ form, canEditDetails = true }: Props) => {

	return (
		<Box>
			<Box mb={2}>
				<FormYesNo
					name="isPoolfleet"
					label="Handelt es sich um ein ALD Flex Fahrzeug?"
					form={form}
					options={{ required: true }}
				/>
			</Box>
			{/* we hide (rather then not render) 'cause we need them present in form!!! */}
			<Box sx={{ visibility: canEditDetails ? "visible" : "hidden" }}>
				<Box mt={2}>
					<Typography variant="h6" gutterBottom>
						Abmeldung
					</Typography>
				</Box>
				<Box>
					<FormYesNo
						name="doUnregistration"
						label="Soll das Auto abgemeldet werden?"
						form={form}
						options={{
							required: true
						}}
					/>
				</Box>
				<Box mt={2}>
					<Typography variant="h6" gutterBottom>
						Kostenverteilung
					</Typography>
					<Box mt={2}>
						<FormText
							name="transportCostInstallments"
							form={form}
							label="Transportkosten in Rate kalkuliert"
							type="number"
							startAdornment={<InputAdornment position="start">€</InputAdornment>}
						/>
					</Box>
					<Box mt={2}>
						<FormText
							name="costTransferCustomer"
							form={form}
							label="Weiterbelastung an Kunden"
							type="number"
							startAdornment={<InputAdornment position="start">€</InputAdornment>}
						/>
					</Box>
					<Box mt={2}>
						<FormText
							name="debitingAld"
							form={form}
							label="Kosten zu Lasten ALD"
							type="number"
							startAdornment={<InputAdornment position="start">€</InputAdornment>}
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

export default StepAdditional;
