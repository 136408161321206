import LayoutNavigation from "../Components/Layout/LayoutNavigation";
import RootNavigationSection from "./RootNavigationSection";
import ServicesNavigationSection from "./ServicesNavigationSection";

export default () => {
	return (
		<LayoutNavigation>
			<ServicesNavigationSection />
			<RootNavigationSection />
		</LayoutNavigation>
	);
};
