import moment from "moment";
import { StorageFile } from "./Domain";

const notFound: StorageFile = {
  hash: "0383a250e987fe737edbf2518f208bc7",
  filename: "notfound.jpg",
  date: moment().toISOString()
}

export { notFound }
