import axios from "axios";
import Tile from "Components/Tiles/Tile";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { Check, Close, Warning } from "@mui/icons-material";
import { Box, Grid, useTheme } from "@mui/material";

import { useHistory } from "react-router";
import Chip from "../../Components/Chip";
import FluentGrid from "../../Components/FluentGrid";
import FormChoices from "../../Components/FormChoices";
import FormElementLabel from "../../Components/FormElementLabel";
import FormNumber from "../../Components/FormNumber";
import FormText from "../../Components/FormText";
import FormTiresAndRims from "../../Components/FormTiresAndRims";
import FormYesNo from "../../Components/FormYesNo";
import TextBlock from "../../Components/Layout/TextBlock";
import ListItemChip from "../../Components/ListItemChip";
import ListItemChips from "../../Components/ListItemChips";
import TileContent from "../../Components/Tiles/TileContent";
import TileContentAction from "../../Components/Tiles/TileContentAction";
import TileContentActions from "../../Components/Tiles/TileContentActions";
import { Remarketing, ReturnForm, Vehicle, VehicleAssessment } from "../../system/Domain";
import formatDateTime from "../../system/formatDateTime";
import useAction from "../../system/useAction";
import useForm from "../../system/useForm";
import { formatRepairOrderStatus, paintRepairOrderStatus } from "./CarglassRepairOrderListItem";

interface Props {
	vehicle: Vehicle;
	setVehicle: Dispatch<SetStateAction<Vehicle | undefined>>;
	assessment?: VehicleAssessment;
	returnForm?: ReturnForm;
	disabled: boolean;
}

const clonePartialRemarketing = (vehicle: Vehicle | undefined): Partial<Remarketing> => ({
	previousOwners: vehicle?.remarketing?.previousOwners,
	mileage: vehicle?.remarketing?.mileage,
	publicHeadline: vehicle?.remarketing?.publicHeadline,
	publicHeadlineConfirmed: vehicle?.remarketing?.publicHeadlineConfirmed,
	hasWindshieldDamage: vehicle?.remarketing?.hasWindshieldDamage,
	windshieldDamage: !vehicle?.remarketing?.windshieldDamage
		? null
		: {
				...vehicle.remarketing.windshieldDamage,
			},
	hadAccident: vehicle?.remarketing?.hadAccident,
	hadEngineReplacement: vehicle?.remarketing?.hadEngineReplacement,
	engineReplacementMileage: vehicle?.remarketing?.engineReplacementMileage,
	hasAdditionalTires: vehicle?.remarketing?.hasAdditionalTires,
	tiresAndRims: {
		...vehicle?.remarketing?.tiresAndRims,
	},
});

export default ({ vehicle, setVehicle, assessment, returnForm, disabled }: Props) => {
	const [remarketing, setRemarketing] = useState<Partial<Remarketing>>(clonePartialRemarketing(vehicle));
	const [originPublicHeadline] = useState({
		text: remarketing.publicHeadline,
		confirmed: remarketing.publicHeadlineConfirmed,
	});

	const history = useHistory();
	const theme = useTheme();

	useEffect(() => {
		setRemarketing(clonePartialRemarketing(vehicle));
	}, [
		vehicle?.id,
		vehicle?.remarketing?.mileage,
		vehicle?.remarketing?.previousOwners,
		vehicle?.remarketing?.publicHeadline,
		vehicle?.remarketing?.publicHeadlineConfirmed,
		vehicle?.remarketing?.hasWindshieldDamage,
		vehicle?.remarketing?.windshieldDamage?.repairWork,
		vehicle?.remarketing?.hadAccident,
		vehicle?.remarketing?.hadEngineReplacement,
		vehicle?.remarketing?.engineReplacementMileage,
		vehicle?.remarketing?.hasAdditionalTires,
		vehicle?.remarketing?.tiresAndRims?.tireType,
		vehicle?.remarketing?.tiresAndRims?.rimType,
	]);

	useEffect(() => {
		if (!remarketing.hasWindshieldDamage) {
			setRemarketing((r) => ({
				...r,
				windshieldDamage: null,
			}));
		}
	}, [remarketing.hasWindshieldDamage]);

	useEffect(() => {
		if (remarketing.publicHeadline !== originPublicHeadline.text && remarketing.publicHeadlineConfirmed === true) {
			form.setValue("publicHeadlineConfirmed", false);
		}

		if (
			remarketing.publicHeadline === originPublicHeadline.text &&
			originPublicHeadline.confirmed === true &&
			remarketing.publicHeadlineConfirmed !== true
		) {
			form.setValue("publicHeadlineConfirmed", true);
		}
	}, [remarketing.publicHeadline]);

	useEffect(() => {
		if (!assessment || remarketing?.mileage) {
			return;
		}

		setRemarketing((r) => ({
			...r,
			mileage: assessment?.mileage,
		}));
	}, [assessment]);

	useEffect(() => {
		if (!returnForm || remarketing?.hasAdditionalTires !== null) {
			return;
		}

		const tiresPartial = returnForm.additionalTiresAndRims
			? {
					hasAdditionalTires: returnForm.additionalTiresAndRims,
					tiresAndRims: {
						rimType: returnForm.suppliedTiresAndRims.rimType,
						tireType: returnForm.suppliedTiresAndRims.tireType,
					},
				}
			: {
					hasAdditionalTires: returnForm.additionalTiresAndRims,
				};
		setRemarketing((r) => ({
			...r,
			...tiresPartial,
		}));
	}, [returnForm]);

	const form = useForm({
		values: remarketing,
		setValues: setRemarketing,
	});

	const [save, isSaving] = useAction(async () => {
		if (!vehicle) {
			return;
		}

		if (!form.validateAll()) {
			return;
		}

		const { data } = await axios.put<Vehicle>(`/api/vehicles/${vehicle.id}/remarketing`, remarketing);
		setVehicle(data);
	});

	const labelPublicHeadline = (
		<Box mb={0.25} style={{ display: "flex", justifyContent: "space-between" }}>
			<FormElementLabel text="Verkaufstitel" form={form} name="publicHeadline"></FormElementLabel>
			<Chip
				label={remarketing.publicHeadlineConfirmed ? "bestätigt" : "nicht bestätigt"}
				icon={
					remarketing.publicHeadlineConfirmed ? (
						<Check style={{ color: theme.palette.success.light }} />
					) : (
						<Close />
					)
				}
				clickable={!disabled}
				explicitColor={remarketing.publicHeadlineConfirmed ? theme.palette.success.light : undefined}
				variant={"outlined"}
				onClick={
					disabled
						? undefined
						: () => {
								form.setValue("publicHeadlineConfirmed", !remarketing.publicHeadlineConfirmed);
							}
				}
			/>
		</Box>
	);

	const hasUnsavedChanges =
		remarketing.mileage !== vehicle?.remarketing.mileage ||
		remarketing.previousOwners !== vehicle?.remarketing.previousOwners ||
		remarketing.publicHeadline !== vehicle?.remarketing.publicHeadline ||
		remarketing.publicHeadlineConfirmed !== vehicle?.remarketing.publicHeadlineConfirmed ||
		remarketing.hasWindshieldDamage !== vehicle?.remarketing.hasWindshieldDamage ||
		remarketing.windshieldDamage?.repairWork?.description !==
			vehicle?.remarketing.windshieldDamage?.repairWork?.description ||
		remarketing.hadAccident !== vehicle?.remarketing.hadAccident ||
		remarketing.hadEngineReplacement !== vehicle?.remarketing.hadEngineReplacement ||
		remarketing.engineReplacementMileage !== vehicle?.remarketing.engineReplacementMileage ||
		remarketing.hasAdditionalTires !== vehicle?.remarketing.hasAdditionalTires ||
		remarketing.tiresAndRims?.tireType !== vehicle?.remarketing.tiresAndRims?.tireType ||
		remarketing.tiresAndRims?.rimType !== vehicle?.remarketing.tiresAndRims?.rimType;

	return (
		<Tile title="Verkauf">
			<TileContent dense>
				<Box p={2} pb={4}>
					<Grid container spacing={3}>
						<FormNumber
							name="previousOwners"
							label="Anzahl Vorbesitzer"
							form={form}
							inline
							readOnly={disabled}
							options={{
								required: true,
							}}
							fullWidth
						/>

						<FormNumber
							name="mileage"
							label="Letzter KM-Stand"
							form={form}
							inline
							readOnly={disabled}
							options={{
								required: true,
							}}
							fullWidth
						/>

						<FormText
							name="publicHeadline"
							label={labelPublicHeadline}
							form={form}
							inline
							readOnly={disabled}
							multiline
							options={{
								required: true,
							}}
							fullWidth
						/>

						<FormYesNo
							name="hasWindshieldDamage"
							label="Frontscheibe beschädigt"
							form={form}
							disabled={disabled}
							options={{
								required: true,
							}}
						/>

						{remarketing.hasWindshieldDamage && (
							<FormChoices
								name="windshieldDamage.repairWork.repairTask"
								label="Frontscheibe Reparatur"
								form={form}
								disabled={disabled}
								options={{
									required: true,
								}}
								choices={{
									None: "Keine",
									WindshieldReplacement: "Austausch",
									WindshieldSmartRepair: "Smart-Repair",
								}}
							/>
						)}

						<FormYesNo
							name="hadAccident"
							label="Vorschäden laut Gutachten"
							form={form}
							disabled={disabled}
							options={{
								required: true,
							}}
						/>

						<FormYesNo
							name="hadEngineReplacement"
							label="Austauschmotor"
							form={form}
							disabled={disabled}
							options={{
								required: true,
							}}
						/>

						{remarketing.hadEngineReplacement && (
							<FormNumber
								name="engineReplacementMileage"
								label="KM-Stand bei Tausch"
								form={form}
								readOnly={disabled}
								options={{
									required: true,
								}}
							/>
						)}

						<FormYesNo
							name="hasAdditionalTires"
							label="Wurden weitere Reifen und Felgen abgegeben?"
							form={form}
							disabled={disabled}
							options={{
								required: true,
							}}
						/>

						{remarketing.hasAdditionalTires && (
							<FormTiresAndRims name="tiresAndRims" form={form} disabled={disabled} canHaveNoRims />
						)}
					</Grid>
				</Box>
				{!!vehicle.remarketing.windshieldDamage?.orderId && (
					<FluentGrid p={2} mb={1} itemWidth={150} borderTop="1px solid rgba(255, 255, 255, 0.1)">
						<TextBlock
							flat
							primary="Carglass Auftrag"
							secondary={
								<>
									{formatDateTime(vehicle.remarketing.windshieldDamage.statusDate)}
									{vehicle.remarketing.windshieldDamage.status && (
										<ListItemChips>
											<ListItemChip
												label={formatRepairOrderStatus(
													vehicle.remarketing.windshieldDamage.status,
												)}
												color={paintRepairOrderStatus(
													vehicle.remarketing.windshieldDamage.status,
												)}
												onClick={() =>
													history.push("/orders/repairs", {
														plateNumber: vehicle.plateNumber,
													})
												}
											/>
										</ListItemChips>
									)}
								</>
							}
						/>
					</FluentGrid>
				)}
				{!disabled && (
					<TileContentActions icon={!hasUnsavedChanges ? <Check /> : <Warning />} isBusy={isSaving}>
						<TileContentAction
							label={hasUnsavedChanges ? "Speichern" : "Gespeichert"}
							color="primary"
							disabled={!hasUnsavedChanges || form.hasErrors || isSaving}
							onClick={save}
						/>
						{hasUnsavedChanges && (
							<TileContentAction
								label="Verwerfen"
								color="secondary"
								disabled={isSaving}
								onClick={() => setRemarketing(clonePartialRemarketing(vehicle))}
							/>
						)}
					</TileContentActions>
				)}
			</TileContent>
		</Tile>
	);
};
