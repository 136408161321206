import { useEffect } from 'react';
import { CustomerOrder } from "../../system/Domain";
import { Box } from "@mui/material";
import CustomerDeliveryChoice from "./CustomerDelivery/CustomerDeliveryChoice";
import PricingRequestChoice from "./PricingRequest/PricingRequestChoice";
import CollectionChoice from "./Collection/CollectionChoice";
import Typography from "@mui/material/Typography";
import CustomerActionChoiceButton from "./CustomerActionChoiceButton";
import { useRecoilState } from "recoil";
import { defaultCustomerTitle, customerTitle } from "../../system/atoms/customerTitle";
import { customerChoice } from "../../system/atoms/customerChoice";
import { Commute, EmojiTransportation, LocalOffer } from "@mui/icons-material";
import moment from "moment";
import dateFormats from "../../system/dateFormats";
import showMessage from "../../Dialogs/showMessage";

interface Props {
	customerOrder: CustomerOrder
	toStatusOverview: () => void
}

const CustomerActionChoices = (props: Props) => {
	const [choice, setChoice] = useRecoilState(customerChoice);
	const [, setCustomerTitle] = useRecoilState(customerTitle);

	useEffect(() => setCustomerTitle(defaultCustomerTitle), []);

	const back = () => setChoice(undefined);

	const selectPricingRequest = async () => {
		const contractEndDate = moment(props.customerOrder.dateContractEnd).add(-20,'weeks');

		// we do not allow a pricing request with more than 20 weeks left on the contract
		if (contractEndDate.isAfter(moment())) {
			await showMessage({
				title: "Kaufpreisanfrage erstellen nicht möglich",
				message: `Der Kaufpreis kann erst ab 20 Wochen vor Vertragsende angefragt werden. Diese Option ist damit erst ab dem ${contractEndDate.format(dateFormats.date)} verfügbar.`
			});

			return false;
		}

		setChoice("PricingRequest");
	};

	return (
		<>
			{!choice && (
				<>
					<Box mt={4} mb={2}>
						<Typography variant="h6">
							{!props.customerOrder.dateContractEnd && (
								<>Sehr geehrter Kunde, ihr Leasingvertrag läuft bald aus.</>
							)}
							{props.customerOrder.dateContractEnd && (
								<>
									Sehr geehrter Kunde, ihr Leasingvertrag läuft am {moment(props.customerOrder.dateContractEnd).format(dateFormats.date)} aus.
								</>
							)}
						</Typography>
					</Box>
					<Box m={2} display="inline-block">
						<CustomerActionChoiceButton
							title="Abholung planen"
							icon={<Commute color="disabled" />}
							onClick={() => setChoice("Return")}
						/>
					</Box>
					<Box m={2} display="inline-block">
						<CustomerActionChoiceButton
							title="Selbst anliefern"
							icon={<EmojiTransportation color="disabled" />}
							onClick={() => setChoice("CustomerDelivery")}
						/>
					</Box>
					{!props.customerOrder.isRkv && (
						<Box m={2} display="inline-block">
							<CustomerActionChoiceButton
								title="Preis anfragen"
								icon={<LocalOffer color="disabled" />}
								onClick={selectPricingRequest}
							/>
						</Box>
					)}
				</>
			)}
			{choice && (
				<>
					{choice === "Return" && (
						<CollectionChoice
							backAction={back}
							{...props}
						/>
					)}
					{choice === "CustomerDelivery" && (
						<CustomerDeliveryChoice
							backAction={back}
							{...props}
						/>
					)}
					{choice === "PricingRequest" && (
						<PricingRequestChoice
							backAction={back}
							{...props}
						/>
					)}
				</>
			)}
		</>
	);
}

export default CustomerActionChoices;
