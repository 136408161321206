import { Dispatch, SetStateAction, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Add, GetApp, RotateLeft } from '@mui/icons-material';
import { Button, Divider, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Sidebar from '../../../Components/Sidebar/Sidebar';
import SidebarButton from '../../../Components/Sidebar/SidebarButton';
import SidebarForm from "../../../Components/Sidebar/SidebarForm";
import SidebarGroup from '../../../Components/Sidebar/SidebarGroup';
import Sort, { SortItem } from '../../../Components/Sort';
import { CustomerOrdersOverviewPerspective, CustomerOrderType } from '../../../system/Domain';
import { CustomerOrderChoice, CustomerOrdersOverviewQuery } from './CustomerOrdersOverview';
import axios from "axios";
import parseContentDispositionFilename from "../../../system/parseContentDispositionFilename";
import fileDownload from "js-file-download";

interface Props {
	query: CustomerOrdersOverviewQuery
	setQuery: Dispatch<SetStateAction<CustomerOrdersOverviewQuery>>
	resetQuery: () => CustomerOrdersOverviewQuery
	triggerExport: () => Promise<void>
}

const sortItems: SortItem[] = [
	{
		title: "Erstellungsdatum",
		field: "dateCreated"
	}
];

const CustomerOrdersOverviewSidebar = (props: Props) => {
	const history = useHistory();

	const [isProcessing, setIsProcessing] = useState(false);

	const triggerExport = async () => {
		setIsProcessing(true);

		try {
			const response = await axios.get(`/api/orders/customers`, {
				responseType: "blob",
				params: {
					...props.query,
					output: "xlsx"
				}
			});

			const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
			fileDownload(response.data, filename);
		} finally {
			setIsProcessing(false);
		}
	};

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarButton
					color="primary"
					startIcon={<Add />}
					label="Neuer Kundenauftrag"
					onClick={() => history.push("/orders/customers/new")}
				/>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarForm>
					<FormControl variant="standard" fullWidth>
						<InputLabel>Perspektive</InputLabel>
						<Select
							value={props.query.perspective}
							onChange={(e) => props.setQuery(g => ({
								...g,
								skip: 0,
								perspective: e.target.value as CustomerOrdersOverviewPerspective
							}))}
						>
							<MenuItem value="All">Alle</MenuItem>
							<MenuItem value="Created">Erstellt</MenuItem>
							<MenuItem value="Cancelled">Storniert</MenuItem>
							<MenuItem value="Completed">Ausgefüllt</MenuItem>
							<MenuItem value="Own">Von mir erstellt</MenuItem>
						</Select>
					</FormControl>
					{props.query.perspective === "Completed" && (
						<FormControl variant="standard" fullWidth>
							<InputLabel>Typ</InputLabel>
							<Select
								value={props.query.type ?? "Alle"}
								onChange={(e) => props.setQuery(g => ({
									...g,
									skip: 0,
									type: e.target.value !== "Alle" ? e.target.value as CustomerOrderType : undefined
								}))}
							>
								<MenuItem value="Alle">Alle</MenuItem>
								<Divider />
								<MenuItem value="Return">Abholung</MenuItem>
								<MenuItem value="CustomerDelivery">Eigenanlieferung</MenuItem>
								<MenuItem value="PricingRequest">Kaufpreisanfrage</MenuItem>
							</Select>
						</FormControl>
					)}
					{props.query.type === "PricingRequest" && (
						<FormControl variant="standard" fullWidth>
							<InputLabel>Entscheidung</InputLabel>
							<Select
								value={props.query.choice ?? "Alle"}
								onChange={(e) => props.setQuery(g => ({
									...g,
									skip: 0,
									choice: e.target.value !== "Alle" ? e.target.value as CustomerOrderChoice : undefined
								}))}
							>
								<MenuItem value="Alle">Alle</MenuItem>
								<Divider />
								<MenuItem value="None">Keine / Unbepreist</MenuItem>
								<MenuItem value="Accepted">Angenommen</MenuItem>
								<MenuItem value="Denied">Abgelehnt</MenuItem>
							</Select>
						</FormControl>
					)}
					<Sort
						items={sortItems}
						value={props.query.sort}
						onChange={(sort) => props.setQuery(g => ({
							...g,
							skip: 0,
							sort
						}))}
					/>
				</SidebarForm>
			</SidebarGroup>
			<SidebarGroup>
				<SidebarButton
					variant="outlined"
					startIcon={<GetApp />}
					fullWidth
					disabled={isProcessing}
					onClick={triggerExport}
				>
					Excel-Export
				</SidebarButton>
				<Button
					variant="outlined"
					startIcon={<RotateLeft />}
					fullWidth
					onClick={props.resetQuery}
					color="inherit"
				>
					Filter zurücksetzen
				</Button>
			</SidebarGroup>
		</Sidebar>
	);
};

export default CustomerOrdersOverviewSidebar;
