import { ArmsValue } from "./ArmsVehicle";
import Tile from "../../../Components/Tiles/Tile";
import TileContent from "../../../Components/Tiles/TileContent";
import Properties from "../../../Components/Properties";
import ArmsProperty from "./ArmsProperty";

interface ArmsAttributeGroupProps {
	name : string
	values : ArmsValue[]
}

const ArmsAttributeGroup = ({ name, values } : ArmsAttributeGroupProps) => (
	<Tile title={name}>
		<TileContent>
			<Properties>
				{values.map(v => <ArmsProperty key={v.name} armsValue={v} />)}
			</Properties>
		</TileContent>
	</Tile>
);

export default ArmsAttributeGroup;
