import { FormControl, FormControlLabel, Radio, RadioGroup, useTheme } from "@mui/material";
import { ChangeEvent, useEffect } from "react";
import { VehicleAddresses } from "../../../system/Domain";
import { Form } from "../../../system/useForm";
import { addressSetter } from "../Returns/ReturnOrderUtils";

interface Props {
	name: string;
	form: Form;
	required?: boolean;
	isRkv: boolean;
	aldAddressData: VehicleAddresses;
}

const AssessmentRadioChoice = (props: Props) => {
	const theme = useTheme();
	useEffect(() => {
		props.form.register(props.name, { required: props.required ?? false });

		return () => {
			props.form.unregister(props.name);
		};
	}, [props.name]);

	const handleAssessmentLocationChange = (event: ChangeEvent<HTMLInputElement>) => {
		const selectedValue = (event.target as HTMLInputElement).value;

		props.form.setValue(props.name, selectedValue);
		props.form.validate(props.name, selectedValue);

		switch (selectedValue) {
			case "CustomerAddress":
				addressSetter("address", props.form, props.aldAddressData.customerAddress);
				break;
			case "DriverAddress":
				addressSetter("address", props.form, props.aldAddressData.traderAddress);
				break;
			case "CarParkAddress":
				addressSetter("address", props.form, props.aldAddressData.carParkAddress);
				break;
			case "RkvAddress":
				addressSetter("address", props.form, props.aldAddressData.rkvAddress);
				break;
		}
	};

	const radio = <Radio sx={!!props.form.errors[props.name] && { color: theme.palette.error.main }} color="primary" />;

	return (
		<FormControl component="fieldset" sx={!!props.form.errors[props.name] && { color: theme.palette.error.main }}>
			<RadioGroup
				row
				name={props.name}
				value={props.form.getValue(props.name) ?? ""}
				onChange={handleAssessmentLocationChange}
			>
				<FormControlLabel value="CustomerAddress" control={radio} label="Kundenadresse" labelPlacement="end" />
				<FormControlLabel value="DriverAddress" control={radio} label="Fahreradresse" labelPlacement="end" />
				<FormControlLabel
					value="CarParkAddress"
					control={radio}
					label="Gebrauchtwagenplatz"
					labelPlacement="end"
				/>
				{props.isRkv && (
					<FormControlLabel
						value="RkvAddress"
						control={radio}
						label="RKV-Geberadresse"
						labelPlacement="end"
					/>
				)}
				<FormControlLabel value="CustomEntry" control={radio} label="Freie Eingabe" labelPlacement="end" />
			</RadioGroup>
		</FormControl>
	);
};

export default AssessmentRadioChoice;
