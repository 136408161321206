import { Box, LinearProgress } from "@mui/material";
import TextBlock from "../../Components/Layout/TextBlock";
import { InventoryProps } from "./Inventory";

export default ({ inventory }: InventoryProps) => (
	<>
		<TextBlock
			primary={`${inventory.actualStock} von ${inventory.expectedStock + inventory.unexpectedVehicles} Fahrzeugen erfasst`}
			secondary={<>
				<div>davon {inventory.correctedVehicles} Korrekturen</div>
			</>}
		/>
		<Box mt={2}>
			<LinearProgress
				variant="determinate"
				value={inventory.expectedStock === 0 ? 0
					: (inventory.actualStock / (inventory.expectedStock + inventory.unexpectedVehicles)) * 100}
			/>
		</Box>
	</>
);
