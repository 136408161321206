import { Billing, BillingInvoice } from "../../system/Domain";
import { useCallback, useState } from "react";
import axios from "axios";
import parseContentDispositionFilename from "../../system/parseContentDispositionFilename";
import fileDownload from "js-file-download";

export default () => {
	const [isDownloading, setIsDownloading] = useState(false);

	const downloadInvoice = useCallback(async (billing : Billing, invoice : BillingInvoice) => {
		try {
			setIsDownloading(true);

			const response = await axios.get(`/api/billings/${billing.id}/invoices/${invoice.file.hash}`, {
				responseType: "blob"
			});

			const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
			fileDownload(response.data, filename);
		}
		finally {
			setIsDownloading(false);
		}
	}, []);

	const downloadInvoices = useCallback(async (billing : Billing, client? : string) => {
		try {
			setIsDownloading(true);
			const response = await axios.get(`/api/billings/${billing.id}/invoices`, {
				responseType: "blob",
				params: {
					client: client
				}
			});

			const filename = parseContentDispositionFilename(response.headers["content-disposition"]);
			fileDownload(response.data, filename);
		}
		finally {
			setIsDownloading(false);
		}
	}, []);

	return [downloadInvoice, downloadInvoices, isDownloading] as const;
};
