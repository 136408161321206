import { Box, Button, CircularProgress, Grid } from "@mui/material";
import axios from "axios";
import Layout from "Components/Layout/Layout";
import VehicleNavigation from "Navigation/VehicleNavigation";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useAsync } from "react-use";
import { DekraAssessment, StorageFile, UserRole, VehicleAssessment } from "system/Domain";
import { notFound } from "system/storage";
import useUser from "system/useUser";
import useVehicle from "system/useVehicle";
import LotScanContextMenu from "../LotScanContextMenu";
import VehicleHeader from "../VehicleHeader";
import VehicleHeaderMenu from "../VehicleHeaderMenu";
import VehicleSubMenu from "../VehicleSubMenu";
import AssessmentItemCard from "./AssessmentItemCard";

export interface AssessmentItem {
	type: string;
	id: string;
	date: string | null;
	dateCreated: string | null;
	dateRejected: string | null;
	externalId: string | null;
	assessment: StorageFile;
	isLinked: boolean;
}

const AssessmentsPage = () => {
	const { id } = useParams<{ id: string }>();
	const [vehicle] = useVehicle(id);
	const [assessments, setAssessments] = useState<AssessmentItem[]>([]);
	const history = useHistory();
	const [, , , , hasAnyRole] = useUser();

	const { value: vehicleAssessments } = useAsync(async () => {
		if (!vehicle) return [];

		const { data: results } = await axios.get<VehicleAssessment[]>(`/api/assessments?vin=${vehicle.vin}`);
		return results;
	}, [vehicle]);

	const { value: dekraAssessments } = useAsync(async () => {
		if (!vehicle) return [];

		const { data: results } = await axios.get<DekraAssessment[]>(`/api/dekra-assessments?vin=${vehicle.vin}`);
		return results;
	}, [vehicle]);

	useEffect(() => {
		if (!dekraAssessments || !vehicleAssessments) {
			return;
		}

		setAssessments([
			...vehicleAssessments.map((va) => ({
				type: "VehicleAssessment",
				id: va.id,
				date: va.assessmentDate,
				dateCreated: va.dateCompleted,
				dateRejected: va.dateRejected || null,
				externalId: va.externalId,
				assessment: va.attachments.find((a) => a.type === "Gutachten")?.storageFile || notFound,
				isLinked: va.id === vehicle?.remarketing?.assessment?.assessmentId,
			})),
			...dekraAssessments
				.filter((da) => !vehicleAssessments.find((va) => va.externalId === da.vorgangsnummer))
				.map((da) => ({
					type: "DekraAssessment",
					id: da.id,
					date: da.auftrag?.besichtigungsdatum,
					dateCreated: da.dateCreated,
					dateRejected: null,
					externalId: da.vorgangsnummer,
					assessment: da.attachments.find((a) => a.beschreibung === "SV-Ergebnis")?.file || notFound,
					isLinked: da.id === vehicle?.remarketing?.assessment?.assessmentId,
				})),
		]);
	}, [dekraAssessments, vehicleAssessments]);

	const handleSelectItem = (assessmentItem: AssessmentItem) => async () => {
		if (assessmentItem.type === "DekraAssessment") {
			const { data: assessment } = await axios.post(`/api/assessments`, {
				dekraAssessmentId: assessmentItem.id,
			});
			history.push(`/vehicles/${vehicle!.id}/assessments/${assessment.id}`);
		} else {
			history.push(`/vehicles/${vehicle!.id}/assessments/${assessmentItem.id}`);
		}
	};

	function handleNewAssessment() {
		if (vehicle) {
			return () => {
				history.push(`/vehicles/${vehicle.id}/assessments/new`);
			};
		}
	}

	return (
		<Layout
			title="Gutachten"
			plateNumber={vehicle?.plateNumber}
			navigation={<VehicleNavigation vehicle={vehicle} />}
			contextMenu={[<LotScanContextMenu />, <VehicleHeaderMenu vehicle={vehicle} />]}
			subMenu={vehicle && <VehicleSubMenu vehicle={vehicle} />}
		>
			{!vehicle && <CircularProgress size={48} />}
			{vehicle && (
				<Box>
					<Grid container spacing={2} alignItems="flex-end" justifyContent="space-between">
						<Grid item>
							<VehicleHeader vehicle={vehicle} />
						</Grid>
						{hasAnyRole([UserRole.AldAdmin, UserRole.AldManager, UserRole.SalesBackoffice]) && (
							<Grid item>
								<Button variant="contained" color="primary" onClick={handleNewAssessment()}>
									Neue Trockenbewertung
								</Button>
							</Grid>
						)}
					</Grid>
					<Box mt={4}>
						<Grid container spacing={2}>
							{assessments.map((a) => {
								return (
									<Grid key={a.id} item>
										<AssessmentItemCard assessmentItem={a} onSelect={handleSelectItem(a)} />
									</Grid>
								);
							})}
						</Grid>
					</Box>
				</Box>
			)}
		</Layout>
	);
};

export default AssessmentsPage;
