import axios, { AxiosResponse } from 'axios';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { Dispatch, SetStateAction, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { CloudDownload, Link, LinkOff } from '@mui/icons-material';
import { TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import ActionButton from '../../../Components/ActionButton';
import TextBlock from '../../../Components/Layout/TextBlock';
import PaperTable from '../../../Components/PaperTable';
import dateFormats from '../../../system/dateFormats';
import { ReturnOrderSession } from '../../../system/Domain';
import parseContentDispositionFilename from '../../../system/parseContentDispositionFilename';
import ReturnOrderSessionStatusPill from './ReturnOrderSessionStatusPill';

interface Props {
	returnOrderSessions : ReturnOrderSession[]
	setReturnOrderSessions : Dispatch<SetStateAction<ReturnOrderSession[]>>
}

const ReturnOrderSessionsOverviewTable = (props : Props) => {
	const history = useHistory();
	const [isProcessing, setIsProcessing] = useState<boolean>(false);

	const handleDownloadFile = async (id : string) => {
		if (!id) return;

		const response = await axios.get<Blob>(`/api/storage/${id}`, {
			responseType: "blob"
		});

		handleDownload(response);
	};

	const handleDownload = (response : AxiosResponse<Blob>) => {
		const filename = parseContentDispositionFilename(response.headers["content-disposition"]);

		fileDownload(response.data, filename);
	};

	const handleCompletion = async (id : string) => {
		if (!id) return;

		setIsProcessing(true);

		try {
			const {data:returnOrderSession } = await axios.post(`/api/orders/returns/sessions/${id}/complete`);

			props.setReturnOrderSessions(sessions => sessions
				.map(s => s.id === returnOrderSession.id ? returnOrderSession : s)
			);
		} finally {
			setIsProcessing(false);
		}
	}

	return (
		<PaperTable>
			<colgroup>
				<col width="auto" />
				<col width="20%" />
				<col width="20%" />
				<col width="20%" />
				<col width="20%" />
				<col width="20%" />
				<col width="auto" />
				<col width="auto" />
			</colgroup>
			<TableHead>
				<TableRow>
					<TableCell>Status</TableCell>
					<TableCell>Import</TableCell>
					<TableCell>Gutachten</TableCell>
					<TableCell>Fahrzeughistorie</TableCell>
					<TableCell>Gutachtenübersicht</TableCell>
					<TableCell>Abmeldung</TableCell>
					<TableCell></TableCell>
					<TableCell>Aktionen</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{props.returnOrderSessions && props.returnOrderSessions.map(session => (
					<TableRow
						key={session.id}
						hover
					>
						<TableCell>
							<ReturnOrderSessionStatusPill returnOrderSession={session} />
						</TableCell>
						<TableCell
							onClick={session.importId ? () => history.push(`/imports/${session.importId}`) : undefined}
						>
							{session.importId && (
								<TextBlock
									primary={<Link />}
									secondary="zum Import"
								/>
							)}
							{!session.importId && (
								<TextBlock
									primary={<LinkOff />}
								/>
							)}
						</TableCell>
						<TableCell
							onClick={() => handleDownloadFile(session.assessmentCsvReference?.hash)}
						>
							{session.assessmentCsvReference && (
								<TextBlock
									primary={<CloudDownload />}
									secondary="Herunterladen"
								/>
							)}
						</TableCell>
						<TableCell
							onClick={() => handleDownloadFile(session.vehicleHistoryCsvReference?.hash)}
						>
							{session.vehicleHistoryCsvReference && (
								<TextBlock
									primary={<CloudDownload />}
									secondary="Herunterladen"
								/>
							)}
						</TableCell>
						<TableCell
							onClick={() => handleDownloadFile(session.assessmentOverviewCsvReference?.hash)}
						>
							{session.assessmentOverviewCsvReference && (
								<TextBlock
									primary={<CloudDownload />}
									secondary="Herunterladen"
								/>
							)}
						</TableCell>
						<TableCell
							onClick={() => handleDownloadFile(session.unregistrationCsvReference?.hash)}
						>
							{session.unregistrationCsvReference && (
								<TextBlock
									primary={<CloudDownload />}
									secondary="Herunterladen"
								/>
							)}
						</TableCell>
						<TableCell>
							<TextBlock
								primary={session.createdBy.name}
								secondary={moment(session.dateCreated).format(dateFormats.dateTime)}
							/>
						</TableCell>
						<TableCell>
							{session.status === "Created" && (
								<ActionButton variant="text" color="primary" disabled={isProcessing} onClick={() => handleCompletion(session.id)}>
									Abschließen
								</ActionButton>
							)}
							{/*	<ActionButton variant="text" color="secondary" disabled={isProcessing} onClick={() => handleCancellation(session.id)}>*/}
							{/*		Stornieren*/}
							{/*	</ActionButton>*/}
						</TableCell>
					</TableRow>
				))}
			</TableBody>

		</PaperTable>
	);
};

export default ReturnOrderSessionsOverviewTable;
