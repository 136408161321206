import { Box, Divider, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';

export interface SortProps {
	items: SortItem[]
	value: string
	onChange: (key: string, field: string, direction: string) => void
}

export interface SortItem {
	title: string
	field: string
}

export default ({ items, value, onChange }: SortProps) => {
	return (
		<FormControl variant="standard" fullWidth>
			<InputLabel>Sortierung</InputLabel>
			<Select
				value={value}
				sx={{
					"& .MuiSelect-select": {
						display: "flex",
						alignItems: "center"
					}
				}}
			>
				{items.map((i, j) => {
					const ui = (title: string, field: string, direction: string) => {
						const key = `${field}:${direction}`;
						return (
							<MenuItem
								key={key}
								value={key}
								selected={key === value}
								onClick={() => {
									onChange(key, field, direction);
								}}
							>
								{direction === "asc" && (
									<ArrowUpward fontSize="small" />
								)}
								{direction === "desc" && (
									<ArrowDownward fontSize="small" />
								)}
								<Box ml={1} display="inline">
									{title}
								</Box>
							</MenuItem>
						);
					};

					return [
						j > 0 && <Box my={1}><Divider /></Box>,
						ui(i.title, i.field, "asc"),
						ui(i.title, i.field, "desc"),
					];
				})}
			</Select>
		</FormControl>
	);
};
