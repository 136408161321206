import { Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box/Box";
import { InvoiceItem } from "../../system/Domain"
import numberFormatter from '../../system/currencyFormatter';
import TextBlock from "../../Components/Layout/TextBlock";

interface Props {
	item: InvoiceItem
}

const Surcharges = (props: Props) => {
	const surchargeNames = [
		{
			key: "deregistrationNoTax",
			name: "Abmeldung beh. Geb. steuerfrei",
			short: "ABSF"
		},
		{
			key: "deregistrationWithTax",
			name: "Abmeldung beh. Geb. steuerpflichtig",
			short: "ABSP"
		},
		{
			key: "shipmentCost",
			name: "Versand",
			short: "VER"
		},
		{
			key: "kzkNoTax",
			name: "Behördliche Gebühren KZK steuerfrei",
			short: "KZSF"
		},
		{
			key: "kzkWithTax",
			name: "Behördliche Gebühren KZK steuerpflichtig",
			short: "KZSP"
		},
		{
			key: "netOilCost",
			name: "Oel netto",
			short: "Oel"
		},
		{
			key: "specialHandlingCost",
			name: "Sonderhandling Einnahme",
			short: "SNE"
		},
		{
			key: "otherCost",
			name: "andere Zusatzkosten",
			short: "ANZ"
		}
	];

	return (
		<Tooltip
			title={
				<Box p={1}>
					{surchargeNames.map(sn => (
						<TextBlock primary={sn.short} secondary={sn.name} key={sn.name} />
					))}
				</Box>
			}
		>
			<div>
				{props.item.deregistrationNoTax != 0 && (
					<Typography
						noWrap>{surchargeNames.find(sn => sn.key == "deregistrationNoTax")?.short + ": " + numberFormatter.format(props.item.deregistrationNoTax)}</Typography>
				)}
				{props.item.deregistrationWithTax != 0 && (
					<Typography
						noWrap>{surchargeNames.find(sn => sn.key == "deregistrationWithTax")?.short + ": " + numberFormatter.format(props.item.deregistrationWithTax)}</Typography>
				)}
				{props.item.shipmentCost != 0 && (
					<Typography
						noWrap>{surchargeNames.find(sn => sn.key == "shipmentCost")?.short + ": " + numberFormatter.format(props.item.shipmentCost)}</Typography>
				)}
				{props.item.kzkNoTax != 0 && (
					<Typography
						noWrap>{surchargeNames.find(sn => sn.key == "kzkNoTax")?.short + ": " + numberFormatter.format(props.item.kzkNoTax)}</Typography>
				)}
				{props.item.kzkWithTax != 0 && (
					<Typography
						noWrap>{surchargeNames.find(sn => sn.key == "kzkWithTax")?.short + ": " + numberFormatter.format(props.item.kzkWithTax)}</Typography>
				)}
				{props.item.netOilCost != 0 && (
					<Typography
						noWrap>{surchargeNames.find(sn => sn.key == "netOilCost")?.short + ": " + numberFormatter.format(props.item.netOilCost)}</Typography>
				)}
				{props.item.specialHandlingCost != 0 && (
					<Typography
						noWrap>{surchargeNames.find(sn => sn.key == "specialHandlingCost")?.short + ": " + numberFormatter.format(props.item.specialHandlingCost)}</Typography>
				)}
				{props.item.otherCost != 0 && (
					<Typography
						noWrap>{surchargeNames.find(sn => sn.key == "otherCost")?.short + ": " + numberFormatter.format(props.item.otherCost)}</Typography>
				)}
			</div>
		</Tooltip>
	)
}

export default Surcharges;
