import { atom } from "recoil";

const defaultCustomerTitle = "Leasingvertragsende";

const customerTitle = atom({
	key: "customer-title",
	default: defaultCustomerTitle,
});

export { customerTitle, defaultCustomerTitle };
