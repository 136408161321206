import { useParams } from "react-router";
import PricingPage from "./PricingPage";
import { createNew } from "./transportPricingUtils";

export default () => {
	const { company } = useParams<{ company: string }>();
	const pricing = createNew(company);

	return (
		<PricingPage
			pricing={pricing}
			startEdit
		/>
	)
}
