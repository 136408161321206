import { isDefined, isValidBoolean, isValidNumber, isValidText } from './validators';
import axios from "axios";
import fileDownload from "js-file-download";
import confirmDialog from "../Dialogs/confirm";
import parseContentDispositionFilename from "./parseContentDispositionFilename";
import { ReturnForm } from "./Domain";

export default (r : ReturnForm) => !r ? r : Object.assign(r, {
	isLocked: () => isDefined(r.signatures)
		&& (
			(isDefined(r.signatures?.deliverer)
				&& isValidText(r.signatures?.deliverer?.image)
			)
			|| (isDefined(r.signatures?.recipient)
				&& isValidText(r.signatures?.recipient?.image)
			)
		),
	isSigned: () => isDefined(r.signatures)
		&& isDefined(r.signatures?.deliverer)
		&& isValidText(r.signatures?.deliverer?.image)
		&& isDefined(r.signatures?.recipient)
		&& isValidText(r.signatures?.recipient?.image),
	isValid: () => isDefined(r.readiness)
		&& isValidBoolean(r.readiness?.oilLevel)
		&& isValidBoolean(r.readiness?.coolant)
		&& isValidBoolean(r.readiness?.warningIndicator)
		&& isValidBoolean(r.readiness?.startAttempt)
		&& isValidBoolean(r.readiness?.lighting)
		&& isValidText(r.readiness?.fuelLevel)
		&& isValidBoolean(r.readiness?.validInspections)
		&& isValidBoolean(r.readiness?.inspectionReports)
		&& isValidBoolean(r.readiness?.correctVin)
		&& isDefined(r.readiness?.fuelLevelPictureProof)

		&& isDefined(r.documentsAndEquipment)
		&& isValidBoolean(r.documentsAndEquipment?.registrationDocument)
		&& (r.documentsAndEquipment?.registrationDocument === false || isDefined(r.documentsAndEquipment?.registrationDocumentFront))
		&& (r.documentsAndEquipment?.registrationDocument === false || isDefined(r.documentsAndEquipment?.registrationDocumentBack))
		&& isValidNumber(r.documentsAndEquipment?.keys)
		&& isValidBoolean(r.documentsAndEquipment?.remoteControl)
		&& isValidNumber(r.documentsAndEquipment?.fuelCards)
		&& isValidNumber(r.documentsAndEquipment?.seats)
		&& isValidBoolean(r.documentsAndEquipment?.boardBriefcase)
		&& isValidBoolean(r.documentsAndEquipment?.operationManual)
		&& isValidBoolean(r.documentsAndEquipment?.serviceBooklet)
		&& isValidBoolean(r.documentsAndEquipment?.serviceRecord)
		&& isValidBoolean(r.documentsAndEquipment?.radioCodeCard)
		&& isValidBoolean(r.documentsAndEquipment?.radio)
		&& isValidBoolean(r.documentsAndEquipment?.navigationDevice)
		&& isValidBoolean(r.documentsAndEquipment?.navigationDataMedium)
		&& isValidNumber(r.documentsAndEquipment?.plateNumbers)

		&& isDefined(r.impedingConditions)
		&& isValidBoolean(r.impedingConditions?.lighting)
		&& isValidBoolean(r.impedingConditions?.rain)
		&& isValidBoolean(r.impedingConditions?.dirt)
		&& isValidBoolean(r.impedingConditions?.snowOrIce)
		&& isDefined(r.impedingConditions?.pictureOfVehicle)

		&& isDefined(r.vehiclePictures)
		&& isDefined(r.vehiclePictures?.frontLeft)
		&& isDefined(r.vehiclePictures?.backLeft)
		&& isDefined(r.vehiclePictures?.backCenter)
		&& isDefined(r.vehiclePictures?.trunk)
		&& isDefined(r.vehiclePictures?.backRight)
		&& isDefined(r.vehiclePictures?.tiresAndRims)
		&& isDefined(r.vehiclePictures?.frontRight)
		&& isDefined(r.vehiclePictures?.frontCenter)
		&& isDefined(r.vehiclePictures?.windshield)
		&& isDefined(r.vehiclePictures?.interior)

		&& isDefined(r.installedTiresAndRims)
		&& isValidText(r.installedTiresAndRims?.tireType)
		&& isValidText(r.installedTiresAndRims?.rimType)
		&& isValidBoolean(r.installedTiresAndRims?.spareTire)
		&& isValidBoolean(r.installedTiresAndRims?.wheelTrimCap)
		&& isDefined(r.installedTiresAndRims?.rimFrontLeft)
		&& isDefined(r.installedTiresAndRims?.rimBackLeft)
		&& isDefined(r.installedTiresAndRims?.rimBackRight)
		&& isDefined(r.installedTiresAndRims?.rimFrontRight)

		&& isValidBoolean(r.additionalTiresAndRims)
		&& (!r.additionalTiresAndRims || (
			isValidText(r.suppliedTiresAndRims?.tireType)
			&& isValidText(r.suppliedTiresAndRims?.rimType)
			&& isDefined(r.suppliedTiresAndRims?.picture)
		))

		&& isDefined(r.accessories)
		&& isValidBoolean(r.accessories?.antenna)
		&& isValidBoolean(r.accessories?.roofRack)
		&& isValidBoolean(r.accessories?.bootCover)
		&& isValidBoolean(r.accessories?.loadSecuringNet)
		&& isValidBoolean(r.accessories?.toolKit)
		&& isValidBoolean(r.accessories?.jack)
		&& isValidBoolean(r.accessories?.tireFit)
		&& isValidBoolean(r.accessories?.firstAidKit)
		&& isValidBoolean(r.accessories?.warningTriangle)
		&& isValidBoolean(r.accessories?.chargingCable)

		&& isValidText(r.previousDamages)
		&& (r.previousDamages !== "Yes" || (
			isDefined(r.previousDamagesDetails)
			&& isValidNumber(r.previousDamagesDetails?.repairCosts)
			&& isValidText(r.previousDamagesDetails?.insuranceType)
			&& isValidText(r.previousDamagesDetails?.insuranceAgency)
		))

		&& isDefined(r.transfer)
		&& isValidNumber(r.transfer?.mileage)
		&& isValidBoolean(r.transfer?.driversLicenseChecked),
	print: async () => {
		const { data, headers } = await axios.get(`/api/orders/transports/${r.orderId}/return-forms/${r.id}.pdf`, {
			responseType: "blob"
		});

		const filename = parseContentDispositionFilename(headers["content-disposition"]);
		fileDownload(data, filename);
	},
	delete: async () => {
		const result = await confirmDialog("Soll das Rücknahmeprotokoll wirklich gelöscht werden?");
		if (!result.ok) {
			return false;
		}

		await axios.delete(`/api/orders/transports/${r.orderId}/return-forms/${r.id}`);
		return true;
	}
});
