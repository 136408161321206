import axios from "axios";
import VehicleSearch from "Components/VehicleSearch";
import OrdersNavigation from "Navigation/OrdersNavigation";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Layout from "../../../Components/Layout/Layout";
import NotificationSnackbar from "../../../Components/Layout/NotificationSnackbar";
import confirm from "../../../Dialogs/confirm";
import { AssessmentType, CustomerOrder, ReturnOrder, UserRole, Vehicle } from "../../../system/Domain";
import { Box, Button, Grid } from "@mui/material";
import FormChoices from "../../../Components/FormChoices";
import useForm from "../../../system/useForm";
import FormYesNo from "../../../Components/FormYesNo";
import useAction from "../../../system/useAction";
import CustomerQuickFAQ from "../../Customer/CustomerQuickFAQ";
import useUser from "../../../system/useUser";

export interface CustomerOrderDetailsFormType {
	isPoolfleet?: boolean;
	assessmentType?: AssessmentType;
	isTraderOrder?: boolean;
	allowEarlyReturn?: boolean;
}

const CustomerOrdersNew = () => {
	const history = useHistory();
	const [vehicle, setVehicle] = useState<Vehicle>();
	const [error, setError] = useState<string>("");
	const [customerOrderDetails, setCustomerOrderDetails] = useState<CustomerOrderDetailsFormType>(
		{}
	);
	const [, , hasRole] = useUser();
	const isReceivablesManagement = hasRole(UserRole.ReceivablesManagement);

	const handleSelect = async (vehicle: Vehicle) => {
		// customerOrder duplicate check
		const { data: existingCustomerOrders } = await axios.get<CustomerOrder[]>(
			"/api/orders/customers",
			{
				params: {
					vehicleId: vehicle.id
				}
			}
		);

		if (existingCustomerOrders?.length > 0) {
			if (existingCustomerOrders.some((s) => s.status === "Created")) {
				setError("Es gibt für dieses Fahrzeug bereits einen offenen Kundenrückholauftrag");
				return;
			} else if (existingCustomerOrders.some((s) => s.status === "Completed")) {
				const result = await confirm(
					"Achtung - es liegt bereits ein vom Kunden ausgefüllter Kundenrückholauftrag vor. Wollen Sie wirklich fortfahren?",
					"Warnung"
				);

				if (!result.ok) {
					return;
				}
			}
		}

		// return order check - active until remarketing cycle refactoring
		const { data: existingReturnOrder } = await axios.get<ReturnOrder[]>(
			"/api/orders/returns",
			{
				params: {
					vehicleId: vehicle.id
				}
			}
		);

		if (
			existingReturnOrder?.length > 0 &&
			existingReturnOrder.some((r) => r.status !== "Cancelled")
		) {
			setError(
				"Es gibt für dieses Fahrzeug bereits einen (nicht stornierten) Rückholauftrag"
			);

			return;
		}

		setVehicle(vehicle);
	};

	const form = useForm({
		values: customerOrderDetails,
		setValues: setCustomerOrderDetails
	});

	const handleClearError = () => {
		setError("");
	};

	const [save, isSaving] = useAction(async () => {
		if (!vehicle) return;
		if (!form.validateAll()) return;

		await axios.post("/api/orders/customers", {
			vehicleId: vehicle.id,
			...customerOrderDetails
		});

		history.push("/orders/customers");
	});

	return (
		<Layout title="Kundenauftrag erstellen" navigation={<OrdersNavigation />}>
			<NotificationSnackbar message={error} onClose={handleClearError} color="error" />
			{!vehicle && <VehicleSearch onClick={handleSelect} />}
			{vehicle && (
				<Grid container spacing={3}>
					<Grid item xs={12} md={6} lg={4}>
						<FormChoices
							name="assessmentType"
							label="Gutachtentyp"
							form={form}
							choices={{
								Restwertgutachten: "R1 - Restwertgutachten",
								Minderwertsgutachten: "R2 - Minderwertsgutachten"
							}}
							options={{ required: true }}
						/>
						<Box mt={2}>
							<FormYesNo
								name="isPoolfleet"
								label="Handelt es sich um ein ALD Flex Fahrzeug?"
								form={form}
								options={{ required: true }}
							/>
						</Box>
						<Box mt={2}>
							<FormYesNo
								name="isTraderOrder"
								label={
									<>
										Wird dieser Auftrag für einen Händler erstellt?{" "}
										<CustomerQuickFAQ faqKey="creation-trader-hint" />
									</>
								}
								form={form}
								options={{ required: true }}
							/>
						</Box>
						{isReceivablesManagement && (
							<Box mt={2}>
								<FormYesNo
									name="allowEarlyReturn"
									label={
										<>
											Handelt es sich um eine frühzeitige Rückführung?
											<CustomerQuickFAQ faqKey="creation-early-return-hint" />
										</>
									}
									form={form}
									options={{ required: true }}
								/>
							</Box>
						)}
					</Grid>
					<Grid item xs={12}>
						<Box mt={2} display="flex">
							<Button
								variant="outlined"
								color="primary"
								onClick={save}
								disabled={isSaving}
							>
								Auftrag erstellen
							</Button>
						</Box>
					</Grid>
				</Grid>
			)}
		</Layout>
	);
};

export default CustomerOrdersNew;
