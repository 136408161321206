import axios from 'axios';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {CompareArrows, Explore, Print} from '@mui/icons-material';

import Sidebar from '../../../../Components/Sidebar/Sidebar';
import SidebarButton from '../../../../Components/Sidebar/SidebarButton';
import SidebarGroup from '../../../../Components/Sidebar/SidebarGroup';
import { ReturnForm, TransportOrder } from '../../../../system/Domain';

export interface ReturnFormSidebarProps {
	order : TransportOrder
	returnForm? : ReturnForm
	deltaFormId? : string
	deltaReturnForm : ReturnForm | null
	setDeltaReturnForm : Dispatch<SetStateAction<ReturnForm | null>>
}

export default ({ order, returnForm, deltaFormId, deltaReturnForm, setDeltaReturnForm } : ReturnFormSidebarProps) => {
	const history = useHistory();

	const [printing, setPrinting] = useState(false);
	const print = useCallback(async () => {
		if (!returnForm || !returnForm.print) {
			return;
		}

		setPrinting(true);

		try {
			await returnForm.print();
		} finally {
			setPrinting(false);
		}
	}, [returnForm]);

	const toggleDelta = async () => {
		if (!returnForm) {
			return;
		}

		if (deltaReturnForm) {
			setDeltaReturnForm(null);
		} else {
			const response = await axios.get(`/api/orders/transports/${returnForm.orderId}/return-forms/${returnForm.predecessorId}`);
			setDeltaReturnForm(response.data);
		}
	};

	return (
		<Sidebar>
			<SidebarGroup>
				<SidebarButton
					startIcon={<Explore />}
					label="Zum Auftrag"
					onClick={() => history.push(`/orders/transports/${order.id}`)}
				/>
				{returnForm?.id && (
					<SidebarButton
						startIcon={<Print />}
						label="Protokoll drucken"
						onClick={print}
						disabled={printing}
					/>
				)}
			</SidebarGroup>
			<SidebarGroup>
				{returnForm && returnForm.id && returnForm.predecessorId && returnForm.isSigned && returnForm.isSigned() && !deltaFormId && (
					<SidebarButton
						startIcon={<CompareArrows />}
						label={!deltaReturnForm ? "Nur Differenzen anzeigen" : "Alles anzeigen"}
						onClick={toggleDelta}
					/>
				)}
			</SidebarGroup>
		</Sidebar>
	);
}
