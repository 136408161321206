import { Grid, GridProps } from "@mui/material";
import { Children } from "react";

type ActionsProps = GridProps & {
	align?: string
}

export default ({ children, align = "right", ...rest }: ActionsProps) => {
	return (
		<Grid
			container
			spacing={1}
			sx={{
				flexWrap: "nowrap",
				alignItems: "baseline",
				flexDirection: align === "right" ? "row-reverse" : "row"
			}}
			{...rest}
		>
			{Children.map(children, (child: any) => {
				if (!child) {
					return null;
				}

				return (
					<Grid item>
						{child}
					</Grid>
				);
			})}
		</Grid>
	);
}
