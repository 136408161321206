import axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";
import { useState } from "react";
import { GetApp, SkipNext, SkipPrevious } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import ActionButton from "../../Components/ActionButton";
import Gauge from "../../Components/Gauge";
import Tile from "../../Components/Tiles/Tile";
import TileActions from "../../Components/Tiles/TileActions";
import TileContent from "../../Components/Tiles/TileContent";
import { Return } from "../../system/Domain";
import useAsyncEffect from "../../system/useAsyncEffect";

export const dekra_capacity_morning = 65;
export const dekra_capacity_afternoon = 55;

export default () => {
	const [date, setDate] = useState(moment());
	const [data, setData] = useState<Return>({ morning: 0, afternoon: 0 });
	const [downloading, setDownloading] = useState<boolean>(false);

	useAsyncEffect(async () => {
		const response = await axios.get<Return>(`/api/returns/${date.format("YYYY-MM-DD")}`);
		setData(response.data);
	}, [date]);

	const handleNextDay = () => {
		const newDate = date.clone().add(1, "day");
		setDate(newDate);
	};

	const handlePreviousDay = () => {
		const newDate = date.clone().add(-1, "day");
		setDate(newDate);
	};

	const handleDownload = async () => {
		try {
			setDownloading(true);
			const response = await axios.get(`/api/returns/${date.format("YYYY-MM-DD")}/meldeliste`);
			fileDownload(response.data, `Meldeliste-${date.format("DD-MM-YYYY")}.csv`, "text/csv", "\uFEFF");
		} finally {
			setDownloading(false);
		}
	};

	const handleDownloadQrCodes = async () => {
		try {
			setDownloading(true);

			const response = await axios.get(
				`/api/orders/transports/planned-returns/qr-code?date=${date.format("YYYY-MM-DD")}`,
				{
					responseType: "blob",
				},
			);

			fileDownload(response.data, `${date.format("YYYY-MM-DD")} QrCodes.pdf`);
		} finally {
			setDownloading(false);
		}
	};

	return (
		<Tile sxProp={{ width: 640 }} title={`Auftragseingang ${date.format("L")}`}>
			<TileContent>
				<Gauge value={data.morning} max={dekra_capacity_morning} title="Vormittags" />
				<Gauge value={data.afternoon} max={dekra_capacity_afternoon} title="Nachmittags" />
			</TileContent>
			<TileActions>
				<div>
					<Box component="span" mr={2}>
						<ActionButton variant="text" disabled={downloading} onClick={handleDownloadQrCodes}>
							Qr Codes
						</ActionButton>
					</Box>
					<Box component="span" mr={2}>
						<IconButton size="small" disabled={downloading} onClick={handleDownload}>
							<GetApp />
						</IconButton>
					</Box>
					<IconButton size="small" onClick={handlePreviousDay}>
						<SkipPrevious />
					</IconButton>
					<IconButton size="small" onClick={handleNextDay}>
						<SkipNext />
					</IconButton>
				</div>
			</TileActions>
		</Tile>
	);
};
