import { Box, Snackbar, useTheme } from "@mui/material";
import { Error, Close } from '@mui/icons-material';
import { useEffect, useState } from "react";
import SnackbarContent from "@mui/material/SnackbarContent";
import IconButton from "@mui/material/IconButton";
import useAsyncEffect from "../../system/useAsyncEffect";
import useLog from "../../system/useLog";
import translateError from "../../system/translateError";

interface ErrorSnackbarProps {
	error: any,
	onClose: () => void
}

export default ({ error, onClose }: ErrorSnackbarProps) => {
	const theme = useTheme();
	const [message, setMessage] = useState(null);

	useAsyncEffect(async () => {
		let newMessage = error ? error.message : "";

		if (error && error.response) {
			const { response } = error;

			if (response.status === 403) {
				newMessage = "Sie sind zur Ausführung dieser Operation nicht berechtigt";
			} else {
				if (response.data.text) {
					newMessage = await response.data.text();
				} else if (response.data) {
					newMessage = response.data;
					if (typeof newMessage === "object") {
						newMessage = JSON.stringify(response.data);
					}
				}
			}
		}

		setMessage(
			translateError(newMessage)
		);
	}, [error]);

	const log = useLog();

	useEffect(() => {
		if (!message) {
			return;
		}

		log.warn(message);
	}, [message]);

	return (
		<Snackbar
			open={Boolean(message)}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center"
			}}
			autoHideDuration={6000}
			onClose={onClose}
		>
			<SnackbarContent
				sx={{
					backgroundColor: theme.palette.error.dark,
					color: theme.palette.getContrastText(theme.palette.error.dark)
				}}
				message={(
					<Box sx={{
						display: "flex",
						alignItems: "center"
					}}>
						<Error sx={{
							fontSize: 20,
							opacity: 0.9,
							marginRight: theme.spacing(1)
						}} />
						<Box sx={{ whiteSpace: "pre-line" }}>
							{message}
						</Box>
					</Box>
				)}
				action={[
					<IconButton key="close" color="inherit" onClick={onClose}>
						<Close sx={{
							fontSize: 20
						}} />
					</IconButton>
				]}
			/>
		</Snackbar>
	);
};
