import {
	Assignment,
	AssignmentTurnedIn,
	CarRental,
	DirectionsCar,
	EmojiTransportation,
	ExitToApp,
	Help,
	Money,
	Settings,
	SettingsBackupRestore,
} from "@mui/icons-material";
import { Theme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import VehicleServiceUnconfirmedBadge from "Components/Layout/Badges/VehicleServiceUnconfirmedBadge";
import { useRecoilState } from "recoil";
import { logoutAtom } from "system/atoms/logout";
import { menuAtom } from "system/atoms/menu";
import { UserRole } from "system/Domain";
import useUser from "system/useUser";
import LayoutNavigationItem from "../Components/Layout/LayoutNavigationItem";
import LayoutNavigationSection from "../Components/Layout/LayoutNavigationSection";

export default () => {
	const [menu] = useRecoilState(menuAtom);
	const [, setLogout] = useRecoilState(logoutAtom);
	const [user, , hasRole] = useUser();
	const isScreenSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

	if (menu.open && !isScreenSmall) {
		return null;
	}

	return (
		<LayoutNavigationSection title="Allgemein">
			<LayoutNavigationItem
				roles={[
					UserRole.AldUser,
					UserRole.LotManager,
					UserRole.LogisticCompany,
					UserRole.Driver,
					UserRole.DamageAssessor,
				]}
				icon={<Assignment />}
				title="Aufträge"
				path={`/orders/transports`}
			/>
			<LayoutNavigationItem
				roles={[UserRole.AldUser, UserRole.LotManager]}
				icon={<DirectionsCar />}
				title="Fahrzeuge"
				path={`/vehicles/search`}
			/>
			<LayoutNavigationItem
				roles={[UserRole.AldUser, UserRole.LotManager]}
				icon={
					<VehicleServiceUnconfirmedBadge>
						<AssignmentTurnedIn />
					</VehicleServiceUnconfirmedBadge>
				}
				title="Tätigkeiten"
				path={`/vehicles/services`}
			/>
			<LayoutNavigationItem
				roles={[UserRole.AldUser]}
				icon={<Money />}
				title="Verkauf"
				path={`/sales/dashboard`}
			/>
			<LayoutNavigationItem
				roles={[UserRole.AldUser]}
				icon={<CarRental />}
				title="Flex"
				path="/pool-fleet/vehicles"
			/>
			<LayoutNavigationItem
				roles={[UserRole.AldUser, UserRole.LotManager]}
				icon={<EmojiTransportation />}
				title="Dekra"
				path="/companies/dekra"
				companies={["Dekra", "ALD"]}
			/>
			<LayoutNavigationItem
				roles={[UserRole.AldAdmin]}
				icon={<SettingsBackupRestore />}
				title="Verwaltung"
				path="/admin/jobs"
			/>
			<LayoutNavigationItem
				roles={[UserRole.AldUser, UserRole.LotManager, UserRole.Driver, UserRole.LogisticCompany]}
				icon={<Settings />}
				title="Einstellungen"
				path="/settings"
			/>

			{user && (
				<>
					{hasRole(UserRole.AldUser) && (
						<LayoutNavigationItem
							onClick={() => window.open("https://jira.ald.de/servicedesk/customer/portal/4", "_blank")}
							icon={<Help />}
							title="Hilfe"
						/>
					)}
					{!hasRole(UserRole.AldUser) && !hasRole(UserRole.Customer) && (
						<LayoutNavigationItem path="/help" icon={<Help />} title="Hilfe" />
					)}
					{hasRole(UserRole.Customer) && (
						<LayoutNavigationItem path="/orders/customers/faq" icon={<Help />} title="Hilfe / FAQ" />
					)}
					<LayoutNavigationItem
						roles={[UserRole.AldUser, UserRole.Customer, UserRole.Driver]}
						onClick={() => setLogout(true)}
						icon={<ExitToApp />}
						title="Abmelden"
					/>
				</>
			)}
		</LayoutNavigationSection>
	);
};
